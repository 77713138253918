import appUrl from "../../utils/Constants/appUrl";
import { getData, postData } from "../genaric/genaric-api";
import { store } from "../../redux";
import { setUser } from "../../redux/login";
import axios from "axios";

let SSO_URL = process.env.REACT_APP_SSO_URL;
/////////////////////////////////////////////////////////////////////////////// AUTH FUNCTIONS START
export async function login(data) {
    return postData(appUrl["user-management"].login, data);
}
export async function logoutCall(data) {
    return postData(appUrl["user-management"].logout, data);
}
export async function logout() {
    store.dispatch(setUser(null));
    localStorage.clear();

    const cookiesArray = document.cookie.split(";");

    // Iterate over the array and remove each cookie
    cookiesArray.forEach((cookie) => {
        const cookieName = cookie.split("=")[0].trim();
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });

    window.location.reload();
}

export async function refreshTokenAPI() {
    let url = `${SSO_URL}connect/token`;
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${accessToken}`,
        },
    };
    // Params
    const params = new URLSearchParams();
    params.append("client_id", "SSOClient");
    params.append("client_secret", "ssosecret");
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refreshToken);

    return axios.post(url, params, config);
}

export async function changePassword(data) {
    return postData(appUrl["user-management"].changePassword, data);
}

export async function sendEmail(data) {
    return postData(appUrl["user-management"].sendEmail, data);
}

export async function checkTimeExpiry(data) {
    return postData(appUrl["user-management"].checkTimeExpiry, data);
}

export async function resendCode(data) {
    return postData(appUrl["user-management"].resendCode, data);
}

export async function verifyCode(data) {
    return postData(appUrl["user-management"].verifyCode, data);
}

export async function setPassword(data) {
    return postData(appUrl["user-management"].setPassword, data);
}

export async function validatePasswordToken(data) {
    return postData(appUrl["user-management"].verifyToken, data);
}

export async function getLoggedInUser(data) {
    return postData(appUrl["user-management"].loggedInUser, data);
}
/////////////////////////////////////////////////////////////////////////////// AUTH FUNCTIONS END

/////////////////////////////////////////////////////////////////////////////// USER FUNCTIONS START
export async function getUserGrid(data) {
    return postData(appUrl["user-management"].getUserGrid, data);
}
export async function getUserAssign(data) {
    return postData(appUrl["user-management"].getUserAssign, data);
}

export async function setUserStatus(data) {
    return postData(appUrl["user-management"].setUserStatus, data);
}

export async function updateUserProfile(data) {
    return postData(appUrl["user-management"].updateUserProfile, data);
}

export async function updateUserProfileImage(data) {
    return postData(appUrl["user-management"].updateUserProfileImage, data);
}

export async function createUser(data) {
    return postData(appUrl["user-management"].createUser, data);
}

export async function deleteAttachment(data) {
    return postData(appUrl["user-management"].deleteAttachment, data);
}

/////////////////////////////////////////////////////////////////////////////// USER FUNCTIONS END

/////////////////////////////////////////////////////////////////////////////// ROLE/RIGHTS FUNCTIONS START
export async function getAllRightsForTree() {
    return postData(appUrl["user-management"].getAllRights);
}

export async function createRole(data) {
    return postData(appUrl["user-management"].createRole, data);
}

export async function updateRole(data) {
    return postData(appUrl["user-management"].updateRole, data);
}

export async function getRoleGrid(data) {
    return postData(appUrl["user-management"].getRoleGrid, data);
}

export async function getRoleRights(data) {
    return postData(appUrl["user-management"].getRoleRights, data);
}

export async function getAssignedUserRoles(data) {
    return postData(appUrl["user-management"].getAssignedUserRoles, data);
}

export async function deleteUserRole(data) {
    return postData(appUrl["user-management"].deleteRole + "?roleId=" + data, {});
}

export async function setRoleStatus(data) {
    return postData(appUrl["user-management"].updateRoleStatus, data);
}
/////////////////////////////////////////////////////////////////////////////// ROLE/RIGHTS FUNCTIONS END
