import React, { useContext, useEffect, useState } from "react";
import FilterBar from "./GridView/FilterBar";
import Body from "./GridView/Body";
import { ToastContext } from "../../../../../context/toast";
import { changeCreditRequestStatus, getCreditRequests } from "../../../../../services/credit-request";
import AddNewCreditRequest from "../Components/AddNewCreditRequest";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onCreditRequestFormDirty } from "../../../../../redux/main-view";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";
import ViewCreditRequestDetail from "../Components/ViewCreditRequestDetail";
import { CLIENT_CREDIT_GRID_HEADERS_FOR_CLIENT, CREDIT_REQUEST_KEBAB_MENU, ROLE_NAME } from "../../../../../utils/Constants/global";
import { validateSomeUserPermissions, validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { loginState } from "../../../../../redux/login";
import { CREDIT_REQUEST_RIGHTS } from "../../../../../utils/Constants/rights";

const CreditRequest = (props) => {
    const { isClientRequest, gridColumns } = props;
    const toast = useContext(ToastContext);

    //Hooks
    const dispatch = useDispatch();

    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    const [keyword, setKeyword] = useState("");
    const [selectedColumns, setSelectedColumns] = useState(gridColumns);
    const [selectedCreditRequests, setSelectedCreditRequests] = useState([]);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);

    const [kebabMenuItems, setKebabMenuItems] = useState([]);
    const [kebabMenuItemLoader, setKebabMenuItemLoader] = useState(false);
    const [addCreditRequestDiaglog, setAddCreditRequestDiaglog] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const [viewDetailsDialog, setViewDetailsDialog] = useState(false);
    const [creditRequestDetail, setCreditRequestDetail] = useState({});

    const [showAll, setShowAll] = useState(false);

    const fetchCreditRequests = async () => {
        let payload = {
            credit_requests: isClientRequest ? 1 : 0,
            per_page: pageSize,
            page: currentPage,
            sort_by: sortField,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
            search_text: keyword,
        };

        if (showAll) payload["show_all"] = 1;

        setLoading(true);
        try {
            let res = await getCreditRequests(payload);

            if (res.status) {
                if (res.data && res.data.crs) {
                    setResponseData(res.data.crs.data);
                    setPagination({
                        ...pagination,
                        totalRecords: res.data.crs.total,
                        totalPages: res.data.crs.last_page,
                        isLastPage: res.data.crs.last_page,
                    });
                }

                setLoading(false);
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const approveDenyCR = async (status) => {
        const ids = selectedCreditRequests.map((item) => item["id"]);
        let payload = {
            ids: ids,
            status: status,
        };

        setKebabMenuItemLoader(true);
        try {
            let res = await changeCreditRequestStatus(payload);
            if (res && res.data) {
                if (res.data.status) {
                    let msg = isClientRequest ? "Client" : "Debtor";
                    msg += " Credit Request ";

                    msg += payload.status === 1 ? "Approved" : "Denied";
                    toast.showMessage(msg, res.data.message, "success");
                    fetchCreditRequests();
                    setSelectedCreditRequests([]);
                } else {
                    toast.showMessage("Error", res.data.message, "error");
                }
            }
            setKebabMenuItemLoader(false);
        } catch (e) {
            toast.showMessage("Info", "Sorry, we are unable to process your request at this time.", "error");
            setKebabMenuItemLoader(false);
        }
    };

    const kebabMenuItemClick = (status) => {
        if (status === 2) {
            approveDenyCR(1);
        } else if (status === 3) {
            approveDenyCR(2);
        }
    };

    const confirmHandler = () => {
        dispatch(onCreditRequestFormDirty(false));
        setIsOpening(false);
        setAddCreditRequestDiaglog(false);
    };

    const handleCloseAddCreditRequestDialog = (refreshCR = false) => {
        if (refreshCR) {
            setAddCreditRequestDiaglog(false);
            fetchCreditRequests();
        } else {
            //cancel button is clicked, so check if is there any change in form.
            if (screens.cr.isFormDirty) {
                setIsOpening(true);
            } else {
                dispatch(onCreditRequestFormDirty(false));
                setAddCreditRequestDiaglog(false);
            }
        }
    };

    const handleOpenAddCreditRequestDialog = () => {
        setAddCreditRequestDiaglog(true);
    };

    const handleCloseViewDetailDialog = (refreshCR = false) => {
        setViewDetailsDialog(false);
    };

    const handleOpenViewDetailDialog = (data) => {
        //fetch credit request
        setCreditRequestDetail(data);

        setViewDetailsDialog(true);
    };

    useEffect(() => {
        fetchCreditRequests();
    }, [showAll, currentPage, pageSize, sortField, sortOrder]);

    useEffect(() => {
        let status = null;
        setKebabMenuItemLoader(false);
        selectedCreditRequests.map((item) => {
            if ((status && status !== item.status) || item.status === "Approved" || item.status === "Denied") {
                setKebabMenuItemLoader(true);
            } else {
                status = item.status;
            }
        });
    }, [selectedCreditRequests]);

    useEffect(() => {
        if (keyword) {
            const delayDebounceFn = setTimeout(() => {
                fetchCreditRequests();
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        } else {
            fetchCreditRequests();
        }
    }, [keyword]);

    useEffect(() => {
        if (user) {
            // Kebab Menu Items
            const _kebab_menu_items = CREDIT_REQUEST_KEBAB_MENU.filter((col) => {
                const isAllowed = validateUserPermissions(isClientRequest ? col.client_required_rights : col.debtor_required_rights, user.rights);
                if (isAllowed) return col;
            });
            setKebabMenuItems(_kebab_menu_items);
        }
    }, [user]);

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            setSelectedColumns(CLIENT_CREDIT_GRID_HEADERS_FOR_CLIENT);
        }
    }, []);

    return (
        <div className="grid">
            <FilterBar
                isClientRequest={isClientRequest}
                setKeyword={setKeyword}
                keyword={keyword}
                setSelectedColumns={setSelectedColumns}
                gridColumns={gridColumns}
                selectedCreditRequests={selectedCreditRequests}
                kebabMenuItems={kebabMenuItems}
                kebabMenuItemClick={kebabMenuItemClick}
                kebabMenuItemLoader={kebabMenuItemLoader}
                handleOpenAddCreditRequestDialog={handleOpenAddCreditRequestDialog}
                showAll={showAll}
                setShowAll={setShowAll}
            />
            <Body
                isClientRequest={isClientRequest}
                responseData={responseData}
                selectedCreditRequests={selectedCreditRequests}
                setSelectedCreditRequests={setSelectedCreditRequests}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                setPage={setPage}
                page={page}
                handleOpenViewDetailDialog={handleOpenViewDetailDialog}
            />

            {validateSomeUserPermissions([CREDIT_REQUEST_RIGHTS.client.add_new_request, CREDIT_REQUEST_RIGHTS.debtor.add_new_request], user?.rights) && (
                <Dialog
                    blockScroll={true}
                    header={`Add New Request`}
                    visible={addCreditRequestDiaglog}
                    onHide={handleCloseAddCreditRequestDialog}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "60vw" }}
                >
                    <AddNewCreditRequest isClientRequest={isClientRequest} onHide={handleCloseAddCreditRequestDialog} setIsOpening={setIsOpening} />
                </Dialog>
            )}

            {isOpening && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpening(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            <Dialog
                header={`${isClientRequest ? "Client" : "Debtor"} Credit Request`}
                visible={viewDetailsDialog}
                onHide={handleCloseViewDetailDialog}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
                blockScroll={true}
            >
                <ViewCreditRequestDetail isClientRequest={isClientRequest} onHide={handleCloseViewDetailDialog} data={creditRequestDetail} />
            </Dialog>
        </div>
    );
};

export default CreditRequest;
