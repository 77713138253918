import React, { useContext, useEffect, useState } from "react";

//styles

import "./createrrbatch.scss";

//components

import ChargeBackTable from "./ChargeBackTable";

import ReserveReleaseTable from "./ReserveReleaseTable";

import { BreadCrumb } from "primereact/breadcrumb";

import { useSelector } from "react-redux";

import { ToastContext } from "../../../../../../context/toast";

import { getPercentAr, getPreviousProspectReserves, getProspectAvailableEscrows, getRRBatch, saveRRBatch } from "../../../../../../services/reserve";

import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";

import { InputNumber } from "primereact/inputnumber";

import CustomInputField from "../../../../../../ui/CustomInputField/CustomInputField";

import * as Yup from "yup";

import { useFormik } from "formik";

import { getCustomDropDown } from "../../../../../../services/dropdown";

import { useQuery } from "../../../../../../utils/HelperFuctions/UseQuery";

import { currencyFormatUSD } from "../../../../../../utils/HelperFuctions/checkAmount";

import { Dialog } from "primereact/dialog";

import ReserveBreakdown from "./ReserveBreakdown";

import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";

import { useHistory, useLocation } from "react-router-dom";

import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const validationSchema = Yup.object().shape({
    reserve_release_date: Yup.string().required("Reserve Release Date  is required").nullable(),

    payment_type: Yup.string().required("Payment Type is required"),

    custom_amount: Yup.string().required("Total Amount with Previous Reserves and Escrow is required").nullable(),

    excrow_amount: Yup.string().nullable(),

    previous_reserves: Yup.string().nullable(),

    cb_amount: Yup.string().nullable(),

    released_amount: Yup.number().min(1, "Released Amount is required").required("Released Amount is required"),
});

const CreateRRBatch = () => {
    const location = useLocation();

    let prospectId = null;
    let batchIds = null;
    if (location.state !== undefined) {
        prospectId = location.state?.prospectId;
        batchIds = location.state?.id;
    }

    // console.log("Batch ids"+batchIds);
    // console.log("prospect_id"+prospectId);

    const toast = useContext(ToastContext);

    const history = useHistory();

    const home = { icon: "bi bi-arrow-repeat" };

    const [batch, setBatch] = useState([]);

    const [chargebacks, setChargebacks] = useState([]);

    const [wireFee, setWireFee] = useState(0);

    const [loading, setLoading] = useState(false);

    const [paymentTypes, setPaymentTypes] = useState([]);

    const [dropdownsLoading, setDropdownLoading] = useState(false);

    const [percentAr, setPercentAr] = useState("");

    const [availableEscrows, setAvaialbleEscrows] = useState("");

    const [totalBatchAmount, setTotalBatchAmount] = useState("");

    const [disablePost, setDisablePost] = useState(false);

    const [extraEscrowModal, setExtraEscrowModal] = useState(false);

    const [escrowData, setEscrowData] = useState([]);

    const [selectedChargebacks, setSelectedChargebacks] = useState([]);

    const [selectedEscrows, setSelectedEscrows] = useState([]);

    const [cb, setCb] = useState("");

    const [cbLoading, setCbLoading] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        validationSchema: validationSchema,

        initialValues: {
            reserve_release_date: null,

            payment_type: "",

            custom_amount: 0,

            // escrow_amount: 0,

            previous_reserves: 0,

            cb_amount: 0,

            released_amount: 0,
        },

        onSubmit: async (data) => {
            let { reserve_release_date, ...rest } = data;

            let payload = {
                ...rest,

                reserve_release_date: getDateFormat(reserve_release_date),

                prospect_id: prospectId,

                invoices: batchIds.split(",").map(Number),
            };

            if (data.cb_amount > 0) {
                payload.charge_backs = selectedChargebacks.map((item) => item.id);
            }

            payload.escrows = selectedEscrows.map((item) => item.id);

            setIsSaving(true);
            setIsLoading(true);
            try {
                let res = await saveRRBatch(payload);

                if (res?.data?.status) {
                    toast.createdToast("Reserves Released");

                    history.push("/reserves");
                } else {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }

                setIsSaving(false);
                setIsLoading(false);
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
                setIsLoading(false);
            }
        },
    });

    const fetchRRbatch = async () => {
        let payload = {
            id: batchIds.split(",").map(Number),
        };
        setLoading(true);
        try {
            let res = await getRRBatch(payload);

            if (res?.data?.status) {
                setBatch(res.data.batch);
                setChargebacks(res.data.chargebacks);
                setWireFee(res.data.wire_fee);
                let released_amount = 0;
                res.data.batch.forEach((item) => {
                    released_amount += parseFloat(item.release_amount);
                    if (item.hold_account_status === 1) {
                        released_amount += item.uc;
                    }
                });
                setTotalBatchAmount(released_amount);
                formik.setFieldValue("custom_amount", released_amount);
                formik.setFieldValue("amount", released_amount);
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");

            setLoading(false);
        }
    };

    const fetchPaymentTypes = async () => {
        let payload = {
            select: ["payment_type"],
        };
        setDropdownLoading(true);
        try {
            const res = await getCustomDropDown(payload);
            if (res.data.status) {
                const types = JSON.parse(res.data.dropdowns.payment_type);
                setPaymentTypes(types);
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setDropdownLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            setDropdownLoading(false);
        }
    };

    const fetchPreviousProspectReserves = async () => {
        let payload = {
            prospect_id: prospectId,
        };
        try {
            let res = await getPreviousProspectReserves(payload);
            if (res?.data?.status) {
                formik.values.previous_reserves = res.data.amount;
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchPaymentId = (txt) => {
        let id = false;
        if (paymentTypes?.length) {
            paymentTypes.filter((item) => {
                if (item.name.toLowerCase().indexOf(txt.toLowerCase()) !== -1) {
                    id = item.value;
                }
            });
        }

        return id;
    };

    const calculateReleasedAmount = () => {
        let previous_reserves = formik.values.previous_reserves !== undefined ? formik.values.previous_reserves : 0;
        let custom_amount = formik.values.custom_amount !== undefined ? formik.values.custom_amount : 0;
        let wire_fee = formik.values.wire_fee !== undefined ? formik.values.wire_fee : 0;
        let cb_amount = formik.values.cb_amount !== undefined ? formik.values.cb_amount : 0;
        let released = previous_reserves + custom_amount - wire_fee - cb_amount;
        if (released < 0) {
            released = 0;
        }
        formik.values.released_amount = released;
        formik.setFieldValue("released_amount", released);
    };

    const customAmountChangeHandler = (e) => {
        formik.values.custom_amount = e.value;
        formik.setFieldValue("custom_amount", e.value);
    };

    const isWire = (paymentId) => {
        if (paymentId === fetchPaymentId("wire")) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (batchIds) {
            fetchRRbatch();
        }
    }, [batchIds]);

    useEffect(() => {
        if (paymentTypes?.length == 0) {
            fetchPaymentTypes();
            fetchPreviousProspectReserves();
        }
    }, []);

    useEffect(() => {
        if (isWire(formik.values.payment_type)) {
            formik.values.wire_fee = wireFee;
        } else {
            formik.values.wire_fee = 0;
        }
    }, [formik.values.payment_type]);

    useEffect(() => {
        calculateReleasedAmount();

        if (formik.values.custom_amount > 0 && formik.values.custom_amount > totalBatchAmount) {
            setDisablePost(true);
        } else {
            setDisablePost(false);
        }
    }, [formik.values.wire_fee, formik.values.custom_amount, formik.values.cb_amount, formik.values.escrow_amount]);

    useEffect(() => {
        if (availableEscrows > 0 && percentAr > 0 && availableEscrows > percentAr) {
            formik.setFieldValue("escrow_amount", availableEscrows - percentAr);
        } else {
            formik.values.escrow_amount = 0;
        }
    }, [availableEscrows, percentAr]);

    useEffect(() => {
        if (cb > 0) {
            formik.setFieldValue("cb_amount", cb);
        } else {
            formik.setFieldValue("cb_amount", 0);
        }
    }, [cb]);

    const handleCancel = () => {
        if (formik.values.reserve_release_date !== null || formik.values.payment_type !== "") {
            setIsFormDirty(true);
        } else {
            history.push("/reserves");
        }
    };
    const confirmHandler = () => {
        history.push("/reserves");
    };
    const items = [{ label: <span onClick={handleCancel}>Reserves</span> }, { label: "Create RR Batch" }];
    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div>
                    <div className="">
                        <BreadCrumb model={items} home={home} />
                    </div>
                    <div className="card">
                        <div className="grid">
                            <div className="md:col-12">
                                <ReserveReleaseTable batch={batch} loading={loading} />
                            </div>

                            {chargebacks?.length > 0 && (
                                <div className="md:col-12">
                                    <ChargeBackTable
                                        chargebacks={chargebacks}
                                        selectedChargebacks={selectedChargebacks}
                                        setSelectedChargebacks={setSelectedChargebacks}
                                        setCb={setCb}
                                        setCbLoading={setCbLoading}
                                    />
                                </div>
                            )}
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid mt-5">
                                <CustomInputField
                                    className={`col-12 md:col-4`}
                                    iden="reserve_release_date"
                                    label="Reserve Release Date"
                                    type="calendar"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="mm/dd/yyyy"
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />

                                <CustomInputField
                                    className={`col-12 md:col-4 ${dropdownsLoading && "no-drop"}`}
                                    options={paymentTypes}
                                    optionLabel="name"
                                    optionValue="value"
                                    iden="payment_type"
                                    label="Payment Type"
                                    formik={formik}
                                    type="dropdown"
                                    placeHolder="Select"
                                    mandatory="*"
                                    disabled={dropdownsLoading}
                                />

                                {isWire(formik.values.payment_type) && (
                                    <CustomInputField className={`col-12 md:col-4 no-drop}`} iden="wire_fee" label="Wire Fee" formik={formik} type="currency" disabled={true} />
                                )}

                                <CustomInputField className={`col-12 md:col-4 no-drop}`} iden="previous_reserves" label="Previous Reserves" formik={formik} type="currency" disabled={true} />
                                {formik.values.escrow_amount > 0 && (
                                    <div className="input_position mt-5">
                                        <div className="icon_inner_input">
                                            <span onClick={() => setExtraEscrowModal(true)} className="tooltip">
                                                <i class="bi bi-question-circle"></i>

                                                <span class="tooltiptext">View Breakdown</span>
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <CustomInputField
                                    className={`col-12 md:col-4 no-drop}`}
                                    iden="custom_amount"
                                    label="Total Amount with Previous Reserves and Escrow"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    max={99999999.99}
                                    onValueChange={(e) => customAmountChangeHandler(e)}
                                />

                                {selectedChargebacks?.length > 0 && (
                                    <>
                                        <CustomInputField className={`col-12 md:col-4 no-drop`} iden="cb_amount" label="CB Amount Deducted" formik={formik} type="currency" disabled={true} />
                                    </>
                                )}

                                <CustomInputField
                                    className={`col-12 md:col-4 no-drop`}
                                    iden="released_amount"
                                    label="Amount to be Released"
                                    formik={formik}
                                    type="currency"
                                    disabled="true"
                                    mandatory="*"
                                />

                                {disablePost && (
                                    <div className="md:col-12">
                                        <small className="p-error mt-3 pl-2">
                                            Note: Total Amount with Previous Reserves and Escrow should be either equal or less than batch total amount {currencyFormatUSD(totalBatchAmount)}
                                        </small>
                                    </div>
                                )}

                                <div className="col-12 text-center mt-3">
                                    <CustomAddNewButton
                                        id="submit"
                                        type="submit"
                                        title="Post Reserve Release"
                                        className="width_post_reserve"
                                        isDisabled={disablePost || loading || cbLoading || isSaving}
                                        isLoading={isLoading}
                                    ></CustomAddNewButton>
                                </div>
                            </div>
                        </form>
                    </div>

                    <Dialog
                        blockScroll={true}
                        visible={extraEscrowModal}
                        onHide={() => setExtraEscrowModal(false)}
                        header="Reserve Breakdown"
                        breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                        style={{ width: "60vw" }}
                    >
                        <ReserveBreakdown
                            onHide={() => setExtraEscrowModal(false)}
                            escrowData={escrowData}
                            selectedEscrows={selectedEscrows}
                            setSelectedEscrows={setSelectedEscrows}
                            extraEscrows={formik.values.escrow_amount}
                        />
                    </Dialog>
                </div>
            )}
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default CreateRRBatch;
