export function saveStorageParam(route, value) {
    localStorage.setItem("param", (route +"-"+ (value ^ 102255555)));
}

export function getStorageParam() {
    let param = localStorage.getItem("param");
    if (param) {
        param = param.split("-");
        if (param.length > 0) {
            return param[1] ^ 102255555;
        }
    }
    return null;
}
