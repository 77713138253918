import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useRef } from "react";
import { currencyFormat } from "../../../../../utils/HelperFuctions/checkAmount";

const PendingCollection = (props) => {
    const { response } = props;
    const dt = useRef(null);

    const handleCollectionTemplate = (rowData) => {
        if (rowData.total_collection === null) {
            return "$0.00";
        } else if (rowData.total_collection) {
            return currencyFormat(rowData.total_collection);
        }
    };

    const handlePostingTemplate = (rowData) => {
        if (rowData.max_posting_date === null) {
            return "Unidentified";
        } else if (rowData.max_posting_date) {
            return rowData.max_posting_date;
        }
    };
    const handlePendingTemplate = (rowData) => {
        if (rowData.pending_amount === null || rowData.pending_amount === 0) {
            return "$0.00";
        } else if (rowData.pending_amount) {
            return currencyFormat(rowData.pending_amount);
        }
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3">
                    <DataTable ref={dt} value={response} emptyMessage="No record available.">
                        <Column field="legal_name" header="Debtor Name"></Column>
                        <Column field="invoice_no" header="Invoice No"></Column>
                        <Column body={handleCollectionTemplate} header="Collection Amount"></Column>
                        <Column body={handlePendingTemplate} header="Pending Amount"></Column>
                        <Column body={handlePostingTemplate} header="Posting Date"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default PendingCollection;
