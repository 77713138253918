import React, { useRef, useState } from "react";

// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
//Components
import CustomLoading from "../../../../../../../ui/CustomSpinner/custom_spinner";
// Helper Function
import { getColumnBody } from "../../../../../../../utils/HelperFuctions/supportHelper";
import { PaginatorTemplate } from "../../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { currencyFormatUSD } from "../../../../../../../utils/HelperFuctions/checkAmount";
import { getDateFormat } from "../../../../../../../utils/HelperFuctions/dateFormat";

// icons
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";

// Components
const Body = (props) => {
    const {
        selectedHeaders,
        selectedUsers,
        setSelectedUsers,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        kebabMenuOption,
        setKebabMenuOption,
        setPage,
        page,
    } = props;

    //Use ref
    const ref = useRef(null);

    // States
    // const [page, setPage] = useState(currentPage);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [detaildialogVisible, setDetailDialogVisible] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const toggleRowExpansion = (rowData) => {
        if (expandedRows.includes(rowData)) {
            setExpandedRows(expandedRows.filter((row) => row !== rowData));
        } else {
            setExpandedRows([rowData]);
        }
    };

    const customExpansionButton = (rowData) => {
        const isRowExpanded = expandedRows?.length > 0 && expandedRows?.includes(rowData);
        return (
            <div className="flex align-items-center justify-content-between">
                <span>{rowData.collection_id}</span>
                <span className="flex mr-5" onClick={() => toggleRowExpansion(rowData)}>
                    {isRowExpanded ? <MdOutlineExpandLess size={20} /> : <MdOutlineExpandMore size={20} />}
                </span>
            </div>
        );
    };

    const rowExpansionTemplate = (rowData) => {
        const invoiceAmountBodyTemplate = (rowData) => {
            return (
                <div>
                    <div className="amount_column_alignment amount_col_width">{rowData?.invoice_amount ? currencyFormatUSD(rowData?.invoice_amount) : currencyFormatUSD(0)}</div>
                </div>
            );
        };

        const invoiceDateBodyTemplate = (rowData) => {
            return <div>{rowData?.invoice_date ? getDateFormat(rowData?.invoice_date) : ""}</div>;
        };

        const fundingDateBodyTemplate = (rowData) => {
            return <div>{rowData?.funding_date ? getDateFormat(rowData?.funding_date) : ""}</div>;
        };

        const data = JSON.parse(rowData?.invoices);
        return (
            <div className="p-3">
                <DataTable value={data}>
                    <Column key="invoice_no" field="invoice_no" header="Invoice No" />
                    <Column key="invoice_date" field="invoice_date" header="Invoice Date" body={invoiceDateBodyTemplate} />
                    <Column key="funding_date" field="funding_date" header="Funding Date" body={fundingDateBodyTemplate} />
                    <Column key="invoice_amount" field="invoice_amount" header="Invoice Amount" body={invoiceAmountBodyTemplate} headerClassName="amount_column_alignment amount_col_width_header" />
                </DataTable>
            </div>
        );
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedUsers(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        // Row Selection
        if (event.type === "row") {
            setSelectedRowId(event.data.id);
            setDetailDialogVisible(true);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = selectedColumns.map((col) => {
        if (col.field === "collection_id") {
            return (
                <Column key={col.field} field={col.field} header={col.header} body={customExpansionButton} sortable headerClassName={`${col.field === "amount" ? "amount_column_alignment" : ""}`}>
                    {renderSortIcon(col.field)}
                </Column>
            );
        } else {
            return (
                <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable headerClassName={`${col.field === "amount" ? "amount_column_alignment" : ""}`}>
                    {renderSortIcon(col.field)}
                </Column>
            );
        }
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };
    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 pr-0">
                    <div className="card p-1 m-0">
                        <DataTable
                            ref={ref}
                            value={selectedHeaders}
                            selection={selectedUsers.length === 0 ? null : selectedUsers}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            )}
        </>
    );
};

export default Body;
