import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

//styles
import "../../../userManagement.scss";

//primereact
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";

//Headr component
import Header from "../Header/Header";

// Context & APIs
import { ToastContext } from "../../../../../context/toast";
import { getUserGrid, setUserStatus } from "../../../../../services/user-management/user";

//ui Components
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import SearchInputComponent from "../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../../ui/KebabMenu/KebabMenuComponent";
import { PaginatorTemplate } from "../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { USERMANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";
import { USERMANAGMENT_KEBAB_MENU, getContactMask } from "../../../../../utils/Constants/global";

const UserIndex = () => {
    // Context
    const toastContext = useContext(ToastContext);

    // Hooks
    const history = useHistory();

    const columns = [
        { field: "first_Name", header: "Full Name" },
        { field: "email", header: "Email" },
        { field: "user_Name", header: "Username" },
        { field: "contact", header: "Phone No" },
        { field: "role_Name", header: "Role" },
        { field: "application_Status", header: "Status" },
    ];

    // Ref
    const dt = useRef(null);

    //states
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);
    const [dialogVisibleStatus, setDialogVisibleStatus] = useState(false);

    /////////////////////////////////////// PAGINATION REQUIRED DATA START ////////////////////////////////////////////////////
    // API response Data
    const [responseData, setReponseData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [page, setPage] = useState(currentPage);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    const [kebab_menu_items, setKebabMenuItems] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    /////////////////////////////////////// PAGINATION REQUIRED DATA END ////////////////////////////////////////////////////
    const handleColumn = (data) => {
        setSelectedColumns(data);
    };

    const getColumnBody = (colField) => (rowData) => {
        if (colField === "first_Name") {
            return (rowData.last_Name ? rowData.last_Name : "") + " " + (rowData.first_Name ? rowData.first_Name : "");
        }
        if (colField === "application_Status") {
            return rowData.application_Status ? "Active" : "Inactive";
        } else if (colField === "contact") {
            rowData.contact = rowData.contact?.replace("-", "");
            let phone_mask = getContactMask("", rowData.contact);
            return phone_mask;
        }

        return rowData[colField] ? rowData[colField] : "";
    };

    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable={col.sortable} />;
    });

    //properties
    const changeUserStatus = async () => {
        try {
            setDialogVisibleStatus(false);
            let data = selectedUsers[0];
            let oldStatus = data.application_Status;
            let resp = await setUserStatus({
                sso_id: data.user_Id,
                status: !data.application_Status,
            });
            setSelectedUsers([]);
            if (resp.data.status) {
                toastContext.updateToast("Profile Status");
                getUsers();
            } else {
                toastContext.showMessage("Error", resp.data.message, "error");
            }
        } catch (error) {}
    };

    const handleOpenMenuItems = (status) => {
        if (status === 1) {
            setDialogVisibleStatus(true);
        }
    };

    const handleRowSelect = (event) => {
        if (!validateUserPermissions([USERMANAGMENT_RIGHTS.user_profile_selection], user?.rights)) return;

        if (event.type === "row") {
            history.push(`/addnewuser`, { user: event?.data });
        }
    };

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedUsers(event.value);
        }
    };

    const getUsers = async () => {
        setLoading(true);
        const dto = {
            pageNumber: currentPage,
            size: pageSize,
            search: search,
            status: showAll ? null : true,
            isLogin: false,
        };
        let resp = await getUserGrid(dto);

        setReponseData(resp.data);
        setLoading(false);
        setIsLoaded(true);
    };

    const getRowClassName = (rowData) => {
        if (!rowData.application_Status) {
            return "inactive_row_color"; // delete_row_color
        }
        return "";
    };

    //useEffect
    useEffect(() => {
        setTimeout(() => setLoading(false), 1000); // simulate data loading for 2 seconds
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        // Kebab Menu
        const _kebab_items = USERMANAGMENT_KEBAB_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);
            if (isAllowed) {
                return col;
            }
        });
        setKebabMenuItems(_kebab_items);
    }, [user]);

    useEffect(() => {
        getUsers();
    }, [currentPage, pageSize, showAll]);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getUsers();
                }
            }, 350);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);

    return (
        <>
            {dialogVisibleStatus && (
                <CustomConfirmDialog
                    header="Change Status"
                    type="error"
                    onHide={() => setDialogVisibleStatus(false)}
                    handler={changeUserStatus}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure you want to change status?"
                />
            )}
            <div className="grid">
                <div className="col-12 md:col-11">
                    <h4 className="pt-2">
                        <b>User Profiles</b>
                    </h4>
                </div>
            </div>
            <div className="grid">
                <div className="md:col-6 col-12">
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll} onChange={(e) => setShowAll(e.checked)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include inactive user profiles
                        </label>
                    </div>
                </div>
                <div className="md:col-6 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent value={search === null ? "" : search} setKeyword={setSearch} placeholder="Search by full name, email & username" />
                        <div className="ml-2">
                            <ColumnFilterComponent items={columns} handleColumnFilter={handleColumn} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedUsers.length == 1 ? false : true} />}
                        </div>
                        <div className="ml-2">
                            <Header />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {loading ? (
                        <CustomLoading />
                    ) : (
                        <DataTable
                            ref={dt}
                            value={responseData?.records ?? []}
                            selection={selectedUsers.length === 0 ? null : selectedUsers}
                            onSelectionChange={handleSelectionChange} // for checkbox
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                responseData?.isFirstPage ? 1 : 0,
                                responseData?.totalPages ?? 0,
                                currentPage,
                                responseData?.totalPages ?? 0,
                                pageSize,
                                page,
                                responseData?.totalRecords ?? 0,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                            {/* <Column body={(rowData, rowIndex) => actionTempate(rowData, rowIndex)} header="Status"></Column> */}
                        </DataTable>
                    )}
                </div>
            </div>
        </>
    );
};

export default UserIndex;
