import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function getCollections(data = null) {
    return postData(appUrl["collections"].fetch, data);
}
export async function getCollectionInvoices(data = null) {
    return postData(appUrl["collections"].fetchCollectionInvoices, data);
}

export async function saveCollection(data = null) {
    return postData(appUrl["collections"].saveCollection, data);
}

export async function getDebtorProspectUC(data = null) {
    return postData(appUrl["collections"].debtorProspectUC, data);
}

export async function postNote(data = null) {
    return postData(appUrl["collections"].saveNote, data);
}

export async function getNotes(data = null) {
    return postData(appUrl["collections"].fetchNotes, data);
}

export async function getMenuInvoices(data = null) {
    return postData(appUrl["collections"].fetchCollections, data);
}

export async function postUCNonInvoice(data = null) {
    return postData(appUrl["collections"].saveUCNonInvoice, data);
}

export async function getUCNonInvoice(data = null) {
    return postData(appUrl["collections"].fetchUCNonInvoices, data);
}

export async function getReturnUC(data = null) {
    return postData(appUrl["collections"].fetchReturnUC, data);
}

export async function saveReturnUC(data = null) {
    return postData(appUrl["collections"].saveReturnUC, data);
}
