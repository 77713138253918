import React from "react";
import { useHistory } from "react-router-dom";

//Components
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

const Header = (props) => {
    // Hooks
    const history = useHistory();

    // Redux
    const {user} = useSelector(loginState)

    const handleNavigate = () => {
        history.push("/addnewdebtor");
    };

    return validateUserPermissions([MANAGMENT_RIGHTS.debtors.add_new], user?.rights ?? []) && <CustomAddNewButton type="button" title="Add New Debtor" onClick={handleNavigate} />;
};

export default Header;
