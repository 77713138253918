import { postData } from "../../services/genaric/genaric-api";

export async function openAttachment(blobName) {
    try {
        const response = await postData(
            "blob/fetch",
            {
                blobName,
            },
            true,
            "blob"
        );

        if (response.status !== 200) {
            throw new Error("Network response was not ok");
        }

        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
    } catch (error) {
        console.error("Error downloading blob:", error);
    }
}
