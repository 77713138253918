import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function getCreditRequests(data = null) {
    return postData(appUrl["cr"].fetch, data);
}

export async function saveCreditRequest(data) {
    return postData(appUrl["cr"].save, data);
}

export async function fetchCreditLimit(data) {
    return postData(appUrl['cr'].fetchCL, data);
}

export async function changeCreditRequestStatus(data) {
    return postData(appUrl['cr'].changeStatus, data);
}