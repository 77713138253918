import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";
import { onProspectDataMutated } from "../../../../../redux/main-view";

//Components
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomDropdownField from "../../../../../ui/CustomDropdownField/CustomDropdownField";

// Context
import { ToastContext } from "../../../../../context/toast";

// API'S
import { changeProspectStatus, fetchClientAdvocates, postUnderWritingDocument } from "../../../../../services/prospect";
import { getAssignUser } from "../../../../../services/support";
import { getUserAssign, getUserGrid } from "../../../../../services/user-management/user";
import { FILE_MAX_SIZE, ROLE_NAME } from "../../../../../utils/Constants/global";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import { fetchOwners } from "../../../../../services/term-sheet";
import { countW9Attachment, postW9Attachment, sendw9Form } from "../../../../../services/client";
import { BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import { Button } from "primereact/button";

let validationSchema = Yup.object().shape({
    owner_id: Yup.string("Business Owners is required"),
});

const SendW9Form = (props) => {
    const { onHide, prospectId } = props;
    // states
    const [uploadedFile, setUploadedFile] = useState([]);
    const [totalFiles, setTotalFiles] = useState(0);
    const [disabledBtn, setDisabledBtn] = useState(false);

    // Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // States
    const [isSaving, setIsSaving] = useState(false);
    const [owners, setOwners] = useState([]);

    // View States
    const [isLoading, setIsLoading] = useState(false);
    const [ownerLoading, setOwnerLoading] = useState(false);

    // const formik = useFormik({
    //     validationSchema: validationSchema,
    //     initialValues: {
    //         owner_id: [],
    //     },
    //     onSubmit: async (data) => {
    //         setIsSaving(true);
    //         try {
    //             const res = await sendw9Form(data);

    //             if (res) {
    //                 if (res.data.status) {
    //                     toastContext.showMessage("W9 Form", res.data.message, "success");
    //                     onHide();
    //                 } else {
    //                     toastContext.showMessage("W9 Form", res.data.message, "danger");
    //                 }
    //             } else {
    //                 setIsSaving(false);
    //                 toastContext.showMessage("Error", "Server Error.", "error");
    //             }
    //         } catch (e) {
    //             toastContext.showMessage("Error", "Server Error.", "error");
    //             onHide();
    //         }
    //         setIsSaving(false);
    //     },
    // });

    const handleSave = async () => {
        setDisabledBtn(true);
        if (uploadedFile.length === 0) {
            toast.showMessage("Error", "Please upload atleast one attachment.", "error");
            setDisabledBtn(false);
            return;
        }
        try {
            let data = {
                attachment_path: uploadedFile,
            };
            const formData = new FormData();
            formData.append("prospect_id", prospectId);
            data.attachment_path.forEach((file) => {
                formData.append(`attachments[]`, file);
            });
            const res = await postW9Attachment(formData);
            if (res.status) {
                toast.showMessage("Attachment(s)", "W9 Form has been uploaded successfully.", "success");
                onHide();
            }
        } catch {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDisabledBtn(false);
    };

    // UseEffects
    useEffect(() => {
        // getOwners();
        handleCountDocuments();
    }, []);

    const getOwners = async () => {
        setOwnerLoading(true);
        let form = {
            prospect_id: prospectId,
        };
        let res = await fetchOwners(form);
        if (res?.data.status) {
            setOwners(res.data.owners);
        }
        setOwnerLoading(false);

        return;
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        let upload = [];
        if (uploadedFile.length !== 5 && 5 - parseInt(uploadedFile.length) >= parseInt(files.length) && 5 - parseInt(totalFiles) >= parseInt(files.length)) {
            if (files.length > 0 && files.length <= 5) {
                upload = [...uploadedFile];
                for (let i = 0; i < files.length; i++) {
                    const fileType = files[i].name.split(".");
                    const index = fileType.length - 1;
                    const accept = ".pdf";
                    const check_file = accept.includes(fileType[index]);
                    if (check_file) {
                        const fileSize = files[i].size > FILE_MAX_SIZE.FILE_SIZE; // File should not be greater than 3 MB..
                        if (fileSize) {
                            toast.showMessage("Exceeding Limit", `${files[i]?.name} size is greater than allowed size.`, "error");
                        } else if (files[i].name.includes("#") || files[i].name.includes("&")) {
                            toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                        } else {
                            upload.push(files[i]);
                        }
                    } else {
                        toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                    }
                }
                setUploadedFile(upload);
            }
        } else {
            toast.showMessage("Exceeding Limit", "You are only authorized to upload maximum 5 files.", "error");
        }

        event.target.value = null;
    };
    const handleRemove = (index) => {
        const updatedAttachments = uploadedFile.filter((_, i) => i !== index);
        setUploadedFile(updatedAttachments);
    };

    const handleCountDocuments = async (type) => {
        setDisabledBtn(true);
        try {
            let data = {
                prospect_id: prospectId,
            };
            const res = await countW9Attachment(data);
            if (res.data.status) {
                setTotalFiles(res.data.count);
            }
        } catch {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDisabledBtn(false);
    };

    return (
        <>
            <div className="grid">
                {/* <CustomInputField
                        className="col-12 md:col-12"
                        formik={formik}
                        iden="owner_id"
                        label="Business Owners"
                        disabled={ownerLoading}
                        type="dropdown"
                        mandatory="*"
                        optionLabel="name"
                        optionValue="value"
                        options={owners}
                        placeHolder="Select"
                    /> */}
                <div className="col-12">
                    <label htmlFor="NameTitle">
                        <b>Attachments</b>
                        <span className="clr_red">*</span>
                        <Button
                            type="button"
                            tooltip=".pdf files only (max size 3MB) "
                            tooltipOptions={{ position: "bottom" }}
                            icon={<BsInfoCircle />}
                            aria-label="Submit"
                            className="customTooltipicon"
                        />
                    </label>
                    <div className="file_upload_class">
                        <label htmlFor="file-upload" className={`flex flex-row justify-content-between align-items-center ${disabledBtn ? "disabled_choose_file_input" : ""}`}>
                            Choose File <BsPaperclip />
                        </label>
                    </div>
                    <input type="file" id="file-upload" multiple onChange={handleFileUpload} accept=".pdf" style={{ display: "none" }} />
                    <div className="col-12">
                        <div>
                            {uploadedFile?.map((file, index) => (
                                <div key={index} className="image-item">
                                    <div>
                                        <div className="flex row" key={index}>
                                            <span className="text_break">{file?.name}</span>
                                            <BsXLg className="cursor-pointer cross_icon ml-1" onClick={() => handleRemove(index)} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 text-right pt-5">
                        <CustomAddNewButton className={isSaving ? "no-drop" : ""} title="Save" isDisabled={disabledBtn} onClick={() => handleSave()} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SendW9Form;
