import React, { useContext, useEffect, useState } from "react";

// Prime React
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Accordion, AccordionTab } from "primereact/accordion";

// Icons
import { BsCheck, BsPencil, BsPlus, BsTrash3 } from "react-icons/bs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onClientDataChange, onClientSupplierFormDirty, onFormDirty } from "../../../../../../../redux/main-view";

// Helper Function
import formDirtyHandler from "../../../../../../../utils/HelperFuctions/clientHelper";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../../../../utils/HelperFuctions/cleanData";

// UI Components
import CustomCardSpinner from "../../../../../../../ui/CustomCardSpinner/CustomCardSpinner";

// Context
import { ToastContext } from "../../../../../../../context/toast";

// API'S
import { saveProspect, supplierDelete } from "../../../../../../../services/prospect";

const SupplierInformation = () => {
    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // State
    const [checked, setChecked] = useState([]);
    const [activeTabs, setActiveTabs] = useState([]);
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [formArray, setFormArray] = useState([]);
    // const [formArray, setFormArray] = useState([{ phone: "", name: "", products_supplied: "", country_phone_code: "+1" }]);

    // View State
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    // Handlers
    const handleInputChange = (e, index) => {
        dispatch(onFormDirty(true));
        dispatch(onClientSupplierFormDirty(true));
        const { name, value } = e.target;
        let cleanValue = null;
        if (name !== "country_phone_code") {
            cleanValue = textExtraSpaceRemoval(value);
        }
        const updatedFormArray = formArray.map((item, i) => {
            if (i === index) {
                if (name === "country_phone_code") {
                    return {
                        ...item,
                        [name]: value,
                    };
                } else if (name === "name") {
                    return {
                        ...item,
                        [name]: toTitleCaseWhileTyping(value),
                    };
                } else {
                    return {
                        ...item,
                        [name]: cleanValue,
                    };
                }
            }
            return item;
        });
        setFormArray(updatedFormArray);
    };

    const handleAddMore = () => {
        if (formArray.length === 5) {
            toast.showMessage("Limit", "Maximum 5 alloweds.", "error");
        } else {
            setActiveTabs([formArray.length]);
            setFormArray([...formArray, { phone: "", name: "", products_supplied: "", country_phone_code: "+1" }]);
        }
    };

    const handleRemoveForm = async (values) => {
        if (values) {
            const updatedFormArray = formArray.filter((_, i) => !values.includes(i));
            setFormArray(updatedFormArray);
            setChecked([]);
        } else {
            setIsSaving(true);
            // Deleting Data from Backend
            const res = formArray.filter((_, i) => checked.includes(i));

            if (res.length > 0) {
                const ids = res.map((item) => item.id);

                try {
                    setIsLoading(true);
                    let payload = {
                        id: ids,
                    };

                    const res = await supplierDelete(payload);

                    if (res.data.status) {
                        let client_data = JSON.parse(JSON.stringify(screens.client.data));
                        let suppliers = client_data.suppliers;
                        suppliers = suppliers.filter((item, i) => !ids.includes(item.id));
                        client_data["suppliers"] = suppliers;
                        dispatch(onClientDataChange(client_data));
                        setIsLoading(false);
                        setIsSaving(false);
                    } else {
                        toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
                        setIsLoading(false);
                        setIsSaving(false);
                    }
                } catch (error) {
                    setIsSaving(false);
                    setIsLoading(false);
                    toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            }
            const updatedFormArray = formArray.filter((_, i) => !checked.includes(i));
            setFormArray(updatedFormArray);
            setChecked([]);
        }
    };

    const handleCheckedChange = (index) => {
        const updatedChecked = [...checked];
        const exist = checked.includes(index);
        if (exist) {
            const value = checked.indexOf(index);
            updatedChecked.splice(value, 1);
        } else {
            updatedChecked.push(index);
        }
        setChecked(updatedChecked);
    };

    const saveHandler = async () => {
        // Removing Empty form
        let temp = [];
        let filteredData = formArray.filter((obj, index) => {
            if (obj.name || obj.phone || obj.products_supplied) {
                return true;
            } else {
                temp.push(index);
            }
        });

        // if (temp.length !== 0) {
        handleRemoveForm(temp);
        // }

        if (screens.client.isSupplierFormDirty) {
            setIsSaving(true);

            // Cleaning Data
            const updatedArray = filteredData.map((obj) => {
                const { country_phone_code, ...rest } = obj;
                return {
                    ...rest,
                    country_phone_code: country_phone_code.phone_code ?? "+1",
                };
            });
            const payload = {
                step: "4",
                suppliers: updatedArray,
                id: screens.client.data.id,
            };

            let res = await saveProspect(payload);
            if (res.status) {
                formDirtyHandler();
                setIsDisabled(true);
                dispatch(onFormDirty(false));
                dispatch(onClientSupplierFormDirty(false));
                setActiveTabs([]);
                setChecked([]);
                dispatch(onClientDataChange(res.data.prospect));
            } else {
                toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsSaving(false);
        } else {
            setIsDisabled(true);
            setActiveTabs([]);
            setChecked([]);
        }
    };

    const HeaderItems = (index, name) => {
        return (
            <>
                <div>
                    <Checkbox checked={checked.includes(index)} onChange={(e) => handleCheckedChange(index)} />
                </div>
                <div className="flex flex-column pl-2 pt-2 pb-2">
                    <h5 className="m-0">{name}</h5>
                </div>
            </>
        );
    };

    // UseEffect
    useEffect(() => {
        if (screens.client.data) {
            let temp = [];
            screens.client.data.suppliers.map((item, index) => {
                const data = { id: item.id, phone: item.phone, name: item.name, products_supplied: item.products_supplied, country_phone_code: item.country_phone_code };
                temp.push(data);
            });

            setFormArray(temp);
        }
    }, []);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCodeOptions(screens.prospect.countryCodes);
        }
    }, [screens.prospect.countryCodes]);

    return (
        <>
            <div className="main_brdr_class bank_info">
                <form>
                    <div className="grid">
                        {/* Header */}
                        <div className="col-6 md:col-7">
                            <div className="">
                                <h5>
                                    <b>Supplier Information</b>
                                </h5>
                            </div>
                        </div>
                        {/* //Header Button */}
                        <div className="col-6 md:col-5 text-right">
                            {checked.length !== 0 && (
                                <Button
                                    tooltip="Delete"
                                    tooltipOptions={{ position: "top" }}
                                    className="delete_icon mr-2"
                                    icon={<BsTrash3 />}
                                    disabled={isSaving}
                                    type="button"
                                    onClick={() => (isSaving ? null : handleRemoveForm())}
                                />
                            )}

                            {isDisabled ? (
                                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit_icon" icon={<BsPencil />} type="button" onClick={() => setIsDisabled(false)} />
                            ) : (
                                <>
                                    <Button tooltip="Add More" tooltipOptions={{ position: "top" }} className="edit_icon mr-2" icon={<BsPlus />} type="button" onClick={() => handleAddMore()} />
                                    <Button
                                        tooltip="Save"
                                        tooltipOptions={{ position: "top" }}
                                        className={isSaving ? "check_tick_icon_disabled" : "check_tick_icon"}
                                        icon={<BsCheck />}
                                        disabled={isSaving}
                                        type="button"
                                        onClick={() => (isSaving ? null : saveHandler())}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    {/* Body */}
                    {isLoading ? (
                        <CustomCardSpinner />
                    ) : (
                        <div className="business_scrollbar">
                            {formArray.length === 0 ? (
                                <h5 className="m-6 no_record_rext">No Supplier Available</h5>
                            ) : (
                                <div className="grid supplier_accordian_tab">
                                    <div className="col-12">
                                        <Accordion
                                            multiple={true}
                                            activeIndex={activeTabs}
                                            onTabChange={(e) => {
                                                setActiveTabs(e.index);
                                            }}
                                        >
                                            {formArray.map((item, index) => (
                                                <AccordionTab headerTemplate={HeaderItems(index, item.name)} disabled={isDisabled} key={index}>
                                                    <div className="grid" key={index}>
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>Supplier Name</b>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="md:col-6 col-12">
                                                            <InputText
                                                                className="custom_border_none"
                                                                id="name"
                                                                name="name"
                                                                label="Supplier Name"
                                                                value={item.name}
                                                                maxLength="60"
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                placeholder="Enter Supplier Name"
                                                                keyfilter={/^[a-zA-Z\s]+$/}
                                                            />
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>Product Supplied</b>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="md:col-6 col-12">
                                                            <InputText
                                                                className="custom_border_none"
                                                                id="products_supplied"
                                                                name="products_supplied"
                                                                label="Product Supplied"
                                                                maxLength="60"
                                                                value={item.products_supplied}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                placeholder="Enter Product Supplied"
                                                                keyfilter="alphanum"
                                                            />
                                                        </div>

                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>Phone No</b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <div className="border_div">
                                                                <Dropdown
                                                                    id="country_phone_code"
                                                                    name="country_phone_code"
                                                                    value={item.country_phone_code}
                                                                    placeholder={item.country_phone_code.phone_code ?? item.country_phone_code}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                    className="proscustom_width_country"
                                                                    options={countryCodeOptions}
                                                                    optionLabel="phone_code"
                                                                    filter
                                                                    filterBy="phone_code"
                                                                    emptyMessage="No results found"
                                                                />
                                                                <InputMask
                                                                    iden="phone"
                                                                    name="phone"
                                                                    mask="999-999-9999"
                                                                    placeholder="999-999-9999"
                                                                    value={item.phone}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                    className="proscustom_width_phone"
                                                                />
                                                            </div>
                                                            {item.phoneError && <small className="p-error">{item.phoneError}</small>}
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                            ))}
                                        </Accordion>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default SupplierInformation;
