import React, { useState, useRef, useEffect, useContext } from "react";
//styles
import "./annoucements.scss";
// UI Components
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import SearchInputComponent from "../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../ui/KebabMenu/KebabMenuComponent";
import { PaginatorTemplate } from "../../../../ui/PaginatorTemplate/PaginatorTemplate";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
// Components
import AddNewAnnouncement from "../Components/AddNewAnnouncement";
// Prime react
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BsCheckLg } from "react-icons/bs";
//Headr component4
import Header from "../Header/header";
// Context & APIs
import { ToastContext } from "../../../../context/toast";
import { deleteAnnoucement, getAllAnnoucements } from "../../../../services/annoucements";
// Constants
import { SETTINGS_RIGHTS } from "../../../../utils/Constants/rights";
// Utils
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../redux/login";
import { ANNOUNCEMENT_ITEMS } from "../../../../utils/Constants/global";
import { trimTextWithDots } from "../../../../utils/HelperFuctions/trimText";
import { Tooltip } from "primereact/tooltip";
import { BreadCrumb } from "primereact/breadcrumb";

const AnnoucementIndex = () => {
    const toastContext = useContext(ToastContext);
    // bread Crumbs
    const BreakcrumbItems = [{ label: "Settings", url: "/settings" }, { label: "Announcements" }];
    const homeIcon = { icon: "bi bi-toggle-off" };
    const columns = [
        { field: "title", header: "Announcement Title" },
        { field: "effective_from", header: "Effective From" },
        { field: "effective_to", header: "Effective To" },
        { field: "announcement_text", header: "Announcement Text" },
        { field: "role_names", header: "Broadcasted To" },
    ];
    // Redux
    const { user } = useSelector(loginState);
    const dt = useRef(null);
    //states
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedAnnoucement, setSelectedAnnoucement] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);
    const [editAnnoucementDialog, setEditAnnoucementDialog] = useState(false);
    const [editAnnoucementData, setEditAnnoucementData] = useState();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    /////////////////////////////////////// PAGINATION REQUIRED DATA START ////////////////////////////////////////////////////
    const [first, setFirst] = useState(0);
    const [responseData, setReponseData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [page, setPage] = useState(currentPage);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });
    const [kebab_menu_items, setKebabMenuItems] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTitleTooltip, setShowTitleTooltip] = useState(false);

    const onCustomPage = (event) => {
        setFirst(event.first);
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };
    /////////////////////////////////////// PAGINATION REQUIRED DATA END ////////////////////////////////////////////////////
    const handleColumn = (data) => {
        setSelectedColumns(data);
    };
    const getColumnBody = (colField) => (rowData) => {
        const handleMouseEnter = (col) => {
            if (col === "title") {
                setShowTitleTooltip(true);
            } else {
                setShowTooltip(true);
            }
        };

        const handleMouseLeave = (col) => {
            if (col === "title") {
                setShowTitleTooltip(false);
            } else {
                setShowTooltip(false);
            }
        };

        if (colField === "first_Name") {
            return (rowData.last_Name ? rowData.last_Name : "") + " " + (rowData.first_Name ? rowData.first_Name : "");
        } else if (colField === "role_names") {
            if (rowData.broadcast_to === 1) {
                return rowData.users_name;
            } else {
                return rowData.role_names;
            }
        } else if (colField === "announcement_text") {
            return (
                <>
                    <div onMouseEnter={() => handleMouseEnter("announcement_text")} onMouseLeave={() => handleMouseLeave("announcement_text")}>
                        <span id={`name-${rowData.id}`}>{trimTextWithDots(rowData.announcement_text, 25)}</span>
                        {showTooltip && (
                            <Tooltip target={`#name-${rowData.id}`} position="left" onHide={() => setShowTooltip(false)}>
                                {rowData.announcement_text}
                            </Tooltip>
                        )}
                    </div>
                </>
            );
        } else if (colField === "title") {
            return (
                <>
                    <div onMouseEnter={() => handleMouseEnter("title")} onMouseLeave={() => handleMouseLeave("title")}>
                        <span id={`name-${rowData.id}`}>{trimTextWithDots(rowData.title, 25)}</span>
                        {showTitleTooltip && (
                            <Tooltip target={`#name-${rowData.id}`} position="left" onHide={() => setShowTitleTooltip(false)}>
                                {rowData.title}
                            </Tooltip>
                        )}
                    </div>
                </>
            );
        }

        return rowData[colField] ? rowData[colField] : "";
    };
    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable={col.sortable} />;
    });

    const handleOpenMenuItems = (status) => {
        if (status === 1) {
            setShowDeleteDialog(true);
        }
    };

    const deleteAnnoucementHandler = async () => {
        try {
            setIsDeleting(true);
            const annoucementIds = selectedAnnoucement.map((annoucement) => annoucement.id);
            let dto = {
                id: annoucementIds,
            };
            let resp = await deleteAnnoucement(dto);
            if (resp.data.status) {
                setIsDeleting(false);
                setSelectedAnnoucement([]);
                setShowDeleteDialog(false);
                toastContext.deleteToast("Annoucement");
                getAnnoucments();
            }
        } catch (error) {
            setIsDeleting(false);
        }
    };

    const handleRowSelect = (event) => {
        if (event.type === "row") {
            setEditAnnoucementData(event.data);
            setEditAnnoucementDialog(true);
            // history.push(`/addnewuser`, { user: event?.data });
        }
    };

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedAnnoucement(event.value);
        }
    };

    const getAnnoucments = async () => {
        setLoading(true);
        const dto = {
            page: currentPage,
            per_page: pageSize,
            search_text: search,
        };
        let resp = await getAllAnnoucements(dto);
        setPagination({
            ...pagination,
            totalRecords: resp.data.announcement.total,
            totalPages: resp.data.announcement.last_page,
            isLastPage: resp.data.announcement.last_page,
        });
        setLoading(false);
        setIsLoaded(true);
        setReponseData(resp.data.announcement);
    };

    const onCancel = (refresh) => {
        if (refresh) {
            getAnnoucments();
        }
        setEditAnnoucementDialog(false);
    };

    //useEffect
    useEffect(() => {
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        // Client Menu Items
        const _kebab_menu_items = ANNOUNCEMENT_ITEMS.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);
            if (isAllowed) return col;
        });
        setKebabMenuItems(_kebab_menu_items);
    }, [user]);

    useEffect(() => {
        getAnnoucments();
    }, [currentPage, pageSize, showAll]);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                getAnnoucments();
            }, 250);
            return () => clearTimeout(delayDebounceFn);
        } else {
            getAnnoucments();
        }
    }, [search]);

    return (
        <>
            {validateUserPermissions([SETTINGS_RIGHTS.announcments.announcement_selection], user?.rights) && (
                <Dialog
                    blockScroll={true}
                    header="Edit Announcement"
                    visible={editAnnoucementDialog}
                    onHide={() => onCancel(false)}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw" }}
                >
                    <AddNewAnnouncement editAnnoucementData={editAnnoucementData} onHide={(refresh) => onCancel(refresh)} />
                </Dialog>
            )}
            <div className="grid">
                <div className="col-12 pt-3 pb-3">
                    <BreadCrumb model={BreakcrumbItems} home={homeIcon} />
                </div>
            </div>
            <div className="grid">
                <div className="md:col-6 col-12"></div>
                <div className="md:col-6 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent value={search} setKeyword={setSearch} placeholder="Search by announcement title" />
                        <div className="ml-2">
                            <ColumnFilterComponent items={columns} handleColumnFilter={handleColumn} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedAnnoucement.length === 0} />}
                        </div>
                        <div className="ml-2">
                            <Header getAnnoucments={getAnnoucments} selectedAnnoucementsLength={selectedAnnoucement.length} />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    {loading ? (
                        <CustomLoading />
                    ) : (
                        <DataTable
                            ref={dt}
                            value={responseData?.data}
                            selection={selectedAnnoucement.length === 0 ? null : selectedAnnoucement}
                            onSelectionChange={handleSelectionChange} // for checkbox
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rows={pageSize}
                            paginator
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            first={first}
                            onPage={onCustomPage}
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                            {/* <Column body={(rowData, rowIndex) => actionTempate(rowData, rowIndex)} header="Status"></Column> */}
                        </DataTable>
                    )}
                </div>
            </div>
            {showDeleteDialog && (
                <CustomConfirmDialog
                    isDisabled={isDeleting}
                    header="Delete Announcement"
                    type="error"
                    onHide={() => setShowDeleteDialog(false)}
                    handler={deleteAnnoucementHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to delete announcement?"
                />
            )}
        </>
    );
};

export default AnnoucementIndex;
