import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";


//Component
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { loginState } from "../../../../../../../redux/login";


// Utils
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";

// Constants
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";

const Header = (props) => {
    const history = useHistory();

    // Redux
    const { user } = useSelector(loginState);

    return (
        <>
            <div className="grid text-right">
                <div className="md:col-12 ">
                    {validateUserPermissions([TRANSACTION_RIGHTS.invoices.add_new_invoice], user?.rights) && (
                        <CustomAddNewButton type="button" title="Add New Invoice" onClick={() => history.push("addnewinvoice")} />
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
