import React, { useContext, useEffect, useState } from "react";
//styles
import "../../../../../Operations/operation.scss";
// Constants
import { COLLECTIONS_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import FilterBar from "./GridView/FilterBar";
import Body from "./GridView/Body";
import { getDocumentsCount } from "../../../../../../services/documents";
import { getCollections } from "../../../../../../services/collection";
import { ToastContext } from "../../../../../../context/toast";

const Collections = () => {
    // State
    const [keyword, setKeyword] = useState("");
    const [onSelectionColumn] = useState([]);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(COLLECTIONS_GRID_HEADERS);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);

    const toast = useContext(ToastContext);

    const [kebabMenuOption, setKebabMenuOption] = useState({
        change_status: false,
        assign_tiicket: false,
    });

    // PAGINATION
    // States
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);

    // Api Handler
    const getCollection = async (page) => {
        try {
            let payload = {
                per_page: pageSize,
                search_text: keyword,
                page: page ? page : currentPage,
                is_uc:false,
            };
            if (showAll) payload["show_all"] = showAll;
            if (sortField) {
                payload["sort_by"] = sortField;
            }
            if (sortOrder) {
                payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
            }
            setLoading(true);
            const res = await getCollections(payload);
            if (res) {
                if (res?.data?.collections) {
                    setSelectedHeaders(res?.data?.collections?.data);
                    setPagination({
                        ...pagination,
                        totalRecords: res?.data?.collections?.total,
                        totalPages: res?.data?.collections?.last_page,
                        isLastPage: res?.data?.collections?.last_page,
                    });
                    setLoading(false);
                }
            } else {
                toast.showMessage("Error", "Server error.", "error");
                setLoading(false);
            }
        } catch (error) {
            toast.showMessage("Error", "Server error.", "error");
            setLoading(false);
        }
    };

    useEffect(() => {
        getCollection();
    }, [showAll, sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getCollection();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    // //UseEffect
    // useEffect(() => {
    //     if (keyword) {
    //         const delayDebounceFn = setTimeout(() => {
    //             setCurrentPage(1);
    //             setPage(1);
    //             getCollection(1);
    //         }, 350);
    //         return () => clearTimeout(delayDebounceFn);
    //     } else {
    //         getCollection();
    //     }
    // }, [showAll,keyword, sortField, sortOrder, pageSize, currentPage]);

    return (
        <>
            <FilterBar
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                setKebabMenuOption={setKebabMenuOption}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                setLoading={setLoading}
            />
            <Body
                selectedHeaders={selectedHeaders}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                setPage={setPage}
                page={page}
            />
        </>
    );
};

export default Collections;
