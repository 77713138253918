import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Prime React
import { Button } from "primereact/button";

// Constext
import { ToastContext } from "../../../../../../../context/toast";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onClientBusinessInformationFormDirty, onClientDataChange, onFormDirty } from "../../../../../../../redux/main-view";

// Icons
import { BsCheck, BsInfoCircle, BsPencil } from "react-icons/bs";

// UI Components
import CustomTextField from "../../../../../../../ui/CustomTextField/CustomTextField";
import CustomInputField from "../../../../../../../ui/CustomInputField/CustomInputField";
import CustomInputTextArea from "../../../../../../../ui/CustomInputTextArea/CustomInputTextArea";
import CustomDropdownField from "../../../../../../../ui/CustomDropdownField/CustomDropdownField";

// Helper Fucntion
import { AmountCheck } from "../../../../../../../utils/HelperFuctions/checkAmount";
import formDirtyHandler from "../../../../../../../utils/HelperFuctions/clientHelper";

// Constants
import { FEDRAL_TAXES_PAST_DUE, TAXI_LIEN_FIELD } from "../../../../../../../utils/Constants/global";

// API'S
import { saveProspect } from "../../../../../../../services/prospect";

const validationSchema = Yup.object().shape({
    company_company_taxes_past_due_status: Yup.string().required("Federal/State Tax Due is required"),
    company_tax_lien_field: Yup.string().required("Tax Lien Filed is required"),
    broker_referral_code: Yup.string().nullable().min(7, "Referral Code must be at least 7 characters"),
});

const BusinessInformation = () => {
    //Dispatch
    const dispatch = useDispatch();

    // Redux
    const { screens } = useSelector(mainViewState);

    // Context
    const toast = useContext(ToastContext);

    // View State
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            company_company_taxes_past_due_status: "",
            company_tax_lien_field: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                data = {
                    ...data,
                    step: "1",
                    company_country_fax_code: data.company_country_fax_code ? data.company_country_fax_code.phone_code : "+1",
                    company_country_phone_code: data.company_country_phone_code ? data.company_country_phone_code.phone_code : "+1",
                    id: screens.client.data.id,
                };

                if (screens.client.isBusinessInformationFormDirty) {
                    let res = await saveProspect(data);
                    if (res) {
                        if (res.data.status) {
                            setIsDisabled(true);
                            dispatch(onClientDataChange(res.data.prospect));
                            dispatch(onClientBusinessInformationFormDirty(false));
                            dispatch(onFormDirty(false));
                            // Handling Form Dirty
                            formDirtyHandler();
                        } else {
                            toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                        }
                    } else {
                        toast.showMessage("Error", "Server Error", "error");
                        setIsSaving(false);
                    }
                } else {
                    setIsDisabled(true);
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
            }
        },
    });

    // Handlers
    const inputHandler = (e) => {
        dispatch(onFormDirty(true));
        dispatch(onClientBusinessInformationFormDirty(true));
        formik.handleChange(e);
        const { name, value } = e.target;

        if (name === "company_taxes_past_due_amount") {
            formik.setFieldValue([name], AmountCheck(value));
        } else if (name === "company_company_taxes_past_due_status") {
            if (value === 0) {
                formik.setFieldValue("company_taxes_past_due_type", "");
                formik.setFieldValue("company_taxes_past_due_amount", "");
            }
        }
    };

    const handleCurrencyChange = (event, name) => {
        dispatch(onFormDirty(true));
        dispatch(onClientBusinessInformationFormDirty(true));
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    useEffect(() => {
        if (screens.client.data) {
            const result = screens.client.data;
            formik.setFieldValue("company_company_taxes_past_due_status", result?.company_company_taxes_past_due_status === null ? "" : result?.company_company_taxes_past_due_status ? 1 : 0);
            formik.setFieldValue("company_taxes_past_due_type", result?.company_taxes_past_due_type);
            formik.setFieldValue("company_taxes_past_due_amount", result?.company_taxes_past_due_amount);
            formik.setFieldValue("company_tax_lien_field", result?.company_tax_lien_field === null ? "" : result?.company_tax_lien_field ? 1 : 0);
            formik.setFieldValue("reference", result?.reference);
            formik.setFieldValue("broker_referral_code", result?.broker_referral_code);
            formik.setFieldValue("company_business_description", result?.company_business_description);
        }
    }, []);

    return (
        <>
            <div className="main_brdr_class bank_info">
                <form>
                    <div className="grid">
                        {/* Header */}
                        <div className="col-7">
                            <div className="">
                                <h5>
                                    <b>Business Information</b>
                                </h5>
                            </div>
                        </div>
                        <div className="col-5 text-right">
                            {isDisabled ? (
                                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit_icon" icon={<BsPencil />} type="button" onClick={() => setIsDisabled(false)} />
                            ) : (
                                <Button
                                    tooltip="Save"
                                    tooltipOptions={{ position: "top" }}
                                    className={isSaving ? "check_tick_icon_disabled" : "check_tick_icon"}
                                    icon={<BsCheck />}
                                    disabled={isSaving}
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                />
                            )}
                        </div>
                    </div>
                    {/* Body */}
                    <div className="business_scrollbar">
                        <div className="grid">
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Federal/State Tax Due? <span className="clr_red">*</span>
                                        </b>

                                        <Button
                                            type="button"
                                            tooltip="It's best to meet the IRS tax deadline to avoid any issues.
                                             If there is any federal or state tax due, please mention reason and amount."
                                            tooltipOptions={{ position: "bottom" }}
                                            icon={<BsInfoCircle />}
                                            aria-label="Submit"
                                            className="customTooltipicon"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomDropdownField
                                    iden="company_company_taxes_past_due_status"
                                    label="Federal/State Tax Due?"
                                    mandatory="*"
                                    formik={formik}
                                    options={FEDRAL_TAXES_PAST_DUE}
                                    onChange={(e) => inputHandler(e)}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Due Type</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="company_taxes_past_due_type"
                                    label="Due Type"
                                    disabled={isDisabled ? true : formik.values.company_company_taxes_past_due_status === 0 ? true : false}
                                    formik={formik}
                                    maxLength={30}
                                    placeHolder="Enter Due Type"
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Due Amount</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="company_taxes_past_due_amount"
                                    label="Due Amount"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "company_taxes_past_due_amount")}
                                    min={0}
                                    disabled={isDisabled ? true : formik.values.company_company_taxes_past_due_status === 0 ? true : false}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Tax Lien Filed? <span className="clr_red">*</span>
                                        </b>
                                        <Button
                                            type="button"
                                            tooltip="A federal tax lien is the government’s legal claim against your property when you neglect or fail to pay a tax debt. The lien protects the government’s interest in all your property, including real estate, personal property and financial assets."
                                            tooltipOptions={{ position: "bottom" }}
                                            icon={<BsInfoCircle />}
                                            aria-label="Submit"
                                            className="customTooltipicon"
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomDropdownField
                                    iden="company_tax_lien_field"
                                    label="Tax Lien Filed?"
                                    mandatory="*"
                                    formik={formik}
                                    options={TAXI_LIEN_FIELD}
                                    onChange={(e) => inputHandler(e)}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Referral Platform</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="reference"
                                    label="Referral Platform"
                                    formik={formik}
                                    maxLength={35}
                                    placeHolder="Enter Referral Platform"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Referral Code</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="broker_referral_code"
                                    label="Referral Code"
                                    formik={formik}
                                    maxLength={7}
                                    keyfilter="alphanum"
                                    placeHolder="Enter Referral Code"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="col-12">
                                <CustomInputTextArea
                                    className="col-12 md:col-12"
                                    iden="company_business_description"
                                    label="Description of Business"
                                    formik={formik}
                                    rows={1}
                                    maxLength={500}
                                    placeHolder="Description of Business"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default BusinessInformation;
