import React, { useEffect, useState } from "react";

// Prime React
import { Checkbox } from "primereact/checkbox";

// UI Components
import KebabMenuComponent from "../../../../ui/KebabMenu/KebabMenuComponent";
import ColumnFilterComponent from "../../../../ui/ColumnFilter/ColumnFilterComponent";
import SearchInputComponent from "../../../../ui/SearchInput/SearchInputComponent";

// Components
import Header from "./Header";

// Constants
import { ROLE_NAME, SUPPORT_GRID_HEADERS, SUPPORT_KEBAB_MENU } from "../../../../utils/Constants/global";

// Rights
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedUsers, setKebabMenuOption, user } = props;
    const [onSelectionColumn] = useState([]);

    const [kebab_menu_items, setKebabMenuItems] = useState([]);

    //Handlers
    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                change_status: true,
                assign_tiicket: false,
            });
        } else if (status == 2 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                change_status: false,
                assign_tiicket: true,
            });
        }
    };

    // Use Effects
    useEffect(() => {
        SUPPORT_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        // Kebab Menu
        const _kebab_menu_items = SUPPORT_KEBAB_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });
        setKebabMenuItems(_kebab_menu_items);
    }, [user]);

    return (
        <div className="grid">
            <div className="col-7 md:col-10">
                <h4 className="pl-2">
                    <b>Support</b>
                </h4>
            </div>
            <div className="md:col-7 col-12">
                {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT && user?.userRoles[0]?.roleName !== ROLE_NAME.DEBTOR && user?.userRoles[0]?.roleName !== ROLE_NAME.BROKER ? (
                    <div className="flex mt-4 pl-2">
                        <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include closed tickets
                        </label>
                    </div>
                ) : null}
            </div>
            <div className="md:col-5 col-12 pt-3">
                <div className="flex flex-row">
                    <SearchInputComponent
                        type="text"
                        value={keyword}
                        setKeyword={setKeyword}
                        // handleInput={(e) => setKeyword(e.target.value)}
                        placeholder="Search by ticket title, ticket type and priority"
                    />
                    <div className="ml-2">
                        <ColumnFilterComponent items={SUPPORT_GRID_HEADERS} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                    </div>
                    <div className={`ml-2 ${true ? "no-drop" : ""}`}>{kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} />}</div>
                    <div className="ml-2">
                        <Header user={user} selectedUsersLength={10} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterBar;
