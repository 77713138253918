import React, { useContext, useRef, useState } from "react";

import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getColumnBody } from "../../../../../utils/HelperFuctions/supportHelper";
import { classNames } from "primereact/utils";
import { PaginatorTemplate } from "../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { useHistory } from "react-router-dom";
import { ToastContext } from "../../../../../context/toast";
import { validateSomeUserPermissions, validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";
const Body = (props) => {
    const {
        responseData,
        selectedDebtors,
        setSelectedDebtors,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        setPage,
        page,
        showAll,
    } = props;

    const ref = useRef(null);
    const toast = useContext(ToastContext);
    const history = useHistory();
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Redux
    const { user } = useSelector(loginState);

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedDebtors(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions([MANAGMENT_RIGHTS.debtors.selection], user?.rights)) return;
        if (!validateSomeUserPermissions([MANAGMENT_RIGHTS.debtors.profile], user.rights ?? [])) return;
        // Row Selection
        if (event.type === "row") {
            if (event.data.id) {
                if (!event.data.deleted) {
                    history.push({ pathname: "/editdebtor", state: { id: `${event.data.id}`, prospect_id: `${event.data.prospect_id}` } });
                }
            } else {
                toast.showMessage("Error", "Admin has been notified", "error");
            }
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable   headerClassName={`${col.field === "available_credit_limit" || col.field === "credit_limit" ? "amount_column_alignment" : ""}`}>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.deleted || rowData.status === "Terminated") {
            return "delete_row_color";
        }

        return "";
    };

    return (
        <div className="col-12 pt-0">
            {loading ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <DataTable
                        ref={ref}
                        value={responseData}
                        selection={selectedDebtors.length === 0 ? null : selectedDebtors}
                        onSelectionChange={handleSelectionChange}
                        onSort={onSort}
                        sortable
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onPage={onCustomPage}
                        paginatorTemplate={PaginatorTemplate(
                            pagination.isFirstPage,
                            pagination.isLastPage,
                            currentPage,
                            pagination.totalPages,
                            pageSize,
                            page,
                            pagination.totalRecords,
                            pageInputTooltip,
                            setCurrentPage,
                            setPage,
                            setPageSize,
                            setPageInputTooltip
                        )}
                        rows={pageSize}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="No record available."
                        selectionMode="single" // Add selectionMode prop
                        onRowSelect={handleRowSelect} // Add onRowSelect event handler
                        // loading={loading}
                        rowClassName={getRowClassName}
                    >
                        {!showAll && <Column selectionMode="multiple"></Column>}
                        {columnComponents}
                    </DataTable>
                </div>
            )}
        </div>
    );
};

export default Body;
