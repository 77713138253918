import React, { useContext, useEffect, useState } from "react";
import { SignatureComponent } from "../../components/SignatureComponent";
import { useLocation } from "react-router-dom";
import { checkTermSheetSigned, saveTermSheetSigned, termSheetDetails } from "../../services/term-sheet";
import { ToastContext } from "../../context/toast";
import { useHistory } from "react-router-dom";
import CustomLoading from "../../ui/CustomSpinner/custom_spinner";
import moment from "moment";
import FactorFlow from "../../Images/Logo.png";
import { BsX } from "react-icons/bs";
import { Button } from "primereact/button";
import { useQuery } from "../../utils/HelperFuctions/UseQuery";
import { currencyFormatUSD } from "../../utils/HelperFuctions/checkAmount";

export const TermSheetSignature = () => {
    const [savingDisabled, setSavingDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [termSheetData, setTermSheetData] = useState(null);
    const [companyName, setCompanyName] = useState("Frontline Funding, LLC.");
    const [propertierName, setPropertierName] = useState("");
    const [base64Image, setBase64Image] = useState("");
    const [data, setData] = useState([]);
    let query = useQuery();
    let id = query.get("id");
    let id2 = query.get("id2");
    let id3 = query.get("id3");
    const toast = useContext(ToastContext);
    const history = useHistory();
    const location = useLocation();
    let idl = null;
    let id2l = null;
    let id3l = null;
    if (location?.state !== undefined) {
        idl = location.state?.id;
        id2l = location.state?.id2;
        id3l = location.state?.id3;
    } else if (location.pathname === "/SignedTermSheet" && location?.state === undefined) {
        history.push("/prospectindex");
    }
    const handleSaveSigned = async (base64Url) => {
        setSavingDisabled(true);
        setIsLoading(true);
        let dto = {
            term_sheet_id: id2,
            owner_id: id,
            signature: base64Url,
        };
        try {
            const res = await saveTermSheetSigned(dto);
            if (res.data.status) {
                window.location.href = "/login";
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setSavingDisabled(false);
        setIsLoading(false);
    };

    const handleStatus = async () => {
        let dto = {
            term_sheet_id: id2,
            owner_id: id,
        };
        setIsLoading(true);
        try {
            const res = await checkTermSheetSigned(dto);
            if (res.data.status) {
                if (res.data.term_sheet_status) {
                    history.push("/linkexpire");
                    return;
                } else {
                    handleGetTermSheet();
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const handleGetTermSheet = async () => {
        let dto = {};
        if (location.pathname === "/SignedTermSheet") {
            dto = {
                prospect_id: id3l,
                owner_id: idl,
                show: true,
            };
        } else {
            dto = {
                prospect_id: id3,
                owner_id: id,
                show: false,
            };
        }

        try {
            const res = await termSheetDetails(dto);
            if (res.data.status) {
                setTermSheetData(res.data.term);
                setData(res.data.term.prospect);
                const ownerDetails = res.data.term.owners.filter((owner) => {
                    return parseInt(owner.value) === parseInt(res.data.owner_id);
                });

                setPropertierName(ownerDetails[0].name);
                setBase64Image(ownerDetails[0]?.pivot?.signature);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setIsLoading(false);
    };
    const onClose = () => {
        history.push("/prospectindex");
    };
    useEffect(() => {
        if (location.pathname === "/SignedTermSheet") {
            setIsLoading(true);
            handleGetTermSheet();
        } else {
            handleStatus();
        }
        if ((!id || !id2 || !id3) && location.pathname !== "/SignedTermSheet") {
            window.location.href = "/login";
        }
    }, []);
    return (
        <div
        // className={`${location.pathname === "/SignedTermSheet" && "layout-main-container"}`}
        // style={{
        //     padding: localStorage.getItem("accessToken") ? "4.5rem 1rem 1rem 4rem" : "",
        // }}
        >
            {isLoading ? (
                <>
                    <CustomLoading />
                </>
            ) : (
                <>
                    <div className="card">
                        <div className="termsheet_detail">
                            <form>
                                <div className="grid">
                                    {location.pathname === "/SignedTermSheet" && (
                                        <div className="col-12">
                                            <div className="term_sheet_cross text-right mr-5">
                                                <Button className="bsx_icon" icon={<BsX />} onClick={onClose} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <div>
                                            <img src={FactorFlow} alt="logo" width="187" height="33" />
                                        </div>
                                        <div className="pt-3 heading_detail">
                                            <p>
                                                <b>{moment(new Date()).format("LL")}</b>
                                            </p>
                                            <p>
                                                <b>{data?.company_business_name}</b>
                                            </p>
                                            <p>
                                                <b>{data?.company_address}</b>
                                            </p>
                                            <p>
                                                <b>{data?.company_state + " " + data?.company_zip}</b>
                                            </p>
                                        </div>
                                        <div className="pt-3">
                                            <p>
                                                <b>Re: </b>
                                                <span>
                                                    Non-Binding Credit Proposal for <b>{data?.company_business_name}</b>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className=" col-12 md:col-4 pt-5">
                                        <p>
                                            <b>Dear: </b>
                                            <span>
                                                <b>{propertierName}</b>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <p>
                                            Thank you for the opportunity to support your business objectives. This letter confirms that you have requested {companyName} and/or its affiliate (“
                                            {companyName}
                                            ”) to provide a Purchase Facility to your company. Below is an outline of the terms of a relationship with {companyName}, contingent upon satisfactory
                                            completion of due diligence including collateral verification, debtor limit approval, credit committee approval and the execution of {companyName} closing
                                            documents. Please note that this is a proposal letter only and does not imply any commitment on the part of {companyName}
                                        </p>
                                        <p>
                                            <b> Purchase of Accounts *:</b> You shall have the option to request that we periodically purchase your accounts which are in existence at the time of
                                            purchase, up to a maximum outstanding Initial Purchase Price of{" "}
                                            $<span className="custom_text_span">
                                                <b>{currencyFormatUSD(termSheetData?.factoring_limit, false)}</b>
                                            </span>
                                        </p>
                                        <p>
                                            <b> Advance Formula *:</b> Up to
                                            <span className="custom_text_span">
                                                <b> {termSheetData?.advance_percentage}</b>
                                            </span>
                                            % of the Net Face Amount of Invoices, subject to dilution review.
                                        </p>
                                        <p>
                                            <b> Escrow Reserve *:</b>
                                            <span className="custom_text_span">
                                                <b> {termSheetData?.escrow_reserve_percentage}</b>
                                            </span>
                                            % Escrow reserve will be established on 100% of the outstanding Accounts Receivable at all times and repaid to {data?.company_business_name} upon
                                            termination of agreement and completion of all debtors payments received or assets acquired.
                                        </p>
                                        <p>
                                            <b>Recourse *:</b> Any non-payment of invoice(s) past the
                                            <span className="custom_text_span">
                                                <b> {termSheetData?.recourse_days} </b>
                                            </span>
                                            day(s) outstanding will be purchased back by {data?.company_business_name} including Purchase Discount.
                                        </p>
                                        <p>
                                            <b>Purchase Discount *: </b>
                                            <b> {termSheetData?.purchase_discount}</b>% for the first <b>{termSheetData?.discount_days}</b> day(s) period and an incremental discount of{" "}
                                            <b>{termSheetData?.incremental_discount_percentage}%</b> for each incremental day period thereafter.
                                        </p>
                                        <p>
                                            <b>Collateral *: </b>A lien on all assets with a first lien on all Accounts Receivable and Inventory.
                                        </p>
                                        <p>
                                            <b>Guaranty *: </b> Guaranty of <b>{propertierName}</b>.
                                        </p>
                                        <p>
                                            <b>Due Diligence Deposit *: </b>$
                                            <span className="custom_text_span">
                                                <b>{termSheetData?.due_deligence_deposit} </b>
                                            </span>
                                            due with acceptance of this proposal.
                                        </p>
                                        <p>
                                            <b>Exit Fee *: </b>{" "}
                                            <span>
                                                The Exit Fee shall apply for an initial term of {termSheetData?.time_limit_term} months, with an automatic {termSheetData?.automatic_months_renewal}{" "}
                                                month renewals unless terminated in writing not less than {termSheetData?.less_than_days} days nor more than
                                                {termSheetData?.more_than_days} days prior to the next renewal date specifying Client’s intention to terminate this Agreement on the next renewal date.
                                                Standard exit fees will apply.
                                            </span>
                                        </p>
                                        <p>
                                            <b>Cash Management *: </b>All debtors will be notified to make payments to a {companyName} lockbox, payable to Frontline Funding, LLC. Client will pay the
                                            cost of the lockbox at <b>{companyName}</b> prevailing rates. Cash will be applied after
                                            <span className="custom_text_span">
                                                <b> {termSheetData?.business_days_clearance} </b>
                                            </span>
                                            business days clearance.
                                        </p>
                                        <p>
                                            <b>Financial Reports *:</b> Client shall forward, as requested, all pertinent information, including but not limited to A/R and A/P agings, and copies of
                                            invoices along with backup documentation.
                                        </p>
                                        <p>
                                            If this Credit Proposal meets with your approval, kindly indicate your acceptance of the terms stated herein by executing and returning a copy to us. Upon
                                            receipt of this signed Credit Proposal, {companyName} will proceed with its due diligence efforts to obtain final credit committee approval. If your company
                                            does not accept this Credit Proposal within <b>{termSheetData?.proposal_validity}</b> weeks of the date hereof, all provisions herein become null and void.
                                            Upon your acceptance below, you authorize {companyName} to file a UCC-1 Financing Statement in the appropriate jurisdiction to perfect our security interest
                                            in the collateral defined above.
                                        </p>
                                        <p>{companyName} is pleased to make this proposal and looks forward to providing the working capital to meet your needs.</p>
                                        <p>
                                            <b>Should you have any questions, please do not hesitate to call.</b>
                                        </p>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        {location.pathname === "/SignedTermSheet" ? (
                                            <>
                                                {base64Image ? (
                                                    <>
                                                        <img src={base64Image} alt="Signature" className="signature_canvas" />
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <SignatureComponent handleSave={handleSaveSigned} isDisabled={savingDisabled} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default TermSheetSignature;
