import React from "react";
import { useSelector } from "react-redux";

// UI Components
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";

// Redux
import { loginState } from "../../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../../utils/HelperFuctions/validatePermissions";

//Components

const Header = (props) => {
    const { isClientRequest, handleOpenAddCreditRequestDialog } = props;

    // Redux
    const { user } = useSelector(loginState);

    const handleNavigate = () => {
        // history.push(`/addnewcr?client=${isClientRequest? true : false}`);
        handleOpenAddCreditRequestDialog();
    };

    return (
        validateUserPermissions(isClientRequest ? ["creditrequestclientrequest_addnewrequest"] : ["creditrequestdebtorrequest_addnewrequest"], user?.rights) && (
            <CustomAddNewButton type="button" title="Add New Request" onClick={handleNavigate} />
        )
    );
};

export default Header;
