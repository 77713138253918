import React from "react";
import "./customaddnewbutton.scss";

const CustomAddNewButton = (props) => {
    const { title, name, id, className, type, onClick, isDisabled = false, isLoading = false } = props;
    return (
        <>
            <button
                className={`btn  ${isDisabled || isLoading ? "savebtn_disabled" : " savebtn"} ${className}`}
                name={name}
                id={id}
                type={type}
                onClick={onClick}
                disabled={isDisabled}
                style={{ position: "relative" }}
            >
                {title}
                {isLoading && <div className="loading-icon" />}
            </button>
        </>
    );
};

export default CustomAddNewButton;
