import React from "react";

export const Header = () => {
    return (
        <>
            {/* <p className="apply-test">
                <b>Apply Here</b>
            </p>
            <p className="step-fields-title row-margin-bottom">Fill in the following 8 step from to get started!</p> */}
        </>
    );
};
