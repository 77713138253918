import { store } from "../../redux";
import { onFormDirty } from "../../redux/main-view";

const formDirtyHandler = () => {
    const state = store.getState("mainView");

    store.dispatch(
        onFormDirty(
            state.mainView.screens.client.isBusinessInformationFormDirty ||
                state.mainView.screens.client.isBusinessProprietorFormDirty ||
                state.mainView.screens.client.isFinancialFormDirty ||
                state.mainView.screens.client.isLandlordFormDirty ||
                state.mainView.screens.client.isSupplierFormDirty
        )
    );
};

export default formDirtyHandler;
