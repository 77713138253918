import moment from "moment";
import { currencyFormatUSD } from "./checkAmount";
import { trimTextWithDots } from "./trimText";
import { DATE_TIME_FORMAT } from "../Constants/global";
import React from "react";

export const getInvoiceColumnBody = (colField) => (rowData) => {
    if (colField === "invoice_no") {
        if (rowData.invoice_no !== null) {
            return rowData.invoice_no;
        } else {
            return "Unassigned";
        }
    } else if (colField === "schedule_no") {
        if (rowData.schedule_no !== null) {
            return rowData.schedule_no;
        } else {
            return "Unassigned";
        }
    } else if (colField === "funding_date") {
        if (rowData.funding_date !== null) {
            return rowData.funding_date;
        } else {
            return "Not Funded";
        }
    } else if (colField === "invoice_amount") {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : ""}</div>;
    } else if (colField === "uc") {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : ""}</div>;
    } else if (colField === "note") {
        return trimTextWithDots(rowData.note, 30);
    } else if (colField === "amount") {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : ""}</div>;
    } else if (colField === "uc_released_date") {
        return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "";
    }
    return rowData[colField] ? rowData[colField] : "";
};

export const getChargebackColumnBody = (colField) => (rowData) => {
    if (colField === "invoice_amount" || colField === "actual_cb" || colField === "cb" || colField === "adjusted_cb") {
        return rowData[colField] ? currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0);
    }
    return rowData[colField] ? rowData[colField] : "";
};

export const getCashReserveColumnBody = (colField) => (rowData) => {
    if (colField === "funding_date") {
        if (rowData.funding_date !== null) {
            return rowData.funding_date;
        } else {
            return "Not Funded";
        }
    } else if (
        colField === "invoice_amount" ||
        colField === "reserved_amount" ||
        colField === "cash_reserves" ||
        colField === "wire_fee" ||
        colField === "previous_reserves" ||
        colField === "reserve_released_amount" ||
        colField === "escrow_reserves"
    ) {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0)}</div>;
    }
    return rowData[colField] ? rowData[colField] : "";
};

export const getEscrowAdjustmentColumnBody = (colField) => (rowData) => {
    if (colField === "invoice_amount" || colField === "available_escrow_reserves") {
        return rowData[colField] ? currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0);
    } else if (colField === "collection_date") {
        return "Paid";
    }

    return rowData[colField] ? rowData[colField] : "";
};
