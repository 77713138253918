import { APP_SIDEBAR_MENU } from "../Constants/global";

export function updateIsAllowed(itemList, rights) {
    itemList.forEach((item) => {
        if (item.required_rights && item.required_rights.length > 0) {
            item.isAllowed = item.required_rights?.some((requiredRight) => rights?.includes(requiredRight));
        } else {
            item.isAllowed = false;
        }

        if (item.items && item.items.length > 0) {
            updateIsAllowed(item.items, rights);
        }
    });

    return itemList;
}

export function validateUserPermissions(requiredPermissions = [], permissions = []) {
    if (requiredPermissions?.length !== 0) {
        return requiredPermissions?.every((right_name) => permissions?.includes(right_name));
    }

    return true;
}

export function validateSomeUserPermissions(requiredPermissions = [], permissions = []) {
    if (requiredPermissions?.length !== 0) {
        return requiredPermissions?.some((right_name) => permissions?.includes(right_name));
    }
    return true;
}

export function checkLandingScreen(permissions) {
    let route = "/";

    // Dashboard - 0
    if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[0].required_rights, permissions)) {
        route = "/dashboard";
    }
    // -------------------------------------------------------------------
    // Debtor Dashboard - 1
    /*   else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[1].required_rights, permissions)) {
        route = "/debtordashboard";
    } */
    // -------------------------------------------------------------------
    // Management - 2
    // Prospect - 0
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[2].items[0].required_rights, permissions)) {
        route = "/prospectindex";
    }
    // Client - 1
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[2].items[1].required_rights, permissions)) {
        route = "/ClientIndex";
    }
    // Debtor - 2
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[2].items[2].required_rights, permissions)) {
        route = "/debtormain";
    }
    // Broker - 3
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[2].items[3].required_rights, permissions)) {
        route = "/brokers";
    }
    // -------------------------------------------------------------------
    // Transaction - 3
    // Invoice - 0
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[0].required_rights, permissions)) {
        route = "/invoices";
    }
    // Schedule of Assignment - 1
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[1].required_rights, permissions)) {
        route = "/scheduleofassignment";
    }
    // Schedule of Funding - 2
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[2].required_rights, permissions)) {
        route = "/scheduleoffunding";
    }
    // Collections - 3
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[3].required_rights, permissions)) {
        route = "/collections";
    }
    // Hold Accounts - 4
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[4].required_rights, permissions)) {
        route = "/unappliedcash";
    }
    // Reserves - 5
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[5].required_rights, permissions)) {
        route = "/reserves";
    }
    // Charge Backs - 6
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[3].items[6].required_rights, permissions)) {
        route = "/chargebacks";
    }
    // -------------------------------------------------------------------
    // General Ledger - 4
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[4].required_rights, permissions)) {
        route = "/generalledger";
    }
    // -------------------------------------------------------------------
    // Credit Request - 5
    // Client Request - 0
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[5].items[0].required_rights, permissions)) {
        route = "/clientrequest";
    }
    // Debtor Request - 1
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[5].items[1].required_rights, permissions)) {
        route = "/debtorrequest";
    }
    // -------------------------------------------------------------------
    // Underwriting - 6
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[6].required_rights, permissions)) {
        route = "/clientchecklist";
    }
    // -------------------------------------------------------------------
    // Reports - 7
    else if (validateSomeUserPermissions(APP_SIDEBAR_MENU[0].items[7].required_rights, permissions)) {
        route = "/reports";
    }
    // -------------------------------------------------------------------
    // Support - 8
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[8].required_rights, permissions)) {
        route = "/supportmain";
    }

    // -------------------------------------------------------------------
    // User Management - 9
    // Users - 0
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[9].items[0].required_rights, permissions)) {
        route = "/userindex";
    }
    // Roles - 1
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[9].items[1].required_rights, permissions)) {
        route = "/rolesrights";
    }
    // -------------------------------------------------------------------
    // Settings - 10
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[10].required_rights, permissions)) {
        route = "/settings";
    }
    // -------------------------------------------------------------------
    // Chats - 11
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[11].required_rights, permissions)) {
        route = "/chatindex";
    }
    // -------------------------------------------------------------------
    // Documents - 12
    else if (validateUserPermissions(APP_SIDEBAR_MENU[0].items[12].required_rights, permissions)) {
        route = "/documents";
    }

    return route;
}
