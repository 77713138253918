import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";
import { mainViewState } from "../../../../../redux/main-view";
import { ToastContext } from "../../../../../context/toast";

// Prime React
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

// Icons
import { BsX } from "react-icons/bs";
import FactorFlow from "../../../../../Images/Logo.png";

// UI Components
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";

//API'S
import { saveTermSheet } from "../../../../../services/term-sheet";
import { useHistory } from "react-router-dom";

import { currencyFormatUSD } from "../../../../../utils/HelperFuctions/checkAmount";

const TermsheetDetail = (props) => {
    const { onClose, termSheetData, setIsMutated, isMutated, companyName } = props;

    const toast = useContext(ToastContext);
    const history = useHistory();

    const { screens } = useSelector(mainViewState);
    const data = screens.prospect.data ? screens.prospect.data : screens.client.data ? screens.client.data : null;

    // State
    const [propertierName, setPropertierName] = useState([]);

    const [isSaving, setIsSaving] = useState(false);

    // Handlers
    const saveHandler = async () => {
        try {
            setIsSaving(true);
            const res = await saveTermSheet(termSheetData);
            if (res) {
                if (res.data.status) {
                    toast.showMessage("Term Sheet Sent", "Term Sheet has been sent successfully", "success");
                    // onClose();
                    history.push("/prospectindex");
                    setIsMutated(!isMutated);
                    setIsSaving(false);
                } else {
                    toast.showMessage("Term Sheet", "Sorry, we are unable to process your request at this time.", "error");
                    setIsSaving(false);
                }
            } else {
                toast.showMessage("Term Sheet", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
            }
        } catch (error) {
            toast.showMessage("Term Sheet", "Sorry, we are unable to process your request at this time.", "error");
            setIsSaving(false);
        }

        return;
    };

    useEffect(() => {
        if (data) {
            const result = data.owners.filter((item) => termSheetData.owner_ids.includes(item.id));
            const temp = [];
            result.map((item) => {
                temp.push(item.name);
            });
            const names = temp.join(", ");
            setPropertierName(names);
        }
    }, [data]);

    return (
        <>
            {isSaving ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <div className="termsheet_detail">
                        <form>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="term_sheet_cross text-right mr-5">
                                        <CustomAddNewButton title="Send Term Sheet" type="button" disabled={isSaving} onClick={() => (isSaving ? null : saveHandler())} />
                                        <Button className="bsx_icon" icon={<BsX />} onClick={onClose} />
                                    </div>
                                    <div>
                                        <img src={FactorFlow} alt="logo" width="187" height="33" />
                                    </div>
                                    <div className="pt-3 heading_detail">
                                        <p>
                                            <b>{moment(new Date()).format("LL")}</b>
                                        </p>
                                        <p>
                                            <b>
                                                {data?.company_business_name},{data?.company_legal_status_text}
                                            </b>
                                        </p>
                                        <p>
                                            <b>{data?.company_address}</b>
                                        </p>
                                        <p>
                                            <b>{data?.company_state + " " + data?.company_zip}</b>
                                        </p>
                                    </div>
                                    <div className="pt-3">
                                        <p>
                                            <b>Re: </b>
                                            <span>
                                                Non-Binding Credit Proposal for <b>{data?.company_business_name}</b>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className=" col-12 md:col-4 pt-5">
                                    <p>
                                        <b>Dear:</b>{" "}
                                        <span>
                                            <b>{propertierName}</b>
                                        </span>
                                    </p>
                                </div>
                                <div className="col-12">
                                    <p>
                                        Thank you for the opportunity to support your business objectives. This letter confirms that you have requested {companyName} and/or its affiliate (“
                                        {companyName}
                                        ”) to provide a Purchase Facility to your company. Below is an outline of the terms of a relationship with {companyName}, contingent upon satisfactory
                                        completion of due diligence including collateral verification, debtor limit approval, credit committee approval and the execution of {companyName} closing
                                        documents. Please note that this is a proposal letter only and does not imply any commitment on the part of {companyName}
                                    </p>
                                    <p>
                                        <b> Purchase of Accounts *:</b> You shall have the option to request that we periodically purchase your accounts which are in existence at the time of purchase,
                                        up to a maximum outstanding Initial Purchase Price of{" "}
                                        $<span className="custom_text_span">
                                            <b>{currencyFormatUSD(termSheetData?.factoring_limit, false)}</b>
                                        </span>
                                    </p>
                                    <p>
                                        <b> Advance Formula *:</b> Up to
                                        <span className="custom_text_span">
                                            <b> {termSheetData?.advance_percentage}</b>
                                        </span>
                                        % of the Net Face Amount of Invoices, subject to dilution review.
                                    </p>
                                    <p>
                                        <b> Escrow Reserve *:</b>
                                        <span className="custom_text_span">
                                            <b> {termSheetData?.escrow_reserve_percentage}</b>
                                        </span>
                                        % Escrow reserve will be established on 100% of the outstanding Accounts Receivable at all times and repaid to {data?.company_business_name} upon termination of
                                        agreement and completion of all debtors payments received or assets acquired.
                                    </p>
                                    <p>
                                        <b>Recourse *:</b> Any non-payment of invoice(s) past the
                                        <span className="custom_text_span">
                                            <b> {termSheetData?.recourse_days} </b>
                                        </span>
                                        day(s) outstanding will be purchased back by {data?.company_business_name}, {data?.company_legal_status_text} including Purchase Discount.
                                    </p>
                                    <p>
                                        <b>Purchase Discount *: </b>
                                        <b> {termSheetData?.purchase_discount}</b>% for the first <b>{termSheetData?.discount_days}</b> day(s) period and an incremental discount of{" "}
                                        <b>{termSheetData?.incremental_discount_percentage}%</b> for each incremental day period thereafter.
                                    </p>
                                    <p>
                                        <b>Collateral *: </b>A lien on all assets with a first lien on all Accounts Receivable and Inventory.
                                    </p>
                                    <p>
                                        <b>Guaranty *: </b> Guaranty of <b>{propertierName}</b>.
                                    </p>
                                    <p>
                                        <b>Due Diligence Deposit *: </b>$
                                        <span className="custom_text_span">
                                            <b>{termSheetData.due_deligence_deposit} </b>
                                        </span>
                                        due with acceptance of this proposal.
                                    </p>
                                    <p>
                                        <b>Exit Fee *: </b>{" "}
                                        <span>
                                            The Exit Fee shall apply for an initial term of {termSheetData?.time_limit_term} months, with an automatic {termSheetData?.automatic_months_renewal} month
                                            renewals unless terminated in writing not less than {termSheetData?.less_than_days} days nor more than
                                            {termSheetData?.more_than_days} days prior to the next renewal date specifying Client’s intention to terminate this Agreement on the next renewal date.
                                            Standard exit fees will apply.
                                        </span>
                                    </p>
                                    <p>
                                        <b>Cash Management *: </b>All debtors will be notified to make payments to a {companyName} lockbox, payable to Frontline Funding, LLC. Client will pay the cost
                                        of the lockbox at <b>{companyName}</b> prevailing rates. Cash will be applied after
                                        <span className="custom_text_span">
                                            <b> {termSheetData?.business_days_clearance} </b>
                                        </span>
                                        business days clearance.
                                    </p>
                                    <p>
                                        <b>Financial Reports *:</b> Client shall forward, as requested, all pertinent information, including but not limited to A/R and A/P agings, and copies of
                                        invoices along with backup documentation.
                                    </p>
                                    <p>
                                        If this Credit Proposal meets with your approval, kindly indicate your acceptance of the terms stated herein by executing and returning a copy to us. Upon
                                        receipt of this signed Credit Proposal, {companyName} will proceed with its due diligence efforts to obtain final credit committee approval. If your company
                                        does not accept this Credit Proposal within <b>{termSheetData?.proposal_validity}</b> weeks of the date hereof, all provisions herein become null and void. Upon
                                        your acceptance below, you authorize {companyName} to file a UCC-1 Financing Statement in the appropriate jurisdiction to perfect our security interest in the
                                        collateral defined above.
                                    </p>
                                    <p>{companyName} is pleased to make this proposal and looks forward to providing the working capital to meet your needs.</p>
                                    <p>
                                        <b>Should you have any questions, please do not hesitate to call.</b>
                                    </p>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="border_class card">
                                        <div className="pt-3">
                                            <Checkbox checked={termSheetData?.cb_fee ? true : false} />
                                            <span className="pl-3">
                                                Chargeback Fee After 90 Days
                                                <span className="custom_text_span">
                                                    <b> {termSheetData?.cb_fee ? termSheetData?.cb_fee : 0}</b>
                                                </span>
                                                %
                                            </span>
                                        </div>
                                        <div className="pt-2">
                                            <Checkbox checked={termSheetData?.cb_per_day_fee ? true : false} />
                                            <span className="pl-3">
                                                Chargeback Fee per day After 90 Days
                                                <span className="custom_text_span">
                                                    <b> {termSheetData?.cb_per_day_fee ? termSheetData?.cb_per_day_fee : 0}</b>
                                                </span>
                                                %
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default TermsheetDetail;
