import React, { useContext, useEffect, useState } from "react";
//moment
import moment from "moment";

// Redux
import { useSelector } from "react-redux";
import { mainViewState } from "../../../../../redux/main-view";
// Icons
import FactorFlow from "../../../../../Images/Logo.png";
// UI Components
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
const ClientNOA = (props) => {
    const { screens } = useSelector(mainViewState);
    const data = screens.client.data;
    const [isSaving, setIsSaving] = useState(false);

    // Handlers
    const saveHandler = async () => {};
    return (
        <>
            {isSaving ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <div className="termsheet_detail">
                        <form>
                            <div className="grid">
                                <div className="col-12">
                                    <div className="term_sheet_cross text-right mr-3">
                                        <CustomAddNewButton title="Send NOA" type="button" disabled={isSaving} onClick={() => (isSaving ? null : saveHandler())} />
                                    </div>
                                    <div>
                                        <img src={FactorFlow} alt="logo" width="187" height="33" />
                                    </div>
                                    <div className="pt-3 heading_detail">
                                        <p>{process.env.REACT_APP_ADDRESS_STREET}</p>
                                        <p>{process.env.REACT_APP_ADDRESS_HOUSE}</p>
                                        <p>
                                            {process.env.REACT_APP_ADDRESS_CITY}, {process.env.REACT_APP_ADDRESS_STATE} {process.env.REACT_APP_ADDRESS_ZIP}
                                        </p>
                                        <p>Tel: {process.env.REACT_APP_ADDRESS_PHONE}</p>
                                        <p>Fax: {process.env.REACT_APP_ADDRESS_FAX}</p>
                                        <p className="flex justify-content-center">
                                            <b>NOTICE OF ASSIGNMENT OF ACCOUNTS RECEIVABLE </b>
                                        </p>
                                    </div>
                                    <div className="pt-3">
                                        <p>
                                            <b>Attention: </b>
                                            <span>Accounts Payable Manager</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <p>
                                        We are pleased to advise you that, in order to enable <b>{data?.company_business_name}</b> . to better service its customers and expand its business,{" "}
                                        <b>{data?.company_business_name}</b>. has entered into a factoring facility with Frontline Funding, LLC (“Frontline”) and, in that regard, has sold and assigned
                                        its present and future accounts to Frontline.
                                    </p>
                                    <p>
                                        To the extent that you are now indebted, or may in the future become indebted, to <b>{data?.company_business_name}</b>. on account of any matter, payment
                                        thereof is to be made payable solely to Frontline and not to <b>{data?.company_business_name}</b>. or any other entity. Payment to anyone other than Frontline
                                        will not discharge your obligation to pay Frontline.
                                    </p>
                                </div>

                                <div className="col-12">
                                    <p>The payments should be sent to Frontline as follows:</p>
                                    <div className="grid">
                                        <div className="col-6">
                                            <p className="font-sie-bold">
                                                <b>
                                                    <u>
                                                        <i>PAYMENTS VIA MAIL</i>
                                                    </u>
                                                </b>
                                            </p>
                                            <p>Factor Flow</p>
                                            <p>PO BOX 31513</p>
                                            <p>Tampa FL 33631-3513</p>
                                        </div>
                                        <div className="col-6">
                                            <p className="font-sie-bold">
                                                <b>
                                                    <u>
                                                        <i>ELECTRONIC PAYMENTS</i>
                                                    </u>
                                                </b>
                                            </p>
                                            <p>Factor Flow</p>
                                            <p>Routing Number 124000054</p>
                                            <p>Account Number 984083477</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p>
                                        Please notify Frontline of any disputes to payment as soon as possible at: <a>collections@frontlinefunding.com</a> . This Notice of Assignment may only be
                                        revoked by a notarized writing signed by one of Frontline’s officers or agents.
                                    </p>
                                    <p>We thank you in advance for your cooperation, and we look forward to the continued growth and prosperity of our client.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default ClientNOA;
