import React, { useContext, useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";
import { mainViewState } from "../../../../../redux/main-view";

// Icons
import Document from "../../../../../svgs/Document/Document";
import { BsCheckLg, BsFillGearFill, BsPaperclip, BsPlus, BsXLg } from "react-icons/bs";

// Prime React
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { DataTable } from "primereact/datatable";

// UI Components
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";

//Component
import TermSheet from "../../../Prospect/Components/ProspectComponent/TermSheet";

// Helper Function
import { previewDocumentHandler } from "../../../../../utils/HelperFuctions/documnetPreview";

// Constants
import { BUSINESS_NAME_SEARCH, DATE_TIME_FORMAT, UNDERWRITING_DOCUMENTS, underwritingDocumentTypes } from "../../../../../utils/Constants/global";

// API'S
import {
    delete8821Attachment,
    deleteW9Attachment,
    fetch8221Form,
    fetchContract,
    fetchW9Form,
    get8821Attachment,
    getClientBusinessName,
    getClientTermSheet,
    getUWAllDocs,
    getW9Attachment,
} from "../../../../../services/client";
import { openAttachment } from "../../../../../utils/HelperFuctions/openAttachment";
import { useQuery } from "../../../../../utils/HelperFuctions/UseQuery";
import moment from "moment";
import NoteEdit from "../../../Prospect/Components/ChangeStatus/NoteEdit";
import UWDocumentsModal from "../UWDocumentsModal";
import ViewUWDocumentModal from "../ViewUWDocumentModal";
import { ToastContext } from "../../../../../context/toast";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { useLocation } from "react-router-dom";
import { getStorageParam } from "../../../../../utils/Constants/param";
import SendW9Form from "./SendW9Form";
import Send8821Form from "./Send8821Form";
import { extractNameFromClientDocumnetChecklist, fileName } from "../../../../../utils/HelperFuctions/getFileName";
import ViewTermSheetModal from "../../../Prospect/Components/ProspectComponent/ViewTermSheetModal";
import UploadContractModal from "../UploadContractModal";

const ClientDocuments = () => {
    const { screens } = useSelector(mainViewState);

    // State
    const [response, setResponse] = useState([]);
    const [editNotes, setEditNotes] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [uwColumns, setUWColumns] = useState([]);
    const [formDirty, setFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [saveCallStatus, setSaveCallStatus] = useState(false);
    const [signedOwners, setSignedOwners] = useState([]);
    const [attachmentId, setAttachmentId] = useState("");
    const [isW9Attachment, setIsW9Attachment] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [termsheetData, setTermSheetData] = useState([]);
    // Context
    const toast = useContext(ToastContext);

    // Hooks
    let id = getStorageParam();

    // View State
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTerm, setIsLoadingTerm] = useState(false);
    const [isEditCheck, setIsEditCheck] = useState(false);
    const [openAttachmentLoading, setOpenAttachmentLoading] = useState(false);
    const [underWritingDocuments, setUnderWritingDocuments] = useState(false);
    const [veiwunderWritingDocuments, setViewUnderWritingDocuments] = useState(false);
    const [configurationmodal, setConfigurationModal] = useState(false);
    const [selectedAttachmentType, setSelectedAttachmentType] = useState("");
    const [w9Form, setW9Form] = useState([]);
    const [form8821, setForm8821] = useState({});
    const [viewW9Form, setViewW9Form] = useState(false);
    const [view8221Form, setView8221Form] = useState(false);
    const [showTermsheet, setShowTermsheet] = useState(false);
    const [contract, setContract] = useState(false);
    const [uploadContract, setUploadContract] = useState(false);

    const handleSelectUWAttachmentType = (type) => {
        setSelectedAttachmentType(type);
        setViewUnderWritingDocuments(true);
    };
    const getUnderWritingBody = (colField, docs) => (rowData) => {
        if (colField === "attachment") {
            return (
                <>
                    {docs?.includes(rowData.label) ? (
                        <div className={` mr-2 ml-3  ${"document_icon_background"}`} onClick={() => handleSelectUWAttachmentType(rowData.label)}>
                            <Document height="15" width="15" />
                        </div>
                    ) : (
                        <div className="ml-3">No Attachment</div>
                    )}
                </>
            );
        }
        return rowData[colField] ? <div className="p-3">{rowData[colField]}</div> : "";
    };

    const getColumnBody = (colField) => (rowData) => {
        if (colField === "notes") {
            return (
                <>
                    {rowData.notes.map((item, index) => {
                        return (
                            <div key={index} className="mb-2 mt-2 ">
                                <div className="flex ml-3 flex-row justify-content-between align-items-center">
                                    <div>
                                        <span>{item.note}</span>

                                        <span className="ml-4">
                                            <b>{moment(item.updated_at).format(DATE_TIME_FORMAT)}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <Button
                                            type="button"
                                            tooltip="Edit Note"
                                            tooltipOptions={{ position: "top" }}
                                            className="pi pi-pencil edit_pencil"
                                            style={{ fontSize: "18px" }}
                                            onClick={() => editHandler(item)}
                                        />
                                    </div>
                                </div>
                                {index !== rowData.notes.length - 1 && <hr></hr>}
                            </div>
                        );
                    })}
                </>
            );
        } else if (colField === "path") {
            return (
                <>
                    <div
                        className={` mr-2  ml-3 ${rowData.path ? "document_icon_background" : "no_doc"}`}
                        onClick={() => {
                            openDocument(rowData.path);
                            // previewDocumentHandler(rowData.path);
                        }}
                    >
                        {rowData.path ? <Document /> : "No Attachment"}
                    </div>
                </>
            );
        }
        return rowData[colField] ? <div className="p-3"> {rowData[colField]}</div> : "";
    };

    // Business Name Search
    const columnComponents = BUSINESS_NAME_SEARCH.map((col) => {
        if (col.field === "name" || col.field === "path") {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    body={getColumnBody(col.field)}
                    style={{ width: col.header === "Attachment" ? "150px" : "300px" }} // Set width for "name" and "path" columns
                />
            );
        } else {
            return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} />;
        }
    });

    const editHandler = (note) => {
        setEditNotes(note);
        setIsEditCheck(true);
    };

    // API'S Handler
    const fetchBusinessNameandTermSheet = () => {
        setIsLoading(false);
        setIsLoadingTerm(false);
        let payload = {
            prospect_id: id,
        };
        getClientBusinessName(payload).then((res) => {
            setResponse(res.data.attachments);
            setIsLoading(true);
        });
        getClientTermSheet(payload).then((res) => {
            setIsLoadingTerm(true);
            setSignedOwners(res.data.term.owners);
            setTermSheetData(res.data.term);
        });
    };
    const handleFetchDocuments = async () => {
        let docs = [];
        try {
            let data = {
                prospect_id: id,
            };
            const res = await getUWAllDocs(data);
            if (res.data.status) {
                docs = res.data.documents.map((doc) => doc.attachment_type);
                setDocuments(docs);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }

        const columnUnderWriting = UNDERWRITING_DOCUMENTS.map((col) => {
            return <Column key={col.field} field={col.field} header={col.header} body={getUnderWritingBody(col.field, docs)} />;
        });
        setUWColumns(columnUnderWriting);
    };

    const fetchW9FormAttachment = async () => {
        try {
            let data = {
                prospect_id: id,
            };

            const res = await getW9Attachment(data);
            if (res.data.status) {
                setW9Form(res.data.w9_form);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchContractAttachment = async () => {
        try {
            let data = {
                prospect_id: id,
                single: true,
            };

            const res = await fetchContract(data);
            if (res.data.status) {
                setContract(res.data.contract);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetch8221FormAttachment = async () => {
        try {
            let data = {
                prospect_id: id,
            };

            const res = await get8821Attachment(data);
            if (res.data.status) {
                setForm8821(res.data.form_8821);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const onCloseUWModel = () => {
        if (!saveCallStatus) {
            setUnderWritingDocuments(false);
        }
    };
    const onCloseContractModel = () => {
        if (!saveCallStatus) {
            setFormDirty(false);
            setIsVisible(false);
            setUploadContract(false);
        }
    };
    useEffect(() => {
        fetchBusinessNameandTermSheet();
    }, [screens.prospect.isMutated]);

    useEffect(() => {
        fetchContractAttachment();
        handleFetchDocuments();
        fetchW9FormAttachment();
        fetch8221FormAttachment();
    }, []);

    const handleShowAttachment = async (file) => {
        // previewDocumentHandler(file);
        try {
            setOpenAttachmentLoading(true);
            await openAttachment(file);
        } catch (error) {
            console.error("Error opening attachment:", error);
        } finally {
            setOpenAttachmentLoading(false);
        }
    };

    const openDocument = async (attachment) => {
        try {
            setOpenAttachmentLoading(true);
            await openAttachment(attachment);
        } catch (error) {
            console.error("Error opening attachment:", error);
        } finally {
            setOpenAttachmentLoading(false);
        }
    };

    const deleteDocuments = async () => {
        setShowConfirmDeleteDialog(false);
        try {
            let data = {
                id: attachmentId,
            };
            const res = isW9Attachment ? await deleteW9Attachment(data) : await delete8821Attachment(data);
            if (res.data.status) {
                toast.showMessage("Success", "Attachment has been deleted.", "success");
                if (isW9Attachment) {
                    fetchW9FormAttachment();
                } else {
                    fetch8221FormAttachment();
                }
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setIsDeleting(false);
    };

    const handleRemove = (id, type) => {
        setAttachmentId(id);
        setIsW9Attachment(type);
        setShowConfirmDeleteDialog(true);
    };
    return (
        <>
            <Dialog
                onHide={(e) => setConfigurationModal(false)}
                visible={configurationmodal}
                header="Term Sheet Configurations"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "70vw" }}
                blockScroll={true}
            >
                <TermSheet clientmodal={true} onHide={() => setConfigurationModal(false)} />
            </Dialog>

            {!(isLoading && isLoadingTerm) ? (
                <CustomLoading />
            ) : (
                <div className="card doc_client">
                    <Fieldset legend="Business Name Search">
                        <div className="grid">
                            <div className="col-12 md:col-12 brdr_class pr-5">
                                <div className="grid ">
                                    <div className="col-12 ">
                                        <DataTable value={response} responsiveLayout="scroll" id="business-name-search" emptyMessage="No record available.">
                                            {columnComponents}
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    {/* //Termsheet */}
                    <Fieldset legend="Term Sheet" className="fieldset-padding">
                        <div className="grid">
                            <div className="col-12 pr-5 pb-0 pt-0">
                                <div className="text_icons_phone flex flex-row align-items-center justify-content-between">
                                    <p className="mb-0 flex align-items-center">
                                        <BsPaperclip className="icon_size" />
                                        <span className="pl-2 cursor-pointer" onClick={() => setShowTermsheet(true)}>
                                            Termsheet.pdf
                                        </span>
                                    </p>
                                    <span className="send_brdr">
                                        <Button
                                            type="button"
                                            tooltip="Term Sheet Configurations"
                                            tooltipOptions={{ position: "top" }}
                                            icon={<BsFillGearFill />}
                                            className="icon_size"
                                            style={{ border: "0", width: " auto", color: "#fff" }}
                                            onClick={setConfigurationModal}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    {/* //Contract */}
                    <Fieldset legend="Contract" className="fieldset-padding">
                        <div className="grid">
                            <div className="col-12 pr-5 pb-0 pt-0">
                                <div className="text_icons_phone flex flex-row align-items-center justify-content-between">
                                    <p className="mb-0 flex align-items-center">
                                        <BsPaperclip className="icon_size" />
                                        <span className={`pl-2 ${contract?.signed_attachment ? "cursor-pointer" : "disable_span"}`} onClick={() => handleShowAttachment(contract?.signed_attachment)}>
                                            {contract?.signed_attachment ? <>Signed Contract.pdf</> : <>Not uploaded yet!</>}
                                        </span>
                                    </p>
                                    <span className="send_brdr">
                                        <Button
                                            type="button"
                                            tooltip="Upload Contract"
                                            tooltipOptions={{ position: "top" }}
                                            icon={<BsPlus />}
                                            className="icon_size"
                                            style={{ border: "0", width: " auto", color: "#fff", fontSize: "22px", paddingTop: ".4rem" }}
                                            onClick={() => setUploadContract(true)}
                                            disabled={contract?.signed_attachment}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    {/* W9 form */}
                    <Fieldset legend="W9 Form" className="fieldset-padding">
                        <div className="grid">
                            <div className="col-12 pr-5 pb-0 pt-0">
                                <div className="text_icons_phone flex flex-row align-items-center justify-content-between">
                                    <p className="mb-0">
                                        {w9Form.length > 0 ? (
                                            <>
                                                {w9Form.map((file, index) => (
                                                    <div key={index} className=" cursor-pointer">
                                                        <div>
                                                            <div className="flex row align-items-center" key={index}>
                                                                <BsPaperclip className="icon_size mr-2" />
                                                                <span className="text_break" onClick={() => handleShowAttachment(file.pdf_path)}>
                                                                    {fileName(file.pdf_path)}
                                                                </span>
                                                                <BsXLg className="cursor-pointer cross_icon mr-4" onClick={() => handleRemove(file.id, true)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="mb-0 flex align-items-center">
                                                <BsPaperclip className="icon_size" />
                                                <span className={`pl-2 disable_span`}>
                                                    <>Not uploaded yet!</>
                                                </span>
                                            </p>
                                        )}
                                    </p>
                                    <span className="send_brdr">
                                        <Button
                                            type="button"
                                            tooltip="Attach W9 Form"
                                            disabled={w9Form?.agreement_id}
                                            tooltipOptions={{ position: "top" }}
                                            onClick={() => setViewW9Form(true)}
                                            icon={<BsPlus />}
                                            className="icon_size"
                                            style={{ border: "0", width: " auto", color: "#fff", fontSize: "22px", paddingTop: ".4rem" }}
                                        />
                                        {/* <BsSend className="icon_size" /> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    {/* 8821 form */}
                    <Fieldset legend="8821 Form" className="fieldset-padding">
                        <div className="grid">
                            <div className="col-12 pr-5 pb-0 pt-0">
                                <div className="text_icons_phone flex flex-row align-items-center justify-content-between">
                                    <p className="mb-0">
                                        {form8821.length > 0 ? (
                                            <>
                                                {form8821.map((file, index) => (
                                                    <div key={index} className=" cursor-pointer">
                                                        <div>
                                                            <div className="flex row  align-items-center" key={index}>
                                                                <BsPaperclip className="icon_size mr-2" />
                                                                <span className="text_break" onClick={() => handleShowAttachment(file.path)}>
                                                                    {fileName(file.path)}
                                                                </span>
                                                                <BsXLg className="cursor-pointer cross_icon mr-4" onClick={() => handleRemove(file.id, false)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="mb-0 flex align-items-center">
                                                <BsPaperclip className="icon_size" />
                                                <span className={`pl-2 disable_span`}>
                                                    <>Not uploaded yet!</>
                                                </span>
                                            </p>
                                        )}
                                    </p>
                                    <span className="send_brdr">
                                        <Button
                                            type="button"
                                            tooltip="Attach 8821 Form"
                                            tooltipOptions={{ position: "top" }}
                                            onClick={() => setView8221Form(true)}
                                            disabled={form8821?.agreement_id}
                                            icon={<BsPlus />}
                                            className="icon_size"
                                            style={{ border: "0", width: " auto", color: "#fff", fontSize: "22px", paddingTop: ".4rem" }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                    {/* Underwriting Documents */}
                    <Fieldset legend="Underwriting Documents" className="fieldset-padding">
                        <div className="grid">
                            <div className="col-12 pr-5">
                                <div className="text_icons_phone flex flex-row align-items-center justify-content-end">
                                    <span className="send_brdr">
                                        <Button
                                            type="button"
                                            tooltip="Add Documents"
                                            tooltipOptions={{ position: "top" }}
                                            icon={<BsPlus />}
                                            className="icon_size"
                                            style={{ border: "0", width: " auto", color: "#fff", fontSize: "22px", paddingTop: ".4rem" }}
                                            onClick={() => setUnderWritingDocuments(true)}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-12 brdr_class pr-5">
                                <div className="grid ">
                                    <div className="col-12 ">
                                        <DataTable value={underwritingDocumentTypes} responsiveLayout="scroll" id="underwriting-document" emptyMessage="No record available.">
                                            {uwColumns}
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            )}
            <Dialog blockScroll={true} visible={isEditCheck} onHide={() => setIsEditCheck(false)} header="Edit Notes" breakpoints={{ "960px": "60vw", "640px": "100vw" }} style={{ width: "30vw" }}>
                {isEditCheck && <NoteEdit editNotes={editNotes} onHide={() => setIsEditCheck(false)} />}
            </Dialog>
            <Dialog
                blockScroll={true}
                visible={underWritingDocuments}
                onHide={() => {
                    formDirty ? setIsVisible(true) : onCloseUWModel();
                }}
                header="Underwriting Documents"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                {underWritingDocuments && (
                    <UWDocumentsModal
                        underWritingDocuments={underWritingDocuments}
                        onHide={() => setUnderWritingDocuments(false)}
                        id={id}
                        onCall={() => handleFetchDocuments()}
                        setFormDirty={setFormDirty}
                        setSaveCallStatus={setSaveCallStatus}
                    />
                )}
            </Dialog>

            <Dialog
                blockScroll={true}
                visible={uploadContract}
                onHide={() => {
                    formDirty ? setIsVisible(true) : onCloseContractModel();
                }}
                header="Contract"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                {uploadContract && (
                    <UploadContractModal
                        onHide={() => setUploadContract(false)}
                        id={id}
                        onCall={() => fetchContractAttachment()}
                        setFormDirty={setFormDirty}
                        setSaveCallStatus={setSaveCallStatus}
                        contractId={contract?.id}
                        setUploadContract={setUploadContract}
                    />
                )}
            </Dialog>

            <Dialog
                blockScroll={true}
                visible={veiwunderWritingDocuments}
                onHide={() => setViewUnderWritingDocuments(false)}
                header="Underwriting Documents"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                {veiwunderWritingDocuments && (
                    <ViewUWDocumentModal
                        veiwunderWritingDocuments={veiwunderWritingDocuments}
                        onHide={() => setViewUnderWritingDocuments(false)}
                        handleFetchDocuments={handleFetchDocuments}
                        id={id}
                        selectedAttachmentType={selectedAttachmentType}
                    />
                )}
            </Dialog>

            <Dialog blockScroll={true} visible={viewW9Form} onHide={() => setViewW9Form(false)} header="Attach W9 Form" breakpoints={{ "960px": "60vw", "640px": "100vw" }} style={{ width: "30vw" }}>
                {viewW9Form && (
                    <SendW9Form
                        prospectId={id}
                        onHide={() => {
                            setViewW9Form(false);
                            fetchW9FormAttachment();
                        }}
                    />
                )}
            </Dialog>

            <Dialog
                blockScroll={true}
                visible={view8221Form}
                onHide={() => setView8221Form(false)}
                header="Attach 8821 Form"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "30vw" }}
            >
                {view8221Form && (
                    <Send8821Form
                        prospectId={id}
                        onHide={() => {
                            setView8221Form(false);
                            fetch8221FormAttachment();
                        }}
                    />
                )}
            </Dialog>

            <Dialog
                blockScroll={true}
                header="Term Sheet"
                visible={showTermsheet}
                onHide={() => setShowTermsheet(false)}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw", height: "50vh" }}
            >
                <ViewTermSheetModal signedOwners={signedOwners} term={termsheetData} />
            </Dialog>

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data1"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setUploadContract(false);
                        setUnderWritingDocuments(false);
                        setIsVisible(false);
                        setFormDirty(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            {openAttachmentLoading && <CustomLoading center />}

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => deleteDocuments()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}
        </>
    );
};

export default ClientDocuments;
