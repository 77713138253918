import React, { useEffect, useState, useContext } from "react";

// Constants
import FilterBar from "./GridView/FilterBy";
import Body from "./GridView/Body";
import { INVOICES_GRID_HEADERS, INVOICES_GRID_HEADERS_FOR_CLIENT, ROLE_NAME } from "../../../../../../utils/Constants/global";
import { ToastContext } from "../../../../../../context/toast";
import { deleteInvoices, getInvoices } from "../../../../../../services/invoice";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../redux/login";

const Invoices = (props) => {
    const { setSelectedStatus, selectedStatus } = props;

    //Redux
    const { user } = useSelector(loginState);

    // State
    const [keyword, setKeyword] = useState("");
    const [onSelectionColumn] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);

    const [selectedColumns, setSelectedColumns] = useState(INVOICES_GRID_HEADERS);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [dialogCheck, setDialogCheck] = useState(true);

    const [kebabMenuOption, setKebabMenuOption] = useState({
        move_to_schedule: false,
    });

    // PAGINATION
    // States
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });
    const [responseData, setResponseData] = useState([]);
    // View State
    const [loading, setLoading] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    // Api Handler
    const fetchInvoices = async (page) => {
        setLoading(true);
        const form = new FormData();
        form.append("per_page", pageSize);
        form.append("page", page ? page : currentPage);
        form.append("search_text", keyword);
        form.append("sort_desc", "desc");

        if (selectedStatus) {
            form.append("status", selectedStatus);
        }

        if (showAll) {
            form.append("deleted", showAll);
        }

        if (sortOrder) {
            form.append("sort_desc", sortOrder === 1 ? "asc" : "desc");
        }

        try {
            const res = await getInvoices(form);
            if (res.data.status) {
                setResponseData(res.data.invoices.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.invoices.total,
                    totalPages: res.data.invoices.last_page,
                    isLastPage: res.data.invoices.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);

        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            INVOICES_GRID_HEADERS_FOR_CLIENT.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        } else {
            INVOICES_GRID_HEADERS.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, [showAll, sortField, sortOrder, pageSize, currentPage, selectedStatus]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    fetchInvoices();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    // delete Invoices
    const invoiceDelete = async () => {
        setDialogCheck(true);
        const invoiceIds = selectedInvoices.map((invoice) => invoice.id);
        let payload = {
            id: invoiceIds,
        };
        try {
            let res = await deleteInvoices(payload);
            if (res.data.status) {
                toast.deleteToast("Invoice");
                fetchInvoices();
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setSelectedInvoices([]);
        setDialogCheck(true);
    };

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            setSelectedColumns(INVOICES_GRID_HEADERS_FOR_CLIENT);
        }
    }, []);

    return (
        <>
            <FilterBar
                setSelectedStatus={setSelectedStatus}
                selectedStatus={selectedStatus}
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                setKebabMenuOption={setKebabMenuOption}
                selectedInvoices={selectedInvoices}
                handleDeleteInvoice={invoiceDelete}
                dialogCheck={dialogCheck}
            />
            <Body
                selectedHeaders={responseData}
                selectedInvoices={selectedInvoices}
                setSelectedColumns={setSelectedColumns}
                setSelectedInvoices={setSelectedInvoices}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                setPage={setPage}
                page={page}
                fetchInvoices={fetchInvoices}
                setDialogCheck={setDialogCheck}
            />
        </>
    );
};

export default Invoices;
