import { trimTextWithDots } from "./trimText";

export function fileName(link) {
    const regex = /\/([^/]+\.(pdf|png|jpe?g))\//;
    // const regex = /\/([^/]+)\.pdf\//;
    const match = link.match(regex);

    if (match && match[1]) {
        const nameWithEncodedCharacters = match[1];
        const decodedName = decodeURIComponent(nameWithEncodedCharacters);
        const removeCharacters = decodedName.slice(13);
        return removeCharacters;
    }
}

export function extractNameFromURL(file) {
    if (file) {
        const lastSlashIndex = file.lastIndexOf("/");
        const pngExtensionIndex = file.lastIndexOf(".");
        const extractedName = file.substring(lastSlashIndex + 1, pngExtensionIndex);
        const removeCharacters = extractedName.slice(13);
        return removeCharacters;
    }
}

export function extractNameFromClientDocumnetChecklist(file) {
    if (file) {
        const lastSlashIndex = file.lastIndexOf("/");
        const pngExtensionIndex = file.lastIndexOf(".");
        const extractedName = file.substring(lastSlashIndex + 1, pngExtensionIndex);
        const removeCharacters = extractedName;
        return removeCharacters;
    }
}
