import React from "react";
//styles
import "./userprofile.scss";
import { TabView, TabPanel } from "primereact/tabview";
import AccountDetails from "./Screens/AccountDetails/AccountDetails";
import Security from "./Screens/Security/Security";

const UserProfile = () => {
    return (
        <div className="user_profile">
            <h4 className="pt-2">
                <b>User Profile</b>
            </h4>
            <div className="card mt-6">
                <TabView>
                    <TabPanel header="Account Details">
                        <AccountDetails />
                    </TabPanel>
                    <TabPanel header="Change Password">
                        <Security />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    );
};

export default UserProfile;
