import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

// Prime React
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { Accordion, AccordionTab } from "primereact/accordion";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onClientBusinessProprietorFormDirty, onClientDataChange, onFormDirty } from "../../../../../../../redux/main-view";

// Context
import { ToastContext } from "../../../../../../../context/toast";

// Icons
import { BsCheck, BsPencil, BsPlus, BsTrash3 } from "react-icons/bs";

// Helper Function
import { validateEmail } from "../../../../../../../utils/HelperFuctions/checkEmail";
import formDirtyHandler from "../../../../../../../utils/HelperFuctions/clientHelper";
import { PercentageCheck } from "../../../../../../../utils/HelperFuctions/checkPercentage";
import { convertingDateToUTC } from "../../../../../../../utils/HelperFuctions/convertingDateToUTC";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../../../../utils/HelperFuctions/cleanData";

// Constants
import { HOME_OWNERSHIP_OPTION } from "../../../../../../../utils/Constants/global";

// UI Components
import CustomCardSpinner from "../../../../../../../ui/CustomCardSpinner/CustomCardSpinner";
import ZipCodesMenuComponent from "../../../../../../../ui/ZipCodesMenu/ZipCodesMenuComponent";

// API'S
import { getZipCode } from "../../../../../../../services/zip-code";
import { ownerDelete, saveProspect } from "../../../../../../../services/prospect";
import { loginState } from "../../../../../../../redux/login";

const initialValidationState = {
    name: false,
    percent_owned: false,
    percent_check: false,
    email: false,
    email_check: false,
    house_owned: false,
    ssn: false,
    phone: false,
    dob: false,
    zip: false,
};

const ProprietorInformation = (props) => {
    const { user } = useSelector(loginState);

    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // States
    const [checked, setChecked] = useState([]);
    const [activeTabs, setActiveTabs] = useState([]);
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [formArray, setFormArray] = useState([
        {
            name: "",
            percent_owned: "",
            email: "",
            house_owned: "",
            ssn: "",
            dob: "",
            phone: "",
            country_phone_code: "+1",
            zip: "",
            city: "",
            state: "",
            driver_license: "",
            address: "",
            driving_license_expiry_date: "",
            zipCode: [],
            recordNotFoundMsg: "",
            license_mask: "999-99-9999",
            validations: {
                ...initialValidationState,
            },
        },
    ]);
    const [hundredChecker, setHundredChecker] = useState([0, 0, 0, 0, 0]);
    const { setOwnerIds } = props;
    // View State
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isZipCodeSearch, setIsZipSearch] = useState(true);
    const [isButtonClick, setIsButtonClick] = useState(true);
    const [isCalenderVisibleDOB, setIsCalenderVisibleDOB] = useState([false, false, false, false, false]);
    const [isCalenderVisible, setIsCalenderVisible] = useState([false, false, false, false, false]);

    // Handlers
    const handleCalendarVisibleChange = (e, index, name) => {
        if (name === "dob") {
            let visiblity = [...isCalenderVisibleDOB];
            visiblity[index] = e.visible;
            setIsCalenderVisibleDOB(visiblity);
        } else {
            let visiblity = [...isCalenderVisible];
            visiblity[index] = e.visible;
            setIsCalenderVisible(visiblity);
        }
    };
    const inputFieldDataCleaningHandler = (e, item, name, value, index) => {
        const validations = item.validations;
        if (name === "house_owned" || name === "country_phone_code") {
            return {
                ...item,
                [name]: value,
                validations: {
                    ...validations,
                    [name]: false,
                },
            };
        } else if (name === "email") {
            return {
                ...item,
                [name]: value,
                validations: {
                    ...validations,
                    [name]: false,
                    email_check: !validateEmail(value),
                },
            };
        } else if (name === "name") {
            return {
                ...item,
                [name]: toTitleCaseWhileTyping(value),
                validations: {
                    ...validations,
                    [name]: false,
                },
            };
        } else if (name === "dob" || name === "driving_license_expiry_date") {
            if (e.originalEvent.key === "Enter" || e.originalEvent.type === "blur") {
                // Close the calendar modal
                setIsCalenderVisible([false, false, false, false, false]);
                setIsCalenderVisibleDOB([false, false, false, false, false]);
                // if (e.originalEvent.type === "blur") {
                const nextInput = document.getElementById(name === "dob" ? `zip-${index}` : "saveButton");
                if (nextInput) {
                    // nextInput.focus();
                    setIsButtonClick(false);
                }
                // }
            }
            return {
                ...item,
                [name]: value ? convertingDateToUTC(value) : "",
                // [name]: value ? new Date(value) : "",
                validations:
                    name === "driving_license_expiry_date"
                        ? {
                              ...validations,
                          }
                        : {
                              ...validations,
                              [name]: false,
                          },
            };
        } else {
            return {
                ...item,
                [name]: textExtraSpaceRemoval(value),
                validations: {
                    ...validations,
                    [name]: false,
                },
            };
        }
    };
    const handleInputChange = (e, index) => {
        dispatch(onFormDirty(true));
        dispatch(onClientBusinessProprietorFormDirty(true));
        let { name, value } = e.target;
        let cleanValue = name === "house_owned" || name === "dob" || name === "country_phone_code" || name === "driving_license_expiry_date" ? value : textExtraSpaceRemoval(value);
        let hundredChecker_temp = hundredChecker;
        const updatedFormArray = formArray.map((item, i) => {
            const validations = item.validations;
            let temp = 0;
            if (i === index) {
                if (name === "percent_owned") {
                    cleanValue = PercentageCheck(cleanValue);
                    hundredChecker_temp[index] = cleanValue;
                    setHundredChecker(hundredChecker_temp);
                    hundredChecker_temp.map((item) => {
                        temp = temp + Number(item);
                    });
                    return {
                        ...item,
                        [name]: cleanValue,
                        validations: {
                            ...validations,
                            [name]: false,
                            percent_check: temp > 100 ? true : false,
                        },
                    };
                } else {
                    return inputFieldDataCleaningHandler(e, item, name, value, index);
                }
            }
            return {
                ...item,
                validations: {
                    ...validations,
                    percent_check: temp > 100 ? true : false,
                },
            };
        });
        if (name === "zip") {
            if (cleanValue.length >= 4 && isZipCodeSearch) {
                fetchZipCodeData(cleanValue, index);
            } else {
                updatedFormArray[index]["zipCode"] = [];
                updatedFormArray[index]["state"] = "";
                updatedFormArray[index]["city"] = "";
                updatedFormArray[index]["license_mask"] = "999-99-9999";
                setIsZipSearch(true);
            }
        }
        setFormArray(updatedFormArray);
    };
    // const handleInputChange = (e, index) => {
    //     dispatch(onFormDirty(true));
    //     dispatch(onClientBusinessProprietorFormDirty(true));
    //     let { name, value } = e.target;
    //     let cleanValue = null;
    //     if (name === "house_owned" || name === "dob" || name === "country_phone_code" || name === "driving_license_expiry_date") {
    //     } else {
    //         cleanValue = textExtraSpaceRemoval(value);
    //     }
    //     let hundredChecker_temp = hundredChecker;
    //     const updatedFormArray = formArray.map((item, i) => {
    //         const validations = item.validations;
    //         let temp = 0;
    //         if (i === index) {
    //             if (name === "house_owned" || name === "country_phone_code") {
    //                 return {
    //                     ...item,
    //                     [name]: value,
    //                     validations: {
    //                         ...validations,
    //                         [name]: false,
    //                     },
    //                 };
    //             } else if (name === "dob" || name === "driving_license_expiry_date") {
    //                 if (value) {
    //                     if (name === "driving_license_expiry_date") {
    //                         return {
    //                             ...item,
    //                             [name]: new Date(value),
    //                             validations: {
    //                                 ...validations,
    //                             },
    //                         };
    //                     }
    //                     return {
    //                         ...item,
    //                         [name]: new Date(value),
    //                         validations: {
    //                             ...validations,
    //                             [name]: false,
    //                         },
    //                     };
    //                 } else {
    //                     return {
    //                         ...item,
    //                         [name]: "",
    //                         validations: {
    //                             ...validations,
    //                         },
    //                     };
    //                 }
    //             } else if (name === "email") {
    //                 return {
    //                     ...item,
    //                     [name]: value,
    //                     validations: {
    //                         ...validations,
    //                         [name]: false,
    //                         email_check: value ? !validateEmail(value) : false,
    //                     },
    //                 };
    //             } else if (name === "name") {
    //                 return {
    //                     ...item,
    //                     [name]: toTitleCaseWhileTyping(value),
    //                     validations: {
    //                         ...validations,
    //                         [name]: false,
    //                     },
    //                 };
    //             } else {
    //                 if (name === "percent_owned") {
    //                     cleanValue = PercentageCheck(cleanValue);
    //                     hundredChecker_temp[index] = cleanValue;
    //                     setHundredChecker(hundredChecker_temp);
    //                     hundredChecker_temp.map((item) => {
    //                         temp = temp + Number(item);
    //                     });
    //                     return {
    //                         ...item,
    //                         [name]: cleanValue,
    //                         validations: {
    //                             ...validations,
    //                             [name]: false,
    //                             percent_check: temp > 100 ? true : false,
    //                         },
    //                     };
    //                 } else {
    //                     return {
    //                         ...item,
    //                         [name]: cleanValue,
    //                         validations: {
    //                             ...validations,
    //                             [name]: false,
    //                         },
    //                     };
    //                 }
    //             }
    //         }
    //         return {
    //             ...item,
    //             validations: {
    //                 ...validations,
    //                 percent_check: temp > 100 ? true : false,
    //             },
    //         };
    //     });

    //     if (name === "zip") {
    //         if (cleanValue.length >= 4 && isZipCodeSearch) {
    //             fetchZipCodeData(cleanValue, index);
    //         } else {
    //             updatedFormArray[index]["zipCode"] = [];
    //             updatedFormArray[index]["state"] = "";
    //             updatedFormArray[index]["city"] = "";
    //             updatedFormArray[index]["license_mask"] = "999-99-9999";
    //             setIsZipSearch(true);
    //         }
    //     }
    //     setFormArray(updatedFormArray);
    // };

    const handleAddMore = () => {
        if (formArray.length === 5) {
            toast.showMessage("Limit", "Maximum 5 alloweds.", "error");
        } else {
            dispatch(onFormDirty(true));
            dispatch(onClientBusinessProprietorFormDirty(true));
            setActiveTabs([formArray.length]);
            setFormArray([
                ...formArray,
                {
                    name: "",
                    percent_owned: "",
                    email: "",
                    house_owned: "",
                    ssn: "",
                    dob: "",
                    phone: "",
                    country_phone_code: "+1",
                    zip: "",
                    city: "",
                    state: "",
                    driver_license: "",
                    address: "",
                    driving_license_expiry_date: "",
                    zipCode: [],
                    recordNotFoundMsg: "",
                    license_mask: "999-99-9999",
                    validations: {
                        ...initialValidationState,
                    },
                },
            ]);
        }
    };

    const handleRemoveForm = async (values) => {
        if (values) {
            const updatedFormArray = formArray.filter((_, i) => !values.includes(i));
            setFormArray(updatedFormArray);
            setChecked([]);
        } else {
            setIsSaving(true);
            // Deleting Data from Backend
            const res = formArray.filter((_, i) => checked.includes(i));

            if (res.length > 0) {
                const ids = res.map((item) => item.id);
                try {
                    setIsLoading(true);
                    let payload = {
                        id: ids,
                    };
                    const res = await ownerDelete(payload);
                    if (res.data.status) {
                        let client_data = JSON.parse(JSON.stringify(screens.client.data));
                        let _owners = client_data.owners;
                        _owners = _owners.filter((item, i) => !ids.includes(item.id));
                        client_data["owners"] = _owners;
                        dispatch(onClientDataChange(client_data));
                        setIsLoading(false);
                        setIsSaving(false);
                    } else {
                        toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
                        setIsLoading(false);
                        setIsSaving(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setIsSaving(false);
                    toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            }
            const updatedFormArray = formArray.filter((_, i) => !checked.includes(i));
            setFormArray(updatedFormArray);
            setChecked([]);
        }
    };
    const reamianingPercentage = () => {
        let temp = 100;
        for (let i = 0; i < formArray.length; i++) {
            temp = temp - Number(hundredChecker[i]);
        }
        const formattedValue = Number(temp.toFixed(2));
        if (formattedValue > 0) {
            return `Avaiable Percentage is ${formattedValue}`;
        } else if (formattedValue < 0) {
            const newValue = formattedValue.toString().slice(1);
            return `Exceeding Percentage is ${newValue}`;
        }
    };
    const selectedZipCode = (zip, indexing) => {
        setIsZipSearch(false);
        formArray[indexing]["recordNotFoundMsg"] = "";
        formArray[indexing]["zip"] = zip.zip;
        formArray[indexing]["validations"]["zip"] = false;
        formArray[indexing]["state"] = zip.full_state;
        formArray[indexing]["city"] = zip.city;
        formArray[indexing]["license_mask"] = zip.license_mask;
        formArray[indexing]["zipCode"] = [];
        setFormArray(formArray);
    };
    // Removing Empty form
    const handleEmptyObject = (updatedFormArray) => {
        let temp = [];
        let filteredData = updatedFormArray.filter((obj, index) => {
            if (obj.name || obj.percent_owned || obj.email || obj.house_owned || obj.ssn || obj.phone || obj.dob || obj.zip) {
                return true;
            } else {
                if (index === 0) {
                    return false;
                } else {
                    temp.push(index);
                    return false;
                }
            }
        });

        if (temp.length !== 0) {
            handleRemoveForm(temp);
        }
        return filteredData;
    };

    const saveHandler = async () => {
        if (screens.client.isBusinessProprietorFormDirty) {
            try {
                let hasError = false;

                const updatedFormArray = formArray.map((item) => {
                    const { validations, country_phone_code, ...rest } = item;

                    if (
                        item.name === "" ||
                        item.percent_owned === "" ||
                        item.email === "" ||
                        item.house_owned === "" ||
                        item.ssn === "" ||
                        item.phone === "" ||
                        item.dob === "" ||
                        item.zip === "" ||
                        validations.percent_check ||
                        validations.email_check ||
                        item.state === ""
                    ) {
                        hasError = true;
                        return {
                            ...rest,
                            country_phone_code,
                            validations: {
                                ...validations,
                                name: item.name === "",
                                percent_owned: item.percent_owned === "",
                                email: item.email === "",
                                house_owned: item.house_owned === "",
                                ssn: item.ssn === "",
                                phone: item.phone === "",
                                dob: item.dob === "",
                                zip: item.zip === "" || item.state === "",
                            },
                        };
                    } else {
                        return {
                            ...rest,
                            validations,
                            country_phone_code: country_phone_code.phone_code ? country_phone_code.phone_code : country_phone_code,
                        };
                    }
                });

                if (hasError) {
                    setFormArray(updatedFormArray);
                    return;
                }
                setIsSaving(true);

                // Cleaning States
                const data = updatedFormArray.map((item) => {
                    const { validations, zipCode, recordNotFoundMsg, license_mask, ...rest } = item;
                    return {
                        ...rest,
                    };
                });
                let payload = {
                    owners: data,
                    step: "2",
                    id: screens.client.data.id,
                };

                let res = await saveProspect(payload);
                if (res) {
                    if (res.data.status) {
                        setChecked([]);
                        setActiveTabs([]);
                        formDirtyHandler();
                        setIsDisabled(true);
                        dispatch(onFormDirty(false));
                        dispatch(onClientDataChange(res.data.prospect));
                        dispatch(onClientBusinessProprietorFormDirty(false));
                    } else {
                        toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                    }
                } else {
                    toast.showMessage("Error", "Server Error", "error");
                    setIsSaving(false);
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
            }
        } else {
            // Removing Empty Object
            handleEmptyObject(formArray);
            setIsDisabled(true);
            setActiveTabs([]);
            setChecked([]);
        }
    };

    // API Handler's
    const fetchZipCodeData = (value, index) => {
        const paylod = {
            zip: value,
        };
        getZipCode(paylod).then((res) => {
            formArray[index]["zip"] = value;
            formArray[index]["state"] = "";
            formArray[index]["city"] = "";
            formArray[index]["zipCode"] = res.data.codes;
            setFormArray(formArray);
            if (res.data.codes.length == 0) {
                formArray[index]["zip"] = value;
                formArray[index]["zipCode"] = [];
                formArray[index]["recordNotFoundMsg"] = "No Record Available";
                setFormArray(formArray);
            }
        });
    };

    const handleCheckedChange = (index) => {
        const updatedChecked = [...checked];
        const exist = checked.includes(index);
        if (exist) {
            const value = checked.indexOf(index);
            updatedChecked.splice(value, 1);
        } else {
            updatedChecked.push(index);
        }
        setChecked(updatedChecked);
    };

    const HeaderItems = (index, name, email) => {
        return (
            <>
                <div>
                    <Checkbox checked={checked.includes(index)} onChange={(e) => handleCheckedChange(index)} />
                </div>
                <div className="flex flex-column pl-2 pt-2 pb-2">
                    <h5 className="m-0">{name}</h5>
                    <h6 className="pl-0 pt-1 m-0">{email}</h6>
                </div>
            </>
        );
    };

    // UseEffect
    useEffect(() => {
        if (screens.client.data) {
            let ownerIds = [];
            let temp = [];
            screens.client.data.owners.map((item, index) => {
                const data = {
                    id: item.id,
                    name: item.name,
                    percent_owned: item.percent_owned,
                    email: item.email,
                    house_owned: item.house_owned ? 1 : 0,
                    ssn: item.ssn,
                    dob: moment(item.dob).format("YYYY-MM-DD"),
                    phone: item.phone,
                    country_phone_code: item.country_phone_code,
                    zip: item.zip,
                    city: item.city,
                    state: item.state,
                    driver_license: item.driver_license,
                    license_mask: item.driver_license,
                    driving_license_expiry_date: item.driving_license_expiry_date ? moment(item.driving_license_expiry_date).format("YYYY-MM-DD") : "",
                    address: item.address,
                    zipCode: [],
                    recordNotFoundMsg: "",
                    validations: {
                        ...initialValidationState,
                    },
                };
                hundredChecker[index] = item.percent_owned;
                temp.push(data);
                ownerIds.push(item.id);
            });
            setFormArray(temp);
            setOwnerIds(ownerIds);
        }
    }, []);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCodeOptions(screens.prospect.countryCodes);
        }
    }, [screens.prospect.countryCodes]);

    useEffect(() => {
        setTimeout(() => {
            setIsButtonClick(true);
        }, 1);
    }, [isButtonClick]);

    return (
        <>
            <div className="main_brdr_class bank_info">
                <form>
                    <div className="grid">
                        {/* Header */}
                        <div className="md:col-7 col-6">
                            <div className="">
                                <h5>
                                    <b>Owner Information</b>
                                </h5>
                            </div>
                        </div>
                        {/* //Header Button */}
                        <div className="md:col-5 col-6 text-right">
                            {checked.length !== 0 && (
                                <Button
                                    tooltip="Delete"
                                    tooltipOptions={{ position: "top" }}
                                    className="delete_icon mr-2"
                                    icon={<BsTrash3 />}
                                    disabled={isSaving}
                                    type="button"
                                    onClick={() => (isSaving ? null : handleRemoveForm())}
                                />
                            )}

                            {isDisabled ? (
                                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit_icon" icon={<BsPencil />} type="button" onClick={() => setIsDisabled(false)} />
                            ) : (
                                <>
                                    <Button tooltip="Add More" tooltipOptions={{ position: "top" }} className="edit_icon mr-2" icon={<BsPlus />} type="button" onClick={() => handleAddMore()} />
                                    <Button
                                        id="saveButton"
                                        tooltip="Save"
                                        tooltipOptions={{ position: "top" }}
                                        className={isSaving ? "check_tick_icon_disabled" : "check_tick_icon"}
                                        icon={<BsCheck />}
                                        disabled={isSaving}
                                        type="button"
                                        onClick={() => (isButtonClick ? (isSaving ? null : saveHandler()) : null)}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    {/* Body */}
                    {isLoading ? (
                        <CustomCardSpinner />
                    ) : (
                        <div className="business_scrollbar">
                            {formArray.length === 0 ? (
                                <h5 className="m-6 no_record_rext">No Business Owners Available</h5>
                            ) : (
                                <div className="grid accordian_tab">
                                    <div className="col-12">
                                        {/* <Accordion activeIndex={null}> */}
                                        <Accordion
                                            multiple={true}
                                            activeIndex={activeTabs}
                                            onTabChange={(e) => {
                                                setActiveTabs(e.index);
                                            }}
                                        >
                                            {formArray.map((item, index) => (
                                                <AccordionTab headerTemplate={HeaderItems(index, item?.name, item?.email)} disabled={isDisabled} key={index}>
                                                    <div className="grid">
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Full Name<span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="md:col-6 col-12">
                                                            <InputText
                                                                id="name"
                                                                name="name"
                                                                type="text"
                                                                placeholder="Enter Full Name "
                                                                maxLength="40"
                                                                value={item.name}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_cursor"
                                                            />
                                                            {item.validations.name && <small className="p-error">Name is required</small>}
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Ownership Percentage <span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="md:col-6 col-12">
                                                            <InputText
                                                                id="percent_owned"
                                                                name="percent_owned"
                                                                type="text"
                                                                placeholder="Enter Ownership Percentage"
                                                                keyfilter={/^\d*\.?\d*$/}
                                                                maxLength="5"
                                                                value={item.percent_owned}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_cursor"
                                                            />
                                                            {/* {item.validations.percent_owned && <small className="p-error">Ownership Percentage is required</small>} */}
                                                            {item.validations.percent_owned && <small className="p-error">Ownership Percentage is required</small>}
                                                            {item.validations.percent_check && <small className="p-error"> {reamianingPercentage()}</small>}
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Email<span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <InputText
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                placeholder="Enter Email"
                                                                maxLength="50"
                                                                value={item.email}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_cursor"
                                                            />
                                                            {/* {item.validations.email && <small className="p-error">Email is required</small>} */}
                                                            {item.validations.email
                                                                ? item.validations.email && <small className="p-error">Email is required</small>
                                                                : item.validations.email_check && <small className="p-error">Invalid Email</small>}
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Home Ownership<span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <Dropdown
                                                                id="house_owned"
                                                                name="house_owned"
                                                                placeholder="Select"
                                                                value={item.house_owned}
                                                                options={HOME_OWNERSHIP_OPTION}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                optionLabel="name"
                                                                className="custom_cursor"
                                                            />
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        SSN <span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            {
                                                                user?.sensitive_information_disclosure ? 
                                                                (
                                                                    <InputMask
                                                                        name="ssn"
                                                                        id="ssn"
                                                                        type="text"
                                                                        mask="999-99-9999"
                                                                        placeholder="999-99-9999"
                                                                        value={item.ssn}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        className="custom_cursor"
                                                                        autoComplete="off"
                                                                    />
                                                                ) : !user?.sensitive_information_disclosure && !formArray[index].id ? (
                                                                    <InputMask
                                                                        name="ssn"
                                                                        id="ssn"
                                                                        type="text"
                                                                        mask="999-99-9999"
                                                                        placeholder="999-99-9999"
                                                                        value={item.ssn}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        className="custom_cursor"
                                                                        autoComplete="off"
                                                                    />
                                                                )  :  !user?.sensitive_information_disclosure && formArray[index].id  ? (
                                                                    <InputText
                                                                        name="ssn"
                                                                        id="ssn"
                                                                        type="text"
                                                                        placeholder="999-99-9999"
                                                                        value={item.ssn}
                                                                        onChange={(e) => handleInputChange(e, index)}
                                                                        className="custom_cursor"
                                                                        autoComplete="off"
                                                                        disabled={true}
                                                                    />
                                                                ) : ""

                                                            }

                                                            {item.validations.ssn && <small className="p-error">SSN is required</small>}
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Phone No <span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <div className="border_div">
                                                                <Dropdown
                                                                    id="country_phone_code"
                                                                    name="country_phone_code"
                                                                    placeholder={item.country_phone_code.phone_code ?? item.country_phone_code}
                                                                    optionLabel="phone_code"
                                                                    value={item.country_phone_code}
                                                                    options={countryCodeOptions}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                    className="proscustom_width_country"
                                                                    filter
                                                                    filterBy="phone_code"
                                                                    emptyMessage="No results found"
                                                                />
                                                                <InputMask
                                                                    iden="phone"
                                                                    name="phone"
                                                                    mask="999-999-9999"
                                                                    placeholder="999-999-9999"
                                                                    value={item.phone}
                                                                    onChange={(e) => handleInputChange(e, index)}
                                                                    className="proscustom_width_phone"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            {item.validations.phone && <small className="p-error">Phone is required</small>}
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Date of Birth <span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <Calendar
                                                                name="dob"
                                                                id="dob"
                                                                value={new Date(item.dob)}
                                                                placeholder="mm/dd/yyyy"
                                                                mask="99/99/9999"
                                                                showIcon
                                                                maxDate={new Date()}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_border_none"
                                                                onVisibleChange={(e) => handleCalendarVisibleChange(e, index, "dob")}
                                                                visible={isCalenderVisibleDOB[index]}
                                                                monthNavigator
                                                                yearNavigator
                                                                yearRange="1945:2050"
                                                            />
                                                            {item.validations.dob && <small className="p-error">Date is required</small>}
                                                        </div>

                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>
                                                                        Zip Code <span className="clr_red">*</span>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <InputText
                                                                name="zip"
                                                                id={`zip-${index}`}
                                                                type="text"
                                                                placeholder="Search..."
                                                                keyfilter="int"
                                                                maxLength="5"
                                                                value={item.zip}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_cursor"
                                                            />
                                                            {item.validations.zip && <small className="p-error">Zip code is required</small>}
                                                            {item.zip && (
                                                                <div style={{ position: "relative" }}>
                                                                    <ZipCodesMenuComponent zipCodes={item.zipCode} handleSelectedZipCodes={selectedZipCode} indexing={index} />
                                                                    <small className="p-error">{item.recordNotFoundMsg}</small>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>State</b>
                                                                    <span className="clr_red">*</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <InputText name="state" className="custom_cursor" id="state" type="text" disabled value={item.state} />
                                                        </div>
                                                        {/*  */}
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>City</b>
                                                                    <span className="clr_red">*</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <InputText name="city" className="custom_cursor" id="city" type="text" disabled value={item.city} />
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>Driver License No</b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            {/* <InputMask
                                                        id="driver_license"
                                                        name="driver_license"
                                                        type="text"
                                                        mask={item?.license_mask}
                                                        placeholder={item?.license_mask}
                                                        value={item.driver_license}
                                                        onChange={(e) => (e.target.value === undefined ? null : handleInputChange(e, index))}
                                                    /> */}
                                                            <InputText
                                                                name="driver_license"
                                                                id="driver_license"
                                                                type="text"
                                                                placeholder="Enter Driver License No"
                                                                maxLength="25"
                                                                value={item.driver_license}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_cursor"
                                                            />
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <div>
                                                                <p className="pt-3">
                                                                    <b>Driver License Expiry Date</b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="md:col-6 col-12">
                                                            <Calendar
                                                                name="driving_license_expiry_date"
                                                                id="driving_license_expiry_date"
                                                                value={new Date(item.driving_license_expiry_date)}
                                                                placeholder="mm/dd/yyyy"
                                                                mask="99/99/9999"
                                                                showIcon
                                                                minDate={new Date()}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                className="custom_border_none"
                                                                monthNavigator
                                                                yearNavigator
                                                                yearRange="1945:2050"
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                            ))}
                                        </Accordion>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </form>
            </div>
        </>
    );
};

export default ProprietorInformation;
