import React from "react";
//styles
import "./Components.scss";
import { Button } from "primereact/button";
import DashboardImage from "../../../../Images/DebtorDashboardImg.png";
import { useSelector } from "react-redux";
import { loginState } from "../../../../redux/login";
import { useHistory } from "react-router-dom";

const TopSection = () => {
    const { user } = useSelector(loginState);
    const history = useHistory();

    return (
        <div className="card top-section">
            <div className="grid">
                <div className="md:col-7 col-12">
                    <h1 className="hello-color">
                        <b>Hello, </b>
                        <span className="name-color">
                            <b>{user?.first_Name ? user?.first_Name + " " + user.last_Name : ""}</b>
                        </span>
                        {/* <br /> */}
                        {/* <p className="welcome-text">Welcome to your Dashboard</p> */}
                    </h1>
                    {/* <div className="mt-4">
                        <p className="description-text">You have completed 40% of your this week! Start a new goal & improve your result</p>
                    </div> */}
                    <div className="flex mt-6">
                        <Button label="View Invoices" type="button" className="btn btn-default savebtn" onClick={() => history.push("invoices")} />
                    </div>
                </div>
                <div className="md:col-2 col-12"></div>
                <div className="md:col-3 col-12">
                    <img src={DashboardImage} alt="img" className="img-height" />
                </div>
            </div>
        </div>
    );
};
export default TopSection;
