import React, { useContext, useEffect, useRef, useState } from "react";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg, BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
//formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContext } from "../../../../context/toast";
import { getDateFormat } from "../../../../utils/HelperFuctions/dateFormat";
import { deleteAttachmentPolicy, savePolicy } from "../../../../services/policy";
import { extractNameFromURL } from "../../../../utils/HelperFuctions/getFileName";
import { openAttachment } from "../../../../utils/HelperFuctions/openAttachment";
// style
import ".././Screens/policies.scss";
import { Button } from "primereact/button";
import { FILE_MAX_SIZE } from "../../../../utils/Constants/global";
import appUrl from "../../../../utils/Constants/appUrl";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";

const AddNewPolicy = (props) => {
    const { onHide, editData, setFormDirty } = props;

    const hiddenFileInput = useRef(null);
    //states
    const toast = useContext(ToastContext);
    //state

    const [loading, setLoading] = useState(false);
    const [openingAttachmentLoading, setOpeningAttachmentLoading] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [editValues, setEditValues] = useState(editData);
    const [editDisabled, setEditDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);

    //formik
    const validationSchema = Yup.object().shape({
        name: Yup.mixed().required("Policy Title is required"),
        effective_from: Yup.date().required("Effective From is required").nullable(),
        purpose: Yup.mixed().required("Purpose is required"),
        description: Yup.mixed().required("Description is required"),
        attachment_path: Yup.mixed().required("Attachment is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: editData ? editData.name : "",
            effective_from: editData ? new Date(editData.effective_from) : null,
            purpose: editData ? editData.purpose : "",
            description: editData ? editData.description : "",
            attachment_path: editData ? editData.attachment_path : "",
        },
        onSubmit: async (data) => {
            try {
                setLoading(true);
                setIsLoading(true);
                data = {
                    ...data,
                    effective_from: data.effective_from ? getDateFormat(data.effective_from) : "",
                };
                if (editData) {
                    data["id"] = editData?.id;

                    if (typeof data["attachment_path"] == "string") {
                        delete data["attachment_path"];
                    }
                }
                // form Data...
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });
                let res = await savePolicy(formData);
                if (res.data.status) {
                    if (data?.id) toast.updateToast("Policy");
                    else toast.createdToast("Policy");
                    onHide("refresh");
                }
            } catch (error) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setLoading(false);
            setIsLoading(false);
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleKeyPress = (e) => {
        setFormDirty(true);
        if (e.key === " " && e.target.value.trim() === "") {
            e.preventDefault();
        }
    };

    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            onHide(false);
        }
    };
    const confirmHandler = () => {
        onHide(false);
    };

    const openDocument = async (attachment) => {
        if (attachment) {
            try {
                setOpeningAttachmentLoading(true);
                await openAttachment(attachment);
            } catch (error) {
                console.error("Error opening attachment:", error);
            } finally {
                setOpeningAttachmentLoading(false);
            }
            // window.open(appUrl.imageBaseUrl + attachment, "_blank");
        }
    };
    const onFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.name.split(".");
            const index = fileType.length - 1;
            const accept = ".pdf,.doc,.docx,.xls,.xlsx";
            const check_file = accept.includes(fileType[index]);

            if (check_file) {
                const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
                if (fileSize) {
                    toast.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size.", "error");
                    event.target.value = null;
                } else if (file.name.includes("#") || file.name.includes("&")) {
                    toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                } else {
                    formik.setFieldValue("attachment_path", file);
                    formik.setFieldTouched("attachment_path", false);
                    formik.setFieldError("attachment_path", "");
                    setFormDirty(true);
                    event.target.value = null;
                }
            } else {
                event.target.value = null;
                formik.setFieldValue("attachment_path", "");
                formik.setFieldError("attachment_path", "Attachment is required");
                toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
            }
        } else {
            formik.setFieldValue("attachment_path", "");
            formik.setFieldError("attachment_path", "Attachment is required");
        }
    };
    const handleDeleteAttachment = (isAddMode) => {
        if (isAddMode) {
            formik.setFieldValue("attachment_path", "");
        } else {
            setShowConfirmDeleteDialog(true);
            let editClone = editValues;
            setDeleteAttachment({
                id: editClone.id,
            });
        }
    };

    const confirmDeleteHandler = async () => {
        setIsDeleting(true);
        try {
            let resp = await deleteAttachmentPolicy(deleteAttachment);
            if (resp.data.status) {
                setShowConfirmDeleteDialog(false);
                toast.showMessage("File Deleted", "File has been deleted successfully", "success");
                let editClone = editValues;
                editClone = {
                    ...editClone,
                    attachment_path: null,
                };
                setEditValues(editClone);
                formik.setFieldValue("attachment_path", "");
            } else {
                toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsDeleting(false);
        } catch (error) {
            setIsDeleting(false);
            toast.showMessage("Server Error", "Server error.", "error");
        }
    };
    const handleOpenUpload = () => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {
        if (formik.dirty) {
            setEditDisabled(false);
        } else {
            setEditDisabled(true);
        }
    }, [formik.dirty]);
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <CustomInputField
                            iden="name"
                            label="Policy Title"
                            mandatory="*"
                            formik={formik}
                            placeHolder="Enter Policy Title"
                            keyfilter={/^[a-zA-Z0-9\s]+$/}
                            maxLength={35}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="col-12 md:col-6">
                        <CustomInputField
                            iden="effective_from"
                            type="calendar"
                            label="Effective From"
                            mandatory="*"
                            formik={formik}
                            placeHolder="mm/dd/yyyy"
                            minDate={new Date()}
                            monthNavigator
                            yearNavigator
                            yearRange="1945:2050"
                        />
                    </div>
                    <div className="col-12 md:col-12">
                        <CustomInputField
                            iden="purpose"
                            label="Purpose"
                            mandatory="*"
                            formik={formik}
                            placeHolder="Enter Purpose"
                            keyfilter={/^[a-zA-Z0-9\s]+$/}
                            maxLength={50}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="col-12">
                        <CustomInputField
                            iden="description"
                            type="textarea"
                            label="Description"
                            mandatory="*"
                            rows="3"
                            formik={formik}
                            placeHolder="Enter Description"
                            keyfilter={/^[a-zA-Z0-9\s]+$/}
                            maxLength={150}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="col-12">
                        {editData && editValues.attachment_path ? (
                            <>
                                <div className="flex flex row">
                                    <span className="text_break cursor-pointer" onClick={() => openDocument(editData?.attachment_path)}>
                                        {extractNameFromURL(editData.attachment_path)}
                                    </span>
                                    <span onClick={() => handleDeleteAttachment(false)}>
                                        <BsXLg className="cursor-pointer delete_icon ml-4 cross_icon" />
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-12 md:col-12">
                                    <label htmlFor="NameTitle">
                                        <b>Attachment</b>
                                        <span className="clr_red">*</span>
                                        <Button
                                            type="button"
                                            tooltip=".pdf, .doc, .docx, .xls, .xlsx files only (max size 3MB) "
                                            tooltipOptions={{ position: "bottom" }}
                                            icon={<BsInfoCircle />}
                                            aria-label="Submit"
                                            className="customTooltipicon"
                                        />
                                    </label>
                                    <div className="file_upload_class">
                                        <label className={`flex flex-row justify-content-between align-items-center`} onClick={handleOpenUpload}>
                                            {formik.values.attachment_path ? (
                                                <div className="flex row">
                                                    <span className="text_break">{formik.values.attachment_path.name}</span>
                                                    <BsXLg className="cursor-pointer cross_icon ml-1" onClick={(event) => handleDeleteAttachment(true)} />
                                                </div>
                                            ) : (
                                                <>Choose File</>
                                            )}{" "}
                                            <BsPaperclip />
                                        </label>
                                    </div>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        ref={hiddenFileInput}
                                        onChange={onFileSelect}
                                        accept=".pdf,.doc,.docx,.xls,.xlsx"
                                        disabled={formik.values.attachment_path}
                                        style={{ display: "none" }}
                                    />
                                    {getFormErrorMessage("attachment_path")}
                                </div>
                                {/* <div className="col-12 md:col-12" style={{ position: "relative" }}>
                                    <CustomInputField label="Attachment" type="file" id="policyAttachment" formik={formik} onChange={onFileSelect} mandatory="*" />
                                    {formik.values.attachment_path && <BsXLg onClick={() => handleDeleteAttachment(true)} className="attachment_crossed" />}
                                    {getFormErrorMessage("attachment_path")}
                                </div> */}
                            </>
                        )}
                    </div>

                    <div className="col-12 text-right pt-3">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} isDisabled={loading} />
                        <CustomAddNewButton title={editData ? "Update" : "Save"} type="submit" isDisabled={loading || editDisabled} isLoading={isLoading} />
                    </div>
                </div>
            </form>

            {openingAttachmentLoading && <CustomLoading center />}

            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => confirmDeleteHandler()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}
        </>
    );
};

export default AddNewPolicy;
