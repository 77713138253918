import React, { useState } from "react";

// Prime React
import { Dialog } from "primereact/dialog";

//Component
import CreateTicketModal from "../../Components/CreateTicketModal";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";

// Redux
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { SUPPORT_RIGHTS } from "../../../../utils/Constants/rights";

const Header = (props) => {
    const { user } = props;
    //State
    const [showAddEditDialog, setAddEditDialog] = useState(false);

    return (
        <>
            {/* Dialouge Section */}
            <Dialog
                blockScroll={true}
                header="Add New Ticket"
                visible={showAddEditDialog}
                onHide={() => setAddEditDialog(false)}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "52vw" }}
            >
                <CreateTicketModal onHide={() => setAddEditDialog(false)} />
            </Dialog>

            {/* Dialouge Section */}

            <div className="grid text-right">
                <div className="md:col-12 ">
                    {validateUserPermissions([SUPPORT_RIGHTS.add_new_ticket], user?.rights) ? (
                        <CustomAddNewButton
                            type="button"
                            title="Add New Ticket"
                            onClick={() => {
                                setAddEditDialog(true);
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Header;
