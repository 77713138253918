import React, { useContext, useState } from "react";
// components
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";

import { postRevertSchedule } from "../../../../../../services/schedule-assignment";
import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";
import { ToastContext } from "../../../../../../context/toast";

const VerifyScheduleModal = (props) => {
    //props
    const { onHide, selectedSchedules, getGridData } = props;

    // use States
    const [loading, setLoading] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    const revertSchedule = async () => {
        onHide();
        let payload = {
            id: selectedSchedules[0].id,
        };
        try {
            const res = await postRevertSchedule(payload);
            if (res.data.status) {
                toast.showMessage("Success", "Schedule has been reverted successfully.", "success");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        getGridData();
    };

    return (
        <>
            {loading ? (
                <CustomLoading top={20} />
            ) : (
                <>
                    <div className="verify_schedule">
                        <div className="text-center ">
                            <p>Are you sure you want to revert verification</p>
                        </div>
                        <div className="flex justify-content-center pt-4">
                            <CustomCancelbtn onClick={onHide} title="Cancel" />
                            <CustomAddNewButton title="Yes" className="ml-2" onClick={() => revertSchedule()} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default VerifyScheduleModal;
