import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
//styles
import "../../../userManagement.scss";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { USERMANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

//dialog

const Header = (props) => {
    // Props
    const history = useHistory();

    // Redux
    const { user } = useSelector(loginState);

    //navigate to add user
    const handleNavigate = () => {
        if (!validateUserPermissions([USERMANAGMENT_RIGHTS.user_profile_adduser], user?.rights)) return;
        history.push("/addnewuser");
    };

    // Use Effect
    useEffect(() => {}, [user?.rights]);

    return validateUserPermissions([USERMANAGMENT_RIGHTS.user_profile_adduser], user?.rights) && <CustomAddNewButton onClick={handleNavigate} title="Add New User" />;
};

export default Header;
