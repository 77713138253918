import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FactorFlow from "../../Images/LoginPageLogo.png";
import "./Login.css";
import { ToastContext } from "../../context/toast";
import { checkTimeExpiry, resendCode, verifyCode } from "../../services/user-management/user";

const VerificationCode = () => {
    const history = useHistory();
    const toastContext = useContext(ToastContext);
    // States
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("");
    const [seconds, setSeconds] = useState(-1);
    const [countdownActive, setCountdownActive] = useState(true);
    const [values, setValues] = useState(["", "", "", "", "", ""]);

    const onOTPEnter = (index, value) => {
        let _values = [...values];
        _values[index] = value;
        setValues(_values);
    };

    useEffect(() => {
        const inputs = document.querySelectorAll(".otp__input");

        const handleInput = (index, event) => {
            const input = event.target;
            const nextInput = inputs[index + 1];
            const prevInput = inputs[index - 1];

            if (input.value && nextInput) {
                nextInput.focus();
            } else if (!input.value && prevInput) {
                prevInput.focus();
            }
        };

        inputs.forEach((input, index) => {
            input.addEventListener("input", handleInput.bind(null, index));
        });

        return () => {
            inputs.forEach((input, index) => {
                input.removeEventListener("input", handleInput.bind(null, index));
            });
        };
    }, []);

    useEffect(() => {
        checkTimeExpiration();
    }, []);

    useEffect(() => {
        if (seconds >= 0) {
            const timer = setInterval(() => {
                setCountdownActive(true);
                setSeconds((prevSeconds) => {
                    if (prevSeconds > 0) {
                        return prevSeconds - 1;
                    } else {
                        clearInterval(timer);
                        setCountdownActive(false);
                        return 0;
                    }
                });
            }, 1000);

            return () => clearInterval(timer);
        } else {
            setCountdownActive(false);
        }
    }, [seconds]);

    const checkTimeExpiration = async () => {
        setloading(true);
        let payload = {
            email: localStorage.getItem("email"),
        };
        try {
            const res = await checkTimeExpiry(payload);
            if (res.data.status) {
                setSeconds(res.data.seconds);
            } else {
                toastContext.showMessage("Error", res?.data?.message, "error");
            }
        } catch (e) {
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setloading(false);
    };

    const handleResendCode = async () => {
        setloading(true);
        let payload = {
            email: localStorage.getItem("email"),
        };
        try {
            const res = await resendCode(payload);
            if (res.data.status) {
                setValues(["", "", "", "", "", ""]);
                checkTimeExpiration();
                setCountdownActive(true);
                toastContext.showMessage("Password Reset", "Reset password verification code has been sent to your email.", "success");
            } else {
                toastContext.showMessage("Error", res?.data?.message, "error");
            }

            setloading(false);
        } catch (e) {
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setloading(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        setloadingIcon("pi pi-spin pi-spinner");
        try {
            let payload = {
                email: localStorage.getItem("email"),
                code: values.join(""),
            };
            const res = await verifyCode(payload);
            if (res?.data?.status) {
                history.push("/setpassword/" + res?.data?.token);
            } else {
                toastContext.showMessage("Error", res?.data?.message, "error");
            }

            setloading(false);
        } catch (error) {
            toastContext.showMessage("Error", "Email or Password you entered doesn't match your account.", "error");
            setloading(false);
        }
    };

    return (
        <>
            <div className="background_image">
                <div className="grid">
                    <div className="lg:col-7 md:col-6"></div>
                    <div className="lg:col-5 md:col-6 col-12">
                        <div className="custom_margin_top">
                            <div className="res_img">
                                <img src={FactorFlow} alt="Factor Flow Logo" />
                            </div>
                            <div className="pt-2">
                                <h4>One tool for your whole team.</h4>
                            </div>
                        </div>

                        <div className="container container__varification">
                            <p className="sign_in_text">Verification Code</p>
                            <p className="sign_text">Please enter 6-digit verification code to continue</p>

                            <div className="pt-3">
                                <form onSubmit={onSubmit}>
                                    <div className="mb-3">
                                        <input type="text" maxLength={1} placeholder="-" className="otp__input" value={values[0]} autoFocus onChange={(e) => onOTPEnter(0, e.target.value)} />
                                        <input type="text" maxLength={1} placeholder="-" className="otp__input" value={values[1]} onChange={(e) => onOTPEnter(1, e.target.value)} />
                                        <input type="text" maxLength={1} placeholder="-" className="otp__input" value={values[2]} onChange={(e) => onOTPEnter(2, e.target.value)} />
                                        <input type="text" maxLength={1} placeholder="-" className="otp__input" value={values[3]} onChange={(e) => onOTPEnter(3, e.target.value)} />
                                        <input type="text" maxLength={1} placeholder="-" className="otp__input" value={values[4]} onChange={(e) => onOTPEnter(4, e.target.value)} />
                                        <input type="text" maxLength={1} placeholder="-" className="otp__input" value={values[5]} onChange={(e) => onOTPEnter(5, e.target.value)} />
                                    </div>

                                    <div className="flex flex-row justify-content-end mt-3 mr-3">
                                        {(seconds >= 0 || seconds == -1) && (
                                            <>
                                                {/* <p className="forget_text" onClick={handleResendCode} style={{ cursor: countdownActive || loading ? "not-allowed" : "pointer" }}>
                                                    Resend Code ({seconds}s)
                                                </p> */}

                                                <button
                                                    className="forget_text resend__btn"
                                                    onClick={handleResendCode}
                                                    style={{ cursor: countdownActive || loading ? "not-allowed" : "pointer" }}
                                                    disabled={countdownActive || loading || seconds > 0}
                                                >
                                                    Resend Code {seconds > 0 && (
                                                        <span>
                                                        ({Math.floor(seconds / 60) > 0 && `${Math.floor(seconds / 60)}m `}
                                                        {seconds % 60}s)
                                                        </span>
                                                    )}  
                                                </button>
                                            </>
                                        )}
                                    </div>

                                    <div className="flex flex-row justify-content-end mt-3  mr-3">
                                        <button
                                            className={loading || values.length !== 6 || values.some((item, index, arr) => item === "") ? "disabled_sign_in_btn btn sign_in_btn" : "btn sign_in_btn"}
                                            type="submit"
                                            icon={loadingIcon || ""}
                                            iconPos="right"
                                            disabled={loading || values.length !== 6 || values.some((item, index, arr) => item === "")}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerificationCode;
