import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    whiteLabel: null,
    loading:false,
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setWhiteLabel: (state, action) => {
            state.whiteLabel = action.payload;
        },
        setSignInLoading : (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setUser, setWhiteLabel,setSignInLoading } = loginSlice.actions;
export const loginState = (state) => state.login;

export default loginSlice.reducer;

