import React, { useContext, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Context & APIs
import { updateUnderwritingTask } from "../../../../services/under-writing";
import { ToastContext } from "../../../../context/toast";
// UI Componnets
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../utils/HelperFuctions/cleanData";
import { mainViewState, onUnderWritingFormDirty } from "../../../../redux/main-view";
import { useDispatch, useSelector } from "react-redux";
const EditTask = ({ onHide, taskId, taskData, underWritings }) => {
    const toastContext = useContext(ToastContext);
    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);
    const [isSaving, setIsSaving] = useState(false);
    const validationSchema = Yup.object().shape({
        tasktitle: Yup.string().required("Task Title is required").trim(),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tasktitle: taskData.title,
        },
        onSubmit: async (data) => {
            let vertical = underWritings.find((element) => element.id == taskData.under_writing_id);
            let _taskExists = vertical.tasks.filter((item) => item.title === data.tasktitle && item.id != taskId);
            if (_taskExists.length !== 0) {
                toastContext.showMessage("Validation Failed", "Task Title should be unique.", "error");
            } else {
                try {
                    setIsSaving(true);
                    let dto = {
                        id: taskId,
                        title: data.tasktitle.trim(),
                    };
                    let resp = await updateUnderwritingTask(dto);
                    setIsSaving(false);
                    if (resp.data.status) {
                        toastContext.updateToast("Task");
                        onHide(true, "edit task");
                    }
                    return;
                } catch (error) {
                    setIsSaving(false);
                }
            }
        },
    });

    const inputHanlder = (e) => {
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);
        const finalValue = toTitleCaseWhileTyping(cleanValue);
        formik.setFieldValue(name, finalValue);
        dispatch(onUnderWritingFormDirty(true));
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField iden="tasktitle" label="Task Title" mandatory="*" placeHolder="Enter Task Title" maxLength={30} formik={formik} onChange={(e) => inputHanlder(e)} />
                    </div>

                    <div className="col-12 text-right pt-5">
                        <CustomCancelbtn
                            title="Cancel"
                            type="button"
                            onClick={(e) => {
                                onHide(false, "edit task");
                            }}
                        />
                        <CustomAddNewButton title="Update" type="submit" isDisabled={isSaving || !screens.underwriting.isFormDirty} />
                    </div>
                </div>
            </form>
        </>
    );
};
export default EditTask;
