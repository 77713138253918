import React, { useState } from "react";

//component
import { Dialog } from "primereact/dialog";
import AddNewReceivingAccount from "../Components/AddNewReceivingAccount";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";

const Header = () => {
    //state
    const [showAddEditDialog, setAddEditDialog] = useState(false);

    //Properties
    const dialogFuncMap = {
        showAddEditDialog: setAddEditDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    return (
        <>
            <Dialog
                header="Add New Receiving Account"
                visible={showAddEditDialog}
                onHide={() => onHide("showAddEditDialog")}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                <AddNewReceivingAccount onHide={() => onHide("showAddEditDialog")} />
            </Dialog>

            {/* Dialouge Section */}

            <CustomAddNewButton
                title=" Add New"
                onClick={() => {
                    onClick("showAddEditDialog");
                }}
            ></CustomAddNewButton>
        </>
    );
};

export default Header;
