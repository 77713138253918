import React from "react";

// Components
import SearchInputComponent from "../../../../ui/SearchInput/SearchInputComponent";

const FilterBar = (props) => {
    const { setKeyword, keyword } = props;

    return (
        <div className="grid">
            <div className="col-12 md:col-10">
                <h4 className="pl-2">
                    <b>Documents</b>
                </h4>
            </div>

            <div className="md:col-7 col-12"></div>
            <div className="md:col-5 col-12">
                <div className="flex flex-row">
                    <SearchInputComponent type="text" value={keyword} setKeyword={setKeyword} placeholder="Search by client name" />
                </div>
            </div>
        </div>
    );
};

export default FilterBar;
