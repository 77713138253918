import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { loginState } from "../redux/login";

export const SocketContext = createContext();

export const SocketStateProvider = ({ children }) => {
    const { user } = useSelector(loginState);

    const [socket, setSocket] = useState(null);
    const [connected, setConnected] = useState(false);

    const createSocketInstance = async (userId) => {
        const id = userId ?? user?.id;

        if (id === null || id === undefined) return;

        try {
            let socketCon = io(process.env.REACT_APP_SOCKET_URL, {
                query: { userId: id, application: "FF" },
                transports: ["websocket"],
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionAttempts: 5,
            });

            socketCon.on("connect", () => {
                setSocket(socketCon);
                socketCon.removeAllListeners();
                addEventListners(socketCon);
            });

            socketCon.on("connect_error", (err) => {
                console.log(`Socket connect_error due to ${err.message}`);
            });
        } catch (e) {
            console.log(e);
        }
    };

    function setUserId(userId) {
        socket.emit("setUserId", { userId: userId });
    }

    const disconnectSocket = async () => {
        socket.close();
    };

    function addEventListners(socket) {}

    return (
        <SocketContext.Provider
            value={{
                socket,
                connected,
                setUserId,
                createSocketInstance,
                disconnectSocket,
            }}
        >
            {children}
        </SocketContext.Provider>
    );
};
