import React, { useState, useRef, useEffect, useContext } from "react";
// Prime react
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//Ui-Components
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
//Redux
import { useSelector } from "react-redux";
import { loginState } from "../../../redux/login";
//Components
import Header from "../../GeneralLedger/Screens/Header/header";
import FilterBarLedger from "./Filterbar/filterbar";
import { ToastContext } from "../../../context/toast";
import header from "../../GeneralLedger/Screens/Header/header";
import { currencyFormatUSD } from "../../../utils/HelperFuctions/checkAmount";
import moment from "moment";
//styles
import "./generalledger.scss";
const GeneralLedger = () => {
    const columns = [
        { field: "invoice_no", header: "Invoice No" },
        { field: "debtor_name", header: "Debtor Name" },
        { field: "description", header: "Description" },
        { field: "posting_date", header: "Posting Date" },
        { field: "payment_mode", header: "Payment Mode " },
        { field: "transaction_no", header: "Transaction No" },
        { field: "debit_amount", header: "Debit Amount" },
        { field: "credit_amount", header: "Credit Amount" },
    ];

    const toast = useContext(ToastContext);

    // Redux
    const { user } = useSelector(loginState);
    const dt = useRef(null);
    //states
    const [ledgerData, setLedgerData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [exportMenuItems, setExportMenuItems] = useState([
        {
            id: 1,
            label: "Excel",
        },
        {
            id: 2,
            label: "PDF",
        },
    ]);
    const [selectedYear, setSelectedYear] = useState("");
    const [transactionType, setTransactionType] = useState("all");
    const [clientDebtor, setClientDebtor] = useState("client");
    const [showLedgerCards, setShowLedgerCards] = useState(false);
    const [data, setData] = useState([]);
    const [downloadLedger, setDownloadLedger] = useState(false);

    const getColumnBody = (colField) => (rowData) => {
        if (colField === "debit_amount") {
            let amount = rowData[colField] ? "-" + currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0);
            return rowData[colField] > 0 ? <div className="amount_column_alignment danger_text">{amount}</div> : <div className="amount_column_alignment">{amount}</div>;
        } else if (colField === "credit_amount") {
            let amount = rowData[colField] ? "+" + currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0);
            return rowData[colField] > 0 ? <div className="amount_column_alignment success_text">{amount}</div> : <div className="amount_column_alignment">{amount}</div>;
        } else if (colField === "posting_date") {
            return rowData[colField] ? moment(rowData[colField]).format("MM/DD/YYYY") : "";
        }
        return rowData[colField] ? rowData[colField] : "";
    };

    const columnComponents = selectedColumns.map((col) => {
        // return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />;
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getColumnBody(col.field)}
                headerClassName={`${col.field === "debit_amount" || col.field === "credit_amount" ? "amount_column_alignment" : ""}`}
            />
        );
    });

    //useEffect
    useEffect(() => {
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    }, [user]);

    // useEffect(() => {
    //     updateColumns();
    // }, [transactionType, clientDebtor]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <h4 className="m-0">
                        <b>General Ledger</b>
                    </h4>
                </div>
            </div>
            <FilterBarLedger
                toast={toast}
                setLedgerData={setLedgerData}
                setShowLedgerCards={setShowLedgerCards}
                setSelectedYear={setSelectedYear}
                setLoading={setLoading}
                transactionType={transactionType}
                setTransactionType={setTransactionType}
                clientDebtor={clientDebtor}
                setClientDebtor={setClientDebtor}
                setSelectedColumns={setSelectedColumns}
                columns={columns}
                setData={setData}
            />
            {showLedgerCards && (
                <>
                    <Header
                        selectedYear={selectedYear}
                        isDropdownOpen={isDropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                        setExportMenuItems={setExportMenuItems}
                        exportMenuItems={exportMenuItems}
                        ledgerData={ledgerData}
                        data={data}
                        setDownloadLedger={setDownloadLedger}
                        downloadLedger={downloadLedger}
                        user={user}
                    />
                </>
            )}
            {loading ? (
                <CustomLoading />
            ) : ledgerData?.invoices ? (
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <div className="generalLedger_scroll">
                                <DataTable ref={dt} value={ledgerData?.invoices} responsiveLayout="scroll" emptyMessage="No record available.">
                                    {/* <Column></Column> */}
                                    {columnComponents}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default GeneralLedger;
