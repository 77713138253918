import React, { useEffect, useRef } from "react";
//component

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { currencyFormat } from "../../../../../utils/HelperFuctions/checkAmount";
const NoneFinancedInvoices = (props) => {
    const { response } = props;
    const dt = useRef(null);

    const handleTemplate = (rowData) => {
        if (rowData) {
            return "Unassigned";
        }
    };

    const handleInvoiceTemplate = (rowData) => {
        if (rowData.invoice_amount === null || rowData.invoice_amount === 0) {
            return "$0.00";
        } else if (rowData.invoice_amount) {
            return currencyFormat(rowData.invoice_amount);
        }
    };
    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3">
                    <DataTable ref={dt} value={response} emptyMessage="No record available.">
                        <Column field="invoice_no" header="Invoice No"></Column>
                        <Column field="legal_name" header="Debtor Name"></Column>
                        <Column body={handleTemplate} header="Schedule No"></Column>
                        <Column field="invoice_date" header="Invoice Date"></Column>
                        <Column body={handleInvoiceTemplate} header="Invoice Amount"></Column>
                        <Column field="status_text" header="Status"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default NoneFinancedInvoices;
