import React from "react";
// Styles
import "./privacypolicy.scss";

//Logo
import FactorFlow from "../../Images/LoginPageLogo.png";
import { Button } from "primereact/button";
import { BsX } from "react-icons/bs";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="grid m-0">
                    <div className="md:col-1"></div>
                    <div className="md:col-10 col-12 mt-3">
                        <div className="card" style={{ position: "relative" }}>
                            {/* onClick={() => handleRemoveForm(index)} */}
                            <Link to="/login">
                                <Button type="button" className="cros_privacy" icon={<BsX />} />
                            </Link>
                            <div class="watermark">
                                <img src={FactorFlow} alt="Factor Flow Logo" className="water_mark_logo" />
                            </div>
                            <div className="width_img">
                                <img src={FactorFlow} alt="Factor Flow Logo" />
                            </div>
                            <div className="top_section text-center">
                                <h5>
                                    <b>PRIVACY POLICY </b>
                                </h5>
                                <p>Last Modified (07/25/2023)</p>
                            </div>
                            <div className="section_top_text">
                                <p>
                                    Your privacy is of utmost importance to us at Factor Flow. This Privacy Policy elucidates how we collect, utilize, share, and safeguard information about our
                                    esteemed website users and mobile application users (collectively referred to as "Users" or "you"). When Users access, use, or register through our websites, mobile
                                    applications, and products and services (collectively referred to as "Services"), the Company collects certain personal data ("Personal Information"). The provision
                                    of information is contractually mandatory based on your utilization of the Services. If you choose not to provide this information, you will be unable to use the
                                    Services.{" "}
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>1. Application</b>
                                </h6>
                                <p className="text_padding_left">
                                    This Privacy Policy applies to individuals and businesses worldwide who employ our Services. By utilizing the Services, we offer, you agree to be bound by this
                                    policy concerning the information collected about you. If you do not fully and unconditionally agree to this Privacy Policy without modifications, you may not
                                    access, view, use, or register for any portion of the Services.<br></br> The Company reserves the right to modify, change, or replace this Privacy Policy at any
                                    time by updating this document on the Service. You consent to be bound by the most recent version of the Privacy Policy available on the Service. The date of the
                                    last modification will be prominently displayed at the top of the Privacy Policy.
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>2. CHILD PRIVACY </b>
                                </h6>
                                <p className="text_padding_left">
                                    Our Services are intended for users who are 18 years old or above. Moreover, we do not intentionally market the Services to, or collect Personal Information from,
                                    children under the age of 13. If we become aware that we have inadvertently obtained Personal Information from a child under the age of 13, we will expunge such
                                    information from our records.
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>3. INFORMATION COLLECTED </b>
                                </h6>
                                <p className="text_padding_left">
                                    Our legal basis for collecting and processing your Personal Information is (I) your explicit consent when accessing the Services and (II) a legitimate interest in
                                    website traffic analysis and software functionality. When you use the Services, we may collect the following Personal Information:
                                </p>
                                <ul className="ul_text">
                                    <li>
                                        Information Automatically Collected Through the Services: We may employ cookies, log files, clear. gifs, and other analytics tools to monitor your site usage,
                                        enabling us to analyze and enhance the Services. You may have the option to disable cookies in your web browser or mobile device. However, please note that
                                        disabling cookies may hinder you from fully utilizing the Services. You or your device are the source of this information. For more details on the use of
                                        cookies and how to block or disable them, kindly visit allaboutcookies.org.
                                    </li>
                                    <li>
                                        Registration information: This may encompass your name, email address, physical address, banking information, telephone number, and any other information you
                                        willingly provide, such as content you post to the Services (including comments). It includes information used to create an account on the Services or
                                        information you directly transmit to us when contacting us. You are the source of this information.
                                    </li>
                                    <li>
                                        Information You Submit to Us: This may include user-generated content uploaded to the Services or information you transmit to us for customer or technical
                                        support purposes. You are the source of this information.
                                    </li>
                                    <li>
                                        Client and Factoring Information: When you utilize the Service to provide factoring services to clients, we will collect information pertaining to your clients'
                                        accounts and information as inputted by you. You are the source of this information.
                                    </li>
                                    <li>
                                        When you use our Services through your computer, mobile phone, or other devices, we may collect information regarding your device, such as hardware models and
                                        IDs, device type, operating system version, request type, content of your request, and basic usage information about your use of our Services, including date
                                        and time.
                                    </li>
                                    <li>
                                        Additionally, we may collect information regarding application-level events and associate them with your account to provide customer service. We may also
                                        locally store information on your device using mechanisms like browser web storage and application data caches. You or your device are the source of this
                                        information.
                                    </li>
                                </ul>
                            </div>

                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>4. SHARING AND USE OF PERSONAL INFORMATION </b>
                                </h6>
                                <p className="text_padding_left">We may utilize your Personal Information in the following manners:</p>
                                <ul className="ul_text">
                                    <li>
                                        To provide you with all the features and tools of the Services, including our factoring software. You can opt-out of receiving emails by following the
                                        instructions in the emails or canceling your user account.
                                    </li>
                                    <li>To facilitate your collaboration with other Users as per your request.</li>
                                    <li>To contact you about the Services, the Company, or to provide customer or technical support.</li>
                                    <li>To analyze and enhance the features and performance of the Services, as well as to analyze usage patterns.</li>
                                    <li>To apprise Users of offers from the Company.</li>
                                    <li>We may disclose your Personal Information if legally compelled to do so by request or requirement of law.</li>
                                    <li>
                                        We deeply value your privacy. Except for the provisions explicitly mentioned in this Privacy Policy, we will not sell or share your Personal Information with
                                        third parties.
                                    </li>
                                </ul>
                            </div>

                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>5. THIRD-PARTY LINKS</b>
                                </h6>
                                <p className="text_padding_left">
                                    While using the Services, you may encounter links to third-party websites, videos, images, and applications ("Third-Party Links"). We have no control over the
                                    content found on these Third-Party Links, and we cannot guarantee the protection and privacy of any information you provide to them. Exercise caution when accessing
                                    Third-Party Links.
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>6. SECURITY</b>
                                </h6>
                                <p className="text_padding_left">
                                    The Company employs commercially reasonable technical and administrative security measures to mitigate the risks of loss, misuse, unauthorized access, disclosure,
                                    and alteration of your Personal Information. Our safeguards include firewalls, data encryption, physical access controls to our data centers, and information access
                                    authorization controls. However, we cannot guarantee the security of our information storage, nor can we ensure that the information you transmit to and from us
                                    over the Internet, including email transmissions, will not be intercepted.
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>7. INTERNATIONAL PRIVACY PRACTICES </b>
                                </h6>
                                <p className="text_padding_left">
                                    The Company primarily operates and manages its servers within the United States. To provide our products and services to you, we may transmit and store your
                                    Personal Information outside the country of your residence or location, including in the United States. Consequently, if you reside or are located outside the
                                    United States, your Personal Information may be transferred to countries that may not provide the same level of protection for your Personal Information as your
                                    country of residence. We are committed to safeguarding the privacy and confidentiality of Personal Information during such transfers. If you reside or are located
                                    within the European Economic Area and such transfers occur, we take appropriate measures to provide a similar level of protection for the processing carried out in
                                    any such countries as you would have within the European Economic Area, to the extent feasible under applicable law. By using and accessing our Services, Users
                                    residing or located in countries outside the United States agree and consent to the transfer and processing of Personal Information on servers located outside their
                                    country of residence, acknowledging that the acknowledging that the safeguards and protections provided in this Privacy Policy may differ from those in their own
                                    country. Users located in countries with data protection laws or regulations are encouraged to review this Privacy Policy carefully before accessing or using the
                                    Services.
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>8. DATA RETENTION </b>
                                </h6>
                                <p className="text_padding_left">
                                    We retain your Personal Information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or
                                    permitted by law. The criteria used to determine our retention periods include the duration of your use of the Services, the nature of the data collected, and our
                                    legal obligations under applicable laws.
                                </p>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>9. YOUR RIGHTS </b>
                                </h6>
                                <p className="text_padding_left">Users have certain rights regarding their Personal Information, subject to local data protection laws. These rights may include: </p>
                                <ul className="ul_text">
                                    <li>The right to access, correct, update, or request deletion of your Personal Information.</li>
                                    <li>The right to object to the processing of your Personal Information, ask us to restrict processing, or request data portability. </li>
                                    <li>The right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal. </li>
                                    <li>
                                        The right to lodge a complaint with a supervisory authority if you believe our processing of your Personal Information violates applicable data protection laws.{" "}
                                    </li>
                                    <li>
                                        To exercise your rights, please contact us using the contact information provided at the end of this Privacy Policy. We will respond to your request in
                                        accordance with applicable data protection laws.
                                    </li>
                                </ul>
                            </div>
                            <div className="section_top_text">
                                <h6 className="text_transform">
                                    <b>10. CONTACT US </b>
                                </h6>
                                <p className="text_padding_left">
                                    If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at: privacy@factorflow.com We will make
                                    every effort to address your inquiries and resolve any concerns in a timely manner.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-1"></div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
