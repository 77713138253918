import React, { useEffect, useState } from "react";
// Icons
import { BiUserCircle } from "react-icons/bi";
import { BsCheckLg } from "react-icons/bs";
// Redux
import { useSelector } from "react-redux";
import { mainViewState } from "../../../../../redux/main-view";
// Prime React
import { BreadCrumb } from "primereact/breadcrumb";
// UI Components
import Contract from "../ProspectComponent/Contract";
import TermSheet from "../ProspectComponent/TermSheet";
import BusinessSearch from "../ProspectComponent/BusinessSearch";
import ProspectForm from "../../../../../ui/ProspectForm/ProspectForm";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { useHistory } from "react-router-dom";
import { SideBarHandler } from "../../../../../utils/HelperFuctions/sideBarFormDirty";
import { loginState } from "../../../../../redux/login";
import { PROSPECT_DETAILS_HEADER_ITEMS } from "../../../../../utils/Constants/global";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";

const AddNewProspect = (props) => {
    // Accessing LocalStorage Value
    const isEdit = JSON.parse(localStorage.getItem("isEditing"));
    // State
    const [activeCard, setActiveCard] = useState(-1);
    const [isVisible, setIsVisible] = useState();

    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    // Hooks
    const history = useHistory();

    const handleCardClick = (index) => {
        setActiveCard(index);
    };

    const renderCardComponent = () => {
        if (activeCard === 0) {
            return <ProspectForm />;
        } else if (activeCard === 1) {
            return <BusinessSearch />;
        } else if (activeCard === 2) {
            return <TermSheet />;
        } else if (activeCard === 3) {
            return <Contract />;
        }
        return null;
    };

    const navigationHandler = () => {
        history.push("/prospectindex");
        SideBarHandler();
        setIsVisible(false);
    };
    const handleProspectClick = () => {
        if (screens.main.isFormDirty) {
            setIsVisible(true);
        } else {
            SideBarHandler();
            history.push("/prospectindex");
        }
    };
    //bredcrumbs
    const items = [{ label: <span onClick={handleProspectClick}>Prospects</span> }, { label: `${isEdit ? "Edit" : "Add New"} Prospect` }];
    const home = { icon: "bi bi-people" };
    // Use Effect
    const onLandingScreenHandler = () => {
        if (!user) return;
        const required_rights = PROSPECT_DETAILS_HEADER_ITEMS.map((header) => header.required_rights).flat();
        const index = required_rights.findIndex((r_right) => user?.rights.includes(r_right));
        if (index === -1) {
            history.goBack();
            return;
        }
        setActiveCard(index);
    };

    // Use Effect
    useEffect(() => {
        onLandingScreenHandler();
    }, [user]);

    return (
        <>
            <div>
                <div className="">
                    <BreadCrumb model={items} home={home} />
                </div>
            </div>
            <div className="pros_main_header">
                <div className="bg_header dp_flex">
                    {PROSPECT_DETAILS_HEADER_ITEMS.map((header, index, arr) => {
                        return (
                            validateUserPermissions(header.required_rights, user?.rights ?? []) &&
                            (header.isEdit ? (
                                screens.prospect.isEditing || isEdit ? (
                                    <div onClick={() => handleCardClick(index)} className={`text-center ${activeCard === index ? "text_clr_class_active" : "None_text_clr_class_active"}`}>
                                        <div className="flex flex-row align-items-center justify-content-center cursor-pointer">
                                            {header.icon}
                                            <h6>{header.title}</h6>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <div onClick={() => handleCardClick(index)} className={`text-center ${activeCard === index ? "text_clr_class_active" : "None_text_clr_class_active"}`}>
                                    <div className="flex flex-row align-items-center justify-content-center cursor-pointer">
                                        <BiUserCircle className="header_icon" />
                                        <h6>{header.title}</h6>
                                    </div>
                                </div>
                            ))
                        );
                    })}
                </div>
                <div className="col-12">{renderCardComponent()}</div>
            </div>
            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={navigationHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default AddNewProspect;
