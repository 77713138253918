import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import FactorFlow from "../../Images/LoginPageLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../ui/CustomInputField/CustomInputField";
import { setPassword, validatePasswordToken } from "../../services/user-management/user";
import { ToastContext } from "../../context/toast";
import "../login/setpass.scss";
import CustomLoading from "../../ui/CustomSpinner/custom_spinner";

const SetPass = () => {
    let params = useParams();

    const toastContext = useContext(ToastContext);
    const [loading, setLoading] = useState(false);

    let history = useHistory();
    // Formik validation
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .max(20, "Maximum length should be 20")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
        confirmpassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            password: "",
            confirmpassword: "",
        },
        onSubmit: async (data) => {
            try {
                let dto = {
                    token: params?.guid,
                    password: data.password,
                };
                let resp = await setPassword(dto);

                if (resp.status) {
                    localStorage.clear();
                    toastContext.updateToast("Password");
                    history.push("/login");
                } else {
                    toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (error) {
                toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });

    useEffect(() => {
        const validate = async () => {
            if (params?.guid) {
                try {
                    setLoading(true);
                    let dto = {
                        token: params?.guid,
                    };

                    let resp = await validatePasswordToken(dto);
                    if (!resp.data.status) {
                        history.push("/linkexpire");
                    }
                } catch (error) {
                    toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
                setLoading(false);
            }
        };

        validate();
    }, [params]);

    return (
        <>
            {loading ? (
                <>
                    <CustomLoading />
                </>
            ) : (
                <>
                    {" "}
                    <div className="background_image">
                        <div className="grid">
                            <div className="lg:col-7 md:col-6"></div>
                            <div className="lg:col-5 md:col-6 col-12">
                                {/* Logo Text Section */}

                                <div className="custom_margin_top">
                                    <div className="res_img">
                                        <img src={FactorFlow} alt="Factor Flow Logo" />
                                    </div>
                                    <div className="pt-2">
                                        <h4>One tool for your whole team.</h4>
                                    </div>
                                </div>

                                {/* Container Section */}

                                <div className="container">
                                    <p className="sign_in_text">Set Password</p>
                                    <p className="sign_text">Set your password to access the Portal!</p>

                                    <div className="pt-3">
                                        {/* input section  */}
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mb-3 custom_class">
                                                <CustomInputField
                                                    className=""
                                                    iden="password"
                                                    label="Password"
                                                    formik={formik}
                                                    placeHolder="Enter password"
                                                    type="password"
                                                    toggleMask
                                                    id="password"
                                                    name="password"
                                                />
                                            </div>
                                            <div className="mb-3 custom_class">
                                                <CustomInputField
                                                    className=""
                                                    iden="confirmpassword"
                                                    label="Confirm Password"
                                                    formik={formik}
                                                    placeHolder="Enter confirm password"
                                                    type="password"
                                                    id="confirmpassword"
                                                    name="confirmpassword"
                                                    toggleMask
                                                />
                                            </div>
                                            {/* button section */}
                                            <div className="flex flex-row justify-content-end mt-5">
                                                <button className="btn sign_in_btn" type="submit" id="savebtn" name="savebtn">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                        {/* Register section */}

                                        <div className="pt-3 text-center">
                                            <p className="client_text">{/* Not yet a client? */}</p>
                                            <button
                                                className="register_btn"
                                                onClick={(e) => {
                                                    history.push("/login");
                                                }}
                                                id="register_btn"
                                                name="register_btn"
                                            >
                                                Back to login
                                            </button>
                                        </div>
                                    </div>

                                    {/* Privacy Policy Section */}

                                    <div className="pt-6 text-center">
                                        <p className="footer_text">
                                            By registering, you accept our
                                            <br />
                                            <a>Terms of use</a> and{" "}
                                            <a>
                                                <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SetPass;
