import React, { useState, useRef, useEffect, useContext } from "react";

// Components
import Header from "./dashboardcomponents/HeaderSection/Header";
import DataTableView from "./dashboardcomponents/DataTableView/table";
import LineGraph from "./dashboardcomponents/DashboardGraph/linegraph";
import CustomCardView from "./dashboardcomponents/CustomCardView/CustomCardView";
import AvgDebtBarGraph from "./dashboardcomponents/DashboardGraph/AvgDebtBarGraph";
import PaymentTrendBarGraph from "./dashboardcomponents/DashboardGraph/PaymentTrendBarGraph";
import { getClientDropdown } from "../services/client";
import { getDebtorsForDropdown } from "../services/debtor";
import { fetchAnalytics } from "../services/dashboard";
import { fetchDebtConcentration } from "../services/dashboard";
import { ToastContext } from "../context/toast";
import CustomerCargebackTrendGraph from "./dashboardcomponents/DashboardGraph/CustomerCargebackTrendGraph";
import AvgMonthlyCollectionEfficiency from "./dashboardcomponents/DashboardGraph/AvgCollectionEfficiency";
import { ROLE_NAME } from "../utils/Constants/global";
import { loginState } from "../redux/login";
import { useSelector } from "react-redux";

const Dashboard = (props) => {
    //filter State
    const [analyticsLoading, setAnalyticsLoading] = useState(false);
    const [debtConcentrationLoading, setDebtConcentrationLoading] = useState(false);

    //states
    const [selectedClient, setSelectedClient] = useState(0);
    const [selectedDebtors, setSelectedDebtors] = useState(0);
    const [dropdownClients, setDropdownClients] = useState([]);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [debtorsData, setDebtorsData] = useState([]);
    const [collectionData, setCollectionData] = useState([]);

    // View States
    const [clientLoading, setClientLoading] = useState(false);
    const [debtorLoading, setDebtorLoading] = useState(false);

    // Redux
    const { user } = useSelector(loginState);

    // onchange functions
    const onClientChange = (e) => {
        setSelectedClient(e.value);
    };

    const onDebtorsChange = (e) => {
        setSelectedDebtors(e.value);
    };

    // Pagination
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [totalClients, setTotalClients] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    // Context
    const toastContext = useContext(ToastContext);

    // Data
    const [response, setResponse] = useState([]);
    const [analytics, setAnalytics] = useState([]);
    const [cardsData, setCardsData] = useState({
        average_aging: 0,
        total_debt: 0,
        total_paid_amount: 0,
        average_debt_per_debtor: 0,
    });

    //toast message
    const toast = useContext(ToastContext);

    // Handlers

    // getClientDropdown
    const fetchClients = async () => {
        setClientLoading(true);
        try {
            const res = await getClientDropdown();
            if (res.data.status) {
                if (res.data.prospects.data.length > 0) res.data.prospects.data.unshift({ id: 0, company_business_name: "All Clients" });
                setDropdownClients(res.data.prospects.data);
                setDropdownDebtors([{ id: 0, legal_name: "All Debtors" }]);
                setClientLoading(false);
            }
        } catch (e) {
            setClientLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchDebtors = async () => {
        if (selectedClient) {
            setDebtorLoading(true);
            try {
                let payload = {
                    prospect_id: selectedClient,
                };
                let res = await getDebtorsForDropdown(payload);
                if (res.data.status) {
                    res.data.debtors.unshift({ id: 0, legal_name: "All Debtors" });
                    setDropdownDebtors(res.data.debtors);
                    setSelectedDebtors(0);
                    setDebtorLoading(false);
                    if (res.data.debtors.length > 0) {
                        setDebtorLoading(false);
                    }
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        }
    };

    const onFetchAnalyticsHandler = async () => {
        if ((!selectedFromDate && selectedToDate) || (!selectedToDate && selectedFromDate)) return;

        try {
            setAnalyticsLoading(true);
            const payload = {
                prospect_id: selectedClient,
                debtor_id: selectedDebtors,
                from_date: selectedFromDate,
                to_date: selectedToDate,
            };

            const res = await fetchAnalytics(payload);

            if (res.data.status) {
                const analyticsResp = res.data.analytics;
                setAnalytics(analyticsResp);

                setCardsData({
                    average_aging: analyticsResp.average_aging,
                    total_debt: analyticsResp.total_debt,
                    total_paid_amount: analyticsResp.total_paid_amount,
                    average_debt_per_debtor: analyticsResp.average_debt_per_debtor,
                });

                setDebtorsData(analyticsResp.debtors_data);
                setCollectionData(analyticsResp.collection_data);
            }
            setAnalyticsLoading(false);
        } catch (error) {
            setAnalyticsLoading(false);
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const onFetchDebtConcentrationHandler = async () => {
        if ((!selectedFromDate && selectedToDate) || (!selectedToDate && selectedFromDate)) return;

        try {
            setDebtConcentrationLoading(true);
            const payload = {
                per_page: pageSize,
                page: page ? page : currentPage,
                prospect_id: selectedClient,
                debtor_id: selectedDebtors,
                from_date: selectedFromDate,
                to_date: selectedToDate,
            };

            const res = await fetchDebtConcentration(payload);

            if (res.data.status) {
                setResponse(res.data.clients.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.clients.total,
                    totalPages: res.data.clients.last_page,
                    isLastPage: res.data.clients.last_page,
                });

                setTotalClients(res.data.clients.total);
            }
            setDebtConcentrationLoading(false);
        } catch (error) {
            setDebtConcentrationLoading(false);
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const pageNumber = page;

    // Use Effect
    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        fetchDebtors();
    }, [selectedClient]);

    useEffect(() => {
        onFetchAnalyticsHandler();
    }, [selectedClient, selectedDebtors, selectedFromDate, selectedToDate]);

    useEffect(() => {
        onFetchDebtConcentrationHandler();
    }, [selectedClient, selectedDebtors, selectedFromDate, selectedToDate, pageNumber]);

    return (
        <>
            <div className="grid">
                {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT ? (
                    <Header
                        setSelectedClient={setSelectedClient}
                        setSelectedDebtors={setSelectedDebtors}
                        setSelectedFromDate={setSelectedFromDate}
                        dropdownClients={dropdownClients}
                        dropdownDebtors={dropdownDebtors}
                        setSelectedToDate={setSelectedToDate}
                        selectedClient={selectedClient}
                        selectedDebtors={selectedDebtors}
                        selectedFromDate={selectedFromDate}
                        selectedToDate={selectedToDate}
                        onClientChange={onClientChange}
                        onDebtorsChange={onDebtorsChange}
                        clientLoading={clientLoading}
                        debtorLoading={debtorLoading}
                    />
                ) : null}

                <CustomCardView cardsData={cardsData} />
            </div>
            <div className="grid pt-3">
                <DataTableView
                    totalClients={totalClients}
                    response={response}
                    debtConcentrationLoading={debtConcentrationLoading}
                    analyticsLoading={analyticsLoading}
                    onCustomPage={onCustomPage}
                    pagination={pagination}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    pageInputTooltip={pageInputTooltip}
                    setCurrentPage={setCurrentPage}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    setPageInputTooltip={setPageInputTooltip}
                    page={page}
                />
                <LineGraph debtorsData={debtorsData} />
            </div>
            <div className="grid pt-3">
                <AvgDebtBarGraph debtorsData={debtorsData} />
                <PaymentTrendBarGraph collectionData={collectionData} />
                {/* <CustomerCargebackTrendGraph />
                <AvgMonthlyCollectionEfficiency /> */}
            </div>
        </>
    );
};
export default Dashboard;
