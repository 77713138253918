import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Prime React
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onFormDirty, onProspectCountryCodes, onProspectDataChange, onProspectFormDirty, onProspectKnobValue } from "../../../redux/main-view";

// Context
import { ToastContext } from "../../../context/toast";

// UI Components
import CustomTextField from "../../CustomTextField/CustomTextField";
import CustomCancelbtn from "../../CustomCancelbtn/CustomCancelbtn";
import CustomInputField from "../../CustomInputField/CustomInputField";
import CustomAddNewButton from "../../CustomAddNewButton/CustomAddNewButton";
import ZipCodesMenuComponent from "../../ZipCodesMenu/ZipCodesMenuComponent";
import CustomDropdownField from "../../CustomDropdownField/CustomDropdownField";

// Constants
import { BUSINESS_LOAN_OUTSTANDING_STATUS, FACTORED_BEFORE_STATUS } from "../../../utils/Constants/global";

// Helper Function
import { AmountCheck } from "../../../utils/HelperFuctions/checkAmount";
import { convertingDateToUTC } from "../../../utils/HelperFuctions/convertingDateToUTC";
import { countryCodeSearchHandler } from "../../../utils/HelperFuctions/phoneCodeSearch";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";

// API'S
import { getZipCode } from "../../../services/zip-code";
import { getCountryCode } from "../../../services/country-code";
import { saveProspect } from "../../../services/prospect";
import { Button } from "primereact/button";
import { loginState } from "../../../redux/login";

export const FinancialInformation = (props) => {
    const { user } = useSelector(loginState);

    const { sendNextStep, frontline_color } = props;

    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // State
    const [zipCode, setZipCode] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
    });

    // View State
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isZipCodeSearch, setIsZipSearch] = useState(true);
    const [isCalenderVisibleAOD, setIsCalenderVisibleAOD] = useState(false);
    const [isCalenderVisible, setIsCalenderVisible] = useState(false);

    const validationSchema = Yup.object().shape({
        bank_name: Yup.string().required("Bank Name is required"),
        bank_account_number: Yup.string().required("Account No is required"),
        bank_routing_number: Yup.string().required("Routing No is required"),
        bank_zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
        monthly_factoring_volume: Yup.string().required("Expected Monthly Factoring Volume is required"),
        requested_funding_date: Yup.mixed().required("Requested First Funding Date is required"),
        funding_amount: Yup.string().required("Required Funding Amount is required"),
        bank_state: Yup.string().required("State is required"),
        bank_city: Yup.string().required("City is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bank_name: "",
            bank_account_number: "",
            account_opening_date: "",
            bank_routing_number: "",
            bank_zip: "",
            monthly_factoring_volume: "",
            requested_funding_date: "",
            funding_amount: "",
            bank_state: "",
            bank_city: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                data = {
                    ...data,
                    step: "3",
                    id: screens.prospect.data.id,
                    bank_country_phone_code: data.bank_country_phone_code ? data.bank_country_phone_code.phone_code : "+1",
                };
                if (screens.prospect.data?.bank_name) {
                    data["id"] = screens.prospect.data.id;
                }
                if (screens.prospect.isFormDirty) {
                    sendNextStep(4);
                    dispatch(onProspectFormDirty(false));
                } else {
                    let res = await saveProspect(data);
                    if (res) {
                        if (res.data.status) {
                            sendNextStep(4);
                            const isEditing = JSON.parse(localStorage.getItem("isEditing"));

                            if (isEditing) {
                                dispatch(onProspectKnobValue(100));
                            } else {
                                dispatch(onProspectKnobValue(51));
                            }
                            dispatch(onFormDirty(false));
                            dispatch(onProspectDataChange(res.data.prospect));
                        } else {
                            toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                        }
                    } else {
                        toast.showMessage("Error", "Server Error", "error");
                        setIsSaving(false);
                    }
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
                setIsSaving(false);
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Handlers
    const inputFieldDataCleaningHandler = (e, name, value) => {
        if (name === "bank_name" || name === "bank_officer") {
            return toTitleCaseWhileTyping(value);
        } else if (name === "requested_funding_date" || name === "account_opening_date") {
            if (e.originalEvent.key === "Enter" || e.originalEvent.type === "blur") {
                // Close the calendar modal
                // setIsCalenderVisible(true);
                // setIsCalenderVisibleAOD(true);
                if (name === "requested_funding_date") {
                    setIsCalenderVisible(true);
                } else if (name === "account_opening_date") {
                    setIsCalenderVisibleAOD(true);
                }

                // if (e.originalEvent.type === "blur") {
                const nextInput = document.getElementById(name === "account_opening_date" ? "bank_routing_number" : "funding_amount");
                if (nextInput) {
                    // nextInput.focus();
                }
                // }
            }
            if (value) {
                return convertingDateToUTC(value);
                // return new Date(value);
            } else {
                return "";
            }
        } else if (name === "bank_balance" || name === "monthly_factoring_volume" || name === "receivables_outstanding" || name === "funding_amount") {
            return AmountCheck(value);
        } else if (name === "bank_country_phone_code" || name === "business_loan_outstanding_status") {
            return value;
        } else {
            return textExtraSpaceRemoval(value);
        }
    };

    const inputHandler = (e) => {
        dispatch(onFormDirty(true));
        dispatch(onProspectFormDirty(false));
        const { name, value } = e.target;

        if (name === "factored_before_status") {
            if (value === 0) {
                formik.setFieldValue("factored_before_name", "");
                formik.setFieldValue("factored_before_status", value);
            } else {
                formik.setFieldValue(name, value);
            }
        } else {
            formik.setFieldValue(name, inputFieldDataCleaningHandler(e, name, value));
        }
    };

    const handleCurrencyChange = (event, name) => {
        dispatch(onFormDirty(true));
        dispatch(onProspectFormDirty(false));
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRcordNotFoundMsg("");
        formik.values.bank_zip = zip.zip;
        formik.values.bank_state = zip.full_state;
        formik.values.bank_city = zip.city;
        formik.setFieldError("bank_state", "");
        formik.setFieldError("bank_city", "");
        formik.setFieldError("zip", "");
        setZipCode([]);
    };

    const handleSaveAndNext = () => {
        dispatch(onProspectKnobValue(51));
        sendNextStep(4);
    };
    const handlePrevious = () => {
        sendNextStep(2);
    };

    // API'S Handler
    const fetchZipCodeData = (value) => {
        const payload = {
            zip: value,
        };
        getZipCode(payload).then((res) => {
            setZipCode(res.data.codes);
            if (res.data.codes.length == 0) {
                setZipCode([]);
                setRcordNotFoundMsg("No Record Available");
            }
        });
    };
    const fetchCountryCodes = async () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                setCountryCode(res.data.countries);
                dispatch(onProspectCountryCodes(res.data.countries));
            });
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // UseEffect
    useEffect(() => {
        if (formik.values.bank_zip && formik.values.bank_zip?.length >= 4 && isZipCodeSearch) {
            fetchZipCodeData(formik.values.bank_zip);
        } else if (formik.values.bank_zip?.length < 4) {
            setZipCode([]);
            setIsZipSearch(true);
            formik.values.bank_state = "";
            formik.values.bank_city = "";
        }
    }, [formik.values.bank_zip]);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCode(screens.prospect.countryCodes);
        } else {
            fetchCountryCodes();
        }
        if (screens.prospect.data?.bank_name) {
            dispatch(onProspectFormDirty(true));
            setIsZipSearch(false);
            const result = screens.prospect.data;
            formik.setFieldValue("bank_name", result?.bank_name);
            formik.setFieldValue("bank_account_number", result?.bank_account_number);
            formik.setFieldValue("account_opening_date", new Date(result?.account_opening_date));
            // formik.setFieldValue("account_opening_date", moment(result?.account_opening_date).format("YYYY-MM-DD"));
            formik.setFieldValue("bank_routing_number", result?.bank_routing_number);
            formik.setFieldValue("bank_officer", result?.bank_officer);
            formik.setFieldValue("bank_balance", result?.bank_balance);
            formik.setFieldValue("bank_zip", result?.bank_zip);
            formik.setFieldValue("bank_state", result?.bank_state);
            formik.setFieldValue("bank_city", result?.bank_city);
            formik.setFieldValue("bank_phone", result?.bank_phone);
            formik.setFieldValue("business_loan_outstanding_status", result?.business_loan_outstanding_status === null ? "" : result?.business_loan_outstanding_status ? 1 : 0);
            formik.setFieldValue("monthly_factoring_volume", result?.monthly_factoring_volume);
            formik.setFieldValue("receivables_outstanding", result?.receivables_outstanding);
            formik.setFieldValue("requested_funding_date", new Date(result?.requested_funding_date));
            // formik.setFieldValue("requested_funding_date", moment(result?.requested_funding_date).format("YYYY-MM-DD"));
            formik.setFieldValue("funding_amount", result?.funding_amount);
            formik.setFieldValue("factored_before_status", result?.factored_before_status === null ? "" : result?.factored_before_status ? 1 : 0);
            formik.setFieldValue("factored_before_name", result?.factored_before_name);
            setPhoneCodesPlaceholders({
                phone_code: result?.bank_country_phone_code,
            });
        }
    }, []);
    const handlePaste = (e) => {
        e.preventDefault(); // Prevent the default paste behavior
    };

    return (
        <>
            <form className="step-form-top-padding" autoComplete="off">
                {/* Card 1 */}
                <div className={frontline_color ? "frontLine_scroll" : "pros_scrollbar"}>
                    <Fieldset legend="Bank Information">
                        <div className="grid">
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="bank_name"
                                label="Bank Name "
                                mandatory="*"
                                maxLength={35}
                                formik={formik}
                                placeHolder="Enter Bank Name"
                                onChange={(e) => inputHandler(e)}
                                keyfilter={/^[a-zA-Z\s]+$/}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="bank_account_number"
                                label="Account No"
                                mandatory="*"
                                maxLength={25}
                                keyfilter="alphanum"
                                formik={formik}
                                placeHolder="Enter Account No"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                                disabled={ screens.prospect.data?.bank_account_number?.length > 0 && !user?.sensitive_information_disclosure ? true : false}
                            />
                            <div className="col-12 md:col-2">
                                <label htmlFor="phone" className="step-fields-title">
                                    <b>Account Opening Date</b>
                                </label>
                                <div className="mt-2">
                                    <Calendar
                                        id="account_opening_date"
                                        name="account_opening_date"
                                        // value={formik.values.account_opening_date}
                                        value={new Date(formik.values.account_opening_date)}
                                        placeholder="mm/dd/yyyy"
                                        mask="99/99/9999"
                                        showIcon
                                        maxDate={new Date()}
                                        onChange={(e) => inputHandler(e)}
                                        onVisibleChange={(e) => setIsCalenderVisibleAOD(e.visible)}
                                        visible={isCalenderVisibleAOD}
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1945:2050"
                                    />
                                </div>
                            </div>

                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="bank_routing_number"
                                label="Routing No"
                                mandatory="*"
                                maxLength={9}
                                keyfilter="int"
                                formik={formik}
                                placeHolder="Enter Routing No"
                                onChange={(e) => inputHandler(e)}
                                onPaste={(e) => e.preventDefault()}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="bank_officer"
                                label="Bank Officer Name "
                                maxLength={30}
                                formik={formik}
                                placeHolder="Enter Bank Officer Name"
                                onChange={(e) => inputHandler(e)}
                                keyfilter={/^[a-zA-Z\s]+$/}
                                autoComplete="none"
                            />

                            <CustomInputField
                                className="col-12 md:col-2"
                                iden="bank_balance"
                                label="Current Available Balance"
                                formik={formik}
                                type="currency"
                                placeHolder="$0.00"
                                onPaste={(e) => e.preventDefault()}
                                onValueChange={(e) => handleCurrencyChange(e, "bank_balance")}
                                min={0}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                max={99999999.99}
                                autoComplete="none"
                            />

                            <div className="col-12 md:col-2">
                                <div className="zip_menu_position">
                                    <CustomTextField
                                        className=""
                                        iden="bank_zip"
                                        label="Zip Code"
                                        mandatory="*"
                                        keyfilter="int"
                                        formik={formik}
                                        placeHolder="Search..."
                                        maxLength={5}
                                        onChange={(e) => inputHandler(e)}
                                        onPaste={(e) => e.preventDefault()}
                                        autoComplete="none"
                                    />
                                    <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                    <small className="p-error">{recordNotFoundMsg}</small>
                                </div>
                            </div>
                            <CustomTextField disabled mandatory="*" className="col-12 md:col-2" iden="bank_state" label="State" formik={formik} placeHolder="" />
                            <CustomTextField disabled mandatory="*" className="col-12 md:col-2" iden="bank_city" label="City" formik={formik} placeHolder="" />
                            <div className="md:col-3 col-12 row-margin-bottom">
                                <label htmlFor="phone" className="step-fields-title">
                                    <b>Branch Phone</b>
                                </label>
                                <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                    <Dropdown
                                        id="bank_country_phone_code"
                                        name="bank_country_phone_code"
                                        value={formik.values.bank_country_phone_code}
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_country"
                                        options={countryCode}
                                        optionLabel="phone_code"
                                        placeholder={phoneCodesPlaceholders.phone_code}
                                        filter
                                        filterBy="phone_code"
                                        emptyMessage="No results found"
                                        disabled={loading}
                                    />

                                    <InputMask
                                        name="bank_phone"
                                        id="bank_phone"
                                        mask="999-999-9999"
                                        value={formik.values.bank_phone}
                                        placeholder="999-999-9999"
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_phone"
                                        autoComplete="none"
                                    ></InputMask>
                                </div>
                                {getFormErrorMessage("phone")}
                            </div>
                            <CustomDropdownField
                                className="col-12 md:col-3"
                                iden="business_loan_outstanding_status"
                                label="Loan Outstanding?"
                                formik={formik}
                                options={BUSINESS_LOAN_OUTSTANDING_STATUS}
                                optionLabel="name"
                                optionValue="value"
                                placeHolder="Select"
                                onChange={(e) => inputHandler(e)}
                                onPaste={handlePaste}
                                autoComplete="none"
                            />
                        </div>
                    </Fieldset>

                    {/* Card 2 */}

                    <Fieldset legend="Miscellaneous Information" className="mt-3">
                        <div className="grid">
                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="monthly_factoring_volume"
                                label="Expected Monthly Factoring Volume"
                                mandatory="*"
                                formik={formik}
                                type="currency"
                                placeHolder="$0.00"
                                onPaste={(e) => e.preventDefault()}
                                onValueChange={(e) => handleCurrencyChange(e, "monthly_factoring_volume")}
                                min={0}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                max={99999999.99}
                                autoComplete="none"
                            />

                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="receivables_outstanding"
                                label="Current Receivable Outstanding?"
                                formik={formik}
                                type="currency"
                                placeHolder="$0.00"
                                onPaste={(e) => e.preventDefault()}
                                onValueChange={(e) => handleCurrencyChange(e, "receivables_outstanding")}
                                min={0}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                max={99999999.99}
                                autoComplete="none"
                            />
                            <div className="col-12 md:col-4">
                                <label htmlFor="phone" className="step-fields-title">
                                    <b>Requested First Funding Date</b>
                                    <span className="clr_red">*</span>
                                </label>
                                <div className="mt-2">
                                    <Calendar
                                        id="requested_funding_date"
                                        name="requested_funding_date"
                                        // value={formik.values.requested_funding_date}
                                        value={new Date(formik.values.requested_funding_date)}
                                        placeholder="mm/dd/yyyy"
                                        mask="99/99/9999"
                                        showIcon
                                        // maxDate={new Date()}
                                        onChange={(e) => inputHandler(e)}
                                        onVisibleChange={(e) => setIsCalenderVisible(e.visible)}
                                        visible={isCalenderVisible}
                                        onPaste={(e) => e.preventDefault()}
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1945:2050"
                                    />
                                </div>
                                {getFormErrorMessage("requested_funding_date")}
                            </div>
                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="funding_amount"
                                label="Required Funding Amount"
                                mandatory="*"
                                formik={formik}
                                type="currency"
                                placeHolder="$0.00"
                                onPaste={(e) => e.preventDefault()}
                                onDrop={(e) => e.preventDefault()}
                                onValueChange={(e) => handleCurrencyChange(e, "funding_amount")}
                                min={0}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                max={99999999.99}
                                autoComplete="none"
                            />
                            <CustomDropdownField
                                className="col-12 md:col-4"
                                iden="factored_before_status"
                                label="Have You Factored Before?"
                                formik={formik}
                                options={FACTORED_BEFORE_STATUS}
                                optionLabel="name"
                                optionValue="value"
                                placeHolder="Select"
                                onChange={(e) => inputHandler(e)}
                                onPaste={(e) => e.preventDefault()}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-4"
                                iden="factored_before_name"
                                label="Factored with Whom?"
                                formik={formik}
                                maxLength="60"
                                placeHolder=""
                                disabled={formik.values.factored_before_status === 1 ? false : true}
                                onChange={(e) => inputHandler(e)}
                                onPaste={(e) => e.preventDefault()}
                                autoComplete="none"
                            />
                        </div>
                    </Fieldset>
                </div>
                {/* Buttons */}

                <div className="action-btns-prospect-form p-3">
                    <CustomCancelbtn title="Previous" type="button" onClick={handlePrevious} />
                    {frontline_color ? (
                        <Button label="Save and Next" className="fronLineSavebtn" type="button" disabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} />
                    ) : (
                        <CustomAddNewButton title="Save and Next" type="button" isDisabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} />
                    )}
                    {/* <CustomAddNewButton title="Save and Next" type="submit" onClick={handleSaveAndNext} /> */}
                </div>
            </form>
        </>
    );
};
