import React from "react";

// Icons
import { BsCheck, BsCheckAll, BsX } from "react-icons/bs";

// UI Componets
import { AVAILABLE_PROSPECT, DULLY_FILLED_APPLICATION, FALL_OUT } from "../../../../../utils/Constants/global";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onProspectMenuChange } from "../../../../../redux/main-view";

const Header = (props) => {
    const { setActiveCard, activeCard, setSelectedColumns, setHeader, setFilledForm, setStatus, count, setComponentName, setCurrentPage, setPage, setShowAll, setSelectedUsers } = props;

    const dispatch = useDispatch();
    const ViewChangeHandler = (value) => {
        setActiveCard(value);
        if (value === 0) {
            setSelectedColumns(AVAILABLE_PROSPECT);
            setComponentName("available_prospect");
            setHeader(AVAILABLE_PROSPECT);
            setFilledForm(true);
            setStatus(0);

            // Handle Refreshing Case
            const data = {
                status: 0,
                activeCard: value,
                filledForm: true,
                selectedColums: AVAILABLE_PROSPECT,
                header: AVAILABLE_PROSPECT,
                componentName: "available_prospect",
            };
            dispatch(onProspectMenuChange(data));

            localStorage.setItem("MenuData", JSON.stringify(data));
            setCurrentPage(1);
            setPage(1);
            setShowAll(false);
        } else if (value === 1) {
            setSelectedColumns(DULLY_FILLED_APPLICATION);
            setComponentName("dully_filled");
            setHeader(DULLY_FILLED_APPLICATION);
            setFilledForm(false);
            setStatus(0);

            const data = {
                status: 0,
                activeCard: value,
                filledForm: false,
                selectedColums: DULLY_FILLED_APPLICATION,
                header: DULLY_FILLED_APPLICATION,
                componentName: "dully_filled",
            };
            dispatch(onProspectMenuChange(data));
            // Handle Refreshing Case
            localStorage.setItem("MenuData", JSON.stringify(data));

            // localStorage.setItem("status", 0);
            setCurrentPage(1);
            setPage(1);
            setShowAll(false);
        } else {
            setSelectedColumns(FALL_OUT);
            setComponentName("fall_out");
            setHeader(FALL_OUT);
            setFilledForm(true);
            setStatus(2);

            const data = {
                status: 2,
                activeCard: value,
                filledForm: true,
                selectedColums: FALL_OUT,
                header: FALL_OUT,
                componentName: "fall_out",
            };

            dispatch(onProspectMenuChange(data));
            // Handle Refreshing Case
            localStorage.setItem("MenuData", JSON.stringify(data));
            setCurrentPage(1);
            setPage(1);
            setShowAll(false);
        }

        setSelectedUsers([]);
    };
    return (
        <div className="pros_main">
            <div className="grid">
                <div className="col-7 md:col-10">
                    <h4 className="m-0">
                        <b>Prospects</b>
                    </h4>
                </div>
            </div>
            <div className="grid">
                <div className="md:col-4 col-12">
                    <div className={`card flex justify-content-between align-items-center text-center ${activeCard === 0 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(0)}>
                        <div className="icon_bg">
                            <BsCheckAll className="circle_icon_class" />
                        </div>
                        <h6>
                            <b>Available Prospect(s)</b>
                        </h6>
                        <p className="mr-3">{count.total_prospects}</p>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className={`card flex justify-content-between align-items-center  text-center ${activeCard === 1 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(1)}>
                        <div className="icon_bg">
                            <BsCheck className="circle_icon_class" />
                        </div>
                        <h6>
                            <b>Duly Filled Application(s)</b>
                        </h6>
                        <p className="mr-3">{count.total_duly_filled_applications}</p>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className={`card flex justify-content-between align-items-center  text-center ${activeCard === 2 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(2)}>
                        <div className="icon_bg">
                            <BsX className="circle_icon_class" />
                        </div>
                        <h6>
                            <b>Fallout(s)</b>
                        </h6>
                        <p className="mr-3">{count.total_fallouts}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
