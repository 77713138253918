import React, { useContext, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Context & APIs
import { addUnderwritingVertical } from "../../../../services/under-writing";
import { ToastContext } from "../../../../context/toast";
// UI Components
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../utils/HelperFuctions/cleanData";
import { onUnderWritingFormDirty } from "../../../../redux/main-view";
import { useDispatch } from "react-redux";
const AddVertical = ({ onHide, clientId, underWritings }) => {
    const toastContext = useContext(ToastContext);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        tasktitle: Yup.string().required("Vertical Title is required").trim(),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tasktitle: "",
        },
        onSubmit: async (data) => {
            let _underwritings = underWritings.filter((item) => item.name === data.tasktitle);
            if (_underwritings.length !== 0) {
                toastContext.showMessage("Validation Failed", "Vertical Title should be unique.", "error");
            } else {
                try {
                    setIsSaving(true);
                    let dto = {
                        prospect_id: clientId,
                        name: data.tasktitle.trim(),
                    };
                    let resp = await addUnderwritingVertical(dto);
                    setIsSaving(false);
                    if (resp.data.status) {
                        toastContext.createdToast("Vertical");
                        onHide(true, "add vertical");
                    }
                    return;
                } catch (error) {
                    setIsSaving(false);
                }
            }
        },
    });
    const inputHanlder = (e) => {
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);
        const finalValue = toTitleCaseWhileTyping(cleanValue);

        formik.setFieldValue(name, finalValue);
        dispatch(onUnderWritingFormDirty(true));
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField iden="tasktitle" label="Vertical Title" mandatory="*" placeHolder="Enter Vertical Title" formik={formik} maxLength={35} onChange={(e) => inputHanlder(e)} />
                    </div>

                    <div className="col-12 text-right pt-5">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => onHide(false, "add vertical")} />
                        <CustomAddNewButton title="Save" type="submit" isDisabled={isSaving} />
                    </div>
                </div>
            </form>
            {/* {isOpening && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpening(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )} */}
        </>
    );
};
export default AddVertical;
