import React, { useContext, useRef, useState } from "react";

// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
//Components
import CustomLoading from "../../../../../../../ui/CustomSpinner/custom_spinner";
// Helper Function
import { PaginatorTemplate } from "../../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { getInvoiceColumnBody } from "../../../../../../../utils/HelperFuctions/invoiceHelper";
import { ToastContext } from "../../../../../../../context/toast";
import { trimTextWithDots } from "../../../../../../../utils/HelperFuctions/trimText";
import { Tooltip } from "primereact/tooltip";

// Components
const Body = (props) => {
    const {
        selectedHeaders,
        selectedInvoices,
        setSelectedInvoices,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        setPage,
        page,
        componentName,
    } = props;

    //Use ref
    const ref = useRef(null);
    // States
    // const [page, setPage] = useState(currentPage);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [detaildialogVisible, setDetailDialogVisible] = useState(false);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [showTooltip, setShowTooltip] = useState(false);
    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedInvoices(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        // Row Selection
        if (event.type === "row") {
            setSelectedRowId(event.data.id);
            setDetailDialogVisible(true);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };
    const getNoteColumnBody = (colField) => (rowData) => {
        if (colField === "note") {
            const handleMouseEnter = () => {
                setShowTooltip(true);
            };

            const handleMouseLeave = () => {
                setShowTooltip(false);
            };
            return (
                <>
                    {rowData?.note && rowData.note.length > 25 ? (
                        <>
                            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <span id={`name-${rowData.id}`}>{trimTextWithDots(rowData.note, 25)}</span>
                                {showTooltip && (
                                    <Tooltip target={`#name-${rowData.id}`} position="left" onHide={() => setShowTooltip(false)}>
                                        {rowData.note}
                                    </Tooltip>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <span>{rowData.note}</span>
                        </>
                    )}
                </>
            );
        }
        return rowData[colField] ? rowData[colField] : "";
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={col.field === "note" ? getNoteColumnBody(col.field) : getInvoiceColumnBody(col.field)}
                sortable
                headerClassName={`${col.field === "invoice_amount" || col.field === "uc" || col.field === "amount" ? "amount_column_alignment" : ""}`}
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination

    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };
    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 pr-0 pl-0">
                    <div className="card p-1 m-0">
                        <DataTable
                            ref={ref}
                            value={selectedHeaders}
                            selection={selectedInvoices.length === 0 ? null : selectedInvoices}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            {componentName !== "unapplied_cash_released" && <Column selectionMode="multiple"></Column>}
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            )}
        </>
    );
};

export default Body;
