import React, { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getSchedulePayments } from "../../../../../../services/schedule-assignment";
import { ToastContext } from "../../../../../../context/toast";

// Prime React
import { Calendar } from "primereact/calendar";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../../../../ui/CustomInputField/CustomInputField";
import { getCustomDropDown } from "../../../../../../services/dropdown";
import { savePayment } from "../../../../../../services/payment";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";
import CustomModalSpinner from "../../../../../../ui/CustomModalSpinner/CustomModalSpinner";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const DeleteScheduleModal = (props) => {
    const { selectedSchedules, getGridData, onHide, isFormDirty, setIsFormDirty, setFormikIsDirty, isVisible } = props;

    //states
    const [actionchange, setActionChange] = useState("");
    const [loading, setLoading] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [paymentDropdowns, setPaymentDropdowns] = useState([]);
    const [dropdownsLoading, setDropdownsLoading] = useState(false);
    const [wireFeeSelection, setWireFeeSelection] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [amountCheck, setAmountCheck] = useState(99.99);
    const [minPostingDate, setMinPostingDate] = useState(null);

    const [checkNumberErrorLabel, setCheckNumberErrorLabel] = useState("");
    const [checkDateErrorLabel, setCheckDateErrorLabel] = useState("");
    const [checkDepositDateErrorLabel, setCheckDepositDateErrorLabel] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    //yup validation
    const validationSchema = Yup.object().shape({
        payment_type: Yup.string().required("Payment Type is required"),
        schedule_fund_date: Yup.string().required("Schedule Fund Date is required"),

        posting_date: Yup.string().required("Posting Date is required"),
        transaction_number: Yup.string().required(`${checkNumberErrorLabel} No is required`),
        check_date: Yup.string().required(`${checkDateErrorLabel} Date is required`),
        check_deposit_date: Yup.mixed().when("payment_type", {
            is: (val) => val !== "2",
            then: Yup.mixed().required(`${checkDepositDateErrorLabel} Deposit Date is required`),
        }),
    });

    //ref
    const dt = useRef(null);

    //toast message
    const toast = useContext(ToastContext);

    const onActionChange = (e, index) => {
        setActionChange(e.value);
    };

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            schedule_fund_date: "",
            transaction_number: "",
            payment_type: "",
            amount: "",
            wire_fee: "",
        },
        onSubmit: async (data) => {
            try {
                let amount = schedule.amount;
                selectedInvoices.forEach((invoice, index) => {
                    amount += invoice.advance_amount;
                });

                if (formik.values.paid_amount > 0 && formik.values.amount <= amount) {
                    setIsSaving(true);
                    setIsLoading(true);

                    const selectedUnverifiedInvoices = selectedInvoices.map((invoice) => invoice.id);
                    const unverifiedInvoices = schedule.unverified_invoices.filter((obj1) => !selectedUnverifiedInvoices.includes(obj1.id));
                    data = {
                        ...data,
                        schedule_fund_date: getDateFormat(formik.values.schedule_fund_date),
                        schedule_id: selectedSchedules[0].id,
                        selectedUnverifiedInvoices,
                        unverifiedInvoices,
                    };
                    delete data.requested_amount;
                    const res = await savePayment(data);
                    if (res.data.status == true) {
                        onHide();
                        getGridData();
                        toast.showMessage("Payment Funded", "Payment has been funded successfully.", "success");
                    } else {
                        toast.showMessage("Payment", "Sorry, we are unable to process your request at this time.", "error");
                    }
                    setIsSaving(false);
                    setIsLoading(false);
                } else {
                    toast.showMessage("Payment", "Payable amount is not correct.", "error");
                }
                return;
            } catch (error) {
                setIsSaving(false);
                setIsLoading(false);
                toast.showMessage("Payment", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });

    const fetchSchedulePayment = async () => {
        setLoading(true);
        let payload = {
            id: selectedSchedules[0].id,
        };
        try {
            const res = await getSchedulePayments(payload);
            if (res.data.status) {
                setSchedule(res.data);
                formik.setFieldValue("wire_fee", res.data.wireFee);
                formik.setFieldValue("amount", res.data.amount);
                formik.setFieldValue("paid_amount", res.data.paidAmount);
                formik.setFieldValue("requested_amount", res.data.requestedAmount);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (value) {
            formik.setFieldValue(name, value);
        } else {
            formik.setFieldValue(name, "");
        }
        if (name === "check_deposit_date") {
            formik.setFieldValue("posting_date", "");
        }
        if (name === "check_date" && formik.values.payment_type === 2) {
            setMinPostingDate(new Date(value));
        } else if (formik.values.payment_type !== 2 && name === "check_deposit_date") {
            setMinPostingDate(new Date(value));
        }
    };

    //DropDowns
    const handleDropdowns = async () => {
        setDropdownsLoading(true);
        let payload = {
            select: ["payment_type", "invoice_status"],
        };
        try {
            getCustomDropDown(payload).then((res) => {
                if (res.data.status) {
                    setPaymentDropdowns(JSON.parse(res.data.dropdowns.payment_type));
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDropdownsLoading(false);
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedInvoices(event.value);
        }
    };

    const cancelHandlder = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            onHide(false);
        }
    };

    const confirmHandler = () => {
        onHide(false);
    };
    useEffect(() => {
        fetchSchedulePayment();
        handleDropdowns();
    }, []);

    useEffect(() => {
        let amount = schedule.amount;
        selectedInvoices.forEach((invoice, index) => {
            amount += invoice.advance_amount;
        });
        formik.setFieldValue("paid_amount", amount - (formik.values.payment_type == 2 ? formik.values.wire_fee : 0));

        formik.setFieldValue("amount", amount);
    }, [selectedInvoices]);

    useEffect(() => {
        if (formik.values.payment_type === 2) {
            setWireFeeSelection(true);
            formik.setFieldValue("amount", formik.values.amount);
            formik.setFieldValue("paid_amount", formik.values.amount - formik.values.wire_fee);
        } else {
            setWireFeeSelection(false);
            formik.setFieldValue("amount", formik.values.amount);
            formik.setFieldValue("paid_amount", formik.values.amount);
        }
    }, [formik.values.payment_type]);

    const handleCurrencyChange = (event, name) => {
        let { value } = event;
        let amount = schedule.amount;
        selectedInvoices.forEach((invoice, index) => {
            amount += invoice.advance_amount;
        });
        if (value !== null) {
            if (value <= amount) {
                formik.setFieldValue("paid_amount", value - (formik.values.payment_type === 2 ? formik.values.wire_fee : 0));
                formik.setFieldValue("amount", value);
            } else {
                formik.setFieldValue("amount", amount);
                formik.setFieldValue("paid_amount", amount - (formik.values.payment_type === 2 ? formik.values.wire_fee : 0));
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (value === 0) {
            setCheckNumberErrorLabel("Routing");
            setCheckDateErrorLabel("ACH");
            setCheckDepositDateErrorLabel("ACH");
        } else if (value === 1) {
            setCheckNumberErrorLabel("Check");
            setCheckDateErrorLabel("Check");
            setCheckDepositDateErrorLabel("Check");
            formik.setFieldValue("check_date", "");
        } else if (value === 2) {
            setCheckNumberErrorLabel("Transaction");
            setCheckDateErrorLabel("Wire");
        }
        formik.setFieldValue("check_date", "");
        formik.setFieldValue("check_deposit_date", "");
        formik.setFieldValue("posting_date", "");

        formik.handleChange(e);
    };

    useEffect(() => {
        let amount = schedule?.amount ?? 0;
        selectedInvoices.forEach((invoice, index) => {
            amount += invoice.advance_amount;
        });
        setAmountCheck(amount);
    }, [schedule, selectedInvoices]);

    useEffect(() => {
        if (formik.dirty) setFormikIsDirty(true);
    }, [formik.values]);

    return (
        <>
            {loading ? (
                <CustomModalSpinner top={10} />
            ) : (
                <>
                    <div className="card term_sheet_view">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <label className="step-fields-title">
                                        <b>Schedule Fund Date</b>
                                        <span className="clr_red">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <Calendar
                                            id="schedule_fund_date"
                                            name="schedule_fund_date"
                                            value={new Date(formik.values.schedule_fund_date)}
                                            placeholder="mm/dd/yyyy"
                                            mask="99/99/9999"
                                            showIcon
                                            onChange={(e) => inputHandler(e)}
                                            minDate={new Date(schedule.latestInvoiceDate)}
                                            monthNavigator
                                            yearNavigator
                                            yearRange="1945:2050"
                                        />
                                    </div>
                                    {formik?.touched["schedule_fund_date"] && <small className="p-error">{formik?.errors["schedule_fund_date"]}</small>}
                                    {/* {getFormErrorMessage("company_date_established")} */}
                                </div>

                                <CustomInputField
                                    className={`col-12 md:col-4 ${dropdownsLoading && "no-drop"}`}
                                    options={paymentDropdowns}
                                    optionLabel="name"
                                    optionValue="value"
                                    iden="payment_type"
                                    label="Payment Type"
                                    formik={formik}
                                    type="dropdown"
                                    placeHolder="Select"
                                    mandatory="*"
                                    disabled={dropdownsLoading}
                                    onChange={(e) => handleInputChange(e)}
                                />

                                {formik.values.payment_type === 0 || formik.values.payment_type ? (
                                    <>
                                        <CustomInputField
                                            className="col-12 md:col-4"
                                            iden="transaction_number"
                                            label={
                                                formik.values.payment_type === 0
                                                    ? "Routing No"
                                                    : formik.values.payment_type === 1
                                                    ? "Check No"
                                                    : formik.values.payment_type === 2
                                                    ? "Transaction No"
                                                    : ""
                                            }
                                            mandatory="*"
                                            maxLength="20"
                                            keyfilter={/^[[0-9a-zA-Z]+$/}
                                            formik={formik}
                                            placeHolder={
                                                formik.values.payment_type === 0
                                                    ? "Enter Routing No"
                                                    : formik.values.payment_type === 1
                                                    ? "Enter Check No"
                                                    : formik.values.payment_type === 2
                                                    ? "Enter Transaction No"
                                                    : ""
                                            }
                                        />

                                        <div className="col-12 md:col-4">
                                            <label htmlFor="phone" className="step-fields-title">
                                                {formik.values.payment_type === 0 ? (
                                                    <b>ACH Date</b>
                                                ) : formik.values.payment_type === 1 ? (
                                                    <b>Check Date</b>
                                                ) : formik.values.payment_type === 2 ? (
                                                    <b>Wire Date</b>
                                                ) : (
                                                    ""
                                                )}
                                                <span className="clr_red">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Calendar
                                                    id="check_date"
                                                    name="check_date"
                                                    value={new Date(formik.values.check_date)}
                                                    placeholder="mm/dd/yyyy"
                                                    mask="99/99/9999"
                                                    showIcon
                                                    onChange={(e) => inputHandler(e)}
                                                    minDate={new Date(formik.values.schedule_fund_date)}
                                                    disabled={!formik.values.schedule_fund_date}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange="1945:2050"
                                                />
                                            </div>
                                            {getFormErrorMessage("check_date")}
                                        </div>

                                        {formik.values.payment_type !== 2 ? (
                                            <div className="col-12 md:col-4">
                                                <label htmlFor="phone" className="step-fields-title">
                                                    {formik.values.payment_type === 0 ? <b>ACH Deposit Date</b> : formik.values.payment_type === 1 ? <b>Check Deposit Date</b> : ""}
                                                    <span className="clr_red">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <Calendar
                                                        id="check_deposit_date"
                                                        name="check_deposit_date"
                                                        value={new Date(formik.values.check_deposit_date)}
                                                        placeholder="mm/dd/yyyy"
                                                        mask="99/99/9999"
                                                        minDate={new Date(formik.values.check_date)}
                                                        showIcon
                                                        onChange={(e) => inputHandler(e)}
                                                        disabled={!formik.values.check_date}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange="1945:2050"
                                                    />
                                                </div>
                                                {getFormErrorMessage("check_deposit_date")}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <div className="col-12 md:col-4">
                                            <label htmlFor="phone" className="step-fields-title">
                                                <b>Posting Date</b>
                                                <span className="clr_red">*</span>
                                            </label>
                                            <div className="mt-2">
                                                <Calendar
                                                    id="posting_date"
                                                    name="posting_date"
                                                    value={new Date(formik.values.posting_date)}
                                                    placeholder="mm/dd/yyyy"
                                                    mask="99/99/9999"
                                                    minDate={new Date(minPostingDate)}
                                                    showIcon
                                                    onChange={(e) => inputHandler(e)}
                                                    disabled={!formik.values.check_deposit_date && !formik.values.check_date}
                                                    monthNavigator
                                                    yearNavigator
                                                    yearRange="1945:2050"
                                                />
                                            </div>
                                            {getFormErrorMessage("posting_date")}
                                        </div>

                                        {wireFeeSelection && (
                                            <>
                                                <CustomInputField
                                                    className="col-12 md:col-4"
                                                    iden="wire_fee"
                                                    label="Wire Fee"
                                                    disabled={true}
                                                    mandatory="*"
                                                    maxLength="11"
                                                    // onValueChange={(event) => inputHandler(event, "wire_fee")}
                                                    type="currency"
                                                    formik={formik}
                                                    placeHolder="Enter Wire Fee"
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}

                                <CustomInputField
                                    className="col-12 md:col-4"
                                    iden="amount"
                                    name="amount"
                                    label="Amount"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "amount")}
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={amountCheck}
                                />
                                <CustomInputField
                                    className="col-12 md:col-4"
                                    iden="paid_amount"
                                    label="Payable Amount"
                                    mandatory="*"
                                    disabled={true}
                                    maxLength="11"
                                    type="currency"
                                    formik={formik}
                                    placeHolder="Enter Payable Amount"
                                />
                                <CustomInputField
                                    className="col-12 md:col-4"
                                    iden="requested_amount"
                                    label="Requested Amount"
                                    disabled={true}
                                    mandatory="*"
                                    maxLength="11"
                                    formik={formik}
                                    placeHolder="Enter Requested Amount"
                                    type="currency"
                                />

                                {/* 
                                <CustomInputField
                                    className="col-12 md:col-4"
                                    onChange={(e) => handleInputChange(e)}
                                    iden="amount"
                                    name="amount"
                                    label="Amount"
                                    mandatory="*"
                                    maxLength="11"
                                    min={0}
                                    keyfilter={/^\d*\.?\d*$/}
                                    formik={formik}
                                    placeHolder="Enter Amount"
                                /> */}
                            </div>
                            <div className="grid mt-3">
                                <div className="col-12 text-right">
                                    <CustomCancelbtn id="cancel" title="Cancel" type="button" onClick={cancelHandlder} />
                                    <CustomAddNewButton title="Save" isDisabled={isSaving} className="ml-2" type="submit" isLoading={isLoading} />
                                </div>
                            </div>
                        </form>
                    </div>
                    {schedule.unverified_invoices && schedule.unverified_invoices.length > 0 && (
                        <div className="grid">
                            <h5 class="mt-3">
                                <b>Fund Unverified Invoice(s)</b>
                            </h5>
                            <div className="col-12 pt-3">
                                <DataTable
                                    ref={dt}
                                    value={schedule.unverified_invoices}
                                    rows={5}
                                    responsiveLayout="scroll"
                                    onSelectionChange={handleSelectionChange}
                                    selection={selectedInvoices.length === 0 ? null : selectedInvoices}
                                    emptyMessage="No record available."
                                >
                                    {!schedule.isFunded && <Column selectionMode="multiple"></Column>}
                                    <Column field="invoice_no" header="Invoice No"></Column>
                                    <Column field="debtor_name" header="Debtor Name"></Column>
                                    <Column field="invoice_amount" header="Invoice Amount"></Column>
                                    <Column field="advance_amount" header="Advance Amount"></Column>
                                    <Column field="pending_amount" header="Pending Amount"></Column>
                                </DataTable>
                            </div>
                        </div>
                    )}

                    {schedule.chargebacks && schedule.chargebacks.length > 0 && (
                        <div className="grid">
                            <h5 class="mt-3">
                                <b>Chargeback (s)</b>
                            </h5>
                            <div className="col-12 pt-3">
                                <DataTable ref={dt} value={schedule.chargebacks} rows={5} responsiveLayout="scroll" emptyMessage="No record available.">
                                    <Column field="invoice_no" header="Invoice No"></Column>
                                    <Column field="debtor.legal_name" header="Debtor Name"></Column>
                                    <Column field="invoice_amount" header="Invoice Amount"></Column>
                                    <Column field="advance_amount" header="Advance Amount"></Column>
                                    <Column field="actual_cb" header="Adjusted CB"></Column>
                                </DataTable>
                            </div>
                        </div>
                    )}

                    {(isFormDirty || isVisible) && (
                        <CustomConfirmDialog
                            header="Unsaved Data"
                            type="error"
                            onHide={() => setIsFormDirty(false)}
                            handler={confirmHandler}
                            icon={<BsCheckLg />}
                            title={"Confirm"}
                            firsttext="Are you sure, you want to close the form without saving the changes?"
                        />
                    )}
                </>
            )}
        </>
    );
};

export default DeleteScheduleModal;
