import React, { useContext, useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import "./terminationcomponent.scss";
import NoneFinancedInvoices from "./NoneFinancedInvoices";
import NoneFinancedSchedules from "./NoneFinancedSchedules";
import PendingCollection from "./PendingCollection";
import ReservesTermination from "./ReservesTermination";
import HoldAccountsTermination from "./HoldAccountsTermination";
import { ToastContext } from "../../../../../context/toast";
import { getClientAllInvoicesInformation } from "../../../../../services/client";
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import CustomModalSpinner from "../../../../../ui/CustomModalSpinner/CustomModalSpinner";

const TerminationIndex = (props) => {
    //props
    const { data } = props;

    const [activeStep, setActiveStep] = useState(1); // Initialize the active step to 1
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    const [stepNames, setStepNames] = useState([
        { value: 1, label: "Non-Financed Invoices" },
        { value: 2, label: "Non-Financed Schedules" },
        { value: 3, label: "Pending Collection" },
        { value: 4, label: "Escrow Reserves" },
        { value: 5, label: "Unapplied Cash" },
    ]); // Initialize the active step to 1

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const fetchClientInvoices = async () => {
        let payload = {
            prospect_id: data.id,
            step: activeStep,
        };
        try {
            const res = await getClientAllInvoicesInformation(payload);
            if (res.data.status) {
                setResponse(res.data.details);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    const renderStepComponent = (step) => {
        switch (step) {
            case 1:
                return <NoneFinancedInvoices response={response} />;
            case 2:
                return <NoneFinancedSchedules response={response} />;
            case 3:
                return <PendingCollection response={response} />;
            case 4:
                return <ReservesTermination response={response} />;
            case 5:
                return <HoldAccountsTermination response={response} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchClientInvoices();
    }, [activeStep]);

    return (
        <>
            {loading ? (
                <CustomModalSpinner/>
            ) : (
                <>
                    <div className="termination_steps pt-4">
                        {stepNames.map((step, index) => (
                            <>
                                <div className="flex flex-column align-items-center" key={index}>
                                    <div key={index} className={`circle_steps ${activeStep === step.value ? "active" : "inactive"}`} onClick={() => handleStepClick(step.value)}>
                                        <div>{step.value}</div>
                                    </div>
                                    <div className="pt-2 pb-2">
                                        <b>{step.label}</b>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>

                    <div className="step_content">{renderStepComponent(activeStep)}</div>
                </>
            )}
        </>
    );
};

export default TerminationIndex;
