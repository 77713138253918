import React, { useRef } from "react";

//components
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { BsThreeDotsVertical } from "react-icons/bs";

const KebabMenuComponent = (props) => {
    const { items, handleMenuOpen, isDisabled = false, selected } = props;
    const menu = useRef(null);

    const renderMenuItem = (item, index) => {
        const isLastItem = index === items.length - 1;
        const menuClass = isLastItem ? "last_kebab_menu_item" : "kebab_menu_item";  
        const disableItem =  (isDisabled || selected.length === 0) && item.status > 1 ? "disable_kebab_menu_border_single" : "";
        return (
            <div className={`pt-3 ${menuClass} ${disableItem} cursor-pointer`} onClick={(event) => handleChange(item.status, event)}>
                <p className="pl-3 pb-3 kebab_menu_item_font">{item.title}</p>
            </div>
        );
    };
    const handleChange = (val, event) => {
        handleMenuOpen(val);
        menu.current.toggle(event);
    };
    const menuItems = items.map((item, index) => {
        return {
            label: item.title,
            template: () => renderMenuItem(item, index),
        };
    });
    return (
        <div className="kebab_menu">
            <Menu model={menuItems} popup ref={menu} id="popup_menu" className="kebab_menu_border" />
            <Button icon={<BsThreeDotsVertical />} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup></Button>
        </div>
    );
};

export default KebabMenuComponent;
