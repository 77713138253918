import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function fetchOwners(data) {
    return postData(appUrl["term_sheet"].owner, data);
}

export async function saveTermSheet(data) {
    return postData(appUrl["term_sheet"].save, data);
}

export async function getTermsheet(data) {
    return postData(appUrl["term_sheet"].detail, data);
}

export async function getWireFee(data) {
    return postData(appUrl["term_sheet"].fetchWire, data);
}

export async function saveTermSheetSigned(data) {
    return postData(appUrl["term_sheet"].signedTermSheet, data);
}

export async function checkTermSheetSigned(data) {
    return postData(appUrl["term_sheet"].signedTermSheetStatus, data);
}

export async function termSheetDetails(data) {
    return postData(appUrl["term_sheet"].signedTermSheetDetail, data);
}

