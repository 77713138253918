import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";
import { ToastContext } from "../../../../../context/toast";

//Components
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputTextArea from "../../../../../ui/CustomInputTextArea/CustomInputTextArea";

// API'S
import { editNote } from "../../../../../services/prospect";
import { onProspectDataMutated } from "../../../../../redux/main-view";

let validationSchema = Yup.object().shape({
    note: Yup.string().required("Note is required"),
});

const NoteEdit = (props) => {
    const { onHide, editNotes } = props;

    // Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // States
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            note: "",
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            try {
                let payload = {
                    id: editNotes.id,
                    note: data.note,
                };
                const res = await editNote(payload);
                if (res) {
                    if (res.data.status) {
                        onHide();
                        toast.updateToast("Note");
                        dispatch(onProspectDataMutated());
                    } else {
                        toast.showMessage("Note Edit Failed", "Sorry, we are unable to process your request at this time.", "error");
                        setIsSaving(false);
                    }
                } else {
                    toast.showMessage("Note Edit Failed", "Sorry, we are unable to process your request at this time.", "error");
                    setIsSaving(false);
                }
            } catch (e) {
                toast.showMessage("Note Edit Failed", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
            }
            setIsSaving(false);
        },
    });

    useEffect(() => {
        formik.setFieldValue("note", editNotes.note);
    }, []);

    return (
        <>
            <form>
                <div className="grid">
                    <CustomInputTextArea
                        className="col-12 md:col-12"
                        iden="note"
                        label="Note"
                        formik={formik}
                        maxLength={250}
                        mandatory="*"
                        rows="6"
                        placeHolder="Edit Notes"
                        onChange={(e) => {
                            formik.handleChange(e);
                            setIsFormDirty(true);
                        }}
                    />
                    <div className="col-12 text-right pt-5">
                        <CustomAddNewButton className={isSaving ? "no-drop" : ""} title="Update" type="submit" isDisabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default NoteEdit;
