import React, { useContext, useEffect, useState } from "react";
import TerminationIndex from "./TerminationComponent";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import { ToastContext } from "../../../../context/toast";
import { getClientAllInformation } from "../../../../services/client";
import { currencyFormat } from "../../../../utils/HelperFuctions/checkAmount";
import { getDateFormat } from "../../../../utils/HelperFuctions/dateFormat";
import CustomModalSpinner from "../../../../ui/CustomModalSpinner/CustomModalSpinner";

const TerminateModalDetail = (props) => {
    const { date, onHide } = props;
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    const fetchAllInformation = async () => {
        let payload = {
            prospect_id: date.id,
        };
        try {
            const res = await getClientAllInformation(payload);
            if (res.data.status) {
                setResponse(res.data.details);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(true);
    };

    useEffect(() => {
        fetchAllInformation();
    }, []);
    return (
        <>
            <div className="grid terminate_detail">
                {loading && (
                    <>
                        <div className="col-12">
                            <div>
                                <p className="text-center pb-4 pt-1">Client termination require following steps prior to be completed before termination date.</p>
                            </div>
                        </div>
                        <div className="col-12 md:col-6">
                            <p className="p_tag">
                                Associated Debtor(s) Count : <span>{response.total_debtors > 9 || response.total_debtors === 0 ? response.total_debtors : "0" + response.total_debtors}</span>
                            </p>
                            <p className="p_tag">
                                Total Cash Reserves : <span>${response.total_reserves}</span>
                            </p>
                            <p className="p_tag">
                                Expected Termination Date : <span>{getDateFormat(date.terminated_at)}</span>
                            </p>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="align_text">
                                <p className="p_tag">
                                    Total Collection Amount Pending : <span>${response.total_collection_amount_pending}</span>
                                </p>
                                <p className="p_tag">
                                    Unapplied Cash : <span>{response.total_hold_accounts !== null ? currencyFormat(response.total_hold_accounts) : "$0"}</span>
                                </p>
                                <p className="p_tag"></p>
                            </div>
                        </div>
                    </>
                )}

                <div className="col-12">
                    <TerminationIndex data={date} />
                </div>

                <div className="col-12 text-right pt-3">
                    <CustomCancelbtn title="Close" onClick={() => onHide(false)} />
                </div>
            </div>
        </>
    );
};

export default TerminateModalDetail;
