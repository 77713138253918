import React, { useEffect, useState } from "react";

// Prime React
import { BreadCrumb } from "primereact/breadcrumb";

// UI Components
import ClientNOA from "../ClientsComponent/NOA";
import ClientProfile from "../ClientsComponent/Profile";
import ClientDocuments from "../ClientsComponent/Documents";
// Styles
import "../clientcomponent.scss";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";
import { CLIENT_DETAILS_HEADER_ITEMS } from "../../../../../utils/Constants/global";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { useHistory } from "react-router-dom";
import { MANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";
import UsersIndex from "../ClientsComponent/Users";

const Headercomponent = () => {
    // Redux
    const { user } = useSelector(loginState);
    // State
    const [activeCard, setActiveCard] = useState(-1);
    const [debtorIds, setDebtorIds] = useState([]);
    const [ownerIds, setOwnerIds] = useState([]);
    const [editedClientId, setEditedClientId] = useState([]);

    // Hooks
    const history = useHistory();

    const renderCardComponent = () => {
        if (activeCard === 0) {
            return <ClientProfile setDebtorIds={setDebtorIds} debtorIds={debtorIds} setOwnerIds={setOwnerIds} setEditedClientId={setEditedClientId} />;
        } else if (activeCard === 1) {
            return <ClientDocuments />;
        } else if (activeCard === 2) {
            return <ClientNOA />;
        } else if (activeCard === 3) {
            return <UsersIndex setDebtorIds={setDebtorIds} debtorIds={debtorIds} ownerIds={ownerIds} id={editedClientId} />;
        }
        return <></>;
    };
    // Bredcrumbs
    const items = [{ label: "Clients", url: "/clientindex" }, { label: `Edit Client` }];
    const home = { icon: "bi bi-people" };

    const onLandingScreenHandler = () => {
        if (!user) return;

        if (!validateUserPermissions([MANAGMENT_RIGHTS.clients.selection], user?.rights)) {
            history.goBack();
            return;
        }

        const required_rights = CLIENT_DETAILS_HEADER_ITEMS.map((header) => header.required_rights).flat();
        const index = required_rights.findIndex((r_right) => user?.rights.includes(r_right));

        if (index === 4) {
            history.goBack();
            return;
        }

        setActiveCard(index);
    };

    // Use Effect
    useEffect(() => {
        onLandingScreenHandler();
    }, [user]);

    return (
        <>
            <div>
                <div className="">
                    <BreadCrumb model={items} home={home} />
                </div>
            </div>
            <div className="pros_main_header">
                <div className="bg_header dp_flex">
                    {CLIENT_DETAILS_HEADER_ITEMS.map((header, index, arr) => {
                        return validateUserPermissions(header.required_rights, user?.rights ?? []) ? (
                            <div onClick={() => setActiveCard(index)} className={`text-center ${activeCard === index ? "text_clr_class_active" : "None_text_clr_class_active"}`}>
                                <div className="flex flex-row align-items-center justify-content-center cursor-pointer">
                                    {header.icon}
                                    <h6>{header.title}</h6>
                                </div>
                            </div>
                        ) : (
                            <></>
                        );
                    })}
                </div>
            </div>
            <div className="col-12">{renderCardComponent()}</div>
        </>
    );
};

export default Headercomponent;
