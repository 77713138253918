import React, { useContext, useEffect, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Prime React
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onFormDirty, onProspectCountryCodes, onProspectDataChange, onProspectFormDirty, onProspectKnobValue } from "../../../redux/main-view";
// Context
import { ToastContext } from "../../../context/toast";
// UI Compoments
import CustomTextField from "../../CustomTextField/CustomTextField";
import CustomCancelbtn from "../../CustomCancelbtn/CustomCancelbtn";
import ZipCodesMenuComponent from "../../ZipCodesMenu/ZipCodesMenuComponent";
import CustomAddNewButton from "../../CustomAddNewButton/CustomAddNewButton";
import CustomDropdownField from "../../CustomDropdownField/CustomDropdownField";
import CustomInputTextArea from "../../CustomInputTextArea/CustomInputTextArea";
// Constants
import { LEASING_BUSINESS_SPACE } from "../../../utils/Constants/global";
// Helper Function
import { AmountCheck } from "../../../utils/HelperFuctions/checkAmount";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";
import { countryCodeSearchHandler } from "../../../utils/HelperFuctions/phoneCodeSearch";
// API'S
import { getZipCode } from "../../../services/zip-code";
import { getCountryCode } from "../../../services/country-code";
import { saveProspect } from "../../../services/prospect";
import CustomInputField from "../../CustomInputField/CustomInputField";
import { Button } from "primereact/button";

const validationSchema = Yup.object().shape({
    leasing_business_space: Yup.string().required("Currently Leasing Office is required"),
    landlord_phone: Yup.string().required("Phone No is required"),
    landlord_zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
    landlord_state: Yup.string().required("State is required"),
    landlord_city: Yup.string().required("City is required"),
});

export const LandlordInformation = (props) => {
    const { sendNextStep, frontline_color } = props;

    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // State
    const [zipCode, setZipCode] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
    });

    // View State
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isZipCodeSearch, setIsZipSearch] = useState(true);

    const formik = useFormik({
        validationSchema: isDisabled ? "" : validationSchema,
        initialValues: {
            leasing_business_space: "",
            landlord_phone: "",
            landlord_zip: "",
            landlord_state: "",
            landlord_city: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                data = {
                    ...data,
                    step: "5",
                    id: screens.prospect.data.id,
                    landlord_country_phone_code: isDisabled ? "+1" : data.landlord_country_phone_code ? data.landlord_country_phone_code.phone_code : "+1",
                };
                if (screens.prospect.data?.leasing_business_space) {
                    data["id"] = screens.prospect.data.id;
                }
                if (screens.prospect.isFormDirty) {
                    sendNextStep(6);
                    dispatch(onProspectFormDirty(false));
                } else {
                    let res = await saveProspect(data);
                    if (res) {
                        if (res.data.status) {
                            sendNextStep(6);
                            dispatch(onFormDirty(false));
                            const isEditing = JSON.parse(localStorage.getItem("isEditing"));
                            if (isEditing) {
                                dispatch(onProspectKnobValue(100));
                            } else {
                                dispatch(onProspectKnobValue(85));
                            }
                            dispatch(onProspectDataChange(res.data.prospect));
                        } else {
                            toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                        }
                    } else {
                        toast.showMessage("Error", "Server Error", "error");
                        setIsSaving(false);
                    }
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
                setIsSaving(false);
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Handlers
    const inputHandler = (e) => {
        dispatch(onFormDirty(true));
        dispatch(onProspectFormDirty(false));
        const { name, value } = e.target;
        let cleanValue = null;
        if (name === "leasing_business_space" || name === "landlord_country_phone_code") {
        } else {
            cleanValue = textExtraSpaceRemoval(value);
        }

        if (name === "leasing_business_space" || name === "landlord_country_phone_code") {
            if (value === 0) {
                setIsDisabled(true);
                formik.setFieldValue("present_lease_period", "");
                formik.setFieldValue("landlord_name", "");
                formik.setFieldValue("landlord_monthly_rent", "");
                formik.setFieldValue("landlord_country_phone_code", "");
                formik.setFieldValue("landlord_phone", "");
                formik.setFieldValue("landlord_address", "");
                formik.setFieldValue("landlord_zip", "");
                formik.setFieldValue("landlord_state", "");
                formik.setFieldValue("landlord_city", "");
                formik.setFieldValue("leasing_business_space", value);
                setPhoneCodesPlaceholders({
                    phone_code: "+1",
                });
            } else {
                setIsDisabled(false);
                formik.setFieldValue(name, value);
            }
        } else if (name === "landlord_name") {
            formik.setFieldValue([name], toTitleCaseWhileTyping(value));
        } else if (name === "landlord_monthly_rent") {
            formik.setFieldValue([name], AmountCheck(cleanValue));
        } else {
            formik.setFieldValue(name, cleanValue);
        }
    };

    const handleCurrencyChange = (event, name) => {
        dispatch(onFormDirty(true));
        dispatch(onProspectFormDirty(false));
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRcordNotFoundMsg("");
        formik.values.landlord_zip = zip.zip;
        formik.values.landlord_state = zip.full_state;
        formik.values.landlord_city = zip.city;
        formik.setFieldError("landlord_state", "");
        formik.setFieldError("landlord_city", "");
        formik.setFieldError("zip", "");
        setZipCode([]);
    };

    const handleSaveAndNext = () => {
        dispatch(onProspectKnobValue(85));
        sendNextStep(6);
    };
    const handlePrevious = () => {
        sendNextStep(4);
    };

    // API'S
    const fetchZipCodeData = (value) => {
        const payload = {
            zip: value,
        };
        getZipCode(payload).then((res) => {
            setZipCode(res.data.codes);
            if (res.data.codes.length == 0) {
                setZipCode([]);
                setRcordNotFoundMsg("No Record Available");
            }
        });
    };
    const fetchCountryCodes = async () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                setCountryCode(res.data.countries);
                dispatch(onProspectCountryCodes(res.data.countries));
            });
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // UseEffect
    useEffect(() => {
        if (formik.values.landlord_zip && formik.values.landlord_zip?.length >= 4 && isZipCodeSearch) {
            fetchZipCodeData(formik.values.landlord_zip);
        } else if (formik.values.landlord_zip?.length < 4) {
            setZipCode([]);
            setIsZipSearch(true);
            formik.values.landlord_state = "";
            formik.values.landlord_city = "";
        }
    }, [formik.values.landlord_zip]);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCode(screens.prospect.countryCodes);
        } else {
            fetchCountryCodes();
        }
        if (screens.prospect.data) {
            if (screens.prospect.data?.leasing_business_space !== null) {
                dispatch(onProspectFormDirty(true));
                setIsZipSearch(false);
                const result = screens.prospect.data;
                if (!result?.leasing_business_space) {
                    setIsDisabled(true);
                }
                formik.setFieldValue("leasing_business_space", result?.leasing_business_space ? 1 : 0);
                formik.setFieldValue("present_lease_period", result?.present_lease_period ?? "");
                formik.setFieldValue("landlord_name", result?.landlord_name ?? "");
                formik.setFieldValue("landlord_monthly_rent", result?.landlord_monthly_rent ?? "");
                formik.setFieldValue("landlord_country_phone_code", result?.landlord_country_phone_code ?? "");
                formik.setFieldValue("landlord_phone", result?.landlord_phone ?? "");
                formik.setFieldValue("landlord_address", result?.landlord_address ?? "");
                formik.setFieldValue("landlord_zip", result?.landlord_zip ?? "");
                formik.setFieldValue("landlord_state", result?.landlord_state ?? "");
                formik.setFieldValue("landlord_city", result?.landlord_city ?? "");
                setPhoneCodesPlaceholders({
                    phone_code: result?.landlord_country_phone_code,
                });
            }
        }
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="step-form-top-padding" autoComplete="off">
                <div className={frontline_color ? "frontLine_scroll" : "pros_scrollbar"}>
                    <div className="grid">
                        <CustomDropdownField
                            className="col-12 md:col-3"
                            mandatory="*"
                            iden="leasing_business_space"
                            label="Currently Leasing Office?"
                            formik={formik}
                            options={LEASING_BUSINESS_SPACE}
                            optionLabel="name"
                            optionValue="value"
                            placeHolder="Select"
                            onChange={(e) => inputHandler(e)}
                            autoComplete="none"
                        />
                        <CustomTextField
                            className="col-12 md:col-3"
                            iden="present_lease_period"
                            label="Lease Period"
                            maxLength={35}
                            disabled={isDisabled}
                            formik={formik}
                            placeHolder="Enter Lease Period"
                            onChange={(e) => inputHandler(e)}
                            keyfilter={/^[a-zA-Z0-9\s]+$/}
                            autoComplete="none"
                        />
                        <CustomTextField
                            className="col-12 md:col-3"
                            iden="landlord_name"
                            label="Landlord Name"
                            maxLength={40}
                            disabled={isDisabled}
                            formik={formik}
                            placeHolder="Enter Landlord Name"
                            onChange={(e) => inputHandler(e)}
                            keyfilter={/^[a-zA-Z\s]+$/}
                            autoComplete="none"
                        />
                        <CustomInputField
                            className="col-12 md:col-3"
                            iden="landlord_monthly_rent"
                            label="Monthly Rent"
                            formik={formik}
                            type="currency"
                            placeHolder="$0.00"
                            onPaste={(e) => e.preventDefault()}
                            onValueChange={(e) => handleCurrencyChange(e, "landlord_monthly_rent")}
                            min={0}
                            disabled={isDisabled}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            max={99999999.99}
                            autoComplete="none"
                        />
                        <div className="md:col-3 col-12">
                            <label htmlFor="phone" className="step-fields-title">
                                <b>
                                    Phone No <span className="clr_red">*</span>
                                </b>
                            </label>
                            <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                <Dropdown
                                    id="landlord_country_phone_code"
                                    name="landlord_country_phone_code"
                                    value={formik.values.landlord_country_phone_code}
                                    onChange={(e) => inputHandler(e)}
                                    className="proscustom_width_country"
                                    options={countryCode}
                                    optionLabel="phone_code"
                                    placeholder={phoneCodesPlaceholders.phone_code}
                                    filter
                                    filterBy="phone_code"
                                    emptyMessage="No results found"
                                    disabled={isDisabled || loading}
                                />

                                <InputMask
                                    name="landlord_phone"
                                    id="landlord_phone"
                                    mask="999-999-9999"
                                    value={formik.values.landlord_phone}
                                    disabled={isDisabled}
                                    placeholder="999-999-9999"
                                    onChange={(e) => inputHandler(e)}
                                    className="proscustom_width_phone"
                                    autoComplete="none"
                                ></InputMask>
                            </div>
                            {getFormErrorMessage("landlord_phone")}
                        </div>

                        <div className="col-12 md:col-3">
                            <div className="zip_menu_position">
                                <CustomTextField
                                    className=""
                                    iden="landlord_zip"
                                    label="Zip Code"
                                    disabled={isDisabled}
                                    mandatory="*"
                                    keyfilter="int"
                                    formik={formik}
                                    placeHolder="Search..."
                                    maxLength={5}
                                    onChange={(e) => inputHandler(e)}
                                    onPaste={(e) => e.preventDefault()}
                                    autoComplete="none"
                                />
                                <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                <small className="p-error">{recordNotFoundMsg}</small>
                            </div>
                        </div>
                        <CustomTextField disabled mandatory="*" className="col-12 md:col-3" iden="landlord_state" label="State" formik={formik} placeHolder="" />
                        <CustomTextField disabled mandatory="*" className="col-12 md:col-3" iden="landlord_city" label="City" formik={formik} placeHolder="" />
                        <CustomInputTextArea
                            className="col-12 md:col-9"
                            iden="landlord_address"
                            label="Landlord Address"
                            disabled={isDisabled}
                            maxLength={150}
                            formik={formik}
                            rows="1"
                            placeHolder="Enter Landlord Address"
                            onChange={(e) => inputHandler(e)}
                            autoComplete="none"
                        />
                    </div>
                </div>
                {/* Buttons */}

                <div className="action-btns-prospect-form pr-3">
                    <CustomCancelbtn title="Previous" onClick={handlePrevious} />
                    {frontline_color ? (
                        <Button label="Save and Next" className="fronLineSavebtn" type="submit" disabled={isSaving} />
                    ) : (
                        <CustomAddNewButton title="Save and Next" type="submit" isDisabled={isSaving} />
                    )}
                    {/* <Button label="Save and Next" type="submit" className="btn btn-default savebtn" onClick={handleSaveAndNext} /> */}
                </div>
            </form>
        </>
    );
};
