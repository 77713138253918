import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function fetchGeneralLedger(data = null) {
    return postData(appUrl["general-ledger"].fetch, data);
}

export async function downloadReport(data = null) {
    return postData(appUrl["general-ledger"].download, data);
}
