import appUrl from "../../utils/Constants/appUrl";
import { getData, postData } from "../genaric/genaric-api";

export async function createSupport(data) {
    return postData(appUrl["support"].create, data);
}

export async function changeStatus(data) {
    return postData(appUrl["support"].changeStatus, data);
}
export async function assignTicket(data) {
    return postData(appUrl["support"].assignTicket, data);
}

export async function saveResponse(data) {
    return postData(appUrl["support"].saveResponse, data);
}

export async function getAllDropdown(data) {
    return postData(appUrl["support"].getDropdownValues, data);
}

export async function getAllTickets(data) {
    return postData(appUrl["support"].getTickets, data);
}

export async function getTicketById(data) {
    return postData(appUrl["support"].getTicketById, data);
}

export async function getSupportType() {
    return getData(appUrl["support"].supportType);
}
export async function getSupportModule() {
    return getData(appUrl["support"].supportModule);
}

export async function getSupportPriority() {
    return getData(appUrl["support"].supportPriority);
}

export async function getSupportStatus() {
    return getData(appUrl["support"].supportStatus);
}

export async function getAssignUser(data) {
    return postData(appUrl["support"].getAssignToUser, data);
}
