import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import FactorFlow from "../../Images/LoginPageLogo.png";
import * as Yup from "yup";
import "./Login.css";
import { sendEmail } from "../../services/user-management/user";
import { useFormik } from "formik";
import CustomInputField from "../../ui/CustomInputField/CustomInputField";
import { ToastContext } from "../../context/toast";
import { Link } from "react-router-dom";

const ForgetPass = () => {
    const toastContext = useContext(ToastContext);
    const [loading, setLoading] = useState(false);

    let history = useHistory();
    // Formik validation
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is required"), //.email("Invalid Email")
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
        },
        onSubmit: async (data) => {
            try {
                setLoading(true);
                let dto = {
                    email: data.email,
                };

                localStorage.setItem("email", dto.email);
                let resp = await sendEmail(dto);
                if (resp.data.status) {
                    toastContext.showMessage("Password Reset", "Reset password verification code has been sent to your email.", "success");
                    formik.resetForm();
                    history.push("/verificatiocode");
                } else {
                    toastContext.showMessage("Password Reset", "Reset password verification code has been sent to your email.", "success");
                    // toastContext.showMessage("Password Reset", resp.data.message, "error");
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        },
    });

    return (
        <div className="background_image">
            <div class="grid">
                <div className="lg:col-7 md:col-6"></div>
                <div className="lg:col-5 md:col-6 col-12">
                    {/* Logo Text Section */}

                    <div className="custom_margin_top">
                        <div className="res_img">
                            <img src={FactorFlow} alt="Factor Flow Logo" />
                        </div>
                        <div className="pt-2">
                            <h4>One tool for your whole team needs!</h4>
                        </div>
                    </div>

                    {/* Container Section */}

                    <div className="container">
                        <p className="sign_in_text">Forgot Password?</p>
                        <p className="sign_text">Enter your email address to reset password!</p>

                        <div className="pt-3">
                            {/* input section  */}

                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-3">
                                    <CustomInputField iden="email" label="Email" formik={formik} placeHolder="Enter Email" />
                                </div>
                                {/* button section */}
                                <div className="flex flex-row justify-content-end mt-5">
                                    <button className="btn  sign_in_btn" type="submit" name="signinbtn" id="signinbtn" disabled={loading} style={{ cursor: loading ? "not-allowed" : "pointer" }}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                            {/* Register section */}

                            <div className="pt-3 text-center">
                                <p className="client_text">{/* Not yet a client? */}</p>
                                <button
                                    className="register_btn"
                                    onClick={(e) => {
                                        history.push("/login");
                                    }}
                                    name="backtologin"
                                    id="backtologin"
                                >
                                    Back to login
                                </button>
                            </div>
                        </div>

                        {/* Privacy Policy Section */}

                        <div className="pt-6 text-center">
                            <p className="footer_text">
                                By registering, you accept our
                                <br />
                                <a>Terms of use</a> and{" "}
                                <a>
                                    <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPass;
