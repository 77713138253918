import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { loginState } from "./redux/login";
import { checkLandingScreen, validateSomeUserPermissions, validateUserPermissions } from "./utils/HelperFuctions/validatePermissions";
import CustomLoading from "./ui/CustomSpinner/custom_spinner";

export const ProtectedRoute = ({ required_rights, optional_rights, parent_path, children, ...rest }) => {
    // States
    const [isLoading, setIsLoading] = useState(true);

    // Redux
    const { user } = useSelector(loginState);

    // Hooks
    const history = useHistory();

    const location = useLocation();

    // Constants
    const token = localStorage.getItem("accessToken");

    const onPermissionsValidator = () => {
        let rr_status = false;
        let or_status = false;

        // console.log("required_rights", required_rights);
        // console.log("optional_rights", optional_rights);
        // console.log("parent_path", parent_path);
        // console.log("children", children);

        if (required_rights?.length > 0 && optional_rights?.length > 0) {
            rr_status = validateUserPermissions(required_rights ?? [], user?.rights ?? []);
            or_status = validateSomeUserPermissions(optional_rights ?? [], user?.rights ?? []);
        }
        if (required_rights?.length > 0) {
            rr_status = validateUserPermissions(required_rights ?? [], user?.rights ?? []);
            or_status = true;
        }
        if (optional_rights?.length > 0) {
            rr_status = true;
            or_status = validateSomeUserPermissions(optional_rights ?? [], user?.rights ?? []);
        }

        if (!(rr_status && or_status)) {
            setIsLoading(false);

            if (parent_path !== "") {
                history.replace(parent_path);
            } else {
                history.replace("/home");
            }
        }
    };

    const onLandUserPermittedPage = () => {
        if (user === null || user === undefined || !token) return;

        if (history.location.pathname === "/home" && history.location.pathname !== "/404") {
            const page = checkLandingScreen(user?.rights ?? []);

            history.replace(page);
            setIsLoading(false);
            return;
        } else {
            if (rest.path === history.location.pathname) {
                onPermissionsValidator();
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        onLandUserPermittedPage();

        let param = localStorage.getItem("param");
        if (param) {
            let parts = param.split("-");
            if (parts.length > 0) {
                const clientDetails = parts[0];
                if ("/" + clientDetails !== location.pathname) localStorage.removeItem("param");
            }
        }
    }, [user, history.location.pathname]);

    return <Route {...rest} render={({ location }) => (token ? !isLoading ? children : <CustomLoading /> : <Redirect to="/" />)} />;
    // return <Route {...rest} render={({ location }) => (token ? children : <Redirect to="/" />)} />;
};
