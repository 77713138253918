import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export  async function getChatList(data) {
    return postData(appUrl["chat"].fetch,data);
}

export  async function getConversation(data) {
    return postData(appUrl["chat"].fetchConversation,data);
}

export  async function saveChat(data) {
    return postData(appUrl["chat"].save,data);
}