import React from "react";

export default function NotificationIcon(props) {
    const { color, width = "25", height = "24" } = props;
    return (
        <div>
            <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.4325 2.38727L12.3335 2.36728L12.2345 2.38727L11.0387 2.62877C8.06887 3.22856 5.83353 5.85455 5.83353 9C5.83353 9.90223 5.63666 12.2217 5.15591 14.5105C4.91762 15.6449 4.60196 16.8098 4.18862 17.8089L3.90268 18.5H4.65064H20.0164H20.7643L20.4784 17.8089C20.065 16.8098 19.7494 15.6449 19.5111 14.5105C19.0304 12.2216 18.8335 9.90222 18.8335 9C18.8335 5.85453 16.5982 3.22853 13.6283 2.62876L12.4325 2.38727ZM21.2151 18.2232C21.3521 18.4979 21.5024 18.7603 21.6678 19H2.99921C3.1646 18.7603 3.31488 18.4979 3.45194 18.2232C4.16049 16.8033 4.62712 14.822 4.91777 13.0632C5.20945 11.298 5.33353 9.69475 5.33353 9C5.33353 5.61165 7.74146 2.78459 10.9397 2.13867L11.3826 2.04923L11.3384 1.59963C11.3352 1.56701 11.3335 1.53378 11.3335 1.5C11.3335 0.947715 11.7812 0.5 12.3335 0.5C12.8858 0.5 13.3335 0.947715 13.3335 1.5C13.3335 1.53377 13.3318 1.567 13.3286 1.59963L13.2844 2.04923L13.7273 2.13866C16.9256 2.78456 19.3335 5.61163 19.3335 9C19.3335 9.69474 19.4576 11.298 19.7493 13.0632C20.0399 14.822 20.5065 16.8033 21.2151 18.2232ZM14.7835 21.5C14.5519 22.6411 13.543 23.5 12.3335 23.5C11.124 23.5 10.1151 22.6411 9.8835 21.5H14.7835Z"
                    fill="black"
                    stroke="#050505"
                />
            </svg>
        </div>
    );
}
