import React from "react";
//css
import "./Login.css";
//components
import FactorFlow from "../../Images/LoginPageLogo.png";
import BrokerFormComponent from "../Management/Brokers/Components/BrokerFormComponent";
import { Link } from "react-router-dom";

const BecomePartner = () => {
    return (
        <div className="become_partner background_image">
            <div className="ml-3 pt-4" style={{ width: "fit-content" }}>
                <Link to="/login">
                    <div className="effect_hover">
                        <span className="back_login flex flex-row align-items-center">
                            <i className="text-2xl pi pi-arrow-circle-left mr-2"></i>
                            <h3> Back to Login</h3>
                        </span>
                    </div>
                </Link>
            </div>
            <div class="grid ">
                <div className="lg:col-5 md:col-4 col-12"></div>
                <div className="lg:col-7 md:col-8 col-12">
                    {/* Logo Text Section */}

                    <div className="text-center custom_padding_top">
                        <div className="pt-2">
                            <img src={FactorFlow} alt="Factor Flow Logo" />
                        </div>
                        <div className="pt-2">
                            <h4>Signup here to become a referral partner</h4>
                        </div>
                    </div>

                    {/* Container Section */}

                    <div className="card mt-2">
                        {/* Broker Form */}
                        <BrokerFormComponent showBecomePartnerButton={true} data={[]} />
                        {/* Privacy Policy Section */}
                        <div className="pt-4 text-center">
                            <p className="footer_text">
                                By registering, you accept our
                                <br />
                                <a>Terms of use</a> and{" "}
                                <a>
                                    <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BecomePartner;
