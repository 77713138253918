import React, { useContext, useRef, useState } from "react";
import classNames from "classnames";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ToastContext } from "../../../../../../../context/toast";
import { PaginatorTemplate } from "../../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { getCashReserveColumnBody } from "../../../../../../../utils/HelperFuctions/invoiceHelper";
import CustomLoading from "../../../../../../../ui/CustomSpinner/custom_spinner";
import { Dialog } from "primereact/dialog";
import ReserveReleaseDetail from "../../Components/ReserveReleaseDetail";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { useSelector } from "react-redux";

// Redux
import { loginState } from "../../../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";

const Body = (props) => {
    const {
        selectedReserves,
        setSelectedReserves,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        responseData,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        page,
        setPage,
        loading,
        componentName,
    } = props;

    const dt = useRef(null);
    const toast = useContext(ToastContext);

    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [reserveDetailModal, setReserveDetailModal] = useState(false);
    const [reserveId, setReserveId] = useState("");

    // Redux
    const { user } = useSelector(loginState);

    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedReserves(event.value);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const getRowClassName = (rowData) => {
        if (rowData.deleted) {
            return "delete_row_color";
        } else if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };
    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getCashReserveColumnBody(col.field)}
                sortable
                headerClassName={`${
                    col.field === "reserved_amount" ||
                    col.field === "cash_reserves" ||
                    col.field === "invoice_amount" ||
                    col.field === "wire_fee" ||
                    col.field === "previous_reserves" ||
                    col.field === "reserve_released_amount" ||
                    col.field === "escrow_reserves"
                        ? "amount_column_alignment"
                        : ""
                }`}
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Paginations
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const handleRowSelect = (event) => {
        if (!validateUserPermissions([TRANSACTION_RIGHTS.reserves.reserve_release_selection], user?.rights)) return;

        // Row Selection
        if (event.type === "row" && componentName === "reserve_releases") {
            if (event.data.id) {
                if (!event.data.deleted) {
                    setReserveId(event.data.id);
                    setReserveDetailModal(true);
                }
            } else {
                toast.showMessage("Error", "Admin has been notified", "error");
            }
        }
    };

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 p-0">
                    <div className="card">
                        <DataTable
                            ref={dt}
                            value={responseData}
                            selection={selectedReserves.length === 0 ? null : selectedReserves}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            rowClassName={getRowClassName}
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    </div>

                    <Dialog
                        blockScroll={true}
                        visible={reserveDetailModal}
                        onHide={() => setReserveDetailModal(false)}
                        header="Reserve Release BreakDown"
                        breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                        style={{ width: "65vw" }}
                    >
                        <ReserveReleaseDetail onHide={() => setReserveDetailModal(false)} reserveId={reserveId} />
                    </Dialog>
                </div>
            )}
        </>
    );
};

export default Body;
