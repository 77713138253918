import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//styles
import "./component.scss";
//Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//components
// import { InputText } from "primereact/inputtext";
import CustomInputField from "../../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import { ToastContext } from "../../../../../../context/toast";
import { getDebtorPoc, getDebtors } from "../../../../../../services/debtor";
import { deleteAttachments, getInvoiceClientsDropdown, getInvoiceDetails, invoiceStatusChange, postAttachments, postInvoice } from "../../../../../../services/invoice";
import { getCustomDropDown } from "../../../../../../services/dropdown";
import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";
import { openAttachment } from "../../../../../../utils/HelperFuctions/openAttachment";
import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";
import { Button } from "primereact/button";
import { BsCheck2, BsCheckLg, BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import { AmountCheck } from "../../../../../../utils/HelperFuctions/checkAmount";
import { BreadCrumb } from "primereact/breadcrumb";
import { fileName } from "../../../../../../utils/HelperFuctions/getFileName";
import { previewDocumentHandler } from "../../../../../../utils/HelperFuctions/documnetPreview";
import { validateUserPermissions } from "../../../../../../utils/HelperFuctions/validatePermissions";
import { TRANSACTION_RIGHTS } from "../../../../../../utils/Constants/rights";
import { useDispatch, useSelector } from "react-redux";
import { loginState } from "../../../../../../redux/login";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { FILE_MAX_SIZE, ROLE_NAME } from "../../../../../../utils/Constants/global";
import { mainViewState, onInvoiceFormDirty } from "../../../../../../redux/main-view";

const AddInvoiceComponenet = () => {
    //Edit Invoice
    const location = useLocation();
    let id = null;
    if (location.state !== undefined) id = location.state?.id;

    //use state
    const [dropdownClients, setDropdownClients] = useState([]);
    const [clientLoading, setClientLoading] = useState(false);
    const [debtorLoading, setDebtorLoading] = useState(true);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [debtroContacts, setDebtorContacts] = useState([]);
    const [debtorContactsLoading, setDebtorContactsLoading] = useState(true);
    const [paymentDropdowns, setPaymentDropdowns] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState([]);
    const [dropdownsLoading, setDropdownsLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [openAttachmentLoading, setOpenAttachmentLoading] = useState(false);
    const [clientStatus, setClientStatus] = useState(true);
    const [loading, setLoading] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null);
    const [invoiceStatus, setInvoiceStatus] = useState(null);
    const [invoiceNoDisbale, setInvoiceNoDisable] = useState(false);
    const [editData, setEditData] = useState([]);
    const [clientSearch, setClientSearch] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDebtor, setIsDebtor] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);

    //image states

    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);

    // Redux
    const { user } = useSelector(loginState);

    // bread Crumbs
    const BreakcrumbItems = [{ label: "Invoices", url: "/invoices" }, { label: id ? "Edit Invoice" : "Add New Invoice" }];
    const homeIcon = { icon: "bi bi-arrow-repeat" };

    // use History
    const history = useHistory();

    //toast message
    const toast = useContext(ToastContext);

    // validationForm
    const validationForm = (data) => {
        if (data.invoice_amount) {
            let getDebtor = dropdownDebtors.filter(function (debtor) {
                return debtor.id === parseInt(formik.values.debtor_id);
            });
            if (parseInt(data.invoice_amount) > parseInt(getDebtor[0].available_credit_limit)) {
                toast.showMessage("Warning", "Invoice amount cannot be greater than debtor's available credit limit", "warn");
                return true;
            }
            return false;
        }
    };

    //validation
    const validationSchema = Yup.object().shape({
        prospect_id: Yup.string().required("Client Name is required"),
        debtor_id: Yup.string().required("Debtor Name is required"),
        debtor_contact: Yup.array().min(1, "Debtor Contact is required"),
        invoice_no: Yup.string()
            .required("Invoice No is required")
            .test("greater-than-zero", "Invoice No must be greater than 0", (value) => {
                return parseFloat(value) > 0;
            }),
        invoice_amount: Yup.string().required("Invoice Amount is required"),
        invoice_date: Yup.string().required("Invoice Date is required").nullable(),
        commitment_date: Yup.string().nullable(),
        Tentative_payment_mode: Yup.string().nullable(),
        net_invoice_term: Yup.string().required("Net Invoice Term is required"),
        invoice_due_date: Yup.string().required("Invoice Due Date is required").nullable(),
        advance_percentage: Yup.string().required("Advance Percentage is required"),
        advance_amount: Yup.string().required("Advance Amount is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            prospect_id: "",
            debtor_id: "",
            debtor_contact: [],
            invoice_no: "",
            invoice_amount: "",
            invoice_date: null,
            commitment_date: null,
            Tentative_payment_mode: "",
            net_invoice_term: "",
            invoice_due_date: null,
            advance_amount: "",
            advance_percentage: "",
        },
        onSubmit: async (data) => {
            if (invoiceId != null) {
                data["id"] = invoiceId;
            }
            //data
            data = {
                ...data,
                invoice_date: getDateFormat(data.invoice_date),
                commitment_date: data.commitment_date ? getDateFormat(data.commitment_date) : "",
                invoice_due_date: getDateFormat(data.invoice_due_date),
                debtor_contact: JSON.stringify(data.debtor_contact),
            };

            if (!validationForm(data)) {
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    formData.append(key, data[key]);
                });

                if (uploadedFile !== null) {
                    uploadedFile.map((item, index) => {
                        formData.append("attachments[]", uploadedFile[index]);
                    });
                }
                // Submit Form
                setDisableButton(true);
                setIsLoading(true);

                try {
                    const res = await postInvoice(formData);
                    if (res.data.status == true) {
                        if (data.id) {
                            toast.updateToast("Invoice");
                        } else {
                            toast.createdToast("Invoice");
                        }
                        dispatch(onInvoiceFormDirty(false));
                        history.push("/invoices");
                    } else {
                        toast.showMessage("Error", res.data.message, "error");
                        setDisableButton(false);
                    }
                } catch (e) {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
            }
            setDisableButton(false);
            setIsLoading(false);
        },
    });

    // fetch Clients

    const fetchClients = async (keyword = null) => {
        if (!clientLoading) {
            setClientLoading(true);
            let payload = {
                per_page: 50,
                search_text: keyword,
            };
            try {
                getInvoiceClientsDropdown(payload).then((res) => {
                    if (res.data.status) {
                        if (res.data && res.data.prospects && res.data.prospects.data.length > 0) {
                            setDropdownClients(res.data.prospects.data);
                        }
                    }
                    setClientLoading(false);
                });
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setClientLoading(false);
            }
        }
    };

    // fetch Debtors
    const fetchDebtors = async (keyword = null, debtor_id = null) => {
        setDebtorLoading(true);
        let payload = {
            prospect_id: formik?.values?.prospect_id ? formik?.values?.prospect_id : debtor_id,     //formik.values.prospect_id,
            per_page: 100,
            search_text: keyword,
            status: 0,
        };
        try {
            let res = await getDebtors(payload);
            if (res.data.status) {
                setDropdownDebtors(res.data.debtors.data);
                if (res.data.debtors.data.length > 0) {
                    setDebtorLoading(false);
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // fetch debtors contacts
    const fetchDebtorContacts = async () => {
        setDebtorContactsLoading(true);
        let payload = {
            prospect_id: formik.values.prospect_id,
            debtor_id: formik.values.debtor_id,
        };
        try {
            let res = await getDebtorPoc(payload);
            if (res.data.status) {
                setDebtorContacts(res.data.pocs);
                if (res.data.pocs.length > 0) {
                    setDebtorContactsLoading(false);
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    //DropDowns
    const handleDropdowns = async () => {
        setDropdownsLoading(true);
        let payload = {
            select: ["payment_type", "invoice_status"],
        };
        try {
            getCustomDropDown(payload).then((res) => {
                if (res.data.status) {
                    setPaymentDropdowns(JSON.parse(res.data.dropdowns.payment_type));
                    setStatusDropdown(JSON.parse(res.data.dropdowns.invoice_status));
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDropdownsLoading(false);
    };

    //  Doesn't allowing copy paste data in the fields
    const handlePaste = (event) => {
        const { name } = event.target;
        if (name === "invoice_amount" || name === "invoice_no") {
            event.stopPropagation();
        }
    };

    const handleClientFilter = (event) => {
        setClientSearch(event.filter);
    };

    // Change Status Invoice
    const handleChangeStatus = async (name) => {
        if (!validateUserPermissions([TRANSACTION_RIGHTS.invoices.approval_denial], user?.rights)) return;

        let obj = statusDropdown.filter(function (status) {
            return status.name === name;
        });
        let payload = {
            ids: [parseInt(id)],
            status: obj[0].value,
        };
        try {
            let res = await invoiceStatusChange(payload);
            if (res.data.status) {
                setInvoiceStatus(obj[0].value);
                formik.setFieldValue("status", obj[0].value);
                if (name === "Declined") toast.showMessage("Invoice Denied", "Invoice has been denied successfully.", "success");
                else if (name === "Approved") toast.showMessage("Invoice Approved", "Invoice has been approved successfully.", "success");
                history.push("/invoices");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // File Upload
    const handleApiAttachment = async (event, files) => {
        setDisableButton(true);
        try {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("attachments[]", file);
            });
            let res = await postAttachments(formData);
            if (res.data.status) {
                setUploadedFile((prevUploadedFile) => [...prevUploadedFile, ...res.data.urls]);
            } else {
                toast.showMessage("Error", res.data.message, "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            event.target.value = null;
        }
        setDisableButton(false);
    };
    const handleFileUpload = (event) => {
        dispatch(onInvoiceFormDirty(true));

        const files = event.target.files;
        let upload = [];
        let filesSaved = false;
        if (uploadedFile.length !== 4 && 4 - parseInt(uploadedFile.length) >= parseInt(files.length)) {
            if (files.length <= 4) {
                for (let i = 0; i < files.length; i++) {
                    const fileType = files[i].name.split(".");
                    const index = fileType.length - 1;
                    const accept = ".pdf,.jpeg,.png,.jpg";
                    const check_file = accept.includes(fileType[index]);
                    if (check_file) {
                        const fileSize = files[i].size > FILE_MAX_SIZE.FILE_SIZE;
                        if (fileSize) {
                            toast.showMessage("Exceeding Limit", `${files[i]?.name} size is greater than allowed size.`, "error");
                        } else if (files[i]?.name.includes("#") || files[i]?.name.includes("&")) {
                            toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                        } else {
                            upload.push(files[i]);

                            // const input = document.getElementById("file-upload");
                            // input.value = ""; // Clear the input value
                            // const newInput = input.cloneNode(true);
                            // input.parentNode.replaceChild(newInput, input);
                        }
                    } else {
                        toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                    }
                }
                handleApiAttachment(event, upload);
                event.target.value = null;
                filesSaved = true;
            }
        }
        if (!filesSaved) {
            toast.showMessage("Exceeding Limit", "You are only authorized to upload 4 files.", "error");
            event.target.value = null;
        }
    };

    const removeImage = (index) => {
        uploadedFile.splice(index, 1);
        setUploadedFile(uploadedFile);
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };

    const showImage = (index) => {
        setSelectedImage(images[index]);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    const handleCurrencyChange = (event, name) => {
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                let amounts = (parseFloat(amount) * parseFloat(formik.values.advance_percentage)) / 100;
                let amountToBeFixed = parseFloat(amounts).toFixed(2);
                formik.setFieldValue("advance_amount", amountToBeFixed);
                return;
            } else {
                let amounts = (parseFloat(event.value) * parseFloat(formik.values.advance_percentage)) / 100;
                let amountToBeFixed = parseFloat(amounts).toFixed(2);
                formik.setFieldValue("advance_amount", amountToBeFixed);
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };
    const handleShowAttachment = async (file) => {
        setOpenAttachmentLoading(true);
        try {
            await openAttachment(file);
        } catch (error) {
            console.error("Error opening attachment:", error);
        } finally {
            setOpenAttachmentLoading(false);
        }

        // previewDocumentHandler(file);
    };
    const handleDelete = (file) => {
        setShowConfirmDeleteDialog(true);
        setDeleteAttachment({
            path: file,
        });
    };

    const confirmDeleteHandler = async () => {
        setIsDeleting(true);
        try {
            let resp = await deleteAttachments(deleteAttachment);
            if (resp.data.status) {
                setShowConfirmDeleteDialog(false);
                toast.showMessage("File Deleted", "File has been deleted successfully", "success");

                const newUploadedFile = uploadedFile.filter((item) => item !== deleteAttachment.path);

                setUploadedFile(newUploadedFile);
            } else {
                toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsDeleting(false);
        } catch (error) {
            setIsDeleting(false);
            toast.showMessage("Server Error", "Server error.", "error");
        }
    };

    const handleCancel = () => {
        if (screens.invoice.isFormDirty) {
            setIsFormDirty(true);
        } else {
            dispatch(onInvoiceFormDirty(false));
            history.push("/invoices");
        }

        // if (formik.values.prospect_id !== "" || formik.values.invoice_no !== "" || formik.values.invoice_date !== null) {
        //     setIsFormDirty(true);
        // } else {
        //     history.push("/invoices");
        // }
    };

    const confirmHandler = () => {
        dispatch(onInvoiceFormDirty(false));
        history.push("/invoices");
    };

    const onLandingScreen = () => {
        if (!user) return;

        if (!validateUserPermissions([TRANSACTION_RIGHTS.invoices.invoice_selection], user?.rights)) {
            history.goBack();
        }
    };

    const inputHandler = (e) => {
        dispatch(onInvoiceFormDirty(true));

        const { name, value } = e.target;

        // const cleanValue = textExtraSpaceRemoval(value);
        formik.setFieldValue(name, value);
    };

    //UseEffect
    useEffect(() => {
        if (!user) return;

        const apiCall = async () => {
            setLoading(true);
            setClientStatus(false);
            let payload = {
                id: id,
            };
            try {
                let res = await getInvoiceDetails(payload);
                if (res.data.status) {
                    let data = [];
                    data = res.data.invoices;
                    setEditData(data);
                    formik.setFieldValue("prospect_id", data.prospect_id);
                    formik.setFieldValue("debtor_id", data.debtor_id);
                    formik.setFieldValue("debtor_contact", typeof data.debtor_contact === "string" ? JSON.parse(data.debtor_contact) : data.debtor_contact);
                    formik.setFieldValue("invoice_no", data.invoice_no);
                    formik.setFieldValue("invoice_amount", data.invoice_amount);
                    formik.setFieldValue("invoice_date", new Date(data.invoice_date));
                    formik.setFieldValue("commitment_date", data.commitment_date ? new Date(data.commitment_date) : null);
                    formik.setFieldValue("Tentative_payment_mode", data.Tentative_payment_mode ? data.Tentative_payment_mode : "");
                    formik.setFieldValue("net_invoice_term", data.discount_days);
                    formik.setFieldValue("invoice_due_date", new Date(data.invoice_due_date));
                    formik.setFieldValue("advance_amount", data.advance_amount);
                    formik.setFieldValue("status", data.status);
                    formik.setFieldValue("payment_type", data.payment_type);
                    formik.setFieldValue("advance_percentage", data.advance_percentage);
                    setUploadedFile(data.attachments.map((attachment) => attachment.path));
                    setInvoiceId(data.id);
                    setClientStatus(true);
                    setInvoiceNoDisable(true);
                    setInvoiceStatus(data.status);
                    fetchDebtors(null, data.prospect_id);
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setLoading(false);
            }
        };

        if (id !== null) {
            onLandingScreen();
            apiCall();
        }

        // fetchClients();
        handleDropdowns();
        if (!id) {
            formik.setFieldValue("payment_type", 2);
            formik.setFieldValue("status", 1);
        }
    }, [user]);

    //UseEffect for client selection
    useEffect(() => {
        if (clientStatus == true) {
            if (formik.values.prospect_id != "") {
                const clientItems = dropdownClients.filter(function (client) {
                    return parseInt(client.id) === parseInt(formik.values.prospect_id);
                });
                if (clientItems) {
                    formik.values.advance_percentage = clientItems[0].term.advance_percentage;
                    formik.values.net_invoice_term = clientItems[0].term.discount_days;
                }
                fetchDebtors();
            }
        }
    }, [formik.values.prospect_id]);

    //UseEffect for debtor contacts
    useEffect(() => {
        if (formik.values.debtor_id != "") {
            if (clientStatus == true) {
                formik.values.debtor_contact = [];
            }
            fetchDebtorContacts();
        }
    }, [formik.values.debtor_id]);

    useEffect(() => {
        setIsDebtor(user?.userRoles[0]["roleName"] === ROLE_NAME.DEBTOR);
    }, []);

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            if (dropdownClients.length > 0) {
                setTimeout(() => {
                    formik.setFieldValue("prospect_id", dropdownClients[0].id);
                    // formik.values.prospect_id = dropdownClients[0].id;
                }, 100);
            }
        }
    }, [dropdownClients]);

    //UseEffect for Invoice Due Date
    useEffect(() => {
        if (clientStatus == true) {
            if (formik.values.invoice_date !== null) {
                formik.setFieldValue("commitment_date", null);
                let invoice_date = new Date(formik.values.invoice_date);
                formik.setFieldValue("invoice_due_date", new Date(invoice_date.setDate(invoice_date.getDate() + formik.values.net_invoice_term - 1)));
            } else {
                formik.setFieldValue("commitment_date", null);
                formik.setFieldValue("invoice_date", null);
                formik.setFieldValue("invoice_due_date", null);
            }
        }
    }, [formik.values.invoice_date]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchClients(clientSearch.length === 0 ? null : clientSearch);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [clientSearch]);

    // use Effect for invoice amount
    useEffect(() => {
        if (formik.values.invoice_amount != "") {
            let amount = (parseFloat(formik.values.invoice_amount) * parseFloat(formik.values.advance_percentage)) / 100;
            let amountToBeFixed = parseFloat(amount).toFixed(2);
            formik.values.advance_amount = amountToBeFixed;
        } else {
            formik.values.advance_amount = "";
        }
    }, [formik.values.invoice_amount]);

    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3 pb-3">
                    <BreadCrumb model={BreakcrumbItems} home={homeIcon} />
                </div>
            </div>
            {loading == true ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="card">
                        {id !== null && editData?.is_funding !== 1 && user?.userRoles[0].roleName === ROLE_NAME.DEBTOR && (
                            <>
                                <div className="flex justify-content-end pb-4">
                                    {validateUserPermissions([TRANSACTION_RIGHTS.invoices.approval_denial], user?.rights) && (
                                        <>
                                            <Button
                                                tooltip="Deny"
                                                tooltipOptions={{ position: "top" }}
                                                className={`deny_button ${invoiceStatus === 3 && "deny_button_disabled"}`}
                                                icon={<BsXLg />}
                                                type="button"
                                                onClick={() => handleChangeStatus("Declined")}
                                                disabled={invoiceStatus === 3}
                                            />
                                            <Button
                                                tooltip="Approve"
                                                tooltipOptions={{ position: "top" }}
                                                className={`approve_button ml-2 ${invoiceStatus === 2 && "approve_button_disabled"}`}
                                                icon={<BsCheck2 />}
                                                type="bittpn"
                                                onClick={() => handleChangeStatus("Approved")}
                                                disabled={invoiceStatus === 2}
                                            />
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                {/* Client */}

                                <CustomInputField
                                    className={`col-12 md:col-3 ${clientLoading && "no-drop"}`}
                                    options={dropdownClients}
                                    optionLabel="company_business_name"
                                    optionValue="id"
                                    iden="prospect_id"
                                    label="Client Name"
                                    formik={formik}
                                    type="dropdown"
                                    filter={true}
                                    filterBy="company_business_name"
                                    placeHolder="Select"
                                    mandatory="*"
                                    disabled={clientLoading || editData?.schedule_no || user?.userRoles[0].roleName === ROLE_NAME.CLIENT}
                                    handleFilter={handleClientFilter}
                                    onChange={(e) => inputHandler(e)}
                                />
                                {/* Debtor */}
                                <CustomInputField
                                    className={`col-12 md:col-3 ${debtorLoading && "no-drop"}`}
                                    options={dropdownDebtors}
                                    optionLabel="debtor_name"
                                    optionValue="id"
                                    iden="debtor_id"
                                    label="Debtor Name"
                                    formik={formik}
                                    type="dropdown"
                                    filterBy="debtor_name"
                                    placeHolder="Select"
                                    mandatory="*"
                                    disabled={debtorLoading || editData?.schedule_no}
                                    onChange={(e) => inputHandler(e)}
                                />
                                {/* Debtor Contacts */}
                                <CustomInputField
                                    className={`col-12 md:col-3 ${debtorContactsLoading && "no-drop"}`}
                                    options={debtroContacts}
                                    optionLabel="email"
                                    iden="debtor_contact"
                                    label="Debtor Contacts"
                                    formik={formik}
                                    type="multiSelect"
                                    optionValue="email"
                                    placeHolder="Select"
                                    mandatory="*"
                                    disabled={debtorContactsLoading || editData?.is_funding}
                                    onChange={(e) => inputHandler(e)}
                                    filter
                                />
                                {/* Invoice No */}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="invoice_no"
                                    label="Invoice No"
                                    name="invoice_no"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="Enter Invoice No"
                                    maxLength={6}
                                    keyfilter={/^[0-9\b]+$/}
                                    onPaste={(e) => handlePaste(e)}
                                    disabled={invoiceNoDisbale || editData?.is_funding}
                                    onChange={(e) => inputHandler(e)}
                                />
                                {/* Invoice Amount */}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="invoice_amount"
                                    label="Invoice Amount"
                                    mandatory="*"
                                    formik={formik}
                                    onPaste={(e) => handlePaste(e)}
                                    type="currency"
                                    onValueChange={(e) => handleCurrencyChange(e, "invoice_amount")}
                                    min={0}
                                    disabled={debtorLoading || isDebtor || editData?.is_funding}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                    onChange={(e) => inputHandler(e)}
                                />
                                {/* Invoice Date */}
                                <CustomInputField
                                    className={`col-12 md:col-3 ${formik.values.prospect_id === "" && "no-drop"}`}
                                    iden="invoice_date"
                                    label="Invoice Date"
                                    type="calendar"
                                    mandatory="*"
                                    disabled={isDebtor || editData?.is_funding}
                                    formik={formik}
                                    placeHolder="mm/dd/yyyy"
                                    onChange={(e) => inputHandler(e)}
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                                {/* Commitment Date */}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="commitment_date"
                                    label="Commitment Date"
                                    type="calendar"
                                    formik={formik}
                                    placeHolder="mm/dd/yyyy"
                                    disabled={user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT || editData?.is_funding}
                                    minDate={new Date(formik.values.invoice_date)}
                                    onChange={(e) => inputHandler(e)}
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                                {/* Expected Payment Mode */}
                                <CustomInputField
                                    className={`col-12 md:col-3 ${dropdownsLoading && "no-drop"}`}
                                    options={paymentDropdowns}
                                    optionLabel="name"
                                    optionValue="value"
                                    iden="Tentative_payment_mode"
                                    label="Expected Payment Mode"
                                    formik={formik}
                                    type="dropdown"
                                    placeHolder="Select"
                                    disabled={dropdownsLoading || user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT || editData?.is_funding}
                                    onChange={(e) => inputHandler(e)}
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                                {/* Net Invoice Term */}
                                <CustomInputField className="col-12 md:col-3" iden="net_invoice_term" label="Net Invoice Term" mandatory="*" formik={formik} keyfilter={/^[0-9\b]+$/} disabled={true} />
                                {/* Invoice Due Date */}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="invoice_due_date"
                                    label="Invoice Due Date"
                                    type="calendar"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="mm/dd/yyyy"
                                    disabled={true}
                                    onChange={(e) => inputHandler(e)}
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />

                                {/* Advance percentage */}
                                <CustomInputField
                                    className="col-12 md:col-3"
                                    iden="advance_percentage"
                                    label="Advance Percentage"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="%"
                                    keyfilter={/^[0-9\b]+$/}
                                    maxLength={7}
                                    disabled={true}
                                    onChange={(e) => inputHandler(e)}
                                />
                                {/* Advance amount */}
                                <div className="col-12 md:col-3">
                                    <label>
                                        <b>Advance Amount</b> <span className="clr_red">*</span>
                                    </label>
                                    <div className="input_custom_border">
                                        <span className="pos_dolar">$</span>
                                        <span>{formik.values.advance_amount}</span>
                                    </div>
                                </div>
                                {/* Status */}
                                <CustomInputField
                                    className={`col-12 md:col-3 ${dropdownsLoading && "no-drop"} `}
                                    options={statusDropdown}
                                    optionLabel="name"
                                    optionValue="value"
                                    iden="status"
                                    label="Status"
                                    formik={formik}
                                    type="dropdown"
                                    placeHolder="Select"
                                    disabled={dropdownsLoading || isDebtor || editData?.is_funding}
                                    onChange={(e) => inputHandler(e)}
                                />

                                {/* Payment Type */}
                                <CustomInputField
                                    className={`col-12 md:col-3 ${dropdownsLoading && "no-drop"}`}
                                    options={paymentDropdowns}
                                    optionLabel="name"
                                    optionValue="value"
                                    iden="payment_type"
                                    label="Payment Type"
                                    formik={formik}
                                    type="dropdown"
                                    placeHolder="Select"
                                    disabled={dropdownsLoading || isDebtor || editData?.is_funding}
                                    onChange={(e) => inputHandler(e)}
                                />
                                {/* Attachments */}
                                <div className="col-12 md:col-3">
                                    <label htmlFor="NameTitle">
                                        <b>Attachments</b>
                                        <Button
                                            type="button"
                                            tooltip=".pdf, .jpeg, .png, .jpg files only (max size 3MB)"
                                            tooltipOptions={{ position: "bottom" }}
                                            icon={<BsInfoCircle />}
                                            aria-label="Submit"
                                            className="customTooltipicon"
                                        />
                                    </label>
                                    {/* 
                                    <div className="pt-3">
                                        <input type="file" onChange={onFileSelect} accept=".jpg,.jpeg,.png,.pdf" multiple />
                                    </div> */}{" "}
                                    <div className="file_upload_class">
                                        <label htmlFor="file-upload" className="flex flex-row justify-content-between align-items-center">
                                            Choose File <BsPaperclip />
                                        </label>
                                    </div>
                                    <input
                                        type="file"
                                        id="file-upload"
                                        multiple
                                        onChange={handleFileUpload}
                                        accept=".pdf,.jpeg,.png,.jpg"
                                        style={{ display: "none" }}
                                        disabled={disableButton || isDebtor || editData?.is_funding}
                                    />
                                </div>

                                <div className="md:col-3 col-12 mt-4">
                                    {uploadedFile.length > 0 && (
                                        <div>
                                            {/* <p>Uploaded Image:</p> */}
                                            {uploadedFile.map((file, index) => (
                                                <div className="flex flex row justify-content-around" key={index}>
                                                    <span className={`text_break  ${editData?.is_funding ? "disable_span" : "cursor-pointer"}`} onClick={() => handleShowAttachment(file)}>
                                                        {fileName(file)}
                                                    </span>

                                                    <span className={`text_break ${editData?.is_funding ? "disable_span" : "cursor-pointer"}`} onClick={() => handleDelete(file)}>
                                                        <BsXLg className="bs_Cros_img" />
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {showModal && (
                                    <div className="modal" onClick={closeModal}>
                                        <div className="modal-content">
                                            <span className="close" onClick={closeModal}>
                                                &times;
                                            </span>
                                            <img src={selectedImage.previewUrl} alt="Preview" className="modal-image" />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-row justify-content-end pt-6">
                                <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} />
                                <CustomAddNewButton title={id ? "Update" : "Save"} type="submit" isDisabled={disableButton || editData.is_funding} isLoading={isLoading} />
                            </div>
                        </form>
                    </div>
                </>
            )}

            {openAttachmentLoading && <CustomLoading center />}

            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={(e) => setIsFormDirty(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => confirmDeleteHandler()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}
        </>
    );
};

export default AddInvoiceComponenet;
