import React from "react";
import Index from "../Index";

const Notification = () => {
    return (
        <div>
            <h2>Notifications</h2>
            <div className="pt-4">
                <Index />
            </div>
        </div>
    );
};

export default Notification;
