import React, { useRef, useState } from "react";

// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";

// Helper Function
import { PaginatorTemplate } from "../../../../ui/PaginatorTemplate/PaginatorTemplate";

// Components
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import TicketDetailModal from "../../Components/TicketDetailModal";
import AssignTicketModal from "../../Components/AssignTicketModal";
import ChangeStatusModal from "../../Components/ChangeStatusModal";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { SUPPORT_RIGHTS } from "../../../../utils/Constants/rights";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../../utils/Constants/global";
import { Tooltip } from "primereact/tooltip";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const Body = (props) => {
    const {
        selectedHeaders,
        selectedUsers,
        setSelectedUsers,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        kebabMenuOption,
        setKebabMenuOption,
        setPage,
        page,
        user,
    } = props;

    //Use ref
    const ref = useRef(null);

    // States
    // const [page, setPage] = useState(currentPage);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltipAssign, setShowTooltipAssign] = useState(false);
    const [showTooltipCreator, setShowTooltipCreator] = useState(false);
    const [detaildialogVisible, setDetailDialogVisible] = useState(false);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedUsers(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions([SUPPORT_RIGHTS.ticket_selection], user?.rights)) return;

        // Row Selection
        // supports_ticketselection
        if (event.type === "row") {
            setSelectedRowId(event.data.id);
            setDetailDialogVisible(true);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const supportGetColumnBody = (colField) => (rowData) => {
        const { id, title } = rowData;
        const handleMouseEnter = () => {
            setShowTooltip(true);
        };

        const handleMouseLeave = () => {
            setShowTooltip(false);
        };

        if (colField === "createdBy") {
            const created_by = (rowData.cu_last_name ? rowData.cu_last_name : "") + " " + (rowData.cu_first_name ? rowData.cu_first_name : "");

            if (created_by.length > 24) {
                return (
                    <div className="response_text" onMouseEnter={() => setShowTooltipCreator(true)} onMouseLeave={() => setShowTooltipCreator(false)}>
                        <span id={`name-${id}`}>{created_by}</span>
                        {showTooltipCreator && (
                            <Tooltip target={`#name-${id}`} position="top" onHide={() => setShowTooltip(false)}>
                                {created_by}
                            </Tooltip>
                        )}
                    </div>
                );
            }
            return <span>{created_by}</span>;
        } else if (colField === "created_at" || colField === "requested_date") {
            return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "";
        } else if (colField === "assignedto") {
            const assigned_to =
                rowData.au_first_name || rowData.au_last_name ? (rowData.au_last_name ? rowData.au_last_name : "") + " " + (rowData.au_first_name ? rowData.au_first_name : "") : "Unassigned";

            if (assigned_to.length > 24) {
                return (
                    <div className="response_text" onMouseEnter={() => setShowTooltipAssign(true)} onMouseLeave={() => setShowTooltipAssign(false)}>
                        <span id={`name-${id}`}>{assigned_to}</span>
                        {showTooltipAssign && (
                            <Tooltip target={`#name-${id}`} position="top" onHide={() => setShowTooltip(false)}>
                                {assigned_to}
                            </Tooltip>
                        )}
                    </div>
                );
            }
            return <span>{assigned_to}</span>;
        } else if (colField === "resolution_date") {
            return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "Unresolved";
        } else if (colField === "title") {
            if (title.length > 24) {
                return (
                    <div className="response_text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <span id={`name-${id}`}>{title}</span>
                        {showTooltip && (
                            <Tooltip target={`#name-${id}`} position="top" onHide={() => setShowTooltip(false)}>
                                {title}
                            </Tooltip>
                        )}
                    </div>
                );
            }
            return <span>{title}</span>;
        } else {
            return rowData[colField] ? rowData[colField] : "";
        }
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={supportGetColumnBody(col.field)} sortable>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
        setPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.status_text == "Closed") {
            return "delete_row_color";
        }
        return "";
    };

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 pr-0 pt-0">
                    <div className="card p-1 m-0">
                        <DataTable
                            ref={ref}
                            value={selectedHeaders}
                            selection={selectedUsers.length === 0 ? null : selectedUsers}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single"
                            onRowSelect={handleRowSelect}
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            )}
            {/* Ticket Detail Modal */}
            <Dialog
                visible={detaildialogVisible}
                onHide={() => (isFormDirty ? setIsVisible(true) : setDetailDialogVisible(false))}
                header="Ticket Details"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "80vw", height: "69.5vh" }}
                blockScroll={true}
            >
                {detaildialogVisible && <TicketDetailModal setIsFormDirty={setIsFormDirty} onHide={() => setDetailDialogVisible(false)} selectedRowId={selectedRowId} />}
            </Dialog>

            <Dialog
                visible={kebabMenuOption.assign_tiicket}
                onHide={() =>
                    setKebabMenuOption({
                        change_status: false,
                        assign_tiicket: false,
                    })
                }
                header="Assign Ticket"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "30vw" }}
                blockScroll={true}
            >
                {kebabMenuOption.assign_tiicket && (
                    <AssignTicketModal
                        selectedUsers={selectedUsers}
                        onHide={() =>
                            setKebabMenuOption({
                                change_status: false,
                                assign_tiicket: false,
                            })
                        }
                    />
                )}
            </Dialog>

            <Dialog
                blockScroll={true}
                visible={kebabMenuOption.change_status}
                onHide={() =>
                    setKebabMenuOption({
                        change_status: false,
                        assign_tiicket: false,
                    })
                }
                header="Change Status"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "30vw" }}
            >
                {kebabMenuOption.change_status && (
                    <ChangeStatusModal
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        onHide={() =>
                            setKebabMenuOption({
                                change_status: false,
                                assign_tiicket: false,
                            })
                        }
                    />
                )}
            </Dialog>
            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setDetailDialogVisible(false);
                        setIsVisible(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default Body;
