import React, { useContext, useEffect, useState } from "react";
//Components

import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//context
import { ToastContext } from "../../../../context/toast";
import { FILE_MAX_SIZE } from "../../../../utils/Constants/global";
import { BsCheckLg, BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { Button } from "primereact/button";
import { saveClientUnderwritingAttachments, saveContract } from "../../../../services/client";
const UploadContractModal = (props) => {
    //props
    const { onHide, id, onCall, setFormDirty, setSaveCallStatus, contractId, setUploadContract } = props;

    // states
    const [uploadedFile, setUploadedFile] = useState(null);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [cancelBtnDisabled, setCancelBtnDisabled] = useState(false);

    let validationSchema = Yup.object().shape({
        // attachment_path: Yup.mixed().required("Signed Contract is required"),
        attachment_path: Yup.mixed().when([], {
            is: () => !uploadedFile || uploadedFile == [] || uploadedFile == null,
            then: Yup.string().required("Signed Contract is required"),
            otherwise: Yup.string().notRequired(),
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            attachment_path: "",
        },
        onSubmit: async (data) => {
            setFormDirty(false);
            setDisabledBtn(true);
            setCancelBtnDisabled(true);
            setSaveCallStatus(true);
            try {
                data = {
                    ...data,
                    attachment_path: uploadedFile,
                };
                const formData = new FormData();
                if (contractId) {
                    formData.append("id", contractId);
                }
                formData.append("prospect_id", id);
                formData.append("signed_attachment", data.attachment_path);

                const res = await saveContract(formData);
                if (res.status) {
                    toast.showMessage("Attachment(s)", "Contract has been uploaded successfully.", "success");
                    onHide();
                    onCall();
                }
            } catch {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setDisabledBtn(false);
            setCancelBtnDisabled(false);
            setSaveCallStatus(false);
        },
    });

    // Validation
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    // Context
    const toast = useContext(ToastContext);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const fileType = file.name.split(".");
        const index = fileType.length - 1;
        const accept = ".pdf,.doc,.docx";
        const check_file = accept.includes(fileType[index]);
        if (check_file) {
            const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
            if (fileSize) {
                toast.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size (3mb allowed).", "error");
                event.target.value = null;
                setUploadedFile(null);
                setFormDirty(false);
            } else if (file.name.includes("#") || file.name.includes("&")) {
                toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
            } else {
                formik.setFieldError("attachment_path", "");
                setFormDirty(true);
                setUploadedFile(file);
                event.target.value = null;
            }
        } else {
            toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
            setUploadedFile(null);
            setFormDirty(false);
        }
    };

    // const handleInputChange = (e) => {
    //     const { value } = e.target;
    //     formik.handleChange(e);
    //     setFormDirty(true);
    // };

    const handleRemove = () => {
        setUploadedFile(null);
    };

    const handleCancel = () => {
        console.log("uploadedFile", uploadedFile);
        if (uploadedFile == null) {
            onHide();
        } else {
            setIsFormDirty(true);
        }
    };

    console.log(uploadedFile);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <label htmlFor="NameTitle">
                            <b>Signed Contract</b>
                            <span className="clr_red">*</span>
                            <Button
                                type="button"
                                tooltip=".pdf, .doc, .docx files only (max size 3MB) "
                                tooltipOptions={{ position: "bottom" }}
                                icon={<BsInfoCircle />}
                                aria-label="Submit"
                                className="customTooltipicon"
                            />
                        </label>
                        <div className="file_upload_class">
                            <label htmlFor="file-upload" className={`flex flex-row justify-content-between align-items-center ${disabledBtn ? "disabled_choose_file_input" : ""}`}>
                                Choose File <BsPaperclip />
                            </label>
                        </div>
                        <input type="file" id="file-upload" onChange={handleFileUpload} accept=".pdf, .doc, .docx" style={{ display: "none" }} />
                        {getFormErrorMessage("attachment_path")}
                    </div>

                    {uploadedFile?.name && (
                        <>
                            <div className="col-12">
                                <div>
                                    <div className="image-item">
                                        <div>
                                            <div className="flex row">
                                                <span className="text_break">{uploadedFile?.name}</span>
                                                <BsXLg className="cursor-pointer cross_icon ml-1" onClick={() => handleRemove()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="col-12 text-right pt-5">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} isDisabled={cancelBtnDisabled} />
                        <CustomAddNewButton title="Save" type="submit" isDisabled={disabledBtn} />
                    </div>
                </div>
            </form>
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => {
                        setIsFormDirty(false);
                        setFormDirty(false);
                        setUploadContract(false);
                    }}
                    handler={onHide}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default UploadContractModal;
