import React from "react";

export default function DeleteIcon(props) {
    const { color, width = "21", height = "20" } = props;
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.79102 1.25H12.541C12.8862 1.25 13.166 1.52982 13.166 1.875V3.125H8.16602V1.875C8.16602 1.52982 8.44584 1.25 8.79102 1.25ZM14.416 3.125V1.875C14.416 0.839466 13.5765 0 12.541 0H8.79102C7.75548 0 6.91602 0.839466 6.91602 1.875V3.125H3.79808C3.79384 3.12496 3.78958 3.12496 3.78532 3.125H2.54102C2.19584 3.125 1.91602 3.40482 1.91602 3.75C1.91602 4.09518 2.19584 4.375 2.54102 4.375H3.21402L4.27997 17.6994C4.38391 18.9986 5.46861 20 6.77201 20H14.56C15.8634 20 16.9481 18.9986 17.0521 17.6994L18.118 4.375H18.791C19.1362 4.375 19.416 4.09518 19.416 3.75C19.416 3.40482 19.1362 3.125 18.791 3.125H17.5467C17.5424 3.12496 17.5382 3.12496 17.5339 3.125H14.416ZM16.864 4.375L15.806 17.5997C15.7541 18.2493 15.2117 18.75 14.56 18.75H6.77201C6.1203 18.75 5.57796 18.2493 5.52599 17.5997L4.46801 4.375H16.864ZM7.50431 5.62608C7.8489 5.60581 8.14467 5.86872 8.16494 6.2133L8.78994 16.8383C8.81021 17.1829 8.5473 17.4787 8.20272 17.4989C7.85813 17.5192 7.56236 17.2563 7.54209 16.9117L6.91709 6.2867C6.89682 5.94212 7.15973 5.64635 7.50431 5.62608ZM13.8277 5.62608C14.1723 5.64635 14.4352 5.94212 14.4149 6.2867L13.7899 16.9117C13.7697 17.2563 13.4739 17.5192 13.1293 17.4989C12.7847 17.4787 12.5218 17.1829 12.5421 16.8383L13.1671 6.2133C13.1874 5.86872 13.4831 5.60581 13.8277 5.62608ZM10.666 5.625C11.0112 5.625 11.291 5.90482 11.291 6.25V16.875C11.291 17.2202 11.0112 17.5 10.666 17.5C10.3208 17.5 10.041 17.2202 10.041 16.875V6.25C10.041 5.90482 10.3208 5.625 10.666 5.625Z"
                    fill="#555960"
                />
            </svg>
        </>
    );
}
