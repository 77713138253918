import React, { useState, useEffect, useContext } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//components
import { ToastContext } from "../../../../context/toast";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { getCustomDropDown } from "../../../../services/dropdown";
import { ChangeStatus } from "../../../../services/broker";

const ChangeBrokerStatusComponent = (props) => {
    const { onHide, selectedBroker } = props;
    // View States
    const [isLoading, setIsLoading] = useState(false);
    const [brokerStatusOptions, setBrokerStatusOptions] = useState([]);
    //toast message
    const toast = useContext(ToastContext);

    // FormMik
    let validationSchema = Yup.object().shape({
        status: Yup.string().required("Status is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: "",
        },
        onSubmit: async (data) => {
       
            setIsLoading(true);
            try {
                const temp = [];
                selectedBroker.map((item) => {
                    temp.push(item.id);
                });

                let payload = {
                    ids: temp,
                    status: data.status,
                };
                const res = await ChangeStatus(payload);
                if (res?.data) {
                    toast.updateToast("Broker Status");
                    onHide();
                } else {
                    setIsLoading(false);
                    toast.showMessage("Error", "Admin has been notified", "error");
                }
            } catch (e) {
                toast.showMessage("Error", "Admin has been notified", "error");
                setIsLoading(false);
            }
            setIsLoading(false);
        },
    });

    useEffect(() => {
        const fetchBrokerStatus = async () => {
            setIsLoading(true);
            let payload = {
                select: ["broker_status"],
            };
            try {
                const res = await getCustomDropDown(payload);
                if (res.status) {
                    let status = JSON.parse(res.data.dropdowns.broker_status);

                    if(selectedBroker[0]?.broker_status===2)
                    {
                        status=status.filter((record) => record.value === 3);
                    }
                    setBrokerStatusOptions(status);
                    
                } else {
                    setIsLoading(false);
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                onHide();
            }
            setIsLoading(false);
        };

        fetchBrokerStatus();
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <CustomInputField
                        className={`col-12 md:col-12 ${isLoading ? "no-drop" : " "}`}
                        disabled={isLoading}
                        type="dropdown"
                        iden="status"
                        label="Status"
                        mandatory="*"
                        formik={formik}
                        optionLabel="name"
                        optionValue="value"
                        options={brokerStatusOptions}
                        placeHolder="Select"
                    />
                    <div className="col-12 text-right pt-5">
                        <CustomAddNewButton title="Save" type="submit" isDisabled={isLoading} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default ChangeBrokerStatusComponent;
