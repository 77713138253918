import React, { useState } from "react";
//components
import { Dialog } from "primereact/dialog";
import InviteClientModal from "../Components/InviteClientModal";
//ui-components
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { MANAGMENT_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";

//dialog
const UserHeader = (props) => {
    // Props
    const { getProspectUsers, ownersForDropdown, ownerLoading, showInviteButton, setShowInviteButton, id, setUpdateClientUser } = props;
    const [inviteClient, setInviteClient] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const { user } = useSelector(loginState);

    const onCancel = (refresh) => {
        if (refresh) {
            getProspectUsers();
        }
        setInviteClient(false);
    };
    return (
        <>
            <Dialog
                header="Invite Client"
                visible={inviteClient}
                onHide={(e) => (isFormDirty ? setIsVisible(true) : setInviteClient(false))}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
            >
                <InviteClientModal
                    onHide={(e) => setInviteClient(false)}
                    ownersForDropdown={ownersForDropdown}
                    ownerLoading={ownerLoading}
                    setShowInviteButton={setShowInviteButton}
                    showInviteButton={showInviteButton}
                    setUpdateClientUser={setUpdateClientUser}
                    setInviteClient={setInviteClient}
                    setIsFormDirty={setIsFormDirty}
                    isFormDirty={isFormDirty}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    id={id}
                />
            </Dialog>
            {validateUserPermissions([MANAGMENT_RIGHTS.clients.invite_client], user?.rights) && (
                <CustomAddNewButton title="Invite Client" onClick={() => setInviteClient(true)} isDisabled={ownerLoading || !showInviteButton} type="button" />
            )}
        </>
    );
};

export default UserHeader;
