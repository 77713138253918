import React from "react";
import Prospect from "./Screens";

const ProspectIndex = () => {
    return (
        <>
            <Prospect />
        </>
    );
};

export default ProspectIndex;
