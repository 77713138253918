import React, { useEffect, useRef, useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { getConversation, saveChat } from "../../../services/chat";
import { useSelector } from "react-redux";
import { loginState } from "../../../redux/login";
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
import moment, { now } from "moment";

const ChatMessageIndex = (props) => {
    const { selectedChat } = props;
    const { user } = useSelector(loginState);

    const [conversation, setConversation] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);

    const chatWindowRef = useRef(null);

    const handleScroll = async () => {
        const { scrollTop } = chatWindowRef.current;
        if (scrollTop === 0 && hasMoreMessages) {
            getMessages();
        }
    };

    const getMessages = async () => {
        if (selectedChat.participant_id !== undefined && selectedChat.participant_id !== null) {
            setLoading(true);
            let form = {
                page: page + 1,
                per_page: perPage,
                chatter_id: selectedChat.participant_id,
            };
            let res = await getConversation(form);
            if (res?.data) {
                const newMessages = res.data.conversation.data;
                if (newMessages.length === 0) {
                    setHasMoreMessages(false);
                } else {
                    let updatedConversation = [...newMessages.reverse(), ...conversation];
                    setConversation(updatedConversation);
                    setPage(page + 1);
                }
            }
            setLoading(false);
        }

        return;
    };
    const saveMessage = async (message) => {
        if (keyword.trim()) {
            setSaveLoading(true);
            let form = {
                receiver_id: selectedChat.participant_id,
                message: keyword,
            };

            let res = await saveChat(form);
            if (res?.status) {
                if (res.data.status) {
                    const customMessage = {
                        sender_id: user.id,
                        receiver_id: selectedChat.participant_id,
                        message: keyword,
                        created_at: now(),
                    };
                    setConversation([...conversation, customMessage]);
                    setKeyword("");
                }
            }
            setSaveLoading(false);
        }
        return;
    };
    useEffect(() => {
        setPage(0);
        setPerPage(10);
        setConversation([]);
        getMessages();
    }, [selectedChat]);

    useEffect(() => {
        if (chatWindowRef.current && page === 1) {
            setTimeout(function () {
                chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
            }, 100);
        } else {
            setTimeout(function () {
                chatWindowRef.current.scrollTop = 100;
            }, 100);
        }
    }, [conversation]);

    return (
        <>
            <div className="chat_header_name p-3">
                <h5>{selectedChat.name}</h5>
            </div>

            <div className="chat_menu_scroll" ref={chatWindowRef} onScroll={handleScroll}>
                <div className="chat_position">
                    {loading ? <CustomLoading top={"50px"} /> : <></>}
                    {conversation.map((item) => (
                        <>
                            <div key={item.id} className={item?.sender_id === user?.id ? "sender_chat_messgaes" : "chat_messgaes"}>
                                <div className="message_text">{item.message}</div>
                                <div className="date_text">
                                    <span> {moment(item.created_at).format("MMMM DD, YYYY, hh:mm A")}</span>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
            {selectedChat.participant_id ? (
                <div className="chat_background_view">
                    <div className="chat_input_class">
                        <input className="custom_inputField" type="text" placeHolder="Send your message " value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                    </div>
                    <div className="chat_button_position">
                        <button className="chat_send_button" disabled={saveLoading} id="chat_send" onClick={saveMessage} name="chat_send">
                            <BsFillSendFill className="text-2xl" />
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ChatMessageIndex;
