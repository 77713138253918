import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Prime React
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";

// Icons
import { BsX } from "react-icons/bs";

// Context
import { ToastContext } from "../../../../../context/toast";
import { DebtorContext } from "../../../../../context/DebtorContext";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { onDebtorFormDirty } from "../../../../../redux/main-view";

// Helper Function
import { validateEmail } from "../../../../../utils/HelperFuctions/checkEmail";
import toTitleCaseWhileTyping from "../../../../../utils/HelperFuctions/cleanData";

// API'S
import { saveDebtor } from "../../../../../services/debtor";
import { getCountryCode } from "../../../../../services/country-code";
import { loginState } from "../../../../../redux/login";
import { ROLE_NAME } from "../../../../../utils/Constants/global";

const initialValidationState = {
    first_name: false,
    email: false,
    phone: false,
    inValidEmail: false,
};
const DebtorPoc = (props) => {
    const { setIndexCall, setIsOpening, pocs, setPocs, formik, setIsClient, isClient, newIndustry, industryValue, industryTitle, industries } = props;
    const toast = useContext(ToastContext);
    const history = useHistory();
    const dispatch = useDispatch();

    const [countryCode, setCountryCode] = useState([]);
    const debtor = useContext(DebtorContext);
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { user } = useSelector(loginState);

    const handleAddContact = () => {
        if (pocs.length === 10) {
            toast.showMessage("Limit", "Maximum 10 contacts are allowed.", "error");
        } else {
            setPocs([
                ...pocs,
                {
                    first_name: "",
                    last_name: "",
                    email: "",
                    department: "",
                    phone: "",
                    fax: "",
                    country_phone_code: "",
                    country_fax_code: "",
                    validations: {
                        ...initialValidationState,
                    },
                },
            ]);
        }
    };

    const handleRemoveForm = (index) => {
        if (index !== 0) {
            const _pocs = pocs.filter((_, i) => i !== index);
            setPocs(_pocs);
            dispatch(onDebtorFormDirty(true));
        }
    };

    const saveHandler = async () => {
        let hasError = false;
        let _pocs = pocs.map((item) => {
            const { validations, ...rest } = item;
            if (item.first_name === "" || item.email === "" || item.phone === "" || !validateEmail(item.email)) {
                hasError = true;

                return {
                    ...rest,
                    validations: {
                        first_name: item.first_name === "",
                        email: item.email === "",
                        phone: item.phone === "",
                        inValidEmail: item.email ? !validateEmail(item.email) : false,
                    },
                };
            } else {
                let data = {
                    ...rest,
                    fax: rest.fax?.toString().replaceAll("-", ""),
                    phone: rest.phone.toString().replaceAll("-", ""),
                    country_phone_code: rest.country_phone_code ? rest.country_phone_code : "+1",
                    country_fax_code: rest.country_fax_code !== "+1" ? rest.country_fax_code : "+1",
                };

                return data;
            }
        });

        if (hasError) {
            setPocs(_pocs);
            return;
        }

        let payload = {
            ...formik.values,
            fax: formik.values.fax ? formik.values.fax.toString().replaceAll("-", "") : "",
            phone: formik.values.phone.toString().replaceAll("-", ""),
            pocs: _pocs,
        };

        if (newIndustry) {
            payload["industry"] = industryTitle;
            payload["industry_id"] = 0;
        } else {
            const industry_id = industries.find((data) => data.label === industryTitle)?.value;
            payload["industry"] = industryTitle;
            payload["industry_id"] = industry_id;
        }

        if (payload.hasOwnProperty("id") && payload.id == "") {
            delete payload.id;
        }

        setIsSaving(true);
        setIsLoading(true);

        try {
            let res = await saveDebtor(payload);

            if (res && res.status) {
                if (res.data && res.data.status) {
                    if (payload.id) {
                        toast.updateToast("Debtor");
                    } else {
                        toast.createdToast("Debtor");
                    }

                    history.push("/debtormain");
                    // setIndexCall(0);
                } else {
                    if (res.data && res.data.message) {
                        toast.showMessage("Creation Failed", res.data.message, "error");
                    }
                }
                setIsSaving(false);
                setIsLoading(false);
            } else {
                if (res.data && res.data.message) {
                    toast.showMessage("Creation Failed", res.data.message, "error");
                } else {
                    toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
                setIsSaving(false);
                setIsLoading(false);
            }
        } catch (e) {}
    };

    const handleInputChange = (e, index) => {
        dispatch(onDebtorFormDirty(true));

        const { name, value } = e.target;
        const _pocs = pocs.map((item, i) => {
            const validations = item.validations;
            if (i === index) {
                if (name === "first_name" || name === "last_name" || name === "department") {
                    return {
                        ...item,
                        [name]: toTitleCaseWhileTyping(value),
                        validations: {
                            ...validations,
                            first_name: item.first_name === "",
                        },
                    };
                } else {
                    return {
                        ...item,
                        [name]: value,
                        validations: {
                            ...validations,
                            email: item.email === "",
                            phone: item.phone === "",
                            inValidEmail: item.email ? !validateEmail(item.email) : false,
                        },
                    };
                }
            }
            return item;
        });

        setPocs(_pocs);
    };

    const fetchingCountryCodes = () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                if (res && res.status) {
                    if (res.data) {
                        setCountryCode(res.data.countries);
                    }
                }
            });
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const countryCodeSearchHandler = (event) => {
        let phone_code = event.target.value;
        let regex = /^[0-9+\b]+$/;
        if (!regex.test(phone_code)) {
            event.stopPropagation();
        }
    };

    useEffect(() => {
        fetchingCountryCodes();
    }, [debtor]);

    useEffect(() => {
        const apiCall = async () => {
            setLoading(true);

            let _pocs = debtor.pocs.map((item) => {
                let _item = { ...item };

                _item.validations = { ...initialValidationState };

                return _item;
            });

            setPocs(_pocs);
        };

        if (debtor && debtor.pocs && debtor.pocs.length > 0 && Object.keys(debtor.pocs[0]).length > 0) {
            apiCall();
        }

        // if(user?.userRoles[0]['roleName']===ROLE_NAME.CLIENT){
        //     setIsClient(true);
        // }
    }, []);

    return (
        <>
            <div className="card">
                {pocs.map((item, index) => (
                    <form className="step-form-top-padding" key={index}>
                        <Fieldset legend="Contact Details">
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                                        <label htmlFor="Title">
                                            <b>
                                                Contact First Name <span className="clr_red">*</span>
                                            </b>
                                        </label>
                                    </div>
                                    <div>
                                        <InputText
                                            id="first_name"
                                            name="first_name"
                                            type="text"
                                            disabled={isClient}
                                            placeholder="Enter Contact First Name"
                                            maxLength="25"
                                            value={item.first_name}
                                            keyfilter={/^[A-Za-z0-9\s]+$/}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                        {item.validations?.first_name && <small className="p-error">Contact First Name is required</small>}
                                    </div>
                                </div>

                                <div className="col-12 md:col-4">
                                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                                        <label htmlFor="Name">
                                            <b>Contact Last Name</b>
                                        </label>
                                    </div>
                                    <div>
                                        <InputText
                                            id="last_name"
                                            name="last_name"
                                            type="text"
                                            disabled={isClient}
                                            placeholder="Enter Contact Last Name"
                                            maxLength="50"
                                            value={item.last_name}
                                            keyfilter={/^[A-Za-z0-9\s]+$/}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                                        <label htmlFor="email">
                                            <b>
                                                Email<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                    </div>
                                    <div>
                                        <InputText
                                            name="email"
                                            id="email"
                                            disabled={isClient}
                                            type="email"
                                            placeholder="Enter Email"
                                            value={item.email}
                                            onChange={(e) => handleInputChange(e, index)}
                                            maxLength="50"
                                        />
                                        {item.validations?.email && <small className="p-error">Email is required</small>}
                                        {item.validations?.inValidEmail && <small className="p-error">Email is invalid</small>}
                                    </div>
                                </div>

                                <div className="col-12 md:col-4">
                                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                                        <label htmlFor="departments" className="step-fields-title">
                                            <b>Department</b>
                                        </label>
                                    </div>
                                    <div>
                                        <InputText
                                            id="department"
                                            name="department"
                                            type="text"
                                            placeholder="Enter Department"
                                            disabled={isClient}
                                            maxLength="40"
                                            value={item.department}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </div>
                                </div>
                                <div className="md:col-4 col-12">
                                    <label htmlFor="phone" className="lbl">
                                        <b>
                                            Phone No<span className="clr_red">*</span>
                                        </b>
                                    </label>
                                    <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                        <Dropdown
                                            id="country_phone_code"
                                            name="country_phone_code"
                                            placeholder="+1"
                                            optionLabel="phone_code"
                                            disabled={isClient}
                                            optionValue="phone_code"
                                            value={item.country_phone_code}
                                            options={countryCode}
                                            onChange={(e) => handleInputChange(e, index)}
                                            className="proscustom_width_country"
                                            filter
                                            filterBy="phone_code"
                                            emptyMessage="No results found"
                                        />
                                        <InputMask
                                            iden="phone"
                                            name="phone"
                                            mask="999-999-9999"
                                            placeholder="999-999-9999"
                                            disabled={isClient}
                                            value={item.phone}
                                            onChange={(e) => handleInputChange(e, index)}
                                            className="proscustom_width_phone"
                                        />
                                    </div>
                                    {item.validations?.phone && <small className="p-error">Phone No is required</small>}
                                </div>
                                <div className="md:col-4 col-12">
                                    <label htmlFor="phone" className="lbl">
                                        <b>Fax No</b>
                                    </label>
                                    <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                        <Dropdown
                                            id="country_fax_code"
                                            name="country_fax_code"
                                            placeholder="+1"
                                            optionLabel="phone_code"
                                            optionValue="phone_code"
                                            value={item.country_fax_code}
                                            disabled={isClient}
                                            options={countryCode}
                                            onChange={(e) => handleInputChange(e, index)}
                                            className="proscustom_width_country"
                                            filter
                                            filterBy="phone_code"
                                            emptyMessage="No results found"
                                        />

                                        <InputMask
                                            iden="fax"
                                            name="fax"
                                            mask="999-999-9999"
                                            placeholder="999-999-9999"
                                            value={item.fax}
                                            disabled={isClient}
                                            onChange={(e) => handleInputChange(e, index)}
                                            className="proscustom_width_phone"
                                        />
                                    </div>
                                </div>
                                {index !== 0 && <Button type="button" onClick={() => handleRemoveForm(index)} className="cros_btn" icon={<BsX />}></Button>}
                            </div>
                        </Fieldset>
                    </form>
                ))}
                {pocs.length < 10 && (
                    <div className="pt-3">
                        <CustomAddNewButton id="addPoc" title="Add More" isDisabled={isClient} type="button" onClick={handleAddContact} />
                    </div>
                )}

                <div className="action-btns-prospect-form pt-6">
                    <CustomCancelbtn id="cancelBtn" title="Previous" type="button" onClick={() => setIndexCall(0, debtor?.profile, pocs)} />
                    <CustomAddNewButton id="save" title="Save" type="submit" isDisabled={isSaving || isClient} onClick={saveHandler} isLoading={isLoading} />
                </div>
            </div>
        </>
    );
};

export default DebtorPoc;
