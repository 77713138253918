import React, { useContext, useEffect, useState } from "react";
//Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//ui-components
import CustomInputField from "../../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { getNotes, postNote } from "../../../../../../services/collection";
import { ToastContext } from "../../../../../../context/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { dateTimeFormatForInputFields, getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";

const NotesModal = (props) => {
    //props
    const { selected,onHide } = props;
    // states
    const [data, setData] = useState([]);
    // toast
    const toast = useContext(ToastContext);
    const validationSchema = Yup.object().shape({
        note: Yup.string().required("Add Note is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            note: "",
        },
        onSubmit: async () => {
            onHide();
            let payload = {
                collection_id: selected[0]?.id,
                note: formik.values.note,
            };
            try {
                let res = await postNote(payload);
                if (res.data.status) {
                    toast.createdToast("Note(s)");
                    formik.resetForm();
                    handleFetchNotes();
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });

    const handleFetchNotes = async () => {
        let payload = {
            collection_id: selected[0]?.id,
        };
        try {
            let res = await getNotes(payload);
            if (res.data.status) {
                setData(res.data.notes);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const handleTemplate = (rowData) => {
        return <>{dateTimeFormatForInputFields(rowData?.created_at)}</>;
    };
    useEffect(() => {
        handleFetchNotes();
    }, []);
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField type="textarea" rows="5" iden="note"  label="Add Note" mandatory="*" formik={formik} maxLength={150}/>
                    </div>
                    <div className="col-12 text-right">
                        <CustomAddNewButton title="Save" type="submit" />
                    </div>
                    {data.length > 0 && (
                        <>
                            <div className="col-12 pt-3">
                                <DataTable value={data} emptyMessage="No record available.">
                                    <Column field="note" header="Notes"></Column>
                                    <Column body={handleTemplate} header="Created At"></Column>
                                </DataTable>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    );
};

export default NotesModal;
