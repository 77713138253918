import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../../../context/toast";
import { fetchCreditLimit, saveCreditRequest } from "../../../../../services/credit-request";
import { getProspectDropdown } from "../../../../../services/prospect";
import { getDebtors } from "../../../../../services/debtor";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { onCreditRequestFormDirty } from "../../../../../redux/main-view";
import { useDispatch, useSelector } from "react-redux";
import { AmountCheck } from "../../../../../utils/HelperFuctions/checkAmount";
import { ROLE_NAME } from "../../../../../utils/Constants/global";
import { loginState } from "../../../../../redux/login";
const clientValidationSchema = Yup.object().shape({
    prospect_id: Yup.string().required("Client Name is required"),
    requested_amount: Yup.number()
        .typeError("Requested Credit Limit is required")
        .min(0.01, "Requested Credit Limit should be greater than $0.00")
        .max(99999999.99, "Requested Credit Limit should be less than $99,999,999.99")
        .required("Requested Credit Limit is required"),
});
const debtorValidationSchema = Yup.object().shape({
    prospect_id: Yup.string().required("Client Name is required"),
    debtor_id: Yup.string().required("Debtor Name is required"),
    requested_amount: Yup.number()
        .typeError("Requested Credit Limit is required")
        .min(0.01, "Requested Credit Limit should be greater than $0.00")
        .max(99999999.99, "Requested Credit Limit should be less than $99,999,999.99")
        .required("Requested Credit Limit is required"),
});
const CreditRequestForm = (props) => {
    const { isClientRequest, onHide } = props;
    const toast = useContext(ToastContext);
    const dispatch = useDispatch();

    const [prospects, setProspects] = useState([]);
    const [debtors, setDebtors] = useState([]);
    const [prospectLoading, setProspectLoading] = useState(false);
    const [debtorLoading, setDebtorLoading] = useState(false);
    const [creditLimitLoading, setCreditLimitLoading] = useState(false);

    const [clientSelected, setClientSelected] = useState(false);
    const [clientSearch, setClientSearch] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isAvailableCLExceeded, setIsAvailableCLExceeded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { user } = useSelector(loginState);

    const formik = useFormik({
        validationSchema: isClientRequest ? clientValidationSchema : debtorValidationSchema,
        initialValues: {
            prospect_id: "",
            debtor_id: "",
            current_credit_limit: "",
            requested_amount: "",
            client_active_credit_limit: "",
        },
        onSubmit: async (data) => {
            let { client_active_credit_limit, new_credit_limit, ...rest } = data;

            setIsSaving(true);
            setIsLoading(true);

            try {
                let res = await saveCreditRequest(rest);
                if (res.data) {
                    if (res.data.status) {
                        if (isClientRequest) {
                            toast.createdToast("Client Credit Request");
                        } else {
                            toast.createdToast("Debtor Credit Request");
                        }

                        onHide(true);
                    } else {
                        toast.showMessage("Creation Failed", res.data.message, "error");
                    }
                } else {
                    toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
                setIsSaving(false);
                setIsLoading(false);
            } catch (e) {
                toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
                setIsLoading(false);
            }
        },
    });

    const handleCurrencyChange = (event, name) => {
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 99999999.99) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const fetchProspectDropdown = (filterValue) => {
        const payload = {
            per_page: "20",
            search_text: filterValue,
        };

        setProspectLoading(true);
        getProspectDropdown(payload).then((res) => {
            if (res.status) {
                if (res.data.prospects.data.length) {
                    setProspects(res.data.prospects.data);
                }
            }
            setProspectLoading(false);
        });
    };

    const fetchDebtorDropdown = (filterValue = null) => {
        let payload = {
            page: 1,
            per_page: 10,
            search_text: filterValue,
            prospect_id: formik.values.prospect_id,
        };

        setDebtorLoading(true);
        getDebtors(payload).then((res) => {
            if (res && res.status) {
                if (res.data && res.data.debtors.data?.length) {
                    setDebtors(res.data.debtors.data);
                }
            }
            setDebtorLoading(false);
        });
    };

    const handleClientFilter = (event) => {
        setClientSearch(event.filter);
    };

    const handleDebtorFilter = (event) => {
        dispatch(onCreditRequestFormDirty(true));

        const filterValue = event.filter;
        if (filterValue.length > 2) {
            const delayDebounceFn = setTimeout(() => {
                fetchDebtorDropdown(filterValue);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    };
    const fetchClientCR = async () => {
        let payload = {
            prospect_id: formik.values.prospect_id,
        };
        setCreditLimitLoading(true);
        fetchCreditLimit(payload).then((res) => {
            if (res && res.status) {
                if (res.data && res.data.credit_limit) {
                    formik.setFieldValue("current_credit_limit", res.data.credit_limit.client_original_credit_limit ? res.data.credit_limit.client_original_credit_limit : "");
                }
            }
            setCreditLimitLoading(false);
        });
    };

    const fetchDebtorCR = () => {
        let payload = {
            prospect_id: formik.values.prospect_id,
            debtor_id: formik.values.debtor_id,
        };

        fetchCreditLimit(payload).then((res) => {
            if (res && res.status) {
                if (res.data && res.data.credit_limit) {
                    formik.setFieldValue("current_credit_limit", res.data.credit_limit.debtor_original_credit_limit ? res.data.credit_limit.debtor_original_credit_limit : "");
                    formik.setFieldValue("client_active_credit_limit", res.data.credit_limit.client_active_credit_limit);
                }
            }
        });
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;

        if (name === "prospect_id" || name === "debtor_id") {
            formik.setFieldValue(name, value);
            dispatch(onCreditRequestFormDirty(true));
        }
    };

    useEffect(() => {
        if (formik.values.prospect_id) {
            setClientSelected(true);

            if (!isClientRequest) {
                fetchDebtorDropdown();
            } else {
                fetchClientCR();
            }
            //clear other fields
            formik.setFieldValue("current_credit_limit", "");
            formik.setFieldValue("requested_amount", "");
            formik.setFieldValue("new_credit_limit", "");
            formik.setFieldValue("client_active_credit_limit", "");
        }
    }, [formik.values.prospect_id]);

    useEffect(() => {
        if (formik.values.debtor_id) {
            fetchDebtorCR();
        }
    }, [formik.values.debtor_id]);

    useEffect(() => {
        let requested_amount = formik.values.requested_amount ? parseFloat(formik.values.requested_amount) : 0;

        if (!isNaN(requested_amount)) {
            formik.values.new_credit_limit = formik.values.current_credit_limit + requested_amount;

            if (isClientRequest) {
                if (formik.values.new_credit_limit > 100000000) {
                    setIsAvailableCLExceeded(true);
                } else {
                    setIsAvailableCLExceeded(false);
                }
            } else {
                if (formik.values.client_active_credit_limit && formik.values.requested_amount > formik.values.client_active_credit_limit) {
                    setIsAvailableCLExceeded(true);
                } else {
                    setIsAvailableCLExceeded(false);
                }
            }
        }
    }, [formik.values.requested_amount, formik.values.current_credit_limit, formik.values.client_active_credit_limit]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchProspectDropdown(clientSearch.length === 0 ? null : clientSearch);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [clientSearch]);

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            if (prospects.length > 0) {
                setTimeout(() => {
                    formik.setFieldValue("prospect_id", prospects[0].id);
                }, 250);
            }
        }
    }, [prospects]);
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <CustomInputField
                    className={`col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} ${prospectLoading && "no-drop"}`}
                    type="dropdown"
                    iden="prospect_id"
                    label="Client Name"
                    mandatory="*"
                    formik={formik}
                    options={prospects}
                    optionLabel="company_business_name"
                    optionValue="id"
                    placeHolder="Select"
                    filter
                    filterBy="company_business_name"
                    handleFilter={handleClientFilter}
                    onChange={(e) => inputHandler(e)}
                    disabled={prospectLoading || user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT}
                />

                {!isClientRequest && (
                    <CustomInputField
                        className={`col-12 md:col-4 ${(!clientSelected || debtorLoading) && "no-drop"}`}
                        type="dropdown"
                        iden="debtor_id"
                        label="Debtor Name"
                        mandatory="*"
                        formik={formik}
                        options={debtors}
                        optionLabel="debtor_name"
                        optionValue="id"
                        placeHolder="Select"
                        filter
                        filterBy="debtor_name"
                        onFilter={handleDebtorFilter}
                        onChange={(e) => inputHandler(e)}
                        disabled={!clientSelected || debtorLoading}
                    />
                )}

                {!isClientRequest && (
                    <CustomInputField
                        className={`col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop`}
                        type="currency"
                        iden="client_active_credit_limit"
                        label="Client's Available Credit Limit"
                        formik={formik}
                        disabled={true}
                    />
                )}

                <CustomInputField
                    className={`col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop`}
                    type="currency"
                    iden="current_credit_limit"
                    label="Previous Credit Limit"
                    formik={formik}
                    disabled={true}
                />
                <CustomInputField
                    className={`col-12 ${isClientRequest ? "md:col-6" : "md:col-4"}`}
                    iden="requested_amount"
                    label="Requested Credit Limit"
                    mandatory="*"
                    formik={formik}
                    type="currency"
                    placeHolder="$0.00"
                    onPaste={(e) => e.preventDefault()}
                    onValueChange={(e) => handleCurrencyChange(e, "requested_amount")}
                    min={0}
                    disabled={!formik.values.prospect_id || (!isClientRequest && !formik.values.debtor_id)}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    max={99999999.99}
                />

                <CustomInputField
                    className={`col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop`}
                    type="currency"
                    iden="new_credit_limit"
                    label="Current Credit Limit"
                    formik={formik}
                    disabled={true}
                />
            </div>
            {isAvailableCLExceeded && (
                <div>
                    {isClientRequest ? (
                        <small className="p-error">Warning: Current Credit Limit can not exceed from $99,999,999.99.</small>
                    ) : (
                        <small className="p-error">Warning: Requested Credit Limit cannot exceed the Client's Available Credit Limit.</small>
                    )}
                </div>
            )}
            <div className="flex flex-row justify-content-end pt-3">
                <CustomCancelbtn id="cancel" title="Cancel" type="button" onClick={() => onHide(false)} />
                <CustomAddNewButton id="submit" title="Save" type="submit" isDisabled={isSaving || isAvailableCLExceeded || creditLimitLoading} isLoading={isLoading} />
            </div>
        </form>
    );
};

export default CreditRequestForm;
