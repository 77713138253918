import React, { useContext, useEffect, useState } from "react";
// Components
import Body from "./GridView/Body";
import Header from "./GridView/Header";
import FilterBar from "./GridView/FilterBar";
// Styles
import "../../management.scss";
// Constants
import { AVAILABLE_PROSPECT } from "../../../../utils/Constants/global";
// API'S
import { fetchProspect, fetchProspectCount } from "../../../../services/prospect";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onProspectMenuChange } from "../../../../redux/main-view";
import { ToastContext } from "../../../../context/toast";

const Prospect = (props) => {
    //Rdux
    const { screens } = useSelector(mainViewState);

    // Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // State
    const [response, setResponse] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [header, setHeader] = useState(AVAILABLE_PROSPECT);
    const [selectedColumns, setSelectedColumns] = useState(AVAILABLE_PROSPECT);
    const [componentName, setComponentName] = useState("available_prospect");
    const [count, setCount] = useState({
        total_duly_filled_applications: 0,
        total_fallouts: 0,
        total_prospects: 0,
    });
    const [kebabMenuOption, setKebabMenuOption] = useState({
        promote_to_client: false,
        demote_to_fallout: false,
        delete: false,
    });
    const [dulyKebabMenuOption, setDullyKebabMenuOption] = useState({
        change_status: false,
        email_to_prospect: false,
    });
    const [showAll, setShowAll] = useState(false);
    const [activeCard, setActiveCard] = useState(0);
    const [status, setStatus] = useState(0);

    // Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);
    const [filledForm, setFilledForm] = useState(true);
    const getProspects = async (page) => {
        try {
            setLoading(true);
            let payload = {
                per_page: pageSize,
                search_text: keyword === null ? "" : keyword,
                application_completely_filled: filledForm,
                page: page ? page : currentPage,
                show_all: showAll,
            };
            if (filledForm) {
                payload["status"] = status;
            }
            if (sortField) {
                payload["sort_by"] = sortField;
            }
            if (sortOrder) {
                payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
            }
            const res = await fetchProspect(payload);

            if (res.data.status) {
                setResponse(res.data.prospects.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.prospects.total,
                    totalPages: res.data.prospects.last_page,
                    isLastPage: res.data.prospects.last_page,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    useEffect(() => {
        getProspects();
    }, [screens.prospect.isMutated, sortField, showAll, sortOrder, pageSize, filledForm, currentPage, status]);
    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage !== 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getProspects();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);
    useEffect(() => {
        let payload = {
            show_all: showAll,
        };
        fetchProspectCount(payload).then((res) => {
            setCount({
                total_duly_filled_applications: res.data.total_duly_filled_applications,
                total_fallouts: res.data.total_fallouts,
                total_prospects: res.data.total_prospects,
            });
        });
    }, [screens.prospect.isMutated, showAll]);
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("MenuData"));
        if (data) {
            dispatch(onProspectMenuChange(data));
        }
    }, []);

    return (
        <>
            <Header
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                setSelectedColumns={setSelectedColumns}
                setHeader={setHeader}
                setFilledForm={setFilledForm}
                setStatus={setStatus}
                count={count}
                setComponentName={setComponentName}
                setCurrentPage={setCurrentPage}
                setPage={setPage}
                setShowAll={setShowAll}
                setSelectedUsers={setSelectedUsers}
            />
            <FilterBar
                showAll={showAll}
                setShowAll={setShowAll}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                setKeyword={setKeyword}
                keyword={keyword}
                header={header}
                selectedUsers={selectedUsers}
                setKebabMenuOption={setKebabMenuOption}
                componentName={componentName}
                setDullyKebabMenuOption={setDullyKebabMenuOption}
            />
            <Body
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                response={response}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                page={page}
                setPage={setPage}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                filledForm={filledForm}
                dulyKebabMenuOption={dulyKebabMenuOption}
                setDullyKebabMenuOption={setDullyKebabMenuOption}
                showAll={showAll}
                status={status}
            />
        </>
    );
};

export default Prospect;
