import React from "react";
//components
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";

//styles
import "../../Deposit/deposit.scss";

const AddNewDeposit = ({ onHide }) => {
    const validationSchema = Yup.object().shape({
        date: Yup.string().required("This field is required"),
        receiving_account: Yup.string().required("This field is required"),
        invoice: Yup.string().required("This field is required"),
        sender: Yup.string().required("This is required"),
        type: Yup.string().required("This is required"),
        attachment: Yup.string().required("This is required"),
        description: Yup.string().required("This is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            amount: "",
            date: "",
            receiving_account: "",
            invoice: "",
            sender: "",
            status: "",
            type: "",
            attachment: "",
            description: "",
        },
        onSubmit: async (data) => {
            console.log(data);
            return;
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const receivingAccountoption = [
        { name: "Account1", value: "a" },
        { name: "Account2", value: "b" },
        { name: "Account3", value: "c" },
    ];
    const invoiceOption = [
        { name: "2321", value: 1 },
        { name: "1023", value: 2 },
        { name: "9872", value: 3 },
    ];
    const senderOption = [
        { name: "Corporation", value: 1 },
        { name: "LLC", value: 2 },
        { name: "Patnership", value: 3 },
        { name: "Sole Proprietorship", value: 4 },
    ];
    const statusOption = [
        { name: "Pending", value: 1 },
        { name: "Approved", value: 2 },
    ];
    const paymentTypeOption = [
        { name: "USD", value: 1 },
        { name: "Euro", value: 2 },
    ];
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="step-form-top-padding">
                <div className="grid ">
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="amount" className="step-fields-title">
                            Deposit Amount
                        </label>
                        <InputText
                            name="amount"
                            id="amount"
                            type="text"
                            placeholder="$00.00"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            className="mt-2"
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="md:col-4  col-12 row-margin-bottom">
                        <label htmlFor="date" className={classNames({ "p-error": isFormFieldValid("date") }, "step-fields-title")}>
                            Deposit Date *
                        </label>
                        <Calendar
                            id="date"
                            showIcon
                            placeholder="08/10/2020"
                            mask="99/99/9999"
                            value={formik.values.date}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("date") }, "mt-2")}
                            onBlur={formik.handleBlur}
                            monthNavigator
                            yearNavigator
                            yearRange="1945:2050"
                        />
                        {getFormErrorMessage("date")}
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="receiving_account" className={classNames({ "p-error": isFormFieldValid("receiving_account") }, "step-fields-title")}>
                            Receiving Account *
                        </label>
                        <Dropdown
                            id="receiving_account"
                            name="receiving_account"
                            value={formik.values.receiving_account}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("receiving_account") }, "mt-2")}
                            options={receivingAccountoption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                        {getFormErrorMessage("receiving_account")}
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="invoice" className={classNames({ "p-error": isFormFieldValid("invoice") }, "step-fields-title")}>
                            Deposit Against Invoice *
                        </label>
                        <Dropdown
                            id="invoice"
                            name="invoice"
                            value={formik.values.invoice}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("invoice") }, "mt-2")}
                            options={invoiceOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                        {getFormErrorMessage("invoice")}
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="sender" className={classNames({ "p-error": isFormFieldValid("sender") }, "step-fields-title")}>
                            Sender Name *
                        </label>
                        <Dropdown
                            id="sender"
                            name="sender"
                            value={formik.values.sender}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("sender") }, "mt-2")}
                            options={senderOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                        {getFormErrorMessage("sender")}
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="status" className="step-fields-title">
                            Status *
                        </label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className="mt-2"
                            options={statusOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="type" className={classNames({ "p-error": isFormFieldValid("type") }, "step-fields-title")}>
                            Payment Type *
                        </label>
                        <Dropdown
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("type") }, "mt-2")}
                            options={paymentTypeOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                        {getFormErrorMessage("type")}
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="attachment" className={classNames({ "p-error": isFormFieldValid("attachment") }, "step-fields-title")}>
                            Attachment *
                        </label>
                        <InputText
                            name="attachment"
                            id="attachment"
                            type="file"
                            placeholder="Attachment"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.attachment}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("attachment") }, "mt-2")}
                            onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("attachment")}
                    </div>
                    <div className="col-12">
                        <InputTextarea
                            rows={7}
                            name="description"
                            id="description"
                            type="text"
                            placeholder="Description *"
                            className={classNames({ "p-invalid": isFormFieldValid("description") }, "mt-2 customTextarea")}
                        />
                        {getFormErrorMessage("description")}
                    </div>
                </div>
                <div class="cancel_save_btn mt-4">
                    <Button
                        label="Cancel"
                        className="btn btn-default"
                        onClick={(e) => {
                            e.preventDefault();
                            onHide();
                        }}
                    />

                    <Button
                        label="Save"
                        type="submit"
                        className="btn btn-default savebtn"
                        //  onClick={handleSaveAndNext}
                    />
                </div>
            </form>
        </>
    );
};

export default AddNewDeposit;
