import moment from "moment";
import { DATE_TIME_FORMAT, getContactMask } from "../Constants/global";
import { currencyFormatUSD } from "./checkAmount";
import React from "react";

export const getColumnBody = (colField) => (rowData) => {
    if (colField === "createdBy") {
        return (rowData.cu_last_name ? rowData.cu_last_name : "") + " " + (rowData.cu_first_name ? rowData.cu_first_name : "");
    } else if (colField === "created_at" || colField === "requested_date" || colField === "updated_at") {
        return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "";
    } else if (colField === "assignedto") {
        return rowData.au_first_name || rowData.au_last_name ? (rowData.au_last_name ? rowData.au_last_name : "") + " " + (rowData.au_first_name ? rowData.au_first_name : "") : "Unassigned";
    } else if (colField === "resolution_date") {
        return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "Unresolved";
    } else if (colField === "action_date") {
        return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "Pending for Action";
    } else if (colField === "schedule_no") {
        if (rowData.schedule_no !== null || rowData.schedule_no !== "") {
            return rowData.schedule_no;
        } else {
            return "N/A";
        }
    } else if (colField === "funding_date") {
        if (rowData.funding_date !== null) {
            if (rowData.funding_date !== null || rowData.funding_date !== "") {
                return rowData.funding_date;
            } else {
                return "N/A";
            }
        }
    } else if (colField === "phone") {
        let phone_mask = getContactMask(rowData.phone_code, rowData.phone);
        return phone_mask;
    } else if (
        colField === "credit_limit" ||
        colField === "available_credit_limit" ||
        colField === "amount" ||
        colField === "actual_cb" ||
        colField === "cb" ||
        colField === "adjusted_cb" ||
        colField === "invoice_amount" ||
        colField === "advance_amount" ||
        colField === "pending_amount" ||
        colField === "current_credit_limit" ||
        colField === "requested_amount" ||
        colField === "new_limit_amount" ||
        colField === "reserved_amount" ||
        colField === "release_amount" ||
        colField === "tax_per_day"
    ) {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0)}</div>;
    }
    return rowData[colField] ? rowData[colField] : "";
};
