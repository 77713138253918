import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// Prime React
import { Button } from "primereact/button";
// Icons
import { BsCheckLg, BsPaperclip, BsPlus, BsXLg } from "react-icons/bs";

// Context
import { ToastContext } from "../../../context/toast";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onFormDirty, onProspectAttachment, onProspectDataChange, onProspectIndexChange, onProspectIsEditing, onProspectKnobValue } from "../../../redux/main-view";

// Constants
import { DOCUMENT_CHECKLIST, FILE_MAX_SIZE } from "../../../utils/Constants/global";

// UI Components
import CustomLoading from "../../CustomSpinner/custom_spinner";
import CustomCancelbtn from "../../CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../CustomAddNewButton/CustomAddNewButton";

// API'S Handler
import { deleteProspectAttachment, saveProspect, saveProspectAttachment } from "../../../services/prospect";
import { loginState } from "../../../redux/login";
import { checkPathname } from "../../../utils/HelperFuctions/getPathNameForProspect";
import CustomConfirmDialog from "../../CustomConfirmDialog/CustomConfirmDialog";
import appUrl from "../../../utils/Constants/appUrl";
import { postData } from "../../../services/genaric/genaric-api";
import { openAttachment } from "../../../utils/HelperFuctions/openAttachment";

export const DocumentChecklist = (props) => {
    const { sendNextStep, frontline_color } = props;

    // use  location to get the pathname
    const location = useLocation();

    // UseRef
    const fileInputRef = useRef(null);

    //Dispatch
    const dispatch = useDispatch();
    const history = useHistory();

    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    // Context
    const toast = useContext(ToastContext);

    // State
    const [type, setType] = useState("");
    const [formArray, setFormArray] = useState({
        invoices_to_factor: [],
        customer_list_with_addresses: [],
        accounts_receivable_aging: [],
        current_financial_statements: [],
        accounts_payable_aging: [],
        bank_authorization_form: [],
        tax_authorization_form: [],
        tax_returns: [],
        liability_insurance_certificate: [],
        articles_of_incorporation_or_assumed_name_certificate: [],
        copy_of_941s_with_proof_of_payment: [],
        sample_copy_of_invoice: [],
        workers_comp_insurance: [],
        copy_of_current_PACA_license: [],
        copy_of_applicant_driver_license: [],
    });

    // View State
    const [isLoad, setIsLoad] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Handlers
    const handlePrevious = () => {
        sendNextStep(5);
    };

    const handleIconClick = (type) => {
        fileInputRef.current.click();
        setType(type);
    };

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const fileSize = formArray[type] ? formArray[type].length : 0;

        let errorFlag = false;
        if (fileSize + newFiles.length <= 4) {
            const form = new FormData();
            form.append("prospect_id", screens.prospect.data.id);
            form.append("type", type);
            newFiles.map((item, index) => {
                const fileType = newFiles[index].name.split(".");
                const accept = ".pdf,.doc,.docx,.xlsx,.xls,.jpg,.png,.jpeg";
                const check_file = accept.includes(fileType[fileType.length - 1]);
                if (check_file) {
                    if (newFiles[index].size > FILE_MAX_SIZE.FILE_SIZE) {
                        errorFlag = true;
                        toast.showMessage("Exceeding Limit", `${newFiles[index]?.name} size is greater than allowed size (3MB).`, "error");
                    } else if (newFiles[index]?.name.includes("#") || newFiles[index]?.name.includes("&")) {
                        toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                    } else {
                        form.append("attachment[]", newFiles[index]);
                    }
                } else {
                    errorFlag = true;
                    toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                }
            });
            const pathname = location.pathname;
            const isFound = checkPathname(pathname);
            if (!isFound) {
                form.append("company_id", user?.company_id);
            }
            if (!errorFlag) fileSavingHandler(form);
        } else {
            toast.showMessage("Exceeding Limit", "You are only authorized to upload maximum 4 files.", "error");
            fileInputRef.current.value = null;
        }
    };

    const openDocument = async (blobName) => {
        // Perform the action to open the document
        // if (blobName) {
        //     window.open(appUrl.imageBaseUrl + blobName, "_blank");
        // }

        setIsLoad(true);
        try {
            await openAttachment(blobName);
        } catch (error) {
            console.error("Error opening attachment:", error);
        } finally {
            setIsLoad(false);
        }

        // openAttachment(blobName, isLoad);

        // try {
        //     const response = await postData(
        //         "blob/fetch",
        //         {
        //             blobName: blobName,
        //         },
        //         true,
        //         "blob"
        //     );

        //     if (response.status !== 200) {
        //         throw new Error("Network response was not ok");
        //     }

        //     const blob = new Blob([response.data], { type: response.headers["content-type"] });
        //     const url = window.URL.createObjectURL(blob);
        //     window.open(url, "_blank");
        // } catch (error) {
        //     console.error("Error downloading blob:", error);
        // } finally {
        // }
        // setIsLoad(false);
    };

    const handleDelete = (attachment, column_name) => {
        setShowConfirmDeleteDialog(true);
        setDeleteAttachment({
            path: appUrl.imageBaseUrl + attachment,
            column: column_name,
            prospect_id: screens.prospect.data.id,
        });
    };

    const confirmHandler = async () => {
        setIsDeleting(true);
        try {
            let resp = await deleteProspectAttachment(deleteAttachment);

            if (resp.data.status) {
                setShowConfirmDeleteDialog(false);
                setFormArray(resp.data.prospect_attachment);
                dispatch(onProspectAttachment(resp.data.prospect_attachment));
                toast.showMessage("File Deleted", "File has been deleted successfully", "success");
            } else {
                toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsDeleting(false);
        } catch (error) {
            setIsDeleting(false);
            toast.showMessage("Server Error", "Server error.", "error");
        }
    };

    const saveHandler = async () => {
        try {
            setIsSaving(true);
            setIsLoading(true);
            let payload = {
                id: screens.prospect.data.id,
                application_completely_filled: true,
                step: "6",
            };
            const pathname = location.pathname;
            const isFound = checkPathname(pathname);
            if (!isFound) {
                payload["company_id"] = user?.company_id;
            }
            let res = await saveProspect(payload);

            if (res) {
                if (res.data.status) {
                    dispatch(onProspectIndexChange(0));
                    dispatch(onProspectKnobValue(0));
                    dispatch(onProspectDataChange(null));
                    dispatch(onProspectAttachment(null));
                    dispatch(onProspectIsEditing(false));
                    dispatch(onFormDirty(false));
                    setIsSaving(false);
                    setIsLoading(false);

                    history.push("/prospectindex");
                    const isEditing = localStorage.getItem("isEditing");
                    localStorage.removeItem("isEditing");
                    if (isEditing) {
                        toast.updateToast("Prospect");
                    } else {
                        toast.showMessage("Submission Success", "Your request has been received successfully. Our representative will contact you shortly", "success");
                    }
                } else {
                    setIsSaving(false);
                    setIsLoading(false);
                    toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            } else {
                setIsSaving(false);
                setIsLoading(false);
                toast.showMessage("Error", "Server Error", "error");
            }
        } catch (error) {
            setIsSaving(false);
            setIsLoading(false);
            toast.showMessage("Error", "Server Error", "error");
        }
    };

    // API Hanlder's
    const fileSavingHandler = async (form) => {
        try {
            setIsLoad(true);
            let res = await saveProspectAttachment(form);
            if (res) {
                if (res.data.status) {
                    setFormArray(res.data.prospect_attachment);
                    dispatch(onProspectAttachment(res.data.prospect_attachment));
                    toast.showMessage("File Upload", "File has been uploaded successfully", "success");
                } else {
                    toast.showMessage("Error", res.data.message, "error");
                }
            } else {
                setIsLoad(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsLoad(false);
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            setIsLoad(false);
        }
    };

    // UseEffects
    useEffect(() => {
        if (screens.prospect.attachments) {
            setFormArray(screens.prospect.attachments);
        } else {
            if (screens.prospect.data?.support_attachments) {
                setFormArray(screens.prospect.data.support_attachments);
            }
        }
    }, []);

    useEffect(() => {
        console.log(formArray);
    }, []);

    return (
        <>
            <div className={frontline_color ? "frontLine_scroll" : "documents_step"}>
                {isLoad ? (
                    <CustomLoading top={"300px"} />
                ) : (
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <div className="documents_attachment_border">
                                <div className="grid">
                                    <div className="col-12 md:col-12">
                                        {DOCUMENT_CHECKLIST.map((document, index) => (
                                            <div className="document_type mt-2" key={index}>
                                                <div className="flex flex-row justify-content-between">
                                                    <p className="pt-2">
                                                        <b>{document.title}</b>
                                                    </p>
                                                    {frontline_color ? (
                                                        <Button className="upload_fronLineSavebtn" icon={<BsPlus />} onClick={() => handleIconClick(document.value)} />
                                                    ) : (
                                                        <Button className="uploadfile_button_plus" icon={<BsPlus />} onClick={() => handleIconClick(document.value)} />
                                                    )}
                                                    <input
                                                        accept=".pdf,.doc,.docx,.xlsx,.xls,.jpg,.png,.jpeg"
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: "none" }}
                                                        onChange={handleFileChange}
                                                        multiple
                                                    />
                                                </div>
                                                <div>
                                                    {formArray[document?.value] !== null &&
                                                        formArray[document?.value].map((item, index) => {
                                                            const result = formArray[document.value][index].split("/");
                                                            return (
                                                                <p className="attachment_text mt-3">
                                                                    <div key={index} className="flex flex-row align-items-center ">
                                                                        <span className="mr-2 mt-1">
                                                                            <BsPaperclip />
                                                                        </span>

                                                                        <span className="attach_span" onClick={() => openDocument(formArray[document.value][index])}>
                                                                            {result[result.length - 1]}
                                                                        </span>
                                                                        <span className="mt-2" onClick={() => handleDelete(formArray[document.value][index], document.value)}>
                                                                            <BsXLg className="bs_Cros_img" />
                                                                        </span>
                                                                    </div>
                                                                </p>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => confirmHandler()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}

            <div className="action-btns-prospect-form pt-3 pr-4">
                <CustomCancelbtn title="Previous" type="button" onClick={handlePrevious} />
                {frontline_color ? (
                    <Button label="Submit" className="fronLineSavebtn" type="button" onClick={() => (isSaving ? null : saveHandler())} disabled={isSaving} />
                ) : (
                    <CustomAddNewButton title="Submit" type="button" onClick={() => (isSaving ? null : saveHandler())} isDisabled={isSaving} isLoading={isLoading} />
                )}
            </div>
        </>
    );
};
