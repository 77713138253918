import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export  async function getChargebacks(data) {
    return postData(appUrl["cb"].fetch, data);
}

export  async function adjustChargebacks(data) {
    return postData(appUrl["cb"].adjust, data);
}

export  async function sumChargebacks() {
    return postData(appUrl["cb"].sum);
}