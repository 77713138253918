export function imageTypeCheck(value) {
    const fileExtensionRegex = /\.(\w+)$/; // This regex matches the file extension after the last dot
    const matches = value.match(fileExtensionRegex);

    if (matches[0].toLowerCase() === ".xlsx") {
        return "xlsx";
    } else if (matches[0].toLowerCase() === ".xls") {
        return "xls";
    } else {
        if (matches && matches.length > 1) {
            const fileType = matches[0].toLowerCase();
            const types = ".png, .jpg, .jpeg";
            const result = types.includes(fileType);
            return result;
        } else {
            return false;
        }
    }
}
