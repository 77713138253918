import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import KebabMenuComponent from "../../../../../../../ui/KebabMenu/KebabMenuComponent";
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";

// Constants
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";

// Redux
import { loginState } from "../../../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { Checkbox } from "primereact/checkbox";
import Legends from "../../../../../../../ui/Legends/Legends";

// Redux

const FilterBar = (props) => {
    const { showAll,setShowAll,selectedColumns, setSelectedColumns, header, keyword, setKeyword, selectedReserves,componentName } = props;

    const history = useHistory();

    // State
    const [onSelectionColumn, setOnSelectionColumn] = useState([]);
    const [kebabMenuItems, setKebabMenuItems] = useState([]);
    const [prospectId, setProsectId] = useState("");

    // Redux
    const { user } = useSelector(loginState);

    // Handlers
    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedReserves?.length !== 0) {
            let batch = selectedReserves.map((item) => item.id);
            history.push({ pathname: "/createrrbatch", state: { id: `${batch.join(",")}`,prospectId :`${prospectId}` } });
            // history.push("/createrrbatch?prospectId=" + prospectId + "&id=" + batch.join(","));
        }
    };

    const extractUniqueReserves = () => {
        let prospect_ids = selectedReserves.map((item) => item.prospect_id);
        let unique = prospect_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        return unique;
    };

    const onKebabMenuHandler = () => {
        if (selectedReserves?.length > 0 && user) {
            let unique = extractUniqueReserves();

            let isDisabled = false;
            if (unique?.length == 1) {
                setProsectId(unique[0]);
                isDisabled=false
            } else {
                isDisabled = true;
            }

            const kebab_menu_items = [{ title: "Create RR Batch", status: 1, isDisabled: isDisabled, required_rights: [TRANSACTION_RIGHTS.reserves.create_rr_batch] }];
            setKebabMenuItems(kebab_menu_items);
        }
        else {
            
            const kebab_menu_items = [{ title: "Create RR Batch", status: 1, isDisabled: true, required_rights: [TRANSACTION_RIGHTS.reserves.create_rr_batch] }];
            setKebabMenuItems(kebab_menu_items);
        }
    };

    const onKebabMenuRightsBasedHandler = () => {
        if (!user) return;

        let isDisabled = true;
        if (selectedReserves?.length > 0 && user) {
            let unique = extractUniqueReserves();

            if (unique?.length == 1) {
                setProsectId(unique[0]);
                isDisabled=false
            } else {
                isDisabled = true;
            }
        }

        const kebab_menu_items = [{ title: "Create RR Batch", status: 1, isDisabled: isDisabled, required_rights: [TRANSACTION_RIGHTS.reserves.create_rr_batch] }];
        const updated_items = kebab_menu_items.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);
            if (isAllowed) {
                return col;
            }
        });

        setKebabMenuItems(updated_items);
    }

    //UseEffect
    useEffect(() => {
        const temp = [];
        selectedColumns.filter((col) => {
            temp.push(col.field);
        });
        setOnSelectionColumn(temp);
    }, [header]);

    useEffect(() => {
        onKebabMenuHandler();
    }, [selectedReserves]);

    useEffect(() => {
        onKebabMenuRightsBasedHandler()
    }, [user]);

    return (
        <div className="grid">
            <div className="md:col-7 col-12">
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include terminated reserve
                        </label>
                        {showAll === 1 && (
                                <>
                                    <div className="mt-1 ml-3">
                                        <Legends classes="square-legend-delete" title="Deleted" />
                                    </div>
                                    <div className="mt-1 ml-2">
                                        <Legends classes="square-legend-terminate" title="Terminated" />
                                    </div>
                                </>
                            )}
                    </div>
                </div>

            <div className="md:col-5 col-12 pt-3">
                <div className="flex flex-row">
                    <SearchInputComponent
                        value={keyword}
                        setKeyword={setKeyword}
                        placeholder={`Search by invoice no, client name, ${componentName == "reserve_releases" ? "reserve release date" : "funding date"}`}
                    />
                    <div className="ml-2">
                        <ColumnFilterComponent items={header} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                    </div>
                    {componentName === "cash_reserves" && (
                        <div className="ml-2">{kebabMenuItems.length > 0 && <KebabMenuComponent items={kebabMenuItems} handleMenuOpen={(e) => handleOpenMenuItems(e)} />}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FilterBar;
