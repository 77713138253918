import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
//ui-components
import CustomLoading from "../../../../../../../ui/CustomSpinner/custom_spinner";
// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";

// Helper Function

import { getInvoiceColumnBody } from "../../../../../../../utils/HelperFuctions/invoiceHelper";
import MoveToScheduleComponent from "../../Components/MoveToScheduleComponent";
import { PaginatorTemplate } from "../../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";

// Util
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";

// Components

const Body = (props) => {
    const {
        selectedHeaders,
        selectedInvoices,
        setSelectedInvoices,
        selectedColumns,
        setSelectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        kebabMenuOption,
        setKebabMenuOption,
        setPage,
        page,
        fetchInvoices,
        setDialogCheck,
    } = props;

    //Use ref
    const ref = useRef(null);

    // use History
    const history = useHistory();

    // States
    // const [page, setPage] = useState(currentPage);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Redux
    const { user } = useSelector(loginState);

    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Checking Funding Date
        setDialogCheck(false);
        if (event?.value?.length > 0) {
            let prospectID = event?.value[0]?.prospect_id;
            event.value.map((item) => {
                if (item.funding_date) {
                    setDialogCheck(true);
                } else if (item.schedule_no) {
                    setDialogCheck(true);
                } else if (prospectID !== item.prospect_id) {
                    setDialogCheck(true);
                }
            });
        }

        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedInvoices(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions([TRANSACTION_RIGHTS.invoices.invoice_selection], user?.rights)) return;

        // Row Selection
        if (event.type === "row") {
            setSelectedRowId(event.data.id);
            history.push({ pathname: "/addnewinvoice", state: { id: `${event.data.id}` } });
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const index = selectedColumns.findIndex((item) => item["field"] === "legal_name");
    if (selectedColumns && user && !user.debtor_id && index < 0) {
        selectedColumns.splice(3, 0, { field: "legal_name", header: "Debtor Name" });
        setSelectedColumns(selectedColumns);
    }
    

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getInvoiceColumnBody(col.field)}
                sortable
                headerClassName={`${col.field === "invoice_amount" ? "amount_column_alignment" : ""}`}
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.deleted) {
            return "delete_row_color";
        } else if (rowData.is_terminated) {
            return "terminated_row_color";
        }
        return "";
    };

    return (
        <>
            <div className="col-12 p-0">
                {loading ? (
                    <CustomLoading />
                ) : (
                    <div className="card">
                        <DataTable
                            ref={ref}
                            value={selectedHeaders}
                            selection={selectedInvoices.length === 0 ? null : selectedInvoices}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    </div>
                )}
            </div>
            <Dialog
                visible={kebabMenuOption.move_to_schedule}
                onHide={() => {
                    setKebabMenuOption({
                        move_to_schedule: false,
                    });
                    setSelectedInvoices([]);
                }}
                header="Move to Schedule"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                <MoveToScheduleComponent
                    invoices={selectedInvoices}
                    onHide={() => {
                        setKebabMenuOption({
                            move_to_schedule: false,
                        });
                        setSelectedInvoices([]);
                    }}
                    fetchInvoices={fetchInvoices}
                />
            </Dialog>
        </>
    );
};

export default Body;
