import React, { useState, useRef, useContext, useEffect } from "react";

// Prime React
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

//styles
import moment from "moment";
//global js
import { DATE_TIME_FORMAT, PROSPECT_UNDER_WRITING_GRID_HEADERS, PROSPECT_UNDER_WRITING_GRID_HEADERS_ADMIN, ROLE_NAME } from "../../../../utils/Constants/global";
import { openAttachment } from "../../../../utils/HelperFuctions/openAttachment";
//context
import { ToastContext } from "../../../../context/toast";
//svgs
import Document from "../../../../svgs/Document/Document";
//ui-components
import CustomModalSpinner from "../../../../ui/CustomModalSpinner/CustomModalSpinner";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import { deleteClientUnderwritingAttachments, getUniqueClientAttachmentType } from "../../../../services/client";
import { extractNameFromURL } from "../../../../utils/HelperFuctions/getFileName";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg, BsTrash3 } from "react-icons/bs";
import { loginState } from "../../../../redux/login";
import { useSelector } from "react-redux";
import appUrl from "../../../../utils/Constants/appUrl";

const ViewUWDocumentModal = (props) => {
    const { id, selectedAttachmentType, onHide, handleFetchDocuments } = props;
    // Context
    const toast = useContext(ToastContext);
    const { user } = useSelector(loginState);

    // UseRef
    const dt = useRef(null);

    // States
    const [loading, setLoading] = useState(false);
    const [openAttachmentLoading, setOpenAttachmentLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({});

    const openDocument = async (attachment) => {
        if (attachment) {
            setOpenAttachmentLoading(true);
            try {
                await openAttachment(attachment);
            } catch (error) {
                console.error("Error opening attachment:", error);
            } finally {
                setOpenAttachmentLoading(false);
            }
            // window.open(appUrl.imageBaseUrl + attachment, "_blank");
        }
    };

    const headerDataBdoy = (colField) => (rowData) => {
        if (colField === "attachment_path") {
            return (
                <>
                    <div className={` mr-2  ml-3  ${rowData.attachment_path ? "document_icon_background" : "no_doc"}`} onClick={() => openDocument(rowData.attachment_path)}>
                        {rowData.attachment_path ? <Document height="15" width="15" t /> : "No Attachment"}
                    </div>
                </>
            );
        } else if (colField === "created_at") {
            return rowData[colField] ? <div className="p-3">{moment(rowData[colField]).format(DATE_TIME_FORMAT)}</div> : "";
        } else if (colField === "name") {
            return <div className="p-3"> {extractNameFromURL(rowData.attachment_path)}</div>;
        } else if (colField === "action") {
            return (
                <>
                    <div className={` mr-2 mt-1`} style={{ marginLeft: "1rem" }} onClick={() => handleDelete(rowData.id)}>
                        {rowData.id ? <BsTrash3 style={{ color: "red", fontSize: "16px", cursor: "pointer" }} /> : "No Attachment"}
                    </div>
                </>
            );
        } else {
            return rowData[colField] ? rowData[colField] : "";
        }
    };

    const columnComponents = (user?.userRoles[0]["roleName"] === ROLE_NAME.ADMIN ? PROSPECT_UNDER_WRITING_GRID_HEADERS_ADMIN : PROSPECT_UNDER_WRITING_GRID_HEADERS).map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={headerDataBdoy(col.field)}></Column>;
    });

    const handleDelete = (id) => {
        setShowConfirmDeleteDialog(true);
        setDeleteAttachment({
            id: id,
        });
    };

    const deleteDocuments = async () => {
        setIsDeleting(true);
        try {
            let data = {
                id: deleteAttachment.id,
            };
            const res = await deleteClientUnderwritingAttachments(data);
            if (res.data.status) {
                toast.showMessage("Success", "Attachment has been deleted.", "success");
                onHide();
                handleFetchDocuments();
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setIsDeleting(false);
    };
    const handleFetchDocument = async () => {
        setLoading(true);
        try {
            let data = {
                prospect_id: id,
                attachment_type: selectedAttachmentType,
            };
            const res = await getUniqueClientAttachmentType(data);
            if (res.data.status) {
                console.log(res.data.documents);
                setDocuments(res.data.documents);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        handleFetchDocument();
    }, []);
    return (
        <>
            {loading ? (
                <CustomModalSpinner height={"70px"} />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 custom_scrollbar_fieldsetting">
                            <DataTable ref={dt} value={documents} responsiveLayout="scroll" id="underwriting-document" emptyMessage="No record available.">
                                {columnComponents}
                            </DataTable>
                        </div>
                    </div>
                </>
            )}

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => deleteDocuments()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}

            {openAttachmentLoading && <CustomLoading center />}
        </>
    );
};

export default ViewUWDocumentModal;
