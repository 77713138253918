import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Prime React
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { BsCheckLg } from "react-icons/bs";

// Context
import { ToastContext } from "../../../../../context/toast";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { onProspectDataMutated, onProspectIndexChange, onProspectIsEditing } from "../../../../../redux/main-view";

// Helper Function
import { ProspectColumnBody } from "../../../../../utils/HelperFuctions/prospectHelper";
import { PaginatorTemplate } from "../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import CustomPromptMesage from "../../../../../ui/CustomPromptMesage/index";

// UI Components
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import PromoteClientModal from "../../Components/ChangeStatus/PromoteClient";
import ChangeStatus from "../../Components/ChangeStatus/ChangeStatus";

// API'S
import { changeProspectStatus, deleteProspect, unSavedProspectSendEmail } from "../../../../../services/prospect";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { loginState } from "../../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

const Body = (props) => {
    const {
        selectedUsers,
        setSelectedUsers,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        response,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        page,
        setPage,
        loading,
        kebabMenuOption,
        setKebabMenuOption,
        filledForm,
        dulyKebabMenuOption,
        setDullyKebabMenuOption,
        showAll,
        status,
    } = props;

    //Use Ref
    const dt = useRef(null);
    // Context
    const toast = useContext(ToastContext);
    // Redux
    const { user } = useSelector(loginState);
    //Dispatch
    const dispatch = useDispatch();
    const history = useHistory();
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [isSaving, setIsSaving] = useState(false);
    const [checkClientPromotion, setCheckClientPromotion] = useState(false);
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedUsers(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions([MANAGMENT_RIGHTS.prospects.selection], user?.rights)) return;
        if (!validateUserPermissions([MANAGMENT_RIGHTS.prospects.profile, MANAGMENT_RIGHTS.prospects.business_verification, MANAGMENT_RIGHTS.prospects.termsheet], user?.rights)) return;

        // Row Selection
        if (event.type === "row") {
            dispatch(onProspectIndexChange(1));
            dispatch(onProspectIsEditing(filledForm));
            // Handle Refreshing Case
            localStorage.setItem("isEditing", filledForm);
            history.push({ pathname: "/addprospect", state: { id: `${event.data.id}` } });
            // history.push(`/addprospect?id=${event.data.id}`);
        }
    };

    const confirmHandler = async () => {
        const temp = [];
        selectedUsers.map((item) => {
            temp.push(item.id);
        });
        setIsSaving(true);
        const payload = {
            ids: temp,
            status: 2,
        };
        let res = await changeProspectStatus(payload);
        if (res) {
            if (res.data.status) {
                setKebabMenuOption({
                    promote_to_client: false,
                    demote_to_fallout: false,
                    delete: false,
                });
                dispatch(onProspectDataMutated());
                setSelectedUsers([]);
                setIsSaving(false);
                toast.showMessage("Fallout", "Prospect has been updated successfully", "success");
            }
        } else {
            setIsSaving(false);
            toast.showMessage("Error", "Server error.", "error");
        }
    };
    const deleteHandler = async () => {
        const temp = [];
        selectedUsers.map((item) => {
            temp.push(item.id);
        });
        setIsSaving(true);
        const payload = {
            id: temp,
        };
        let res = await deleteProspect(payload);
        if (res) {
            if (res.data.status) {
                setKebabMenuOption({
                    promote_to_client: false,
                    demote_to_fallout: false,
                    delete: false,
                });
                dispatch(onProspectDataMutated());
                setSelectedUsers([]);
                setIsSaving(false);
                toast.showMessage("Prospect Deleted", "Prospect has been deleted successfully", "success");
            }
        } else {
            setIsSaving(false);
            toast.showMessage("Error", "Server error.", "error");
        }
    };
    const emailSendHandler = async () => {
        const temp = [];
        selectedUsers.map((item) => {
            temp.push(item.id);
        });
        setIsSaving(true);
        const payload = {
            id: temp,
        };

        const res = await unSavedProspectSendEmail(payload);
        if (res) {
            if (res.data.status) {
                setDullyKebabMenuOption({
                    change_status: false,
                    email_to_prospect: false,
                });

                setSelectedUsers([]);
                setIsSaving(false);
                toast.showMessage("Duly Filled Application", "Reminder email has been sent to the prospect(s).", "success");
            }
        } else {
            setIsSaving(false);
            toast.showMessage("Error", "Server error.", "error");
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const getRowClassName = (rowData) => {
        if (rowData.deleted) {
            return "delete_row_color";
        }
        return "";
    };
    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={ProspectColumnBody(col.field)}
                headerClassName={`${col.field === "funding_amount" || col.field === "factoring_limit" || col.field === "active_credit_limit" ?"amount_column_alignment" : ""}`}
                sortable
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Paginations
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    return (
        <>
            <div className="col-12 p-0">
                {loading ? (
                    <CustomLoading />
                ) : (
                    <div className="card">
                        <DataTable
                            ref={dt}
                            value={response}
                            selection={selectedUsers.length === 0 ? null : selectedUsers}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            {!showAll && status !== 2 && <Column selectionMode="multiple"></Column>}
                            {columnComponents}
                        </DataTable>
                    </div>
                )}
            </div>
            <Dialog
                blockScroll={true}
                visible={kebabMenuOption.promote_to_client}
                onHide={() =>
                    setKebabMenuOption({
                        promote_to_client: false,
                        demote_to_fallout: false,
                        delete: false,
                    })
                }
                header="Promote to Client"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "30vw", textAlign: "center !important" }}
            >
                {kebabMenuOption.promote_to_client && (
                    <PromoteClientModal
                        selectedUsers={selectedUsers}
                        setCheckClientPromotion={setCheckClientPromotion}
                        onHide={() =>
                            setKebabMenuOption({
                                promote_to_client: false,
                                demote_to_fallout: false,
                                delete: false,
                            })
                        }
                    />
                )}
            </Dialog>

            {checkClientPromotion && (
                <CustomPromptMesage
                    header="Promote to Client"
                    type="error"
                    onHide={() => setCheckClientPromotion(false)}
                    handler={emailSendHandler}
                    icon={<BsCheckLg />}
                    title="Confirm"
                    firsttext="Please ensure following steps have been completed before client promotion."
                    optionOne="1. Business Name Search"
                    optionTwo="2. Signed Term Sheet(s)"
                />
            )}

            {kebabMenuOption.demote_to_fallout && (
                <CustomConfirmDialog
                    header="Demote to Fallout"
                    type="error"
                    onHide={() =>
                        setKebabMenuOption({
                            promote_to_client: false,
                            demote_to_fallout: false,
                            delete: false,
                        })
                    }
                    handler={() => (isSaving ? null : confirmHandler())}
                    icon={<BsCheckLg />}
                    isDisabled={isSaving}
                    title="Confirm"
                    firsttext="Are you sure, you want to demote prospect to fallout?"
                    secondtext="This action cannot be reverted."
                />
            )}
            {/* <BsTrash3/> */}
            {kebabMenuOption.delete && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() =>
                        setKebabMenuOption({
                            promote_to_client: false,
                            demote_to_fallout: false,
                            delete: false,
                        })
                    }
                    handler={isSaving ? null : () => deleteHandler()}
                    isDisabled={isSaving}
                    icon={<BsCheckLg />}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                    secondtext="This action cannot be reverted."
                />
            )}

            <Dialog
                blockScroll={true}
                visible={dulyKebabMenuOption.change_status}
                onHide={() =>
                    setDullyKebabMenuOption({
                        change_status: false,
                        email_to_prospect: false,
                    })
                }
                header="Change Status"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
            >
                {dulyKebabMenuOption.change_status && (
                    <ChangeStatus
                        selectedUsers={selectedUsers}
                        onHide={() =>
                            setDullyKebabMenuOption({
                                change_status: false,
                                email_to_prospect: false,
                            })
                        }
                    />
                )}
            </Dialog>

            {dulyKebabMenuOption.email_to_prospect && (
                <CustomConfirmDialog
                    header="Confirm Send"
                    type="success"
                    onHide={() =>
                        setDullyKebabMenuOption({
                            change_status: false,
                            email_to_prospect: false,
                        })
                    }
                    handler={emailSendHandler}
                    icon={<BsCheckLg />}
                    isDisabled={isSaving}
                    title="Confirm"
                    firsttext="Are you sure, you want to send the email?"
                    className="custom_confirm_dialog"
                />
            )}
        </>
    );
};

export default Body;
