import React, { useContext, useEffect, useRef, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../../../ui/CustomInputField/CustomInputField";
import { getInvoiceClientsDropdown } from "../../../../../../services/invoice";
import { ToastContext } from "../../../../../../context/toast";
import { getDebtors } from "../../../../../../services/debtor";
import { getCustomDropDown } from "../../../../../../services/dropdown";
import { postUCNonInvoice } from "../../../../../../services/collection";
import { AmountCheck } from "../../../../../../utils/HelperFuctions/checkAmount";
import { BsCheckLg, BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { Calendar } from "primereact/calendar";
import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";
import { Button } from "primereact/button";
import { FILE_MAX_SIZE } from "../../../../../../utils/Constants/global";

//Components

const UnAppliedcashModal = (props) => {
    // states
    const [dropdownClients, setDropdownClients] = useState([]);
    const [clientLoading, setClientLoading] = useState(false);
    const [clientSearch, setClientSearch] = useState("");
    const [debtorLoading, setDebtorLoading] = useState(true);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [paymentDropdowns, setPaymentDropdowns] = useState([]);
    const [dropdownsLoading, setDropdownsLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [checkNumberErrorLabel, setCheckNumberErrorLabel] = useState("");
    const [checkDateErrorLabel, setCheckDateErrorLabel] = useState("");
    const [checkDepositDateErrorLabel, setCheckDepositDateErrorLabel] = useState("");
    const [minPostingDate, setMinPostingDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenFileInput = useRef(null);

    const { onHide, onCancel, setFormDirty } = props;
    //toast message
    const toast = useContext(ToastContext);

    let validationSchema = Yup.object().shape({
        prospect_id: Yup.string().required("Client Name is required"),
        debtor_id: Yup.string().required("Debtor Name is required"),
        amount: Yup.string().required("Amount Received is required"),
        payment_type: Yup.string().required("Payment Type is required"),
        check_path: Yup.mixed().required("Attachment is required"),
        note: Yup.string().required("Comments is required").trim(),
        posting_date: Yup.string().required("Posting Date is required"),
        check_number: Yup.string().when("payment_type", {
            is: "0",
            then: Yup.string().required("Routing No is required"),
            otherwise: Yup.string().when("payment_type", {
                is: "1",
                then: Yup.string().required("Check No is required"),
                otherwise: Yup.string().when("payment_type", {
                    is: "2",
                    then: Yup.string().required("Transaction No is required"),
                    otherwise: Yup.string().required("This is required"),
                }),
            }),
        }),
        check_date: Yup.string().when("payment_type", {
            is: "0",
            then: Yup.string().required("ACH Date is required"),
            otherwise: Yup.string().when("payment_type", {
                is: "1",
                then: Yup.string().required("Check Date is required"),
                otherwise: Yup.string().when("payment_type", {
                    is: "2",
                    then: Yup.string().required("Wire Date is required"),
                    otherwise: Yup.string().required("This is required"),
                }),
            }),
        }),
        check_deposit_date: Yup.string().when("payment_type", {
            is: "0",
            then: Yup.string().required("ACH Deposit Date is required"),
            otherwise: Yup.string().when("payment_type", {
                is: "1",
                then: Yup.string().required("Check Deposit Date is required"),
                otherwise: Yup.string().when("payment_type", {
                    is: "2",
                    then: Yup.string(),
                    otherwise: Yup.string().required("This is required"),
                }),
            }),
        }),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            prospect_id: "",
            debtor_id: "",
            amount: "",
            payment_type: "",
            check_path: "",
            note: "",
            check_date: "",
            check_deposit_date: "",
            check_number: "",
            posting_date: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                setIsLoading(true);
                data = {
                    ...data,
                    check_date: data.check_date ? getDateFormat(data.check_date) : "",
                    posting_date: data.posting_date ? getDateFormat(data.posting_date) : "",
                };

                if (formik.values.check_deposit_date !== "") {
                    data["check_deposit_date"] = getDateFormat(formik.values.check_deposit_date);
                } else if (formik.values.check_deposit_date === "" && data.payment_type === 2) {
                    data["check_deposit_date"] = getDateFormat(formik.values.check_date);
                    delete data.check_date;
                }
                // form Data...
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    if (key === "check_path") {
                        formData.append("check_path", uploadedFile);
                    } else {
                        formData.append(key, data[key]);
                    }
                });
                let res = await postUCNonInvoice(formData);
                if (res.data.status) {
                    toast.createdToast("Unapplied cash");
                    onHide();
                }
            } catch (error) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsSaving(false);
            setIsLoading(false);
        },
    });

    // Validation
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (value === 0) {
            setCheckNumberErrorLabel("Routing");
            setCheckDateErrorLabel("ACH");
            setCheckDepositDateErrorLabel("ACH");
        } else if (value === 1) {
            formik.setFieldError("check_number", "");
            setCheckNumberErrorLabel("Check");
            setCheckDateErrorLabel("Check");
            setCheckDepositDateErrorLabel("Check");
        } else if (value === 2) {
            setCheckNumberErrorLabel("Transaction");
            setCheckDateErrorLabel("Wire");
        }
        formik.setFieldValue("check_date", "");
        formik.setFieldValue("check_deposit_date", "");
        formik.setFieldValue("posting_date", "");

        formik.handleChange(e);
    };
    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (value) {
            formik.setFieldValue(name, value);
        } else {
            formik.setFieldValue(name, "");
        }
        if (name === "check_date") {
            formik.setFieldValue("check_deposit_date", "");
        }
        if (name === "check_deposit_date") {
            formik.setFieldValue("posting_date", "");
        }
        if (name === "check_date" && formik.values.payment_type === 2) {
            setMinPostingDate(new Date(value));
        } else if (formik.values.payment_type !== 2 && name === "check_deposit_date") {
            setMinPostingDate(new Date(value));
        }
    };
    //DropDowns
    const handleDropdowns = async () => {
        setDropdownsLoading(true);
        let payload = {
            select: ["payment_type"],
        };
        try {
            getCustomDropDown(payload).then((res) => {
                if (res.data.status) {
                    setPaymentDropdowns(JSON.parse(res.data.dropdowns.payment_type));
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDropdownsLoading(false);
    };

    // fetch Debtors
    const fetchDebtors = async (keyword = null) => {
        setDebtorLoading(true);
        let payload = {
            prospect_id: formik.values.prospect_id,
            per_page: 100,
            search_text: keyword,
            status: 0,
        };
        try {
            let res = await getDebtors(payload);
            if (res.data.status) {
                setDropdownDebtors(res.data.debtors.data);
                if (res.data.debtors.data.length > 0) {
                    setDebtorLoading(false);
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    //UseEffect for client selection
    useEffect(() => {
        if (formik.values.prospect_id != "") {
            const prospect_id = formik.values.prospect_id;
            formik.resetForm();
            formik.setFieldValue("prospect_id", prospect_id);
            fetchDebtors();
        }
    }, [formik.values.prospect_id]);
    // fetch Clients

    const fetchClients = async (keyword = null) => {
        setClientLoading(true);
        let payload = {
            per_page: 50,
            search_text: keyword,
        };
        try {
            getInvoiceClientsDropdown(payload).then((res) => {
                if (res.data.status) {
                    if (res.data && res.data.prospects && res.data.prospects.data.length > 0) {
                        setDropdownClients(res.data.prospects.data);
                        setClientLoading(false);
                    }
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const onFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.name.split(".");
            const index = fileType.length - 1;
            const accept = ".jpg,.jpeg,.png,.pdf";
            const check_file = accept.includes(fileType[index]);

            if (check_file) {
                const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
                if (fileSize) {
                    toast.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size.", "error");
                    event.target.value = null;
                } else if (file.name.includes("#") || file.name.includes("&")) {
                    toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                } else {
                    setUploadedFile(file);
                    formik.setFieldValue("check_path", file);
                    formik.setFieldTouched("check_path", false);
                    formik.setFieldError("check_path", "");
                    event.target.value = null;
                }
            } else {
                event.target.value = null;
                formik.setFieldValue("check_path", "");
                formik.setFieldError("check_path", "Attachment is required");
                toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
            }
        } else {
            formik.setFieldValue("check_path", "");
            formik.setFieldError("check_path", "Attachment is required");
        }
    };
    const handleClientFilter = (event) => {
        setClientSearch(event.filter);
    };
    //  Doesn't allowing copy paste data in the fields
    const handlePaste = (event) => {
        const { name } = event.target;
        if (name === "amount") {
            event.stopPropagation();
        }
    };
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchClients(clientSearch.length === 0 ? null : clientSearch);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [clientSearch]);
    useEffect(() => {
        handleDropdowns();
    }, []);

    const handleCurrencyChange = (event, name) => {
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };
    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            onCancel();
        }
    };

    const handleAttachmentDelete = () => {
        setUploadedFile(null);
    };
    useEffect(() => {
        if (formik.dirty) {
            setFormDirty(true);
        } else {
            setFormDirty(false);
        }
        // if (formik.values.prospect_id !==) {
        //     setFormDirty(true);
        // }
    }, [formik.dirty]);

    const handleOpenUpload = () => {
        hiddenFileInput.current.click();
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <CustomInputField
                        className={`col-12 md:col-4 ${clientLoading && "no-drop"}`}
                        type="dropdown"
                        iden="prospect_id"
                        label="Client Name"
                        mandatory="*"
                        formik={formik}
                        optionLabel="company_business_name"
                        optionValue="id"
                        options={dropdownClients}
                        disabled={clientLoading}
                        filter={true}
                        handleFilter={handleClientFilter}
                        placeHolder="Select"
                    />
                    <CustomInputField
                        className={`col-12 md:col-4 ${debtorLoading && "no-drop"}`}
                        type="dropdown"
                        iden="debtor_id"
                        label="Debtor Name"
                        mandatory="*"
                        formik={formik}
                        optionLabel="debtor_name"
                        optionValue="id"
                        options={dropdownDebtors}
                        placeHolder="Select"
                        disabled={debtorLoading}
                    />
                    <CustomInputField
                        className="col-12 md:col-4"
                        iden="amount"
                        label="Amount Received"
                        mandatory="*"
                        formik={formik}
                        placeHolder="$0.00"
                        onPaste={(e) => handlePaste(e)}
                        onValueChange={(e) => handleCurrencyChange(e, "amount")}
                        type="currency"
                        min={0}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        max={99999999.99}
                    />
                    <CustomInputField
                        className={`col-12 md:col-6 ${dropdownsLoading && "no-drop"}`}
                        type="dropdown"
                        iden="payment_type"
                        label="Payment Type"
                        mandatory="*"
                        formik={formik}
                        optionLabel="name"
                        optionValue="value"
                        options={paymentDropdowns}
                        placeHolder="Select"
                        disabled={dropdownsLoading}
                        onChange={(e) => handleInputChange(e)}
                    />

                    <div className="col-12 md:col-6">
                        <label htmlFor="NameTitle">
                            <b>Attachment</b>
                            <span className="clr_red">*</span>
                            <Button
                                type="button"
                                tooltip=".pdf, .jpeg, .png, .jpg files only (max size 3MB) "
                                tooltipOptions={{ position: "bottom" }}
                                icon={<BsInfoCircle />}
                                aria-label="Submit"
                                className="customTooltipicon"
                            />
                        </label>
                        <div className="file_upload_class">
                            <label className={`flex flex-row justify-content-between align-items-center`} onClick={handleOpenUpload}>
                                {uploadedFile ? (
                                    <div className="flex row">
                                        <span className="text_break">{uploadedFile.name}</span>
                                        <BsXLg className="cursor-pointer cross_icon ml-1" onClick={() => handleAttachmentDelete()} />
                                    </div>
                                ) : (
                                    <> Choose File</>
                                )}
                                <BsPaperclip />
                            </label>
                        </div>
                        <input type="file" ref={hiddenFileInput} disabled={uploadedFile} id="file-upload" onChange={onFileSelect} accept=".pdf,.jpeg,.png,.jpg" style={{ display: "none" }} />
                        {getFormErrorMessage("check_path")}
                    </div>

                    {/* <div className="col-12 md:col-6" style={{ position: "relative" }}>
                        <CustomInputField label="Attachment" type="file" id="ucAttachment" formik={formik} onChange={onFileSelect} mandatory="*" />
                        {uploadedFile && <BsXLg onClick={() => handleAttachmentDelete()} className="attachment_crossed" />}
                        {getFormErrorMessage("check_path")}
                    </div> */}
                    {formik.values.payment_type === 0 || formik.values.payment_type ? (
                        <>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    label={formik.values.payment_type === 0 ? "Routing No" : formik.values.payment_type === 1 ? "Check No" : formik.values.payment_type === 2 ? "Transaction No" : ""}
                                    keyfilter={/^[[0-9a-zA-Z]+$/}
                                    iden="check_number"
                                    formik={formik}
                                    mandatory="*"
                                    placeHolder={
                                        formik.values.payment_type === 0
                                            ? "Enter Routing No"
                                            : formik.values.payment_type === 1
                                            ? "Enter Check No"
                                            : formik.values.payment_type === 2
                                            ? "Enter Transaction No"
                                            : ""
                                    }
                                    maxLength={20}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <label htmlFor="phone" className="step-fields-title">
                                    {formik.values.payment_type === 0 ? (
                                        <b>ACH Date</b>
                                    ) : formik.values.payment_type === 1 ? (
                                        <b>Check Date</b>
                                    ) : formik.values.payment_type === 2 ? (
                                        <b>Wire Date</b>
                                    ) : (
                                        ""
                                    )}
                                    <span className="clr_red">*</span>
                                </label>
                                <div className="mt-2">
                                    <Calendar
                                        id="check_date"
                                        name="check_date"
                                        value={new Date(formik.values.check_date)}
                                        placeholder="mm/dd/yyyy"
                                        mask="99/99/9999"
                                        showIcon
                                        onChange={(e) => inputHandler(e)}
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1945:2050"
                                    />
                                </div>
                                {getFormErrorMessage("check_date")}
                            </div>
                            {formik.values.payment_type !== 2 ? (
                                <div className="col-12 md:col-6">
                                    <label htmlFor="phone" className="step-fields-title">
                                        {formik.values.payment_type === 0 ? <b>ACH Deposit Date</b> : formik.values.payment_type === 1 ? <b>Check Deposit Date</b> : ""}
                                        <span className="clr_red">*</span>
                                    </label>
                                    <div className="mt-2">
                                        <Calendar
                                            id="check_deposit_date"
                                            name="check_deposit_date"
                                            value={new Date(formik.values.check_deposit_date)}
                                            placeholder="mm/dd/yyyy"
                                            mask="99/99/9999"
                                            minDate={new Date(formik.values.check_date)}
                                            showIcon
                                            onChange={(e) => inputHandler(e)}
                                            monthNavigator
                                            yearNavigator
                                            yearRange="1945:2050"
                                        />
                                    </div>
                                    {getFormErrorMessage("check_deposit_date")}
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="col-12 md:col-6">
                                <label htmlFor="phone" className="step-fields-title">
                                    <b>Posting Date</b>
                                    <span className="clr_red">*</span>
                                </label>
                                <div className="mt-2">
                                    <Calendar
                                        id="posting_date"
                                        name="posting_date"
                                        value={new Date(formik.values.posting_date)}
                                        placeholder="mm/dd/yyyy"
                                        mask="99/99/9999"
                                        minDate={new Date(minPostingDate)}
                                        showIcon
                                        onChange={(e) => inputHandler(e)}
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1945:2050"
                                    />
                                </div>
                                {getFormErrorMessage("posting_date")}
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    <CustomInputField className="col-12 md:col-12" type="textarea" mandatory="*" iden="note" label="Comments" placeHolder="Enter Comments" maxLength="150" formik={formik} />

                    <div className="col-12 text-right pt-5 ">
                        <CustomCancelbtn title="Cancel" type="button" onClick={handleCancel} isDisabled={isSaving} />

                        <CustomAddNewButton title="Save" type="submit" isDisabled={isSaving} isLoading={isLoading} />
                    </div>
                </div>
            </form>

            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={() => onCancel()}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default UnAppliedcashModal;
