import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export async function postInvoice(data) {
    return postData(appUrl["invoice"].create, data);
}

export async function getInvoiceClientsDropdown(data) {
    return postData(appUrl["invoice"].invoiceClientsDropdown, data);
}

export async function getInvoiceDetails(data) {
    return postData(appUrl["invoice"].invoiceDetails, data);
}

export async function invoiceStatusChange(data) {
    return postData(appUrl["invoice"].status, data);
}

export async function invoicesMoveToSchedule(data) {
    return postData(appUrl["invoice"].moveToSchedule, data);
}

export async function getProspectSchedules(data) {
    return postData(appUrl["invoice"].prospectSchedules, data);
}

export async function deleteInvoices(data) {
    return postData(appUrl["invoice"].delete, data);
}

export  async function getInvoices(data) {
    return postData(appUrl["invoice"].fetch,data);
}

export  async function postAttachments(data) {
    return postData(appUrl["invoice"].uploadAttachment,data);
}

export  async function deleteAttachments(data) {
    return postData(appUrl["invoice"].deleteAttachment,data);
}

export  async function adjustHoldAccount(data) {
    return postData(appUrl["invoice"].adjustHoldAccount,data);
}

export  async function adjustHoldAccountInCollection(data) {
    return postData(appUrl["invoice"].adjustHoldAccountInCollection,data);
}