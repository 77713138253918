import React, { useState, useRef, useEffect } from "react";
//Primereact components
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
//svgs
import EditIcon from "../../../../svgs/Deposits/EditIcon";
//ui
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
//svg
import DeleteIcon from "../../../../svgs/Deposits/DeleteIcon";
//components
import Header from "./Header/Header";
import AddNewReceivingAccount from "./Components/AddNewReceivingAccount";

const ReceivingAccounts = () => {
    const dt = useRef(null);
    //states
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedPros, setSelectedPros] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addData, setAddData] = useState(null);
    const [showAddEditDialog, setAddEditDialog] = useState(false);

    //Properties
    const dialogFuncMap = {
        showAddEditDialog: setAddEditDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    //data Aray
    const data = [
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
        { number: "435647y547", title: "Joseph Fall", bankname: "Zions bank", accounttype: "Current", currency: "USD", status: "pending", description: "loreum ipsum..." },
    ];

    // multiSelect Array

    const prosName = [
        { name: "Deposit ID", code: "NY" },
        { name: "Deposit Amount", code: "RM" },
        { name: "Deposit Date", code: "LDN" },
        { name: "Receiving Account", code: "IST" },
        { name: "Deposit Type", code: "IST" },
        { name: "Payment ID", code: "PRS" },
        { name: "Sender", code: "PRS" },
        { name: "Status", code: "PRS" },
    ];

    const actionTempate = (rowData) => {
        return (
            <>
                <div className="Edit_Icon flex flex-row">
                    <Button
                        tooltipOptions={{ position: "top" }}
                        tooltip="Edit"
                        icon={<EditIcon />}
                        aria-label="Submit"
                        className="icon_button"
                        onClick={() => {
                            setAddData(null);
                            onClick("showAddEditDialog");
                        }}
                    />
                    <Button tooltipOptions={{ position: "top" }} tooltip="Delete" icon={<DeleteIcon />} aria-label="Submit" className="icon_button" />
                </div>
            </>
        );
    };

    useEffect(() => {
        setTimeout(() => setLoading(false), 3000); // simulate data loading for 2 seconds
    }, []);
    return (
        <>
            <Dialog
                header="Add New Receiving Account"
                visible={showAddEditDialog}
                onHide={() => onHide("showAddEditDialog")}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                <AddNewReceivingAccount onHide={() => onHide("showAddEditDialog")} />
            </Dialog>

            {loading === true ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 md:col-10">
                            <h4 className="pt-2">
                                <b>Receiving Account</b>
                            </h4>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="md:col-10"></div>
                        <div className="col-12 md:col-2 pr-0 text-right">
                            <Header />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="md:col-7 pt-3"></div>
                        <div className="col-12 md:col-5 pt-3">
                            <div className="flex flex-row justify-content-end">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                                </span>
                                <MultiSelect
                                    className="multi_width"
                                    filter
                                    value={selectedPros}
                                    options={prosName}
                                    onChange={(e) => setSelectedPros(e.value)}
                                    optionLabel="name"
                                    placeholder="Select"
                                    maxSelectedLabels={2}
                                />
                            </div>
                        </div>
                        <div className="col-12 pt-3">
                            <DataTable ref={dt} value={data} rows={5} paginator responsiveLayout="scroll" globalFilter={globalFilter} emptyMessage="No record available.">
                                <Column field="number" header="Account Number"></Column>
                                <Column field="title" header="Account Title"></Column>
                                <Column field="bankname" header="Bank Name"></Column>
                                <Column field="accounttype" header="Account Type"></Column>
                                <Column field="currency" header="Currency"></Column>
                                <Column field="status" header="Status"></Column>
                                <Column field="description" header="Description"></Column>
                                <Column body={actionTempate} header="Actions"></Column>
                            </DataTable>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ReceivingAccounts;
