import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// Redux
import { loginState } from "../../../../redux/login";

// UI Components
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";

// Utils
import {validateUserPermissions} from '../../../../utils/HelperFuctions/validatePermissions'
import { MANAGMENT_RIGHTS } from "../../../../utils/Constants/rights";


const Header = (props) => {
    const { broker_id = null } = props;

    // Redux
    const {user}  = useSelector(loginState)

    const history = useHistory();
    //navigate to add user
    const handleNavigate = () => {
        history.push("/brokerdetails");
    };
    return (
        <>
            {validateUserPermissions([MANAGMENT_RIGHTS.brokers.add_new], user?.rights) &&  <CustomAddNewButton title="Add New Broker" type="button" onClick={handleNavigate} />}
        </>
    );
};

export default Header;
