import React from "react";
import { currencyFormatUSD } from "../../../utils/HelperFuctions/checkAmount";
//Component
import { Chart } from "primereact/chart";
const LineGraph = (props) => {
    const { debtorsData } = props;

    const balanceDebtorChartLabels = [];
    const balanceDebtorChartData = [];

    const averageAgingTurnoverChartLabels = [];
    const averageAgingChartData = [];
    const averageAgingTurnoverChartData = [];

    Object.values(debtorsData).forEach(function (debtor) {
        balanceDebtorChartLabels.push(debtor.name);
        balanceDebtorChartData.push(debtor.total_debt);

        averageAgingTurnoverChartLabels.push(debtor.name);
        averageAgingChartData.push(debtor.average_aging);
        averageAgingTurnoverChartData.push(debtor.average_aging_turnover);
    });

    const balanceDebtorData = {
        labels: balanceDebtorChartLabels,
        datasets: [
            {
                label: "",
                data: balanceDebtorChartData,
                fill: false,
                tension: 0.4,
                borderColor: "#3165CB",
            },
        ],
    };
    const averageAgingTurnoverData = {
        labels: averageAgingTurnoverChartLabels,
        datasets: [
            {
                label: "Avg.Aging",
                data: averageAgingChartData,
                fill: false,
                tension: 0.4,
                borderColor: "#3165CB",
            },
            {
                label: "Avg.Turnover",
                data: averageAgingTurnoverChartData,
                fill: true,
                borderColor: "#FFA726",
                tension: 0.4,
                backgroundColor: "rgba(255,167,38,0.2)",
            },
        ],
    };

    const getLightTheme = () => {
        let balanceDebtor = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: (context) => currencyFormatUSD(balanceDebtorChartData[context.dataIndex]), // Display custom tooltip labels
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                        display: false,
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                        callback: (value) => `$${value.toFixed(2)}`, // Adding currency symbol
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
            animation: {
                duration: 0,
            },
        };
        let averageAgingTurnover = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                        display: false,
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
            animation: {
                duration: 0,
            },
        };

        return {
            balanceDebtor,
            averageAgingTurnover,
        };
    };

    const { balanceDebtor, averageAgingTurnover } = getLightTheme();

    return (
        <>
            <div className="col-12 lg:col-6 xl:col-6">
                <div className="card">
                    <h5 className="p-mx-0 p-my-1 pt-2">
                        <b> Balance by Debtor</b>
                    </h5>
                    <Chart type="line" className="p-axis-label-x" data={balanceDebtorData} options={balanceDebtor} style={{ height: "300px" }} />
                </div>
                <div className="card">
                    <h5 className="p-mx-0 p-my-1 pt-2">
                        <b> Average Aging vs.Turnover by Debtor</b>
                    </h5>
                    <Chart type="line" data={averageAgingTurnoverData} options={averageAgingTurnover} style={{ height: "300px" }} />
                </div>
            </div>
        </>
    );
};

export default LineGraph;
