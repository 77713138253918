import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function savePayment(data) {
    return postData(appUrl["payment"].save, data);
}

export async function fetchPayment(data) {
    return postData(appUrl["payment"].fetch, data);
}
