import React, { useContext, useState } from "react";
//styles
import "./custombrokerinvitemodal.scss";
//ui components
import CustomInputField from "../CustomInputField/CustomInputField";
import CustomAddNewButton from "../CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../CustomCancelbtn/CustomCancelbtn";

//formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { inviteClient } from "../../services/broker";
import { ToastContext } from "../../context/toast";
import CustomConfirmDialog from "../CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const CustomBrokerInviteModal = (props) => {
    const { onHide, setInviteClientModal, isVisible, setIsVisible, isFormDirty, setIsFormDirty } = props;

    const [isSaving, setIsSaving] = useState(false);
    const toast = useContext(ToastContext);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email To is required").email("Invalid Email"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                let res = await inviteClient(data);
                if (res.data.status) {
                    formik.resetForm();
                    setInviteClientModal(false);
                    toast.showMessage("Success", res.data.message, "success");
                    setIsSaving(false);
                } else {
                    toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
            }
        },
    });

    const handleHanlde = (e) => {
        setIsFormDirty(true);
        formik.handleChange(e);
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField label="Email To" iden="email" maxLength={50} mandatory="*" formik={formik} placeHolder="Enter Email" onChange={(e) => handleHanlde(e)} />
                    </div>
                    <div className="col-12 mt-3 text-center">
                        <CustomCancelbtn
                            type="button"
                            title="Cancel"
                            onClick={(e) => {
                                isFormDirty ? setIsVisible(true) : onHide();
                            }}
                        />
                        <CustomAddNewButton title="Send" isDisabled={isSaving} />
                    </div>
                </div>
            </form>

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        onHide();
                        setIsVisible(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without inviting client?"
                />
            )}
        </>
    );
};

export default CustomBrokerInviteModal;
