import React, { useRef } from "react";
//styles
import "./component.scss";
//components
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { BsThreeDotsVertical } from "react-icons/bs";

const InvoiceKebabMenuComponent = (props) => {
    const { items, handleMenuOpen, selected, dialogCheck } = props;
    const menu = useRef(null);

    const renderMenuItem = (item, index) => {
        const isLastItem = index === items.length - 1;
        const menuClass = isLastItem ? "last_kebab_menu_item" : "kebab_menu_item";
        const disableItem = selected.length === 0 || dialogCheck ? "disable_kebab_menu_border_single no-drop" : "cursor-pointer";
        return (
            <div className={`pt-3 ${menuClass} ${disableItem} `} onClick={() => handleChange(item.status)}>
                <p className="pl-3 pb-3 kebab_menu_item_font">{item.title}</p>
            </div>
        );
    };
    const handleChange = (val) => {
        handleMenuOpen(val);
    };
    const menuItems = items.map((item, index) => {
        return {
            label: item.title,
            template: () => renderMenuItem(item, index),
        };
    });
    return (
        <div className="invoice_kebab_menu">
            <Menu model={menuItems} popup ref={menu} id="popup_menu" className="invoice_kebab_menu_border" />
            <Button icon={<BsThreeDotsVertical />} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup className="invoice_menu_btn"></Button>
        </div>
    );
};

export default InvoiceKebabMenuComponent;