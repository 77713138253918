import React, { useContext, useEffect, useRef, useState } from "react";
// components
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
//styles
import "./component.scss";
import { getChargeBackInvoiceStatus, postVerifySchedule } from "../../../../../../services/schedule-assignment";
import { ToastContext } from "../../../../../../context/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import CustomModalSpinner from "../../../../../../ui/CustomModalSpinner/CustomModalSpinner";
import { CHARGEBACK_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import { getColumnBody } from "../../../../../../utils/HelperFuctions/supportHelper";

const VerifyScheduleModal = (props) => {
    //props
    const { onHide, selectedSchedules, getGridData } = props;

    // use States
    const [loading, setLoading] = useState(false);
    const [chargebackStatus, setChargebackStatus] = useState(false);
    const [invoices, setInvoices] = useState();
    const [scheduleTotalAmount, setScheduleTotalAmount] = useState(0);
    const [chargebackExceed, setChargebackExceed] = useState(false);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [verifyLoading, setVerifyLoading] = useState(false);
    

    //toast message
    const toast = useContext(ToastContext);

    //ref
    const dt = useRef(null);

    const verifySchedule = async (verifyWithChargeback) => {
        let payload = {
            id: selectedSchedules[0].id,
            chargebacks: verifyWithChargeback ? selectedInvoices.map((invoice) => invoice.id) : [],
        };
        try {
            setVerifyLoading(true);
            const res = await postVerifySchedule(payload);
            if (res.data.status) {
                toast.showMessage("Success", "Schedule has been verified successfully.", "success");
                onHide();
                getGridData();
            } else {
                toast.showMessage("Error", res.data.message, "error");
            }
            setVerifyLoading(false);
        } catch (e) {
            setVerifyLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchChargeBackStatus = async () => {
        setLoading(true);
        let payload = {
            prospect_id: selectedSchedules[0].prospect_id,
            charge_back_settle_status: 1,
            charge_back_settled: false,
            schedule_id: selectedSchedules[0].id,
        };
        try {
            const res = await getChargeBackInvoiceStatus(payload);
            if (res.data.status) {
                setChargebackStatus(res.data.status);
                setInvoices(res.data.invoices);
                setScheduleTotalAmount(res.data.scheduleTotalAmount);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedInvoices(event.value);

            let fee_charged = 0;
            event.value.forEach((invoice) => {
                fee_charged += invoice.adjusted_cb;
            });
            if (scheduleTotalAmount < fee_charged) setChargebackExceed(true);
            else setChargebackExceed(false);
        }
    };

    const columnComponents = CHARGEBACK_GRID_HEADERS.map((col) => {
        return (
            (col.header !== 'Invoice_No' || col.header !== 'Client Name') ?
            <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)}
            headerClassName={`${col.field === "invoice_amount" || col.field === "actual_cb" || col.field === "cb" || col.field === "adjusted_cb"  ? "amount_column_alignment" : ""}`}>
            </Column> : null

        );
    });

    useEffect(() => {
        fetchChargeBackStatus();
    }, []);

    return (
        <>
            {loading ? (
                <CustomModalSpinner top={20} />
            ) : (
                <>
                    <div className="verify_schedule">
                        <div className="text-center ">
                            <p>Are you sure, you want to verify the schedule?</p>
                        </div>
                        {chargebackStatus && invoices.length > 0 && (
                            <div className="grid">
                                <h6 class="mt-3">
                                    <b>Chargeback(s)</b>
                                </h6>
                                <div className="col-12 pt-3">
                                    <DataTable
                                        ref={dt}
                                        value={invoices}
                                        rows={5}
                                        responsiveLayout="scroll"
                                        onSelectionChange={handleSelectionChange}
                                        selection={selectedInvoices.length === 0 ? null : selectedInvoices}
                                        emptyMessage="No record available."
                                    >
                                        <Column selectionMode="multiple"></Column>
                                        <Column field="invoice_no" header="Invoice No"></Column>
                                        <Column field="prospect.company_business_name" header="Client Name"></Column>
                                        <Column field="debtor.legal_name" header="Debtor Name"></Column>
                                        <Column field="invoice_amount" header="Invoice Amount" body={getColumnBody("invoice_amount")} headerClassName="amount_column_alignment"></Column>
                                        {/* <Column field="advance_amount" header="Advance Amount" body={getColumnBody("advance_amount")} headerClassName="amount_column_alignment"></Column>
                                        <Column field="pending_amount" header="Pending Amount" body={getColumnBody("pending_amount")} headerClassName="amount_column_alignment"></Column>
                                         */}
                                        <Column field="actual_cb" header="Actual CB" body={getColumnBody("actual_cb")} headerClassName="amount_column_alignment"></Column>
                                        <Column field="adjusted_cb" header="CB to be settled" body={getColumnBody("adjusted_cb")} headerClassName="amount_column_alignment"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        )}
                        {chargebackExceed && <p> Note: Chargeback amount to be settled exceed the total value of schedule. Please uncheck some of the chargebacks.</p>}
                        <div className="flex justify-content-center pt-4">
                            <CustomCancelbtn className="ml-2" onClick={onHide} title="Cancel" />{" "}
                            <CustomAddNewButton
                                title="Verify"
                                isDisabled={(chargebackStatus && selectedInvoices.length == 0) || chargebackExceed || verifyLoading}
                                className="ml-2"
                                onClick={() => verifySchedule(true)}
                            />
                            {chargebackStatus && (
                                <CustomAddNewButton title="Verify W/O Adjustments" isDisabled={verifyLoading} className="ml-3 without_adjustment_width" onClick={() => verifySchedule(false)} />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default VerifyScheduleModal;
