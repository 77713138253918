import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function savePolicy(data) {
    return postData(appUrl["policy"].save, data);
}

export async function getPolicy(data) {
    return postData(appUrl["policy"].fetch, data);
}

export async function deletePolicy(data) {
    return postData(appUrl["policy"].delete, data);
}

export async function deleteAttachmentPolicy(data) {
    return postData(appUrl["policy"].deleteAttachment, data);
}
