//CustomInputTextArea
import React from "react";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import "../CustomInputField/customInputfield.scss";

export default function CustomInputTextArea({ mandatory, rows, iden, formik, className, label, placeHolder, disabled = false, maxLength, ...remainingProps }) {
    function titleCase(str) {
        return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    }
    const isFormFieldValid = (name) => !!(formik?.touched[name] && formik?.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik?.errors[name]}</small>;
    };

    // Handle Change
    const handleChange = (e) => formik?.handleChange(e);

    return (
        <div className={`field ${className} flex flex-column`}>
            <div style={{ width: "100%", marginBottom: ".5rem" }}>
                <label htmlFor="name1">
                    <b> {label || titleCase(iden)}</b>
                    <span className="clr_red">{mandatory}</span>
                </label>
            </div>
            <div className="flex-grow-1 default">
                <InputTextarea
                    disabled={disabled}
                    id={iden}
                    name={iden}
                    value={formik.values[iden]}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    type="text"
                    placeholder={placeHolder}
                    rows={rows}
                    className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                    maxLength={maxLength}
                    {...remainingProps}
                />
            </div>
            {getFormErrorMessage(iden)}
        </div>
    );
}
