import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";

//Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Prime React
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

// Icons
import { BsCheck, BsCheckLg, BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";

// Context
import { ToastContext } from "../../../../../context/toast";

// Redux
import { useSelector } from "react-redux";
import { mainViewState } from "../../../../../redux/main-view";

// Constants
import { DATE_TIME_FORMAT, FILE_MAX_SIZE } from "../../../../../utils/Constants/global";
import { BUSINESS_NAME_NOTES, BUSINESS_NAME_SEARCH } from "../../../../../utils/Constants/global";

// UI Components
import NoteEdit from "../ChangeStatus/NoteEdit";
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import CustomAttachmentUpload from "../../../../../ui/CustomAttachmentUpload/CustomAttachmentUpload";

// Helper Functions
import { textExtraSpaceRemoval } from "../../../../../utils/HelperFuctions/cleanData";
import { previewDocumentHandler } from "../../../../../utils/HelperFuctions/documnetPreview";
import { openAttachment } from "../../../../../utils/HelperFuctions/openAttachment";

// API'S
import { saveProspectBusinessName } from "../../../../../services/prospect";
import { getClientBusinessName } from "../../../../../services/client";
import Document from "../../../../../svgs/Document/Document";
import { useQuery } from "../../../../../utils/HelperFuctions/UseQuery";
import { fileName } from "../../../../../utils/HelperFuctions/getFileName";
import appUrl from "../../../../../utils/Constants/appUrl";

// Validations
const validationSchema = Yup.object().shape({
    name: Yup.string().required("Attachment Name is required"),
});

const BusinessSearch = () => {
    const location = useLocation();
    let id = null;
    if (location.state !== undefined) id = location.state?.id;

    // Context
    const toast = useContext(ToastContext);
    //use History
    const history = useHistory();
    // Redux
    const { screens } = useSelector(mainViewState);
    // States
    const [notes, setNotes] = useState([]);
    const [localNotes, setLocalNotes] = useState("");
    const [fileUploadData, setfileUploadData] = useState(null);
    const [response, setResponse] = useState(null);
    const [editNotes, setEditNotes] = useState(null);
    // View State
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [remove, setRemove] = useState(false);
    const [openAttachmentLoading, setOpenAttachmentLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isMutated, setIsMutated] = useState(true);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isEditCheck, setIsEditCheck] = useState(false);
    const hiddenFileInput = useRef(null);
    // Hooks
    const query = useQuery();
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            try {
                if (fileUploadData) {
                    const updatedNotes = notes.map((item) => {
                        return { note: item.notes };
                    });
                    const form = new FormData();
                    form.append("name", data.name);
                    form.append("attachment", fileUploadData);
                    form.append("prospect_id", screens.prospect.data.id);
                    if (updatedNotes.length > 0) {
                        form.append("notes", JSON.stringify(updatedNotes));
                    }
                    let res = await saveProspectBusinessName(form);
                    if (res && res.data.status) {
                        toast.showMessage("Creation", "Business Name Search has been created successfully.", "success");
                        formik.resetForm();
                        setfileUploadData(null);
                        setNotes([]);
                        setRemove(!remove);
                        setLocalNotes("");
                        setIsDisabled(true);
                        setIsMutated(!isMutated);
                    } else {
                        toast.showMessage("Error", res.data.message, "error");
                        setIsSaving(false);
                    }
                } else {
                    toast.showMessage("File Required", "Please select the file first", "error");
                }
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
            }
            setIsSaving(false);
        },
    });
    // Notes Grid
    const columnComponentsNotes = BUSINESS_NAME_NOTES.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} />;
    });
    const getColumnBody = (colField) => (rowData) => {
        if (colField === "notes") {
            return (
                <>
                    {rowData.notes.map((item, index) => {
                        return (
                            <div key={index} className="mb-2 ">
                                <div className="flex flex-row justify-content-between align-items-center">
                                    <div>
                                        <span>{item.note}</span>

                                        <span className="ml-4">
                                            <b>{moment(item.updated_at).format(DATE_TIME_FORMAT)}</b>
                                        </span>
                                    </div>
                                    <div>
                                        <Button
                                            type="button"
                                            tooltip="Edit Note"
                                            tooltipOptions={{ position: "top" }}
                                            className="bi bi-pencil edit_pencil"
                                            style={{ fontSize: "18px" }}
                                            onClick={() => editHandler(item)}
                                        />
                                    </div>
                                </div>
                                {index !== rowData.notes.length - 1 && <hr></hr>}
                            </div>
                        );
                    })}
                </>
            );
        } else if (colField === "path") {
            return (
                <>
                    <div
                        className={` mr-2 mt-1 ${rowData.path ? "document_icon_background" : "no_doc"}`}
                        onClick={() => {
                            openDocument(rowData.path);
                            // previewDocumentHandler(rowData.path);
                        }}
                    >
                        {rowData.path ? <Document /> : "No Attachment"}
                    </div>
                </>
            );
        }
        return rowData[colField] ? rowData[colField] : "";
    };
    // Business Name Search
    const columnComponents = BUSINESS_NAME_SEARCH.map((col) => {
        if (col.field === "name" || col.field === "path") {
            return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} style={{ width: "300px" }} />;
        } else {
            return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} />;
        }
    });

    // Handlers
    const handleInputChange = (e) => {
        setIsFormDirty(true);
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);
        if (name === "notes") {
            setLocalNotes(cleanValue);
        } else {
            formik.setFieldValue(name, cleanValue);
        }
    };
    const handleSelectedFile = (event) => {
        if (event.target?.files?.length > 0) {
            const file = event.target.files[0];
            const fileType = file.name.split(".");
            const index = fileType.length - 1;
            const accept = ".pdf,.png,.jpg,.jpeg,.xlsx,.xls,.doc,.docx";
            const check_file = accept.includes(fileType[index]);
            if (check_file) {
                if (file.size > FILE_MAX_SIZE.FILE_SIZE) {
                    toast.showMessage("Exceeding Limit", `${file?.name} size is greater than allowed size.`, "error");
                } else if (file?.name?.includes("#") || file.name.includes("&")) {
                    toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                } else {
                    setfileUploadData(file);
                }
            } else {
                toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                setfileUploadData(null);
            }

            event.target.value = null;
        }
    };

    const notesHandler = () => {
        if (notes.length === 2) {
            toast.showMessage("Limit", "Maximum 2 notes allowed.", "error");
            setIsDisabled(true);
            setLocalNotes("");
        } else {
            if (localNotes) {
                const temp = [...notes];
                temp.push({
                    no: notes.length + 1,
                    notes: localNotes,
                });
                setNotes(temp);
                setLocalNotes("");
            }
            setIsDisabled(true);
        }
    };
    const cancelHanlder = () => {
        formik.resetForm();
        setfileUploadData(null);
        setNotes([]);
        setRemove(!remove);
        setLocalNotes("");
        setIsDisabled(true);
        setIsVisible(false);
        setIsFormDirty(false);
        history.push("/prospectindex");
    };
    const editHandler = (note) => {
        setEditNotes(note);
        setIsEditCheck(true);
    };
    // API'S Handler
    const fetchBusinessNameSearch = () => {
        try {
            setIsLoading(true);
            let payload = {
                prospect_id: Number(id),
            };
            getClientBusinessName(payload).then((res) => {
                setResponse(res.data.attachments);
                setIsLoading(false);
            });
        } catch (error) {
            setIsLoading(false);
            toast.showMessage("Fetching Business Name Search", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const handleDelete = (file) => {
        setfileUploadData(null);
    };
    useEffect(() => {
        fetchBusinessNameSearch();
    }, [screens.prospect.data, isMutated, screens.prospect.isMutated]);

    const handleOpenUpload = () => {
        hiddenFileInput.current.click();
    };

    const openDocument = async (attachment) => {
        try {
            setOpenAttachmentLoading(true);
            await openAttachment(attachment);
        } catch (error) {
            console.error("Error opening attachment:", error);
        } finally {
            setOpenAttachmentLoading(false);
        }
    };

    return (
        <>
            <div className="card busines_search">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-12 md:col-7">
                            <div className="grid brdr_class">
                                <CustomInputField
                                    className="col-12 md:col-12"
                                    mandatory="*"
                                    iden="name"
                                    label="Attachment Name"
                                    maxLength={25}
                                    formik={formik}
                                    placeHolder="Enter Attachment Name"
                                    onChange={handleInputChange}
                                />

                                {/* <div className="col-12 md:col-12">
                                    <CustomAttachmentUpload
                                        setIsFormDirty={setIsFormDirty}
                                        isFormDirty={isFormDirty}
                                        handleSelectedFile={handleSelectedFile}
                                        fileType={".pdf, .png, .jpg, .jpeg, .xlsx, .xls, .doc, .docx"}
                                        remove={remove}
                                        multiple="uploadSingle"
                                    />
                                </div> */}
                                <div className="col-12">
                                    <label htmlFor="NameTitle">
                                        <b>Attachment</b>
                                        <Button
                                            type="button"
                                            tooltip=".pdf, .png, .jpg, .jpeg, .xlsx, .xls, .doc, .docx files only (max size 3MB) "
                                            tooltipOptions={{ position: "right" }}
                                            icon={<BsInfoCircle />}
                                            aria-label="Submit"
                                            className="customTooltipicon"
                                        />
                                    </label>
                                    {/* 
                                    <div className="pt-3">
                                        <input type="file" onChange={onFileSelect} accept=".jpg,.jpeg,.png,.pdf" multiple />
                                    </div> */}{" "}
                                    <div className="file_upload_class">
                                        <label className="flex flex-row justify-content-between align-items-center" onClick={handleOpenUpload}>
                                            {fileUploadData ? (
                                                <div>
                                                    <div className="flex flex row justify-content-around">
                                                        <span className="text_break  cursor-pointer">{fileUploadData.name}</span>

                                                        <span className="text_break  cursor-pointer mt-1" onClick={() => handleDelete(fileUploadData)}>
                                                            <BsXLg className="bs_Cros_img" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <> Choose File</>
                                            )}

                                            <BsPaperclip />
                                        </label>
                                    </div>
                                    <input
                                        type="file"
                                        disabled={fileUploadData}
                                        ref={hiddenFileInput}
                                        id="file-upload"
                                        onChange={handleSelectedFile}
                                        accept=".pdf, .png, .jpg, .jpeg, .xlsx, .xls, .doc, .docx"
                                        style={{ display: "none" }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* //notes */}
                        <div className="col-12 md:col-5">
                            <div className="text-right">
                                <Button tooltip="Save" tooltipOptions={{ position: "top" }} className="check_tick_icon" icon={<BsCheck />} type="button" onClick={notesHandler} />
                            </div>
                            <div className={`col-12 md:col-12 p-0 `}>
                                <div className="col-12 md:col-12 mb-1 ">
                                    <label htmlFor="notes">
                                        <b> Notes </b>
                                    </label>
                                </div>
                                <InputTextarea id="notes" name="notes" maxLength="250" placeholder="Enter Notes" value={localNotes} rows="3" onChange={handleInputChange} />
                            </div>
                            <div className="col-12 pt-2">
                                <DataTable value={notes} responsiveLayout="scroll" emptyMessage="No record available.">
                                    {columnComponentsNotes}
                                </DataTable>
                            </div>
                        </div>
                        {/* //buttons */}
                        <div className="col-12 text-right">
                            <CustomCancelbtn title="Cancel" type="button" onClick={() => (isFormDirty ? setIsVisible(true) : cancelHanlder())} />
                            <CustomAddNewButton title="Save" type="submit" isDisabled={isSaving} />
                        </div>
                    </div>
                    {isLoading ? (
                        <CustomLoading />
                    ) : (
                        <div className="grid p-2">
                            <h5>
                                <b>Business Name Search</b>
                            </h5>
                            <div className="col-12 pt-2">
                                <DataTable value={response} responsiveLayout="scroll" emptyMessage="No record available.">
                                    {columnComponents}
                                </DataTable>
                            </div>
                        </div>
                    )}
                </form>
            </div>

            {openAttachmentLoading && <CustomLoading center />}

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={cancelHanlder}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
            <Dialog blockScroll={true} visible={isEditCheck} onHide={() => setIsEditCheck(false)} header="Edit Notes" breakpoints={{ "960px": "60vw", "640px": "100vw" }} style={{ width: "30vw" }}>
                {isEditCheck && <NoteEdit editNotes={editNotes} onHide={() => setIsEditCheck(false)} />}
            </Dialog>
        </>
    );
};

export default BusinessSearch;
