import moment from "moment";
import { currencyFields } from "../Constants/global";
import { currencyFormatUSD } from "./checkAmount";
import { dateTimeFormatForInputFields, getDateFormat } from "./dateFormat";
import { trimTextWithDots } from "./trimText";
import React from "react";

export const getReportsColumnBody = (colField) => (rowData) => {
    // const unappliedCashReceivedFields = ["amount_uc", "returned_uc", "fee_applicable"];

    if (colField === "invoice_no") {
        if (rowData.invoice_no !== null) {
            return rowData.invoice_no;
        } else {
            return "Unassigned";
        }
    } else if (colField === "schedule_no") {
        if (rowData.schedule_no !== null) {
            return rowData.schedule_no;
        } else {
            return "Unassigned";
        }
    } else if (currencyFields.includes(colField)) {
        return (
            <div className="amount_column_alignment">
                {rowData[colField] && rowData[colField] != "Not Applicable" ? currencyFormatUSD(rowData[colField]) : rowData[colField] === "Not Applicable" ? "Not Applicable" : "$0.00"}
            </div>
        );
    } else if (colField === "cb_uc_amount") {
        return (
            <div className="amount_column_alignment">
                {rowData['has_uc'] ? '(' + currencyFormatUSD(rowData[colField]) + ')' : currencyFormatUSD(rowData[colField]) }
            </div>
        );
    } else if (colField === "debit_amount") {
        let amount = rowData[colField] ? "-" + currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0);
        return rowData[colField] > 0 ? <div className="amount_column_alignment danger_text">{amount}</div> : <div className="amount_column_alignment">{amount}</div>;
    } else if (colField === "credit_amount") {
        let amount = rowData[colField] ? "+" + currencyFormatUSD(rowData[colField]) : currencyFormatUSD(0);
        return rowData[colField] > 0 ? <div className="amount_column_alignment success_text">{amount}</div> : <div className="amount_column_alignment">{amount}</div>;
    } else if (colField === "posting_date") {
        return rowData[colField] ? moment(rowData[colField]).format("MM/DD/YYYY") : "";
    }

    // else if (unappliedCashReceivedFields.includes(colField)) {
    //     return <div className="amount_column_alignment">{rowData[colField] && rowData[colField] != "Not Applicable" ? currencyFormatUSD(rowData[colField]) : rowData[colField]}</div>;
    // }
    else if (colField === "return_date") {
        return rowData[colField] && rowData[colField] != "Not Applicable" ? dateTimeFormatForInputFields(rowData[colField]) : rowData[colField];
    } else if (colField === "efficiency") {
        return rowData.efficiency > 0 ? rowData.efficiency + "%" : "0.00%";
    } else if (colField === "invoices_paid") {
        return rowData.invoices_paid > 0 ? rowData.invoices_paid : "0";
    } else if (colField === "check_posted") {
        return rowData.check_posted > 0 ? rowData.check_posted : "0";
    } else if (colField === "age") {
        if (rowData.age == 0) {
            return 1;
        }
    } else if (colField === "invoice_due_date" || colField === "invoice_date") {
        return rowData[colField] ? getDateFormat(rowData[colField]) : rowData[colField];
    } else if (colField === "cb_uc_amount") {
        console.log('rowData.cb_uc_amount', rowData.cb_uc_amount)
        return rowData.has_uc ? '(' + currencyFormatUSD(rowData.cb_uc_amount) + ')' : currencyFormatUSD(rowData.cb_uc_amount);
    }

    return rowData[colField] ? rowData[colField] : "";
};
