import React, { useContext, useEffect, useState } from "react";
// style
import "./component.scss";
//components
import CreatableSelect from "react-select/creatable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import { invoicesMoveToSchedule } from "../../../../../../services/invoice";
import { ToastContext } from "../../../../../../context/toast";
import { getProspectScheduleNo } from "../../../../../../services/schedule-assignment";

const MoveToScheduleComponent = (props) => {
    const { invoices = [], onHide, fetchInvoices } = props;
    const [scheduleNo, setScheduleNo] = useState(null);
    const [newSchedule, setNewSchedule] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState([]);
    const [message, setMessage] = useState("");

    //toast message
    const toast = useContext(ToastContext);

    const handleSelectSchedule = (newValue, actionMeta) => {
        if (newValue && actionMeta.action === "create-option") {
            setScheduleNo(newValue.label);
            setNewSchedule(true);
        } else if (newValue && actionMeta.action === "select-option") {
            setScheduleNo(newValue.label);
            setNewSchedule(false);
        }
        return;
    };

    const handleSendSchedule = async () => {
        onHide();
        const invoiceIds = invoices.map((invoice) => invoice.id);

        let payload = {
            invoices_ids: invoiceIds,
        };
        if (newSchedule) {
            payload["schedule_no"] = scheduleNo;
        } else {
            const schedule_id = invoice.find((data) => data.schedule_no === scheduleNo)?.id;
            payload["schedule_id"] = schedule_id;
        }

        try {
            let res = await invoicesMoveToSchedule(payload);
            if (res.data.status) {
                toast.showMessage("Success", "Invoice(s) has been moved to the schedule", "success");
                fetchInvoices();
            } else {
                toast.showMessage("Error", res.data.message, "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const handleScheduleNo = async () => {
        setLoading(true);
        let payload = {
            "schedules.prospect_id": invoices[0].prospect_id,
        };
        try {
            const res = await getProspectScheduleNo(payload);
            if (res.data.status) {
                setInvoice(res.data.invoices);
                const schedule_options = res.data.invoices.map((schedule) => ({
                    label: schedule.schedule_no,
                    value: schedule.schedule_no,
                }));
                setSchedules(schedule_options);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    const handleInputChange = (inputValue) => {
        const maxLength = 6;
        const numeric = inputValue.replace(/\D/g, "");
        // sanitized value indicates that input value must be greater and equal to 1
        const sanitizedValue = isNaN(parseInt(numeric)) ? "" : Math.max(1, parseInt(numeric));
        const numericLength = sanitizedValue.toString().slice(0, maxLength);
        return numericLength;
    };

    useEffect(() => {
        handleScheduleNo();
    }, []);

    return (
        <div>
            <CreatableSelect isClearable options={schedules} onChange={handleSelectSchedule} onInputChange={handleInputChange} isDisabled={loading} />
            {message && <small className="p-error">{message}</small>}
            <div className="pt-6">
                <DataTable value={invoices} responsiveLayout="scroll">
                    <Column field="company_business_name" header="Client Name"></Column>
                    <Column field="legal_name" header="Debtor Name"></Column>
                    <Column field="invoice_no" header="Invoice No"></Column>
                    <Column field="invoice_date" header="Invoice Date"></Column>
                    <Column field="invoice_amount" header="Invoice Amount"></Column>
                </DataTable>
                <div className="flex justify-content-end pt-6">
                    <CustomCancelbtn type="button" title="Cancel" onClick={onHide} />
                    <CustomAddNewButton type="button" title="Save" onClick={handleSendSchedule} />
                </div>
            </div>
        </div>
    );
};

export default MoveToScheduleComponent;
