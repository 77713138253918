import React, { useEffect, useRef, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { onSupportDataMutated } from "../../../redux/main-view";

// Context
import { ToastContext } from "../../../context/toast";

//Components
import CustomCancelbtn from "../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomInputField from "../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../ui/CustomAddNewButton/CustomAddNewButton";

// API'S
import { createSupport, getAllDropdown } from "../../../services/support";
import CustomConfirmDialog from "../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

let validationSchema = Yup.object().shape({
    title: Yup.string().required("Ticket Title is required").trim(),
    ticket_type: Yup.string().required("Ticket Type is required").trim(),
    priority: Yup.string().required("Priority is required").trim(),
    details: Yup.string().required("Ticket Details is required").trim(),
});
const CreateTicketModal = (props) => {
    const { onHide } = props;
    const toastContext = useContext(ToastContext);
    // Dispatch
    const dispatch = useDispatch();
    // States
    const [isSaving, setIsSaving] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [supportTypeOptions, setSupportTypeOptions] = useState([]);
    const [supportModuleOptions, setSupportModuleOptions] = useState([]);
    const [supportPriorityOptions, setSupportPriorityOptions] = useState([]);
    // View States
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
            ticket_type: "",
            priority: "",
            details: "",
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            setIsLoading(true);
            try {
                const res = await createSupport(data);
                if (res) {
                    if (res?.data?.status) {
                        toastContext.createdToast("Ticket");
                        dispatch(onSupportDataMutated());
                        onHide();
                    } else {
                        setIsSaving(false);
                        setIsLoading(false);
                        toastContext.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                    }
                } else {
                    setIsSaving(false);
                    setIsLoading(false);
                    toastContext.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (e) {
                toastContext.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsSaving(false);
            setIsLoading(false);
        },
    });

    const handleHanlde = (e) => {
        setIsFormDirty(true);
        formik.handleChange(e);
    };

    // UseEffects
    useEffect(() => {
        const fetchingDropdownValues = async () => {
            setIsLoading(true);
            const data = {
                select: ["support_module", "support_ticket_priority", "support_ticket_type"],
            };
            getAllDropdown(data).then((res) => {
                const _type = JSON.parse(res.data.dropdowns.support_ticket_type);
                setSupportTypeOptions(_type);
                const _priority = JSON.parse(res.data.dropdowns.support_ticket_priority);
                setSupportPriorityOptions(_priority);
                const _module = JSON.parse(res.data.dropdowns.support_module);
                setSupportModuleOptions(_module);
            });
            setIsLoading(false);
        };

        fetchingDropdownValues();
    }, []);

    return (
        <>
            <form>
                <div className="grid">
                    <CustomInputField
                        className="col-12 md:col-4"
                        iden="title"
                        label="Ticket Title"
                        mandatory="*"
                        formik={formik}
                        placeHolder="Enter ticket title"
                        maxLength="40"
                        onChange={(e) => handleHanlde(e)}
                    />
                    <CustomInputField
                        className="col-12 md:col-4"
                        type="dropdown"
                        iden="ticket_type"
                        label="Ticket Type"
                        mandatory="*"
                        formik={formik}
                        optionLabel="name"
                        optionValue="value"
                        options={supportTypeOptions}
                        placeHolder="Select"
                        onChange={(e) => handleHanlde(e)}
                    />
                    <CustomInputField
                        className="col-12 md:col-4"
                        type="dropdown"
                        iden="priority"
                        label="Priority"
                        mandatory="*"
                        formik={formik}
                        optionLabel="name"
                        optionValue="value"
                        options={supportPriorityOptions}
                        placeHolder="Select"
                        onChange={(e) => handleHanlde(e)}
                    />
                    <CustomInputField
                        className="col-12 md:col-12"
                        type="textarea"
                        mandatory="*"
                        iden="details"
                        label="Ticket Details"
                        placeHolder="Enter Ticket Details"
                        maxLength="150"
                        formik={formik}
                        onChange={(e) => handleHanlde(e)}
                    />

                    <div className="col-12 text-right pt-5 ">
                        <CustomCancelbtn
                            title="Cancel"
                            type="button"
                            onClick={(e) => {
                                isFormDirty ? setIsVisible(true) : onHide();
                            }}
                        />

                        <CustomAddNewButton title="Save" type="button" isDisabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} isLoading={isLoading} />
                    </div>
                </div>
            </form>
            {/* )} */}
            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => onHide()}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default CreateTicketModal;
