import { currencyFormatUSD } from "./checkAmount";
import React from "react";

export const getScheduleColumnBody = (colField) => (rowData) => {
    if (colField === "schedule_fund_date") {
        if (rowData.schedule_fund_date !== null) {
            return rowData.schedule_fund_date;
        } else {
            return "Not Funded";
        }
    } else if (colField === "attachmentCount") {
        if (rowData.attachmentCount > 0) {
            return rowData.attachmentCount;
        } else {
            return "0";
        }
    } else if (colField === "remaining_amount") {
        return <div className="amount_column_alignment">{rowData[colField] === 0 ? "$0.00" : currencyFormatUSD(rowData[colField])}</div>;
    } else if (colField === "total_amount") {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : ""}</div>;
    } else if (colField === "funding_date") {
        if (rowData.funding_date !== null) {
            return rowData.funding_date;
        } else {
            return "Not Funded";
        }
    } else if (colField === "unverifiedCount") {
        return rowData[colField] === 0 ? "0" : rowData.unverifiedCount;
    } else if (colField === "verifiedCount") {
        return rowData[colField] === 0 ? "0" : rowData.verifiedCount;
    } else if (colField === "invoicesCount") {
        return rowData[colField] === 0 ? "0" : rowData.invoicesCount;
    } else if (colField === "totalScheduleFunding") {
        return <div className="amount_column_alignment">{rowData[colField] === 0 ? "$0.00" : rowData[colField] ? currencyFormatUSD(rowData[colField]) : ""}</div>;
    }

    return rowData[colField] ? rowData[colField] : "";
};
