import React, { useState } from "react";
//styles
import "../Screens/annoucements.scss";
import { Dialog } from "primereact/dialog";
import AddNewAnnouncement from "../Components/AddNewAnnouncement";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { useSelector } from "react-redux";
// Utils
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
// Constants
import { SETTINGS_RIGHTS } from "../../../../utils/Constants/rights";
// Redux
import { loginState } from "../../../../redux/login";
const Header = (props) => {
    // Props
    const { getAnnoucments } = props;
    const [addNewAnnoucement, setAddNewAnnoucement] = useState(false);

    // Redux
    const { user } = useSelector(loginState);
    const onCancel = (refresh) => {
        if (refresh) {
            getAnnoucments();
        }
        setAddNewAnnoucement(false);
    };
    return (
        validateUserPermissions([SETTINGS_RIGHTS.announcments.add_new_announcment], user?.rights) && (
            <>
                <Dialog
                    blockScroll={true}
                    header="Add New Announcement"
                    visible={addNewAnnoucement}
                    onHide={() => onCancel(false)}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw" }}
                >
                    <AddNewAnnouncement onHide={(refresh) => onCancel(refresh)} />
                </Dialog>
                <CustomAddNewButton title="Add New Announcement" onClick={() => setAddNewAnnoucement(true)}></CustomAddNewButton>
            </>
        )
    );
};

export default Header;
