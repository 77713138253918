import React, { useState, useEffect, useRef, useContext } from "react";
import { Route, useLocation, BrowserRouter as Router, Redirect } from "react-router-dom";

// Redux
import { setUser } from "./redux/login";
import { loginState } from "./redux/login";
import { SocketContext } from "./context/socket";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onChatNewMessage, onSideBarMain, setDebtorStatusChanged, setFetchNewNotification } from "./redux/main-view";

import { BsCheckLg } from "react-icons/bs";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.css";

// Assets
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";

// Styles main
import classNames from "classnames";
import "./App.scss";

//Login routes
import Login from "./pages/login/Login";
import ForgetPass from "./pages/login/ForgetPass";
import SetPass from "./pages/login/SetPass";
import ResetPassword from "./pages/login/ResetPassword";
import RegisterNow from "./pages/login/RegisterNow";
import PrivacyPolicy from "./ui/PrivacyPolicy/PrivacyPolicy";
import { ProtectedRoute } from "./ProtectedRoute";
import { ProtectedRouteAuth } from "./ProtectedRouteAuth";
// Routes
import Dashboard from "./components/Dashboard";
import Support from "./pages/Support/Screens/index";
import ChatIndex from "./pages/Chats/Screens/ChatIndex";
import BecomePartner from "./pages/login/BecomePartner";
import Debtor from "./pages/Management/Debtors/Screens";
import Brokers from "./pages/Management/Brokers/Brokers";
import UserProfile from "./pages/UserProfile/UserProfile";
import Document from "./pages/Documents/Screens/index";
import ClientIndex from "./pages/Management/Clients/index";
import ProspectIndex from "./pages/Management/Prospect/index";
import Deposit from "./pages/Deposits/Screens/Deposit/Deposit";
import AddNewBroker from "./pages/Management/Brokers/Components/AddNewBroker";
import UserIndex from "./pages/UserManagement/Screens/UserManage/Screens/UserIndex";
import RolesRights from "./pages/UserManagement/Screens/RoleRights/Screens/index";
import ClientChecklist from "./pages/UnderWriting/ClientChecklist/ClientChecklist";
import ReceivingAccounts from "./pages/Deposits/Screens/ReceivingAccount/ReceivingAccounts";
import BankAccount from "./pages/Deposits/Screens/BankAccount/BankAccount";
import AddNewUser from "./pages/UserManagement/Screens/UserManage/Screens/AddNewUser";
import AddNewRole from "./pages/UserManagement/Screens/RoleRights/Screens/AddNewRole";
import Notification from "./pages/Notifications/Screens/Notification";

import ReportsMain from "./pages/Reports";
import SettingsMain from "./pages/Settings";
import ReportIndex from "./pages/Reports/ReportGenerate/index";
import AnnoucementIndex from "./pages/Settings/Annoucements/Screens";
import AddNewProspect from "./pages/Management/Prospect/Components/HeaderComponent/Headercomponent";
import ScheduleOfFunding from "./pages/Operations/Screens/Transactions/ScheduleOfFunding/Screens/index";
import Collections from "./pages/Operations/Screens/Transactions/Collections/Screens/index";
import AddNewCollection from "./pages/Operations/Screens/Transactions/Collections/Components/AddNewCollection/index";
import CreditRequest from "./pages/Operations/Screens/CreditRequest/Screens";

import Invoices from "./pages/Operations/Screens/Transactions/Invoices/Screens";
import AddNewDebtor from "./pages/Management/Debtors/Components/HeaderComponent/AddNewDebtor";
import Headercomponent from "./pages/Management/Clients/Components/HeaderDetails/HeaderComponent";

import TermsConditionComponent from "./pages/Management/Brokers/Components/TermsConditionComponent";
import TermsheetDetail from "./pages/Management/Prospect/Components/ProspectComponent/TermsheetDetail";

import ScheduleOfAssignment from "./pages/Operations/Screens/Transactions/ScheduleOfAssignment/Screens";
import AddInvoiceComponenet from "./pages/Operations/Screens/Transactions/Invoices/Components/AddInvoiceComponenet";
import HoldAccounts from "./pages/Operations/Screens/Transactions/HoldAccounts/Screens/index";
import AddNewScheduleOfAssignment from "./pages/Operations/Screens/Transactions/ScheduleOfAssignment/Components/AddNewScheduleOfAssignment";
import AddNewCreditRequest from "./pages/Operations/Screens/CreditRequest/Components/AddNewCreditRequest";

// Constants
import { APP_SIDEBAR_MENU, CLIENT_CREDIT_GRID_HEADERS, DEBTOR_CREDIT_GRID_HEADERS, ROLE_NAME } from "./utils/Constants/global";

// Helper Function
import { SideBarHandler } from "./utils/HelperFuctions/sideBarFormDirty";

// UI Components
import CustomConfirmDialog from "./ui/CustomConfirmDialog/CustomConfirmDialog";

// API'S
import { getLoggedInUser } from "./services/user-management/user";
import Chargebacks from "./pages/Operations/Screens/Transactions/Chargebacks/Screens";
import Reserves from "./pages/Operations/Screens/Transactions/Reserves/Screens";
import CreateRRBatch from "./pages/Operations/Screens/Transactions/Reserves/Components/CreateRRBatch";
import { updateIsAllowed } from "./utils/HelperFuctions/validatePermissions";
import DebtorDashboard from "./pages/DebtorModule/DebtorDashboard/Screens/Dashboard";
import { MANAGMENT_RIGHTS, SETTINGS_RIGHTS, TRANSACTION_RIGHTS, USERMANAGMENT_RIGHTS } from "./utils/Constants/rights";
import CustomLoading from "./ui/CustomSpinner/custom_spinner";
import PoliciesIndex from "./pages/Settings/Policies/Screens";
import GeneralLedger from "./pages/GeneralLedger/Screens";
import FronllineFundingRegisterNow from "./pages/login/FronllineFundingRegisterNow";
import TermSheetSignature from "./pages/SignatureLinks/TermSheetSignature";
import LinkExpireScreen from "./LinkExpireScreen";
import VerificationCode from "./pages/login/VerificationCode";

const App = () => {
    // Ref
    const copyTooltipRef = useRef();
    const location = useLocation();
    const { user } = useSelector(loginState);
    const { screens } = useSelector(mainViewState);

    const socketContext = useContext(SocketContext);
    const dispatch = useDispatch();

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");

    // View State
    const [ripple, setRipple] = useState(true);
    const [isOpeneing, setIsOpeneing] = useState(false);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [menu, setMenu] = useState([
        {
            items: [],
            isAllowed: false,
        },
    ]);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        const isCondition = event.item.label === "Management" || event.item.label === "Transactions" || event.item.label === "Credit Request";
        dispatch(onSideBarMain(event.item.label));
        if (!isCondition) {
            SideBarHandler();
        }
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const isDesktop = () => {
        return window.innerWidth >= 992;
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 116) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        window.addEventListener("popstate", () => {
            window.history.pushState(null, "", window.location.pathname);
        });
    }, []);

    useEffect(() => {
        const getUserData = async () => {
            try {
                if (localStorage.getItem("accessToken")) {
                    let resp = await getLoggedInUser();
                    dispatch(setUser(resp?.data?.user));
                    if (resp?.data?.user) {
                        const updated_menu = updateIsAllowed(JSON.parse(JSON.stringify(APP_SIDEBAR_MENU)), resp?.data?.user?.rights);
                        setMenu([{ items: updated_menu[0].items, isAllowed: true }]);
                        socketContext.createSocketInstance(resp?.data?.user?.id);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (!user) {
            getUserData();
        } else {
            const updated_menu = updateIsAllowed(JSON.parse(JSON.stringify(APP_SIDEBAR_MENU)), user?.rights);
            setMenu([{ items: updated_menu[0].items, isAllowed: true }]);

            socketContext.createSocketInstance();
        }
    }, [user]);

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const redirectToLogin = () => {
        return <Redirect to="/login" />;
    };
    const onEventHandler = (event_name, resp) => {
        switch (event_name) {
            case process.env.REACT_APP_NOTIFICATION_URL + `new_chat:${user.id}`:
                dispatch(onChatNewMessage(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `change_status:${user.id}`:
                dispatch(setDebtorStatusChanged(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `invoice_created:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `new_prospect_application:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `new_debtor_request:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `new_broker_application:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `approved_via_email:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `denied_via_email:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `new_policy_created:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `debtor_approved:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `debtor_denied:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `new_broker_application:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `advocate_prospect_assigned:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `payment_completed:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `reserve_release_complete:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `invoice_disapproved:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `schedule_soa_approved:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `ur_missing:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `invoice_verification_request:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `due_date_reached:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `debtor_noa_acknowledged:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `debtor_reminder:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `debtor_new_noa:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `exceeded_escrows:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `schedule_new_soa:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `prospect_new_application:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `prospect_term_sign:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
            case process.env.REACT_APP_NOTIFICATION_URL + `credit_limit_consumed:${user.id}`:
                dispatch(setFetchNewNotification(true));
                break;
        }
    };

    useEffect(() => {
        if (socketContext.socket !== undefined && socketContext.socket !== null && user) {
            socketContext.socket.onAny((eventName, data) => {
                onEventHandler(eventName, data);
            });
        }
    }, [socketContext.socket, user]);

    useEffect(() => {
        if (menu) {
            if (screens.chat.isNewMessage) {
                const chatsIndex = menu[0].items.findIndex((item) => item.label === "Chats");
                if (chatsIndex != -1) {
                    menu[0].items[chatsIndex].label = "New messages";
                    setMenu(menu);
                }
            } else {
                const chatsIndex = menu[0].items.findIndex((item) => item.label === "New messages");
                if (chatsIndex != -1) {
                    menu[0].items[chatsIndex].label = "Chat";
                    setMenu(menu);
                }
            }
        }

        setTimeout(() => {
            setLoading(true);
            setLoading(false);
        }, 0);
    }, [screens.chat.isNewMessage]);

    const confirmHanlder = () => {
        SideBarHandler(screens.main.sideBarName);
        setIsOpeneing(false);
    };
    //Revoke Emoji
    const revokeEmojisAndPreventPaste = () => {
        document.addEventListener("input", (event) => {
            const inputElement = event.target;
            if (inputElement.type !== "file" && (inputElement.tagName === "INPUT" || inputElement.tagName === "TEXTAREA")) {
                const sanitizedValue = inputElement.value.replace(/[\uD800-\uDFFF]/gu, "");
                inputElement.value = sanitizedValue;
            }
        });

        document.addEventListener("paste", (event) => {
            event.preventDefault(); // Prevent the default paste behavior
        });
    };

    useEffect(() => {
        revokeEmojisAndPreventPaste(); // Call the function when the component mounts

        // Clean up event listeners when the component is unmounted
        return () => {
            document.removeEventListener("input", revokeEmojisAndPreventPaste);
            document.removeEventListener("paste", revokeEmojisAndPreventPaste);
        };
    }, []);
    // document.addEventListener("keydown", function (event) {
    //     // Define the key combination you want to prevent (e.g., Ctrl + Alt + Delete)
    //     const combinationToPrevent = event.ctrlKey && event.altKey && event.key === "Delete";
    //     if (combinationToPrevent) {
    //         event.preventDefault();
    //         console.log("Prevented Ctrl + Alt + Delete");
    //     }
    // });
    document.addEventListener("keydown", function (event) {
        // Define the key combination you want to prevent (e.g., Meta + Alt + Dot)
        const combinationToPrevent = event.metaKey && event.key === ".";
        if (combinationToPrevent) {
            event.preventDefault();
            console.log("Prevented Meta + Alt + Dot");
        }
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <Router>
                <Route path="/" component={redirectToLogin} exact />
                <Route path="/signature" component={TermSheetSignature} exact />
                <Route path="/linkexpire" component={LinkExpireScreen} exact />
                <Route path="/verificatiocode" component={VerificationCode} exact />
                <Route path="/setpassword/:guid" component={SetPass} exact />
                {/* <Route path="/SignedTermSheet" component={TermSheetSignature} exact />  */}

                <ProtectedRouteAuth path="/login" exact>
                    <Login />
                </ProtectedRouteAuth>

                {/* <ProtectedRouteAuth path="/signature" exact={true}>
                    <TermSheetSignature />
                </ProtectedRouteAuth> */}

                {/* <ProtectedRouteAuth path="/setpassword/:guid" exact={true}>
                    <SetPass />
                </ProtectedRouteAuth> */}
                <ProtectedRouteAuth path="/resetpassword" exact={true}>
                    <ResetPassword />
                </ProtectedRouteAuth>
                <ProtectedRouteAuth path="/forgetpass" exact={true}>
                    <ForgetPass />
                </ProtectedRouteAuth>
                {/* <Route path="/verificatiocode" component={VerificationCode} exact>
                    {/* <VerificationCode /> 
                </Route> */}
                <ProtectedRouteAuth path="/becomepartner" exact>
                    <BecomePartner />
                </ProtectedRouteAuth>
                <ProtectedRouteAuth path="/registernow" exact>
                    <RegisterNow />
                </ProtectedRouteAuth>
                <ProtectedRouteAuth path="/frontlineregisternow" exact>
                    <FronllineFundingRegisterNow />
                </ProtectedRouteAuth>
                <ProtectedRouteAuth path="/privacypolicy" exact>
                    <PrivacyPolicy />
                </ProtectedRouteAuth>

                {localStorage.getItem("accessToken") && (
                    <>
                        <AppTopbar
                            onToggleMenuClick={onToggleMenuClick}
                            layoutColorMode={layoutColorMode}
                            mobileTopbarMenuActive={mobileTopbarMenuActive}
                            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                        />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            {loading ? (
                                ""
                            ) : (
                                <AppMenu
                                    model={menu}
                                    onMenuItemClick={(e) => {
                                        onMenuItemClick(e);
                                    }}
                                    layoutColorMode={layoutColorMode}
                                />
                            )}
                        </div>
                    </>
                )}

                <div
                    className="layout-main-container"
                    style={{
                        minHeight: localStorage.getItem("accessToken") ? "100vh" : "auto",
                        padding: localStorage.getItem("accessToken") ? "4.5rem 1rem 1rem 4rem" : "",
                    }}
                >
                    <div className="layout-main">
                        <ProtectedRoute path="/home" required_rights={[]} optional_rights={[]} parent_path="" exact>
                            <CustomLoading />
                        </ProtectedRoute>

                        <Route path="/SignedTermSheet" exact>
                            <TermSheetSignature />
                        </Route>

                        <ProtectedRoute path="/dashboard" required_rights={APP_SIDEBAR_MENU[0].items[0].required_rights} optional_rights={[]} parent_path="" exact>
                            {!user ? <CustomLoading /> : user?.userRoles[0]["roleName"] === ROLE_NAME.DEBTOR ? <DebtorDashboard /> : <Dashboard />}
                        </ProtectedRoute>
                        {/*  <ProtectedRoute path="/debtordashboard" required_rights={APP_SIDEBAR_MENU[0].items[1].required_rights ?? []} optional_rights={[]} exact>
                            <DebtorDashboard />
                        </ProtectedRoute> */}

                        {/*  <ProtectedRoute path="/documentsign" required_rights={[]} optional_rights={[]} parent_path="" exact>
                            <DocumentSign />
                        </ProtectedRoute> */}

                        <ProtectedRoute path="/ClientIndex" required_rights={APP_SIDEBAR_MENU[0].items[2].items[1].required_rights} optional_rights={[]} parent_path="" exact>
                            <ClientIndex />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/clientdetails"
                            required_rights={[MANAGMENT_RIGHTS.clients.selection]}
                            optional_rights={[MANAGMENT_RIGHTS.clients.profile, MANAGMENT_RIGHTS.clients.documents, MANAGMENT_RIGHTS.clients.notice_of_assignment]}
                            parent_path="/clientindex"
                            exact
                        >
                            <Headercomponent />
                        </ProtectedRoute>

                        <ProtectedRoute path="/debtormain" required_rights={APP_SIDEBAR_MENU[0].items[2].items[2].required_rights} optional_rights={[]} parent_path="" exact>
                            <Debtor />
                        </ProtectedRoute>

                        {/* <ProtectedRoute path="/debtorscoring" exact required_rights={[]} optional_rights={[]} parent_path="">
                            <DabtorScoring />
                        </ProtectedRoute> */}

                        <ProtectedRoute path="/invoices" required_rights={APP_SIDEBAR_MENU[0].items[3].items[0].required_rights} optional_rights={[]} parent_path="" exact>
                            <Invoices />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/addnewinvoice"
                            required_rights={[TRANSACTION_RIGHTS.invoices.view]}
                            optional_rights={[TRANSACTION_RIGHTS.invoices.add_new_invoice, TRANSACTION_RIGHTS.invoices.invoice_selection]}
                            parent_path="/invoices"
                            exact
                        >
                            <AddInvoiceComponenet />
                        </ProtectedRoute>

                        <ProtectedRoute path="/scheduleofassignment" required_rights={APP_SIDEBAR_MENU[0].items[3].items[1].required_rights} optional_rights={[]} parent_path="" exact>
                            <ScheduleOfAssignment />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/addnewscheduleofassignment"
                            required_rights={[TRANSACTION_RIGHTS.schedule_of_assignment.view]}
                            optional_rights={[TRANSACTION_RIGHTS.schedule_of_assignment.add_new_schedule, TRANSACTION_RIGHTS.schedule_of_assignment.schedule_selection]}
                            parent_path="/scheduleofassignment"
                            exact
                        >
                            <AddNewScheduleOfAssignment />
                        </ProtectedRoute>

                        <ProtectedRoute path="/scheduleoffunding" required_rights={APP_SIDEBAR_MENU[0].items[3].items[2].required_rights} optional_rights={[]} parent_path="" exact>
                            <ScheduleOfFunding />
                        </ProtectedRoute>

                        <ProtectedRoute path="/collections" required_rights={APP_SIDEBAR_MENU[0].items[3].items[3].required_rights} optional_rights={[]} parent_path="" exact>
                            <Collections />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/addnewcollection"
                            required_rights={[TRANSACTION_RIGHTS.collections.view, TRANSACTION_RIGHTS.collections.add_new_collection]}
                            optional_rights={[]}
                            parent_path="/collections"
                            exact
                        >
                            <AddNewCollection />
                        </ProtectedRoute>

                        <ProtectedRoute path="/unappliedcash" required_rights={APP_SIDEBAR_MENU[0].items[3].items[4].required_rights} optional_rights={[]} vparent_path="" exact>
                            <HoldAccounts />
                        </ProtectedRoute>

                        <ProtectedRoute path="/reserves" required_rights={APP_SIDEBAR_MENU[0].items[3].items[5].required_rights} optional_rights={[]} parent_path="" exact>
                            <Reserves />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/createrrbatch"
                            required_rights={[TRANSACTION_RIGHTS.reserves.view, TRANSACTION_RIGHTS.reserves.create_rr_batch]}
                            optional_rights={[]}
                            parent_path="/reserves"
                            exact
                        >
                            <CreateRRBatch />
                        </ProtectedRoute>

                        <ProtectedRoute path="/chargebacks" required_rights={APP_SIDEBAR_MENU[0].items[3].items[6].required_rights} optional_rights={[]} parent_path="" exact>
                            <Chargebacks />
                        </ProtectedRoute>
                        {/*
                        <ProtectedRoute path="/brokerpayment" exact>
                            <BrokerPayment />
                        </ProtectedRoute> */}
                        {/* <ProtectedRoute path="/brokerdetails/:id" exact>
                        </ProtectedRoute> */}

                        <ProtectedRoute
                            path="/brokerdetails"
                            required_rights={[MANAGMENT_RIGHTS.brokers.view]}
                            optional_rights={[MANAGMENT_RIGHTS.brokers.add_new, MANAGMENT_RIGHTS.brokers.selection]}
                            parent_path="/brokers"
                            exact
                        >
                            <AddNewBroker />
                        </ProtectedRoute>

                        <ProtectedRoute path="/clientrequest" required_rights={APP_SIDEBAR_MENU[0].items[5].items[0].required_rights} optional_rights={[]} parent_path="" exact>
                            <CreditRequest isClientRequest={true} gridColumns={CLIENT_CREDIT_GRID_HEADERS} />
                        </ProtectedRoute>

                        <ProtectedRoute path="/debtorrequest" required_rights={APP_SIDEBAR_MENU[0].items[5].items[1].required_rights} optional_rights={[]} parent_path="" exact>
                            <CreditRequest isClientRequest={false} gridColumns={DEBTOR_CREDIT_GRID_HEADERS} />
                        </ProtectedRoute>

                        <ProtectedRoute path="/userindex" required_rights={APP_SIDEBAR_MENU[0].items[9].items[0].required_rights} optional_rights={[]} parent_path="" exact>
                            <UserIndex />
                        </ProtectedRoute>
                        <ProtectedRoute path="/rolesrights" required_rights={APP_SIDEBAR_MENU[0].items[9].items[1].required_rights} optional_rights={[]} parent_path="" exact>
                            <RolesRights />
                        </ProtectedRoute>

                        <ProtectedRoute path="/reports" required_rights={[]} optional_rights={APP_SIDEBAR_MENU[0].items[7].required_rights} parent_path="" exact>
                            <ReportsMain />
                        </ProtectedRoute>
                        <ProtectedRoute path="/reportindex" required_rights={[]} optional_rights={APP_SIDEBAR_MENU[0].items[7].required_rights} parent_path="/reports" exact>
                            <ReportIndex />
                        </ProtectedRoute>
                        <ProtectedRoute path="/supportmain" required_rights={APP_SIDEBAR_MENU[0].items[8].required_rights} optional_rights={[]} parent_path="" exact>
                            <Support />
                        </ProtectedRoute>

                        <ProtectedRoute path="/termsheetdetail" parent_path="" exact>
                            <TermsheetDetail />
                        </ProtectedRoute>
                        <ProtectedRoute path="/prospectindex" required_rights={APP_SIDEBAR_MENU[0].items[2].items[0].required_rights} optional_rights={[]} parent_path="" exact>
                            <ProspectIndex />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/addprospect"
                            required_rights={[MANAGMENT_RIGHTS.prospects.view]}
                            optional_rights={[MANAGMENT_RIGHTS.prospects.add_new, MANAGMENT_RIGHTS.prospects.selection]}
                            parent_path="/prospectindex"
                            exact
                        >
                            <AddNewProspect />
                        </ProtectedRoute>

                        <ProtectedRoute path="/brokers" required_rights={APP_SIDEBAR_MENU[0].items[2].items[3].required_rights} optional_rights={[]} parent_path="" exact>
                            <Brokers />
                        </ProtectedRoute>
                        <ProtectedRoute path="/clientchecklist" required_rights={APP_SIDEBAR_MENU[0].items[6].required_rights} optional_rights={[]} parent_path="" exact>
                            <ClientChecklist />
                        </ProtectedRoute>
                        {/* <ProtectedRoute path="/fieldsetting" exact>
                            <FieldSetting />
                        </ProtectedRoute> */}
                        <ProtectedRoute path="/documents" required_rights={APP_SIDEBAR_MENU[0].items[12].required_rights} optional_rights={[]} parent_path="" exact>
                            <Document />
                        </ProtectedRoute>
                        <ProtectedRoute path="/profile" exact>
                            {/*optional_rights={["dashboard_dashboardview"]} parent_path="" optional_rights={[]} required_rights={[]} */}
                            <UserProfile />
                        </ProtectedRoute>

                        <ProtectedRoute path="/deposits" parent_path="" exact>
                            <Deposit />
                        </ProtectedRoute>
                        <ProtectedRoute path="/receivingaccounts" parent_path="" exact>
                            <ReceivingAccounts />
                        </ProtectedRoute>
                        <ProtectedRoute path="/bankaccounts" parent_path="" exact>
                            <BankAccount />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/addnewuser"
                            required_rights={[USERMANAGMENT_RIGHTS.user_profile_view]}
                            optional_rights={[USERMANAGMENT_RIGHTS.user_profile_adduser, USERMANAGMENT_RIGHTS.user_profile_selection]}
                            parent_path="/userindex"
                            exact
                        >
                            <AddNewUser />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/addnewrole"
                            exact
                            required_rights={[USERMANAGMENT_RIGHTS.role_rights_view, USERMANAGMENT_RIGHTS.role_rights_addrole]}
                            optional_rights={[]}
                            parent_path="/rolesrights"
                        >
                            <AddNewRole />
                        </ProtectedRoute>

                        <ProtectedRoute
                            path="/editrole"
                            exact
                            required_rights={[USERMANAGMENT_RIGHTS.role_rights_view, USERMANAGMENT_RIGHTS.role_rights_selection]}
                            optional_rights={[]}
                            parent_path="/rolesrights"
                        >
                            <AddNewRole />
                        </ProtectedRoute>

                        <ProtectedRoute path="/notifications" exact>
                            <Notification />
                        </ProtectedRoute>

                        <ProtectedRoute path="/terms&conditions" required_rights={[MANAGMENT_RIGHTS.brokers.view, MANAGMENT_RIGHTS.brokers.selection]} optional_rights={[]} parent_path="" exact>
                            <TermsConditionComponent />
                        </ProtectedRoute>

                        <ProtectedRoute path="/chatindex" parent_path="/chatindex" exact>
                            <ChatIndex />
                        </ProtectedRoute>

                        <ProtectedRoute path="/addnewdebtor" exact required_rights={[MANAGMENT_RIGHTS.debtors.view]} optional_rights={[MANAGMENT_RIGHTS.debtors.add_new]} parent_path="/debtormain">
                            <AddNewDebtor />
                        </ProtectedRoute>

                        <ProtectedRoute path="/editdebtor" exact required_rights={[MANAGMENT_RIGHTS.debtors.view]} optional_rights={[MANAGMENT_RIGHTS.debtors.selection]} parent_path="/debtormain">
                            <AddNewDebtor />
                        </ProtectedRoute>

                        <ProtectedRoute path="/settings" exact required_rights={[]} optional_rights={APP_SIDEBAR_MENU[0].items[10].required_rights} parent_path="">
                            <SettingsMain />
                        </ProtectedRoute>
                        <ProtectedRoute path="/annoucements" exact required_rights={[SETTINGS_RIGHTS.announcments.view]} optional_rights={[]} parent_path="/settings">
                            <AnnoucementIndex />
                        </ProtectedRoute>
                        <ProtectedRoute path="/policies" exact required_rights={[SETTINGS_RIGHTS.policies.view]} optional_rights={[]} parent_path="/policies">
                            <PoliciesIndex />
                        </ProtectedRoute>

                        <ProtectedRoute path="/addnewcr" exact parent_path="">
                            <AddNewCreditRequest />
                        </ProtectedRoute>
                        <ProtectedRoute path="/generalledger" required_rights={APP_SIDEBAR_MENU[0].items[4].required_rights} optional_rights={[]} parent_path="" exact>
                            <GeneralLedger />
                        </ProtectedRoute>
                    </div>
                    {/* {localStorage.getItem("accessToken") && <AppFooter layoutColorMode={layoutColorMode} />} */}
                </div>
            </Router>

            <AppConfig
                rippleEffect={ripple}
                onRippleEffect={onRipple}
                inputStyle={inputStyle}
                onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode}
                onLayoutModeChange={onLayoutModeChange}
                layoutColorMode={layoutColorMode}
                onColorModeChange={onColorModeChange}
            />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

            {isOpeneing && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpeneing(false)}
                    handler={confirmHanlder}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                    // secondtext="This action cannot be reverted."
                />
            )}
        </div>
    );
};

export default App;
