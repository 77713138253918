import React from "react";

// Icons
import { BsCardChecklist, BsCardList, BsCreditCard2Front } from "react-icons/bs";

// Styles
import "./addnewcollection.scss";
import { BreadCrumb } from "primereact/breadcrumb";

// bread Crumbs
const BreakcrumbItems = [{ label: "Collections", url: "/collections" }, { label: "Add New Collection" }];
const homeIcon = { icon: "bi bi-arrow-repeat" };

const Header = (props) => {
    const { setHeader, setSubHeader, activeCard, setActiveCard, setCollectionType } = props;

    const ViewChangeHandler = (value) => {
        setActiveCard(value);
        if (value === 0) {
            setHeader("Collection Against Invoice(s)");
            setCollectionType(1);
            setSubHeader("");
        } else if (value === 1) {
            setHeader("Combined Collection Against CB & Invoice(s)");
            setCollectionType(3);
            setSubHeader("Chargebacks ");
        } else {
            setHeader("Collection Against Chargeback");
            setCollectionType(2);
            setSubHeader("Chargebacks ");
        }
    };

    return (
        <>
            <div className="addnewcollection_card">
                <div className="grid">
                    <div className="col-12">
                        <h4 className="pt-2">
                            <BreadCrumb model={BreakcrumbItems} home={homeIcon} />
                        </h4>
                    </div>

                    <div className="md:col-4 col-12 text-center" onClick={() => ViewChangeHandler(0)}>
                        <div className={`card ${activeCard === 0 ? "active_class" : ""}`}>
                            <div className="collection_card_alignment">
                                <div className="icon_bg">
                                    <BsCardList className="circle_icon_class" />
                                </div>
                                <h6> Collection Against Invoice</h6>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-4 col-12 text-center" onClick={() => ViewChangeHandler(1)}>
                        <div className={`card ${activeCard === 1 ? "active_class" : ""}`}>
                            <div className="collection_card_alignment">
                                <div className="icon_bg">
                                    <BsCardChecklist className="circle_icon_class" />
                                </div>
                                {/* <h6 className="pl-3"> Combined Collection Against CB & Invoices</h6> */}
                                <h6> Collection Against CB & Invoices</h6>
                            </div>
                        </div>
                    </div>

                    <div className="md:col-4 col-12 text-center" onClick={() => ViewChangeHandler(2)}>
                        <div className={`card ${activeCard === 2 ? "active_class" : ""}`}>
                            <div className="collection_card_alignment">
                                <div className="icon_bg">
                                    <BsCreditCard2Front className="circle_icon_class" />
                                </div>
                                <h6> Collection Against Chargeback</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
