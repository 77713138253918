import React, { useContext, useEffect, useState } from "react";

//Components
import CustomAddNewButton from "../../../ui/CustomAddNewButton/CustomAddNewButton";
// Context
import { ToastContext } from "../../../context/toast";
//Redux
import { useDispatch } from "react-redux";
import { onSupportDataMutated } from "../../../redux/main-view";
// API'S
import { assignTicket, getAssignUser } from "../../../services/support";
import CustomModalSpinner from "../../../ui/CustomModalSpinner/CustomModalSpinner";
import { InputText } from "primereact/inputtext";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";
import { ROLE_NAME } from "../../../utils/Constants/global";
const AssignTicketModal = (props) => {
    const { onHide, selectedUsers } = props;
    // Dispatch
    const dispatch = useDispatch();
    // Context
    const toastContext = useContext(ToastContext);
    // States
    const [options, setOptions] = useState({
        pageNumber: 1,
        size: 10,
        search: "",
        status: null,
        isLogin: true,
    });
    const [users, setUsers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedUserId, setSelectedUserId] = useState(null);
    // View States
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmits = async () => {
        setIsSaving(true);
        try {
            const form = new FormData();
            const temp = [];
            selectedUsers.map((item) => {
                temp.push(item.id);
            });
            form.append("id", JSON.stringify(temp));
            form.append("user_id", selectedUserId);
            const res = await assignTicket(form);

            if (res) {
                toastContext.assignmentToast("Ticket");
                dispatch(onSupportDataMutated());
                setSelectedUserId(null);
                setUsers([]);
                setIsSaving(false);
                onHide();
            } else {
                onHide();
                setIsSaving(false);
                toastContext.showMessage("Assignment Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            onHide();
            setIsSaving(false);
            toastContext.showMessage("Assignment Failed", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const handleChange = (e) => {
        const temp = textExtraSpaceRemoval(e.target.value);
        const result = toTitleCaseWhileTyping(temp);
        setGlobalFilter(textExtraSpaceRemoval(result));
        setOptions({ ...options, search: result });
    };

    const fetchingValues = async () => {
        setIsLoading(true);
        const res = await getAssignUser(options);
        let data = res?.data?.records?.filter((rec) => {
            return rec.role_Name === ROLE_NAME.ADMIN || rec.role_Name === ROLE_NAME.ADMINNEW;
        });
        setUsers(data ?? []);
        setIsLoading(false);
        return;
    };
    // // UseEffects
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchingValues();
        }, 350);
        return () => clearTimeout(delayDebounceFn);
    }, [options]);
    return (
        <>
            <form>
                <div className="grid">
                    <div className="col-12">
                        <label>
                            <b>
                                Assign To<span className="clr_red">*</span>
                            </b>
                        </label>
                        <div className="pt-3">
                            <div className="search_input_width">
                                <span className="p-input-icon-right text ">
                                    <InputText type="text" value={globalFilter} onChange={handleChange} placeholder="Search by name" className={`p-3 ${"border_class"}`} />
                                    <i className="pi pi-search" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pl-3">
                        {isLoading ? (
                            <CustomModalSpinner height="200px" />
                        ) : users.length === 0 ? (
                            <div>No record available</div>
                        ) : (
                            <div className="grid">
                                {users.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`pb-2 assign_ticket_efffect col-6 ${selectedUserId === item.user_Id ? "assign_ticket" : ""} `}
                                            onClick={() => setSelectedUserId(item.user_Id)}
                                        >
                                            {item.first_Name + " " + (item.last_Name ?? "")}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="col-12 text-right">
                        <CustomAddNewButton title="Save" isDisabled={isSaving} type="button" onClick={() => (isSaving ? null : handleSubmits())} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AssignTicketModal;
