import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//ui
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import ZipCodesMenuComponent from "../../../../ui/ZipCodesMenu/ZipCodesMenuComponent";
//services
import { postBroker } from "../../../../services/broker";
import { getCountryCode } from "../../../../services/country-code";
import { getZipCode } from "../../../../services/zip-code";
import { getStates } from "../../../../services/states";
import { ToastContext } from "../../../../context/toast";
//Redux
import { useSelector } from "react-redux";
//PrimeReact Components
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import toTitleCaseWhileTyping from "../../../../utils/HelperFuctions/cleanData";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { loginState } from "../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../utils/Constants/rights";
import { checkBrokerPathname } from "../../../../utils/HelperFuctions/getPathNameForProspect";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";
const BrokerFormComponent = (props) => {
    // Props
    const { showBecomePartnerButton, data, dropdownfetchStatus = true, formDisabled } = props;

    // use  location to get the pathname
    const location = useLocation();
    const { user } = useSelector(loginState);
    //useStates
    const [countryCode, setCountryCode] = useState([]);
    const [loading, setLoading] = useState(false);
    const [zipCodes, setZipCode] = useState([]);

    const [zipCodeSearch, setZipCodeSearch] = useState(true);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [states, setStates] = useState([]);
    const [brokerId, setBrokerId] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [disableLicenseMask, setDisableLicenseMask] = useState(true);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
        telephone_code: "+1",
        fax_code: "+1",
    });

    const toast = useContext(ToastContext);

    // use History
    const history = useHistory();
    //validation
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("First Name is required"),
        email: Yup.string().required("Email is required").email("Invalid Email"),
        phone: Yup.string().required("Phone No is required"),
        street_address: Yup.string().required("Address is required"),
        zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
        fax: Yup.string().required("Fax No is required"),
        ssn: Yup.string().required("Tax ID/SSN is required"),
        license_number: Yup.string().required("License No is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            first_name: "",
            email: "",
            country_phone_code: "+1",
            zip: "",
            country_fax_code: "+1",
            country_telephone_code: "+1",
            street_address: "",
            ssn: "",
            license_number: "",
            phone: "",
            fax: "",
            telephone: "",
            state: "",
            city: "",
        },
        onSubmit: async (data) => {
            if (data.country_telephone_code != "" && data.telephone && data.telephone != "") {
                data.country_telephone_code = data.country_telephone_code;
            } else {
                data.country_telephone_code = "+1";
            }

            if (brokerId != null) {
                data.id = brokerId;
            }

            const pathname = location.pathname;
            const isFound = checkBrokerPathname(pathname);

            if (isFound) {
                data["company_id"] = user?.company_id;
            }

            //data
            data = {
                ...data,
                ssn: data.ssn.toString().replaceAll("-", ""),
                fax: data.fax.toString().replaceAll("-", ""),
                phone: data.phone.toString().replaceAll("-", ""),
                telephone: data.telephone && data.telephone != "" ? data.telephone.toString().replaceAll("-", "") : "",
                license_number: data.license_number.toString().replaceAll("-", ""),
                country_phone_code: data.country_phone_code !== "+1" ? data.country_phone_code.phone_code : "+1",
                country_fax_code: data.country_fax_code !== "+1" ? data.country_fax_code.phone_code : "+1",
                country_telephone_code: data.country_telephone_code !== "+1" ? data.country_telephone_code.phone_code : "+1",
            };
            // Submit Form
            setDisableButton(true);
            setIsLoading(true);
            try {
                const res = await postBroker(data);
                if (res.data.status == true) {
                    if (showBecomePartnerButton) {
                        toast.showMessage("Application Submission", "Your application has been submitted successfully!", "success");
                        history.push("/login");
                    } else {
                        history.push("/brokers");
                        if (data.id) {
                            toast.updateToast("Broker");
                        } else {
                            toast.createdToast("Broker");
                        }
                    }
                } else {
                    toast.showMessage("Error", res.data.message, "error");
                    setDisableButton(false);
                    setIsLoading(false);
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setDisableButton(false);
            setIsLoading(false);
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, toTitleCaseWhileTyping(value));
    };

    const getCountryCodes = () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                if (res.data.status) {
                    setCountryCode(res.data.countries);
                }
            });
            getStates().then((res) => {
                if (res.data.status) {
                    setStates(res.data.states);
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }

        setLoading(false);
    };

    const getZip = async (value) => {
        if (zipCodeSearch) {
            let data = {
                zip: value,
            };
            try {
                let res = await getZipCode(data);
                if (res.data.codes.length > 0) {
                    setRcordNotFoundMsg("");
                    setZipCode(res.data.codes);
                } else {
                    setZipCode([]);
                    setRcordNotFoundMsg("No record available.");
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        }
    };
    const selectedZipCode = (zip) => {
        setZipCodeSearch(false);
        setDisableLicenseMask(false);
        formik.values.zip = zip.zip;
        formik.values.state = zip.full_state;
        formik.values.city = zip.city;
        formik.setFieldError("state", "");
        formik.setFieldError("city", "");
        formik.setFieldError("zip", "");
        setZipCode([]);
    };

    const onLandingScreenHandler = () => {
        if (!validateUserPermissions([MANAGMENT_RIGHTS.brokers.selection], user?.rights)) {
            history.goBack();
        }
    };

    useEffect(() => {
        if (formik.values.zip && formik.values.zip.length >= 4 && zipCodeSearch) {
            getZip(formik.values.zip);
        } else if (formik.values.zip.length < 4) {
            setZipCode([]);
            setZipCodeSearch(true);
            formik.values.state = "";
            formik.values.city = "";
        }
    }, [formik.values.zip]);

    useEffect(() => {
        // Method
        const apiCall = async () => {
            setZipCodeSearch(false);
            setLoading(true);
            formik.setFieldValue("first_name", data.first_name);
            formik.setFieldValue("last_name", data.last_name);
            formik.setFieldValue("email", data.email);
            formik.setFieldValue("country_phone_code", data.country_phone_code);
            formik.setFieldValue("phone", data.phone);
            formik.setFieldValue("country_fax_code", data.country_fax_code);
            formik.setFieldValue("fax", data.fax);
            formik.setFieldValue("street_address", data.street_address);
            formik.setFieldValue("zip", data.zip);
            formik.setFieldValue("state", data.state);
            formik.setFieldValue("city", data.city);
            formik.setFieldValue("ssn", data.ssn);
            formik.setFieldValue("country_telephone_code", data.country_telephone_code);
            formik.setFieldValue("state_of_formation", data.state_of_formation);
            formik.setFieldValue("telephone", data.telephone);
            setPhoneCodesPlaceholders({
                phone_code: data.country_phone_code,
                telephone_code: data.country_telephone_code,
                fax_code: data.country_fax_code,
            });
            formik.setFieldValue("license_number", data.license_number.replaceAll(/\s/g, ""));
            setBrokerId(data.id);
            setDisableButton(false);
            setIsLoading(false);
        };

        if (Object.keys(data).length > 0) {
            onLandingScreenHandler();
            apiCall();
        }

        if (dropdownfetchStatus) {
            getCountryCodes();
        }
    }, []);

    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            history.push("/brokers");
        }
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    {/* First Name */}
                    <CustomInputField
                        className="col-12 md:col-3"
                        iden="first_name"
                        label="First Name "
                        mandatory="*"
                        formik={formik}
                        placeHolder="Enter First Name"
                        maxLength={30}
                        keyfilter={/^[A-Za-z]+$/}
                        disabled={formDisabled}
                        onChange={inputHandler}
                    />
                    {/* Last Name */}
                    <CustomInputField
                        className="col-12 md:col-3"
                        iden="last_name"
                        label="Last Name "
                        formik={formik}
                        placeHolder="Enter Last Name"
                        maxLength={30}
                        keyfilter={/^[A-Za-z]+$/}
                        disabled={formDisabled}
                        onChange={inputHandler}
                    />
                    {/* Email */}
                    <CustomInputField
                        className="col-12 md:col-3"
                        iden="email"
                        type="text"
                        label="Email"
                        mandatory="*"
                        formik={formik}
                        placeHolder="Enter Email"
                        maxLength={50}
                        disabled={formDisabled}
                    />
                    {/* Phone No */}
                    <div className="md:col-3 col-12">
                        <label htmlFor="phone" className="lbl">
                            <b>
                                Phone No <span className="clr_red">*</span>
                            </b>
                        </label>
                        <div className="border_div">
                            <Dropdown
                                id="country_phone_code"
                                name="country_phone_code"
                                value={formik.values.country_phone_code}
                                onChange={formik.handleChange}
                                className="custom_width_country"
                                options={countryCode}
                                optionLabel="phone_code"
                                placeholder={phoneCodesPlaceholders.phone_code}
                                filter
                                filterBy="phone_code"
                                emptyMessage="No results found"
                                disabled={loading || formDisabled}
                            />
                            <InputMask
                                name="phone"
                                id="phone"
                                mask="999-999-9999"
                                value={formik.values.phone}
                                placeholder="999-999-9999"
                                onChange={formik.handleChange}
                                className="custom_width_phone"
                                disabled={formDisabled}
                            ></InputMask>
                        </div>
                        {getFormErrorMessage("phone")}
                    </div>
                    {/* Fax No */}
                    <div className="md:col-3 col-12">
                        <label htmlFor="faxno" className="lbl">
                            <b>
                                Fax No
                                <span className="clr_red">*</span>
                            </b>
                        </label>
                        <div className="border_div">
                            <Dropdown
                                id="country_fax_code"
                                name="country_fax_code"
                                value={formik.values.country_fax_code}
                                onChange={formik.handleChange}
                                className="custom_width_country"
                                options={countryCode}
                                optionLabel="phone_code"
                                placeholder={phoneCodesPlaceholders.fax_code}
                                filter
                                filterBy="phone_code"
                                emptyMessage="No results available"
                                disabled={loading || formDisabled}
                            />

                            <InputMask
                                name="fax"
                                id="fax"
                                mask="999-999-9999"
                                value={formik.values.fax}
                                placeholder="999-999-9999"
                                onChange={formik.handleChange}
                                className="custom_width_phone"
                                disabled={formDisabled}
                            ></InputMask>
                        </div>
                        {getFormErrorMessage("fax")}
                    </div>
                    {/* Address */}
                    <CustomInputField
                        className="col-12 md:col-9"
                        iden="street_address"
                        label="Address"
                        mandatory="*"
                        formik={formik}
                        placeHolder="Enter Address"
                        maxLength={150}
                        disabled={formDisabled}
                    />
                    {/* Zip CODE */}
                    <div className="col-12 md:col-3">
                        <div className="zip_menu_position">
                            <CustomInputField
                                className=""
                                iden="zip"
                                label="Zip Code"
                                mandatory="*"
                                formik={formik}
                                placeHolder="Search..."
                                maxLength={5}
                                keyfilter={/^[0-9\b]+$/}
                                disabled={formDisabled}
                            />
                            <ZipCodesMenuComponent zipCodes={zipCodes} handleSelectedZipCodes={selectedZipCode} />
                            <small className="p-error">{recordNotFoundMsg}</small>
                        </div>
                    </div>
                    {/* State */}
                    <CustomInputField className="col-12 md:col-3" iden="state" label="State" formik={formik} disabled={formDisabled || true} />
                    {/* City */}
                    <CustomInputField className="col-12 md:col-3" iden="city" label="City" formik={formik} disabled={formDisabled || true} />
                    {/* License Number */}
                    <CustomInputField
                        className="col-12 md:col-3"
                        iden="license_number"
                        label="License No"
                        mandatory="*"
                        type="text"
                        formik={formik}
                        placeHolder="Enter License No"
                        maxLength={20}
                        keyfilter="alphanum"
                        disabled={formDisabled ? true : brokerId && !user?.sensitive_information_disclosure ? true : false}
                    />
                    {/* Federal Employer */}

                    {
                        user?.sensitive_information_disclosure ? 
                        (
                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="ssn"
                                mandatory="*"
                                label="Federal Employer Tax ID/SSN"
                                formik={formik}
                                placeHolder="Enter Federal Employer Tax ID/SSN"
                                mask={"999-99-9999"}
                                disabled={formDisabled}
                            />
                        ) : !user?.sensitive_information_disclosure && !brokerId ? (
                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="ssn"
                                mandatory="*"
                                label="Federal Employer Tax ID/SSN"
                                formik={formik}
                                placeHolder="Enter Federal Employer Tax ID/SSN"
                                mask={"999-99-9999"}
                                disabled={formDisabled}
                            />
                        )  :  !user?.sensitive_information_disclosure && brokerId  ? (
                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="ssn"
                                mandatory="*"
                                label="Federal Employer Tax ID/SSN"
                                formik={formik}
                                placeHolder="Enter Federal Employer Tax ID/SSN"
                                disabled={true}
                            />
                        ) : ""

                    }

                    {/* Telephone No */}
                    <div className="md:col-4 col-12">
                        <label htmlFor="phone" className="lbl">
                            <b>Telephone No</b>
                        </label>
                        <div className="border_div">
                            <Dropdown
                                id="country_telephone_code"
                                name="country_telephone_code"
                                value={formik.values.country_telephone_code}
                                onChange={formik.handleChange}
                                className="custom_width_country"
                                options={countryCode}
                                optionLabel="phone_code"
                                placeholder={phoneCodesPlaceholders.telephone_code}
                                filter
                                filterBy="phone_code"
                                emptyMessage="No results found"
                                disabled={loading || formDisabled}
                            />
                            <InputMask
                                name="telephone"
                                id="telephone"
                                mask="999-999-9999"
                                value={formik.values.telephone}
                                placeholder="999-999-9999"
                                onChange={formik.handleChange}
                                className="custom_width_phone"
                                disabled={formDisabled}
                            ></InputMask>
                        </div>
                        {getFormErrorMessage("country_telephone_code")}
                    </div>
                    {/* Formation */}
                    <CustomInputField
                        className="col-12 md:col-4"
                        options={states}
                        optionLabel="value"
                        iden="state_of_formation"
                        label="State of Formation"
                        formik={formik}
                        type="dropdown"
                        filter={true}
                        filterBy="value"
                        placeHolder="Select"
                        disabled={loading || formDisabled}
                    />
                </div>
                {showBecomePartnerButton ? (
                    <div className="text-center pt-2">
                        <CustomAddNewButton title="Become a Partner " type="submit" isDisabled={disableButton} isLoading={isLoading} />
                    </div>
                ) : (
                    <div className="flex flex-row justify-content-end pt-2">
                        <CustomCancelbtn title="Cancel" type="button" onClick={handleCancel} />
                        <CustomAddNewButton title={brokerId ? "Update" : "Save"} type="submit" isDisabled={disableButton || formDisabled} isLoading={isLoading} />
                    </div>
                )}
            </form>
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={() => history.push("/brokers")}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default BrokerFormComponent;
