import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";
import { onProspectDataMutated } from "../../../../../redux/main-view";

//Components
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomDropdownField from "../../../../../ui/CustomDropdownField/CustomDropdownField";

// Context
import { ToastContext } from "../../../../../context/toast";

// API'S
import { changeProspectStatus, fetchClientAdvocates, postUnderWritingDocument } from "../../../../../services/prospect";
import { getAssignUser } from "../../../../../services/support";
import { getUserAssign, getUserGrid } from "../../../../../services/user-management/user";
import { ROLE_NAME } from "../../../../../utils/Constants/global";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";

let validationSchema = Yup.object().shape({
    selected_advocates: Yup.array().min(1,"Client Advocate is required"),
});

const PromoteClientModal = (props) => {
    const { onHide, selectedUsers, setCheckClientPromotion } = props;

    // Dispatch
    const dispatch = useDispatch();

    // Context
    const toastContext = useContext(ToastContext);

    // States
    const [isSaving, setIsSaving] = useState(false);
    const [clientsAdvocate, setClientsAdvocate] = useState([]);

    // View States
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            selected_advocates:[],
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            try {
                const temp = [];
                selectedUsers.map((item) => {
                    temp.push(item.id);
                });
                const payload = {
                    ids: temp,
                    status: 1,
                    selected_advocates: data.selected_advocates,
                };
                // console.log(payload);
                const res = await changeProspectStatus(payload);
                
                if (res) {
                    if (res.data.status) {
                        toastContext.showMessage("Status Change", "Promoted to client successfully.", "success");
                        dispatch(onProspectDataMutated());
                    } else {
                        setCheckClientPromotion(true);
                    }
                    handleSaveVertical(temp);
                    onHide();
                } else {
                    setIsSaving(false);
                    toastContext.showMessage("Error", "Server Error.", "error");
                }
            } catch (e) {
                toastContext.showMessage("Error", "Server Error.", "error");
                onHide();
            }
            setIsSaving(false);
        },
    });
    const handleSaveVertical = (users) => {
        try {
            const dto = {
                ids: users,
                name: "Underwriting Documents",
            };
            postUnderWritingDocument(dto);
        } catch (e) {
            toastContext.toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    // // UseEffects
    useEffect(() => {
    
        getUsers();
    }, []);

    const getUsers = async (searchValue = null) => {
        setIsLoading(true);

        const dto = {
            pageNumber: 1,
            size: 1000,
            search: "",
            status: null,
            isLogin: false,
        };
        try {
            const res = await getUserAssign(dto);
            let data = res?.data?.records?.filter((rec) => {
                return rec.role_Name === ROLE_NAME.CA;
            });
            setClientsAdvocate(data);
        } catch {
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setIsLoading(false);
    };

    return (
        <>
            <form onSubmit={isSaving ? null : formik.handleSubmit}>
                <div className="grid">
                    <CustomInputField
                        className={`col-12 md:col-12 ${isLoading ? "no-drop" : " "}`}
                        disabled={isLoading}
                        iden="selected_advocates"
                        label="Assign Client Advocate"
                        type="multiSelect"
                        mandatory="*"
                        formik={formik}
                        optionLabel={(option) => `${option.last_Name} ${option.first_Name}`}
                        optionValue="id"
                        options={clientsAdvocate}
                        placeHolder="Select"
                    />
                    <div className="col-12 text-right pt-5">
                        <CustomAddNewButton className={isSaving ? "no-drop" : ""} title="Save" type="submit" isDisabled={isSaving} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default PromoteClientModal;
