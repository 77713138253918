import React from "react";
import "./customcancelbtn.scss";
const CustomCancelbtn = (props) => {
    const { title, name, id, className, onClick, type,isDisabled = false } = props;

    return (
        <>
            <button className={`btn cancelbtn  ${className}`} name={name} id={id} type={type} onClick={onClick} disabled={isDisabled}>
                {title}
            </button>
        </>
    );
};

export default CustomCancelbtn;
