import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//styles
import "./component.scss";
//Formik
// import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
//ui
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
//service
import { getBrokerById } from "../../../../services/broker";
//reacticons
import { BsXCircle } from "react-icons/bs";
//moment
import moment from "moment";
import { useQuery } from "../../../../utils/HelperFuctions/UseQuery";

const TermsConditionComponent = () => {
    // get params
    let query = useQuery();
    let id = query.get("id");
    // use History
    const history = useHistory();
    //states
    const [loading, setLoading] = useState(false);
    const [agreementDate, setAgreementDate] = useState("2013-01-08");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
        telephone_code: "+1",
        fax_code: "+1",
    });
    const [data, setData] = useState({});

    // Formik
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            country_phone_code: "",
            zip: "",
            country_fax_code: "",
            street_address: "",
            ssn: "",
            license_number: "",
        },
    });

    const fetchBrokerById = async (id) => {
        setLoading(true);
        // Broker Fetch By Id
        let payload = {
            id: id,
        };
        let res = await getBrokerById(payload);
        let data = res.data.brokers;

        //Updating Values
        formik.setFieldValue("first_name", data.first_name);
        formik.setFieldValue("last_name", data.last_name);
        formik.setFieldValue("email", data.email);
        formik.setFieldValue("country_phone_code", data.country_phone_code);
        formik.setFieldValue("phone", data.phone);
        formik.setFieldValue("country_fax_code", data.country_fax_code);
        formik.setFieldValue("fax", data.fax);
        formik.setFieldValue("street_address", data.street_address);
        formik.setFieldValue("zip", data.zip);
        formik.setFieldValue("state", data.state);
        formik.setFieldValue("city", data.city);
        formik.setFieldValue("ssn", data.ssn);
        formik.setFieldValue("country_telephone_code", data.country_telephone_code);
        formik.setFieldValue("state_of_formation", data.state_of_formation);
        formik.setFieldValue("telephone", data.telephone);
        formik.setFieldValue("license_number", data.license_number.replaceAll("-", ""));
        formik.setFieldValue("broker_comission", data.broker_comission);
        setAgreementDate(moment(data.agreement_date).format("YYYY-MM-DD"));
        setData(data);

        setPhoneCodesPlaceholders({
            phone_code: data.country_phone_code,
            telephone_code: data.country_telephone_code ? data.country_telephone_code : "+1",
            fax_code: data.country_fax_code,
        });
        setLoading(false);
    };

    useEffect(() => {
        fetchBrokerById(parseInt(id));
    }, []);

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="terms_condition">
                        <div className="card mt-6 card_color">
                            <div className="text-right">
                                <BsXCircle className="navigate-icon" onClick={() => history.push(`/brokerdetails?id=${parseInt(id)}`)} />
                            </div>
                            <h2 className="text-center header_text">Terms & Conditions</h2>
                            <p className="referral_agreement pt-6">
                                This Referral Agreement (“Agreement”) is entered into as of the{" "}
                                <span>
                                    {" "}
                                    <input className="calender_agreement cursor-pointer" type="date" value={agreementDate} disabled /> by and between Frontline Funding, LLC, a Florida (“FF”), and
                                    NAME("
                                    {(formik.values.last_name ? formik.values.last_name : "") + " " + (formik.values.first_name ? formik.values.first_name : "")}"), whose address and details are;
                                </span>
                            </p>
                            <div className="grid">
                                {/* Address */}
                                <CustomInputField className="col-12 md:col-6" iden="street_address" label="Address" formik={formik} disabled={true} />
                                {/* Phone No */}
                                <div className="md:col-3 col-12">
                                    <label htmlFor="phone" className="lbl">
                                        <b>Phone No</b>
                                    </label>
                                    <div className="border_div">
                                        <Dropdown
                                            id="country_phone_code"
                                            name="country_phone_code"
                                            value={formik.values.country}
                                            onChange={formik.handleChange}
                                            className="custom_width_country"
                                            optionLabel="name"
                                            placeholder={phoneCodesPlaceholders.phone_code}
                                            disabled={true}
                                        />

                                        <InputMask
                                            name="phone"
                                            id="phone"
                                            mask="999-999-9999"
                                            value={formik.values.phone}
                                            placeholder="999-999-9999"
                                            onChange={formik.handleChange}
                                            className="custom_width_phone"
                                            disabled={true}
                                        ></InputMask>
                                    </div>
                                </div>
                                {/* Email */}
                                <CustomInputField className="col-12 md:col-3" iden="email" type="email" label="Email" formik={formik} placeHolder="Enter Email" disabled={true} />
                                {/* Zip CODE */}
                                <CustomInputField className="col-12 md:col-3" iden="zip" label="Zip Code" formik={formik} disabled={true} />
                                {/* State */}
                                <CustomInputField className="col-12 md:col-3" iden="state" label="State" formik={formik} disabled={true} />
                                {/* City */}
                                <CustomInputField className="col-12 md:col-3" iden="city" label="City" formik={formik} disabled={true} />
                                {/* State of Formation */}
                                <CustomInputField className="col-12 md:col-3" iden="state_of_formation" label="State of Formation" formik={formik} disabled={true} />
                                {/* Fax No */}
                                <div className="md:col-3 col-12">
                                    <label htmlFor="faxno" className="lbl">
                                        <b>Fax No</b>
                                    </label>
                                    <div className="border_div">
                                        <Dropdown
                                            id="country_fax_code"
                                            name="country_fax_code"
                                            value={formik.values.country_fax_code}
                                            onChange={formik.handleChange}
                                            className="custom_width_country"
                                            disabled={true}
                                            placeholder={phoneCodesPlaceholders.fax_code}
                                        />

                                        <InputMask
                                            name="fax"
                                            id="fax"
                                            mask="999-999-9999"
                                            value={formik.values.fax}
                                            placeholder="999-999-9999"
                                            onChange={formik.handleChange}
                                            className="custom_width_phone"
                                            disabled={true}
                                        ></InputMask>
                                    </div>
                                </div>
                                {/* Telephone No */}
                                <div className="md:col-3 col-12">
                                    <label htmlFor="country_telephone_code" className="lbl">
                                        <b>Cell No</b>
                                    </label>
                                    <div className="border_div">
                                        <Dropdown
                                            id="country_telephone_code"
                                            name="country_telephone_code"
                                            value={formik.values.country_telephone_code}
                                            onChange={formik.handleChange}
                                            className="custom_width_country"
                                            disabled={true}
                                            placeholder={phoneCodesPlaceholders.telephone_code}
                                        />
                                        <InputMask
                                            name="cell_phone"
                                            id="cell_phone"
                                            mask="999-999-9999"
                                            value={formik.values.cell_phone}
                                            placeholder="999-999-9999"
                                            onChange={formik.handleChange}
                                            className="custom_width_phone"
                                            disabled={true}
                                        ></InputMask>
                                    </div>
                                </div>
                                {/* License Number */}
                                <CustomInputField className="col-12 md:col-3" iden="license_number" label="Broker License No" type="text" formik={formik} disabled={true} />
                                {/* Federal Employer */}
                                <CustomInputField className="col-12 md:col-3" iden="ssn" label="Federal Employer Tax ID or SSN" formik={formik} mask={"999-99-9999"} disabled={true} />
                            </div>
                            {/* Broker Information */}
                            <div className="text-justify brokers_obligation pt-6">
                                <p>
                                    <b>1. Broker's Obligations</b>
                                    <br />
                                    Broker agrees to act as a non-exclusive broker for FF to solicit new prospective clients for financial services from FF, including accounts receivable financing or
                                    other services offered by FF. Broker expressly understands that FF is under no obligation to accept any prospective client solicited by Broker and that FF may, in
                                    its sole and absolute discretion, reject or decline any such prospective clients for any or no reason, in which event Referral Entity shall not be entitled to
                                    commissions or other compensation from FF under this Agreement.
                                </p>
                                <p>
                                    <b>2. Commissions Payable</b>
                                    <br />
                                    In the event that FF accepts a prospective client solicited or introduced by Broker and provides financing, Broker shall be paid a commission, except as provided in
                                    this Agreement, based on the fees earned by, and paid to, FF(whether expressed as factoring fees, discount fees, or otherwise, the "Fees") by clients of FF at a
                                    rate of <b>{data.broker_commission}%</b> of the Fees (the "Broker Commission"). The Broker Commission shall be earned by Broker as a percentage of Fees received by
                                    FF, in cleared funds, and adjusted for any reimbursements, rebates, deductions, or collection costs. In the event of a default by a client referred to FF by Broker,
                                    under any agreement with FF, regardless of whether or not FF provided client with written notice of such default, no Broker Commission shall be paid to Broker
                                    unless and until FF recovers the full amount of any damages suffered by FF, together with any amounts owed under any agreement with FF.
                                </p>
                                <p>
                                    <b>3. Ineligible Broker Commission</b>
                                    <br />
                                    In the event that a client referred to FF by Broker is downgraded to a "watch credit" or a further deteriorating status, as determined by FF's credit committee in
                                    its sole discretion and according to FF's credit rating standards, and for the duration of such downgrade, Broker understands that no Broker Commission shall be
                                    due, payable, or accrued with respect to the downgraded client so long as such downgraded status exists. The Broker Commission shall resume eligibility upon a
                                    satisfactory upgrade above the "watch credit" risk status as determined by FF's credit committee and according to FF's credit rating standards.
                                </p>
                                <p>
                                    <b>4. Modification of Client Agreement</b>
                                    <br />
                                    FF reserves the right to alter, amend, or terminate the terms of the agreement between client and FF in its absolute discretion and without consent of Broker. In
                                    such event, FF shall not be liable to Broker for any damages suffered or allegedly suffered by Broker as a result of FF's decision.
                                </p>
                                <p>
                                    <b>5. Broker's Representations and Warranties</b>
                                    <br />
                                    Broker represents and warrants to FF that: 5.1 It has, and will maintain for the duration of this Agreement, all licenses, authorizations, and insurance that may be
                                    required from time to time; 5.2 It has no prior or concurrent obligations, commitments, or impediments that will limit or prevent the performance of Broker's
                                    obligations under this Agreement; 5.3 It will not advertise or distribute any printed or digital material using or referring to FF or its logo, trademarks, and
                                    insignias, without the pre-approval of FF; and 5.4 It will engage in best practices in its industry, conform to highest ethical standards and protect the good
                                    reputation of FF.
                                </p>
                                <p>
                                    <b>6. Non-disparagement and Non solicitation</b>
                                    <br />
                                    Broker shall not disparage FF nor take any action that may in any way impair FF's business or relationship with (i) any client or prospective client whether or not
                                    such client was referred to FF by Broker; or (ii) FF's business reputation. During the term of this Agreement and for a period of twelve (12) consecutive months
                                    after termination, Broker shall not solicit any client away from FF for any service or product that FF offers or otherwise interfere with any relationship between
                                    FF and any client or prospective client. In the event Broker breaches this, FF shall be entitled to injunctive relief and any obligation of FF to pay Broker
                                    Commission shall terminate immediately upon such breach and no further Broker Commission shall be due or payable to Broker.
                                </p>
                                <p>
                                    <b>7. Right to Offset</b>
                                    <br />
                                    If Broker shall breach any representation, warranty, or covenant contained in this Agreement, or otherwise expressly made to FF, FF shall have the right to offset
                                    all loss, cost, damage, liability, and expense (including reasonably attorneys' fees) suffered or incurred by FF against any Broker Commission owing to Broker under
                                    this Agreement or any other agreement between FF and Broker.
                                </p>
                                <p>
                                    <b>8. No Employment</b>
                                    <br />
                                    The relationship between Broker and FF shall be solely that of an independent contractor. Broker shall not have and shall not represent at any time that it has any
                                    authority to bind FF in any manner whatsoever, without FF's prior express and written authorization. Broker shall not be construed as an employee, agent, or
                                    representative of FF for any purpose, nor shall this Agreement be deemed or construed to create a partnership or joint venture between FF and Broker.
                                </p>
                                <p>
                                    <b>9. Termination</b>
                                    <br />
                                    This Agreement shall remain in effect until terminated. This Agreement may be terminated by either party by providing the other party with fifteen (15) calendar
                                    days' notice. This Agreement may also be terminated by FF immediately upon a breach or repudiation of any representation or warranty made by Broker to FF or a
                                    breach of any term or obligation of this Agreement by Broker. In the event that this Agreement is terminated by either party, no further Brokers Commission shall
                                    accrue or be payable to Broker. All representations and warranties in this Agreement shall survive any termination of this Agreement.
                                </p>
                                <p>
                                    <b>10. Disputes</b>
                                    <br />
                                    In the event of any dispute or claim by Broker, it is agreed that prior to the initiation of any action by Broker, Broker shall give written notice of such dispute
                                    or claim to FF, specifying in detail the precise basis for such dispute or claim. Such written notice shall be provided by certified mail, return receipt requested
                                    to FF and shall be provided within fifteen (15) calendar days from when such dispute or claim arises; otherwise, such dispute or claim shall be deemed waived by
                                    Broker.
                                </p>
                                <p>
                                    <b>11. Waiver</b>
                                    <br />
                                    No act or failure to act by FF shall be deemed a waiver of any provision of this Agreement, unless such waiver is in writing and signed by FF. Any such waiver shall
                                    apply only to the circumstances described in the writing and shall not apply to any other prior or subsequent events, unless expressly provided for in the writing
                                    executed by FF.
                                </p>
                                <p>
                                    <b>12. Governing Law</b>
                                    <br />
                                    This Agreement is made and entered into in the State of Florida, and shall be in all respects interpreted, enforced, and governed under the laws of the State of
                                    Florida. Broker agrees and consents to the exclusive jurisdiction and venue of the state and federal courts in and for Orange County, Florida concerning any matter
                                    involved or related to this Agreement, and waives any right to seek a change of venue for any reason, including but not limited to inconvenience of this forum.
                                </p>
                                <p>
                                    <b>13. Third Party Beneficiaries</b>
                                    <br />
                                    Only the parties to this Agreement shall be entitled to the benefits of this Agreement, and no other party shall be deemed a third-party beneficiary under this
                                    Agreement.
                                </p>
                                <p>
                                    <b>14. Assignment</b>
                                    <br />
                                    Broker may not assign, pledge, transfer, or encumber any rights granted to it under this Agreement, nor delegate any duties or obligations under this Agreement,
                                    without prior written consent of FF may assign or transfer this Agreement, in whole or in part, at any time without notice to Broker and upon assignment shall no
                                    further liability to Broker.
                                </p>
                                <p>
                                    <b>15. Binding Effect</b>
                                    <br />
                                    This Agreement shall be binding on and may be legally enforced by the parties and their successors and permitted assigns.
                                </p>
                                <p>
                                    <b>16. Waiver of Trial by Jury</b>
                                    <br />
                                    TO THE FULLEST EXTENT PERMISSIBLE UNDER LAW, FF AND BROKER KNOWINGLY, VOLUNTARILY, AND INTENTIONALLY WAIVE THE RIGHT TO A TRIAL BY JURY IN ANY ACTION OR PROCEEDING
                                    BASED UPON, RELATED TO, OR ARISING OUT OF THE SUBJECT MATTER OF THIS AGREEMENT.
                                </p>
                                <p>
                                    <b>17. Severability</b>
                                    <br />
                                    Should a provision of this Agreement be determined to be invalid or unenforceable, the validity of the remaining terms and provisions shall not be affected so as to
                                    grant the maximum intended benefit to the parties to this Agreement.
                                </p>
                                <p>
                                    <b>18. Entire Agreement </b>
                                    <br />
                                    This Agreement sets forth the entire agreement between the parties hereto regarding the applicable subject matter, and supersedes any and all prior agreement or
                                    understandings, oral or written, between the parties with respect to the matters that are the subject of this Agreement. This Agreement is executed freely and
                                    voluntarily and without reliance upon any statement or representation of FF or its attorneys, agents, or other representatives or such party except as specifically
                                    set forth herein. There are no and Broker is not relying on any written or oral representations not expressly written in this Agreement.
                                </p>
                                <p>
                                    <b>IN WITNESS HEREOF, the parties hereto have executed this Agreement as of the day and year first above written.</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default TermsConditionComponent;
