import React, { useEffect, useState } from "react";

// Constants
import {
    PURCHASE_SUMMARY_WITH_FEE_REPORT_GRID_HEADERS,
    PURCHASE_SUMMARY_WITHOUT_FEE_REPORT_GRID_HEADERS,
    INVOICE_SUMMARY_AGING_REPORT_GRID_HEADERS,
    PAYMENT_HISTORY_DETAIL_REPORT_GRID_HEADERS,
    RESERVE_DETAIL_REPORT_GRID_HEADERS,
    ESCROW_RESERVE_REPORT_GRID_HEADERS,
    CHARGEBACKS_REPORT_GRID_HEADERS,
    BROKER_COMMISION_REPORT_GRID_HEADERS,
    CREDIT_LIMIT_WARNING_REPORT_GRID_HEADERS,
    LAST_PAYMENT_DATE_REPORT_GRID_HEADERS,
    CLIENT_RISK_MANAGEMENT_DETAIL_REPORT_GRID_HEADERS,
    CLIENT_ANALYSIS_REPORT_GRID_HEADERS,
    UNAPPLIED_CASH_RECEIVED_REPORT_GRID_HEADERS,
    COLLECTION_EFFICIENCY_ANALYSIS_REPORT_GRID_HEADERS,
    CASH_POSTING_REPORT,
    DEBTOR_AGING_REPORT,
    TRANSACTIONS,
} from "../../../utils/Constants/global";

// Components
import Body from "./GridView/Body";
import FilterBar from "./GridView/FilterBar";

const data = [{ id: "1", businessname: "Dashboard", pocname: "error", pocemail: "closed", phoneno: "Medium", requiredfundingamount: "admin" }];

const ReportIndex = () => {
    const [reportValue, setReportValue] = useState("");

    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [users, setUsers] = useState([...data]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [onSelectionColumn] = useState([]);

    // Handler
    const onReportColumnHandler = () => {
        const name = localStorage.getItem("report_name");
        setReportValue(name);
        if (name === "purchase_summary_with_fee") {
            setSelectedColumns(PURCHASE_SUMMARY_WITH_FEE_REPORT_GRID_HEADERS);
        } else if (name === "purchase_summary_without_fee") {
            setSelectedColumns(PURCHASE_SUMMARY_WITHOUT_FEE_REPORT_GRID_HEADERS);
        } else if (name === "invoice_summary_aging") {
            setSelectedColumns(INVOICE_SUMMARY_AGING_REPORT_GRID_HEADERS);
        } else if (name === "payment_history_detail") {
            setSelectedColumns(PAYMENT_HISTORY_DETAIL_REPORT_GRID_HEADERS);
        } else if (name === "reserve_detail") {
            setSelectedColumns(RESERVE_DETAIL_REPORT_GRID_HEADERS);
        } else if (name === "escrow_reserve") {
            setSelectedColumns(ESCROW_RESERVE_REPORT_GRID_HEADERS);
        } else if (name === "chargebacks") {
            setSelectedColumns(CHARGEBACKS_REPORT_GRID_HEADERS);
        } else if (name === "broker_commission_report") {
            setSelectedColumns(BROKER_COMMISION_REPORT_GRID_HEADERS);
        } else if (name === "credit_limit_warning") {
            setSelectedColumns(CREDIT_LIMIT_WARNING_REPORT_GRID_HEADERS);
        } else if (name === "last_payment_date") {
            setSelectedColumns(LAST_PAYMENT_DATE_REPORT_GRID_HEADERS);
        } else if (name === "client_risk_management_detail") {
            setSelectedColumns(CLIENT_RISK_MANAGEMENT_DETAIL_REPORT_GRID_HEADERS);
        } else if (name === "client_analysis") {
            setSelectedColumns(CLIENT_ANALYSIS_REPORT_GRID_HEADERS);
        } else if (name === "unapplied_cash_received") {
            setSelectedColumns(UNAPPLIED_CASH_RECEIVED_REPORT_GRID_HEADERS);
        } else if (name === "collection_efficiency_analysis") {
            setSelectedColumns(COLLECTION_EFFICIENCY_ANALYSIS_REPORT_GRID_HEADERS);
        } else if (name === "cash_posting_report") {
            setSelectedColumns(CASH_POSTING_REPORT);
        } else if (name === "debtor_aging") {
            setSelectedColumns(DEBTOR_AGING_REPORT);
        } else if (name === "transactions") {
            setSelectedColumns(TRANSACTIONS);
        }
    };

    //useEffect
    useEffect(() => {
        onReportColumnHandler();

        // Don't understand this code.
        selectedColumns.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        setTimeout(() => setLoading(false), 1000); // simulate data loading for 2 seconds
    }, []);

    return (
        <>
            <FilterBar
                showAll={showAll}
                setShowAll={setShowAll}
                setGlobalFilter={setGlobalFilter}
                selectedColumns={selectedColumns}
                onSelectionColumn={onSelectionColumn}
                setSelectedColumns={setSelectedColumns}
                reportValue={reportValue}
            />
            <Body
                users={users}
                setUsers={setUsers}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                showAll={showAll}
                setShowAll={setShowAll}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                onSelectionColumn={onSelectionColumn}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                setSelectedRowData={setSelectedRowData}
                reportValue={reportValue}
            />
        </>
    );
};

export default ReportIndex;
