import React from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Ripple } from "primereact/ripple";

export function PaginatorTemplate(isFirstPage, isLastPage, currentPage, totalPages, pageSize, page, totalRecords, pageInputTooltip, setCurrentPage, setPage, setPageSize, setPageInputTooltip) {
    const prevPage = () => {
        setCurrentPage(currentPage - 1);
        setPage(currentPage - 1);
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
        setPage(currentPage + 1);
    };

    const onPageInputKeyDown = (event, options) => {
        if (event.key === "Enter") {
            const _page = parseInt(page);
            if (_page <= 0 || _page > totalPages) {
                setPage(currentPage);
                setPageInputTooltip(`Value must be between 1 and ${totalPages}.`);
            } else {
                setCurrentPage(_page);
                setPageInputTooltip("Press 'Enter' key to go to this page.");
            }
        }
    };
    const onPageInputChange = (event) => {
        setPage(event.target.value);
    };

    return {
        layout: "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
        PrevPageLink: (options) => {
            return (
                <button type="button" className={`p-paginator-prev p-paginator-element p-link ${isFirstPage === currentPage ? "p-disabled" : ""}`} onClick={prevPage}>
                    <span className="p-p-3">Previous</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={`p-paginator-prev p-paginator-element p-link ${isLastPage === currentPage ? "p-disabled" : ""}`} onClick={nextPage}>
                    <span className="p-p-3">Next</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            options.currentPage = currentPage;
            options.totalPages = totalPages;
            options.view.startPage = isFirstPage;
            options.view.endPage = isLastPage;
            options.page = currentPage;
            let temp = 0;
            if (totalPages > 0) {
                {
                    return Array(totalPages)
                        .fill(1)
                        .map((_, pageIndex) => {
                            const pageNumber = pageIndex + 1;
                            const pageDifference = Math.abs(pageNumber - currentPage);

                            if (totalPages <= 3) {
                                return (
                                    <button
                                        key={pageIndex}
                                        type="button"
                                        className={`p-paginator-page p-paginator-element p-link p-paginator-page-start p-paginator-page-end ${pageNumber === currentPage ? "p-highlight" : ""}`}
                                        onClick={(e) => {
                                            setCurrentPage(pageNumber);
                                            setPage(pageNumber);
                                        }}
                                    >
                                        {pageNumber}
                                        <Ripple />
                                    </button>
                                );
                            } else {
                                if (pageNumber <= 3 || pageNumber === totalPages || pageDifference <= 1) {
                                    return (
                                        <button
                                            key={pageIndex}
                                            type="button"
                                            className={`p-paginator-page p-paginator-element p-link p-paginator-page-start p-paginator-page-end ${pageNumber === currentPage ? "p-highlight" : ""}`}
                                            onClick={(e) => {
                                                setCurrentPage(pageNumber);
                                                setPage(pageNumber);
                                            }}
                                        >
                                            {pageNumber}
                                            <Ripple />
                                        </button>
                                    );
                                }

                                if ((pageNumber === 4 && currentPage > 5) || (pageNumber === 2 && currentPage < totalPages - 3)) {
                                    return (
                                        // className={classNames}
                                        <span key={pageIndex} style={{ userSelect: "none" }}>
                                            ...
                                        </span>
                                    );
                                } else {
                                    if (temp == 0) {
                                        temp = 1;
                                        return (
                                            <span key={pageIndex} style={{ userSelect: "none" }}>
                                                ...
                                            </span>
                                        );
                                    }
                                }
                            }

                            return null; // Default case
                        });
                }
            }
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: "All", value: totalRecords },
            ];

            const _value = dropdownOptions.some((item) => item.value === pageSize) ? pageSize : totalRecords;

            return (
                <Dropdown
                    className="paginator_dropdown"
                    value={_value}
                    options={dropdownOptions}
                    onChange={(e) => {
                        setPageSize(e.value);
                        setCurrentPage(1);
                        setPage(1);
                    }}
                    appendTo={document.body}
                />
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span className="p-mx-3 ml-3" style={{ color: "var(--text-color)", userSelect: "none" }}>
                    <InputText
                        size="2"
                        className="p-ml-1"
                        value={page}
                        tooltip={pageInputTooltip}
                        keyfilter="num"
                        onKeyDown={(e) => {
                            if (e.key === "-" || e.key === ".") {
                                e.preventDefault(); // Prevent entering Dashes and Dot
                            } else {
                                onPageInputKeyDown(e, options);
                            }
                        }}
                        onChange={onPageInputChange}
                    />
                </span>
            );
        },
    };
}
