import React from "react";

// Ui Components
import { getReportName } from "../../../../utils/HelperFuctions/reportsHeader";
import { BreadCrumb } from "primereact/breadcrumb";

const FilterBar = (props) => {
    const { reportValue } = props;

    const items = [{ label: "Reports", url: "/reports" }, { label: getReportName(reportValue) }];
    const home = { icon: "bi bi-file-earmark-bar-graph" };

    return (
        <div className="grid">
            <div className="col-12">
                <BreadCrumb model={items} home={home} />
            </div>
        </div>
    );
};

export default FilterBar;
