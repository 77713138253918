import React from "react";
import { useHistory } from "react-router-dom";
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { useSelector } from "react-redux";

// Redux
import { loginState } from "../../../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";

// Constants
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";


const Header = () => {
    const history = useHistory();


    // Redux
    const {user} = useSelector(loginState)

    return validateUserPermissions([TRANSACTION_RIGHTS.schedule_of_assignment.add_new_schedule], user?.rights) && <CustomAddNewButton type="button" title="Add New Schedule"  onClick={() => history.push(`/addnewscheduleofassignment`)} />
        
    
};

export default Header;
