import React, { useContext, useEffect, useRef, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Prime Component
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";

// UI Components
import CustomCancelbtn from "../../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomInputField from "../../../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { ToastContext } from "../../../../../../../context/toast";
import { getDebtors } from "../../../../../../../services/debtor";
import { getCollectionInvoices, getDebtorProspectUC, getProspectUC, saveCollection } from "../../../../../../../services/collection";
import { getCustomDropDown } from "../../../../../../../services/dropdown";
import CustomDropdownField from "../../../../../../../ui/CustomDropdownField/CustomDropdownField";
import { getDateFormat } from "../../../../../../../utils/HelperFuctions/dateFormat";
import { checkCollectionAmount, currencyFormat, currencyFormateToValue, currencyFormatUSD, currentStringToNumber } from "../../../../../../../utils/HelperFuctions/checkAmount";
import { useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { getInvoiceClientsDropdown } from "../../../../../../../services/invoice";
import CustomConfirmDialog from "../../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg, BsInfoCircle, BsPaperclip, BsX, BsXLg } from "react-icons/bs";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { FILE_MAX_SIZE } from "../../../../../../../utils/Constants/global";
import { InputText } from "primereact/inputtext";

const CollectionCard = (props) => {
    const { header, subHeader, collectionType } = props;

    // toast
    const toastContext = useContext(ToastContext);

    //use History
    const history = useHistory();

    // States
    const [prospectId, setProspectId] = useState("");
    const [debtorId, setDebtorId] = useState("");
    const [dropdownClients, setDropdownClients] = useState([]);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [dropdownInvoices, setDropdownInvoices] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [checkNumberErrorLabel, setCheckNumberErrorLabel] = useState("");
    const [checkDateErrorLabel, setCheckDateErrorLabel] = useState("");
    const [checkDepositDateErrorLabel, setCheckDepositDateErrorLabel] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);

    // View States
    const [clientLoading, setClientLoading] = useState(false);
    const [debtorLoading, setDebtorLoading] = useState(false);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [paymentTypeLoading, setPaymentTypeLoading] = useState(false);
    const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [showUnappliedCash, setShowUnappliedCash] = useState(false);
    const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);
    const [amountMessage, setAmountMessage] = useState("");
    const [unappliedCash, setUnappliedCash] = useState(false);
    const [ucValue, setUcValue] = useState(0);
    const [amountsFieldDisabled, setAmountFieldDisabled] = useState(true);
    const [maxFundingDate, setMaxFundingDate] = useState(null);
    const [minPostingDate, setMinPostingDate] = useState(null);
    const [clientSearch, setClientSearch] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [max, setMax] = useState(99999999.99);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenFileInput = useRef(null);

    const validationSchema = Yup.object().shape({
        prospect_id: Yup.string().required("Client Name is required"),
        debtor_id: Yup.string().required("Debtor Name is required"),
        invoices: Yup.array().required("Invoice is required"),
        amount: Yup.string().required("Amount is required"),
        payment_type: Yup.string().required("Payment Type is required"),
        posting_date: Yup.string().required("Posting Date is required"),
        check_number: Yup.string().required(`${checkNumberErrorLabel} Number is required`),
        check_date: Yup.string().required(`${checkDateErrorLabel} Date is required`),
        check_deposit_date: Yup.mixed().when("payment_type", {
            is: (val) => val !== "2",
            then: Yup.mixed().required(`${checkDepositDateErrorLabel} Deposit Date is required`),
        }),

        total_amount: Yup.string().test("required", "Total Amount is required", function (value) {
            if (collectionType === 3) {
                // When 'collectionType === 3' , the field is required
                return !!value;
            } else {
                // When 'collectionType !== 3' is, the field is nullable
                return true;
            }
        }),
        cb_amount: Yup.string().test("required", "Chargbacks Amount is required", function (value) {
            if (collectionType === 3) {
                // When 'collectionType === 3' , the field is required
                return !!value;
            } else {
                // When 'collectionType !== 3' is, the field is nullable
                return true;
            }
        }),
        check_path: Yup.string().required("Attachments is required"),
    });

    //toast message
    const toast = useContext(ToastContext);

    //use ref
    const dt = useRef(null);

    const handleValidation = () => {
        if (collectionType !== 3) {
            let total = 0;
            let ucAmount = 0;

            // calculation between invoice amount
            let currentAmount = parseFloat(formik.values.amount);

            // calculating the invoices amount in data table
            selectedInvoices.forEach((invoice) => {
                if (invoice?.collected_amount) {
                    total += parseFloat(invoice.collected_amount);
                } else {
                    toast.showMessage("Error", `Invoice No. ${invoice.invoice_no} must have Amount to be Settled for further processing.`, "error");
                }
            });
            let status = checkCollectionAmount(currentAmount, total);

            if (status === "equal") {
                return true;
            } else {
                toast.showMessage("Error", "Amounts are not matching.", "error");
                return false;
            }
        } else if (collectionType === 3) {
            // Invoices Amount
            let totalAmounts = parseFloat(formik.values.total_amount);
            let invoicesAmount = parseFloat(formik.values.amount);
            let chargebacksAmount = parseFloat(formik.values.cb_amount);

            if (totalAmounts === invoicesAmount + chargebacksAmount) {
                let totalInvoicesAmount = 0;
                let totalCBAmount = 0;

                let isChargeBackInvoics = false;
                let isInvoice = false;
                // calculating the invoices amount and charge back  in data table

                selectedInvoices.forEach((invoice) => {
                    if (invoice?.collected_amount) {
                        if (invoice.actual_cb > 0) {
                            isChargeBackInvoics = true;
                            totalCBAmount += parseFloat(invoice.collected_amount);
                        } else {
                            isInvoice = true;
                            totalInvoicesAmount += parseFloat(invoice.collected_amount);
                        }
                    } else {
                        toast.showMessage("Error", `Invoice No. ${invoice.invoice_no} must have Amount to be Settled for further processing.`, "error");
                        return false;
                    }
                });

                // Checking if Invoice total amount and Chargebacks Incvoices total amount is equal to selected data table invoices amounts and chargeback amounts

                if (isChargeBackInvoics && isInvoice) {
                    // checking invoice amount
                    let statusInvoices = checkCollectionAmount(invoicesAmount, totalInvoicesAmount);
                    // checking chargebacks amount
                    let statusChargbacks = checkCollectionAmount(chargebacksAmount, totalCBAmount);
                    if (statusInvoices === "equal" && statusChargbacks === "equal") {
                        return true;
                    } else {
                        toast.showMessage("Error", "Amounts entered does not match.", "error");
                        return false;
                    }
                } else {
                    toast.showMessage("Error", `Please select atleast one ${isChargeBackInvoics ? "Invoice" : " Chargeback Invoice"}`, "error");
                    return false;
                }
            } else {
                toast.showMessage("Error", "Total amount does not matches with chargebacks amount and amount", "error");
                return false;
            }
        }
    };

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            prospect_id: "",
            debtor_id: "",
            invoices: "",
            amount: "",
            payment_type: "",
            check_number: "",
            check_date: "",
            check_deposit_date: "",
            posting_date: "",
            total_amount: "",
            cb_amount: "",
            check_path: "",
        },

        onSubmit: async (data) => {
            try {
                if (handleValidation()) {
                    setIsSaving(true);
                    setIsLoading(true);

                    // fetching invoice id and collected amount from the selected invoices state for sending to api...
                    const modifiedInvoices = selectedInvoices.map(({ id, collected_amount, move_to_cb, uc }) => ({
                        id,
                        collected_amount,
                        move_to_cb,
                        uc,
                    }));

                    // final payload for api call..
                    data = {
                        ...data,
                        invoices: modifiedInvoices,
                        collection_type: collectionType,
                        check_date: getDateFormat(formik.values.check_date),
                        posting_date: getDateFormat(formik.values.posting_date),
                    };

                    if (!data.uc_collected) {
                        delete data.uc_collected;
                    }

                    if (formik.values.check_deposit_date !== "") {
                        data["check_deposit_date"] = getDateFormat(formik.values.check_deposit_date);
                    } else if (formik.values.check_deposit_date === "" && data.payment_type === 2) {
                        data["check_deposit_date"] = getDateFormat(formik.values.check_date);
                        delete data.check_date;
                    }
                    if (collectionType !== 3) {
                        delete data.cb_amount;
                    } else if (collectionType === 3) {
                        data["amount"] = data.total_amount;
                        data["invoice_amount"] = data.amount;
                    }

                    delete data.total_amount;
                    delete data.total_unapplied_cash;
                    delete data.pending_unapplied_cash;

                    // form Data...
                    const formData = new FormData();
                    Object.keys(data).forEach((key) => {
                        if (key === "invoices") {
                            formData.append(key, JSON.stringify(data[key]));
                        } else if (key === "check_path") {
                            formData.append("check_path", uploadedFile);
                        } else {
                            formData.append(key, data[key]);
                        }
                    });

                    let res = await saveCollection(formData);

                    if (res.data.status) {
                        formik.resetForm();
                        toast.showMessage("Success", "Collection completed successfully.", "success");
                        history.push("/collections");
                        setIsSaving(false);
                        setIsLoading(false);
                    } else {
                        toast.showMessage("Creation Failed", res.data.message, "error");
                    }
                    setIsSaving(false);
                    setIsLoading(false);
                }
            } catch (error) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
                setIsLoading(false);
            }
        },
    });

    // Validation
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (value === 0) {
            setCheckNumberErrorLabel("Routing");
            setCheckDateErrorLabel("ACH");
            setCheckDepositDateErrorLabel("ACH");
        } else if (value === 1) {
            setCheckNumberErrorLabel("Check");
            setCheckDateErrorLabel("Check");
            setCheckDepositDateErrorLabel("Check");
            formik.setFieldValue("check_date", "");
        } else if (value === 2) {
            setCheckNumberErrorLabel("Transaction");
            setCheckDateErrorLabel("Wire");
        }
        formik.setFieldValue("check_date", "");
        formik.setFieldValue("check_deposit_date", "");
        formik.setFieldValue("posting_date", "");

        formik.handleChange(e);
    };

    const handleInputAmount = (e) => {
        let { value } = e;
        formik.setFieldValue("amount", parseFloat(value));
        if (value === null) {
            formik.setFieldValue("amount", parseFloat(0));
        }
        if (collectionType !== 3) {
            let status = checkCollectionAmount(value, totalBalanceAmount);
            if (status === "less") {
                setAmountMessage("Collection amount is less than the total amount of the invoice(s).");
                if (ucValue > 0) {
                    setUnappliedCash(true);
                    formik.setFieldValue("total_unapplied_cash", ucValue);
                    formik.setFieldValue("pending_unapplied_cash", ucValue);
                }
            } else if (status === "greater") {
                setAmountMessage("Collection amount is greater than the total amount of the invoice(s).");
            } else {
                setAmountMessage("");
            }

            if (status !== "less") {
                setUnappliedCash(false);
                formik.setFieldValue("total_unapplied_cash", "");
                formik.setFieldValue("pending_unapplied_cash", "");
                setShowUnappliedCash(false);
            }
        }

        // formik.handleChange(e);
    };

    const handleTotalAmount = (e) => {
        const { value } = e;
        formik.setFieldValue("total_amount", parseFloat(value));
        if (value === null) {
            formik.setFieldValue("total_amount", parseFloat(0));
        }
        let status = checkCollectionAmount(value, totalBalanceAmount);
        if (status === "less") {
            setAmountMessage("Collection amount is less than the total amount of the invoice(s).");
            if (ucValue > 0) {
                setUnappliedCash(true);
                formik.setFieldValue("total_unapplied_cash", ucValue);
                formik.setFieldValue("pending_unapplied_cash", ucValue);
            }
        } else if (status === "greater") {
            setAmountMessage("Collection amount is greater than the total amount of the invoice(s).");
        } else {
            setAmountMessage("");
        }

        if (status !== "less") {
            setUnappliedCash(false);
            formik.setFieldValue("total_unapplied_cash", "");
            formik.setFieldValue("pending_unapplied_cash", "");
            setShowUnappliedCash(false);
        }

        // formik.handleChange(e);
    };

    const handleCBAmount = (e) => {
        const { value } = e;
        formik.setFieldValue("cb_amount", parseFloat(value));
        if (value === null) {
            formik.setFieldValue("cb_amount", parseFloat(0));
        }
    };

    const handleSettleUnAppliedAmount1 = (e) => {
        const { value } = e;

        formik.setFieldValue("uc_collected", value);
        if (parseFloat(value) <= parseFloat(formik.values.total_unapplied_cash)) {
            let updatePendingCash = parseFloat(formik.values.total_unapplied_cash) - parseFloat(value);
            formik.setFieldValue("pending_unapplied_cash", updatePendingCash);
        } else {
            formik.setFieldValue("pending_unapplied_cash", 0);
        }

        if (!value) {
            formik.setFieldValue("pending_unapplied_cash", ucValue);
        }
    };

    const handleSettleUnAppliedAmount = (e) => {
        const { value } = e;

        formik.setFieldValue("uc_collected", value);
        if (parseFloat(value) <= parseFloat(formik.values.total_unapplied_cash)) {
            let updatePendingCash = parseFloat(formik.values.total_unapplied_cash) - parseFloat(value);
            formik.setFieldValue("pending_unapplied_cash", updatePendingCash);
        } else {
            formik.setFieldValue("pending_unapplied_cash", 0);
        }

        if (!value) {
            formik.setFieldValue("pending_unapplied_cash", ucValue);
        }
    };

    // getClientDropdown
    const fetchClients = (keyword = null) => {
        setClientLoading(true);
        let payload = {
            per_page: 50,
            search_text: keyword,
        };
        try {
            getInvoiceClientsDropdown(payload).then((res) => {
                if (res.data.status) {
                    if (res.data && res.data.prospects && res.data.prospects.data.length > 0) {
                        setDropdownClients(res.data.prospects.data);
                        setClientLoading(false);
                    }
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    //handle filter
    const handleClientFilter = (event) => {
        setClientSearch(event.filter);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchClients(clientSearch.length === 0 ? null : clientSearch);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [clientSearch]);

    const fetchDebtorProspectUC = async () => {
        if (debtorId !== formik.values.debtor_id) {
            try {
                let payload = {
                    prospect_id: formik.values.prospect_id,
                    debtor_id: formik.values.debtor_id,
                    hold_account_collection_status: true,
                };
                let res = await getDebtorProspectUC(payload);
                if (res.data.status) {
                    setUcValue(res.data.uc);
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        }
    };

    const fetchDebtors = async (keyword = null) => {
        if (prospectId !== formik.values.prospect_id) {
            setDebtorLoading(true);
            try {
                let payload = {
                    prospect_id: formik.values.prospect_id,
                    per_page: 100,
                    search_text: keyword,
                    status: 0,
                };
                let res = await getDebtors(payload);
                if (res.data.status) {
                    setDropdownDebtors(res.data.debtors.data);
                    if (res.data.debtors.data.length > 0) {
                        setDebtorLoading(false);
                    }
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        }
    };

    const fetchCollectableInvoices = async () => {
        setInvoiceLoading(true);
        let payload = {
            prospect_id: formik.values.prospect_id,
            debtor_id: formik.values.debtor_id,
            collection_type: collectionType,
        };
        try {
            let res = await getCollectionInvoices(payload);
            if (res.data.status) {
                setDropdownInvoices(res.data.invoices);

                setInvoiceLoading(false);
            } else {
                toast.showMessage("Fetching Error", "Sorry, we are unable to process your request at this time.", "error");
                setInvoiceLoading(false);
            }
        } catch (e) {
            setInvoiceLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchPaymentTypes = async () => {
        setPaymentTypeLoading(true);
        let payload = {
            select: ["payment_type"],
        };
        try {
            let res = await getCustomDropDown(payload);
            if (res.status) {
                const payment_type = JSON.parse(res.data.dropdowns.payment_type);
                setPaymentTypeOptions(payment_type);
                setPaymentTypeLoading(false);
            } else {
                toast.showMessage("Fetching Error", "Sorry, we are unable to process your request at this time.", "error");
                setPaymentTypeLoading(false);
            }
        } catch (e) {
            setPaymentTypeLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        fetchClients();
        fetchPaymentTypes();
    }, []);

    useEffect(() => {
        setAmountFieldDisabled(true);
        let invoices;
        if (formik.values.invoices.length) {
            invoices = formik.values.invoices.map((inv) => {
                inv.uc = "";
                return inv;
            });
        } else {
            invoices = formik.values.invoices;
        }

        setSelectedInvoices(invoices);
        let amount = 0;
        if (formik.values.invoices.length > 0) {
            let dateObjects = [];
            setAmountFieldDisabled(false);
            invoices.forEach((invoice) => {
                if (invoice.actual_cb > 0) {
                    amount += parseFloat(invoice.cb);
                } else {
                    amount += parseFloat(invoice.pending_amount);
                }
                dateObjects.push(new Date(invoice.funding_date));
            });

            // Finding the max Date in selected Invoices...
            const maxDate = new Date(Math.max(...dateObjects));
            setMaxFundingDate(maxDate);
        }

        setTotalBalanceAmount(amount.toFixed(2));
    }, [formik.values.invoices]);

    useEffect(() => {
        if (formik.values.prospect_id !== "") {
            setProspectId(formik.values.prospect_id);
            formik.setFieldValue("invoices", "");
            fetchDebtors();
        }
        if (formik.values.prospect_id !== "" && formik.values.debtor_id) {
            setDebtorId(formik.values.debtor_id);
            formik.setFieldValue("invoices", "");
            fetchCollectableInvoices();
            fetchDebtorProspectUC();
        }
    }, [formik.values.prospect_id, formik.values.debtor_id]);

    const inputCollectedAmountHandler = (e, data, index) => {
        const { value } = e;
        let obj = [...selectedInvoices];
        obj[index]["collected_amount"] = value;

        if (obj[index].actual_cb > 0 || obj[index].pending_amount <= obj[index].collected_amount || !obj[index].collected_amount) {
            obj[index].move_to_cb = false;

            if (showUnappliedCash) {
                obj[index].uc = "";
            }
        } else {
            if (!obj[index].move_to_cb) {
                obj[index].move_to_cb = false;
            }
        }

        //recalculate pending uc.

        let uc = 0;
        selectedInvoices.forEach((inv, i) => {
            if (inv.uc && i != index) {
                uc += parseFloat(inv.uc);
            }
        });

        if (uc <= parseFloat(formik.values.total_unapplied_cash)) {
            let updatePendingCash = parseFloat(formik.values.total_unapplied_cash) - uc;
            formik.setFieldValue("pending_unapplied_cash", updatePendingCash);
        } else {
            formik.setFieldValue("pending_unapplied_cash", formik.values.total_unapplied_cash);
        }

        setSelectedInvoices(obj);
    };

    const onMoveToChargebackChecked = (e, index) => {
        let obj = [...selectedInvoices];
        obj[index]["move_to_cb"] = e.checked;
        setSelectedInvoices(obj);
    };

    const onKeyDown = (e, index) => {
        const inputElement = e.target;
        const key = e.key;

        const isDigit = /^\d$/.test(key);
        if (isDigit) {
            // Get the current cursor position
            const selectionStart = inputElement.selectionStart;
            const selectionEnd = inputElement.selectionEnd;

            const currentValue = inputElement.value;
            const newValue = currentValue.substring(0, selectionStart) + key + currentValue.substring(selectionEnd);

            const value = currentStringToNumber(newValue);

            let obj = [...selectedInvoices];
            let rem = obj[index]["pending_amount"] - obj[index]["collected_amount"];

            let uc = 0;

            selectedInvoices.forEach((inv, i) => {
                if (inv.uc && i != index) {
                    uc += parseFloat(inv.uc);
                }
            });

            let pending = parseFloat(formik.values.total_unapplied_cash) - uc;

            if (value >= rem) {
                if (rem > pending) {
                    obj[index].uc = pending;
                    setMax(pending);
                } else {
                    obj[index].uc = rem;
                    setMax(rem);
                }
                e.preventDefault();
            } else {
                if (value > pending) {
                    obj[index].uc = pending;
                    e.preventDefault();
                } else {
                    // obj[index].uc = value ? parseFloat(value) : "";
                }
            }

            if (obj[index].uc && obj[index].pending_amount <= obj[index].collected_amount + parseFloat(obj[index].uc)) {
                obj[index].move_to_cb = false;
            }
            setSelectedInvoices(obj);

            uc = 0;
            selectedInvoices.forEach((inv, i) => {
                if (inv.uc) {
                    uc += parseFloat(inv.uc);
                }
            });

            if (uc <= parseFloat(formik.values.total_unapplied_cash)) {
                let updatePendingCash = parseFloat(formik.values.total_unapplied_cash) - uc;
                formik.setFieldValue("pending_unapplied_cash", updatePendingCash.toFixed(2));
            } else {
                formik.setFieldValue("pending_unapplied_cash", formik.values.total_unapplied_cash);
            }

            if (!uc) {
                formik.setFieldValue("pending_unapplied_cash", ucValue);
            }
        }
    };

    const onChangeSettleUc = (e, index) => {
        const value = e.value;

        let obj = [...selectedInvoices];
        let rem = obj[index]["pending_amount"] - obj[index]["collected_amount"];

        let uc = 0;

        selectedInvoices.forEach((inv, i) => {
            if (inv.uc && i != index) {
                uc += parseFloat(inv.uc);
            }
        });

        let pending = parseFloat(formik.values.total_unapplied_cash) - uc;

        if (value >= rem) {
            if (rem > pending) {
                obj[index].uc = pending;
                setMax(pending);
            } else {
                obj[index].uc = rem;
                setMax(rem);
            }
        } else {
            if (value > pending) {
                obj[index].uc = pending;
            } else {
                obj[index].uc = value ? parseFloat(value) : "";
            }
        }

        if (obj[index].uc && obj[index].pending_amount <= obj[index].collected_amount + parseFloat(obj[index].uc)) {
            obj[index].move_to_cb = false;
        }

        setSelectedInvoices(obj);

        uc = 0;
        selectedInvoices.forEach((inv, i) => {
            if (inv.uc) {
                uc += parseFloat(inv.uc);
            }
        });

        if (uc <= parseFloat(formik.values.total_unapplied_cash)) {
            let updatePendingCash = parseFloat(formik.values.total_unapplied_cash) - uc;
            formik.setFieldValue("pending_unapplied_cash", updatePendingCash.toFixed(2));
        } else {
            formik.setFieldValue("pending_unapplied_cash", formik.values.total_unapplied_cash);
        }

        if (!uc) {
            formik.setFieldValue("pending_unapplied_cash", ucValue);
        }
    };

    const templateCollectedAmount = (rowData, rowIndex) => {
        return (
            <>
                <InputNumber
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    value={rowData.collected_amount}
                    onChange={(e) => inputCollectedAmountHandler(e, rowData, rowIndex.rowIndex)}
                    className="collected_amount"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    min={0}
                    max={99999999.99}
                />
            </>
        );
    };

    const templatependingAmount = (rowData, rowIndex) => {
        const uc = rowData.uc ? parseFloat(rowData.uc) : 0;
        const calculatedAmount = rowData?.collected_amount
            ? ((rowData?.actual_cb > 0 ? parseFloat(rowData?.cb) : parseFloat(rowData?.pending_amount)) - (parseFloat(rowData?.collected_amount) + uc)).toFixed(2)
            : rowData?.actual_cb > 0
            ? parseFloat(rowData?.cb)
            : parseFloat(rowData?.pending_amount).toFixed(2);

        return <p>{currencyFormatUSD(calculatedAmount)}</p>;
    };

    const templateMoveToChargeback = (rowData, rowIndex) => {
        return (
            <>
                <div className={`mr-2 mt-1`}>
                    <Checkbox
                        onChange={(e) => onMoveToChargebackChecked(e, rowIndex.rowIndex)}
                        checked={selectedInvoices[rowIndex.rowIndex]?.move_to_cb ? true : false}
                        disabled={
                            rowData.actual_cb > 0 ||
                            selectedInvoices[rowIndex.rowIndex].pending_amount <= selectedInvoices[rowIndex.rowIndex].collected_amount ||
                            !selectedInvoices[rowIndex.rowIndex].collected_amount ||
                            (rowData.uc && rowData.pending_amount <= parseFloat(rowData.uc) + rowData.collected_amount)
                                ? true
                                : false
                        }
                    ></Checkbox>
                </div>
            </>
        );
    };

    const templateSettleUc = (rowData, rowIndex) => {
        return (
            <>
                <div className={`mr-2 mt-1`}>
                    <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        value={rowData.uc}
                        onChange={(e) => onChangeSettleUc(e, rowIndex.rowIndex)}
                        onKeyDown={(e) => onKeyDown(e, rowIndex.rowIndex)}
                        disabled={
                            selectedInvoices[rowIndex.rowIndex].pending_amount <= selectedInvoices[rowIndex.rowIndex].collected_amount || !selectedInvoices[rowIndex.rowIndex].collected_amount
                                ? true
                                : false
                        }
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        min={0}
                        max={max}
                    />
                </div>
            </>
        );
    };

    const templateBalanceAmount = (rowData, rowIndex) => {
        if (rowData.actual_cb > 0)
            return (
                <>
                    <p>{currencyFormatUSD(rowData.cb)}</p>
                </>
            );
        else {
            return (
                <>
                    <p>{currencyFormatUSD(rowData.pending_amount)}</p>
                </>
            );
        }
    };
    const templateInvoiceType = (rowData, rowIndex) => {
        if (rowData.actual_cb > 0) {
            return "ChargeBack";
        } else {
            return "Invoice";
        }
    };
    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];

            const fileType = file.name.split(".");
            const index = fileType.length - 1;
            const accept = ".jpg,.jpeg,.png,.pdf";
            const check_file = accept.includes(fileType[index]);
            if (check_file) {
                const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
                if (fileSize) {
                    toastContext.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size.", "error");
                    event.target.value = null;
                } else if (file.name.includes("#") || file.name.includes("&")) {
                    toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                } else {
                    setUploadedFile(file);
                    formik.setFieldValue("check_path", file);
                    formik.setFieldTouched("check_path", false);
                    formik.setFieldError("check_path", "");
                    event.target.value = null;
                }
            } else {
                event.target.value = null;
                toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
            }
        }
    };
    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (value) {
            formik.setFieldValue(name, value);
        } else {
            formik.setFieldValue(name, "");
        }
        if (name === "check_deposit_date") {
            formik.setFieldValue("posting_date", "");
        }
        if (name === "check_date" && formik.values.payment_type === 2) {
            setMinPostingDate(new Date(value));
        } else if (formik.values.payment_type !== 2 && name === "check_deposit_date") {
            setMinPostingDate(new Date(value));
        }
    };
    const handleShowUnappliedCash = (e) => {
        const { value } = e;
        if (!value) {
            formik.setFieldValue("uc_collected", "0");
            formik.setFieldValue("pending_unapplied_cash", ucValue);

            let obj = [...selectedInvoices];
            obj.forEach((inv) => {
                inv.uc = "";
            });

            setSelectedInvoices(obj);
            setMax(99999999.99);
        }

        setShowUnappliedCash(value);
    };
    const handleAttachmentDelete = () => {
        setUploadedFile(null);
    };
    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            history.push("/collections");
        }
    };
    const confirmHandler = () => {
        history.push("/collections");
    };
    //Reseting Forms values when the collection type changes
    useEffect(() => {
        formik.resetForm();
        setUploadedFile(null);
    }, [collectionType]);
    const handleOpenUpload = () => {
        hiddenFileInput.current.click();
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <CustomInputField
                                            options={dropdownClients}
                                            optionLabel="company_business_name"
                                            optionValue="id"
                                            iden="prospect_id"
                                            label="Client Name"
                                            formik={formik}
                                            type="dropdown"
                                            filter={true}
                                            filterBy="company_business_name"
                                            placeHolder="Select"
                                            mandatory="*"
                                            disabled={clientLoading}
                                            handleFilter={handleClientFilter}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <CustomInputField
                                            options={dropdownDebtors}
                                            optionLabel="debtor_name"
                                            optionValue="id"
                                            iden="debtor_id"
                                            label="Debtor Name"
                                            formik={formik}
                                            type="dropdown"
                                            filterBy="legal_name"
                                            placeHolder="Select"
                                            mandatory="*"
                                            disabled={debtorLoading}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <CustomInputField
                                            label="Invoices"
                                            type="multiSelect"
                                            iden="invoices"
                                            formik={formik}
                                            optionLabel="invoice_no"
                                            // optionValue="id"
                                            options={dropdownInvoices}
                                            mandatory="*"
                                            placeHolder="Select"
                                            disabled={invoiceLoading}
                                        />
                                    </div>
                                    {collectionType === 3 && (
                                        <>
                                            <div className="col-12 md:col-4">
                                                <CustomInputField
                                                    label={"Total Amount"}
                                                    type="currency"
                                                    iden="total_amount"
                                                    formik={formik}
                                                    mandatory="*"
                                                    placeHolder="Enter Amount"
                                                    onValueChange={(e) => handleTotalAmount(e)}
                                                    min={0}
                                                    max={99999999.99}
                                                    maxFractionDigits={2}
                                                    minFractionDigits={2}
                                                    disabled={amountsFieldDisabled}
                                                />
                                            </div>
                                            <div className="col-12 md:col-4">
                                                <CustomInputField
                                                    label={"Chargebacks Amount"}
                                                    type="currency"
                                                    iden="cb_amount"
                                                    formik={formik}
                                                    mandatory="*"
                                                    onValueChange={(e) => handleCBAmount(e)}
                                                    min={0}
                                                    max={99999999.99}
                                                    maxFractionDigits={2}
                                                    minFractionDigits={2}
                                                    disabled={amountsFieldDisabled}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="col-12 md:col-4">
                                        <CustomInputField
                                            label={collectionType !== 3 ? `${subHeader} Amount` : "Invoices Amount"}
                                            type="currency"
                                            iden="amount"
                                            formik={formik}
                                            mandatory="*"
                                            placeHolder="Enter Amount"
                                            onValueChange={(e) => handleInputAmount(e)}
                                            min={0}
                                            max={99999999.99}
                                            maxFractionDigits={2}
                                            minFractionDigits={2}
                                            disabled={amountsFieldDisabled}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <CustomDropdownField
                                            label="Payment Type"
                                            type="dropdown"
                                            iden="payment_type"
                                            formik={formik}
                                            optionLabel="name"
                                            optionValue="value"
                                            options={paymentTypeOptions}
                                            mandatory="*"
                                            onChange={(e) => handleInputChange(e)}
                                            placeHolder="Select"
                                            disabled={paymentTypeLoading}
                                            // onChange={(e) => inputHandler(e)}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <label htmlFor="NameTitle">
                                            <b>Attachment</b>
                                            <span className="clr_red">*</span>
                                            <Button
                                                type="button"
                                                tooltip=".pdf, .jpeg, .png, .jpg files only (max size 3MB) "
                                                tooltipOptions={{ position: "bottom" }}
                                                icon={<BsInfoCircle />}
                                                aria-label="Submit"
                                                className="customTooltipicon"
                                            />
                                        </label>
                                        <div className="file_upload_class">
                                            <label className={`flex flex-row justify-content-between align-items-center`} onClick={handleOpenUpload}>
                                                {uploadedFile ? (
                                                    <div className="flex row">
                                                        <span className="text_break">{uploadedFile.name}</span>
                                                        <BsXLg className="cursor-pointer cross_icon ml-1" onClick={() => handleAttachmentDelete()} />
                                                    </div>
                                                ) : (
                                                    <> Choose File </>
                                                )}
                                                <BsPaperclip />
                                            </label>
                                        </div>
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            disabled={uploadedFile}
                                            id="file-upload"
                                            onChange={onFileSelect}
                                            accept=".pdf,.jpeg,.png,.jpg"
                                            style={{ display: "none" }}
                                        />
                                        {getFormErrorMessage("check_path")}
                                    </div>

                                    {formik.values.payment_type === 0 || formik.values.payment_type ? (
                                        <>
                                            <div className="col-12 md:col-6">
                                                <CustomInputField
                                                    label={
                                                        formik.values.payment_type === 0
                                                            ? "Routing No"
                                                            : formik.values.payment_type === 1
                                                            ? "Check No"
                                                            : formik.values.payment_type === 2
                                                            ? "Transaction No"
                                                            : ""
                                                    }
                                                    keyfilter={/^[[0-9a-zA-Z]+$/}
                                                    iden="check_number"
                                                    formik={formik}
                                                    mandatory="*"
                                                    placeHolder={
                                                        formik.values.payment_type === 0
                                                            ? "Enter Routing No"
                                                            : formik.values.payment_type === 1
                                                            ? "Enter Check No"
                                                            : formik.values.payment_type === 2
                                                            ? "Enter Transaction No"
                                                            : ""
                                                    }
                                                    maxLength={20}
                                                />
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <label htmlFor="phone" className="step-fields-title">
                                                    {formik.values.payment_type === 0 ? (
                                                        <b>ACH Date</b>
                                                    ) : formik.values.payment_type === 1 ? (
                                                        <b>Check Date</b>
                                                    ) : formik.values.payment_type === 2 ? (
                                                        <b>Wire Date</b>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span className="clr_red">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <Calendar
                                                        id="check_date"
                                                        name="check_date"
                                                        value={new Date(formik.values.check_date)}
                                                        placeholder="mm/dd/yyyy"
                                                        mask="99/99/9999"
                                                        showIcon
                                                        onChange={(e) => inputHandler(e)}
                                                        minDate={new Date(maxFundingDate)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange="1945:2050"
                                                    />
                                                </div>
                                                {getFormErrorMessage("check_date")}
                                            </div>
                                            {formik.values.payment_type !== 2 ? (
                                                <div className="col-12 md:col-6">
                                                    <label htmlFor="phone" className="step-fields-title">
                                                        {formik.values.payment_type === 0 ? <b>ACH Deposit Date</b> : formik.values.payment_type === 1 ? <b>Check Deposit Date</b> : ""}
                                                        <span className="clr_red">*</span>
                                                    </label>
                                                    <div className="mt-2">
                                                        <Calendar
                                                            id="check_deposit_date"
                                                            name="check_deposit_date"
                                                            value={new Date(formik.values.check_deposit_date)}
                                                            placeholder="mm/dd/yyyy"
                                                            mask="99/99/9999"
                                                            minDate={new Date(formik.values.check_date)}
                                                            showIcon
                                                            onChange={(e) => inputHandler(e)}
                                                            monthNavigator
                                                            yearNavigator
                                                            yearRange="1945:2050"
                                                        />
                                                    </div>
                                                    {getFormErrorMessage("check_deposit_date")}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="col-12 md:col-6">
                                                <label htmlFor="phone" className="step-fields-title">
                                                    <b>Posting Date</b>
                                                    <span className="clr_red">*</span>
                                                </label>
                                                <div className="mt-2">
                                                    <Calendar
                                                        id="posting_date"
                                                        name="posting_date"
                                                        value={new Date(formik.values.posting_date)}
                                                        placeholder="mm/dd/yyyy"
                                                        mask="99/99/9999"
                                                        minDate={new Date(minPostingDate)}
                                                        showIcon
                                                        onChange={(e) => inputHandler(e)}
                                                        monthNavigator
                                                        yearNavigator
                                                        yearRange="1945:2050"
                                                    />
                                                </div>
                                                {getFormErrorMessage("posting_date")}
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            {/* //Table */}
                            <div className="card pt-3">
                                <div className="grid">
                                    <div className="col-12">
                                        <h5>
                                            <b>{header}</b>
                                        </h5>
                                        <p>
                                            Total Amount For Selected {subHeader} Invoice(s): $ <span>{totalBalanceAmount}</span>
                                        </p>
                                        <p className="p-error">{amountMessage}</p>
                                    </div>
                                    <div className="grid">
                                        {unappliedCash && (
                                            <>
                                                <div className="col-12  mb-3 mt-3">
                                                    <div className="flex flex-row align-items-center">
                                                        <InputSwitch checked={showUnappliedCash} onChange={handleShowUnappliedCash} />
                                                        <span className="pl-2">
                                                            <b>Settle Unapplied Cash?</b>
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {showUnappliedCash && (
                                            <>
                                                <div className="col-12 md:col-4">
                                                    <CustomInputField
                                                        label="Total Unapplied Cash"
                                                        type="text"
                                                        iden="total_unapplied_cash"
                                                        formik={formik}
                                                        placeHolder="Enter Total Unapplied Cash"
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="col-12 md:col-4">
                                                    <CustomInputField
                                                        label="Pending Unapplied Cash"
                                                        type="number"
                                                        iden="pending_unapplied_cash"
                                                        formik={formik}
                                                        placeHolder="Enter Pending Unapplied Cash"
                                                        disabled={true}
                                                    />
                                                </div>
                                                {/* <div className="col-12 md:col-4">
                                                    <CustomInputField
                                                        label="Settle Unapplied Cash"
                                                        type="currency"
                                                        iden="uc_collected"
                                                        formik={formik}
                                                        mandatory="*"
                                                        placeHolder="Enter Settle Unapplied Cash"
                                                        onValueChange={(e) => handleSettleUnAppliedAmount1(e)}
                                                        min={0}
                                                        max={parseFloat(formik.values.total_unapplied_cash)}
                                                        maxFractionDigits={2}
                                                        minFractionDigits={2}
                                                    />
                                                </div> */}
                                            </>
                                        )}
                                    </div>
                                    <div className="col-12 pt-3">
                                        <DataTable ref={dt} value={selectedInvoices} responsiveLayout="scroll" emptyMessage="No record available.">
                                            <Column field="invoice_no" header={collectionType === 2 ? "CB Invoice No" : "Invoice No"}></Column>
                                            {collectionType === 3 && <Column body={templateInvoiceType} header={"Invoice Type"}></Column>}
                                            <Column body={templateBalanceAmount} header={collectionType === 2 ? "CB Balance Amount" : "Balance Amount"}></Column>
                                            <Column body={templateCollectedAmount} header={collectionType === 2 ? "CB Amount to be Settled" : "Amount to be Settled"}></Column>
                                            <Column body={templatependingAmount} header={collectionType === 2 ? "CB Pending Amount" : "Pending Amount"}></Column>
                                            {(collectionType == 1 || collectionType == 3) && <Column body={templateMoveToChargeback} header="Move to Chargeback"></Column>}
                                            {showUnappliedCash && <Column body={templateSettleUc} header="Settle Unapplied Cash"></Column>}
                                        </DataTable>
                                        <CustomInputField
                                            className={`col-12 md:col-12  mt-3`}
                                            type="textarea"
                                            iden="notes"
                                            label="Notes"
                                            formik={formik}
                                            placeHolder="Notes"
                                            rows="3"
                                            maxLength={150}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-3 text-right">
                            {/* onClick={() => history.push("/collections")} */}
                            <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} />
                            <CustomAddNewButton title="Complete Collection" type="submit" isDisabled={isSaving} isLoading={isLoading} />
                        </div>
                    </div>
                </div>
            </form>
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};
export default CollectionCard;
