import {
    BsArrowRepeat,
    BsBarChartFill,
    BsCalendar4,
    BsCalendarCheck,
    BsCardChecklist,
    BsCashStack,
    BsCurrencyDollar,
    BsCurrencyExchange,
    BsExclamationTriangleFill,
    BsFileEarmarkTextFill,
    BsFillBarChartFill,
    BsFlag,
    BsGraphUp,
    BsGraphUpArrow,
    BsPeople,
    BsPeopleFill,
    BsPieChart,
    BsPieChartFill,
    BsToggleOff,
} from "react-icons/bs";
import React from "react";
import {
    CHATS_RIGHTS,
    CREDIT_REQUEST_RIGHTS,
    DASHBOARD_RIGHTS,
    DOCUMENT_RIGHTS,
    GENERAL_LEDGER_RIGHTS,
    MANAGMENT_RIGHTS,
    REPORTS_RIGHTS,
    SETTINGS_RIGHTS,
    SUPPORT_RIGHTS,
    TRANSACTION_RIGHTS,
    UNDERWRITING_RIGHTS,
    USERMANAGMENT_RIGHTS,
} from "./rights";
import { CiSearch } from "react-icons/ci";
import { CgFileDocument } from "react-icons/cg";
import { BiUserCircle } from "react-icons/bi";
import { MdOutlineCampaign } from "react-icons/md";
import { GrAnnounce, GrTransaction } from "react-icons/gr";

export function getContactMask(phone_code = null, str) {
    if (str) {
        let country_mask = "";
        if (phone_code) {
            country_mask = "(" + phone_code + ")" + " ";
        }
        str = str.replaceAll("-", "");
        let phone_mask = str.toString().slice(0, 3) + "-" + str.toString().slice(3, 6) + "-" + str.toString().slice(6, 10);
        let combine_phone_country_mask = country_mask + phone_mask;
        return combine_phone_country_mask;
    }
}

export function getSSNMask(str) {
    if (str) {
        let ssn_mask = str.toString().slice(0, 3) + "-" + str.toString().slice(3, 5) + "-" + str.toString().slice(5, 9);
        return ssn_mask;
    }
}

export const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm";

// under writing document types used in client  module
export const underwritingDocumentTypes = [
    { label: "Picture ID / Driver’s License of Owner" },
    { label: "New prospect Application form with complete Banking & required weekly funding details" },
    { label: "941/940/943 and State Tax Returns" },
    { label: "Articles of Incorporation" },
    { label: "Bank Verification Documents" },
    { label: "Company Financial Statements (Balance Sheet, Profit & loss, Cash Flows)" },
    { label: "Corporate Tax Returns" },
    { label: "Debtors Contact List" },
    { label: "Debtors Contracts" },
    { label: "Debtors Payment History / Collections" },
    { label: "Invoice Summary Aging of 1 year" },
    { label: "Liability Insurance Certificate" },
    { label: "Personal Tax Returns" },
    { label: "Sample Copy of Invoice" },
    { label: "Weekly Funding Breakdown Debtor-wise" },
    { label: "Voided Check" },
    { label: "Workers Comp Insurance" },
];

// App Menu
export const APP_SIDEBAR_MENU = [
    {
        // label: "Home",
        items: [
            // 0
            {
                label: "Dashboard",
                icon: "bi bi-grid-1x2",
                to: "/dashboard",
                required_rights: [DASHBOARD_RIGHTS.view],
            },
            // 1
            {
                label: "Debtor Dashboard",
                icon: "bi bi-columns-gap",
                to: "/debtordashboard",
            },
            // 2
            {
                label: "Management",
                icon: "bi bi-people",
                required_rights: [MANAGMENT_RIGHTS.clients.view, MANAGMENT_RIGHTS.debtors.view, MANAGMENT_RIGHTS.prospects.view, MANAGMENT_RIGHTS.brokers.view],
                items: [
                    { label: "Prospects", to: "/prospectindex", required_rights: [MANAGMENT_RIGHTS.prospects.view], isAllowed: false },
                    { label: "Clients", to: "/clientindex", required_rights: [MANAGMENT_RIGHTS.clients.view], isAllowed: false },
                    { label: "Debtors", to: "/debtormain", required_rights: [MANAGMENT_RIGHTS.debtors.view], isAllowed: false },
                    { label: "Brokers", to: "/brokers", required_rights: [MANAGMENT_RIGHTS.brokers.view], isAllowed: false },
                ],
                isAllowed: false,
            },
            // 3
            {
                label: "Transactions",
                icon: "bi bi-arrow-repeat",
                required_rights: [
                    TRANSACTION_RIGHTS.invoices.view,
                    TRANSACTION_RIGHTS.schedule_of_assignment.view,
                    TRANSACTION_RIGHTS.schedule_of_funding.view,
                    TRANSACTION_RIGHTS.collections.view,
                    TRANSACTION_RIGHTS.hold_accounts.view,
                    TRANSACTION_RIGHTS.charge_backs.view,
                ],
                isAllowed: false,
                items: [
                    { label: "Invoices", to: "/invoices", required_rights: [TRANSACTION_RIGHTS.invoices.view], isAllowed: false },
                    {
                        label: "Schedule of Assignment",
                        to: "/scheduleofAssignment",
                        required_rights: [TRANSACTION_RIGHTS.schedule_of_assignment.view],
                        isAllowed: false,
                    },
                    {
                        label: "Schedule of Funding",
                        to: "/scheduleoffunding",
                        required_rights: [TRANSACTION_RIGHTS.schedule_of_funding.view],
                        isAllowed: false,
                    },
                    {
                        label: "Collections",
                        to: "/collections",
                        required_rights: [TRANSACTION_RIGHTS.collections.view],
                        isAllowed: false,
                    },
                    {
                        label: "Unapplied Cash",
                        to: "/unappliedcash",
                        required_rights: [TRANSACTION_RIGHTS.hold_accounts.view],
                        isAllowed: false,
                    },
                    {
                        label: "Reserves",
                        to: "/reserves",
                        required_rights: [TRANSACTION_RIGHTS.reserves.view],
                        isAllowed: false,
                    },
                    {
                        label: "Chargebacks",
                        to: "/chargebacks",
                        required_rights: [TRANSACTION_RIGHTS.charge_backs.view],
                        isAllowed: false,
                    },
                    {
                        label: "Broker Payment",
                        //  to: "/brokerpayment"
                        required_rights: [],
                        isAllowed: false,
                    },
                ],
            },
            //4
            {
                label: "General Ledger",
                icon: "bi bi-journal-text",
                to: "/generalledger",
                required_rights: [GENERAL_LEDGER_RIGHTS.view],
                isAllowed: false,
            },
            // 5
            {
                label: "Credit Request",
                icon: "bi bi-credit-card-2-front",
                required_rights: [CREDIT_REQUEST_RIGHTS.client.view, CREDIT_REQUEST_RIGHTS.debtor.view],
                isAllowed: false,
                items: [
                    { label: "Client Request", to: "/clientrequest", required_rights: [CREDIT_REQUEST_RIGHTS.client.view], isAllowed: false },
                    { label: "Debtor Request", to: "/debtorrequest", required_rights: [CREDIT_REQUEST_RIGHTS.debtor.view], isAllowed: false },
                ],
            },
            // 6
            {
                label: "Underwriting",
                icon: "bi bi-file-earmark-text",
                to: "/clientchecklist",
                required_rights: [UNDERWRITING_RIGHTS.view],
                isAllowed: false,
            },
            // 7
            {
                label: "Reports",
                icon: "bi bi-file-earmark-bar-graph",
                to: "/reports",
                required_rights: [
                    REPORTS_RIGHTS.purchase_summary_with_fee.view,
                    REPORTS_RIGHTS.purchase_without_fee.view,
                    REPORTS_RIGHTS.invoices_summary_aging.view,
                    REPORTS_RIGHTS.payment_history_detail.view,
                    REPORTS_RIGHTS.reserve_details.view,
                    REPORTS_RIGHTS.escrow_reserve.view,
                    REPORTS_RIGHTS.credit_limit_warning.view,
                    REPORTS_RIGHTS.last_payment_date.view,
                    REPORTS_RIGHTS.charge_backs.view,
                    REPORTS_RIGHTS.client_analysis.view,
                    REPORTS_RIGHTS.client_risk_management.view,
                    REPORTS_RIGHTS.broker_commission.view,
                    REPORTS_RIGHTS.unapplied_cash_received.view,
                    REPORTS_RIGHTS.collection_efficiency_analysis.view,
                    REPORTS_RIGHTS.cash_posting_report.view,
                    REPORTS_RIGHTS.debtor_aging_report.view,
                    REPORTS_RIGHTS.transactions.view,
                ],
                isAllowed: false,
            },
            // 8
            {
                label: "Support ",
                icon: "bi bi-headset",
                to: "/supportmain",
                required_rights: [SUPPORT_RIGHTS.view],
                isAllowed: false,
            },
            // 9
            {
                label: "User Management",
                icon: "bi bi-people",
                required_rights: [USERMANAGMENT_RIGHTS.user_profile_view, USERMANAGMENT_RIGHTS.role_rights_view],
                isAllowed: false,
                items: [
                    { label: "User Profiles", to: "/userindex", required_rights: [USERMANAGMENT_RIGHTS.user_profile_view], isAllowed: false },
                    { label: "Roles & Rights", to: "/rolesrights", required_rights: [USERMANAGMENT_RIGHTS.role_rights_view], isAllowed: false },
                ],
            },
            // 10
            {
                label: "Settings",
                icon: "bi bi-toggle-off",
                to: "/settings",
                required_rights: [SETTINGS_RIGHTS.announcments.view, SETTINGS_RIGHTS.policies.view],
            },
            // 11
            {
                label: "Chats",
                icon: "bi bi-chat-dots",
                to: "/chatindex",
                required_rights: [CHATS_RIGHTS.view],
                isAllowed: false,
            },
            // {
            //     label: "Deposits",
            //     icon: "bi bi-cash-coin",
            //     items: [
            //         { label: "Deposits", to: "/deposits" },
            //         { label: "Receiving Accounts", to: "/receivingaccounts" },
            //         { label: "Bank Accounts", to: "/bankaccounts" },
            //     ],
            // },

            // 12
            {
                label: "Documents",
                icon: "bi bi-file-earmark-word",
                to: "/documents",
                required_rights: [DOCUMENT_RIGHTS.view],
                isAllowed: false,
            },
        ],
        isAllowed: true,
    },
];

// SUPPORT
export const SUPPORT_GRID_HEADERS = [
    { field: "title", header: "Ticket Title" },
    { field: "ticket_type_text", header: "Ticket Type" },
    { field: "priority_text", header: "Priority" },
    { field: "status_text", header: "Status" },
    { field: "assignedto", header: "Assigned To" },
    { field: "createdBy", header: "Created By" },
    { field: "created_at", header: "Requested Date & Time" },
    { field: "resolution_date", header: "Resolution Date & Time" },
];

export const SUPPORT_TICKET_DETAIL = [
    { field: "responder", header: "Responder" },
    { field: "responsetime", header: "Response Date & Time" },
    { field: "attachments", header: "Attachments" },
    { field: "response", header: "Response" },
];

export const INVOICES_GRID_HEADERS = [
    { field: "invoice_no", header: "Invoice No" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "company_business_name", header: "Client Name" },
    // { field: "legal_name", header: "Debtor Name" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "funding_date", header: "Funding Date" },
    { field: "status_text", header: "Status" },
    { field: "invoice_amount", header: "Invoice Amount" },
];

export const INVOICES_GRID_HEADERS_FOR_CLIENT = [
    { field: "invoice_no", header: "Invoice No" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "legal_name", header: "Debtor Name" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "funding_date", header: "Funding Date" },
    { field: "status_text", header: "Status" },
    { field: "invoice_amount", header: "Invoice Amount" },
];
//schedules
export const SCHEDULES_GRID_HEADERS = [
    { field: "company_business_name", header: "Client Name" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "schedule_fund_date", header: "Funding Date" },
    { field: "attachmentCount", header: "Attachments" },
    { field: "unverifiedCount", header: "Unverified Invoices" },
    { field: "verifiedCount", header: "Verified Invoices" },
    { field: "invoicesCount", header: "Total Invoices" },
    { field: "total_amount", header: "Net Funding" },
    { field: "remaining_amount", header: "Remaining Amount" },
    { field: "totalScheduleFunding", header: "Total Funding" },
];
//collections
export const COLLECTIONS_GRID_HEADERS = [
    { field: "collection_id", header: "Collection ID" },
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "posting_date", header: "Posting Date" },
    { field: "payment_type_text", header: "Payment Type" },
    { field: "collection_type_text", header: "Collection Type" },
    // { field: "collected_invoices", header: "Invoices Count" },
    { field: "amount", header: "Collection Amount" },
];
// UNAPPLIED Cash
export const UNAPPLIED_CASH_GRID_HEADERS = [
    { field: "invoice_no", header: "Invoice No" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "company_business_name", header: "Client Name" },
    { field: "legal_name", header: "Debtor Name" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "funding_date", header: "Funding Date" },
    { field: "status_text", header: "Status" },
    { field: "advance_percentage", header: "Advance Percentage" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "uc", header: "Unapplied Cash" },
];
export const UNAPPLIED_CASH_WITHOUT_GRID_HEADERS = [
    { field: "company_business_name", header: "Client Name" },
    { field: "legal_name", header: "Debtor Name" },
    { field: "payment_type_text", header: "Payment Type" },
    { field: "note", header: "Comments" },
    { field: "amount", header: "Amount Received" },
];
export const UNAPPLIED_CASH_RELEASED = [
    { field: "company_business_name", header: "Client Name" },
    { field: "legal_name", header: "Debtor Name" },
    { field: "payment_type_text", header: "Payment Type" },
    { field: "uc_released_date", header: "Released Date" },
    { field: "amount", header: "Amount Received" },
];

// PROSPECT
export const HOME_OWNERSHIP_OPTION = [
    { name: "Own", value: 1 },
    { name: "Rent", value: 0 },
];
export const BUSINESS_LOAN_OUTSTANDING_STATUS = [
    { name: "Yes", value: 1 },
    { name: "No", value: 0 },
];
export const FACTORED_BEFORE_STATUS = [
    { name: "Yes", value: 1 },
    { name: "No", value: 0 },
];

export const LEGAL_STATUS = [
    { name: "Corporation", value: 1 },
    { name: "LLC", value: 2 },
    { name: "Partnership", value: 3 },
    { name: "Sale Proprietorship", value: 4 },
];
export const FEDRAL_TAXES_PAST_DUE = [
    { name: "Yes", value: 1 },
    { name: "No", value: 0 },
];
export const TAXI_LIEN_FIELD = [
    { name: "Yes", value: 1 },
    { name: "No", value: 0 },
];
export const LEASING_BUSINESS_SPACE = [
    { name: "Yes", value: 1 },
    { name: "No", value: 0 },
];
//Kebab Menu Items
export const PROSPECT_MENU_ITEMS = [
    { status: 1, title: "Promote to Client" },
    { status: 2, title: "Demote to Fallout" },
    { status: 3, title: "Delete" },
];

export const PROSPECT_DULLY_MENU_ITEMS = [
    { status: 1, title: "Change Status" },
    { status: 2, title: "Demote to Fallout" },
    { status: 3, title: "Email to Prospect" },
];

export const AVAILABLE_PROSPECT = [
    { field: "company_business_name", header: "Registered Business Name" },
    { field: "poc_name", header: "Contact Name" },
    { field: "poc_email", header: "Contact Email" },
    { field: "company_phone", header: "Phone No" },
    { field: "funding_amount", header: "Required Funding Amount" },
];

export const DULLY_FILLED_APPLICATION = [
    { field: "company_business_name", header: "Registered Business Name" },
    { field: "poc_name", header: "Contact Name" },
    { field: "poc_email", header: "Contact Email" },
    { field: "company_phone", header: "Phone No" },
    { field: "step", header: "Steps Completed" },
];

export const FALL_OUT = [
    { field: "company_business_name", header: "Registered Business Name" },
    { field: "poc_name", header: "Contact Name" },
    { field: "poc_email", header: "Contact Email" },
    { field: "company_phone", header: "Phone No" },
];

export const DOCUMENT_CHECKLIST = [
    { title: "Invoices to Factor (include purchase orders and/or contracts)", value: "invoices_to_factor" },
    { title: "Customer List with Addresses", value: "customer_list_with_addresses" },
    { title: "Accounts Receivable Aging", value: "accounts_receivable_aging" },
    { title: "Current Financial Statements", value: "current_financial_statements" },
    { title: "Accounts Payable Aging", value: "accounts_payable_aging" },
    { title: "Bank Authorization Form (to be provided)", value: "bank_authorization_form" },
    { title: "Tax Authorization Form (to be provided)", value: "tax_authorization_form" },
    { title: "Tax Returns", value: "tax_returns" },
    { title: "Liability Insurance Certificate", value: "liability_insurance_certificate" },
    { title: "Articles of Incorporation or Assumed Name Certificate", value: "articles_of_incorporation_or_assumed_name_certificate" },
    { title: "Copy of 941s (last four quarters) with Proof of Payment", value: "copy_of_941s_with_proof_of_payment" },
    { title: "Sample Copy of Invoice", value: "sample_copy_of_invoice" },
    { title: "Workers’ Comp. Insurance (if requested)", value: "workers_comp_insurance" },
    { title: "Copy of Current PACA License (Agricultural Firms)", value: "copy_of_current_PACA_license" },
    { title: "Copy of Applicant's Drivers License(s)", value: "copy_of_applicant_driver_license" },
];

export const BUSINESS_NAME_NOTES = [
    { field: "no", header: "Sr No." },
    { field: "notes", header: "Notes" },
];

export const BUSINESS_NAME_SEARCH = [
    { field: "name", header: "Attachment Name" },
    { field: "path", header: "Attachment" },
    { field: "notes", header: "Notes" },
];
export const UNDERWRITING_DOCUMENTS = [
    { field: "label", header: "Document Type" },
    { field: "attachment", header: "Attachment" },
];

export const FUTURE_STATUS = [
    { name: "Will Continue", value: 1 },
    { name: "Will not Continue", value: 2 },
    { name: "Unresponsive", value: 3 },
];

export const CHANGE_STATUS_GRID = [
    { field: "future_status_text", header: "Future Status" },
    { field: "attachment_path", header: "Attachment" },
    { field: "comments", header: "Comments" },
    { field: "response_given_by_prospect", header: "Prospect Response" },
];

// Client
export const CLIENT_HEADERS = [
    { field: "company_business_name", header: "Registered Business Name" },
    { field: "poc_name", header: "Contact Name" },
    { field: "poc_email", header: "Contact Email" },
    { field: "company_phone", header: "Phone No" },
    { field: "factoring_limit", header: "Client Limit" },
    { field: "active_credit_limit", header: "Client Available Limit" },
];

export const CLIENT_MENU_ITEMS = [
    // { status: 1, title: "Import", required_rights: [] },
    // { status: 2, title: "Export", required_rights: [] },
    { status: 3, title: "Terminate Client", required_rights: [], isDisabled: false },
    { status: 4, title: "Delete", required_rights: [MANAGMENT_RIGHTS.clients.delete] },
];

// Debtors
export const DEBTOR_GRID_HEADERS = [
    { field: "debtor_name", header: "Debtor Name" },
    { field: "client_name", header: "Client Name" },
    { field: "phone", header: "Phone No" },
    { field: "fein", header: "FEIN" },
    { field: "status", header: "Status" },
    { field: "requested_date", header: "Creation Date & Time" },
    { field: "credit_limit", header: "Credit Limit" },
    { field: "available_credit_limit", header: "Active Credit Limit" },
];

export const DEBTOR_GRID_HEADERS_FOR_CLIENT = [
    { field: "debtor_name", header: "Debtor Name" },
    { field: "phone", header: "Phone No" },
    { field: "fein", header: "FEIN" },
    { field: "status", header: "Status" },
    { field: "requested_date", header: "Creation Date & Time" },
    { field: "credit_limit", header: "Credit Limit" },
    { field: "available_credit_limit", header: "Active Credit Limit" },
];

export const DEBTOR_KEBAB_MENU_ITEMS = [
    { title: "Import", status: 1, required_rights: [""], isAllowed: false },
    { title: "Change Status", status: 2, required_rights: [MANAGMENT_RIGHTS.debtors.status_change], isAllowed: false },
];

//Credit Requests
export const CLIENT_CREDIT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "status", header: "Status" },
    { field: "created_at", header: "Request Date & Time" },
    { field: "action_date", header: "Approval/Denial Date & Time" },
    { field: "current_credit_limit", header: "Previous Credit Limit" },
    { field: "requested_amount", header: "Requested Credit Limit" },
    { field: "new_limit_amount", header: "Current Credit Limit" },
];

export const CLIENT_CREDIT_GRID_HEADERS_FOR_CLIENT = [
    { field: "current_credit_limit", header: "Previous Credit Limit" },
    { field: "requested_amount", header: "Requested Credit Limit" },
    { field: "new_limit_amount", header: "Current Credit Limit" },
    { field: "status", header: "Status" },
    { field: "created_at", header: "Request Date & Time" },
    { field: "action_date", header: "Approval/Denial Date & Time" },
];

export const DEBTOR_CREDIT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "status", header: "Status" },
    { field: "created_at", header: "Request Date & Time" },
    { field: "action_date", header: "Approval/Denial Date & Time" },
    { field: "current_credit_limit", header: "Previous Credit Limit" },
    { field: "requested_amount", header: "Requested Credit Limit" },
    { field: "new_limit_amount", header: "Current Credit Limit" },
];

export const DEBTOR_CREDIT_GRID_HEADERS_FOR_CLIENT = [
    { field: "debtor_name", header: "Debtor Name" },
    { field: "current_credit_limit", header: "Previous Credit Limit" },
    { field: "requested_amount", header: "Requested Credit Limit" },
    { field: "new_limit_amount", header: "Current Credit Limit" },
    { field: "status", header: "Status" },
    { field: "created_at", header: "Request Date & Time" },
    { field: "action_date", header: "Approval/Denial Date & Time" },
];

export const UNDERWRITING_PARENT_MENU = [
    {
        title: "Add Task",
        status: 0,
        required_rights: [UNDERWRITING_RIGHTS.add_task],
    },
    {
        title: "Edit Vertical",
        status: 1,
        required_rights: [UNDERWRITING_RIGHTS.edit_vertical],
    },
    {
        title: "Delete Vertical",
        status: 2,
        required_rights: [UNDERWRITING_RIGHTS.delete_vertical],
    },
];

export const UNDERWRITING_CHILD_MENU = [
    {
        title: "Edit",
        status: 1,
        required_rights: [UNDERWRITING_RIGHTS.edit_task],
    },
    {
        title: "Delete",
        status: 2,
        required_rights: [UNDERWRITING_RIGHTS.delete_task],
    },
];
// Document
export const DOCUMENT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "count", header: "File Count" },
    { field: "prospect_id", header: "Action" },
];
export const PROSPECT_DOCUMENT_GRID_HEADERS = [
    { field: "attachment_name", header: "File Name" },
    { field: "updated_at", header: "Upload Date" },
    { field: "attachment_path", header: "View" },
];

export const PROSPECT_UNDER_WRITING_GRID_HEADERS_ADMIN = [
    { field: "name", header: "File Name" },
    { field: "created_at", header: "Upload Date" },
    { field: "attachment_path", header: "View" },
    { field: "action", header: "Action" },
];

export const PROSPECT_UNDER_WRITING_GRID_HEADERS = [
    { field: "name", header: "File Name" },
    { field: "created_at", header: "Upload Date" },
    { field: "attachment_path", header: "View" },
];

export const PROSPECT_TERM_SHEET_HEADERS = [
    { field: "name", header: "Owner Name" },
    { field: "pivot.agreement_id", header: "Agreement Sent" },
    { field: "pivot.signature", header: "Agreement Attachment" },
];

export const COLLECTION_SELECTED_INVOICE_HEADERS = [
    { field: "invoice_no", header: "Invoice Number" },
    { field: "pending_amount", header: "Balance Amount" },
    { field: "collected_amount", header: "Amount to be settled" },
    { field: "", header: "Pending Amount" },
];

export const CHARGEBACK_GRID_HEADERS = [
    { field: "invoice_no", header: "Invoice No" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "funding_date", header: "Funding Date" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "actual_cb", header: "Actual CB" },
    { field: "cb", header: "Pending CB" },
    { field: "adjusted_cb", header: "CB to be Adjusted" },
];

export const TERM_SHEET_WEEKS = [
    { value: 0, name: "0" },
    { value: 1, name: 1 },
    { value: 2, name: 2 },
];

export const TERM_SHEET_CLEARANCE_DAYS = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
    { value: 6, name: 6 },
    { value: 7, name: 7 },
    { value: 8, name: 8 },
    { value: 9, name: 9 },
];

export const TERM_SHEET_EXIST_FEE = [
    { value: 6, name: 6 },
    { value: 12, name: 12 },
    { value: 24, name: 24 },
    { value: 36, name: 36 },
    { value: 48, name: 48 },
];

export const currencyFields = [
    "invoice_amount_total",
    "advance_amount_total",
    "fee_total",
    "cb_amount",
    "wire_fee",
    "funded_amount",
    "invoice_amount",
    "paid_amount",
    "cb_amount",
    "balance",
    "rebate_amount",
    "fee_amount",
    "payment",
    "applied_to_ar",
    "applied_to_advance",
    "applied_to_fee",
    "reserve_amount",
    "reserve_balance",
    "advance_amount",
    "collection",
    "escrow_reserve",
    "cash_reserve_amount",
    "escrow_balance",
    "actual_cb",
    "cb",
    "adjusted_cb",
    "payment_received",
    "remaining_balance",
    "purchases",
    "collection",
    "fee_earned",
    "debtor_credit_limit",
    "funded_balance",
    "ar_balance",
    "ar_turnover",
    "debtor_balance",
    "sales",
    "average_fund",
    "earnings",
    "amount_uc",
    "returned_uc",
    "fee_applicable",
    "amount",
];

export const CHARGEBACK_ADJUSTMENT_GRID = [
    { field: "client_name", header: "Client Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "cb", header: "CB Amount" },
    { field: "adjusted_cb", header: "CB Amount to be Adjusted" },
];

export const CASH_RESERVE_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "funding_date", header: "Funding Date" },
    { field: "reserved_amount", header: "Cash Reserves" },
];

export const ESCROW_RESERVE_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "funding_date", header: "Funding Date" },
    { field: "escrow_reserves", header: "Escrow Reserves" },
];

export const RESERVE_RELEASE_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "reserve_release_date", header: "Reserve Release Date" },
    { field: "released_amount", header: "Released Amount" },
    { field: "wire_fee", header: "Wire Fee" },
    { field: "previous_reserves", header: "Previous Reserves" },
];

export const RESERVE_RELEASE_BATCH_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "fee_days", header: "Fee Days" },
    { field: "posting_date", header: "Posting Date" },
    { field: "reserved_amount", header: "Reserved Amount" },
    { field: "tax_per_day", header: "Deductions" },
    { field: "release_amount", header: "Release Amount After Deduction" },
];

export const ESCROW_RESERVE_ADJUSTMENT_GRID_HEADERS = [
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "advance_amount", header: "Advance Amount" },
    { field: "collection_date", header: "Status" },
    { field: "available_escrow_reserves", header: "Escrow Deductions" },
];

export const RESERVE_RELEASE_BREAKDOWN_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "reserve_released_amount", header: "Reserve Release Amount" },
    { field: "collection_date", header: "Collection Date" },
];

export const PURCHASE_SUMMARY_WITH_FEE_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "invoice_amount_total", header: "Total Invoice Amount" },
    { field: "advance_amount_total", header: "Total Advance Amount" },
    { field: "fee_total", header: "Total Fee" },
    { field: "wire_fee", header: "Fee Deducted" },
    { field: "funded_amount", header: "Proceeds Owed/Paid to Client" },
];
export const PURCHASE_SUMMARY_WITHOUT_FEE_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "posted_date", header: "Posted Date" },
    { field: "invoice_amount_total", header: "Total Invoice Amount" },
    { field: "advance_amount_total", header: "Total Advance Amount" },
];

export const INVOICE_SUMMARY_AGING_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "invoice_due_date", header: "Due Date" },
    { field: "purchase_date", header: "Purchase Date" },
    { field: "schedule_no", header: "Schedule No" },
    { field: "aging", header: "Aging" },
    { field: "payment_date", header: "Payment Date" },
    { field: "invoice_amount", header: "Invoice Amount" },
    { field: "paid_amount", header: "Paid Amount" },
    { field: "cb_uc_amount", header: "CB/UC Amount" },
    { field: "balance", header: "Balance" },
];

export const PAYMENT_HISTORY_DETAIL_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "buy_no", header: "Buy No" },
    { field: "payment_date", header: "Payment Date" },
    { field: "collection_date", header: "Collection Date" },
    { field: "aging", header: "Aging" },
    { field: "payment_date", header: "Payment Date" },
    { field: "rebate_amount", header: "Rebate Amount" },
    { field: "fee_amount", header: "Fee Amount" },
];

export const RESERVE_DETAIL_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "check_no", header: "Check No" },
    { field: "buy_date", header: "Buy Date" },
    { field: "fee_days", header: "Fee Days" },
    { field: "payment", header: "Payment" },
    { field: "applied_to_advance", header: "Applied to Advance" },
    { field: "applied_to_fee", header: "Applied to Fee" },
    { field: "reserve_amount", header: "Reserve Amount" },
    { field: "reserve_balance", header: "Reserve Balance" },
];

export const ESCROW_RESERVE_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "payment_date", header: "Payment Date" },
    { field: "aging", header: "Aging" },
    { field: "collection", header: "Collection" },
    { field: "escrow_reserve", header: "Escrow Reserve" },
    { field: "cash_reserve_amount", header: "Cash Reserve Amount" },
    { field: "escrow_balance", header: "Escrow Balance" },
    { field: "advance_amount", header: "Advance Amount" },
];

export const RESERVE_CHARGEBACK_GRID_HEADERS = [
    { field: "invoice_no", header: "Invoice No" },
    // { field: "schedule.schedule_no", header: "Schedule No" },
    { field: "prospect.company_business_name", header: "Client Name" },
    { field: "debtor.legal_name", header: "Debtor Name" },
    { field: "actual_cb", header: "Actual CB" },
    { field: "cb", header: "Pending CB" },
    { field: "adjusted_cb", header: "CB to be Adjusted" },
    { field: "invoice_amount", header: "Invoice Amount" },
];

export const CHARGEBACKS_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor", header: "Debtor Name" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "invoice_due_date", header: "Invoice Due Date" },
    { field: "age", header: "Invoice Aging" },
    { field: "payment_received", header: "Payment Received" },
    { field: "remaining_balance", header: "Remaining Balance" },
];

export const BROKER_COMMISION_REPORT_GRID_HEADERS = [
    { field: "broker_name", header: "Broker Name" },
    { field: "client_name", header: "Client Name" },
    { field: "id", header: "Batch No" },
    { field: "purchases", header: "Purchases" },
    { field: "collection", header: "Collection" },
    { field: "fee_earned", header: "Fee Earned" },
    { field: "rate_of_commission", header: "Rate of Commission" },
];

export const CREDIT_LIMIT_WARNING_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "debtor_credit_limit", header: "Debtor Credit Limit" },
    { field: "funded_balance", header: "Funded Balance" },
    { field: "balance_to_limit_percentage", header: "Balance To Limit Percentage" },
];

export const LAST_PAYMENT_DATE_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "ar_balance", header: "A/R Balance" },
    { field: "last_payment_date", header: "Last Payment Date" },
    { field: "no_of_days_since_last_payment", header: "No of Days Since Last Payment" },
];

export const CLIENT_RISK_MANAGEMENT_DETAIL_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "highest_debtor", header: "Highest Debtor" },
    { field: "risk_concentration", header: "Risk Concentration" },
    { field: "ar_turnover", header: "A/R Turnover" },
    { field: "debtor_balance", header: "Debtor Balance" },
    { field: "dilution_percentage", header: "Dilution Percentage" },
];

export const CLIENT_ANALYSIS_REPORT_GRID_HEADERS = [
    { field: "month_year", header: "Month-Year" },
    { field: "sales", header: "Sales" },
    { field: "yield", header: "Yield" },
    { field: "average_fund", header: "Average Fund" },
    { field: "earnings", header: "Earnings" },
];

export const UNAPPLIED_CASH_RECEIVED_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "return_date", header: "Return Date & Time" },
    { field: "amount_uc", header: "Amount (UC)" },
    { field: "returned_uc", header: "Returned Amount" },
    { field: "fee_applicable", header: "Fee (If Applicable)" },
];

export const COLLECTION_EFFICIENCY_ANALYSIS_REPORT_GRID_HEADERS = [
    { field: "client_name", header: "Client Name" },
    { field: "efficiency", header: "Total Collection Average Percentage" },
    { field: "sales", header: "Total Sales" },
];

export const CLIENT_DETAILS_HEADER_ITEMS = [
    { title: "Profile", required_rights: [MANAGMENT_RIGHTS.clients.profile], icon: <BiUserCircle className="header_icon" /> },
    { title: "Documents", required_rights: [MANAGMENT_RIGHTS.clients.documents], icon: <CiSearch className="header_icon" /> },
    { title: "Notice of Assignment", required_rights: [MANAGMENT_RIGHTS.clients.notice_of_assignment], icon: <CgFileDocument className="header_icon" /> },
    { title: "Users", required_rights: [MANAGMENT_RIGHTS.clients.users], icon: <BsPeople className="header_icon" /> },
];

export const CLIENT_USERS_KEBAB_MENU_ITEMS = [
    { title: "Change Status", status: 1, required_rights: [MANAGMENT_RIGHTS.clients.change_status] },
    { title: "Resend Invite", status: 2, required_rights: [MANAGMENT_RIGHTS.clients.resend_invite] },
];

export const PROSPECT_DETAILS_HEADER_ITEMS = [
    { title: "Profile", required_rights: [MANAGMENT_RIGHTS.prospects.profile], isEdit: false, icon: <BiUserCircle className="header_icon" /> },
    { title: "Business Name Search", required_rights: [MANAGMENT_RIGHTS.prospects.business_verification], isEdit: true, icon: <CiSearch className="header_icon" /> },
    { title: "Term Sheet", required_rights: [MANAGMENT_RIGHTS.prospects.termsheet], isEdit: true, icon: <CgFileDocument className="header_icon" /> },
];

export const BROKER_KEBAB_MENU_ITEMS = [
    { title: "Change Status", status: 1, required_rights: [MANAGMENT_RIGHTS.brokers.status_change], isDisabled: false },
    { title: "Send Agreement", status: 2, required_rights: [MANAGMENT_RIGHTS.brokers.send_agreement], isDisabled: false },
];

export const CREDIT_REQUEST_KEBAB_MENU = [
    // { title: "Import", status: 1, client_required_rights: [], debtor_required_rights: [] },
    { title: "Approve", status: 2, client_required_rights: [CREDIT_REQUEST_RIGHTS.client.approve_request], debtor_required_rights: [CREDIT_REQUEST_RIGHTS.debtor.approve_request] },
    { title: "Deny", status: 3, client_required_rights: [CREDIT_REQUEST_RIGHTS.client.deny_request], debtor_required_rights: [CREDIT_REQUEST_RIGHTS.debtor.deny_request] },
    // { title: 'Reverse', status: 4}
];

export const CASH_POSTING_REPORT = [
    { field: "company_business_name", header: "Client Name" },
    { field: "check_posted", header: "Checks Posted" },
    { field: "invoices_paid", header: "Invoices Paid" },
    { field: "amount", header: "Amount Posted" },
];

export const DEBTOR_AGING_REPORT = [
    { field: "client_name", header: "Client Name" },
    { field: "legal_name", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "invoice_date", header: "Invoice Date" },
    { field: "invoice_due_date", header: "Due Date" },
    { field: "age", header: "Age" },
    { field: "invoice_amount", header: "Invoice Amount" },
];

export const TRANSACTIONS = [
    { field: "client_name", header: "Client Name" },
    { field: "debtor_name", header: "Debtor Name" },
    { field: "invoice_no", header: "Invoice No" },
    { field: "description", header: "Description" },
    { field: "posting_date", header: "Posting Date" },
    { field: "transaction_no", header: "Transaction No" },
    { field: "debit_amount", header: "Debit Amount" },
    { field: "credit_amount", header: "Credit Amount" },
];

// Reports
export const REPORT_EXPORT_DROPDOWN_ITEMS = [
    { id: 1, label: "PDF" },
    { id: 2, label: "Excel" },
];

export const REPORTS = {
    category: ["Invoices", "Clients"],
    data: {
        Invoices: [
            {
                title: "Purchase Summary With Fee",
                handle: "purchase_summary_with_fee",
                icon: <BsPieChartFill />,
                required_rights: [...Object.values(REPORTS_RIGHTS.purchase_summary_with_fee)],
            },
            {
                title: "Purchase Summary Without Fee",
                handle: "purchase_summary_without_fee",
                icon: <BsBarChartFill />,
                required_rights: [...Object.values(REPORTS_RIGHTS.purchase_without_fee)],
            },
            {
                title: "Invoice Summary Aging",
                handle: "invoice_summary_aging",
                icon: <BsFileEarmarkTextFill />,
                required_rights: [...Object.values(REPORTS_RIGHTS.invoices_summary_aging)],
            },
            {
                title: "Payment History Detail",
                handle: "payment_history_detail",
                icon: <BsArrowRepeat />,
                required_rights: [...Object.values(REPORTS_RIGHTS.payment_history_detail)],
            },
            {
                title: "Reserve Detail",
                handle: "reserve_detail",
                icon: <BsCardChecklist />,
                required_rights: [...Object.values(REPORTS_RIGHTS.reserve_details)],
            },
            {
                title: "Escrow Reserve",
                handle: "escrow_reserve",
                icon: <BsGraphUpArrow />,
                required_rights: [...Object.values(REPORTS_RIGHTS.escrow_reserve)],
            },
            {
                title: "Chargebacks",
                handle: "chargebacks",
                icon: <BsCurrencyDollar />,
                required_rights: [...Object.values(REPORTS_RIGHTS.charge_backs)],
            },
            {
                title: "Broker Commission Report",
                handle: "broker_commission_report",
                icon: <BsCurrencyExchange />,
                required_rights: [...Object.values(REPORTS_RIGHTS.broker_commission)],
            },
        ],
        Clients: [
            {
                title: "Credit Limit Warning",
                handle: "credit_limit_warning",
                icon: <BsExclamationTriangleFill />,
                required_rights: [...Object.values(REPORTS_RIGHTS.credit_limit_warning)],
            },
            {
                title: "Last Payment Date",
                handle: "last_payment_date",
                icon: <BsCalendarCheck />,
                required_rights: [...Object.values(REPORTS_RIGHTS.last_payment_date)],
            },
             {
                title: "Client Analysis",
                handle: "client_analysis",
                icon: <BsGraphUp />,
                required_rights: [...Object.values(REPORTS_RIGHTS.client_analysis)],
            },
            {
                title: "Client Risk Management Details",
                handle: "client_risk_management_detail",
                icon: <BsPeople />,
                required_rights: [...Object.values(REPORTS_RIGHTS.client_risk_management)],
            },
            {
                title: "Unapplied Cash Received",
                handle: "unapplied_cash_received",
                icon: <BsCashStack />,
                required_rights: [...Object.values(REPORTS_RIGHTS.unapplied_cash_received)],
            },
            {
                title: "Collection Efficiency Analysis",
                handle: "collection_efficiency_analysis",
                icon: <BsGraphUp />,
                required_rights: [...Object.values(REPORTS_RIGHTS.collection_efficiency_analysis)],
            },
            {
                title: "Cash Posting",
                handle: "cash_posting_report",
                icon: <BsCurrencyDollar />,
                required_rights: [...Object.values(REPORTS_RIGHTS.cash_posting_report)],
            },
            {
                title: "Debtor Aging",
                handle: "debtor_aging",
                icon: <BsGraphUp />,
                required_rights: [...Object.values(REPORTS_RIGHTS.debtor_aging_report)],
            },
            {
                title: "Transactions",
                handle: "transactions",
                icon: <GrTransaction />,
                required_rights: [...Object.values(REPORTS_RIGHTS.transactions)],
            },
        ],
    },
};

export const COLLECTION_EFFICIENY_ANALYSIS_DATE_FILTER = [
    { name: "Last Month", value: "lastmonth" },
    { name: "Last 6 Months", value: "6months" },
    { name: "Last 12 Months", value: "12months" },
];

// User Management
export const USERMANAGMENT_KEBAB_MENU = [{ title: "Change Status", status: 1, required_rights: [USERMANAGMENT_RIGHTS.user_profile_statuschange] }];

export const USERMANAGMENT_ROLES_KEBAB_MENU = [
    { title: "Delete", status: 1, required_rights: [USERMANAGMENT_RIGHTS.role_rights_deleterole] },
    { title: "Change Status", status: 2, required_rights: [USERMANAGMENT_RIGHTS.role_rights_statuschange] },
];

// SUPPORT
export const SUPPORT_KEBAB_MENU = [
    {
        title: "Change Status",
        status: 1,
        required_rights: [SUPPORT_RIGHTS.change_status],
    },
    {
        title: "Assign Ticket",
        status: 2,
        required_rights: [SUPPORT_RIGHTS.assign_ticket],
    },
];

// Transaction
// Invoices
export const INVOICES_KEBAB_MENU = [
    {
        title: "Move To Schedule",
        status: 1,
        required_rights: [TRANSACTION_RIGHTS.invoices.move_to_schedule],
    },
    { title: "Delete Invoice", status: 2, required_rights: [TRANSACTION_RIGHTS.invoices.delete_invoice] },
];
// Schedule of Assignment
export const SCHEDULE_OF_ASSIGNMENT_MENU = [
    { title: "Verify Schedule", status: 1, required_rights: [TRANSACTION_RIGHTS.schedule_of_assignment.verify_schedule] },
    // { title: "Download Schedule", status: 2, required_rights: [TRANSACTION_RIGHTS.schedule_of_assignment.download_schedule] },
    { title: "Delete Schedule", status: 3, required_rights: [TRANSACTION_RIGHTS.schedule_of_assignment.deleted_schedule] },
];

// Charge Backs
export const CHARGE_BACKE_MENU = [{ title: "Chargeback Adjustment", status: 1, required_rights: [TRANSACTION_RIGHTS.charge_backs.charge_back_adjustment] }];

// Settings
// Announcemnets
export const ANNOUNCEMENT_ITEMS = [{ title: "Delete", status: 1, required_rights: [SETTINGS_RIGHTS.announcments.delete_announcement] }];

export const SETTINGS = {
    data: [
        {
            title: "Field Settings",
            handle: "Field Settings",
            icon: <BsToggleOff />,
            route: "",
            required_rights: [],
        },
        {
            title: "Theme Settings",
            handle: "Theme Settings",
            icon: <BsCalendar4 />,
            route: "",
            required_rights: [],
        },
        {
            title: "Flag Alerts",
            handle: "Flag Alerts",
            icon: <BsFlag />,
            route: "",
            required_rights: [],
        },
        {
            title: "Flag Alerts",
            handle: "Flag Alerts",
            icon: <BsFlag />,
            route: "",
            required_rights: [],
        },
        {
            title: "Exception Tracking",
            handle: "Exception Tracking",
            icon: <BsPieChart />,
            route: "",
            required_rights: [],
        },
        {
            title: "Currency Settings",
            handle: "Currency Settings",
            icon: <BsCurrencyDollar />,
            route: "",
            required_rights: [],
        },
        {
            title: "Debtor Scoring",
            handle: "Debtor Scoring",
            icon: <BsPeopleFill />,
            route: "",
            required_rights: [],
        },
        {
            title: "Policies",
            handle: "Policies",
            icon: <BsFillBarChartFill />,
            route: "/policies",
            required_rights: [...Object.values(SETTINGS_RIGHTS.policies)],
        },
        {
            title: "Announcements",
            handle: "Announcements",
            icon: <MdOutlineCampaign />,
            route: "/annoucements",
            required_rights: [...Object.values(SETTINGS_RIGHTS.announcments)],
        },
    ],
};

export const ROLE_NAME = {
    DEBTOR: "Debtor",
    CLIENT: "Client",
    BROKER: "Broker",
    CA: "Client Advocate",
    ADMIN: "Admin",
    ADMINNEW: "AdminNew",
};

export const FILE_MAX_SIZE = {
    FILE_SIZE: 3000000,
};
