import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
//style
import "./notificationmenucomponent.scss";
//components
import { Menu } from "primereact/menu";
import NotificationIcon from "../../svgs/Notification/NotificationIcon";
import { BsCalendar4Event } from "react-icons/bs";
import { getNotifications } from "../../services/notifications";
import { mainViewState, setFetchNewNotification } from "../../redux/main-view";
import { dateTimeFormatForInputFields } from "../../utils/HelperFuctions/dateFormat";
import { useDispatch, useSelector } from "react-redux";

const NotificationMenuComponent = () => {
    const menu = useRef(null);
    const history = useHistory();
    const { screens } = useSelector(mainViewState);

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotificationsLength, setUnreadNotificationsLength] = useState([]);
    const dispatch = useDispatch();

    const getNotification = async () => {
        setLoading(true);
        let payload = {
            page: 1,
            per_page: 5,
        };
        let res = await getNotifications(payload);
        if (res.data.notifications.data) {
            setNotifications(res.data.notifications.data);
            setUnreadNotificationsLength(res.data.unread_count);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        getNotification();
    }, []);

    useEffect(() => {
        if (screens.notification.isNewNotification) {
            getNotification();
            dispatch(setFetchNewNotification(false));
        }
        setTimeout(() => {
            setLoading(true);
            setLoading(false);
        }, 0);
    }, [screens.notification.isNewNotification]);

    const renderMenuItem = (item, index) => {
        const isLastItem = index === notifications.length - 1;
        const menuClass = isLastItem ? "noti_border_bottom_remove" : " noti_border_bottom";
        return (
            <>
                {index == 0 && (
                    <div className="flex flex-row justify-content-between notification_header">
                        <p className="notification-text m-0">Notifications</p>
                        {/*<p className="notification_mark_text m-0">Mark all as read</p>*/}
                    </div>
                )}
                <div className={item.read === false ? "pt-3 mb-2 pb-3 pl-3 pr-3 menu-border-left unread_notification" : "pt-3 mb-2 pb-3 pl-3 pr-3  menu-border-left noti_border_bottom"}>
                    <span className="flex flex-row justify-content-between">
                        <p className="notification-title">{item.type}</p>
                        <p className="notification-datetime">
                            <BsCalendar4Event className="mt-1" /> {dateTimeFormatForInputFields(item.created_at)}
                        </p>
                    </span>
                    <p>{item.content}</p>
                </div>
                {index == notifications.length - 1 && (
                    <div className="pt-2 pb-4 text-center cursor-pointer" onClick={handleNavigate}>
                        <p className="notification-title m-0">
                            <b>View All Notifications</b>
                        </p>
                    </div>
                )}
            </>
        );
    };

    const menuItems = notifications?.map((item, index) => {
        return {
            template: () => renderMenuItem(item, index),
        };
    });
    const handleNavigate = () => {
        menu.current.hide();
        history.push("/notifications");
    };
    return (
        <div className="notification_popup">
            <Menu model={menuItems} popup ref={menu} id="popup_menu" className="notification-menu custom_scrollbar" />
            <div
                className="background_notification_icon"
                onClick={(event) => {
                    notifications.length > 0 && menu.current.toggle(event);
                }}
            >
                <NotificationIcon />
                <span className={`notifications-count ${unreadNotificationsLength > 99 ? "w-35px" : "w-30px"}`}>{unreadNotificationsLength > 99 ? `99+` : unreadNotificationsLength}</span>
            </div>
        </div>
    );
};

export default NotificationMenuComponent;
