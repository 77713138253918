import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// UI Components
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import { PaginatorTemplate } from "../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { ProspectColumnBody } from "../../../../utils/HelperFuctions/prospectHelper";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import TerminateModal from "../Components/TerminateModal";
import TerminateModalDetail from "../Components/TerminateModalDetail";
import { validateSomeUserPermissions, validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { loginState } from "../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../utils/Constants/rights";
import { CLIENT_MENU_ITEMS, ROLE_NAME } from "../../../../utils/Constants/global";
import { saveStorageParam } from "../../../../utils/Constants/param";

const Body = (props) => {
    const {
        selectedUsers,
        setSelectedUsers,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        response,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        page,
        setPage,
        loading,
        kebabMenuOption,
        setKebabMenuOption,
        showAll,
    } = props;
    //Use Ref
    const dt = useRef(null);
    // Redux
    const { user } = useSelector(loginState);
    //Dispatch
    const history = useHistory();
    // State
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [date, setDate] = useState("");
    const [isDate, setIsDate] = useState(false);
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };
    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };
    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={ProspectColumnBody(col.field)}
                headerClassName={`${col.field === "active_credit_limit" || col.field === "factoring_limit" ? "amount_column_alignment" : ""}`}
                sortable
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedUsers(event.value);
            if (event.value.length > 1) {
                CLIENT_MENU_ITEMS[0].isDisabled = true;
            } else if (event.value.length === 1) {
                CLIENT_MENU_ITEMS[0].isDisabled = false;
            }
            if (event?.value[0]?.terminated_at) {
                CLIENT_MENU_ITEMS[0].isDisabled = true;
            }
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions([MANAGMENT_RIGHTS.clients.selection], user.rights ?? [])) return;
        if (!validateSomeUserPermissions([MANAGMENT_RIGHTS.clients.profile, MANAGMENT_RIGHTS.clients.documents, MANAGMENT_RIGHTS.clients.notice_of_assignment], user.rights ?? [])) return;
        if (event.type === "row") {
            history.push(`/clientdetails`);
            saveStorageParam("clientdetails", event.data.id);
            // history.push({pathname: `/clientdetails`, state: { id: `${event.data.id}` }});
        }
    };
    const getRowClassName = (rowData) => {
        if (rowData.deleted) {
            return "delete_row_color";
        } else if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };
    // Paginations
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };
    return (
        <div className="col-12 p-0">
            {loading ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <DataTable
                        ref={dt}
                        value={response}
                        selection={selectedUsers.length === 0 ? null : selectedUsers}
                        onSelectionChange={handleSelectionChange}
                        onSort={onSort}
                        sortable
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onPage={onCustomPage}
                        paginatorTemplate={PaginatorTemplate(
                            pagination.isFirstPage,
                            pagination.isLastPage,
                            currentPage,
                            pagination.totalPages,
                            pageSize,
                            page,
                            pagination.totalRecords,
                            pageInputTooltip,
                            setCurrentPage,
                            setPage,
                            setPageSize,
                            setPageInputTooltip
                        )}
                        rows={pageSize}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="No record available."
                        selectionMode="single" // Add selectionMode prop
                        onRowSelect={handleRowSelect} // Add onRowSelect event handler
                        rowClassName={getRowClassName}
                    >
                        {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT ? !showAll && <Column selectionMode="multiple"></Column> : null}

                        {columnComponents}
                    </DataTable>
                    {/* //Terminate CLient Modal */}

                    <Dialog
                        blockScroll={true}
                        visible={kebabMenuOption.terminate_to_client}
                        onHide={() => {
                            setKebabMenuOption({ import_client: false, export_client: false, terminate_to_client: false, delete_client: false });
                            setSelectedUsers([]);
                        }}
                        header="Terminate Client"
                        breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                        style={{ width: "35vw", textAlign: "center !important" }}
                    >
                        {kebabMenuOption.terminate_to_client && (
                            <TerminateModal
                                onHide={() => {
                                    setKebabMenuOption({ import_client: false, export_client: false, terminate_to_client: false, delete_client: false });
                                    setSelectedUsers([]);
                                }}
                                setIsDate={setIsDate}
                                setDate={setDate}
                                selectedUsers={selectedUsers}
                            />
                        )}
                    </Dialog>
                    <Dialog
                        blockScroll={true}
                        visible={isDate}
                        onHide={() => {
                            setIsDate(false);
                            setSelectedUsers([]);
                        }}
                        header="Terminate Client"
                        breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                        style={{ width: "50vw", textAlign: "center !important" }}
                    >
                        {isDate && (
                            <TerminateModalDetail
                                date={date}
                                onHide={() => {
                                    setIsDate(false);
                                    setSelectedUsers([]);
                                }}
                            />
                        )}
                    </Dialog>
                </div>
            )}
        </div>
    );
};

export default Body;
