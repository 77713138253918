import React from "react";

// Icons
import { BsCheck, BsCheckAll, BsX } from "react-icons/bs";

// Contants
import { UNAPPLIED_CASH_GRID_HEADERS, UNAPPLIED_CASH_RELEASED, UNAPPLIED_CASH_WITHOUT_GRID_HEADERS } from "../../../../../../../utils/Constants/global";

const Header = (props) => {
    const { setSelectedInvoices, setActiveCard, activeCard, setSelectedColumns, setHeader, setComponentName, setCurrentPage, setPage, setShowAll, setKeyword } = props;

    const ViewChangeHandler = (value) => {
        setActiveCard(value);
        setCurrentPage(1);
        setPage(1);
        setShowAll(false);

        if (value === 0) {
            setSelectedColumns(UNAPPLIED_CASH_GRID_HEADERS);
            setComponentName("unapplied_cash_with_invoices");
            setHeader(UNAPPLIED_CASH_GRID_HEADERS);
        } else if (value === 1) {
            setSelectedColumns(UNAPPLIED_CASH_WITHOUT_GRID_HEADERS);
            setComponentName("unapplied_cash_without_invoices");
            setHeader(UNAPPLIED_CASH_WITHOUT_GRID_HEADERS);
        } else {
            setSelectedColumns(UNAPPLIED_CASH_RELEASED);
            setComponentName("unapplied_cash_released");

            setHeader(UNAPPLIED_CASH_RELEASED);
        }
        setKeyword("");
        setSelectedInvoices([]);
    };

    return (
        <div className="pros_main">
            <div className="grid">
                <div className="col-7 md:col-10">
                    <h4 className="m-0">
                        <b>Unapplied Cash</b>
                    </h4>
                </div>
            </div>
            <div className="grid">
                <div className="md:col-4 col-12">
                    <div className={`card text-center ${activeCard === 0 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(0)}>
                        <div className="unapplied_card_alignment">
                            <div className="icon_bg">
                                <BsCheckAll className="circle_icon_class" />
                            </div>
                            <h6>
                                <b>Unapplied Cash with Invoices</b>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className={`card text-center ${activeCard === 1 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(1)}>
                        <div className="unapplied_card_alignment">
                            <div className="icon_bg">
                                <BsCheck className="circle_icon_class" />
                            </div>
                            <h6>
                                <b>Unapplied Cash without Invoices</b>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className={`card text-center ${activeCard === 2 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(2)}>
                        <div className="unapplied_card_alignment">
                            <div className="icon_bg">
                                <BsX className="circle_icon_class" />
                            </div>
                            <h6>
                                <b>Unapplied Cash Released</b>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
