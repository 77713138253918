import React, { useContext, useEffect, useState } from "react";
import Header from './GridView/Header';
import { useDispatch, useSelector } from "react-redux";
import { mainViewState } from "../../../../../../redux/main-view";
import { ToastContext } from "../../../../../../context/toast";
import { CASH_RESERVE_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import FilterBar from "./GridView/FilterBar";
import Body from "./GridView/Body";
import { getCashReserves, getReserveReleases } from "../../../../../../services/reserve";

const Reserves = (props) => {
    // Context
    const toast = useContext(ToastContext);

    // State
    const [responseData, setResponseData] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [selectedReserves, setSelectedReserves] = useState([]);
    const [header, setHeader] = useState(CASH_RESERVE_GRID_HEADERS);
    const [selectedColumns, setSelectedColumns] = useState(CASH_RESERVE_GRID_HEADERS);
    const [componentName, setComponentName] = useState("cash_reserves");

    const [showAll, setShowAll] = useState(false);
    const [activeCard, setActiveCard] = useState(0);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);
    

    const fetchCashReserves = async () => {
        let payload = {
            per_page: pageSize,
            page: currentPage,
            sort_by: sortField,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
            search_text: keyword
        };

        if(showAll) payload['show_all']=1

        if (activeCard == 0) {
            payload['reserved_amount >'] = 0
        } else if (activeCard == 1) {
            payload['available_escrow_reserves >'] = 0
        }

        setLoading(true);
        try {
            const res = await getCashReserves(payload);
            if (res.data.status) {
                setResponseData(res.data.reserves.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.reserves.total,
                    totalPages: res.data.reserves.last_page,
                    isLastPage: res.data.reserves.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    const fetchReserveReleases = async () => {
        let payload = {
            per_page: pageSize,
            page: currentPage,
            sort_by: sortField,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
            search_text: keyword
        };

        if(showAll) payload['show_all']=1
        
        setLoading(true);
        try {
            const res = await getReserveReleases(payload);
            if (res.data.status) {
                setResponseData(res.data.reserve_releases.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.reserve_releases.total,
                    totalPages: res.data.reserve_releases.last_page,
                    isLastPage: res.data.reserve_releases.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        
        if (keyword) {
            const delayDebounceFn = setTimeout(() => {
                activeCard == 2 ? fetchReserveReleases() : fetchCashReserves();
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        } else {
            activeCard == 2 ? fetchReserveReleases() : fetchCashReserves();
        }
    }, [showAll, sortField, sortOrder, pageSize, currentPage, activeCard]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    activeCard == 2 ? fetchReserveReleases() : fetchCashReserves();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    return (
        <>
            <Header
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                setSelectedColumns={setSelectedColumns}
                setHeader={setHeader}
                setComponentName={setComponentName}
                setCurrentPage={setCurrentPage}
                setPage={setPage}
                setShowAll={setShowAll}
                fetchCashReserves={fetchCashReserves}
                fetchReserveReleases={fetchReserveReleases}
                setSelectedReserves={setSelectedReserves}
                loading={loading}
            />
            <FilterBar
                showAll={showAll}
                setShowAll={setShowAll}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                setKeyword={setKeyword}
                keyword={keyword}
                header={header}
                selectedReserves={selectedReserves}
                componentName={componentName}
            />
            <Body
                selectedReserves={selectedReserves}
                setSelectedReserves={setSelectedReserves}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                responseData={responseData}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                page={page}
                setPage={setPage}
                loading={loading}
                componentName={componentName}
            />

            
        </>
    );
};

export default Reserves;
