import { useHistory } from "react-router-dom";
import React, { useState } from "react";

// Prime React
import { Knob } from "primereact/knob";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
    mainViewState,
    onFormDirty,
    onProspectAttachment,
    onProspectBusinessProprietorFormDirty,
    onProspectCountryCodes,
    onProspectDataChange,
    onProspectFormDirty,
    onProspectIndexChange,
    onProspectIsEditing,
    onProspectKnobValue,
    onProspectSupplierFormDirty,
} from "../../redux/main-view";

// Icons
import { BsCheckLg } from "react-icons/bs";

// UI Components
import BusinessInformation from "./components/BusinessInformation";
import BusinessProprietors from "./components/BusinessProprietors";
import { DocumentChecklist } from "./components/DocumentChecklist";
import { LandlordInformation } from "./components/LandlordInformation";
import { SupplierInformation } from "./components/SupplierInformation";
import { FinancialInformation } from "./components/FinalcialInformation";
import CustomConfirmDialog from "../CustomConfirmDialog/CustomConfirmDialog";

// API'S
import { unSavedProspectSendEmail } from "../../services/prospect";

// Styles
import "../../App.scss";
import "../../ui/globalcomponent.scss";

const ProspectForm = (props) => {
    const { frontline_color = false } = props;

    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();
    const history = useHistory();

    // State
    const [header, setHeader] = useState("Business Information");
    const [isOpeneing, setIsOpeneing] = useState(false);

    const handleSendNextStep = (step) => {
        showStepForm(step);
    };
    let [content, setContent] = useState(<BusinessInformation sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
    const showStepForm = (step) => {
        switch (step) {
            case 1:
                setHeader("Business Information");
                setContent(<BusinessInformation sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
                break;
            case 2:
                setHeader("Business Owners");
                setContent(<BusinessProprietors sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
                break;
            case 3:
                setHeader("Financial Information");
                setContent(<FinancialInformation sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
                break;
            case 4:
                setHeader("Supplier Information");
                setContent(<SupplierInformation sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
                break;
            case 5:
                setHeader("Landlord Information");
                setContent(<LandlordInformation sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
                break;
            case 6:
                setHeader("Documents Checklist");
                setContent(<DocumentChecklist sendNextStep={handleSendNextStep} setIsOpeneing={setIsOpeneing} frontline_color={frontline_color} />);
                break;
            default:
                content = <div>No step Form Further</div>;
        }
    };

    const confirmHandler = () => {
        dispatch(onProspectIndexChange(0));
        dispatch(onProspectKnobValue(0));
        dispatch(onProspectDataChange(null));
        dispatch(onProspectAttachment(null));
        dispatch(onProspectIsEditing(false));
        dispatch(onProspectFormDirty(false));
        dispatch(onProspectBusinessProprietorFormDirty(false));
        dispatch(onProspectSupplierFormDirty(false));
        dispatch(onProspectCountryCodes(null));
        dispatch(onFormDirty(false));
        history.push("/prospectindex");
        if (screens.prospect.data) {
            if (!screens.prospect.isEditing) {
                let payload = {
                    id: screens.prospect.data.id,
                };
                unSavedProspectSendEmail(payload);
            }
        }
    };

    return (
        <>
            <div className="card">
                <div className="prospect-form-steps">
                    <div className="knob_align_custom">
                        <Knob
                            frontline_color={frontline_color}
                            value={screens.prospect.percentValue}
                            size={100}
                            valueTemplate={screens.prospect.percentValue + "%"}
                            valueColor={frontline_color ? "#8dc63f" : "#3165cb"}
                        />
                        <h4 className="header">
                            <b>{header}</b>
                        </h4>
                    </div>
                    {content}
                </div>
            </div>
            {isOpeneing && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpeneing(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                    // secondtext="This action cannot be reverted."
                />
            )}
        </>
    );
};

export default ProspectForm;
