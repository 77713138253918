export function PercentageCheck(value) {
    if ((value.match(/\./g) || []).length > 1) {
        value = value.replace(/\./g, "");
    }
    if (value === "" || parseFloat(value) <= 100) {
        return value;
    } else {
        const update = value.slice(0, -1);
        value = update;
        return value;
    }
}

export function minimumPercentageCheck(value, min = 0) {
    if (value === "" || parseFloat(value) > parseFloat(min)) {
        return true;
    } else {
        return false;
    }
}
