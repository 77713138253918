import React, { useEffect, useState } from "react";
//Components
import { Avatar } from "primereact/avatar";
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
import moment from "moment/moment";
import appUrl from "../../../utils/Constants/appUrl";
import DefaultUserImage from "../../../Images/user.png";

const ChatSidebar = (props) => {
    const { chatlist, loading, setSelectedChat } = props;
    // Hover state
    const [hoveredItem, setHoveredItem] = useState(null);
    const [selectedItem, setSelectedItem] = useState(0);

    const handleItemHover = (itemId) => {
        setHoveredItem(itemId);
    };
    const handleRowClick = (item) => {
        setSelectedChat(item);
        setSelectedItem(item.id);
    };
    useEffect(() => {
        setSelectedChat([]);
        setSelectedItem(null);
    }, [loading]);

    return (
        <>
            <div className="chat_menu_scroll">
                {loading ? (
                    <CustomLoading top={"50px"} />
                ) : (
                    chatlist.map((item) => (
                        <div
                            className={`add_hover ${hoveredItem === item.id || selectedItem === item.id ? "active" : "inactive"}`}
                            key={item.id}
                            onMouseEnter={() => handleItemHover(item.id)}
                            onMouseLeave={() => handleItemHover(null)}
                            onClick={() => handleRowClick(item)}
                        >
                            <div className="grid mt-2">
                                <div className="md:col-2 col-12">
                                    <Avatar image={item.profile_pic ? appUrl.imageBaseUrl + item.profile_pic : DefaultUserImage} className="p-mr-2" size="xlarge" shape="circle" />
                                </div>
                                <div className="md:col-10 col-12">
                                    <div className="flex flex-row justify-content-between">
                                        <h6 className="mb-1">
                                            <b>{item.name}</b>
                                        </h6>
                                        <span className="date_color_style">{moment(item.created_at).format("MMMM DD, YYYY, hh:mm A")}</span>
                                    </div>
                                    <div>
                                        <div className="text_limit_class">
                                            <p>{item.message}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export default ChatSidebar;
