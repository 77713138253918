import React, { useContext, useEffect, useState } from "react";

// Constants
import { DOCUMENT_GRID_HEADERS } from "../../../utils/Constants/global";

// Context
import { ToastContext } from "../../../context/toast";

// Component
import Body from "./GridView/Body";
import FilterBar from "./GridView/FilterBar";

// API'S
import { getDocumentsCount } from "../../../services/documents";

const Document = () => {
    // Context
    const toast = useContext(ToastContext);

    // State
    const [keyword, setKeyword] = useState(null);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(DOCUMENT_GRID_HEADERS);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(0);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);

    // Api Handler
    const getDocuments = async () => {
        try {
            let payload = {
                per_page: pageSize,
                search_text: keyword === null ? "" : keyword,
                page: page ? page : currentPage,
            };
            if (sortField) {
                payload["sort_by"] = sortField;
            }
            if (sortOrder) {
                payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
            }
            setLoading(true);
            const res = await getDocumentsCount(payload);
            if (res) {
                if (res?.data?.prospect_attachments) {
                    setSelectedHeaders(res?.data?.prospect_attachments?.data);
                    setPagination({
                        ...pagination,
                        totalRecords: res?.data?.prospect_attachments?.total,
                        totalPages: res?.data?.prospect_attachments?.last_page,
                        isLastPage: res?.data?.prospect_attachments?.last_page,
                    });
                    setLoading(false);
                }
            } else {
                toast.showMessage("Error", "Server error.", "error");
                setLoading(false);
            }
        } catch (error) {
            toast.showMessage("Error", "Server error.", "error");
            setLoading(false);
        }
    };

    // //UseEffect

    useEffect(() => {
        getDocuments();
    }, [sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getDocuments();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);
    return (
        <>
            <FilterBar setKeyword={setKeyword} keyword={keyword} />
            <Body
                selectedHeaders={selectedHeaders}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                setPage={setPage}
                page={page}
            />
        </>
    );
};
export default Document;
