import React, { useEffect, useRef } from "react";

// Prime React
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Document from "../../../../svgs/Document/Document";
import { extractNameFromClientDocumnetChecklist } from "../../../../utils/HelperFuctions/getFileName";
import appUrl from "../../../../utils/Constants/appUrl";

const ViewDocumentChecklist = (props) => {
    const { documents } = props;

    // UseRef
    const dt = useRef(null);

    const openDocument = (attachment) => {
        if (attachment) {
            window.open(appUrl.imageBaseUrl + attachment, "_blank");
        }
    };
    const fileNameHandle = (rowData) => {
        return extractNameFromClientDocumnetChecklist(rowData);
    };
    const fileDownload = (rowData) => {
        return (
            <>
                <div className={` mr-2 mt-1 ${rowData ? "document_icon_background" : "no_doc"}`} onClick={() => openDocument(rowData)}>
                    {rowData ? <Document /> : "No Attachment"}
                </div>
            </>
        );
    };

    return (
        <>
            <div className="grid">
                <div className="col-12 custom_scrollbar_fieldsetting">
                    <DataTable ref={dt} value={documents} responsiveLayout="scroll" emptyMessage="No record available.">
                        <Column body={fileNameHandle} header="File Name"></Column>
                        <Column body={fileDownload} header="View"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ViewDocumentChecklist;
