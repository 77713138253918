import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function getDebtors(data = null) {
    return postData(appUrl["debtor"].fetch, data);
}
export async function getDebtorsForDropdown(data = null) {
    return postData(appUrl["debtor"].fetchDebtorsForDropdown, data);
}
export async function getDebtorPoc(data) {
    return postData(appUrl["debtor"].poc, data);
}

export async function saveDebtor(data) {
    return postData(appUrl["debtor"].save, data);
}

export async function getDebtorPocs(data) {
    return postData(appUrl["debtor"].poc, data);
}

export async function getDebtorById(data) {
    return postData(appUrl["debtor"].fetchById, data);
}

export async function changeDebtorStatus(data) {
    return postData(appUrl["debtor"].status, data);
}

export async function deleteDebtor(data) {
    return postData(appUrl["debtor"].delete, data);
}

export async function getProspectDebtorsContact(data) {
    return postData(appUrl["debtor"].prospectDebtorContact, data);
}

export async function isDebtorExists(data) {
    return postData(appUrl["debtor"].exists, data);
}

export async function inviteDebtor(data) {
    return postData(appUrl["debtor"].invite, data);
}

export async function resendDebtorInvite(data) {
    return postData(appUrl["debtor"].resendInvite, data);
}
