import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../../../../context/toast";
import { getCustomDropDown } from "../../../../../../services/dropdown";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../../../ui/CustomInputField/CustomInputField";

//Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";
import { saveReturnUC } from "../../../../../../services/collection";
import { BsCheckLg } from "react-icons/bs";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { getWireFee } from "../../../../../../services/term-sheet";
import CustomModalSpinner from "../../../../../../ui/CustomModalSpinner/CustomModalSpinner";

const ReturnToDebtorComponent = (props) => {
    const { onHide, selectedInvoices, onCancel } = props;

    const [paymentDropdowns, setPaymentDropdowns] = useState([]);
    const [dropdownsLoading, setDropdownsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [wireFee, setWireFee] = useState(0);

    //toast message
    const toast = useContext(ToastContext);

    let validationSchema = Yup.object().shape({
        uc_released_date: Yup.string().required("Return Date is required").nullable(),
        amount: Yup.string(),
        uc_return_payment_type: Yup.string().required("Payment Type is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            uc_released_date: null,
            amount: selectedInvoices[0]?.amount,
            uc_return_payment_type: "",
        },
        onSubmit: async (data) => {
            const ids = selectedInvoices?.map((invoice) => invoice.id);
            try {
                setIsSaving(true);
                data = {
                    ...data,
                    ids,
                    uc_released_date: getDateFormat(data.uc_released_date),
                };
                let res = await saveReturnUC(data);
                if (res.data.status) {
                    toast.showMessage("Success", "Unapplied cash returned successfully.", "success");
                    onHide();
                }
            } catch (error) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsSaving(false);
        },
    });

    // get Wire Fee
    const fetchWireFee = async (data) => {
        let payload = {
            prospect_id: selectedInvoices[0]?.prospect_id,
        };
        try {
            let res = await getWireFee(payload);
            if (res.data.status) {
                setWireFee(res?.data?.term);
                if (parseInt(formik.values.amount) < parseInt(res?.data?.term)) {
                    if (paymentDropdowns) {
                        const index = data?.findIndex((item) => item.value === 2);
                        data[index]["disabled"] = true;
                        setPaymentDropdowns(data);
                    }
                }
            }
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setIsLoading(false);
    };

    //DropDowns
    const handleDropdowns = async () => {
        setDropdownsLoading(true);
        setIsLoading(true);
        let payload = {
            select: ["payment_type"],
        };
        try {
            const res = await getCustomDropDown(payload);
            if (res.data.status) {
                const payments = JSON.parse(res.data.dropdowns.payment_type);
                setPaymentDropdowns(payments);
                fetchWireFee(payments);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDropdownsLoading(false);
    };

    useEffect(() => {
        if (formik.values.uc_released_date === null) {
            formik.setFieldValue("uc_released_date", null);
        }
        //  else if (formik.values.uc_released_date) {
        //     const selectedDateWithCurrentTime = new Date(formik.values.uc_released_date);
        //     const currentTime = new Date();
        //     formik.setFieldValue("uc_released_date",  selectedDateWithCurrentTime.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds()));
        // }
    }, [formik.values.uc_released_date]);

    const handleCancel = () => {
        if (formik.values.uc_released_date !== null || formik.values.uc_return_payment_type !== "") {
            setIsFormDirty(true);
        } else {
            onCancel();
        }
    };
    useEffect(() => {
        // dropdown call
        handleDropdowns();
    }, []);
    const handleInputChange = async (e) => {
        let { value } = e.target;
        if (value === 2) {
            const amount = parseInt(formik.values.amount) - parseInt(wireFee);
            formik.setFieldValue("amount", amount);
        } else {
            formik.setFieldValue("amount", selectedInvoices[0]?.amount);
        }
        formik.handleChange(e);
    };

    return (
        <>
            {isLoading ? (
                <CustomModalSpinner height="100px" />
            ) : (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid">
                            <CustomInputField
                                className={`col-12 md:col-4 ${dropdownsLoading && "no-drop"}`}
                                type="dropdown"
                                iden="uc_return_payment_type"
                                label="Payment Type"
                                mandatory="*"
                                formik={formik}
                                optionLabel="name"
                                optionValue="value"
                                options={paymentDropdowns}
                                placeHolder="Select"
                                disabled={dropdownsLoading}
                                onChange={(e) => handleInputChange(e)}
                            />
                            <CustomInputField
                                className={`col-12 md:col-4`}
                                iden="uc_released_date"
                                label="Return Date"
                                type="calendar"
                                mandatory="*"
                                formik={formik}
                                placeHolder="mm/dd/yyyy"
                                dateFormat="mm/dd/yy" // Set the desired date format
                                timeFormat="HH:mm"
                                monthNavigator
                                yearNavigator
                                yearRange="1945:2050"
                            />
                            <CustomInputField className="col-12 md:col-4" iden="amount" label="Amount" mandatory="*" formik={formik} placeHolder="$0.00" type="currency" disabled={true} />

                            <div className="col-12 text-right pt-5 ">
                                <CustomCancelbtn title="Cancel" type="button" onClick={handleCancel} />
                                <CustomAddNewButton title="Save" type="submit" isDisabled={isSaving} />
                            </div>
                        </div>
                    </form>
                    {isFormDirty && (
                        <CustomConfirmDialog
                            header="Unsaved Data"
                            type="error"
                            onHide={() => setIsFormDirty(false)}
                            handler={() => onCancel()}
                            icon={<BsCheckLg />}
                            title={"Confirm"}
                            firsttext="Are you sure, you want to close the form without saving the changes?"
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ReturnToDebtorComponent;
