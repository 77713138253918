import React, { useState, useRef, useEffect } from "react";
//Component

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import classNames from "classnames";
import { getEscrowAdjustmentColumnBody } from "../../../../../../utils/HelperFuctions/invoiceHelper";
import { ESCROW_RESERVE_ADJUSTMENT_GRID_HEADERS } from "../../../../../../utils/Constants/global";

const ReserveBreakdown = (props) => {
    const {onHide, escrowData, selectedEscrows, setSelectedEscrows, extraEscrows} = props;
    const dt = useRef(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);
    const [escrowSum, setEscrowSum] = useState('');

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedEscrows(event.value);
        }
    };

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = ESCROW_RESERVE_ADJUSTMENT_GRID_HEADERS.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getEscrowAdjustmentColumnBody(col.field)} sortable>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    const autoSelectClickHandler = (e) => {
        let total = 0;
        let _selected = escrowData.filter((item) => {
            
            if (total + item.available_escrow_reserves <= extraEscrows) {
                total += item.available_escrow_reserves;
                return item;
            }
        });

        setSelectedEscrows(_selected);
    };

    useEffect(() => { 
        let _selectedEscrowSum = 0;
        selectedEscrows.forEach(item => {
            _selectedEscrowSum += item.available_escrow_reserves
        });

        setEscrowSum(_selectedEscrowSum);
    }, [selectedEscrows]);

    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3">
                    <DataTable ref={dt} value={escrowData} 
                        selection={selectedEscrows.length === 0 ? null : selectedEscrows }
                        onSelectionChange={handleSelectionChange}
                        onSort={onSort}
                        sortable
                        sortField={sortField}
                        sortOrder={sortOrder}
                        responsiveLayout="scroll" emptyMessage="No record available.">
                        <Column selectionMode="multiple"></Column>
                        {columnComponents}
                    </DataTable>
                </div>
                <div className={`field col-12 md:col-3 no-drop flex flex-column`}>
                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                        <label htmlFor="name1">
                            <b>Extra Escrow Reserves</b>
                        </label>
                    </div>
                    <div className="default input_position">
                        <InputNumber
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            disabled="true"
                            value={extraEscrows}
                        />
                    </div>
                </div>

                <div className={`field col-12 md:col-3 no-drop flex flex-column`}>
                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                        <label htmlFor="name1">
                            <b>Selected Invoice Escrows</b>
                        </label>
                    </div>
                    <div className="default input_position">
                        <InputNumber
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            disabled="true"
                            value={escrowSum}
                        />
                    </div>
                </div>

                <div className="col-12 text-center mt-3">
                    <CustomCancelbtn id="clear" title="Clear Selection" type="button" onClick={() => setSelectedEscrows([])}/>
                    <CustomAddNewButton id="auto_select" title="Auto Select" type="button" onClick={(e) => autoSelectClickHandler(e)}/>
                </div>
            </div>
        </>
    );
};

export default ReserveBreakdown;
