import appUrl from "../../utils/Constants/appUrl";
import { getData, postData } from "../genaric/genaric-api";

export async function getAllWUnderWriting(data) {
    return postData(appUrl["under-writing"].getAllUnderwritings, data);
}

export async function getClientsDropdown(data) {
    return postData(appUrl["under-writing"].getClientsDropdown, data);
}

export async function addUnderwritingVertical(data) {
    return postData(appUrl["under-writing"].createVertical, data);
}

export async function updateUnderwritingVertical(data) {
    return postData(appUrl["under-writing"].createVertical, data);
}

export async function deleteUnderwritingVertical(data) {
    return postData(appUrl["under-writing"].deleteVertical, data);
}

export async function addUnderwritingTask(data) {
    return postData(appUrl["under-writing"].addTask, data);
}

export async function updateUnderwritingTask(data) {
    return postData(appUrl["under-writing"].updateTask, data);
}

export async function deleteUnderwritingTask(data) {
    return postData(appUrl["under-writing"].deleteTask, data);
}
