import React, { useContext, useEffect, useState } from "react";
//components

import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { postAgreement } from "../../../../services/broker";
import { ToastContext } from "../../../../context/toast";
import { minimumPercentageCheck } from "../../../../utils/HelperFuctions/checkPercentage";
import { getDateFormat } from "../../../../utils/HelperFuctions/dateFormat";

const SendAgreementComponent = (props) => {
    //props
    const { onHide, brokerId, handleShowAgreement, callShowAgreement = false, selected } = props;

    //toast message
    const toast = useContext(ToastContext);
    // states
    const [minimumDate, setMinimumDate] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //Form ik
    const validationSchema = Yup.object().shape({
        agreement_date: Yup.string().required("Agreement Date is required").nullable(),
        broker_commission: Yup.string().required("Commission Percentage is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            agreement_date: null,
            broker_commission: "",
        },
        onSubmit: async (data) => {
            //data
            setDisableButton(true);
            setIsLoading(true);
            data = {
                ...data,
                id: parseInt(brokerId),
                agreement_date: getDateFormat(data.agreement_date),
            };

            if (minimumPercentageCheck(formik.values.broker_commission)) {
                // Submit Form
                try {
                    const res = await postAgreement(data);
                    if (res.data.status) {
                        onHide();
                        if (callShowAgreement === true) {
                            handleShowAgreement();
                        }
                        toast.showMessage("Agreement Sent", "Agreement has been sent successfully.", "success");
                    } else {
                        toast.showMessage("Error", "Sorry, we are unable to process your request at this time", "error");
                    }
                } catch (e) {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time", "error");
                }
            } else {
                toast.showMessage("Error", "Broker Commission percentage should be greater than 0", "error");
            }
            setDisableButton(false);
            setIsLoading(false);
        },
    });

    useEffect(() => {
        if (callShowAgreement) {
            setMinimumDate(new Date(selected.agreement_date));
        } else {
            setMinimumDate(new Date(selected[0]?.agreement_date));
        }
    }, []);
    return (
        <>
            {/* <div className="card"> */}
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <CustomInputField
                        className="col-12 md:col-6"
                        iden="agreement_date"
                        label="Agreement Date"
                        type="calendar"
                        mandatory="*"
                        formik={formik}
                        placeHolder="08/10/2020"
                        minDate={minimumDate}
                        monthNavigator
                        yearNavigator
                        yearRange="1945:2050"
                    />
                    <CustomInputField
                        className="col-12 md:col-6"
                        iden="broker_commission"
                        label="Commission Percentage"
                        mandatory="*"
                        formik={formik}
                        placeHolder="Enter Commission Percentage"
                        maxLength={4}
                        mask="99.99"
                    />
                </div>
                <div className="text-right pt-3">
                    <CustomAddNewButton title="Send" type="submit" isDisabled={disableButton} isLoading={isLoading} />
                </div>
            </form>
            {/* </div> */}
        </>
    );
};

export default SendAgreementComponent;
