import React, { useState, useRef, useEffect } from "react";
//Component

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { RESERVE_RELEASE_BATCH_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import { getColumnBody } from "../../../../../../utils/HelperFuctions/supportHelper";
import classNames from "classnames";

const ReserveReleaseTable = (props) => {
    const {batch, loading} = props;
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);

    const dt = useRef(null);

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = RESERVE_RELEASE_BATCH_GRID_HEADERS.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable
            headerClassName={`${col.field === "invoice_amount" || col.field === "reserved_amount" || col.field === "release_amount" || col.field === "tax_per_day" ? "amount_column_alignment" : ""}`}>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    return (
        <>
            <h5>
                <b>Reserve Release</b>
            </h5>
            <div className="grid">
                {/* rrbatch_scroll */}
                <div className="col-12 pt-3"> 
                    <DataTable ref={dt} value={batch} emptyMessage="No record available." loading={loading}
                        onSort={onSort}
                        sortable
                        sortField={sortField}
                        sortOrder={sortOrder}>
                        {columnComponents}
                        {/* <Column field="client_name" header="Client Name"></Column>
                        <Column field="invoice_no" header="Invoice No"></Column>
                        <Column field="invoice_amount" header="Invoice Amount"></Column>
                        <Column field="fee_days" header="Fee Day"></Column>
                        <Column field="posting_date" header="Posting Date"></Column>
                        <Column field="reserved_amount" header="Cash Reserves"></Column>
                        <Column field="tax_per_day" header="Deductions"></Column>
                        <Column field="release_amount" header="Release Amount After Deduction"></Column> */}
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ReserveReleaseTable;
