export function AmountCheck(value) {
    if ((value.match(/\./g) || []).length > 1) {
        value = value.replace(/\./g, "");
    }
    if (value === "" || parseFloat(value) < 100000000) {
        return value;
    } else {
        const update = value.slice(0, -1);
        value = update.trim();

        return value;
    }
}

export function amountCheckUnderOneLac(value) {
    if ((value.match(/\./g) || []).length > 1) {
        value = value.replace(/\./g, "");
    }
    if (value === "" || parseFloat(value) < 100000) {
        return value;
    } else {
        const update = value.slice(0, -1);
        value = update.trim();

        return value;
    }
}
export function amountCheckUnderOneThousand(value) {
    if ((value.match(/\./g) || []).length > 1) {
        value = value.replace(/\./g, "");
    }
    if (value === "" || parseFloat(value) < 1000) {
        return value;
    } else {
        const update = value.slice(0, -1);
        value = update.trim();

        return value;
    }
}

export function currencyFormat(value) {
    const numericValue = value.toString().replace(/[^0-9.]/g, "");
    const formattedCurrency = Number(numericValue).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formattedCurrency;
}

export function currencyFormateToValue(value) {
    return value.toString().replace(/[$,]/g, "");
}

export function currentStringToNumber(currencyString) {
    const number = currencyString.replace(/[^0-9.]/g, '');
    return parseFloat(number);
}

export function checkCollectionAmount(collectedAmount, totalAmount) {
    let amountCollected = parseFloat(collectedAmount).toFixed(2);
    let amountTotal = parseFloat(totalAmount).toFixed(2);
    if (amountCollected < amountTotal) return "less";
    else if (amountCollected === amountTotal) return "equal";
    else if (amountCollected > amountTotal) return "greater";
    else return false;
}

export function currencyFormatUSD(value, currencySymbol = true) {
    const numericString = typeof value === 'string' ? value.replace(/,/g, '') : value;
    const numericValue = parseFloat(numericString);
    
    if (!isNaN(numericValue)) {
        const formattedValue = new Intl.NumberFormat("en-US", {
            style: currencySymbol ? "currency" : "decimal",
            currency: "USD",
            maximumFractionDigits: 2,
        }).format(numericValue);
        return formattedValue;
    }
}
