import { postData } from "../genaric/genaric-api";

import appUrl from "../../utils/Constants/appUrl";

export async function getReports(data = null) {
    return postData(appUrl["reports"].fetch, data);
}

export async function generateReport(data = null) {
    return postData(appUrl["reports"].generate, data);
}
