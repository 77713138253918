import React, { useState } from "react";
//Styles
import "./header.scss";
//Ui-Components
import { Button } from "primereact/button";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";

//React Icons
import { BsCloudDownload } from "react-icons/bs";
import moment from "moment";
import { getDateFormat } from "../../../../utils/HelperFuctions/dateFormat";
import { currencyFields } from "../../../../utils/Constants/global";
import { currencyFormatUSD } from "../../../../utils/HelperFuctions/checkAmount";
import Axios from "axios";
import { Toast } from "react-bootstrap";
import { GENERAL_LEDGER_RIGHTS } from "../../../../utils/Constants/rights";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { saveAs } from "file-saver";

const header = (props) => {
    const { selectedYear, ledgerData, data, downloadLedger, setDownloadLedger, user, isDropdownOpen, setDropdownOpen, setExportMenuItems, exportMenuItems } = props;

    let BASE_URL = process.env.REACT_APP_BASE_URL;

    const handleDownloadSchedule = async (type) => {
        setDropdownOpen(false);
        // if (type === "pdf") {
            setDownloadLedger(true);
            try {
                const accessToken = localStorage.getItem("accessToken");


                let payload = data;
                payload.export = type === "Excel" ? 1 : 0;
                payload.download = true;

                const response = await Axios.post(`${BASE_URL}general_ledger/download`, payload, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: "arraybuffer",
                });

                if (response) {
                    if (type !== "Excel") {
                        const pdfData = new Uint8Array(response.data);
                        const blob = new Blob([pdfData], { type: "application/pdf" });
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = "general_ledger.pdf";
                        a.click();
                    } else {
                        saveAs(new Blob([response.data]), "general_legder.xlsx");
                    }
                    
                }

                setDownloadLedger(false);
            } catch (e) {
                setDownloadLedger(true);
                Toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setDownloadLedger(false);
        // }
    };

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    return (
        <>
            <div className="grid">
                <div className="col-8">
                    <h5 className="creative_staff_h4">
                        <b>{ledgerData?.title}</b>
                    </h5>
                </div>
                {validateUserPermissions([GENERAL_LEDGER_RIGHTS.download_statement], user?.rights) && (
                    <div className="col-4 text-right">
                        <div className="dropdown">
                            {/* <button className={`dropdown-button ${isDropdownOpen ? "hide-chevron" : ""}`} onClick={toggleDropdown}>
                                    Export To
                                </button> */}

                            <CustomAddNewButton
                                title="Export To"
                                className={`dropdown-button ${isDropdownOpen ? "hide-chevron" : ""}`}
                                onClick={toggleDropdown}
                                // isDisabled={gridData.length <= 0}
                            />

                            {isDropdownOpen && exportMenuItems.length > 0 && (
                                <div className="dropdown-menu">
                                    <ul className="dropdown-list">
                                        {exportMenuItems.map((item) => (
                                            <li key={item.id} className="dropdown-item" onClick={() => handleDownloadSchedule(item.label)}>
                                                {item.label}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* <Button
                            icon={<BsCloudDownload />}
                            tooltip="Download Statement"
                            className="print_data"
                            tooltipOptions={{ position: "left" }}
                            onClick={() => handleDownloadSchedule()}
                            disabled={downloadLedger ? true : false}
                        /> */}
                    </div>
                )}

                <div className="col-12 pt-2">
                    <div className="ledger_header_card">
                        <div className="card_text">
                            <p>
                                <b>Statement Period</b>
                            </p>
                            <p>
                                01 Oct {ledgerData?.year - 1} - 30 Sep {ledgerData?.year}
                            </p>
                        </div>
                        <div className="card_text">
                            <p>
                                <b>Assigned Credit Limit</b>
                            </p>
                            <p>{ledgerData?.credit_limit ? currencyFormatUSD(ledgerData?.credit_limit) : "$0.00"}</p>
                        </div>
                        <div className="card_text">
                            <p>
                                <b>Available Credit Limit</b>
                            </p>
                            <p>{ledgerData?.active_credit_limit ? currencyFormatUSD(ledgerData?.active_credit_limit) : "$0.00"}</p>
                        </div>
                        <div className="card_text">
                            <p>
                                <b>Current Balance Outstanding</b>
                            </p>
                            <p>{ledgerData?.credit_limit && ledgerData?.active_credit_limit ? currencyFormatUSD(ledgerData?.credit_limit - ledgerData?.active_credit_limit) : "$0.00"}</p>
                        </div>
                        <div className="card_text">
                            <p>
                                <b>Statement Date</b>
                            </p>
                            <p>{getDateFormat()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default header;
