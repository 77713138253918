import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

//PrimeReact components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";

//ui
import SearchInputComponent from "../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../ui/ColumnFilter/ColumnFilterComponent";
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
// service
import { getBroker } from "../../../services/broker";
//context
import { ToastContext } from "../../../context/toast";
//constants
import { BROKER_KEBAB_MENU_ITEMS, getContactMask, getSSNMask } from "../../../utils/Constants/global";
//components
import ChangeBrokerStatusComponent from "./Components/ChangeBrokerStatusComponent";
import SendAgreementComponent from "./Components/SendAgreementComponent";
import Header from "./Header/Header";
import { PaginatorTemplate } from "../../../ui/PaginatorTemplate/PaginatorTemplate";
import { validateUserPermissions } from "../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../redux/login";
import KebabMenuComponent from "../../../ui/KebabMenu/KebabMenuComponent";
import { MANAGMENT_RIGHTS } from "../../../utils/Constants/rights";

let columns = [
    { field: "last_name", header: "Full Name" },
    { field: "email", header: "Email" },
    { field: "phone", header: "Phone No" },
    { field: "status", header: "Status" },
    { field: "referral_code", header: "Referral Code" },
    { field: "ssn", header: "Tax ID/SSN" },
    { field: "license_number", header: "License No" },
];

const Brokers = () => {
    const dt = useRef(null);

    //use History
    const history = useHistory();

    //states
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);
    const [brokerId, setBrokerId] = useState(null);
    const [searchText, setSearchText] = useState("");

    //kebab Menu Items
    const kebabMenuItems = [
        { title: "Change Status", status: 1, isDisabled: selectedBroker[0]?.status === "Approved" || selectedBroker.length !== 1 },
        { title: "Send Agreement", status: 2, isDisabled: selectedBroker[0]?.status !== "Approved" || selectedBroker.length !== 1 },
    ];

    const [kebab_menu, setKebabMenu] = useState([]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Sort states
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(0);

    // Dialog States
    const [showStatusDialog, setShowStatusDialog] = useState(false);
    const [showSendAgreementDialog, setShowSendAgreementDialog] = useState(false);

    // Redux
    const { user } = useSelector(loginState);

    //toast message
    const toast = useContext(ToastContext);

    // API response Data
    const [responseData, setReponseData] = useState([]);

    const handleColumn = (data) => {
        setSelectedColumns(data);
    };

    const handleRowClick = (event) => {
        if (!validateUserPermissions([MANAGMENT_RIGHTS.brokers.selection], user?.rights)) return;

        if (event.type === "row") {
            if (event.data.id) {
                history.push({pathname: '/brokerdetails', state: { id: `${event.data.id}` }});
            } else {
                toast.showMessage("Error", "Admin has been notified", "error");
            }
        }
    };

    //  Values Selection
    const handleSelectionChange = (event) => {
        // Checking Status
        if (event?.value?.length > 0) {
            const updatedMenuItems = BROKER_KEBAB_MENU_ITEMS.map((menuItem) => {
                if (menuItem.title === "Send Agreement" && event.value.some((item) => item.status !== "Approved")) {
                    return {
                        ...menuItem,
                        isDisabled: true,
                    };
                }
                return menuItem;
            });
            setKebabMenu(updatedMenuItems);
        }

        if (event.type === "checkbox" || event.type === "all") {
            setSelectedBroker(event.value);
        }
    };

    //Kebab Menu Open Items
    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedBroker.length === 1) {
            setShowStatusDialog(true);
        } else if (status == 2 && selectedBroker.length === 1) {
            setShowSendAgreementDialog(true);
        }
    };
    //Close Dialog
    const handleCloseAgreementDialog = () => {
        setShowSendAgreementDialog(false);
        setSelectedBroker([]);
        fetchBroker();
    };
    const handleCloseStatusDialog = () => {
        setShowStatusDialog(false);
        setSelectedBroker([]);
        fetchBroker();
    };
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    //Column Body
    const getColumnBody = (colField) => (rowData) => {
        if (colField === "last_name") {
            return (rowData.last_name ? rowData.last_name : "") + " " + (rowData.first_name ? rowData.first_name : "");
        }
        if (colField === "phone") {
            let phone_mask = getContactMask(rowData.country_phone_code, rowData.phone);
            return phone_mask;
        }
        if (colField === "ssn") {
            let ssn_mask = getSSNMask(rowData.ssn);
            return ssn_mask;
        }
        if (colField === "license_number") {
            let license_number = rowData?.license_number?.replaceAll(/\s/g, "");
            return license_number;
        }
        if (colField === "referral_code") {
            return rowData[colField] ? rowData[colField] : "Awaiting for Approval";
        }

        return rowData[colField] ? rowData[colField] : "";
    };

    // Data Table Fields Rendering
    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const fetchBroker = async () => {
        let payload = {
            per_page: pageSize,
            page: currentPage,
            sort_by: sortField,
            search_text: searchText,
        };

        if (sortOrder) {
            payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
        }

        try {
            let res = await getBroker(payload);
            if (res.status) {
                setReponseData(res.data.brokers.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.brokers.total,
                    totalPages: res.data.brokers.last_page,
                    isLastPage: res.data.brokers.last_page,
                });
                setLoading(false);
                setLoading(false);
            } else {
                setLoading(false);
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        let updatedHeaderColumns = user.sensitive_information_disclosure
        ? selectedColumns
        : selectedColumns.filter(header => 
            header.field !== "ssn" && header.field !== "license_number"
          );

        columns = updatedHeaderColumns;

        setSelectedColumns(updatedHeaderColumns);
    }, []);

    useEffect(() => {
        if (searchText) {
            setLoading(true);
            const delayDebounceFn = setTimeout(() => {
                fetchBroker();
            }, 350);
            return () => clearTimeout(delayDebounceFn);
        } else {
            setLoading(true);
            fetchBroker();
        }
    }, [pageSize, currentPage, sortField, sortOrder, searchText]);

    useEffect(() => {
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        if (user) {
            const _kebab_items = BROKER_KEBAB_MENU_ITEMS.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

                if (isAllowed) {
                    return col;
                }
            });
            setKebabMenu(_kebab_items);
        }
    }, [user]);  

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-10">
                    <h4 className="m-0">
                        <b>Brokers</b>
                    </h4>
                </div>
                <div className="md:col-6"></div>
                <div className="col-12 md:col-6">
                    <div className="flex flex-row">
                        <SearchInputComponent
                            // handleInput={(e) => setSearchText(e.target.value)}
                            placeholder="Search by full name, email, phone & tax id/ssn"
                            value={searchText}
                            setKeyword={setSearchText}
                        />
                        <div className="ml-2">
                            <ColumnFilterComponent items={columns} handleColumnFilter={handleColumn} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">{kebab_menu.length > 0 && <KebabMenuComponent items={kebab_menu} handleMenuOpen={handleOpenMenuItems} selected={selectedBroker} />}</div>
                        {/* <div className="ml-2">
                            <KebabMenuComponent items={kebabMenuItems} handleMenuOpen={handleOpenMenuItems} selected={selectedBroker} />
                        </div> */}
                        <div className="ml-2">
                            <Header broker_id={brokerId} />
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-0">
                    {loading == true ? (
                        <CustomLoading />
                    ) : (
                        <>
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    value={responseData}
                                    responsiveLayout="scroll"
                                    globalFilter={globalFilter}
                                    emptyMessage="No record available."
                                    selection={selectedBroker.length === 0 ? null : selectedBroker}
                                    onPage={onCustomPage}
                                    paginatorTemplate={PaginatorTemplate(
                                        pagination.isFirstPage,
                                        pagination.isLastPage,
                                        currentPage,
                                        pagination.totalPages,
                                        pageSize,
                                        page,
                                        pagination.totalRecords,
                                        pageInputTooltip,
                                        setCurrentPage,
                                        setPage,
                                        setPageSize,
                                        setPageInputTooltip
                                    )}
                                    rows={pageSize}
                                    paginator
                                    onSort={onSort}
                                    sortable
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    onRowSelect={handleRowClick}
                                    onSelectionChange={handleSelectionChange}
                                    selectionMode="single"
                                    // loading={dataTableLoading}
                                >
                                    <Column selectionMode="multiple"></Column>
                                    {columnComponents}
                                </DataTable>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Change Status Dialog */}
            {validateUserPermissions([MANAGMENT_RIGHTS.brokers.status_change], user?.rights) && (
                <Dialog
                    blockScroll={true}
                    visible={showStatusDialog}
                    onHide={handleCloseStatusDialog}
                    header="Change Status"
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "30vw" }}
                >
                    <ChangeBrokerStatusComponent
                        selectedBroker={selectedBroker}
                        onHide={() => {
                            handleCloseStatusDialog();
                            fetchBroker();
                        }}
                    />
                </Dialog>
            )}

            {validateUserPermissions([MANAGMENT_RIGHTS.brokers.send_agreement], user?.rights) && (
                <Dialog
                    blockScroll={true}
                    visible={showSendAgreementDialog}
                    onHide={handleCloseAgreementDialog}
                    header="Send Agreement"
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "50vw" }}
                >
                    <SendAgreementComponent
                        brokerId={selectedBroker.length > 0 ? selectedBroker[0].id : null}
                        onHide={() => {
                            handleCloseAgreementDialog();
                            fetchBroker();
                        }}
                        selected={selectedBroker}
                    />
                </Dialog>
            )}
        </>
    );
};
export default Brokers;
