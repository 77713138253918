import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// Components
import Header from "./Header";
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../../../../ui/KebabMenu/KebabMenuComponent";
import { COLLECTIONS_GRID_HEADERS } from "../../../../../../../utils/Constants/global";
import { Dialog } from "primereact/dialog";
import CollectionInvoices from "../../Components/CollectionInvoices";
import NotesModal from "../../Components/NotesModal";
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { ToastContext } from "../../../../../../../context/toast";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
import { Checkbox } from "primereact/checkbox";
import Legends from "../../../../../../../ui/Legends/Legends";

// Constants

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedUsers, setKebabMenuOption, setSelectedUsers, setLoading } = props;
    const [onSelectionColumn] = useState([]);
    const [collectionnotesmodal, setCollectionNotesModal] = useState(false);
    const [collectioninvoicemodal, setCollectionInvoiceModal] = useState(false);
    const [kebab_menu_items, setKebabMenuItems] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    // Base Url
    let BASE_URL = process.env.REACT_APP_BASE_URL;

    //toast message
    const toast = useContext(ToastContext);

    //Kebab Menu Items
    const COLLECTION_KEBAB_MENU = [
        // {
        //     title: "View Attachment",
        //     status: 1,
        //     isDisabled: selectedUsers.length === 0,
        // },
        // {
        //     title: "View Invoices",
        //     status: 2,
        //     isDisabled: selectedUsers.length === 0,
        // },
        {
            title: "Notes",
            status: 3,
            isDisabled: selectedUsers.length !== 1,
            required_rights: [TRANSACTION_RIGHTS.collections.schedule_notes],
        },
        {
            title: "Download Report",
            status: 4,
            isDisabled: selectedUsers.length !== 1,
            required_rights: [TRANSACTION_RIGHTS.collections.download_report],
        },
    ];

    const handleOpenMenuItems = (status) => {
        if (status === 3 && selectedUsers.length === 1) {
            setCollectionNotesModal(true);
        } else if (status === 4 && selectedUsers.length === 1) {
            handleDownload();
        }
    };
    // Download Report
    const handleDownload = async () => {
        setLoading(true);
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await axios.post(
                `${BASE_URL}collection/report/download`,
                { id: selectedUsers[0].id },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: "arraybuffer",
                    withCredentials: true,
                }
            );
            if (response) {
                const pdfData = new Uint8Array(response.data);
                const blob = new Blob([pdfData], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setSelectedUsers([]);
        setLoading(false);
    };

    useEffect(() => {
        COLLECTIONS_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    }, [user]);

    useEffect(() => {
        // Kebab Items
        const _kebab_menu_items = COLLECTION_KEBAB_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setKebabMenuItems(_kebab_menu_items);
    }, [user, selectedUsers.length]);

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-10">
                    <h4 className="pl-2">
                        <b>Collections</b>
                    </h4>
                </div>

                <div className="md:col-7 col-12">
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include terminated collection
                        </label>
                        {showAll === 1 && (
                            <>
                                <div className="mt-1 ml-3">
                                    <Legends classes="square-legend-delete" title="Deleted" />
                                </div>
                                <div className="mt-1 ml-2">
                                    <Legends classes="square-legend-terminate" title="Terminated" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="md:col-5 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent
                            type="text"
                            value={keyword}
                            setKeyword={setKeyword}
                            // handleInput={(e) => setKeyword(e.target.value)}
                            placeholder="Search by collection ID, invoice no, client name, debtor name & collection type"
                        />
                        <div className="ml-2">
                            <ColumnFilterComponent items={COLLECTIONS_GRID_HEADERS} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className={`ml-2 ${true ? "no-drop" : ""}`}>
                            {/* handleMenuOpen={handleOpenMenuItems} */}
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} />}
                        </div>
                        <div className="ml-2">
                            <Header />
                        </div>
                        {/* <CustomAddNewButton title="temp" type="button" onClick={(e) => setCollectionInvoiceModal(true)} /> */}
                    </div>
                </div>
            </div>
            {/* //dialog */}
            <Dialog
                blockScroll={true}
                visible={collectioninvoicemodal}
                onHide={() => {
                    setCollectionInvoiceModal(false);
                    setSelectedUsers([]);
                }}
                header="Collection Invoices"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "60vw" }}
            >
                <CollectionInvoices onHide={() => setCollectionInvoiceModal(false)} selected={selectedUsers} />
            </Dialog>
            <Dialog
                visible={collectionnotesmodal}
                onHide={() => {
                    setCollectionNotesModal(false);
                    setSelectedUsers([]);
                }}
                header="Notes"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
                blockScroll={true}
            >
                <NotesModal onHide={() => setCollectionNotesModal(false)} selected={selectedUsers} />
            </Dialog>
            {/* //dialog */}
        </>
    );
};

export default FilterBar;
