import React from "react";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";

const DeleteDialog = ({ onHide, data }) => {
    return (
        <div className="grid">
            <div className="col-12 pt-2">
                <p>
                    <b>Are you sure you want to delete this role? Please change the role of below mentioned users before deletion.</b>
                </p>
            </div>
            <div className="col-12 pt-3">
                <div className="role_tbl_overflow">
                    <table className="table_delete">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((user) => (
                                <tr>
                                    <td>
                                        <span>{user.last_Name + " " + user.first_Name}</span>
                                    </td>
                                    <td>
                                        <span>{user.email}</span>
                                    </td>
                                    <td>
                                        <span>{user.role_Name}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-right pt-3">
                <CustomCancelbtn title="Cancel" type="button" onClick={onHide} />
            </div>
        </div>
    );
};

export default DeleteDialog;
