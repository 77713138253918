import { Button } from "primereact/button";
import React, { useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";

const ViewCreditRequestDetail = (props) => {
    const { isClientRequest, onHide, data } = props;

    return (
        <div className="grid">
            <div className={`field col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop flex flex-column`}>
                <div style={{ width: "100%", marginBottom: ".5rem" }}>
                    <label htmlFor="name1">
                        <b>Client Name</b>
                    </label>
                </div>
                <div className="default">
                    <InputText value={data?.client_name} disabled={true} />
                </div>
            </div>

            {!isClientRequest && (
                <div className={`field col-12 md:col-4 no-drop flex flex-column`}>
                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                        <label htmlFor="name1">
                            <b>Debtor Name</b>
                        </label>
                    </div>
                    <div className="default">
                        <InputText value={data?.debtor_name} disabled={true} />
                    </div>
                </div>
            )}

            {!isClientRequest && (
                <div className={`field col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop flex flex-column`}>
                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                        <label htmlFor="name1">
                            <b>Client's Available Credit Limit</b>
                        </label>
                    </div>
                    <div className="default">
                        <InputText value={data?.client_active_credit_limit} disabled={true} />
                    </div>
                </div>
            )}

            <div className={`field col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop flex flex-column`}>
                <div style={{ width: "100%", marginBottom: ".5rem" }}>
                    <label htmlFor="name1">
                        <b>Previous Credit Limit</b>
                    </label>
                </div>
                <div className="default">
                    <InputText value={data?.current_credit_limit} disabled={true} />
                </div>
            </div>

            <div className={`field col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop flex flex-column`}>
                <div style={{ width: "100%", marginBottom: ".5rem" }}>
                    <label htmlFor="name1">
                        <b>Requested Credit Limit</b>
                    </label>
                </div>
                <div className="default">
                    <InputText value={data?.requested_amount} disabled={true} />
                </div>
            </div>

            <div className={`field col-12 ${isClientRequest ? "md:col-6" : "md:col-4"} no-drop flex flex-column`}>
                <div style={{ width: "100%", marginBottom: ".5rem" }}>
                    <label htmlFor="name1">
                        <b>Current Credit Limit</b>
                    </label>
                </div>
                <div className="default">
                    <InputText value={data?.new_limit_amount} disabled={true} />
                </div>
            </div>

            {/* //buttons */}
            <div className="col-12 text-center pt-5">
                <Button
                    label="Close"
                    onClick={(e) => {
                        e.preventDefault();
                        onHide();
                    }}
                    className="cancelbtn mr-2"
                />
            </div>
        </div>
    );
};

export default ViewCreditRequestDetail;
