import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import CustomCancelbtn from "../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../ui/CustomAddNewButton/CustomAddNewButton";

export const SignatureComponent = (props) => {
    const { handleSave, isDisabled = false } = props;

    const signatureRef = useRef();

    const clearSignature = () => {
        signatureRef.current.clear();
    };

    const saveSignature = () => {
        const signatureData = signatureRef.current.toDataURL();
        handleSave(signatureData);
    };
    return (
        <div>
            <SignatureCanvas ref={signatureRef} penColor="black" canvasProps={{ width: 300, height: 200, className: "signature_canvas" }} />
            <div style={{ paddingTop: 20 }}>
                <CustomCancelbtn title="Clear" className="mr-4 cancelbtnSignature " onClick={clearSignature} isDisabled={isDisabled} type="button" />
                <CustomAddNewButton title="Save" className="cancelbtnSignature" onClick={saveSignature} isDisabled={isDisabled} />
            </div>
        </div>
    );
};
