import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
//Components
import CustomLoading from "../../../../../../../ui/CustomSpinner/custom_spinner";
// Helper Function
import { getScheduleColumnBody } from "../../../../../../../utils/HelperFuctions/scheduleHelper";
import { ToastContext } from "../../../../../../../context/toast";
import { PaginatorTemplate } from "../../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";

// Components
const Body = (props) => {
    const {
        selectedHeaders,
        selectedSchedules,
        setSelectedSchedules,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        kebabMenuOption,
        setKebabMenuOption,
        setPage,
        page,
    } = props;

    //Use ref
    const ref = useRef(null);

    //use History
    const history = useHistory();

    //toast message
    const toast = useContext(ToastContext);

    // States
    // const [page, setPage] = useState(currentPage);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [detaildialogVisible, setDetailDialogVisible] = useState(false);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Redux
    const { user } = useSelector(loginState);

    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedSchedules(event.value);
        }
    };

    const handleRowClick = (event) => {
        if (!validateUserPermissions([TRANSACTION_RIGHTS.schedule_of_assignment.schedule_selection], user?.rights)) return;

        if (event.type === "row") {
            if (event.data.id) {
                history.push({ pathname: "/addnewscheduleofassignment", state: { id: `${event.data.id}` } });
            } else {
                toast.showMessage("Error", "Admin has been notified", "error");
            }
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getScheduleColumnBody(col.field)}
                sortable
                headerClassName={`${col.field === "total_amount" || col.field === "remaining_amount" || col.field === "totalScheduleFunding" ? "amount_column_alignment" : ""}`}
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };
    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 p-0">
                    <div className="card">
                        <DataTable
                            ref={ref}
                            value={selectedHeaders}
                            selection={selectedSchedules.length === 0 ? null : selectedSchedules}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowClick} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            )}
        </>
    );
};

export default Body;
