import React from "react";
import { Dialog } from "primereact/dialog";
import CustomAddNewButton from "../CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../CustomCancelbtn/CustomCancelbtn";
import "./customconfirmdialog.scss";

const CustomConfirmDialog = (props) => {
    const { header, icon, firsttext, secondtext, title, type, onHide, handler, isDisabled, isCancelDisabled, visible = true, width = "30vw" } = props;
    const dialogHeader = (
        <div className="dialog-header flex flex-row">
            {/* <div className={type === "success" ? "dialog-icon-success" : "dialog-icon-error"}>{icon}</div> */}

            <div className="ml-auto mr-auto">
                <h5 className="m-0 ml-auto mr-auto pt-2">{header}</h5>
            </div>
        </div>
    );

    return (
        <>
            <Dialog
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                blockScroll={true}
                header={dialogHeader}
                onHide={isCancelDisabled ? null : () => onHide()}
                visible={visible}
                style={{ width }}
                draggable={false}
            >
                <div className="grid text-center">
                    <div className="col-12">
                        <p>{firsttext}</p>

                        <p>{secondtext ?? ""}</p>
                    </div>
                    <div className="col-12 pt-3 ">
                        <CustomCancelbtn title="Cancel" isDisabled={isCancelDisabled} onClick={() => onHide()} />
                        <CustomAddNewButton isDisabled={isDisabled} title={title} onClick={() => handler()} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default CustomConfirmDialog;
