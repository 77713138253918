import React, { useContext, useEffect, useState } from "react";

// Prime React
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";

// Icons
import { BsX } from "react-icons/bs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onFormDirty, onProspectDataChange, onProspectFormDirty, onProspectKnobValue, onProspectSupplierFormDirty } from "../../../redux/main-view";

// Context
import { ToastContext } from "../../../context/toast";

// Helper Function
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";
import { countryCodeSearchHandler } from "../../../utils/HelperFuctions/phoneCodeSearch";

// UI Components
import CustomAddNewButton from "../../CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../CustomCancelbtn/CustomCancelbtn";

// API'S
import { getCountryCode } from "../../../services/country-code";
import { saveProspect, supplierDelete } from "../../../services/prospect";
import { useLocation } from "react-router-dom";
import { checkPathname } from "../../../utils/HelperFuctions/getPathNameForProspect";
import { loginState } from "../../../redux/login";
import CustomLoading from "../../CustomSpinner/custom_spinner";

export const SupplierInformation = (props) => {
    const { sendNextStep, frontline_color } = props;

    // use  location to get the pathname
    const location = useLocation();

    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // States
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [formArray, setFormArray] = useState([{ phone: "", name: "", products_supplied: "", country_phone_code: "+1" }]);

    // View State
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Handlers
    const handleInputChange = (e, index) => {
        dispatch(onFormDirty(true));
        dispatch(onProspectFormDirty(false));
        dispatch(onProspectSupplierFormDirty(false));
        const { name, value } = e.target;
        let cleanValue = null;
        if (name !== "country_phone_code") {
            cleanValue = textExtraSpaceRemoval(value);
        }
        const updatedFormArray = formArray.map((item, i) => {
            if (i === index) {
                if (name === "country_phone_code") {
                    return {
                        ...item,
                        [name]: value,
                    };
                } else if (name === "name") {
                    return {
                        ...item,
                        // [name]: value,
                        [name]: toTitleCaseWhileTyping(value),
                    };
                } else {
                    return {
                        ...item,
                        [name]: cleanValue,
                    };
                }
            }
            return item;
        });
        setFormArray(updatedFormArray);
    };

    const handleSaveAndNext = () => {
        dispatch(onProspectKnobValue(68));
        sendNextStep(5);
    };
    const handlePrevious = () => {
        sendNextStep(3);
    };

    const handleAddMore = () => {
        if (formArray.length === 5) {
            toast.showMessage("Limit", "Maximum 5 alloweds.", "error");
        } else {
            setFormArray([...formArray, { phone: "", name: "", products_supplied: "", country_phone_code: "+1" }]);
        }
    };

    // By Clickin the Cross Icons
    const handleRemoveForm = async (index) => {
        // Deleting Data from Backend
        const res = formArray.filter((_, i) => i === index);

        if (res[0]?.id) {
            const id = [res[0]?.id];

            try {
                setIsLoading(true);
                let payload = {
                    id: id,
                };

                const res = await supplierDelete(payload);

                if (res.data.status) {
                    let prospect_data = JSON.parse(JSON.stringify(screens.prospect.data));
                    let suppliers = prospect_data.suppliers;
                    suppliers = suppliers.filter((item, i) => item.id !== id[0]);
                    prospect_data["suppliers"] = suppliers;
                    dispatch(onProspectDataChange(prospect_data));
                    setIsLoading(false);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        if (index !== 0) {
            const updatedFormArray = formArray.filter((_, i) => i !== index);
            setFormArray(updatedFormArray);
        }
    };

    // On Form Saving Form Removing empty Objects
    const handleEmptyForm = (values) => {
        if (values) {
            const updatedFormArray = formArray.filter((_, i) => !values.includes(i));
            setFormArray(updatedFormArray);
        }
    };

    const saveHandler = async () => {
        try {
            // Removing Empty form
            let temp = [];
            let filteredData = formArray.filter((obj, index) => {
                if (obj.name || obj.phone || obj.products_supplied) {
                    return true;
                } else {
                    temp.push(index);
                    // if (index === 0) {
                    //     return false;
                    // } else {
                    //     return false;
                    // }
                }
            });

            // if (temp.length !== 0) {
            handleEmptyForm(temp);
            // }
            // return;
            if (screens.prospect.isFormDirty || screens.prospect.isSupplierFormDirty) {
                sendNextStep(5);
                dispatch(onProspectFormDirty(false));
                dispatch(onProspectSupplierFormDirty(false));
            } else {
                setIsSaving(true);
                // Cleaning Data
                const updatedArray = filteredData.map((obj) => {
                    const { country_phone_code, ...rest } = obj;
                    return {
                        ...rest,
                        country_phone_code: country_phone_code.phone_code ?? "+1",
                    };
                });
                const payload = {
                    step: "4",
                    suppliers: updatedArray,
                    id: screens.prospect.data.id,
                };
                const pathname = location.pathname;
                const isFound = checkPathname(pathname);
                if (!isFound) {
                    payload["company_id"] = user?.company_id;
                }
                let res = await saveProspect(payload);
                if (res) {
                    if (res.data.status) {
                        sendNextStep(5);
                        dispatch(onFormDirty(false));
                        const isEditing = JSON.parse(localStorage.getItem("isEditing"));
                        if (isEditing) {
                            dispatch(onProspectKnobValue(100));
                        } else {
                            dispatch(onProspectKnobValue(68));
                        }
                        dispatch(onProspectDataChange(res.data.prospect));
                    } else {
                        toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                    }
                } else {
                    toast.showMessage("Error", "Server error.", "error");
                    setIsSaving(false);
                }
                setIsSaving(false);
            }
        } catch (error) {
            toast.showMessage("Error", "Server error.", "error");
            setIsSaving(false);
        }
    };

    // API Handler's
    const fetchCountryCodes = () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                setCountryCodeOptions(res.data.countries);
            });
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    //UseEffect
    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCodeOptions(screens.prospect.countryCodes);
        } else {
            fetchCountryCodes();
        }
        if (screens.prospect.data) {
            dispatch(onProspectSupplierFormDirty(true));
            if (screens.prospect.data?.suppliers) {
                if (screens.prospect.data.suppliers.length !== 0) {
                    let temp = [];
                    screens.prospect.data.suppliers.map((item, index) => {
                        const data = { id: item.id, phone: item.phone, name: item.name, products_supplied: item.products_supplied, country_phone_code: item.country_phone_code };
                        temp.push(data);
                    });
                    setFormArray(temp);
                    dispatch(onProspectFormDirty(true));
                    dispatch(onProspectSupplierFormDirty(true));
                }
            }
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <CustomLoading />
            ) : (
                <div className={frontline_color ? "frontLine_scroll" : "pros_scrollbar"}>
                    {formArray.map((item, index) => (
                        <form className="step-form-top-padding" key={index} autoComplete="off">
                            <Fieldset legend="Supplier Information" className="positon-relative">
                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <label htmlFor="name">
                                            <b>Supplier Name</b>
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                id="name"
                                                name="name"
                                                label="Supplier Name"
                                                value={item.name}
                                                maxLength="60"
                                                keyfilter={/^[a-zA-Z\s]+$/}
                                                onChange={(e) => handleInputChange(e, index)}
                                                placeholder="Enter Supplier Name"
                                                autoComplete="none"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <label htmlFor="products_supplied">
                                            <b>Product Supplied</b>
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                id="products_supplied"
                                                name="products_supplied"
                                                label="Product Supplied"
                                                maxLength="60"
                                                value={item.products_supplied}
                                                onChange={(e) => handleInputChange(e, index)}
                                                placeholder="Enter Product Supplied"
                                                keyfilter={/^[\w\s]*$/}
                                                autoComplete="none"
                                            />
                                        </div>
                                    </div>

                                    <div className="md:col-4 col-12 row-margin-bottom">
                                        <label htmlFor="phone" className="step-fields-title">
                                            <b>Phone No</b>
                                        </label>
                                        <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                            <Dropdown
                                                id="country_phone_code"
                                                name="country_phone_code"
                                                value={item.country_phone_code}
                                                placeholder={item.country_phone_code.phone_code ?? item.country_phone_code}
                                                onChange={(e) => handleInputChange(e, index)}
                                                className="proscustom_width_country"
                                                options={countryCodeOptions}
                                                optionLabel="phone_code"
                                                filter
                                                filterBy="phone_code"
                                                emptyMessage="No results found"
                                            />
                                            <InputMask
                                                iden="phone"
                                                name="phone"
                                                mask="999-999-9999"
                                                placeholder="999-999-9999"
                                                value={item.phone}
                                                onChange={(e) => handleInputChange(e, index)}
                                                className="proscustom_width_phone"
                                                autoComplete="none"
                                            />
                                        </div>
                                        {item.phoneError && <small className="p-error">{item.phoneError}</small>}
                                    </div>
                                    {index !== 0 && <Button type="button" onClick={() => handleRemoveForm(index)} className="cros_btn" icon={<BsX />}></Button>}
                                </div>
                            </Fieldset>
                        </form>
                    ))}
                    <div className="pt-3">
                        {formArray.length === 5 ? null : frontline_color ? (
                            <Button id="addMore" label="Add More" className="fronLineSavebtn" type="button" onClick={handleAddMore} />
                        ) : (
                            <CustomAddNewButton title="Add More" type="button" onClick={handleAddMore} />
                        )}
                    </div>

                    <div className="action-btns-prospect-form pt-6 pr-3">
                        <CustomCancelbtn title="Previous" type="button" onClick={handlePrevious} />
                        {frontline_color ? (
                            <Button label="Save and Next" className="fronLineSavebtn" type="submit" onClick={() => (isSaving ? null : saveHandler())} disabled={isSaving} />
                        ) : (
                            <CustomAddNewButton title="Save and Next" type="submit" onClick={() => (isSaving ? null : saveHandler())} isDisabled={isSaving} />
                        )}
                        {/* <CustomAddNewButton title="Save and Next" type="submit" onClick={handleSaveAndNext} /> */}
                    </div>
                </div>
            )}
        </>
    );
};
