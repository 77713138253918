import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function saveProspect(data) {
    return postData(appUrl["prospect"].save, data);
}
export async function saveProspectAttachment(data) {
    return postData(appUrl["prospect"].saveAttachment, data);
}
export async function saveProspectBusinessName(data) {
    return postData(appUrl["prospect"].saveBusinessName, data);
}

export async function fetchProspect(data) {
    return postData(appUrl["prospect"].fetch, data);
}
export async function fetchProspectById(data) {
    return postData(appUrl["prospect"].fetchById, data);
}
export async function fetchProspectCount(data = null) {
    return postData(appUrl["prospect"].fetchCount, data);
}

export async function fetchClientAdvocates(data = null) {
    return postData(appUrl["prospect"].fetchClientAdvocates, data);
}

export async function getProspectDropdown(data = null) {
    return postData(appUrl["prospect"].dropdown, data);
}

export async function changeProspectStatus(data = null) {
    return postData(appUrl["prospect"].changeStatus, data);
}
export async function deleteProspect(data = null) {
    return postData(appUrl["prospect"].delete, data);
}

export async function saveProspectStatus(data = null) {
    return postData(appUrl["prospect"].saveDulyFilledStatus, data);
}

export async function fetchProspectStatus(data = null) {
    return postData(appUrl["prospect"].fetchDulyFilledStatus, data);
}

export async function unSavedProspectSendEmail(data = null) {
    return postData(appUrl["prospect"].sendEmail, data);
}
export async function editNote(data = null) {
    return postData(appUrl["prospect"].editNote, data);
}

export async function supplierDelete(data = null) {
    return postData(appUrl["prospect"].supplierDelete, data);
}
export async function ownerDelete(data = null) {
    return postData(appUrl["prospect"].ownerDelete, data);
}

export async function fetchDraftProspect(data = null) {
    return postData(appUrl["prospect"].fetchDraft, data);
}

export async function postUnderWritingDocument(data) {
    return postData(appUrl["prospect"].saveUnderWritingDocument, data);
}

export async function deleteProspectAttachment(data) {
    return postData(appUrl["prospect"].deleteAttachment, data);
}
