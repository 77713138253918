import appUrl from "../Constants/appUrl";
import { imageTypeCheck } from "./checkImageFileType";

const openImagePreview = (attachment) => {
    const newWindow = window.open("", "_blank");
    newWindow.document.body.innerHTML = `
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 90vh;">
        <img src="${attachment}" style="max-width: 100%; max-height: 90vh; margin-bottom: 10px;margin-top: 4rem;">
        <a href="${attachment}" download>
          <button style="position: absolute;top: 8px;right: 10px;border:0;background:transparent;cursor:pointer">
          <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.5072 1.67727C6.6397 0.70057 8.21698 0 10 0C13.3628 0 16.1541 2.4993 16.4571 5.72373C18.4476 6.00513 20 7.6707 20 9.71591C20 11.9619 18.1279 13.75 15.8594 13.75H12.5C12.1548 13.75 11.875 13.4702 11.875 13.125C11.875 12.7798 12.1548 12.5 12.5 12.5H15.8594C17.4741 12.5 18.75 11.2355 18.75 9.71591C18.75 8.19629 17.4741 6.93182 15.8594 6.93182H15.2344V6.30682C15.2344 3.532 12.9091 1.25 10 1.25C8.54697 1.25 7.25316 1.82216 6.32358 2.62386C5.37788 3.43946 4.88281 4.42287 4.88281 5.19318V5.75325L4.3261 5.81444C2.57964 6.0064 1.25 7.44082 1.25 9.14773C1.25 10.9811 2.78824 12.5 4.72656 12.5H7.5C7.84518 12.5 8.125 12.7798 8.125 13.125C8.125 13.4702 7.84518 13.75 7.5 13.75H4.72656C2.13442 13.75 0 11.7075 0 9.14773C0 6.94389 1.58233 5.1189 3.67778 4.65625C3.85599 3.57713 4.54981 2.50296 5.5072 1.67727Z" fill="#3165CB"/>
          <path d="M9.55806 19.8169C9.80214 20.061 10.1979 20.061 10.4419 19.8169L14.1919 16.0669C14.436 15.8229 14.436 15.4271 14.1919 15.1831C13.9479 14.939 13.5521 14.939 13.3081 15.1831L10.625 17.8661V6.875C10.625 6.52982 10.3452 6.25 10 6.25C9.65482 6.25 9.375 6.52982 9.375 6.875V17.8661L6.69194 15.1831C6.44786 14.939 6.05214 14.939 5.80806 15.1831C5.56398 15.4271 5.56398 15.8229 5.80806 16.0669L9.55806 19.8169Z" fill="#3165CB"/>
          </svg></button>
        </a>
      </div>`;
};
const downloadDocument = (attachment) => {
    // Perform the action to open the document
    if (attachment) {
        window.open(appUrl.imageBaseUrl + attachment, "_blank");
    }
};

export const previewDocumentHandler = (attachment) => {
    const result = imageTypeCheck(attachment);

    let previewURL = "";
    if (attachment.includes("#") || attachment.includes("&")) {
        previewURL = appUrl.imageBaseUrl + encodeURIComponent(attachment);
    } else {
        previewURL = `https://docs.google.com/viewer?url=${encodeURIComponent(appUrl.imageBaseUrl + attachment)}&embedded=true`;
    }

    const openPDFPreview = () => {
        window.open(previewURL, "_blank");
    };

    if (attachment) {
        if (document.readyState === "complete") {
            if (result === "xlsx" || result === "xls") {
                downloadDocument(attachment);
            } else {
                if (result) {
                    openImagePreview(appUrl.imageBaseUrl + attachment);
                } else {
                    openPDFPreview();
                }
            }
        } else {
            window.addEventListener("load", openPDFPreview);
        }
    }
};
