import React, { useEffect, useState, useContext } from "react";

// Prime React
import { Checkbox } from "primereact/checkbox";

// UI Components

// Components
import Header from "./Header";
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import { INVOICES_GRID_HEADERS, INVOICES_GRID_HEADERS_FOR_CLIENT, INVOICES_KEBAB_MENU, ROLE_NAME } from "../../../../../../../utils/Constants/global";
import InvoiceKebabMenuComponent from "../../Components/InvoiceKebabMenuComponent";
import { ToastContext } from "../../../../../../../context/toast";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { getCustomDropDown } from "../../../../../../../services/dropdown";
import { Dropdown } from "primereact/dropdown";
import Legends from "../../../../../../../ui/Legends/Legends";
// Constants

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedInvoices, setKebabMenuOption, handleDeleteInvoice, dialogCheck, setSelectedStatus, selectedStatus } = props;
    const [onSelectionColumn] = useState([]);
    const [kebab_menu_items, setKebabMenuItems] = useState([]);
    const [dropdownsLoading, setDropdownsLoading] = useState([]);
    const [statusDropdown, setStatusDropdown] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    // Context
    const toast = useContext(ToastContext);

    //Handlers

    //DropDowns
    const handleStatusDropdown = async () => {
        setDropdownsLoading(true);
        let payload = {
            select: ["invoice_status"],
        };
        try {
            getCustomDropDown(payload).then((res) => {
                let statuses = [];
                if (res.data.status) {
                    const statusData = JSON.parse(res.data.dropdowns.invoice_status);
                    statuses = statusData;
                    if (Array.isArray(statusData)) {
                        if (statuses.length > 0) {
                            statuses.unshift({ value: 0, name: "All" });
                        }
                    }

                    setStatusDropdown(statuses);
                }
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setDropdownsLoading(false);
    };

    const handleOpenMenuItems = (status) => {
        if (status === 1 && selectedInvoices.length > 0 && !dialogCheck) {
            setKebabMenuOption({
                move_to_schedule: true,
            });
        } else if (status === 2 && selectedInvoices.length > 0 && !dialogCheck) {
            handleDeleteInvoice();
        }
    };

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            INVOICES_GRID_HEADERS_FOR_CLIENT.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        } else {
            INVOICES_GRID_HEADERS.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        }

        // Kebab
        const _kebab_menu_items = INVOICES_KEBAB_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setKebabMenuItems(_kebab_menu_items);
    }, [user]);

    useEffect(() => {
        handleStatusDropdown();
    }, []);

    // onchange functions
    const onStatusChange = (e) => {
        setSelectedStatus(e.value);
    };

    let placeholderText = "Search by invoice no & client name";
    if (user && !user.debtor_id) {
        placeholderText = "Search by invoice no, client & debtor name";
    }

    return (
        <div className="grid">
            <div className="col-7 md:col-10">
                <h4 className="m-0">
                    <b>{user ? (user.debtor_id && window.location.href == window.location.origin + "/debtordashboard" ? "Recent Invoices" : "Invoices") : "Loading..."}</b>
                </h4>
            </div>
            {user && window.location.href !== window.location.origin + "/debtordashboard" ? (
                <>
                    <div className="md:col-5 col-12">
                        {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT && user?.userRoles[0]?.roleName != ROLE_NAME.DEBTOR ? (
                            <div className="flex mt-4">
                                <Checkbox inputId="showall" checked={showAll === true ? true : false} onChange={(e) => setShowAll(e.checked ? true : false)} />
                                <label htmlFor="showall" className="mt-1 ml-2">
                                    Include deleted/terminated invoices
                                </label>
                                {showAll && (
                                    <>
                                        <div className="mt-1 ml-3">
                                            <Legends classes="square-legend-delete" title="Deleted" />
                                        </div>
                                        <div className="mt-1 ml-2">
                                            <Legends classes="square-legend-terminate" title="Terminated" />
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                </>
            ) : (
                <div className="md:col-4 col-12"></div>
            )}

            {user && window.location.href != window.location.origin + "/debtordashboard" ? (
                <div className="md:col-7 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent type="text" value={keyword} setKeyword={setKeyword} placeholder={placeholderText} />
                        <div className="ml-2">
                            <ColumnFilterComponent
                                items={user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT ? INVOICES_GRID_HEADERS_FOR_CLIENT : INVOICES_GRID_HEADERS}
                                handleColumnFilter={(e) => setSelectedColumns(e)}
                                selectedColumn={onSelectionColumn}
                            />
                        </div>
                        <div className={`ml-2 ${true ? "no-drop" : ""}`}>
                            {kebab_menu_items.length > 0 && (
                                <InvoiceKebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} selected={selectedInvoices} dialogCheck={dialogCheck} />
                            )}
                        </div>
                        <div className="ml-2">
                            <Header selectedUsersLength={10} />
                        </div>
                    </div>
                </div>
            ) : user && user.debtor_id ? (
                <>
                    <div className="md:col-2 col-12"></div>
                    <div className="md:col-2 col-12">
                        <Dropdown
                            value={selectedStatus}
                            filter={false}
                            filterBy="name"
                            options={statusDropdown}
                            onChange={onStatusChange}
                            placeholder="Select Status"
                            optionLabel="name"
                            optionValue="value"
                            className="mr-2"
                            style={{ padding: "4px" }}
                        />
                    </div>
                    <div className="md:col-4 col-12 ">
                        <SearchInputComponent type="text" value={keyword} setKeyword={setKeyword} placeholder="Search by invoice no & client name" />
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default FilterBar;
