import React from "react";
import { BsCalendar4Event } from "react-icons/bs";
import {dateTimeFormatForInputFields} from "../../../utils/HelperFuctions/dateFormat";

const NotificationComponent = (props) => {
    const { notifications } = props;
    return (
        <>
            {/*{notifications.map((notification,index) => (*/}
                <div className="notification_component pt-6">  {/*key={index}*/}
                    <div className="border_box">
                        {notifications.map((item,index) => (
                            <div className={`pt-6 mb-2 pb-3 pl-3 pr-3 items-border-left ${index == notifications.length - 1 ? "" : "items_border_bottom"}`} >
                                <span className="flex flex-row justify-content-between">
                                    <p className="notification-title">{item.type}</p>
                                    <div className="notification-datetime flex flex-row">
                                        <BsCalendar4Event className="mt-1"/> <p className="ml-2">{dateTimeFormatForInputFields(item.created_at)}</p>
                                    </div>
                                </span>
                                <p className="description_width">{item.content}</p>
                            </div>
                        ))}
                    </div>
                </div>
            {/*))}*/}
        </>
    );
};

export default NotificationComponent;
