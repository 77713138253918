import React from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputMask } from "primereact/inputmask";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import "./customInputfield.scss";
import { AutoComplete } from "primereact/autocomplete";

export default function CustomInputField({
    mandatory,
    rows,
    mask,
    iden,
    formik,
    type = "text",
    className,
    label,
    placeHolder,
    options,
    optionLabel,
    optionValue,
    value,
    onChange,
    disabled = false,
    editable = false,
    feedback,
    maxLength,
    filter,
    filterBy,
    keyfilter,
    handleFilter,
    suggestions,
    accept = null,
    onValueChange = null,
    minFractionDigits = 2,
    maxFractionDigits = 2,
    min,
    max,
    ...remainingProps
}) {
    function titleCase(str) {
        return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    }

    const isFormFieldValid = (name) => !!(formik?.touched[name] && formik?.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik?.errors[name]}</small>;
    };
    return (
        <div className={`field ${className} flex flex-column`}>
            <div style={{ width: "100%", marginBottom: ".5rem" }}>
                <label htmlFor="name1">
                    <b> {label || titleCase(iden)}</b>
                    <span className="clr_red">{mandatory}</span>
                </label>
            </div>
            <div className="default">
                {type === "dropdown" ? (
                    <Dropdown
                        disabled={disabled}
                        editable={editable}
                        placeholder={placeHolder}
                        id={iden}
                        name={iden}
                        options={options}
                        optionLabel={optionLabel}
                        optionValue={optionValue}
                        value={formik.values[iden]}
                        onChange={onChange || formik.handleChange}
                        className={classNames("custom_cursor", { "p-invalid": isFormFieldValid({ iden }) })}
                        filter={filter}
                        filterBy={filterBy}
                        onFilter={handleFilter}
                        // showClear
                        maxLength={maxLength}
                        keyfilter={keyfilter}
                        {...remainingProps}
                    />
                ) : type === "multiSelect" ? (
                    <MultiSelect
                        disabled={disabled}
                        placeholder={placeHolder}
                        id={iden}
                        name={iden}
                        filter={filter}
                        filterBy={filterBy}
                        options={options}
                        optionLabel={optionLabel}
                        optionValue={optionValue}
                        value={formik.values[iden]}
                        onChange={formik.handleChange}
                        className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        {...remainingProps}
                    />
                ) : type === "password" ? (
                    <Password
                        disabled={disabled}
                        id={iden}
                        name={iden}
                        value={value || formik.values[iden]}
                        onChange={onChange || formik.handleChange}
                        type={type}
                        placeholder={placeHolder}
                        className={classNames({ "p-invalid": isFormFieldValid({ iden }) })}
                        toggleMask
                        feedback={feedback}
                        maxLength={maxLength}
                        {...remainingProps}
                    />
                ) : type === "textArea" || type === "textarea" ? (
                    <InputTextarea
                        disabled={disabled}
                        id={iden}
                        name={iden}
                        value={formik.values[iden]}
                        onChange={onChange || formik.handleChange}
                        type="text"
                        placeholder={placeHolder}
                        rows={rows}
                        className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        maxLength={maxLength}
                        {...remainingProps}
                    />
                ) : mask !== undefined ? (
                    <InputMask
                        disabled={disabled}
                        id={iden}
                        mask={mask}
                        name={iden}
                        value={value || formik.values[iden]}
                        onChange={onChange || formik.handleChange}
                        placeholder={placeHolder}
                        className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        {...remainingProps}
                    />
                ) : type === "calendar" ? (
                    <Calendar
                        disabled={disabled}
                        id={iden}
                        name={iden}
                        value={formik?.values[iden]}
                        onChange={formik?.handleChange}
                        placeholder={placeHolder}
                        className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        mask="99/99/9999"
                        showIcon
                        {...remainingProps}
                    />
                ) : type === "autocomplete" ? (
                    <AutoComplete
                        disabled={disabled}
                        editable={editable}
                        suggestions={suggestions}
                        id={iden}
                        name={iden}
                        value={formik?.values[iden]}
                        onChange={formik?.handleChange}
                        placeholder={placeHolder}
                        className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        {...remainingProps}
                    />
                ) : type === "currency" ? (
                    <InputNumber
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        disabled={disabled}
                        inputId={iden}
                        name={iden}
                        value={formik?.values[iden]}
                        onChange={(e) => {
                            onValueChange(e);
                        }}
                        inputClassName={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        placeholder={placeHolder}
                        minFractionDigits={minFractionDigits}
                        maxFractionDigits={maxFractionDigits}
                        min={min}
                        max={max}
                        {...remainingProps}
                    />
                ) : (
                    <InputText
                        disabled={disabled}
                        autoComplete="off"
                        autoSave="off"
                        id={iden}
                        name={iden}
                        value={formik?.values[iden]}
                        onChange={onChange || formik?.handleChange}
                        type={type}
                        placeholder={placeHolder}
                        className={classNames("customInput", { "p-invalid": isFormFieldValid({ iden }) })}
                        maxLength={maxLength}
                        keyfilter={keyfilter}
                        accept={accept}
                        {...remainingProps}
                    />
                )}
            </div>
            {getFormErrorMessage(iden)}
        </div>
    );
}
