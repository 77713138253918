import React, { useContext, useEffect, useState } from "react";
//Components
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//ui-components
import CustomModalSpinner from "../../../../../../../ui/CustomModalSpinner/CustomModalSpinner";
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomMutiselectDropdown from "../../../../../../../ui/CustomMutiselectDropdown/CustomMutiselectDropdown";
import { inviteClient } from "../../../../../../../services/client";
import { ToastContext } from "../../../../../../../context/toast";
import CustomCancelbtn from "../../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomConfirmDialog from "../../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const InviteClientModal = (props) => {
    const { ownerLoading, ownersForDropdown, id, showInviteButton, setShowInviteButton, setUpdateClientUser, setInviteClient, setIsFormDirty, isFormDirty, onHide, isVisible, setIsVisible } = props;

    const toast = useContext(ToastContext);

    //state

    const [loading, setLoading] = useState(true);
    const [disableButton, setDisableButton] = useState(false);

    let validationSchema = Yup.object().shape({
        owner_ids: Yup.mixed().required("Owners is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            owner_ids: "",
        },
        onSubmit: async (data) => {
            data.prospect_id = id;
            setShowInviteButton(false);
            setDisableButton(true);

            try {
                const res = await inviteClient(data);
                if (res) {
                    if (res.data.status) {
                        toast.showMessage("Invite Client", res.data.message, "success");
                    } else {
                        toast.showMessage("Error", res.data.message, "error");
                    }

                    setInviteClient(false);
                    setShowInviteButton(true);
                    setUpdateClientUser(true);
                    setDisableButton(false);
                } else {
                    setInviteClient(false);
                    setShowInviteButton(true);
                    setDisableButton(false);
                    toast.showMessage("Error", "Server error.", "error");
                }
            } catch (e) {
                setInviteClient(false);
                setShowInviteButton(true);
                setDisableButton(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });

    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            onHide();
        }
    };

    return (
        <>
            {loading === !true ? (
                <CustomModalSpinner />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-12">
                            <CustomMutiselectDropdown
                                iden="owner_ids"
                                label="Owners"
                                mandatory="*"
                                formik={formik}
                                optionLabel="name"
                                optionValue="value"
                                options={ownersForDropdown}
                                placeHolder="Select"
                                maxSelectedLabels={3}
                                disabled={ownerLoading}
                                onChange={(e) => {
                                    setIsFormDirty(true);
                                    formik.handleChange(e);
                                }}
                            />
                        </div>

                        <div className="col-12 text-right pt-3">
                            <CustomCancelbtn
                                title="Cancel"
                                type="button"
                                onClick={(e) => {
                                    isFormDirty ? setIsVisible(true) : onHide();
                                }}
                                isDisabled={disableButton}
                            />
                            <CustomAddNewButton title="Invite" type="submit" isDisabled={!showInviteButton} />
                        </div>
                    </div>
                </form>
            )}

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setIsFormDirty(false);
                        setIsVisible(false);
                        onHide();
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default InviteClientModal;
