import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export  async function getCashReserves(data) {
    return postData(appUrl["reserves"].cash, data);
}

export async function getReserveReleases(data) {
    return postData(appUrl["reserves"].fetch, data);
}

export async function getRRBatch(data) {
    return postData(appUrl["reserves"].batch, data);
}

export async function getPreviousProspectReserves(data) {
    return postData(appUrl["reserves"].previousReserves, data);
}

export async function getProspectAvailableEscrows(data) {
    return postData(appUrl["reserves"].availableEscrows, data);
}

export async function getPercentAr(data) {
    return postData(appUrl["reserves"].percentAr, data);
}

export async function saveRRBatch(data) {
    return postData(appUrl["reserves"].save, data);
}

export async function getCbAfterReserveDeduction(data) {
    return postData(appUrl['reserves'].afterReserveDeduction, data);
}

export async function getReserveDetail(id) {
    return postData(appUrl['reserves'].show + "/" + id);
}