import React, { useContext, useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { ToastContext } from "../../../../../../context/toast";
import { getColumnBody } from "../../../../../../utils/HelperFuctions/supportHelper";
import { PaginatorTemplate } from "../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";
import { useSelector } from "react-redux";

// Redux
import { loginState } from "../../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../../utils/HelperFuctions/validatePermissions";
import { CREDIT_REQUEST_RIGHTS } from "../../../../../../utils/Constants/rights";

const Body = (props) => {
    const {
        isClientRequest,
        responseData,
        selectedCreditRequests,
        setSelectedCreditRequests,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        setPage,
        page,
        handleOpenViewDetailDialog,
    } = props;

    const ref = useRef(null);
    const toast = useContext(ToastContext);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");
    const [first, setFirst] = useState(0);

    // Redux
    const { user } = useSelector(loginState);

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions(isClientRequest ? [CREDIT_REQUEST_RIGHTS.client.selection] : [CREDIT_REQUEST_RIGHTS.debtor.selection], user?.rights)) return;

        // Row Selection
        if (event.type === "row") {
            if (event.data.id) {
                if (!event.data.deleted) {
                    handleOpenViewDetailDialog(event.data);
                }
            } else {
                toast.showMessage("Error", "Admin has been notified", "error");
            }
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getColumnBody(col.field)}
                sortable
                headerClassName={`${col.field === "current_credit_limit" || col.field === "requested_amount" || col.field === "new_limit_amount" ? "amount_column_alignment" : ""}`}
            >
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    const onCustomPage = (event) => {
        setFirst(event.first);
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.deleted) {
            return "delete_row_color";
        } else if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedCreditRequests(event.value);
        }
    };

    return (
        <div className="col-12 pt-0">
            {loading == true ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <DataTable
                        ref={ref}
                        value={responseData}
                        selection={selectedCreditRequests.length === 0 ? null : selectedCreditRequests}
                        onSelectionChange={handleSelectionChange}
                        onSort={onSort}
                        sortable
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onPage={onCustomPage}
                        paginatorTemplate={PaginatorTemplate(
                            pagination.isFirstPage,
                            pagination.isLastPage,
                            currentPage,
                            pagination.totalPages,
                            pageSize,
                            page,
                            pagination.totalRecords,
                            pageInputTooltip,
                            setCurrentPage,
                            setPage,
                            setPageSize,
                            setPageInputTooltip
                        )}
                        rows={pageSize}
                        paginator
                        responsiveLayout="scroll"
                        emptyMessage="No record available."
                        selectionMode="single" // Add selectionMode prop
                        onRowSelect={handleRowSelect} // Add onRowSelect event handler
                        // loading={loading}
                        rowClassName={getRowClassName}
                    >
                        <Column selectionMode="multiple"></Column>
                        {columnComponents}
                    </DataTable>
                </div>
            )}
        </div>
    );
};

export default Body;
