import React, { useState } from "react";
//styles
import "../../ReceivingAccount/receivingaccount.scss";
//components
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";

const AddNewReceivingAccount = ({ onHide }) => {
    const validationSchema = Yup.object().shape({
        number: Yup.string().required("This field is required"),
        title: Yup.string().required("This field is required"),
        bank_name: Yup.string().required("This field is required"),
        description: Yup.string().required("This is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            number: "",
            title: "",
            bank_name: "",
            account_type: "",
            currency: "",
            status: "",
            description: "",
        },
        onSubmit: async (data) => {
            console.log(data);
            return;
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const bankNameOption = [
        { name: "Bank1", value: "a" },
        { name: "Bank2", value: "b" },
        { name: "Bank3", value: "c" },
    ];
    const accountTypeOption = [
        { name: "2321", value: 1 },
        { name: "1023", value: 2 },
        { name: "9872", value: 3 },
    ];
    const statusOption = [
        { name: "Pending", value: 1 },
        { name: "Approved", value: 2 },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="step-form-top-padding">
                <div className="grid ">
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="number" className={classNames({ "p-error": isFormFieldValid("number") }, "step-fields-title")}>
                            Account Number*
                        </label>
                        <InputText
                            name="number"
                            id="number"
                            type="text"
                            placeholder="Enter account number"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.number}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("number") }, "mt-2")}
                            onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("number")}
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="title" className={classNames({ "p-error": isFormFieldValid("title") }, "step-fields-title")}>
                            Account Title*
                        </label>
                        <InputText
                            name="title"
                            id="title"
                            type="text"
                            placeholder="Enter account title"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("title") }, "mt-2")}
                            onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("title")}
                    </div>
                    <div className="md:col-4  col-12 row-margin-bottom">
                        <label htmlFor="bank_name" className={classNames({ "p-error": isFormFieldValid("bank_name") }, "step-fields-title")}>
                            Bank Name*
                        </label>
                        <Dropdown
                            id="bank_name"
                            name="bank_name"
                            value={formik.values.bank_name}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("bank_name") }, "mt-2")}
                            options={bankNameOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                        {getFormErrorMessage("bank_name")}
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="account_type" className="step-fields-title">
                            Account Type
                        </label>
                        <Dropdown
                            id="account_type"
                            name="account_type"
                            value={formik.values.account_type}
                            onChange={formik.handleChange}
                            className="mt-2"
                            options={accountTypeOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="currency" className="step-fields-title">
                            Currency
                        </label>
                        <InputText
                            name="currency"
                            id="currency"
                            type="text"
                            placeholder="Enter Currency"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            className="mt-2"
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="md:col-4 col-12 row-margin-bottom">
                        <label htmlFor="status" className="step-fields-title">
                            Status
                        </label>
                        <Dropdown
                            id="status"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            className="mt-2"
                            options={statusOption}
                            optionLabel="name"
                            placeholder="Select"
                            filter
                            filterBy="name"
                            onBlur={formik.handleBlur}
                        ></Dropdown>
                    </div>
                    <div className="col-12">
                        <InputTextarea
                            rows={7}
                            name="description"
                            id="description"
                            type="text"
                            placeholder="Description *"
                            className={classNames({ "p-invalid": isFormFieldValid("description") }, "mt-2 customTextarea")}
                        />
                        {getFormErrorMessage("description")}
                    </div>
                </div>
                <div class="cancel_save_btn mt-4">
                    <Button
                        label="Cancel"
                        className="btn btn-default"
                        onClick={(e) => {
                            e.preventDefault();
                            onHide();
                        }}
                    />

                    <Button
                        label="Save"
                        type="submit"
                        className="btn btn-default savebtn"
                        //  onClick={handleSaveAndNext}
                    />
                </div>
            </form>
        </>
    );
};

export default AddNewReceivingAccount;
