import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { onSupportDataMutated } from "../../../redux/main-view";

//Components
import CustomInputField from "../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../ui/CustomAddNewButton/CustomAddNewButton";

// API'S
import { changeStatus, getAllDropdown, getSupportStatus } from "../../../services/support";
import { ToastContext } from "../../../context/toast";
import { ROLE_NAME } from "../../../utils/Constants/global";
import { loginState } from "../../../redux/login";

let validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
});

const ChangeStatusModal = (props) => {
    const { onHide, selectedUsers, setSelectedUsers } = props;

    // Dispatch
    const dispatch = useDispatch();

    //user
    const { user } = useSelector(loginState);

    // Context
    const toastContext = useContext(ToastContext);

    // States
    const [isSaving, setIsSaving] = useState(false);
    const [supportStatusOptions, setSupportStatusOptions] = useState([]);

    // View States
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: "",
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            try {
                const form = new FormData();
                const temp = [];
                selectedUsers.map((item) => {
                    temp.push(item.id);
                });
                data["id"] = temp;
                form.append("id", JSON.stringify(temp));
                form.append("status", data.status);

                const res = await changeStatus(form);
                if (res?.data.status) {
                    setSelectedUsers([]);
                    toastContext.showMessage("Ticket Status Change", "Ticket status has been updated successfully.", "success");
                    dispatch(onSupportDataMutated());
                    onHide();
                } else {
                    setIsSaving(false);
                    toastContext.showMessage("Status Change Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (e) {
                setIsSaving(false);
                toastContext.showMessage("Status Change Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });

    // // UseEffects
    useEffect(() => {
        const fetchingDropdownValues = async () => {
            setIsLoading(true);
            // Fetching Support Status
            const data = {
                select: ["support_ticket_status"],
            };
            await getAllDropdown(data).then((res) => {
                let _status = JSON.parse(res.data.dropdowns.support_ticket_status);
                if (user?.userRoles[0]["roleName"] === ROLE_NAME.DEBTOR || user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT || user?.userRoles[0]["roleName"] === ROLE_NAME.BROKER)
                    _status = _status.filter((status) => status.value === 0);

                setSupportStatusOptions(_status);
            });
            setIsLoading(false);
        };

        fetchingDropdownValues();
    }, []);

    return (
        <>
            <form onSubmit={isSaving ? null : formik.handleSubmit}>
                <div className="grid">
                    <CustomInputField
                        className={`col-12 md:col-12 ${isLoading ? "no-drop" : " "}`}
                        disabled={isLoading}
                        type="dropdown"
                        iden="status"
                        label="Status"
                        mandatory="*"
                        formik={formik}
                        optionLabel="name"
                        optionValue="value"
                        options={supportStatusOptions}
                        placeHolder="Select"
                    />
                    <div className="col-12 text-right pt-5">
                        <CustomAddNewButton className={isSaving ? "no-drop" : ""} title="Save" type="submit" isDisabled={isSaving} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default ChangeStatusModal;
