import { postData } from "../genaric/genaric-api";
import appUrl from "../../utils/Constants/appUrl";

export async function getNotifications(data = null) {
    return postData(appUrl["notifications"].fetch, data);
}

export async function getAllNotifications(data = null) {
    return postData(appUrl["notifications"].fetchAll, data);
}
