import React from "react";
import loaderGif from "../../Images/loader_gif.gif";
import "./modalloader.scss";

export default function CustomModalSpinner({ height = "500px" }) {
    return (
        <div className="loader_modal_div" style={{ height: height }}>
            <img src={loaderGif} alt="Loading..." className="loader_image_width" />
        </div>
    );
}
