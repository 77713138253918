import React, { useContext, useEffect, useState } from "react";
//styles
import "../../../../../Operations/operation.scss";
// Constants
import { UNAPPLIED_CASH_GRID_HEADERS, INVOICES_GRID_HEADERS, UNAPPLIED_CASH_WITHOUT_GRID_HEADERS, UNAPPLIED_CASH_RELEASED } from "../../../../../../utils/Constants/global";
import FilterBar from "./GridView/FilterBar";
import Body from "./GridView/Body";
import { ToastContext } from "../../../../../../context/toast";
import { getInvoices } from "../../../../../../services/invoice";
import Header from "./GridView/Header";
import { Dialog } from "primereact/dialog";
import UnAppliedcashModal from "../Components/UnAppliedcashModal";
import { getReturnUC, getUCNonInvoice } from "../../../../../../services/collection";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const HoldAccounts = () => {
    // State
    const [keyword, setKeyword] = useState("");
    const [onSelectionColumn] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [header, setHeader] = useState(UNAPPLIED_CASH_GRID_HEADERS);
    const [componentName, setComponentName] = useState("unapplied_cash_with_invoices");
    const [selectedColumns, setSelectedColumns] = useState(UNAPPLIED_CASH_GRID_HEADERS);
    const [dialogCheck, setDialogCheck] = useState(false);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [modelOpen, setModalOpen] = useState(false);
    const [activeCard, setActiveCard] = useState(0);
    const [formDirty, setFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const [kebabMenuOption, setKebabMenuOption] = useState({
        transfer_cash_reserves: false,
        transfer_collection: false
    });

    // PAGINATION
    // States
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);

    //toast message
    const toast = useContext(ToastContext);

    // Api Handler
    const fetchInvoices = async (page) => {
        setLoading(true);
        let payload = {
            per_page: pageSize,
            page: page ? page : currentPage,
            search_text: keyword,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
            "hold_account_status =": 0,
            "uc >": 0,
        };
        if (showAll) payload["deleted"] = showAll;
        try {
            const res = await getInvoices(payload);
            if (res.data.status) {
                setResponseData(res.data.invoices.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.invoices.total,
                    totalPages: res.data.invoices.last_page,
                    isLastPage: res.data.invoices.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
        INVOICES_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    };

    const fetchUCNonInvoice = async (page, status) => {
        setLoading(true);
        let payload = {
            per_page: pageSize,
            page: page ? page : currentPage,
            search_text: keyword,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
        };

        if (showAll) payload["show_all"] = showAll;
        try {
            const res = status ? await getUCNonInvoice(payload) : await getReturnUC(payload);
            if (res.data.status) {
                setResponseData(res.data.collections.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.collections.total,
                    totalPages: res.data.collections.last_page,
                    isLastPage: res.data.collections.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
        if (status)
            UNAPPLIED_CASH_WITHOUT_GRID_HEADERS.filter((col) => {
                onSelectionColumn.push(col.field);
            });
        else
            UNAPPLIED_CASH_RELEASED.filter((col) => {
                onSelectionColumn.push(col.field);
            });
    };

    //UseEffect
    useEffect(() => {
        if (keyword) {
            const delayDebounceFn = setTimeout(() => {
                setCurrentPage(1);
                setPage(1);
                if (componentName === "unapplied_cash_without_invoices") fetchUCNonInvoice(currentPage, true);
                else if (componentName === "unapplied_cash_released") fetchUCNonInvoice(currentPage, false);
                else fetchInvoices();
            }, 350);
            return () => clearTimeout(delayDebounceFn);
        } else {
            if (componentName === "unapplied_cash_without_invoices") fetchUCNonInvoice(currentPage, true);
            else if (componentName === "unapplied_cash_released") fetchUCNonInvoice(currentPage, false);
            else fetchInvoices();
        }
    }, [showAll, keyword, sortField, sortOrder, pageSize, currentPage, componentName]);

    return (
        <>
            <Header
                setSelectedInvoices={setSelectedInvoices}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                setSelectedColumns={setSelectedColumns}
                setHeader={setHeader}
                setComponentName={setComponentName}
                setCurrentPage={setCurrentPage}
                setPage={setPage}
                setShowAll={setShowAll}
                loading={loading}
                setKeyword={setKeyword}
                fetchUCNonInvoice={() => fetchUCNonInvoice(1, true)}
            />
            <FilterBar
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                selectedColumns={selectedColumns}
                setKebabMenuOption={setKebabMenuOption}
                kebabMenuOption={kebabMenuOption}
                fetchInvoices={() => fetchInvoices(1)}
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
                header={header}
                componentName={componentName}
                setModalOpen={setModalOpen}
                dialogCheck={dialogCheck}
                fetchUCNonInvoice={() => fetchUCNonInvoice(1, true)}
            />
            <Body
                selectedHeaders={responseData}
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                setPage={setPage}
                page={page}
                componentName={componentName}
                setDialogCheck={setDialogCheck}
            />
            <Dialog
                blockScroll={true}
                header="Add New Unapplied Cash"
                visible={modelOpen}
                onHide={() => {
                    formDirty ? setIsVisible(true) : setModalOpen(false);
                }}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "52vw" }}
            >
                <UnAppliedcashModal
                    onHide={() => {
                        setModalOpen(false);
                        fetchUCNonInvoice(1, true);
                    }}
                    setFormDirty={setFormDirty}
                    onCancel={() => setModalOpen(false)}
                />
            </Dialog>

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setModalOpen(false);
                        setIsVisible(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default HoldAccounts;
