import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
//styles
import "./component.scss";
//PrimeReact components
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
//ui
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
//service
import { ChangeStatus, getBrokerById, inviteBroker, resendBrokerInvite } from "../../../../services/broker";
import { getCustomDropDown } from "../../../../services/dropdown";
import { ToastContext } from "../../../../context/toast";
//redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, setBrokerLoader } from "../../../../redux/main-view";
//ReactIcons
import { BsCheckLg, BsPaperclip } from "react-icons/bs";
//components
import BrokerFormComponent from "./BrokerFormComponent";
import SendAgreementComponent from "./SendAgreementComponent";
import { BreadCrumb } from "primereact/breadcrumb";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { loginState } from "../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../utils/Constants/rights";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import moment from "moment";

const AddNewBroker = () => {
    const location = useLocation();
    let id = null;
    if (location.state !== undefined) id = location.state?.id;

    //useHistory
    const history = useHistory();
    //use Dispatch
    const dispatch = useDispatch();

    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    // useState
    const [brokerId, setBrokerId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAgreementDialog, setShowAgreementDialog] = useState(false);
    const [responseData, setResponseData] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [brokerStatusOptions, setBrokerStatusOptions] = useState([]);
    const [currentStatusBroker, setCurrentBrokerStatus] = useState(null);
    const [showAgreement, setShowAgreement] = useState(false);
    const [dropdownStatusFetch, setDropdownStatusFetch] = useState(true);
    const [formDisabled, setFormDisabled] = useState(false);
    const [sendInvite, setSendInvite] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showInviteButton, setShowInviteButton] = useState(true);
    const [inviteButtonTitle, setInviteButtonTitle] = useState("Invite Broker");
    const [isLoading, setIsLoading] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    const validationSchema = Yup.object().shape({
        status: Yup.string().required("Business Name is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: "",
        },
    });

    const fetchBrokerById = async (id) => {
        dispatch(setBrokerLoader(true));
        let payload = {
            id: id,
        };
        let res = [];
        try {
            res = await getBrokerById(payload);
            if (res.data.status) {
                setResponseData(res.data.brokers);

                setInviteButtonTitle(!res.data.brokers.invite_send_date ? "Invite Broker" : "Resend Invite");

                let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
                let InviteSendDate = moment(res.data.brokers.invite_send_date, "YYYY-MM-DD HH:mm:ss").add(1, "days");
                let is_before = !res.data.brokers.invite_send_date ? true : InviteSendDate.isBefore(currentDateTime);

                let inviteStatus = res.data.brokers.user?.password ? false : is_before;

                setShowInviteButton(inviteStatus);

                setCurrentBrokerStatus(res.data.brokers.broker_status);
                if (res.data.brokers.agreement_date !== null) {
                    setShowAgreement(true);
                }
                formik.values.status = res.data.brokers.broker_status;
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setLoading(false);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            setLoading(false);
        }
        dispatch(setBrokerLoader(false));
        setLoading(false);
    };

    const inviteBrokerHandler = async () => {
        setIsSaving(true);
        setIsLoading(true);

        const payload = {
            id: id,
        };

        let res = [];
        if (inviteButtonTitle == "Invite Broker") {
            res = await inviteBroker(payload);
        } else {
            res = await resendBrokerInvite(payload);
        }

        if (res) {
            if (res.data.status) {
                setShowInviteButton(false);
                setSendInvite(false);
                setIsSaving(false);
                setIsLoading(false);
                setInviteButtonTitle("Resend Invite");
                toast.showMessage("Invite Broker", res.data.message, "success");
            } else {
                setSendInvite(false);
                setIsSaving(false);
                setIsLoading(false);
                toast.showMessage("Error", res.data.message, "error");
            }
        } else {
            setSendInvite(false);
            setIsSaving(false);
            setIsLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        // setLoading(true);
        fetchBrokerStatus();
        if (id !== null) {
            setBrokerId(brokerId);
            fetchBrokerById(id);
        } else {
            setLoading(false);
        }
    }, []);

    const fetchBrokerStatus = async () => {
        let payload = {
            select: ["broker_status"],
        };
        try {
            const res = await getCustomDropDown(payload);
            if (res.data.status) {
                const status = JSON.parse(res.data.dropdowns.broker_status);
                setBrokerStatusOptions(status);
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        const apiCall = async () => {
            setDisableButton(true);
            try {
                const temp = [];
                temp.push(parseInt(id));
                let payload = {
                    ids: temp,
                    status: formik.values.status,
                };
                const res = await ChangeStatus(payload);
                if (res?.data) {
                    toast.updateToast("Status");
                } else {
                    setDisableButton(false);
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setDisableButton(false);
            }
            setDisableButton(false);
        };
        if (formik.values.status !== "" && formik.values.status !== currentStatusBroker) {
            apiCall();
        }
    }, [formik.values.status]);

    useEffect(() => {
        if (formik.values.status && brokerStatusOptions.length > 0) {
            let index = brokerStatusOptions.findIndex((b) => b.value === formik.values.status);
            let status = brokerStatusOptions[index];
            if (status.name === "Approved") {
                setFormDisabled(true);
            }
        }
    }, [formik.values.status, brokerStatusOptions]);

    const items = [{ label: "Brokers", url: "/brokers" }, { label: id ? "Edit Broker" : "Add New Broker" }];
    const home = { icon: "bi bi-people" };

    return (
        <>
            {screens.broker.brokerLoader === true ? (
                <CustomLoading />
            ) : (
                <>
                    <div>
                        <div className="">
                            <BreadCrumb model={items} home={home} />
                        </div>
                    </div>
                    <div className="new_broker">
                        {/* Dialouge Section*/}
                        <Dialog
                            header="Send Agreement"
                            visible={showAgreementDialog}
                            fullScreen
                            breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                            style={{ width: "50vw" }}
                            onHide={() => setShowAgreementDialog(false)}
                        >
                            <SendAgreementComponent
                                onHide={() => {
                                    setShowAgreementDialog(false);
                                    fetchBrokerById(id);
                                }}
                                brokerId={id}
                                handleShowAgreement={() => setShowAgreement(true)}
                                callShowAgreement={true}
                                selected={responseData}
                            />
                        </Dialog>

                        <div className="card">
                            {id !== null && (
                                <>
                                    <div className="grid">
                                        <div className="md:col-6"></div>
                                        {/* Header */}
                                        <div className="md:col-6 col-12">
                                            <div className="header_display">
                                                {/* Status */}
                                                <CustomInputField
                                                    disabled={formDisabled || disableButton}
                                                    className={`broker_top_dropdown ${disableButton ? "no-drop" : " "}`}
                                                    type="dropdown"
                                                    iden="status"
                                                    formik={formik}
                                                    optionLabel="name"
                                                    optionValue="value"
                                                    options={brokerStatusOptions}
                                                    placeHolder="Select"
                                                />

                                                {/* Terms & Conditions */}
                                                {showAgreement === true && (
                                                    <div className="terms_condition_btn cursor-pointer" onClick={() => history.push(`/terms&conditions?id=${id}`)}>
                                                        <BsPaperclip />
                                                    </div>
                                                )}
                                                {/* Agreement Button */}
                                                {formik.values.status === 2 && (
                                                    <CustomAddNewButton title="Send Agreement" onClick={() => setShowAgreementDialog(true)} style={{ marginTop: ".2rem" }} />
                                                )}
                                                {/* Invite Broker Button */}
                                                {validateUserPermissions([MANAGMENT_RIGHTS.brokers.invite_broker], user?.rights) && id && formik.values.status === 2 && (
                                                    <CustomAddNewButton
                                                        title={inviteButtonTitle}
                                                        name="invite_broker"
                                                        type="button"
                                                        onClick={() => setSendInvite(true)}
                                                        isDisabled={showInviteButton == false}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {/* Broker Form */}
                            <div className="broker_add_form pt-2">
                                <BrokerFormComponent formDisabled={formDisabled} showBecomePartnerButton={false} data={responseData} dropdownfetchStatus={dropdownStatusFetch} />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {sendInvite && (
                <CustomConfirmDialog
                    header="Confirm Invite"
                    onHide={() => setSendInvite(false)}
                    type="success"
                    handler={inviteBrokerHandler}
                    icon={<BsCheckLg />}
                    isDisabled={isSaving}
                    title="Confirm"
                    firsttext="Are you sure, you want to send invite to the Broker?"
                    className="custom_confirm_dialog"
                />
            )}
        </>
    );
};

export default AddNewBroker;
