import { getDebtorsForDropdown } from "../../services/debtor";

import dotenv from "dotenv";
dotenv.config();

const appUrl = {
    // baseUrl: "http://52.230.119.123:3010/api/v1/",
    // imageBaseUrl: "http://52.230.119.123:3010/",
    // adminLogin: "auth/panelLogin",
    // forgetUser: "auth/panel/otpRequest",
    // resetPass: "panelUser/resetPassword",
    // getUserList: "panelUser",
    // addNewUser: "panelUser",
    // addUserType: "userType",
    // getUserType: "userType",
    // deleteUser: "panelUser",

    imageBaseUrl: process.env.REACT_APP_AZURE_STORAGE_URL + process.env.REACT_APP_AZURE_STORAGE_CONTAINER,

    dashboard: {
        fetch_debt_concentration: "dashboard/fetch_debt_concentration",
        fetch_analytics: "dashboard/fetch_analytics",
        fetch_invoice_analytics: "dashboard/fetch_invoice_analytics",
    },
    "user-management": {
        // AUTH
        login: "login",
        changePassword: "user/change_password",
        sendEmail: "user/send_email",
        checkTimeExpiry: "user/check_time_expiry",
        resendCode: "user/resend_code",
        verifyCode: "user/verify_code",
        setPassword: "user/save_password",
        verifyToken: "user/validate_password_url",
        loggedInUser: "user/get_user_details",
        logout: "user/logout",
        // USERS
        getUserGrid: "user/get_users",
        getUserAssign: "user/assign",
        setUserStatus: "user/set_status",
        updateUserProfile: "user/update_profile",
        updateUserProfileImage: "user/profile_pic",
        createUser: "user/save",
        deleteAttachment: "user/delete_attachment",
        // ROLES
        getAllRights: "rights/get_module_rights",
        createRole: "roles/create_default_role",
        updateRole: "roles/update_role",
        getRoleGrid: "roles/get_roles",
        getRoleRights: "roles/get_role_rights",
        getAssignedUserRoles: "roles/get_assigned_user_roles",
        deleteRole: "roles/delete_role",
        updateRoleStatus: "roles/set_status",
    },
    broker: {
        create: "broker/save",
        fetch: "broker/fetch",
        fetchById: "broker/details",
        sendAgreement: "broker/agreement/email",
        changeStatus: "broker/change_status",
        inviteClient: "broker/invite_client",
        fetchBrokersForDropdown: "broker/dropdown",
        invite: "broker/invite",
        resendInvite: "broker/resend_invite",
        fetchReferralCode: "broker/fetch_referral_code",
    },
    support: {
        create: "ticket/create",
        changeStatus: "ticket/change/status",
        getAssignToUser: "/user/assign",
        assignTicket: "ticket/assign",
        saveResponse: "/ticket/response",
        getTickets: "ticket/fetch",
        getDropdownValues: "dropdowns",
        getTicketById: "ticket/details",
        supportType: "dd/support/type",
        supportModule: "dd/support/module",
        supportStatus: "dd/support/status",
        supportPriority: "dd/support/priority",
    },
    helpers: {
        timeZone: "time_zones/fetch",
    },
    "country-code": {
        fetch: "country/fetch",
    },
    "zip-code": {
        search: "zip_codes/fetch",
    },
    states: {
        fetch: "states/fetch",
    },
    dropdowns: {
        fetch: "dropdowns",
    },
    prospect: {
        save: "prospect/save-application",
        saveAttachment: "/prospect/6th_step/attachment",
        deleteAttachment: "/prospect/6th_step/attachment/delete",
        saveBusinessName: "prospect/attachment/save",
        fetch: "prospect/fetch",
        fetchCount: "prospect/fetch/count",
        fetchById: "prospect/fetch/id",
        fetchClientAdvocates: "user/fetch/client_advocates",
        dropdown: "client/dropdown",
        changeStatus: "prospect/onboard",
        delete: "prospect/delete",
        saveDulyFilledStatus: "prospect/duly_filled_status/save",
        fetchDulyFilledStatus: "prospect/duly_filled_status/fetch",
        sendEmail: "prospect/email/draft",
        editNote: "prospect/attachment/update",
        supplierDelete: "prospect/supplier/delete",
        ownerDelete: "prospect/owner/delete",
        fetchDraft: "prospect/fetch/draft",
        saveUnderWritingDocument: "prospect/save_underwriting_documents",
    },
    term_sheet: {
        owner: "prospect/owner/dropdown",
        save: "prospect/term_sheet/save",
        detail: "prospect/term_sheet/detail",
        fetchWire: "prospect/term_sheet/get_wire_fee",
        signedTermSheet: "prospect/term_sheet/term_sheet_signed",
        signedTermSheetStatus: "prospect/term_sheet/term_sheet_signed_status",
        signedTermSheetDetail: "prospect/term_sheet/term_sheet_signed_detail",
    },
    chat: {
        fetch: "chat/fetch_chats",
        fetchConversation: "chat/fetch_conversation",
        save: "chat/save",
    },
    client: {
        fetchAll: "client/fetch",
        fetchClientUsers: "client/fetch/client_users",
        fetchDropdown: "client/dropdown",
        invite: "client/invite",
        resendClientInvite: "client/resend_invite",
        fetchBusinessName: "prospect/attachment/fetch",
        fetchTermSheetDetail: "prospect/term_sheet/detail",
        fetchingDebtor: "prospect/debtor/fetch ",
        clientAllInformation: "client/all_information",
        saveTerminationDate: "client/termination/save",
        clientAllInvoicesInformation: "client/all_invoices_information",
        saveUnderwritingAttachments: "client/underwriting_documents/save",
        deleteUnderwritingAttachments: "client/underwriting_documents/delete",
        totalClientUnderwritingDocuments: "client/underwriting_documents/total_client_documents",
        fetchAllClientUWDocs: "client/underwriting_documents/fetch",
        fetchUniqueAttachmentType: "client/underwriting_documents/fetch_unique_uw_type_files",
        fetchw9Form: "prospect/w9_form/fetch",
        sendw9Form: "prospect/w9_form/send",
        fetch8821Form: "prospect/form_8821/fetch",
        send8821Form: "prospect/form_8821/send",
        fetchContract: "prospect/contract/fetch",
        saveContract: "prospect/contract/save",
        saveAttachmentw9Form: "prospect/w9_form/saveW9Attachment",
        countAttachmentw9Form: "prospect/w9_form/countW9Attachment",
        fetchAttachmentw9Form: "prospect/w9_form/fetchW9Attachment",
        deleteAttachmentw9Form: "prospect/w9_form/delete",
        saveAttachment8821Form: "prospect/form_8821/save8821Attachment",
        countAttachment8821Form: "prospect/form_8821/count8821Attachment",
        fetchAttachment8821Form: "prospect/form_8821/fetch8821Attachment",
        deleteAttachment8821Form: "prospect/form_8821/delete",
    },
    debtor: {
        fetch: "debtor/fetch",
        fetchDebtorsForDropdown: "debtor/dropdown",
        save: "debtor/save",
        poc: "debtor/poc/fetch",
        fetchById: "debtor/edit",
        status: "debtor/status",
        delete: "debtor/delete",
        prospectDebtorContact: "debtor/fetchAll",
        exists: "debtor/exists",
        invite: "debtor/invite",
        resendInvite: "debtor/resendInvite",
    },
    invoice: {
        fetch: "invoice/fetch",
        create: "invoice/save",
        invoiceClientsDropdown: "invoice/clients",
        invoiceDetails: "invoice/detail",
        status: "invoice/change_status",
        moveToSchedule: "invoice/move_to_schedule",
        prospectSchedules: "invoice/fetch/prospect/schedules",
        delete: "invoice/delete",
        uploadAttachment: "invoice/upload-attachment",
        deleteAttachment: "invoice/delete-attachment",
        adjustHoldAccount: "invoice/hold_account/adjust",
        adjustHoldAccountInCollection: "invoice/hold_account/adjust-in-collection",
    },
    "under-writing": {
        getAllUnderwritings: "under_writing/fetch",
        getClientsDropdown: "client/dropdown",
        createVertical: "under_writing/save",
        updateVertical: "under_writing/save",
        deleteVertical: "under_writing/delete",
        addTask: "under_writing/task/save",
        updateTask: "under_writing/task/save",
        deleteTask: "under_writing/task/delete",
    },
    "schedule-assignment": {
        fetch: "schedule/fetch",
        save: "schedule/save",
        scheduleNo: "schedule/schedule_no",
        checkChargeBacks: "invoice/check-cb-invoices",
        verifySchedule: "schedule/verify",
        revertSchedule: "schedule/revert",
        schedulesDetail: "schedule/detail",
        schedulesSofDetail: "schedule/sof/detail",
        scheduleDownload: "schedule/download",
        scheduleInvoices: "schedule/invoices",
        prospectScheduleNo: "schedule/prospect/schedule_no",
        schedulePayment: "schedule/payments",
        scheduleDelete: "schedule/delete",
        scheduleInvoiceDelete: "invoice/delete",
        checkUniqueInvoiceNo: "invoice/check-unique",
        pendingDownload: "/pending/download",
        fundedScheduleReversal: "schedule/funded_schedule_reversal",
    },
    payment: {
        save: "payment/save",
        fetch: "payment/fetch",
    },
    annoucements: {
        getAllAnnoucements: "announcement/fetch",
        createAnnoucement: "announcement/save",
        editAnnoucement: "announcement/save",
        deleteAnnoucement: "announcement/delete",
    },
    cr: {
        fetch: "cr/fetch",
        save: "cr/save",
        changeStatus: "cr/change-status",
        fetchCL: "cr/fetch/credit-limit",
    },
    documents: {
        count: "document/count",
        fetch: "document/fetch",
    },
    collections: {
        fetch: "collection/fetch",
        fetchCollectionInvoices: "collection/fetch-collectable-invoices",
        saveCollection: "collection/save",
        debtorProspectUC: "invoice/debtor-prospect/uc",
        saveNote: "collection/note/save",
        fetchNotes: "collection/note/fetch",
        fetchCollections: "collection/invoices/fetch",
        saveUCNonInvoice: "collection/unapplied_cash/save",
        fetchUCNonInvoices: "collection/unapplied_cash/fetch",
        fetchReturnUC: "collection/unapplied_cash/fetch_return_uc",
        saveReturnUC: "collection/unapplied_cash/return_uc",
    },
    notifications: {
        fetch: "notification/fetch",
        fetchAll: "notification/fetch/all",
    },
    cb: {
        fetch: "cb/fetch",
        adjust: "cb/adjust",
        sum: "cb/sum",
    },
    reports: {
        fetch: "reports/fetch",
        generate: "reports/generate",
    },
    reserves: {
        fetch: "reserve/fetch",
        save: "reserve/save",
        show: "reserve/show",
        cash: "reserve/cash/fetch",
        batch: "reserve/batch/fetch",
        previousReserves: "reserve/fetch_previous_prospect_reserves",
        availableEscrows: "reserve/fetch_prospect_available_escrows",
        percentAr: "reserve/fetch_ten_percent_ar",
        afterReserveDeduction: "chargeback/reserves_deducted/fetch",
        adjustedTaxPerDay: "chargeback/adjusted_tax_per_day/fetch",
    },
    policy: {
        fetch: "policy/fetch",
        save: "policy/save",
        delete: "policy/delete",
        deleteAttachment: "policy/delete-attachment",
    },
    industry: {
        fetchIndustriesForDropdown: "industry/dropdown",
    },
    "general-ledger": {
        fetch: "general_ledger/fetch",
        download: "general_ledger/download",
    },
};

export default appUrl;
