import React, { useState } from "react";
// components
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

//Styles
import { dateFormatMDY } from "../../../utils/HelperFuctions/dateFormat";

const Header = (props) => {
    const {
        setSelectedFromDate,
        setSelectedToDate,
        dropdownClients,
        dropdownDebtors,
        selectedClient,
        selectedDebtors,
        onClientChange,
        onDebtorsChange,
        selectedFromDate,
        selectedToDate,
        clientLoading,
        debtorLoading,
    } = props;

    return (
        <>
            <div className="col-12 md:col-3">
                <div className="">
                    <Calendar
                        id="fromDate"
                        showIcon
                        placeholder="From"
                        mask="99/99/9999"
                        onChange={(e) => {
                            if (e.value === null || dateFormatMDY(e.value) === dateFormatMDY(selectedFromDate)) {
                                return;
                            } else {
                                setSelectedFromDate(e.value);
                                e.preventDefault();
                            }
                        }}
                        value={selectedFromDate}
                        maxDate={selectedToDate}
                        monthNavigator
                        yearNavigator
                        yearRange="1945:2050"
                    />
                </div>
            </div>
            <div className="col-12 md:col-3">
                <div className="">
                    <Calendar
                        id="toDate"
                        showIcon
                        placeholder="To"
                        mask="99/99/9999"
                        onChange={(e) => {
                            if (e.value === null || dateFormatMDY(e.value) === dateFormatMDY(selectedToDate)) {
                                return;
                            } else {
                                setSelectedToDate(e.value);
                                e.preventDefault();
                            }
                        }}
                        value={selectedToDate}
                        disabled={clientLoading}
                        minDate={selectedFromDate}
                        maxDate={new Date()}
                        monthNavigator
                        yearNavigator
                        yearRange="1945:2050"
                    />
                </div>
            </div>
            <div className="col-12 md:col-3">
                <div className="">
                    <Dropdown
                        value={selectedClient}
                        filter
                        filterBy="company_business_name"
                        options={dropdownClients}
                        onChange={onClientChange}
                        placeholder="Select Client"
                        optionLabel="company_business_name"
                        optionValue="id"
                    />
                </div>
            </div>
            <div className="col-12 md:col-3">
                <div className="">
                    {/* {" "} */}
                    <Dropdown
                        value={selectedDebtors}
                        filter
                        filterBy="legal_name"
                        options={dropdownDebtors}
                        onChange={onDebtorsChange}
                        placeholder="Select Debtor"
                        optionLabel="legal_name"
                        optionValue="id"
                        disabled={debtorLoading || selectedClient == 0}
                    />
                </div>
            </div>
        </>
    );
};

export default Header;
