export function checkPathname(value) {
    if (value) {
        if (value === "/registernow" || value === "/frontlineregisternow") {
            return true;
        } else {
            return false;
        }
    }
}

export function checkBrokerPathname(value) {
    if (value) {
        if (value === "/brokerdetails") {
            return true;
        } else {
            return false;
        }
    }
}
