import React, { useContext } from "react";
//styles
// import "./chargeback.scss";
import "../../chargeback.scss";
//ui-components
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";
import KebabMenuComponent from "../../../../../../../ui/KebabMenu/KebabMenuComponent";
//global
import { CHARGEBACK_ADJUSTMENT_GRID, CHARGEBACK_GRID_HEADERS, CHARGE_BACKE_MENU } from "../../../../../../../utils/Constants/global";
//react-dom
import { useState } from "react";
import { useEffect } from "react";
//components
import { Dialog } from "primereact/dialog";
import ChargebackAdjustmentModal from "../../Components/ChargebackAdjustmentModal";
import { Checkbox } from "primereact/checkbox";
import { sumChargebacks } from "../../../../../../../services/chargeback";
import { ToastContext } from "../../../../../../../context/toast";
import { currencyFormatUSD } from "../../../../../../../utils/HelperFuctions/checkAmount";
import { useDispatch, useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { mainViewState, onChargebackFormDirty } from "../../../../../../../redux/main-view";
import Legends from "../../../../../../../ui/Legends/Legends";

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedChargebacks, setSelectedChargebacks, fetchChargebacks } = props;
    const toast = useContext(ToastContext);
    const [onSelectionColumn] = useState([]);
    const [chargebackModal, setChargebackModal] = useState(false);
    const [totalChargebackSettled, setTotalChargebackSettled] = useState("");
    const [totalChargebackPending, setTotalChargebackPending] = useState("");
    const [kebab_menu_items, setKebabMenuItems] = useState([]);
    const [isOpening, setIsOpening] = useState(false);
    
    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedChargebacks.length !== 0) {
            setChargebackModal(true);
        }
    };

    const onCloseChargebackAdjustModal = () => {
        if (screens.cb.isFormDirty) {
            setIsOpening(true);
        } else {
            dispatch(onChargebackFormDirty(false));
            onHideChargebackAdjustModal();
        }
    };

    const onHideChargebackAdjustModal = (refresh = false) => {
        
        setChargebackModal(false);
        setSelectedChargebacks([]);
        if (refresh) {
            fetchChargebacks();
        }
    };

    const sumCb = async () => {
        try {
            let res = await sumChargebacks();
            if (res?.data && res.data.status) {
                setTotalChargebackSettled(res.data.total_chargebacks_settled);
                setTotalChargebackPending(res.data.total_chargebacks_pending);
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        CHARGEBACK_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        const _kebab_menu = CHARGE_BACKE_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });
        setKebabMenuItems(_kebab_menu);
    }, [user]);

    useEffect(() => {
        sumCb();
    }, []);

    return (
        <>
            <Dialog
                blockScroll={true}
                onHide={onCloseChargebackAdjustModal}
                visible={chargebackModal}
                header="Adjust Chargeback(s)"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "60vw" }}
            >
                <ChargebackAdjustmentModal
                    onHide={onHideChargebackAdjustModal}
                    selectedChargebacks={selectedChargebacks}
                    setSelectedChargebacks={setSelectedChargebacks}
                    selectedColumns={CHARGEBACK_ADJUSTMENT_GRID}
                    isOpening={isOpening}
                    setIsOpening={setIsOpening}
                />
            </Dialog>

            <div className="grid">
                <div className="col-7 md:col-9">
                    <h4 className="pl-2">
                        <b>Chargebacks</b>
                    </h4>
                </div>
                <div className="col-5 md:col-3 text-right">
                    <p className="header_summary">
                        <b>Chargebacks Settled :</b> <span>{totalChargebackSettled ? currencyFormatUSD(totalChargebackSettled) : currencyFormatUSD(0)}</span>
                    </p>
                    <p className="header_summary">
                        <b>Chargeback Pending :</b> <span>{totalChargebackPending ? currencyFormatUSD(totalChargebackPending) : currencyFormatUSD(0)}</span>
                    </p>
                </div>

                <div className="md:col-7 col-12">
                    <div className="flex mt-4 pl-2">
                        <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include fully settled / terminated chargebacks
                        </label>
                        {showAll === 1 && (
                            <>
                                <div className="mt-1 ml-3">
                                    <Legends classes="square-legend-delete" title="Deleted" />
                                </div>
                                <div className="mt-1 ml-2">
                                    <Legends classes="square-legend-terminate" title="Terminated" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="md:col-5 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent
                            type="text"
                            value={keyword}
                            setKeyword={setKeyword}
                            // handleInput={(e) => setKeyword(e.target.value)}
                            placeholder="Search by invoice no, schedule no, client name, debtor name"
                        />
                        <div className="ml-2">
                            <ColumnFilterComponent items={CHARGEBACK_GRID_HEADERS} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">
                            {/* <KebabMenuComponent /> */}
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedChargebacks.length === 0} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterBar;
