import React, { useContext, useState } from "react";
// Fomik
import * as Yup from "yup";
import { useFormik } from "formik";
// Context & APIs
import { addUnderwritingTask } from "../../../../services/under-writing";
import { ToastContext } from "../../../../context/toast";
// UI Compoinents
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../utils/HelperFuctions/cleanData";
import { useDispatch } from "react-redux";
import { onUnderWritingFormDirty } from "../../../../redux/main-view";

const AddTaskComponent = ({ onHide, verticalId, underWritings }) => {
    const toastContext = useContext(ToastContext);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        tasktitle: Yup.string().required("Task Title is required").trim(),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tasktitle: "",
        },
        onSubmit: async (data) => {
            let vertical = underWritings.find((element) => element.id == verticalId);
            let _taskExists = vertical.tasks.filter((item) => item.title === data.tasktitle);

            if (_taskExists.length !== 0) {
                toastContext.showMessage("Validation Failed", "Task Title should be unique.", "error");
            } else {
                try {
                    setIsSaving(true);
                    let dto = {
                        under_writing_id: verticalId,
                        title: data.tasktitle.trim(),
                        mandatory: true,
                        status: 0,
                    };
                    let resp = await addUnderwritingTask(dto);
                    setIsSaving(false);
                    if (resp.data.status) {
                        toastContext.createdToast("Task");
                        onHide(true, "add task");
                    }
                    return;
                } catch (error) {
                    setIsSaving(false);
                }
            }
        },
    });

    const inputHanlder = (e) => {
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);
        const finalValue = toTitleCaseWhileTyping(cleanValue);
        formik.setFieldValue(name, finalValue);
        dispatch(onUnderWritingFormDirty(true));
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField iden="tasktitle" label="Task Title" mandatory="*" placeHolder="Enter Task Title" formik={formik} maxLength={30} onChange={(e) => inputHanlder(e)} />
                    </div>

                    <div className="col-12 text-right pt-5">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => onHide(false, "add task")} />
                        <CustomAddNewButton title="Save" type="submit" isDisabled={isSaving} />
                    </div>
                </div>
            </form>
        </>
    );
};
export default AddTaskComponent;
