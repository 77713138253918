import React, { useEffect, useState } from "react";

// Redux
import { mainViewState } from "../../../redux/main-view";
import { useSelector } from "react-redux";
import { loginState } from "../../../redux/login";

// Componets
import FilterBar from "./GridView/FilterBar";
import Body from "./GridView/Body";

// Constants
import { SUPPORT_GRID_HEADERS } from "../../../utils/Constants/global";

// API'S
import { getAllTickets } from "../../../services/support";

//Styles
import "./support.scss";

const Support = () => {
    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);
    // State
    const [keyword, setKeyword] = useState("");
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(SUPPORT_GRID_HEADERS);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);

    const [kebabMenuOption, setKebabMenuOption] = useState({
        change_status: false,
        assign_tiicket: false,
    });

    // PAGINATION
    // States
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);

    // Api Handler
    const getTickets = async () => {
        setLoading(true);
        const form = new FormData();
        form.append("include_close", showAll);
        form.append("per_page", pageSize);
        form.append("page", currentPage);
        form.append("search_text", keyword === null ? "" : keyword);

        if (sortField) {
            if (sortField === "createdBy") {
                form.append("sort_by", "cu.first_name");
            } else if (sortField === "assignedto") {
                form.append("sort_by", "au.first_name");
            } else {
                form.append("sort_by", sortField);
            }
        }
        if (sortOrder) {
            form.append("sort_desc", sortOrder === 1 ? "asc" : "desc");
        }
        const res = await getAllTickets(form);
        if (res?.data) {
            setSelectedHeaders(res.data.tickets.data);
            setPagination({
                ...pagination,
                totalRecords: res.data.tickets.total,
                totalPages: res.data.tickets.last_page,
                isLastPage: res.data.tickets.last_page,
            });
        }
        setLoading(false);
    };

    //UseEffect
    // useEffect(() => {
    //     if (keyword) {
    //         const delayDebounceFn = setTimeout(() => {
    //             setCurrentPage(1);
    //             setPage(1);
    //             getTickets(1);
    //         }, 350);
    //         return () => clearTimeout(delayDebounceFn);
    //     } else {
    //         getTickets();
    //     }
    // }, [screens.support.isMutated, showAll, keyword, sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        getTickets();
    }, [screens.support.isMutated, showAll, sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getTickets();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    return (
        <>
            <FilterBar
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                setKebabMenuOption={setKebabMenuOption}
                selectedUsers={selectedUsers}
                user={user}
            />
            <Body
                selectedHeaders={selectedHeaders}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                setPage={setPage}
                page={page}
                user={user}
            />
        </>
    );
};

export default Support;
