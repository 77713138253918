import React, { useRef } from "react";
//styles
import "./customunderwritingverticaldots.scss";
//components
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { BsThreeDotsVertical } from "react-icons/bs";

const CustomUnderWritingVerticalDots = (props) => {
    const { items, handleMenuOpen, isDisabled = false, btnclr = false, showDots = false } = props;
    // const { items, handleMenuOpen, isDisabled = false, btnclr = false } = props;
    const menu = useRef(null);

    const renderMenuItem = (item, index) => {
        const isLastItem = index === items.length - 1;
        const menuClass = isLastItem ? "last_kebab_menu_item" : "kebab_menu_item";
        return (
            <div className={`pt-3 ${menuClass} ${item.isDisabled ? "disabled-kebab-menu" : "cursor-pointer"} `} onClick={() => (item.isDisabled ? null : handleChange(item.status))}>
                <p className="pl-3 pb-3 kebab_menu_item_font">{item.title}</p>
            </div>
        );
    };
    const handleChange = (val) => {
        handleMenuOpen(val);
        menu.current.hide();
    };
    const menuItems = items.map((item, index) => {
        return {
            label: item.title,
            template: () => renderMenuItem(item, index),
        };
    });

    return (
        <div className="underWritting">
            <div className="kebab_menu">
                <Menu model={menuItems} popup ref={menu} id="popup_menu" className="kebab_menu_border" />
                <Button
                    className={`${showDots ? "dots_visibility" : btnclr ? "taskbodybutton " : "taskheaderbutton"}`}
                    icon={<BsThreeDotsVertical />}
                    onClick={(event) => menu.current.toggle(event)}
                    aria-controls="popup_menu"
                    aria-haspopup
                    disabled={isDisabled}
                ></Button>
            </div>
        </div>
    );
};

export default CustomUnderWritingVerticalDots;
