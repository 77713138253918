import React from "react";
import appUrl from "../../../utils/Constants/appUrl";

const PreviewProspectDocumentModal = (props) => {
    const { senddocumenturl } = props;
    const previewURL = `https://docs.google.com/viewer?url=${encodeURIComponent(appUrl.imageBaseUrl + senddocumenturl)}&embedded=true`;

    return <div></div>;
};

export default PreviewProspectDocumentModal;
