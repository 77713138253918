import moment from "moment";

export function getDateFormat(date) {
    return moment(date).format("MM/DD/YYYY");
}

export function dateFormatForInputFields(date) {
    return moment(date).format("YYYY-MM-DD");
}

export function dateTimeFormatForInputFields(date) {
    return moment(date).format("MM/DD/YYYY HH:mm");
}

export function dateFormatMDY(date) {
    return moment(date).format("m/d/Y");
}
