import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Context
import { ToastContext } from "../../../../../../../context/toast";
// Icons
import { BsCheck, BsPencil } from "react-icons/bs";
// Prime React
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onClientDataChange, onClientFinancialFormDirty, onFormDirty } from "../../../../../../../redux/main-view";
// UI Components
import CustomTextField from "../../../../../../../ui/CustomTextField/CustomTextField";
import CustomInputField from "../../../../../../../ui/CustomInputField/CustomInputField";
import ZipCodesMenuComponent from "../../../../../../../ui/ZipCodesMenu/ZipCodesMenuComponent";
import CustomDropdownField from "../../../../../../../ui/CustomDropdownField/CustomDropdownField";
// Constants
import { BUSINESS_LOAN_OUTSTANDING_STATUS, FACTORED_BEFORE_STATUS } from "../../../../../../../utils/Constants/global";
// Helper Function
import { AmountCheck } from "../../../../../../../utils/HelperFuctions/checkAmount";
import formDirtyHandler from "../../../../../../../utils/HelperFuctions/clientHelper";
import { convertingDateToUTC } from "../../../../../../../utils/HelperFuctions/convertingDateToUTC";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../../../../utils/HelperFuctions/cleanData";
// API'S
import { getZipCode } from "../../../../../../../services/zip-code";
import { saveProspect } from "../../../../../../../services/prospect";
import { loginState } from "../../../../../../../redux/login";
const validationSchema = Yup.object().shape({
    bank_name: Yup.string().required("Bank name is required"),
    bank_account_number: Yup.string().required("Account no is required"),
    bank_routing_number: Yup.string().required("Routing no is required"),
    bank_zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
    monthly_factoring_volume: Yup.string().required("Expected Monthly Factoring Volume is required"),
    requested_funding_date: Yup.string().required("Requested First Funding Date is required"),
    funding_amount: Yup.string().required("Required funding Amount is required"),
    bank_state: Yup.string().required("State is required"),
    bank_city: Yup.string().required("City is required"),
});

const FinancialInformation = () => {
    const { user } = useSelector(loginState);

    //Dispatch
    const dispatch = useDispatch();
    // Redux
    const { screens } = useSelector(mainViewState);
    // Context
    const toast = useContext(ToastContext);
    // State
    const [zipCode, setZipCode] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
    });
    const [isZipCodeSearch, setIsZipSearch] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isCalenderVisibleAOD, setIsCalenderVisibleAOD] = useState(false);
    const [isCalenderVisible, setIsCalenderVisible] = useState(false);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bank_name: "",
            bank_account_number: "",
            bank_routing_number: "",
            bank_zip: "",
            monthly_factoring_volume: "",
            requested_funding_date: "",
            funding_amount: "",
            bank_state: "",
            bank_city: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                data = {
                    ...data,
                    step: "6",
                    id: screens.client.data.id,
                    bank_country_phone_code: data.bank_country_phone_code ? data.bank_country_phone_code.phone_code : "+1",
                };
                if (screens.client.isFinancialFormDirty) {
                    let res = await saveProspect(data);
                    if (res) {
                        if (res.data.status) {
                            formDirtyHandler();
                            setIsDisabled(true);
                            dispatch(onFormDirty(false));
                            dispatch(onClientDataChange(res.data.prospect));
                            dispatch(onClientFinancialFormDirty(false));
                        } else {
                            toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                        }
                    } else {
                        toast.showMessage("Error", "Server Error", "error");
                        setIsSaving(false);
                    }
                } else {
                    setIsDisabled(true);
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
            }
        },
    });

    // Handlers
    const inputFieldDataCleaningHandler = (name, value, e) => {
        if (name === "bank_name" || name === "bank_officer") {
            return toTitleCaseWhileTyping(value);
        } else if (name === "requested_funding_date" || name === "account_opening_date") {
            if (e.originalEvent.key === "Enter" || e.originalEvent.type === "blur") {
                // Close the calendar modal
                setIsCalenderVisible(false);
                setIsCalenderVisibleAOD(false);
                const nextInput = document.getElementById(name === "account_opening_date" ? "bank_routing_number" : "funding_amount");
                if (nextInput) {
                    // nextInput.focus();
                }
                // }
            }
            if (value) {
                return convertingDateToUTC(value);
            } else {
                return "";
            }
        } else if (name === "bank_balance" || name === "monthly_factoring_volume" || name === "receivables_outstanding" || name === "funding_amount") {
            return AmountCheck(value);
        } else if (name === "bank_country_phone_code" || name === "business_loan_outstanding_status") {
            return value;
        } else {
            return textExtraSpaceRemoval(value);
        }
    };
    const inputHandler = (e) => {
        dispatch(onFormDirty(true));
        dispatch(onClientFinancialFormDirty(true));
        const { name, value } = e.target;

        if (name === "factored_before_status") {
            if (value === 0) {
                formik.setFieldValue("factored_before_name", "");
                formik.setFieldValue("factored_before_status", value);
            } else {
                formik.setFieldValue(name, value);
            }
        } else {
            formik.setFieldValue(name, inputFieldDataCleaningHandler(name, value, e));
        }
    };

    const handleCurrencyChange = (event, name) => {
        dispatch(onFormDirty(true));
        dispatch(onClientFinancialFormDirty(true));
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRcordNotFoundMsg("");
        formik.values.bank_zip = zip.zip;
        formik.values.bank_state = zip.full_state;
        formik.values.bank_city = zip.city;
        setZipCode([]);
    };

    // API'S Handler
    const fetchZipCodeData = (value) => {
        const payload = {
            zip: value,
        };
        getZipCode(payload).then((res) => {
            setZipCode(res.data.codes);
            if (res.data.codes.length == 0) {
                setZipCode([]);
                setRcordNotFoundMsg("No Record Available");
            }
        });
    };

    // UseEffect
    useEffect(() => {
        if (formik.values.bank_zip && formik.values.bank_zip?.length >= 4 && isZipCodeSearch) {
            fetchZipCodeData(formik.values.bank_zip);
        } else if (formik.values.bank_zip?.length < 4) {
            setZipCode([]);
            setIsZipSearch(true);
            formik.values.bank_state = "";
            formik.values.bank_city = "";
        }
    }, [formik.values.bank_zip]);

    useEffect(() => {
        if (screens.client.data) {
            setIsZipSearch(false);
            const result = screens.client.data;
            formik.setFieldValue("bank_name", result?.bank_name);
            formik.setFieldValue("bank_account_number", result?.bank_account_number);
            formik.setFieldValue("account_opening_date", moment(result?.account_opening_date).format("YYYY-MM-DD"));
            formik.setFieldValue("bank_routing_number", result?.bank_routing_number);
            formik.setFieldValue("bank_officer", result?.bank_officer);
            formik.setFieldValue("bank_balance", result?.bank_balance);
            formik.setFieldValue("bank_zip", result?.bank_zip);
            formik.setFieldValue("bank_state", result?.bank_state);
            formik.setFieldValue("bank_city", result?.bank_city);
            formik.setFieldValue("bank_phone", result?.bank_phone);
            formik.setFieldValue("business_loan_outstanding_status", result?.business_loan_outstanding_status === null ? "" : result?.business_loan_outstanding_status ? 1 : 0);
            formik.setFieldValue("monthly_factoring_volume", result?.monthly_factoring_volume);
            formik.setFieldValue("receivables_outstanding", result?.receivables_outstanding);
            formik.setFieldValue("requested_funding_date", moment(result?.requested_funding_date).format("YYYY-MM-DD"));
            formik.setFieldValue("funding_amount", result?.funding_amount);
            formik.setFieldValue("factored_before_status", result?.factored_before_status === null ? "" : result?.factored_before_status ? 1 : 0);
            formik.setFieldValue("factored_before_name", result?.factored_before_name);
            setPhoneCodesPlaceholders({
                phone_code: result?.bank_country_phone_code,
            });
        }
    }, []);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCode(screens.prospect.countryCodes);
        }
    }, [screens.prospect.countryCodes]);

    return (
        <>
            <div className="main_brdr_class bank_info">
                <form>
                    <div className="grid">
                        {/* Header */}
                        <div className="col-7">
                            <div className="">
                                <h5>
                                    <b>Financial Information</b>
                                </h5>
                            </div>
                        </div>
                        <div className="col-5 text-right">
                            {isDisabled ? (
                                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit_icon" icon={<BsPencil />} type="button" onClick={() => setIsDisabled(false)} />
                            ) : (
                                <Button
                                    tooltip="Save"
                                    tooltipOptions={{ position: "top" }}
                                    className={isSaving ? "check_tick_icon_disabled" : "check_tick_icon"}
                                    icon={<BsCheck />}
                                    disabled={isSaving}
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                />
                            )}
                        </div>

                        {/* Body */}
                    </div>
                    <div className="business_scrollbar">
                        <div className="grid">
                            <div className="col-12 pt-3">
                                <h5>
                                    <b style={{ borderBottom: "1px solid" }}>Bank Information</b>
                                </h5>
                            </div>
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Bank Name<span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="bank_name"
                                    label="Bank Name "
                                    mandatory="*"
                                    maxLength={35}
                                    formik={formik}
                                    keyfilter={/^[a-zA-Z\s]+$/}
                                    placeHolder="Enter Bank Name"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Account No <span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="bank_account_number"
                                    label="Account No"
                                    mandatory="*"
                                    maxLength={25}
                                    keyfilter="alphanum"
                                    formik={formik}
                                    placeHolder="Enter Account No"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled ? true : !user?.sensitive_information_disclosure ? true : false}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Account Opening Date</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <Calendar
                                    id="account_opening_date"
                                    name="account_opening_date"
                                    value={new Date(formik.values.account_opening_date)}
                                    placeholder="mm/dd/yyyy"
                                    mask="99/99/9999"
                                    showIcon
                                    maxDate={new Date()}
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                    className="custom_border_none"
                                    onVisibleChange={(e) => setIsCalenderVisibleAOD(e.visible)}
                                    visible={isCalenderVisibleAOD}
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Routing No <span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="bank_routing_number"
                                    label="Routing No"
                                    mandatory="*"
                                    maxLength={25}
                                    keyfilter="int"
                                    formik={formik}
                                    placeHolder="Enter Routing No"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>

                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Bank Officer Name</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="bank_officer"
                                    label="Bank Officer Name "
                                    maxLength={30}
                                    formik={formik}
                                    placeHolder="Enter Bank Officer Name"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                    keyfilter={/^[a-zA-Z\s]+$/}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Current Available Balance</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="bank_balance"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "bank_balance")}
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Zip Code <span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="zip_menu_position" style={{ position: "relative" }}>
                                    <CustomTextField
                                        className=""
                                        iden="bank_zip"
                                        label="Zip Code"
                                        mandatory="*"
                                        keyfilter="int"
                                        formik={formik}
                                        placeHolder="Search..."
                                        maxLength={5}
                                        onChange={(e) => inputHandler(e)}
                                        disabled={isDisabled}
                                    />
                                    <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                    <small className="p-error">{recordNotFoundMsg}</small>
                                </div>
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>State</b>
                                        <span className="clr_red">*</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField disabled iden="bank_state" label="State" formik={formik} placeHolder="" />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>City</b>
                                        <span className="clr_red">*</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField disabled iden="bank_city" label="City" formik={formik} placeHolder="" />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Branch Phone</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="border_div">
                                    <Dropdown
                                        id="bank_country_phone_code"
                                        name="bank_country_phone_code"
                                        value={formik.values.bank_country_phone_code}
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_country"
                                        options={countryCode}
                                        optionLabel="phone_code"
                                        placeholder={phoneCodesPlaceholders.phone_code}
                                        filter
                                        filterBy="phone_code"
                                        emptyMessage="No results found"
                                        disabled={isDisabled}
                                    />

                                    <InputMask
                                        name="bank_phone"
                                        id="bank_phone"
                                        mask="999-999-9999"
                                        value={formik.values.bank_phone}
                                        placeholder="999-999-9999"
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_phone"
                                        disabled={isDisabled}
                                        autoComplete="off"
                                    ></InputMask>
                                </div>
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Loan Outstanding?</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomDropdownField
                                    iden="business_loan_outstanding_status"
                                    label="Loan Outstanding?"
                                    formik={formik}
                                    options={BUSINESS_LOAN_OUTSTANDING_STATUS}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                            <div className="col-12 pt-3 pb-2">
                                <h5>
                                    <b style={{ borderBottom: "1px solid" }}>Miscellaneous Information</b>
                                </h5>
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Expected Monthly Factoring Volume <span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="monthly_factoring_volume"
                                    label="Expected Monthly Factoring Volume"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "monthly_factoring_volume")}
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Current Receivable Outstanding?</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="receivables_outstanding"
                                    label="Current Receivable Outstanding?"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "receivables_outstanding")}
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Requested First Funding Date<span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <Calendar
                                    id="requested_funding_date"
                                    name="requested_funding_date"
                                    value={new Date(formik.values.requested_funding_date)}
                                    placeholder="mm/dd/yyyy"
                                    mask="99/99/9999"
                                    showIcon
                                    maxDate={new Date()}
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                    className="custom_border_none"
                                    onVisibleChange={(e) => setIsCalenderVisible(e.visible)}
                                    visible={isCalenderVisible}
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Required Funding Amount<span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="funding_amount"
                                    mandatory="*"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "funding_amount")}
                                    min={0}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Have you Factored Before?</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomDropdownField
                                    iden="factored_before_status"
                                    label="Have You Factored Before?"
                                    formik={formik}
                                    options={FACTORED_BEFORE_STATUS}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabled}
                                />
                            </div>
                            {/*  */}
                            <div className="col-12 md:col-6">
                                <div>
                                    <p className="pt-3">
                                        <b>Factored with Whom?</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomTextField
                                    iden="factored_before_name"
                                    label="Factored with Whom?"
                                    formik={formik}
                                    placeHolder=""
                                    disabled={isDisabled ? true : formik.values.factored_before_status === 1 ? false : true}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FinancialInformation;
