import React, { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { fetchPayment } from "../../../../../../services/payment";
import { ToastContext } from "../../../../../../context/toast";
import CustomModalSpinner from "../../../../../../ui/CustomModalSpinner/CustomModalSpinner";

const DeleteScheduleModal = (props) => {
    const { selectedSchedules } = props;

    //states
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([]);

    //toast message
    const toast = useContext(ToastContext);

    //ref
    const dt = useRef(null);

    const fetchSchedulePayment = async () => {
        setLoading(true);
        let payload = {
            schedule_id: selectedSchedules[0].id,
        };
        try {
            const res = await fetchPayment(payload);
            if (res.data.status) {
                setPayments(res.data.payments);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSchedulePayment();
    }, []);

    return (
        <>
            {loading ? (
                <CustomModalSpinner top={10} />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 pt-3">
                            <DataTable ref={dt} value={payments} rows={5} responsiveLayout="scroll" emptyMessage="No record available.">
                                <Column field="schedule_fund_date" header="Schedule Fund Date"></Column>
                                <Column field="amount" header="Amount"></Column>
                                <Column field="transaction_number" header="Transaction No"></Column>
                                <Column field="payment_type_text" header="Payment Type"></Column>
                                <Column field="wire_fee" header="Transaction Fee"></Column>
                                <Column field="paid_amount" header="Funded Amount"></Column>
                            </DataTable>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default DeleteScheduleModal;
