import React, { useRef, useState } from "react";

// Prime React
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
//Components
import CustomLoading from "../../../../../../../ui/CustomSpinner/custom_spinner";
// Helper Function
import { getScheduleColumnBody } from "../../../../../../../utils/HelperFuctions/scheduleHelper";
import { PaginatorTemplate } from "../../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import DetailsOfFunding from "../../Components/DetailsOfFunding";
import { Dialog } from "primereact/dialog";

// Utils
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";

// Components
const Body = (props) => {
    const {
        selectedHeaders,
        selectedSchedules,
        setSelectedSchedules,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        setPage,
        page,
    } = props;

    //Use ref
    const ref = useRef(null);

    // Redux
    const { user } = useSelector(loginState);

    // States
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [detailDialogVisible, setDetailDialogVisible] = useState(false);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    // Multiple Values Selection
    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedSchedules(event.value);
        }
    };

    // Edit Scenario
    const handleRowSelect = (event) => {
        if (!validateUserPermissions([TRANSACTION_RIGHTS.schedule_of_funding.schedule_selection], user?.rights)) return;

        // Row Selection
        if (event.type === "row") {
            setSelectedRowId(event.data.id);
            setDetailDialogVisible(true);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getScheduleColumnBody(col.field)} sortable>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    // Pagination
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const getRowClassName = (rowData) => {
        if (rowData.is_terminated) {
            return "terminated_row_color";
        }

        return "";
    };

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 p-0">
                    <div className="card">
                        <DataTable
                            ref={ref}
                            value={selectedHeaders}
                            selection={selectedSchedules.length === 0 ? null : selectedSchedules}
                            onSelectionChange={handleSelectionChange}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                            onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rowClassName={getRowClassName}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            )}
            {/* <Button title="temporary" onClick={() => setSchedulesFunding(true)} /> */}
            <Dialog
                header="Schedule of Funding Detail"
                visible={detailDialogVisible}
                fullScreen
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "80vw", height: "40vh" }}
                onHide={() => {
                    setDetailDialogVisible(false);
                }}
                className="detail_funding"
            >
                <DetailsOfFunding
                    onHide={() => {
                        setDetailDialogVisible(false);
                    }}
                    selectedRowId={selectedRowId}
                />
            </Dialog>
        </>
    );
};

export default Body;
