import React from "react";
import { Dialog } from "primereact/dialog";

import "./custompromtmessage.scss";

const CustomPromptMesage = (props) => {
    const { header, icon, firsttext, optionOne, optionTwo, type, onHide } = props;
    const dialogHeader = (
        <div className="dialog-header flex flex-row">
            {/* <div className={type === "success" ? "dialog-icon-success" : "dialog-icon-error"}>{icon}</div> */}

            <div className="ml-auto mr-auto">
                <h5 className="m-0 ml-auto mr-auto pt-2">{header}</h5>
            </div>
        </div>
    );

    return (
        <>
            {/* <p> text text text </p> */}
            <Dialog blockScroll={true} header={dialogHeader} onHide={() => onHide()} visible={true} style={{ width: "30vw" }} className="text-center" draggable={false}>
                <div className="grid">
                    <div className="col-12">
                        <p>{firsttext}</p>

                        <p>{optionOne ?? ""}</p>
                        <p>{optionTwo ?? ""}</p>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default CustomPromptMesage;
