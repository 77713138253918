import React, { useEffect, useState } from "react";
//styles
import "../../reserve.scss";
// Icons
import { BsCashCoin, BsCashStack, BsCheck, BsCheckAll, BsCreditCard2Back, BsX } from "react-icons/bs";

// UI Componets
import { useDispatch, useSelector } from "react-redux";
import { CASH_RESERVE_GRID_HEADERS, ESCROW_RESERVE_GRID_HEADERS, RESERVE_RELEASE_GRID_HEADERS } from "../../../../../../../utils/Constants/global";
import { mainViewState, onProspectMenuChange } from "../../../../../../../redux/main-view";

const Header = (props) => {
    const { setActiveCard, activeCard, setSelectedColumns, setHeader, setComponentName, setCurrentPage, setPage, setShowAll, setSelectedReserves, loading } = props;

    const dispatch = useDispatch();

    const ViewChangeHandler = (value) => {
        setActiveCard(value);
        setCurrentPage(1);
        setPage(1);
        setShowAll(false);

        if (value === 0) {
            setSelectedColumns(CASH_RESERVE_GRID_HEADERS);
            setComponentName("cash_reserves");
            setHeader(CASH_RESERVE_GRID_HEADERS);
        } else if (value === 1) {
            setSelectedColumns(ESCROW_RESERVE_GRID_HEADERS);
            setComponentName("escrow_reserves");
            setHeader(ESCROW_RESERVE_GRID_HEADERS);
        } else {
            setSelectedColumns(RESERVE_RELEASE_GRID_HEADERS);
            setComponentName("reserve_releases");
            setHeader(RESERVE_RELEASE_GRID_HEADERS);
        }
        setSelectedReserves([]);
    };

    return (
        <div className="pros_main">
            <div className="grid">
                <div className="col-7 md:col-10">
                    <h4 className="m-0">
                        <b>Reserves</b>
                    </h4>
                </div>
            </div>
            <div className="grid">
                <div className="md:col-4 col-12">
                    <div className={`card text-center ${activeCard === 0 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(0)}>
                        <div className="reserve_card_alignment">
                            <div className="icon_bg">
                                <BsCashCoin className="circle_icon_class" />
                            </div>
                            <h6>
                                <b>Cash Reserves</b>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className={`card text-center ${activeCard === 1 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(1)}>
                        <div className="reserve_card_alignment">
                            <div className="icon_bg">
                                <BsCashStack className="circle_icon_class" />
                            </div>
                            <h6>
                                <b>Escrow Reserves</b>
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="md:col-4 col-12">
                    <div className={`card text-center ${activeCard === 2 ? "active_class" : "non_active_class"}`} onClick={() => ViewChangeHandler(2)}>
                        <div className="reserve_card_alignment">
                            <div className="icon_bg">
                                <BsCreditCard2Back className="circle_icon_class" />
                            </div>
                            {/* <div className="flex  align-items-center"> */}
                            <h6>
                                <b>Reserve Release</b>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
