export function trimTextWithDots(text, maxLength = 10) {
    const stringVal = text ? text.toString() : "";
    if (stringVal) {
        if (stringVal.length <= maxLength) {
            return stringVal;
        } else {
            return stringVal.substring(0, maxLength) + "...";
        }
    }
}
