import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export async function getAllAnnoucements(data) {
    return postData(appUrl["annoucements"].getAllAnnoucements, data);
}

export async function createAnnoucement(data) {
    return postData(appUrl["annoucements"].createAnnoucement, data);
}

export async function updateAnnoucement(data) {
    return postData(appUrl["annoucements"].editAnnoucement, data);
}

export async function deleteAnnoucement(data) {
    return postData(appUrl["annoucements"].deleteAnnoucement, data);
}
