import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";

// Scss
import "../../../../UserManagement/Components/addnewmodal.scss";

// Prime react
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { AccordionTab, Accordion } from "primereact/accordion";
import { BreadCrumb } from "primereact/breadcrumb";
import { BsCheckLg } from "react-icons/bs";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Context & APIs
import { createRole, getAllRightsForTree, getRoleRights, updateRole } from "../../../../../services/user-management/user";
import { ToastContext } from "../../../../../context/toast";

// UI components
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";

// Utils
import { textExtraSpaceRemoval } from "../../../../../utils/HelperFuctions/cleanData";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onFormDirty } from "../../../../../redux/main-view";
import { loginState } from "../../../../../redux/login";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { USERMANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

const AddNewRole = () => {
    const history = useHistory();
    const location = useLocation();
    let id = null;
    if (location.state !== undefined) id = location.state?.id;
    const [isSaving, setIsSaving] = useState(false);
    const toastContext = useContext(ToastContext);
    const [modules, setModules] = useState();
    const [activeModule, setActiveModule] = useState();
    const [subModule, setSubModule] = useState();
    const [checkedRights, setCheckedRights] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const BreakcrumbItems = [{ label: "Roles & Rights", url: "/rolesrights" }, { label: id ? "Edit Role" : "Add New Role" }];
    const homeIcon = { icon: "bi bi-people" };
    // Redux
    const { user } = useSelector(loginState);
    const { screens } = useSelector(mainViewState);
    // Hooks
    const dispatch = useDispatch();
    const USER_MANAGEMNT_MODULE_ICON_MAP = {
        Management: <i className="bi bi-people mr-3"></i>,
        Transactions: <i className="bi bi-arrow-repeat mr-3"></i>,
        "Credit Request": <i className="bi bi-credit-card-2-front mr-3"></i>,
        Underwriting: <i className="bi bi-file-earmark-text mr-3"></i>,
        Reports: <i className="bi bi-file-earmark-bar-graph mr-3"></i>,
        Support: <i className="bi bi-headset mr-3"></i>,
        "User Management": <i className="pi pi-users mr-3"></i>,
        Settings: <i className="bi bi-toggle-off mr-3"></i>,
        Chats: <i className="bi bi-chat-dots mr-3"></i>,
        Documents: <i className="bi bi-file-earmark-word mr-3"></i>,
        Dashboard: <i className="bi bi-columns-gap mr-3"></i>,
        "General Ledger": <i className="bi bi-journal-text mr-3"></i>,
    };

    const validationSchema = Yup.object().shape({
        rolename: Yup.string().required("Role Title is required").min(2, "Role Title must be at least 2 characters"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            rolename: "",
        },
        onSubmit: async (data) => {
            try {
                if (checkedRights.length == 0) {
                    setIsSaving(false);
                    setIsLoading(false);
                    toastContext.showMessage("Error", "Select atleast one right.", "error");
                    return;
                }
                setIsSaving(true);
                setIsLoading(true);
                if (id) {
                    const dto = {
                        roleId: id,
                        roleName: data.rolename,
                        rights: checkedRights.map((r) => {
                            return {
                                rightId: r,
                            };
                        }),
                    };
                    const resp = await updateRole(dto);
                    setIsSaving(false);
                    setIsLoading(false);
                    if (resp.data.status) {
                        toastContext.updateToast("Role");
                        formik.resetForm();
                        history.push("/rolesrights");
                    } else {
                        toastContext.showMessage("Error", resp.data.message, "error");
                    }
                } else {
                    const dto = {
                        roleRightList: [
                            {
                                roleName: data.rolename,
                                rights: checkedRights.map((r) => {
                                    return {
                                        rightId: r,
                                    };
                                }),
                            },
                        ],
                    };
                    const resp = await createRole(dto);
                    setIsSaving(false);
                    setIsLoading(false);
                    if (resp.data.status) {
                        toastContext.createdToast("Role");
                        formik.resetForm();
                        dispatch(onFormDirty(false));
                        history.push("/rolesrights");
                    } else {
                        toastContext.showMessage("Error", resp.data.message, "error");
                    }
                }
                setIsSaving(false);
                setIsLoading(false);
                return;
            } catch (error) {
                setIsSaving(false);
                setIsLoading(false);
                console.log(error);
            }
        },
    });

    // Handlers
    const getRightsById = async (modules) => {
        let resp = await getRoleRights({
            role_id: id,
        });
        setRoleValues(resp.data.rights, modules);
        formik.setValues({ rolename: resp.data.roleName });
    };

    const setRoleValues = (rights, modules) => {
        let checkedRights = [];
        rights.forEach((r) => {
            checkedRights.push(r.rightsId);
        });
        setCheckedRights(checkedRights);
        // rights?.forEach((r) => {
        //     checkedRights.push(r.rightsId);
        // });

        modules.forEach((module) => {
            let isAllCheckedModule = true;
            let isAnyCheckedModule = false;
            module.subModuleNameDTO.forEach((sub) => {
                let isAllCheckedSubModule = true;
                let isAnyCheckedSubModule = false;
                sub.subModuleRightsListDTO.forEach((right) => {
                    let index = checkedRights.findIndex((c) => c === right.rightsId);
                    if (index != -1) {
                        right.checked = true;
                        isAnyCheckedSubModule = true;
                    } else {
                        isAllCheckedSubModule = false;
                    }
                });
                if (isAllCheckedSubModule) {
                    sub.checked = "all";
                } else if (isAnyCheckedSubModule) {
                    sub.checked = "some";
                } else {
                    sub.checked = "none";
                }
            });
            module.subModuleNameDTO.forEach((sub) => {
                if (sub.checked == "all") {
                    isAnyCheckedModule = true;
                } else if (sub.checked == "none") {
                    isAllCheckedModule = false;
                } else if (sub.checked == "some") {
                    isAnyCheckedModule = true;
                    isAllCheckedModule = false;
                }
            });
            if (isAllCheckedModule) {
                module.checked = "all";
            } else if (isAnyCheckedModule) {
                module.checked = "some";
            } else {
                module.checked = "none";
            }
        });
        setModules([...modules]);
    };

    const updateRightsList = (id, action) => {
        if (action === "add") {
            if (!checkedRights.includes(id)) {
                let data = checkedRights;
                data.push(id);
                setCheckedRights(data);
            }
        } else if (action === "remove") {
            const index = checkedRights.indexOf(id);
            if (index !== -1) {
                let data = checkedRights;
                data.splice(index, 1);
                setCheckedRights(data);
            }
        }
    };

    const getTreeData = async () => {
        let resp = await getAllRightsForTree();
        let module = resp.data.map((m) => {
            return {
                moduleName: m.moduleName,
                checked: "none",
                subModuleNameDTO: m.subModuleNameDTO.map((sub) => {
                    return {
                        subModuleName: sub.subModuleName,
                        expanded: false,
                        checked: "none",
                        subModuleRightsListDTO: sub.subModuleRightsListDTO.map((rights) => {
                            return {
                                rightsName: rights.rightsName,
                                displayName: rights.displayName,
                                rightsId: rights.rightsId,
                                checked: false,
                            };
                        }),
                    };
                }),
            };
        });
        setModules(module);
        setActiveModules(0, module);
        return module;
    };

    const setActiveModules = (index, data = modules) => {
        setActiveModule(data[index].moduleName);
        setSubModule(data[index].subModuleNameDTO);
    };

    const checkRight = (right, subModule, index) => {
        try {
            if (right.checked) {
                updateRightsList(right.rightsId, "remove");
            } else {
                updateRightsList(right.rightsId, "add");
            }
            right.checked = !right.checked;
            let isAllChecked = true;
            let isAnyChecked = false;
            subModule.subModuleRightsListDTO.forEach((r) => {
                if (r.checked) {
                    isAnyChecked = true;
                } else {
                    isAllChecked = false;
                }
            });

            if (isAllChecked) {
                subModule.checked = "all";
            } else if (isAnyChecked) {
                subModule.checked = "some";
            } else {
                subModule.checked = "none";
            }

            let activeModuleIndex = modules.findIndex((m) => m.moduleName === activeModule);

            let moduleData = [...modules];
            moduleData[activeModuleIndex].subModuleNameDTO[index] = subModule;

            setModules(moduleData);
            setSubModule(moduleData[activeModuleIndex].subModuleNameDTO);
            checkModuleOnSubmoduleChange();

            dispatch(onFormDirty(true));
        } catch (error) {
            console.log(error);
        }
    };

    const checkSubmodule = (subModule, index) => {
        subModule.subModuleRightsListDTO.forEach((r) => {
            if (subModule.checked == "all") {
                updateRightsList(r.rightsId, "remove");
            } else {
                updateRightsList(r.rightsId, "add");
            }
            r.checked = subModule.checked == "all" ? false : true;
        });
        if (subModule.checked === "all") {
            subModule.checked = "none";
        } else if (subModule.checked === "some" || subModule.checked === "none") {
            subModule.checked = "all";
        }

        let activeModuleIndex = modules.findIndex((m) => m.moduleName === activeModule);

        let moduleData = [...modules];
        moduleData[activeModuleIndex].subModuleNameDTO[index] = subModule;
        setModules(moduleData);
        setSubModule(moduleData[activeModuleIndex].subModuleNameDTO);
        checkModuleOnSubmoduleChange();

        dispatch(onFormDirty(true));
    };

    const checkAllSubmodules = (name) => {
        let index = modules.findIndex((m) => m.moduleName === name);
        if (index != -1) {
            let subModule = modules[index].subModuleNameDTO;
            subModule.forEach((sub) => {
                sub.checked = modules[index].checked == "all" ? "none" : "all";
                sub.subModuleRightsListDTO.forEach((r) => {
                    if (modules[index].checked == "all") {
                        updateRightsList(r.rightsId, "remove");
                    } else {
                        updateRightsList(r.rightsId, "add");
                    }
                    r.checked = modules[index].checked == "all" ? false : true;
                });
            });
        }
        if (modules[index].checked === "all") {
            modules[index].checked = "none";
        } else if (modules[index].checked === "some" || modules[index].checked === "none") {
            modules[index].checked = "all";
        }
        setModules([...modules]);
        setSubModule({ ...subModule });

        dispatch(onFormDirty(true));
    };

    const checkModuleOnSubmoduleChange = () => {
        let index = modules.findIndex((m) => m.moduleName === activeModule);
        let isAllChecked = true;
        let isAnyChecked = false;
        if (index != -1) {
            subModule.forEach((sub) => {
                sub.subModuleRightsListDTO.forEach((r) => {
                    if (r.checked) {
                        isAnyChecked = true;
                    } else {
                        isAllChecked = false;
                    }
                });
            });
        }

        if (isAllChecked) {
            modules[index].checked = "all";
        } else if (isAnyChecked) {
            modules[index].checked = "some";
        } else {
            modules[index].checked = "none";
        }
        setModules([...modules]);
        setSubModule([...subModule]);
    };

    const cancel = () => {
        if (formik.dirty || checkedRights.length != 0) {
            setDialogVisible(true);
        } else {
            history.push("/rolesrights");
        }
    };

    const confirmCancel = () => {
        formik.resetForm();
        dispatch(onFormDirty(false));
        history.push("/rolesrights");
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);
        formik.setFieldValue(name, cleanValue);

        dispatch(onFormDirty(true));
    };

    // Use Effect
    useEffect(() => {
        const getData = async () => {
            let resp = await getTreeData();
            if (id) {
                if (!user) return;
                if (!validateUserPermissions([USERMANAGMENT_RIGHTS.role_rights_selection], user?.rights)) {
                    history.goBack();
                    return;
                }

                await getRightsById(resp);
            }
        };

        getData();

        return () => {
            dispatch(onFormDirty(false));
        };
    }, [user]);

    return (
        <>
            <h4 className="pt-2">
                <BreadCrumb model={BreakcrumbItems} home={homeIcon} />
                {/* <b> {id ? "Edit Role" : "Add New Role"} </b> */}
            </h4>
            <div className="card">
                <form onSubmit={formik.handleSubmit} className="grid pt-5">
                    <div className="md:col-4 col-12">
                        <div>
                            <div className="role_card">
                                <CustomInputField
                                    maxLength={30}
                                    iden="rolename"
                                    mandatory="*"
                                    formik={formik}
                                    className="col-12 md:col-12"
                                    type="text"
                                    label="Role Title"
                                    placeHolder="Enter Role Title"
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>

                            <div className="custom_card marg__top">
                                <div className="col-12">
                                    <h5>Modules</h5> <Divider />
                                </div>
                                <div className="col-12">
                                    <div className="custom_roles_modal_scrollbar">
                                        {modules?.map((m, index) => (
                                            <div key={index} onClick={() => setActiveModules(index)} className={`custom_div cursor-pointer ${activeModule === m.moduleName ? "active_module" : ""}`}>
                                                <div className={`flex flex-row justify-content-between`}>
                                                    <span>
                                                        <p>
                                                            {/* <i className="pi pi-users mr-3"></i> */}
                                                            {USER_MANAGEMNT_MODULE_ICON_MAP[m.moduleName]}
                                                            {m.moduleName}
                                                        </p>
                                                    </span>
                                                    <span>
                                                        <Checkbox
                                                            className={`${m.checked === "some" ? "indeterminate_checkbox" : ""}`}
                                                            checked={m.checked === "all"}
                                                            onChange={() => checkAllSubmodules(m.moduleName)}
                                                            inputId="accept"
                                                            name="accept"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-8 col-12">
                        <div className="custom_card user_right_card">
                            <div className="custom_card_accordian_rolesmodal_scrollbar">
                                <div className="col-12">
                                    <h5>Rights</h5> <Divider />
                                </div>
                                {subModule?.map((sub, index) => (
                                    <Accordion key={index}>
                                        <AccordionTab
                                            className="add_new_user_accordian"
                                            header={
                                                <div className="flex align-items-center">
                                                    <Checkbox
                                                        className={`${sub.checked === "some" ? "indeterminate_checkbox" : ""} mr-2`}
                                                        checked={sub.checked === "all"}
                                                        indeterminate={sub.checked === "some"}
                                                        onChange={() => checkSubmodule(sub, index)}
                                                        inputId="submodule"
                                                        name="submodule"
                                                        value="submodule"
                                                    />
                                                    <span htmlFor="submodule" className="pl-2">
                                                        {sub?.subModuleName}
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <div className="pt-3 grid">
                                                {sub?.subModuleRightsListDTO?.map((right, index2) => (
                                                    <div key={index2} className="md:col-3 col-12">
                                                        <div className="flex align-items-center">
                                                            <Checkbox
                                                                checked={right.checked}
                                                                onChange={() => checkRight(right, sub, index)}
                                                                inputId={index2}
                                                                className="mr-2 ml-2"
                                                                name={index2}
                                                                value={index2}
                                                            />
                                                            <span htmlFor={index2} className="pl-2 pb-2">
                                                                {right.displayName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 text-right pt-5 pr-0">
                            <CustomCancelbtn title="Cancel" type="button" onClick={cancel} />
                            <CustomAddNewButton title={id ? "Update" : "Create"} type="submit" isDisabled={isSaving || (!screens?.main?.isFormDirty ?? false)} isLoading={isLoading} />
                        </div>
                    </div>
                </form>
            </div>

            {dialogVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setDialogVisible(false)}
                    handler={confirmCancel}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default AddNewRole;
