import React, { useEffect, useState } from "react";
//styles
import "./notifications.scss";
import { TabView, TabPanel } from "primereact/tabview";
import NotificationComponent from "./Components/NotificationComponent";
import { getAllNotifications } from "../../services/notifications";
import CustomLoading from "../../ui/CustomSpinner/custom_spinner";

const Index = () => {
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [read, setRead] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const getNotifications = async () => {
        setLoading(true);
        let payload = {
            read: read,
        };
        let res = await getAllNotifications(payload);
        if (res.data.notifications) {
            setNotifications(res.data.notifications);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        getNotifications();
    }, [read]);

    const viewChangeHandler = (e) => {
        setActiveIndex(e.index);
        if (e.index === 1) {
            setRead("read");
        } else if (e.index === 2) {
            setRead("unread");
        } else {
            setRead([]);
        }
    };

    return (
        <div className="card notification_tab">
            {loading === true ? (
                <CustomLoading />
            ) : (
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => {
                        viewChangeHandler(e);
                    }}
                >
                    <TabPanel header="All">
                        <NotificationComponent notifications={notifications} />
                    </TabPanel>
                    <TabPanel header="Read">
                        <NotificationComponent notifications={notifications} />
                    </TabPanel>
                    <TabPanel header="Unread">
                        <NotificationComponent notifications={notifications} />
                    </TabPanel>
                </TabView>
            )}
        </div>
    );
};

export default Index;
