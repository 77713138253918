import React from "react";
import { useHistory } from "react-router-dom";
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
//Component

const Header = () => {
    const history = useHistory();

    // Redux
    const {user} = useSelector(loginState)

    return validateUserPermissions([TRANSACTION_RIGHTS.collections.add_new_collection], user?.rights) && <CustomAddNewButton type="button" title="Add New Collection" onClick={() => history.push(`/addnewcollection`)} />
      
};

export default Header;
