import React, { useState, useRef, useEffect, useContext } from "react";
//Component

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//styles
import "./collectioncomponent.scss";
import { getMenuInvoices } from "../../../../../../services/collection";
import { ToastContext } from "../../../../../../context/toast";

const CollectionInvoices = (props) => {
    const { selected } = props;
    // states
    const [data, setData] = useState([]);
    // toast
    const toast = useContext(ToastContext);
    const dt = useRef(null);
    const handleFetchInvoices = async () => {
        let payload = {
            collection_id: selected[0]?.id,
        };
        try {
            let res = await getMenuInvoices(payload);
            if (res.data.status) {
                //  setData(res.data.invoices);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        handleFetchInvoices();
    }, []);
    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3 collection_invoice_scroll">
                    <DataTable ref={dt} value={data} rows={5} emptyMessage="No record available.">
                        <Column field="client" header="Client Name"></Column>
                        <Column field="invoiceno" header="Invoice No"></Column>
                        <Column field="invoiceamouunt" header="Invoice Amount"></Column>
                        <Column field="feeday" header="Fee Day"></Column>
                        <Column field="postingdate" header="Posting Date"></Column>
                        <Column field="cashreserves" header="Cash Reserves"></Column>
                        <Column field="deductions" header="Deductions"></Column>
                        <Column field="releaseamountafterdeduction" header="Release Amount After Deduction"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default CollectionInvoices;
