import React from "react";
//Style
import "./customcard.scss";
//Import images
import AverageAging from "../../../assets/Portalicons/averageaging_graph.svg";
import TotalDebt from "../../../assets/Portalicons/totaldebt_graph.svg";
import TotalPaid from "../../../assets/Portalicons/totalpaid_graph.svg";
import AverageDebt from "../../../assets/Portalicons/averagedebt_graph.svg";
import { currencyFormatUSD } from "../../../utils/HelperFuctions/checkAmount";
import chartLine from "../../../assets/Portalicons/salesicon/chart-line.svg";
import chartMixed from "../../../assets/Portalicons/salesicon/chart-mixed.svg";
import chartUp from "../../../assets/Portalicons/salesicon/display-chart-up-circle-dollar.svg";
import chartPie from "../../../assets/Portalicons/salesicon/chart-pie-simple-circle-dollar.svg";

const CustomCardView = (props) => {
    const { cardsData } = props;

    return (
        <>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-2">
                        <div className="flex align-items-center justify-content-center ">
                            <span>
                                <img src={chartLine} />
                            </span>
                        </div>
                        <div>
                            <img src={AverageAging} />
                        </div>
                    </div>
                    <div className="flex justify-content-between mb-2">
                        <div>
                            <div className="card_text_small">Average Aging</div>
                            <span className="card_number">{cardsData.average_aging}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-2">
                        <div className="flex align-items-center justify-content-center">
                            <span>
                                <img src={chartMixed} />
                            </span>
                        </div>
                        <div>
                            <img src={TotalDebt} />
                        </div>
                    </div>
                    <div className="flex justify-content-between mb-2">
                        <div>
                            <div className="card_text_small">Total Debt</div>
                            <span className="card_number">{currencyFormatUSD(cardsData.total_debt)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-2">
                        <div className="flex align-items-center justify-content-center">
                            <span>
                                <img src={chartUp} />
                            </span>
                        </div>
                        <div>
                            <img src={TotalPaid} />
                        </div>
                    </div>
                    <div className="flex justify-content-between mb-2">
                        <div>
                            <div className="card_text_small">Total Paid Amount</div>
                            <span className="card_number">{currencyFormatUSD(cardsData.total_paid_amount)}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-2">
                        <div className="flex align-items-center justify-content-center ">
                            <span>
                                <img src={chartPie} />
                            </span>
                        </div>
                        <div>
                            <img src={AverageDebt} />
                        </div>
                    </div>
                    <div className="flex justify-content-between mb-2">
                        <div>
                            <div className="card_text_small">Average Debt per Debtor</div>
                            <span className="card_number">{currencyFormatUSD(cardsData.average_debt_per_debtor)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomCardView;
