import React, { useContext, useEffect, useState } from "react";

// Icons
import { BiUserCircle } from "react-icons/bi";

// UI Components
import DebtorForm from "../DebtorComponent/DebtorForm";
import DebtorPoc from "../DebtorComponent/DebtorPoc";
import * as Yup from "yup";

// Styles
import "../../../management.scss";
import { DebtorContext } from "../../../../../context/DebtorContext";
import { useQuery } from "../../../../../utils/HelperFuctions/UseQuery";
import { getDebtorById, getDebtorPocs, isDebtorExists } from "../../../../../services/debtor";
import { ToastContext } from "../../../../../context/toast";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onDebtorFormDirty, setDebtorLoader } from "../../../../../redux/main-view";
import { useFormik } from "formik";
import { TiContacts } from "react-icons/ti";
import { BreadCrumb } from "primereact/breadcrumb";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { fetchCreditLimit } from "../../../../../services/credit-request";
import { MANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";
import { loginState } from "../../../../../redux/login";

// Utils
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import moment from "moment";

const validationSchema = Yup.object().shape({
    prospect_id: Yup.string().required("Client Name is required"),
    address: Yup.string().required("Address is required"),
    legal_name: Yup.string().required("Debtor Name is required"),
    phone: Yup.string().required("Phone No is required"),
    zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
    fein: Yup.string().required("FEIN is required"),
    email: Yup.string().email("Company Email is invalid").required("Company Email is required"),
    credit_limit: Yup.number()
        .typeError("Weekly Credit Limit is required")
        .min(1, "Weekly Credit Limit should be greater than $0.00")
        .max(99999999.99, "Weekly Credit Limit should be less than $99,999,999.99")
        .required("Weekly Credit Limit is required"),
});

const initialValidationState = {
    name: false,
    email: false,
    phone: false,
    inValidEmail: false,
};

const initialPocState = [
    {
        first_name: "",
        last_name: "",
        email: "",
        department: "",
        country_phone_code: "+1",
        country_fax_code: "+1",
        phone: "",
        fax: "",
        validations: {
            ...initialValidationState,
        },
    },
];

const AddNewDebtor = () => {
    const location = useLocation();

    let debtor_id = null;
    let prospect_id = null;
    if (location.state !== undefined) {
        debtor_id = parseInt(location.state.id);
        prospect_id = parseInt(location.state.prospect_id);
    }

    const { screens } = useSelector(mainViewState);

    const dispatch = useDispatch();
    const toast = useContext(ToastContext);
    const history = useHistory();

    // State
    const [debtor, setDebtor] = useState({});
    const [activeCard, setActiveCard] = useState(-1);
    const [debtorById, setDebtorById] = useState({});
    const [debtorPocs, setDebtorPocs] = useState([]);
    const [pocs, setPocs] = useState(initialPocState);
    const [clientActiveCreditLimit, setClientActiveCreditLimit] = useState(0);

    // View State
    const [isOpening, setIsOpening] = useState(false);
    const [isZipSearch, setIsZipSearch] = useState(false);
    const [creditLimitLoading, setCreditLimitLoading] = useState(false);
    const [showDebtorPocModal, setShowDebtorPocModal] = useState(false);
    const [pocsOnDebtorChange, setPocsOnDebtorChange] = useState(false);
    const [debtorExistsChecking, setDebtorExistsChecking] = useState(false);
    const [showInviteButton, setShowInviteButton] = useState(false);
    const [debtorContactsList, setDebtorContactsList] = useState([]);
    const [showResendInvite, setShowResendInvite] = useState(false);
    const [updateDebtorRecord, setUpdateDebtorRecord] = useState(false);

    const [newIndustry, setNewIndustry] = useState(true);
    const [industryTitle, setIndustryTitle] = useState(null);
    const [industryValue, setIndustryValue] = useState(0);
    const [industries, setIndustries] = useState([]);

    const [isClient, setIsClient] = useState(false);

    // Redux
    const { user } = useSelector(loginState);

    // Formik
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            prospect_id: "",
            address: "",
            legal_name: "",
            phone: "",
            country_phone_code: "+1",
            country_fax_code: "+1",
            zip: "",
            fein: "",
            email: "",
            credit_limit: "",
            optional_comments: "",
        },
        onSubmit: async (data) => {
            if (data.id) {
                handleNext(data);
            } else {
                if (clientActiveCreditLimit < data.credit_limit) {
                    toast.showMessage("Validation Failed", "Weekly Credit Limit can not exceed the client's available credit limit.", "error");
                } else {
                    setDebtorExistsChecking(true);
                    try {
                        let payload = {
                            email: data.email,
                            fein: data.fein,
                        };

                        let res = await isDebtorExists(payload);
                        if (res?.data?.status) {
                            if (res.data.isExists) {
                                toast.showMessage("Validation Failed", res.data.message, "error");
                            } else {
                                handleNext(data);
                            }
                        } else {
                            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                        }
                        setDebtorExistsChecking(false);
                    } catch (e) {
                        toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                        setDebtorExistsChecking(false);
                    }
                }
            }
        },
    });

    const handleNext = (data) => {
        if (pocsOnDebtorChange && pocsOnDebtorChange.length > 0) {
            setShowDebtorPocModal(true);
        } else {
            if (debtor && debtor.profile && debtor.profile.id) {
                data.id = debtor.profile.id;
            }

            data = {
                ...data,
                fax: data.fax ? data.fax.toString().replaceAll("-", "") : "",
                phone: data.phone.toString().replaceAll("-", ""),
                country_phone_code: data.country_phone_code && data.country_phone_code !== "+1" ? data.country_phone_code : "+1",
                country_fax_code: data.country_fax_code && data.country_fax_code !== "+1" ? data.country_fax_code : "+1",
            };
            handleCardClick(1, data);
        }
    };

    const debtorPocClickHandler = (e) => {
        if (screens.debtor.loader || clientActiveCreditLimit === 0) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            formik.handleSubmit();
        }
    };

    // Handler's
    const handleCardClick = (index, profile = null, _pocs = null) => {
        setIsZipSearch(false);
        if (_pocs) {
            if (_pocs.length == 0) {
                _pocs = initialPocState;
            }

            setPocs(_pocs);
        }

        setActiveCard(index);
    };

    const confirmHandler = () => {
        dispatch(onDebtorFormDirty(false));
        history.push("/debtormain");
    };

    const items = [{ label: "Debtors", url: "/debtormain" }, { label: debtor_id ? "Edit Debtor" : "Add New Debtor" }];
    const home = { icon: "bi bi-people" };

    const renderCardComponent = () => {
        if (activeCard === 0) {
            return (
                <DebtorForm
                    setIndexCall={handleCardClick}
                    debtorById={debtorById}
                    setIsOpening={setIsOpening}
                    formik={formik}
                    showDebtorPocModal={showDebtorPocModal}
                    setShowDebtorPocModal={setShowDebtorPocModal}
                    pocsOnDebtorChange={pocsOnDebtorChange}
                    setPocsOnDebtorChange={setPocsOnDebtorChange}
                    debtorPocClickHandler={debtorPocClickHandler}
                    isZipSearch={isZipSearch}
                    setIsZipSearch={setIsZipSearch}
                    setDebtorPocs={setDebtorPocs}
                    debtorPocs={debtorPocs}
                    setPocs={setPocs}
                    initialPocState={initialPocState}
                    debtorExistsChecking={debtorExistsChecking}
                    prospect_id={prospect_id}
                    creditLimitLoading={creditLimitLoading}
                    setShowInviteButton={setShowInviteButton}
                    showInviteButton={showInviteButton}
                    debtorContactsList={debtorContactsList}
                    showResendInvite={showResendInvite}
                    setUpdateDebtorRecord={setUpdateDebtorRecord}
                    setIsClient={setIsClient}
                    isClient={isClient}
                    newIndustry={newIndustry}
                    setNewIndustry={setNewIndustry}
                    industryTitle={industryTitle}
                    setIndustryTitle={setIndustryTitle}
                    industryValue={industryValue}
                    setIndustryValue={setIndustryValue}
                    industries={industries}
                    setIndustries={setIndustries}
                />
            );
        } else if (activeCard === 1) {
            return (
                <DebtorPoc
                    setIndexCall={handleCardClick}
                    setIsClient={setIsClient}
                    isClient={isClient}
                    setIsOpening={setIsOpening}
                    pocs={pocs}
                    setPocs={setPocs}
                    formik={formik}
                    newIndustry={newIndustry}
                    industryValue={industryValue}
                    industryTitle={industryTitle}
                    industries={industries}
                />
            );
        }

        return null;
    };

    // API'S Handler
    const fetchDebtorById = (prospect_id, debtor_id) => {
        try {
            const data = {
                prospect_id: prospect_id,
                id: debtor_id,
            };

            dispatch(setDebtorLoader(true));
            getDebtorById(data).then((res) => {
                if (res && res.status) {
                    if (res.data.status) {
                        setDebtorById(res.data.debtor);
                        res.data.debtor.pocs.map((item) => {
                            if (!item.invite_send_date) {
                                setShowInviteButton(true);
                            }
                        });

                        if (res.data.debtor.users?.length > 0) {
                            setShowResendInvite(true);
                        }

                        let debtor_contact = [];
                        let registered_emails = [];
                        if (res.data.debtor.pocs?.length > 0) {
                            let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

                            res.data.debtor.users.map((item) => {
                                if (item.password) {
                                    registered_emails.push(item.email);
                                }
                            });

                            res.data.debtor.pocs.map((item) => {
                                let inviteSendDate = moment(item.invite_send_date, "YYYY-MM-DD HH:mm:ss").add(1, "days");
                                let is_before = !item.invite_send_date ? false : inviteSendDate.isBefore(currentDateTime);

                                debtor_contact.push({
                                    id: item.id,
                                    email: item.email,
                                    title: item.last_name ? item.first_name + " " + item.last_name : item.first_name,
                                    invite_send_date: item.invite_send_date,
                                    status: registered_emails.includes(item.email) ? false : is_before,
                                });
                            });
                            setDebtorContactsList(debtor_contact);
                        }
                    } else {
                        toast.showMessage("Data Fetch Failed", res.data.message, "error");
                    }
                } else {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
                dispatch(setDebtorLoader(false));
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const updateDebtorById = (prospect_id, debtor_id) => {
        try {
            const data = {
                prospect_id: prospect_id,
                id: debtor_id,
            };

            setUpdateDebtorRecord(false);

            // dispatch(setDebtorLoader(true));
            getDebtorById(data).then((res) => {
                if (res && res.status) {
                    if (res.data.status) {
                        setDebtorById(res.data.debtor);
                        res.data.debtor.pocs.map((item) => {
                            if (!item.invite_send_date) {
                                setShowInviteButton(true);
                            }
                        });

                        if (res.data.debtor.users?.length > 0) {
                            setShowResendInvite(true);
                        }

                        let debtor_contact = [];
                        let registered_emails = [];
                        if (res.data.debtor.pocs?.length > 0) {
                            let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

                            res.data.debtor.users.map((item) => {
                                if (item.password) {
                                    registered_emails.push(item.email);
                                }
                            });
                            res.data.debtor.pocs.map((item) => {
                                let InviteSendDate = moment(item.invite_send_date, "YYYY-MM-DD HH:mm:ss").add(1, "days");
                                let is_before = !item.invite_send_date ? false : InviteSendDate.isBefore(currentDateTime);
                                debtor_contact.push({
                                    id: item.id,
                                    email: item.email,
                                    title: item.last_name ? item.first_name + " " + item.last_name : item.first_name,
                                    invite_send_date: item.invite_send_date,
                                    status: registered_emails.includes(item.email) ? false : is_before,
                                });
                            });
                            setDebtorContactsList(debtor_contact);
                        }
                    } else {
                        toast.showMessage("Data Fetch Failed", res.data.message, "error");
                    }
                } else {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
                // dispatch(setDebtorLoader(false));
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchDebtorPocs = (prospect_id, debtor_id) => {
        try {
            const data = {
                prospect_id: prospect_id,
                debtor_id: debtor_id,
            };
            getDebtorPocs(data).then((res) => {
                if (res && res.status) {
                    if (res.data?.status) {
                        setDebtorPocs(res.data.pocs);
                    } else {
                        toast.showMessage("Data Fetch Failed", res.data?.message, "error");
                    }
                } else {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // Handler
    const onLandingScreenHandler = () => {
        if (!user) return;

        const required_rights = [MANAGMENT_RIGHTS.debtors.profile, MANAGMENT_RIGHTS.debtors.notice_of_assignment];
        for (const index in required_rights) {
            if (validateUserPermissions([required_rights[index]], user?.rights)) {
                setActiveCard(Number(index));
                return;
            }
        }

        history.goBack();
    };

    // UseEffect
    useEffect(() => {
        if (prospect_id && debtor_id) {
            fetchDebtorById(prospect_id, debtor_id);
            fetchDebtorPocs(prospect_id, debtor_id);
        }
    }, []);

    useEffect(() => {
        if (prospect_id && debtor_id && updateDebtorRecord) {
            updateDebtorById(prospect_id, debtor_id);
        }
    }, [updateDebtorRecord]);

    useEffect(() => {
        onLandingScreenHandler();
    }, [user]);

    useEffect(() => {
        let _debtor = { ...debtor };

        if (debtorById) {
            _debtor.profile = debtorById;
        }

        if (debtorPocs) {
            _debtor.pocs = debtorPocs;
        }

        setDebtor(_debtor);
    }, [debtorById, debtorPocs]);

    useEffect(() => {
        if (formik.values.prospect_id) {
            let payload = {
                prospect_id: formik.values.prospect_id,
            };

            setCreditLimitLoading(true);
            fetchCreditLimit(payload).then((res) => {
                if (res && res.status) {
                    if (res.data && res.data.credit_limit) {
                        setClientActiveCreditLimit(res.data.credit_limit.client_active_credit_limit ? res.data.credit_limit.client_active_credit_limit : "");
                    }
                }
                setCreditLimitLoading(false);
            });
        }
    }, [formik.values.prospect_id]);

    return (
        <>
            <div>
                <div className="">
                    <BreadCrumb model={items} home={home} />
                </div>
            </div>
            <div className="debtor_main_header">
                <div className="dp_flex bg_header pl-3">
                    {validateUserPermissions([MANAGMENT_RIGHTS.debtors.profile], user?.rights) && (
                        <div className={`${screens.debtor.loader && "no-drop"}`} onClick={() => handleCardClick(0, debtor?.profile, pocs)}>
                            <div className={`text-center ${activeCard === 0 ? "text_clr_class_active" : "None_text_clr_class_active"}`}>
                                <div className="flex flex-row align-items-center justify-content-center cursor-pointer">
                                    <BiUserCircle className="header_icon" />
                                    &nbsp;
                                    <h6>Profile</h6>
                                </div>
                            </div>
                        </div>
                    )}
                    {validateUserPermissions([MANAGMENT_RIGHTS.debtors.notice_of_assignment], user?.rights) && (
                        <div className={`${(screens.debtor.loader || clientActiveCreditLimit === 0) && "no-drop"}`} onClick={(e) => (screens.debtor.loader ? null : debtorPocClickHandler(e))}>
                            <div className={`text-center ${activeCard === 1 ? "text_clr_class_active" : "None_text_clr_class_active"}`}>
                                <div
                                    className={`flex flex-row align-items-center justify-content-center ${
                                        screens.debtor.loader || clientActiveCreditLimit === 0 ? "disabled_icons" : "cursor-pointer"
                                    } `}
                                >
                                    <TiContacts className="header_icon" />
                                    &nbsp;
                                    <h6>Debtor Contacts</h6>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <DebtorContext.Provider value={debtor}>
                    <div>{renderCardComponent()}</div>
                </DebtorContext.Provider>
            </div>
            {isOpening && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpening(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default AddNewDebtor;
