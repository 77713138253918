import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export async function postBroker(data) {
    return postData(appUrl["broker"].create, data);
}

export async function getBroker(data) {
    return postData(appUrl["broker"].fetch, data);
}

export async function getBrokerById(data) {
    return postData(appUrl["broker"].fetchById, data);
}

export async function postAgreement(data) {
    return postData(appUrl["broker"].sendAgreement, data);
}

export async function ChangeStatus(data) {
    return postData(appUrl["broker"].changeStatus, data);
}

export async function inviteClient(data) {
    return postData(appUrl["broker"].inviteClient, data);
}

export async function getBrokersForDropdown(data = null) {
    return postData(appUrl["broker"].fetchBrokersForDropdown, data);
}

export async function inviteBroker(data = null) {
    return postData(appUrl["broker"].invite, data);
}

export async function resendBrokerInvite(data = null) {
    return postData(appUrl["broker"].resendInvite, data);
}

export async function getReferralCode(data = null) {
    return postData(appUrl["broker"].fetchReferralCode, data);
}
