import React, { useEffect, useState } from "react";
// Prime React
import { Checkbox } from "primereact/checkbox";
// Redux
import { useSelector } from "react-redux";
// Ui Components
import ColumnFilterComponent from "../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../ui/KebabMenu/KebabMenuComponent";
import SearchInputComponent from "../../../../ui/SearchInput/SearchInputComponent";
import { CLIENT_HEADERS, CLIENT_MENU_ITEMS, ROLE_NAME } from "../../../../utils/Constants/global";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";

// Redux
import { loginState } from "../../../../redux/login";
import Legends from "../../../../ui/Legends/Legends";

const FilterBar = (props) => {
    const { showAll, setShowAll, setSelectedColumns, keyword, setKeyword, selectedUsers, setKebabMenuOption } = props;
    // Redux
    const { user } = useSelector(loginState);

    // State
    const [onSelectionColumn, setOnSelectionColumn] = useState([]);
    const [client_menu_items, setClientMenuItems] = useState([]);

    // Handlers
    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                import_client: true,
                export_client: false,
                terminate_to_client: false,
                delete_client: false,
            });
        } else if (status == 2 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                import_client: false,
                export_client: true,
                terminate_to_client: false,
                delete_client: false,
            });
        } else if (status == 3 && selectedUsers.length === 1) {
            setKebabMenuOption({
                import_client: false,
                export_client: false,
                terminate_to_client: true,
                delete_client: false,
            });
        } else if (status == 4 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                import_client: false,
                export_client: false,
                terminate_to_client: false,
                delet_cliente: true,
            });
        }
    };

    // UseEffect
    useEffect(() => {
        const temp = [];
        CLIENT_HEADERS.filter((col) => {
            temp.push(col.field);
        });
        setOnSelectionColumn(temp);

        if (user) {
            const _client_menu_items = CLIENT_MENU_ITEMS.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user.rights);
                if (isAllowed) return col;
            });
            setClientMenuItems(_client_menu_items);
        }
    }, [user]);

    return (
        <div className="grid">
            <div className="col-12">
                <h4 className="m-0">
                    <b>Clients</b>
                </h4>
            </div>
            <div className="md:col-5 col-12">
                {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT ? (
                    <div className="flex mt-4 align-items-center">
                        <Checkbox inputId="showall" checked={showAll} onChange={(e) => setShowAll(e.checked)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include deleted/terminated clients
                        </label>
                        {showAll && (
                            <>
                                <div className="mt-2 ml-3">
                                    <Legends classes="square-legend-delete" title="Deleted" />
                                </div>
                                <div className="mt-2 ml-2">
                                    <Legends classes="square-legend-terminate" title="Terminated" />
                                </div>
                            </>
                        )}
                    </div>
                ) : null}
            </div>

            <div className="md:col-7 col-12 pt-3">
                <div className="flex flex-row justify-content-end align-items-center">
                    <div className="ml-2">
                        <SearchInputComponent isClassWidth={true} value={keyword} setKeyword={setKeyword} placeholder="Search by registered business name, contact name & email " />
                    </div>
                    <div className="ml-2">
                        <ColumnFilterComponent items={CLIENT_HEADERS} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                    </div>
                    <div className="ml-2">
                        {client_menu_items.length > 0 && <KebabMenuComponent items={client_menu_items} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedUsers.length === 0} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterBar;
