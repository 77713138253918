import React, { useContext, useEffect, useState } from "react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../../../../../../../context/toast";
import { mainViewState, onClientDataChange, onClientLandlordFormDirty, onFormDirty } from "../../../../../../../redux/main-view";

// Icons
import { BsCheck, BsPencil } from "react-icons/bs";

// Prime React
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";

// UI Components
import CustomTextField from "../../../../../../../ui/CustomTextField/CustomTextField";
import CustomInputField from "../../../../../../../ui/CustomInputField/CustomInputField";
import ZipCodesMenuComponent from "../../../../../../../ui/ZipCodesMenu/ZipCodesMenuComponent";
import CustomDropdownField from "../../../../../../../ui/CustomDropdownField/CustomDropdownField";
import CustomInputTextArea from "../../../../../../../ui/CustomInputTextArea/CustomInputTextArea";

// Constants
import { LEASING_BUSINESS_SPACE } from "../../../../../../../utils/Constants/global";

// Helper Function
import { AmountCheck } from "../../../../../../../utils/HelperFuctions/checkAmount";
import formDirtyHandler from "../../../../../../../utils/HelperFuctions/clientHelper";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../../../../utils/HelperFuctions/cleanData";

// API'S
import { saveProspect } from "../../../../../../../services/prospect";
import { getZipCode } from "../../../../../../../services/zip-code";

const validationSchema = Yup.object().shape({
    leasing_business_space: Yup.string().required("Currently Leasing Office is required"),
    landlord_phone: Yup.string().required("Phone No is required"),
    landlord_zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
    landlord_state: Yup.string().required("State is required"),
    landlord_city: Yup.string().required("City is required"),
});

const LandlordInformation = () => {
    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // State
    const [zipCode, setZipCode] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
    });

    // View State
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isDisabledButton, setIsDisabledButton] = useState(true);
    const [isZipCodeSearch, setIsZipSearch] = useState(true);

    const formik = useFormik({
        validationSchema: isDisabled ? "" : validationSchema,
        initialValues: {
            leasing_business_space: "",
            landlord_phone: "",
            landlord_zip: "",
            landlord_state: "",
            landlord_city: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                data = {
                    ...data,
                    step: "5",
                    id: screens.client.data.id,
                    landlord_country_phone_code: isDisabled ? "+1" : data.landlord_country_phone_code ? data.landlord_country_phone_code.phone_code : "+1",
                };
                if (screens.client.isLandlordFormDirty) {
                    let res = await saveProspect(data);
                    if (res) {
                        if (res.data.status) {
                            formDirtyHandler();
                            dispatch(onFormDirty(false));
                            setIsDisabledButton(true);
                            dispatch(onClientLandlordFormDirty(false));
                            dispatch(onClientDataChange(res.data.prospect));
                        }
                    } else {
                        toast.showMessage("Error", "Server Error", "error");
                        setIsSaving(false);
                    }
                } else {
                    setIsDisabledButton(true);
                }
                setIsSaving(false);
            } catch (error) {
                toast.showMessage("Error", "Server Error", "error");
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Handlers
    const inputHandler = (e) => {
        dispatch(onFormDirty(true));
        dispatch(onClientLandlordFormDirty(true));
        const { name, value } = e.target;
        let cleanValue = null;
        if (name === "leasing_business_space" || name === "landlord_country_phone_code") {
        } else {
            cleanValue = textExtraSpaceRemoval(value);
        }

        if (name === "leasing_business_space" || name === "landlord_country_phone_code") {
            if (value === 0) {
                setIsDisabled(true);
                formik.setFieldValue("present_lease_period", "");
                formik.setFieldValue("landlord_name", "");
                formik.setFieldValue("landlord_monthly_rent", "");
                formik.setFieldValue("landlord_country_phone_code", "");
                formik.setFieldValue("landlord_phone", "");
                formik.setFieldValue("landlord_address", "");
                formik.setFieldValue("landlord_zip", "");
                formik.setFieldValue("landlord_state", "");
                formik.setFieldValue("landlord_city", "");
                formik.setFieldValue("leasing_business_space", value);
                setPhoneCodesPlaceholders({
                    phone_code: "+1",
                });
            } else {
                setIsDisabled(false);
                formik.setFieldValue(name, value);
            }
        } else if (name === "landlord_name") {
            formik.setFieldValue([name], toTitleCaseWhileTyping(value));
        } else if (name === "landlord_monthly_rent") {
            formik.setFieldValue([name], AmountCheck(cleanValue));
        } else {
            formik.setFieldValue(name, cleanValue);
        }
    };
    const handleCurrencyChange = (event, name) => {
        dispatch(onFormDirty(true));
        dispatch(onClientLandlordFormDirty(true));
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRcordNotFoundMsg("");
        formik.values.landlord_zip = zip.zip;
        formik.setFieldValue("landlord_zip", zip.zip);
        formik.setFieldValue("landlord_state", zip.full_state);
        formik.setFieldTouched("landlord_state", false);
        formik.setFieldValue("landlord_city", zip.city);
        setZipCode([]);
    };

    // API'S
    const fetchZipCodeData = (value) => {
        const payload = {
            zip: value,
        };

        getZipCode(payload).then((res) => {
            setZipCode(res.data.codes);

            if (res.data.codes.length == 0) {
                setZipCode([]);
                setRcordNotFoundMsg("No Record Available");
            }
        });
    };

    // UseEffect
    useEffect(() => {
        if (formik.values.landlord_zip && formik.values.landlord_zip?.length >= 4 && isZipCodeSearch) {
            fetchZipCodeData(formik.values.landlord_zip);
        } else if (formik.values.landlord_zip?.length < 4) {
            setZipCode([]);
            setIsZipSearch(true);
            formik.values.landlord_state = "";
            formik.values.landlord_city = "";
        }
    }, [formik.values.landlord_zip]);

    useEffect(() => {
        if (screens.client.data) {
            if (screens.client.data) {
                setIsZipSearch(false);
                const result = screens.client.data;
                if (!result?.leasing_business_space) {
                    setIsDisabled(true);
                }
                formik.setFieldValue("leasing_business_space", result?.leasing_business_space ? 1 : 0);
                formik.setFieldValue("present_lease_period", result?.present_lease_period ?? "");
                formik.setFieldValue("landlord_name", result?.landlord_name ?? "");
                formik.setFieldValue("landlord_monthly_rent", result?.landlord_monthly_rent ?? "");
                formik.setFieldValue("landlord_country_phone_code", result?.landlord_country_phone_code ?? "");
                formik.setFieldValue("landlord_phone", result?.landlord_phone ?? "");
                formik.setFieldValue("landlord_address", result?.landlord_address ?? "");
                formik.setFieldValue("landlord_zip", result?.landlord_zip ?? "");
                formik.setFieldValue("landlord_state", result?.landlord_state ?? "");
                formik.setFieldValue("landlord_city", result?.landlord_city ?? "");
                setPhoneCodesPlaceholders({
                    phone_code: result?.landlord_country_phone_code,
                });
            }
        }
    }, []);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCode(screens.prospect.countryCodes);
        }
    }, [screens.prospect.countryCodes]);

    return (
        <>
            <div className="main_brdr_class bank_info">
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        {/* Header */}
                        <div className="col-7">
                            <div className="">
                                <h5>
                                    <b>Landlord Information</b>
                                </h5>
                            </div>
                        </div>
                        {/* //Header Button */}
                        <div className="col-5 text-right">
                            {isDisabledButton ? (
                                <Button tooltip="Edit" tooltipOptions={{ position: "top" }} className="edit_icon" icon={<BsPencil />} type="button" onClick={() => setIsDisabledButton(false)} />
                            ) : (
                                <Button
                                    tooltip="Save"
                                    tooltipOptions={{ position: "top" }}
                                    className={isSaving ? "check_tick_icon_disabled" : "check_tick_icon"}
                                    icon={<BsCheck />}
                                    disabled={isSaving}
                                    type="button"
                                    onClick={() => formik.handleSubmit()}
                                />
                            )}
                        </div>
                    </div>
                    {/* Body */}
                    <div className="business_scrollbar">
                        <div className="grid">
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>Currently Leasing Office?</b>
                                    </p>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <CustomDropdownField
                                    mandatory="*"
                                    iden="leasing_business_space"
                                    label="Currently Leasing Office?"
                                    formik={formik}
                                    options={LEASING_BUSINESS_SPACE}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    onChange={(e) => inputHandler(e)}
                                    disabled={isDisabledButton}
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>Lease Period</b>
                                    </p>
                                </div>
                            </div>

                            <div className="md:col-6 col-12">
                                <CustomTextField
                                    iden="present_lease_period"
                                    label="Lease Period"
                                    maxLength={35}
                                    disabled={isDisabledButton || isDisabled}
                                    formik={formik}
                                    placeHolder="Enter Lease Period"
                                    onChange={(e) => inputHandler(e)}
                                    keyfilter={/^[a-zA-Z0-9\s]+$/}
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>Landlord Name</b>
                                    </p>
                                </div>
                            </div>

                            <div className="md:col-6 col-12">
                                <CustomTextField
                                    iden="landlord_name"
                                    label="Landlord Name"
                                    maxLength={40}
                                    disabled={isDisabledButton || isDisabled}
                                    formik={formik}
                                    placeHolder="Enter Landlord Name"
                                    onChange={(e) => inputHandler(e)}
                                    keyfilter={/^[a-zA-Z\s]+$/}
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>Monthly Rent?</b>
                                    </p>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                {/* <CustomTextField
                                    iden="landlord_monthly_rent"
                                    label="Monthly Rent"
                                    keyfilter={/^\d*\.?\d*$/}
                                    maxLength="11"
                                    disabled={isDisabledButton || isDisabled}
                                    formik={formik}
                                    placeHolder="$10000000.00"
                                    onChange={(e) => inputHandler(e)}
                                /> */}
                                <CustomInputField
                                    iden="landlord_monthly_rent"
                                    label="Monthly Rent"
                                    formik={formik}
                                    type="currency"
                                    placeHolder="$0.00"
                                    onPaste={(e) => e.preventDefault()}
                                    onValueChange={(e) => handleCurrencyChange(e, "landlord_monthly_rent")}
                                    min={0}
                                    disabled={isDisabledButton || isDisabled}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                    max={99999999.99}
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Phone No <span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <div className="border_div">
                                    <Dropdown
                                        id="landlord_country_phone_code"
                                        name="landlord_country_phone_code"
                                        value={formik.values.landlord_country_phone_code}
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_country"
                                        options={countryCode}
                                        optionLabel="phone_code"
                                        placeholder={phoneCodesPlaceholders.phone_code}
                                        filter
                                        filterBy="phone_code"
                                        emptyMessage="No results found"
                                        disabled={isDisabled || loading || isDisabledButton}
                                    />

                                    <InputMask
                                        name="landlord_phone"
                                        id="landlord_phone"
                                        mask="999-999-9999"
                                        value={formik.values.landlord_phone}
                                        placeholder="999-999-9999"
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_phone"
                                        disabled={isDisabledButton || isDisabled}
                                    ></InputMask>
                                </div>
                                {getFormErrorMessage("landlord_phone")}
                            </div>

                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>
                                            Zip Code <span className="clr_red">*</span>
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <div className="zip_menu_position" style={{ position: "relative" }}>
                                    <CustomTextField
                                        className=""
                                        iden="landlord_zip"
                                        label="Zip Code"
                                        disabled={isDisabledButton || isDisabled}
                                        mandatory="*"
                                        keyfilter="int"
                                        formik={formik}
                                        placeHolder="Search..."
                                        maxLength={5}
                                        onChange={(e) => inputHandler(e)}
                                    />
                                    <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                    <small className="p-error">{recordNotFoundMsg}</small>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>State</b>
                                        <span className="clr_red">*</span>
                                    </p>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <CustomTextField disabled iden="landlord_state" label="State" formik={formik} placeHolder="" />
                            </div>
                            <div className="md:col-6 col-12">
                                <div>
                                    <p className="pt-3">
                                        <b>City</b>
                                        <span className="clr_red">*</span>
                                    </p>
                                </div>
                            </div>
                            <div className="md:col-6 col-12">
                                <CustomTextField disabled iden="landlord_city" label="City" formik={formik} placeHolder="" />
                            </div>
                            <div className="col-12">
                                <CustomInputTextArea
                                    iden="landlord_address"
                                    label="Landlord Address"
                                    disabled={isDisabledButton || isDisabled}
                                    maxLength={150}
                                    formik={formik}
                                    rows="3"
                                    placeHolder="Enter Landlord Address"
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default LandlordInformation;
