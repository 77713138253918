import React from "react";

export default function ViewEye(props) {
    const { color, width = "20", height = "20" } = props;
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20 7C20 7 16.25 0.125 10 0.125C3.75 0.125 0 7 0 7C0 7 3.75 13.875 10 13.875C16.25 13.875 20 7 20 7ZM1.46587 7C1.53703 6.89151 1.61818 6.77097 1.70909 6.64052C2.1277 6.03991 2.74539 5.24113 3.54013 4.44638C5.15129 2.83523 7.35077 1.375 10 1.375C12.6492 1.375 14.8487 2.83523 16.4599 4.44638C17.2546 5.24113 17.8723 6.03991 18.2909 6.64052C18.3818 6.77097 18.463 6.89151 18.5341 7C18.463 7.10849 18.3818 7.22903 18.2909 7.35948C17.8723 7.96009 17.2546 8.75887 16.4599 9.55362C14.8487 11.1648 12.6492 12.625 10 12.625C7.35077 12.625 5.15129 11.1648 3.54013 9.55362C2.74539 8.75887 2.1277 7.96009 1.70909 7.35948C1.61818 7.22903 1.53703 7.10849 1.46587 7Z"
                    fill="#555960"
                />
                <path
                    d="M10 3.875C8.27411 3.875 6.875 5.27411 6.875 7C6.875 8.72589 8.27411 10.125 10 10.125C11.7259 10.125 13.125 8.72589 13.125 7C13.125 5.27411 11.7259 3.875 10 3.875ZM5.625 7C5.625 4.58375 7.58375 2.625 10 2.625C12.4162 2.625 14.375 4.58375 14.375 7C14.375 9.41625 12.4162 11.375 10 11.375C7.58375 11.375 5.625 9.41625 5.625 7Z"
                    fill="#555960"
                />
            </svg>
        </>
    );
}