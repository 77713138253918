import React, { useContext, useEffect, useState } from "react";
//Components

import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//context
import { ToastContext } from "../../../../context/toast";
import { FILE_MAX_SIZE, underwritingDocumentTypes } from "../../../../utils/Constants/global";
import { BsCheckLg, BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import { getCountUnderwritingDocument, saveClientUnderwritingAttachments } from "../../../../services/client";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { Button } from "primereact/button";
const UWDocumentsModal = (props) => {
    //props
    const { onHide, id, onCall, setFormDirty, setSaveCallStatus } = props;

    // states
    const [uploadedFile, setUploadedFile] = useState([]);
    const [totalFiles, setTotalFiles] = useState(0);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [cancelBtnDisabled, setCancelBtnDisabled] = useState(false);

    let validationSchema = Yup.object().shape({
        attachment_type: Yup.string().required("Document Type is required"),
        attachment_path: Yup.string().when([], {
            is: () => !uploadedFile || uploadedFile.length === 0,
            then: Yup.string().required("Attachment is required"),
            otherwise: Yup.string().notRequired(),
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            attachment_type: "",
            attachment_path: "",
        },
        onSubmit: async (data) => {
            setFormDirty(false);
            setDisabledBtn(true);
            setCancelBtnDisabled(true);
            setSaveCallStatus(true);
            try {
                data = {
                    ...data,
                    attachment_path: uploadedFile,
                };
                const formData = new FormData();
                formData.append("prospect_id", id);
                formData.append("attachment_type", data.attachment_type);
                data.attachment_path.forEach((file, index) => {
                    formData.append(`attachment_path[]`, file);
                });
                const res = await saveClientUnderwritingAttachments(formData);
                if (res.status) {
                    toast.showMessage("Attachment(s)", "Underwriting Document has been uploaded successfully.", "success");
                    onHide();
                    onCall();
                }
            } catch {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setDisabledBtn(false);
            setCancelBtnDisabled(false);
            setSaveCallStatus(false);
        },
    });

    // Validation
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    // Context
    const toast = useContext(ToastContext);

    const handleFileUpload = (event) => {
        const files = event.target.files;
        let upload = [];
        if (uploadedFile.length !== 5 && 5 - parseInt(uploadedFile.length) >= parseInt(files.length) && 5 - parseInt(totalFiles) >= parseInt(files.length)) {
            if (files.length > 0 && files.length <= 5) {
                upload = [...uploadedFile];
                for (let i = 0; i < files.length; i++) {
                    const fileType = files[i].name.split(".");
                    const index = fileType.length - 1;
                    const accept = ".pdf,.doc,.docx,.xls,.xlsx";
                    const check_file = accept.includes(fileType[index]);
                    if (check_file) {
                        const fileSize = files[i].size > FILE_MAX_SIZE.FILE_SIZE; // File should not be greater than 3 MB..
                        if (fileSize) {
                            toast.showMessage("Exceeding Limit", `${files[i]?.name} size is greater than allowed size.`, "error");
                        } else if (files[i].name.includes("#") || files[i].name.includes("&")) {
                            toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                        } else {
                            formik.setFieldError("attachment_path", "");
                            upload.push(files[i]);
                            setFormDirty(true);
                        }
                    } else {
                        toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                    }
                }
                setUploadedFile(upload);
            }
        } else {
            toast.showMessage("Exceeding Limit", "You are only authorized to upload maximum 5 files.", "error");
        }

        event.target.value = null;
    };
    const handleInputChange = (e) => {
        const { value } = e.target;
        formik.handleChange(e);
        handleCountDocuments(value);
        setFormDirty(true);
    };

    const handleCountDocuments = async (type) => {
        setDisabledBtn(true);
        try {
            let data = {
                prospect_id: id,
                attachment_type: type,
            };
            const res = await getCountUnderwritingDocument(data);
            if (res.data.status) {
                setTotalFiles(res.data.count);
                if (res.data.count === 5) {
                    toast.showMessage("Error", "You already uploaded 5 files for this document type.", "error");
                    setDisabledBtn(true);
                } else {
                    setDisabledBtn(false);
                }
            }
        } catch {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const handleRemove = (index) => {
        const updatedAttachments = uploadedFile.filter((_, i) => i !== index);
        setUploadedFile(updatedAttachments);
    };

    const handleCancel = () => {
        if (formik.values.attachment_type === "" && uploadedFile.length === 0) {
            onHide();
        } else {
            setIsFormDirty(true);
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField
                            type="dropdown"
                            iden="attachment_type"
                            label="Document Type"
                            mandatory="*"
                            formik={formik}
                            optionLabel="label"
                            optionValue="label"
                            onChange={(e) => handleInputChange(e)}
                            options={underwritingDocumentTypes}
                            placeHolder="Select Document Type"
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="NameTitle">
                            <b>Attachments</b>
                            <span className="clr_red">*</span>
                            <Button
                                type="button"
                                tooltip=".pdf, .doc, .docx, .xls, .xlsx files only (max size 3MB) "
                                tooltipOptions={{ position: "bottom" }}
                                icon={<BsInfoCircle />}
                                aria-label="Submit"
                                className="customTooltipicon"
                            />
                        </label>
                        <div className="file_upload_class">
                            <label htmlFor="file-upload" className={`flex flex-row justify-content-between align-items-center ${disabledBtn ? "disabled_choose_file_input" : ""}`}>
                                Choose File <BsPaperclip />
                            </label>
                        </div>
                        <input type="file" id="file-upload" multiple onChange={handleFileUpload} accept=".pdf, .doc, .docx, .xls, .xlsx" style={{ display: "none" }} />
                        {getFormErrorMessage("attachment_path")}
                    </div>
                    <div className="col-12">
                        <div>
                            {uploadedFile?.map((file, index) => (
                                <div key={index} className="image-item">
                                    <div>
                                        <div className="flex row" key={index}>
                                            <span className="text_break">{file?.name}</span>
                                            <BsXLg className="cursor-pointer cross_icon ml-1" onClick={() => handleRemove(index)} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-12 text-right pt-5">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} isDisabled={cancelBtnDisabled} />
                        <CustomAddNewButton title="Save" type="submit" isDisabled={disabledBtn} />
                    </div>
                </div>
            </form>
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={onHide}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default UWDocumentsModal;
