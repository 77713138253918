import React, { useState, useRef, useEffect, useContext } from "react";
//Components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
//styles
import "./table.scss";
import { PaginatorTemplate } from "../../../ui/PaginatorTemplate/PaginatorTemplate";
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
import { currencyFormatUSD } from "../../../utils/HelperFuctions/checkAmount";

const DataTableView = (props) => {
    const { totalClients, response, debtConcentrationLoading, onCustomPage, pagination, page, currentPage, pageSize, pageInputTooltip, setCurrentPage, setPage, setPageSize, setPageInputTooltip } =
        props;

    const dt = useRef(null);
    //filter State
    const [expandedRows, setExpandedRows] = useState(null);

    //HeaderName
    const header = (
        <div className="table-header">
            <div className="grid">
                <div className="col-12 md:col-9">
                    <div className="flex flex-row justify-align-content-start">
                        <h5 className="p-mx-0 p-my-1 pt-2">
                            <b>Debt Concentration per Client</b>
                        </h5>
                        <span className="sm__heading">{totalClients === 1 ? totalClients + " Client" : totalClients + " Clients"} </span>
                    </div>
                </div>
                <div className="col-12 md:col-3"></div>
            </div>
        </div>
    );

    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0}</>;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <DataTable showGridlines={true} value={data.debtors}>
                <Column field="debtor_name" header="Debtor Name" />
                <Column field="concentration" header="Concentration" body={concentrationTemplate}></Column>
                <Column field="total_debt" header="Amount" body={debtTemplate} headerClassName="amount_column_alignment" ></Column>
            </DataTable>
        );
    };

    const concentrationTemplate = (rowData) => {
        return (
            <>
                <div>
                    <span>{rowData.concentration.toFixed(2)}%</span>
                </div>
            </>
        );
    };

    const debtTemplate = (rowData) => {
        
        return (
            <>
                <div className="amount_column_alignment" >
                    <span >{currencyFormatUSD(rowData.total_debt)}</span>
                </div>
            </>
        );
    };

    return (
        <>
            <div className="col-12 lg:col-6 xl:col-6">
                <div className="card dashboard_table_scroll">
                    <DataTable
                        ref={dt}
                        value={response}
                        expandedRows={expandedRows}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onRowToggle={(e) => {
                            setExpandedRows(e.data);
                        }}
                        paginator
                        responsiveLayout="scroll"
                        header={header}
                        emptyMessage="No record available."
                        onPage={onCustomPage}
                        paginatorTemplate={PaginatorTemplate(
                            pagination.isFirstPage,
                            pagination.isLastPage,
                            currentPage,
                            pagination.totalPages,
                            pageSize,
                            page,
                            pagination.totalRecords,
                            pageInputTooltip,
                            setCurrentPage,
                            setPage,
                            setPageSize,
                            setPageInputTooltip
                        )}
                        rows={pageSize}
                    >
                        <Column expander={allowExpansion} style={{ width: "3em" }} />
                        <Column field="client_name" header="Client Name"></Column>
                        <Column field="concentration" header="Concentration" body={concentrationTemplate}></Column>
                        <Column field="total_debt" header="Amount" body={debtTemplate} headerClassName="amount_column_alignment" ></Column>
                       
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default DataTableView;
