import { useHistory } from "react-router";
import { store } from "../../redux";
import {
    onFormDirty,
    onProspectAttachment,
    onProspectBusinessProprietorFormDirty,
    onProspectCountryCodes,
    onProspectDataChange,
    onProspectFormDirty,
    onProspectIndexChange,
    onProspectIsEditing,
    onProspectKnobValue,
    onProspectSupplierFormDirty,
} from "../../redux/main-view";

export function SideBarHandler(value) {
    // if (value === "Prospects") {
    store.dispatch(onProspectIndexChange(0));
    store.dispatch(onProspectKnobValue(0));
    store.dispatch(onProspectDataChange(null));
    store.dispatch(onProspectAttachment(null));
    store.dispatch(onProspectIsEditing(false));
    store.dispatch(onProspectFormDirty(false));
    store.dispatch(onProspectBusinessProprietorFormDirty(false));
    store.dispatch(onProspectSupplierFormDirty(false));
    store.dispatch(onProspectCountryCodes(null));
    localStorage.removeItem("isEditing");
    store.dispatch(onFormDirty(false));
    // } else if (value === "Client") {
    // store.dispatch(onFormDirty(false));
    localStorage.removeItem("status");
    localStorage.removeItem("MenuData");
    // } else {
    // localStorage.removeItem("status");
    // localStorage.removeItem("MenuData");

    // }
}
