import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import AddNewPolicy from "../Components/addnewpolicy";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { SETTINGS_RIGHTS } from "../../../../utils/Constants/rights";
import { loginState } from "../../../../redux/login";
import { useSelector } from "react-redux";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";
//dialog
const HeaderPolicies = (props) => {
    // Props
    const { selectedAnnoucementsLength, fetchPolicies } = props;
    const [formDirty, setFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // Redux
    const { user } = useSelector(loginState);
    const [addNewPolicy, setAddNewPolicy] = useState(false);
    const onCancel = (refresh) => {
        if (refresh) {
            fetchPolicies();
        }
        setAddNewPolicy(false);
    };
    return (
        <>
            <Dialog
                header="Add New Policy"
                visible={addNewPolicy}
                onHide={() => {
                    formDirty ? setIsVisible(true) : onCancel(false);
                }}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
            >
                <AddNewPolicy onHide={(refresh) => onCancel(refresh)} setFormDirty={setFormDirty} />
            </Dialog>
            {validateUserPermissions([SETTINGS_RIGHTS.policies.add_new_policies], user?.rights) && (
                <CustomAddNewButton title="Add New Policy" onClick={() => setAddNewPolicy(true)}></CustomAddNewButton>
            )}
            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setAddNewPolicy(false);
                        setIsVisible(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default HeaderPolicies;
