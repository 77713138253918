export const DASHBOARD_RIGHTS = {
    view: "dashboard_dashboardview",
};

export const MANAGMENT_RIGHTS = {
    clients: {
        view: "managementclients_clientsview",
        delete: "managementclients_deleteclient",
        selection: "managementclients_clientselection",
        profile: "managementclients_clientprofile",
        documents: "managementclients_clientdocuments",
        notice_of_assignment: "managementclients_noticeofassignment",
        users: "managementclients_users",
        invite_client: "managementclients_inviteclient",
        change_status: "managementclients_changestatus",
        resend_invite: "managementclients_resendinvite",
    },
    debtors: {
        view: "managementdebtors_debtorsview",
        add_new: "managementdebtors_addnewdebtor",
        status_change: "managementdebtors_debtorstatuschange",
        selection: "managementdebtors_debtorselection",
        profile: "managementdebtors_debtorprofile",
        notice_of_assignment: "managementdebtors_noticeofassignment",
    },
    prospects: {
        view: "managementprospects_prospectsview",
        add_new: "managementprospects_addnewprospect",
        delete: "managementprospects_deleteprospect",
        promote_to_client: "managementprospects_promotetoclient",
        demote_to_fallout: "managementprospects_demotetofallout",
        selection: "managementprospects_prospectselection",
        profile: "managementprospects_prospectprofile",
        business_verification: "managementprospects_businessverification",
        termsheet: "managementprospects_termsheet",
        status_change: "managementprospects_prospectstatuschange",
        email_reminder: "managementprospects_emailreminder",
    },
    brokers: {
        view: "managementbrokers_brokersview",
        add_new: "managementbrokers_addnewbroker",
        status_change: "managementbrokers_brokerstatuschange",
        send_agreement: "managementbrokers_sendagreement",
        selection: "managementbrokers_brokerselection",
        invite_broker: "managementbrokers_invitebroker",
    },
};

export const TRANSACTION_RIGHTS = {
    invoices: {
        view: "transactionsinvoices_invoicesview",
        add_new_invoice: "transactionsinvoices_addnewinvoice",
        move_to_schedule: "transactionsinvoices_movetoschedule",
        delete_invoice: "transactionsinvoices_deleteinvoice",
        invoice_selection: "transactionsinvoices_invoiceselection",
        approval_denial: "transactionsinvoices_invoiceapprovaldenial",
    },
    schedule_of_assignment: {
        view: "transactionsscheduleofassignment_scheduleofassignmentview",
        add_new_schedule: "transactionsscheduleofassignment_addnewschedule",
        verify_schedule: "transactionsscheduleofassignment_verifyschedule",
        download_schedule: "transactionsscheduleofassignment_downloadschedule",
        deleted_schedule: "transactionsscheduleofassignment_deleteschedule",
        schedule_selection: "transactionsscheduleofassignment_scheduleselection",
    },
    schedule_of_funding: {
        view: "transactionsscheduleoffunding_scheduleoffundingview",
        post_payment: "transactionsscheduleoffunding_postpayment",
        show_payment: "transactionsscheduleoffunding_showpayment",
        revert_verification: "transactionsscheduleoffunding_revertverification",
        download_schedule: "transactionsscheduleoffunding_downloadschedule",
        schedule_selection: "transactionsscheduleoffunding_scheduleselection",
    },
    collections: {
        view: "transactionscollections_collectionsview",
        add_new_collection: "transactionscollections_addnewcollection",
        schedule_notes: "transactionscollections_schedulenotes",
        download_report: "transactionscollections_downloadreport",
    },
    hold_accounts: {
        view: "transactionsholdaccounts_holdaccountsview",
        transfer_to_cash_reservers: "transactionsholdaccounts_transfertocashreserves",
    },
    reserves: {
        view: "transactionsreserves_reservesview",
        reserve_release_selection: "transactionsreserves_reservereleaseselection",
        create_rr_batch: "transactionsreserves_createrrbatch",
    },
    charge_backs: {
        view: "transactionschargebacks_chargebacksview",
        charge_back_adjustment: "transactionschargebacks_chargebackadjustment",
    },
    broker_payment: {},
};

export const CREDIT_REQUEST_RIGHTS = {
    client: {
        view: "creditrequestclientrequest_clientrequestview",
        add_new_request: "creditrequestclientrequest_addnewrequest",
        approve_request: "creditrequestclientrequest_approverequest",
        deny_request: "creditrequestclientrequest_denyrequest",
        selection: "creditrequestclientrequest_clientrequestselection",
    },
    debtor: {
        view: "creditrequestdebtorrequest_debtorrequestview",
        add_new_request: "creditrequestdebtorrequest_addnewrequest",
        approve_request: "creditrequestdebtorrequest_approverequest",
        deny_request: "creditrequestdebtorrequest_denyrequest",
        selection: "creditrequestdebtorrequest_debtorrequestselection",
    },
};

export const UNDERWRITING_RIGHTS = {
    view: "underwriting_underwritingview",
    add_new_vertical: "underwriting_addnewvertical",
    edit_vertical: "underwriting_editvertical",
    delete_vertical: "underwriting_deletevertical",
    add_task: "underwriting_addtask",
    edit_task: "underwriting_edittask",
    delete_task: "underwriting_deletetask",
};

export const REPORTS_RIGHTS = {
    purchase_summary_with_fee: {
        view: "reportspurchasesummarywithfee_reportview",
        excel: "reportspurchasesummarywithfee_exporttoexcel",
        pdf: "reportspurchasesummarywithfee_exporttopdf",
    },
    purchase_without_fee: {
        view: "reportspurchasesummarywithoutfee_reportview",
        excel: "reportspurchasesummarywithoutfee_exporttoexcel",
        pdf: "reportspurchasesummarywithoutfee_exporttopdf",
    },
    invoices_summary_aging: {
        view: "reportsinvoicesummaryaging_reportview",
        excel: "reportsinvoicesummaryaging_exporttoexcel",
        pdf: "reportsinvoicesummaryaging_exporttopdf",
    },
    payment_history_detail: {
        view: "reportspaymenthistorydetail_reportview",
        excel: "reportspaymenthistorydetail_exporttoexcel",
        pdf: "reportspaymenthistorydetail_exporttopdf",
    },
    reserve_details: {
        view: "reportsreservedetails_reportview",
        excel: "reportsreservedetails_exporttoexcel",
        pdf: "reportsreservedetails_exporttopdf",
    },
    escrow_reserve: {
        view: "reportsescrowreserve_reportview",
        excel: "reportsescrowreserve_exporttoexcel",
        pdf: "reportsescrowreserve_exporttopdf",
    },
    credit_limit_warning: {
        view: "reportscreditlimitwarning_reportview",
        excel: "reportscreditlimitwarning_exporttoexcel",
        pdf: "reportscreditlimitwarning_exporttopdf",
    },
    last_payment_date: {
        view: "reportslastpaymentdate_reportview",
        excel: "reportslastpaymentdate_exporttoexcel",
        pdf: "reportslastpaymentdate_exporttopdf",
    },

    charge_backs: {
        view: "reportschargebacks_reportview",
        excel: "reportschargebacks_exporttoexcel",
        pdf: "reportschargebacks_exporttopdf",
    },
    client_analysis: {
        view: "reportsclientanalysis_reportview",
        excel: "reportsclientanalysis_exporttoexcel",
        pdf: "reportsclientanalysis_exporttopdf",
    },

    client_risk_management: {
        view: "reportsclientriskmanagementdetail_reportview",
        excel: "reportsclientriskmanagementdetail_exporttoexcel",
        pdf: "reportsclientriskmanagementdetail_exporttopdf",
    },
    broker_commission: {
        view: "reportsbrokercommission_reportview",
        excel: "reportsbrokercommission_exporttoexcel",
        pdf: "reportsbrokercommission_exporttopdf",
    },
    unapplied_cash_received: {
        view: "reportsunappliedcashreceived_reportview",
        excel: "reportsunappliedcashreceived_exporttoexcel",
        pdf: "reportsunappliedcashreceived_exporttopdf",
    },
    collection_efficiency_analysis: {
        view: "reportscollectionefficiencyanalysis_reportview",
        excel: "reportscollectionefficiencyanalysis_exporttoexcel",
        pdf: "reportscollectionefficiencyanalysis_exporttopdf",
    },
    cash_posting_report: {
        view: "reportscashposting_reportview",
        excel: "reportscashposting_exporttoexcel",
        pdf: "reportscashposting_exporttopdf",
    },
    debtor_aging_report: {
        view: "reportsdebtoraging_reportview",
        excel: "reportsdebtoraging_exporttoexcel",
        pdf: "reportsdebtoraging_exporttopdf",
    },
    transactions: {
        view: "reportstransactions_reportview",
        excel: "reportstransactions_exporttoexcel",
        pdf: "reportstransactions_exporttopdf",
    },
};

export const SUPPORT_RIGHTS = {
    view: "support_supportview",
    add_new_ticket: "support_addnewticket",
    change_status: "support_ticketstatuschange",
    assign_ticket: "support_ticketassignment",
    ticket_selection: "support_ticketresponse",
};

export const USERMANAGMENT_RIGHTS = {
    // User
    user_profile_view: "usermanagementuserprofiles_userprofilesview",
    user_profile_selection: "usermanagementuserprofiles_userselection",
    user_profile_adduser: "usermanagementuserprofiles_addnewuser",
    user_profile_statuschange: "usermanagementuserprofiles_userstatuschange",
    //Role
    role_rights_view: "usermanagementrolesrights_rolesrightsview",
    role_rights_selection: "usermanagementrolesrights_roleselection",
    role_rights_addrole: "usermanagementrolesrights_addnewrole",
    role_rights_statuschange: "usermanagementrolesrights_rolestatuschange",
    role_rights_deleterole: "usermanagementrolesrights_deleterole",
};

export const SETTINGS_RIGHTS = {
    announcments: {
        view: "settingsannouncements_announcementsview",
        add_new_announcment: "settingsannouncements_addnewannouncement",
        delete_announcement: "settingsannouncements_deleteannouncement",
        announcement_selection: "settingsannouncements_announcementselection",
    },
    policies: {
        view: "settingspolicies_policiesview",
        add_new_policies: "settingspolicies_addnewpolicy",
        delete_policies: "settingspolicies_deletepolicy",
        policies_selection: "settingspolicies_policyselection",
    },
};

export const CHATS_RIGHTS = {
    view: "chats_chatsview",
};

export const DOCUMENT_RIGHTS = {
    view: "documents_documentsview",
    document_selection: "documents_documentsselection",
};
export const GENERAL_LEDGER_RIGHTS = {
    view: "generalledger_ledgerview",
    download_statement: "generalledger_downloadstatement",
};
