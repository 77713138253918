import React, { useEffect, useState } from "react";
//Styles
import "./filterbar.scss";
//components
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import { Button } from "primereact/button";
import moment from "moment";
import { Toast } from "react-bootstrap";
import { fetchGeneralLedger } from "../../../../services/general-ledger";
import { getClientDropdown } from "../../../../services/client";
import { getDebtorsForDropdown } from "../../../../services/debtor";

const FilterBarLedger = (props) => {
    const { toast, setShowLedgerCards, setSelectedYear, setLedgerData, setLoading, transactionType, setTransactionType, clientDebtor, setClientDebtor, setSelectedColumns, columns, setData } = props;

    //UseState
    const [disableButton, setDisableButton] = useState(false);
    const [fiscalYears, setFiscalYears] = useState(null);
    const [dropdownClients, setDropdownClients] = useState([]);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [clientLoading, setClientLoading] = useState(false);

    //Formik
    const validationSchema = Yup.object().shape({
        client_debtor: Yup.mixed().required("Fiscal Year is required"),
        fiscal_year: Yup.mixed().required("Fiscal Year is required"),

        // id: Yup.mixed().required("Client Name is required"),
        id: Yup.mixed().when("client_debtor", {
            is: "client",
            then: Yup.mixed().required("Client Name is required"),
        }),
        debtor_id: Yup.mixed().when("client_debtor", {
            is: "debtor",
            then: Yup.mixed().required("Debtor Name is required"),
        }),

        transaction_type: Yup.mixed().required("Transaction Type is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            client_debtor: clientDebtor,
            fiscal_year: "",
            id: "",
            debtor_id: "",
            transaction_type: "",
        },
        onSubmit: async (data) => {
            data.data_type = clientDebtor ? clientDebtor : "client";
            setShowLedgerCards(false);
            setData(data);
            data.download = false;
            setDisableButton(true);
            setLoading(true);
            try {
                const res = await fetchGeneralLedger(data);
                if (res) {
                    if (res.data.status) {
                        setLedgerData(res.data.ledger_data);

                        let updatedColumns = columns;
                        if (transactionType == "debit") {
                            const fieldToRemove = "credit_amount";
                            updatedColumns = updatedColumns.filter((column) => column.field !== fieldToRemove);
                        } else if (transactionType == "credit") {
                            const fieldToRemove = "debit_amount";
                            updatedColumns = updatedColumns.filter((column) => column.field !== fieldToRemove);
                        }

                        if (clientDebtor == "debtor") {
                            const fieldToRemove = "debtor_name";
                            updatedColumns = updatedColumns.filter((column) => column.field !== fieldToRemove);
                        }

                        setSelectedColumns(updatedColumns);

                        setShowLedgerCards(true);
                    } else {
                        toast.showMessage("Error", res.data.message, "error");
                    }
                } else {
                    toast.showMessage("Error", "Server error.", "error");
                }
                setDisableButton(false);
                setLoading(false);
            } catch (e) {
                setDisableButton(true);
                setDisableButton(false);
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });
    //Dropdown Option
    const transactiontypeOptions = [
        {
            name: "All",
            value: "all",
        },
        {
            name: "Debit",
            value: "debit",
        },
        {
            name: "Credit",
            value: "credit",
        },
    ];

    const fiscalYearsDropDown = () => {
        const startingYear = 2020;
        const currentYear = moment().year();

        let years = [];
        for (let year = startingYear; year <= currentYear; year++) {
            years.push({
                name: year - 1 + " - " + year,
                value: year,
            });
        }
        setFiscalYears(years);
    };

    const handleRadioChange = (event) => {
        formik.setFieldValue("client_debtor", event.target.value);
        setClientDebtor(event.target.value);
    };

    // getClientDropdown
    const fetchClients = async (searchValue = null) => {
        setClientLoading(true);
        try {
            let payload = {
                per_page: 100,
                show_all: 1,
                search_text: searchValue ? searchValue : "",
            };
            const res = await getClientDropdown(payload);
            if (res.data.status) {
                let _data = res.data.prospects.data;

                setDropdownClients(_data);
                setClientLoading(false);
            }
        } catch (e) {
            setClientLoading(false);
            Toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchDebtors = async (searchValue = null) => {
        setClientLoading(true);
        try {
            // let payload = {
            //     per_page: 100,
            //     show_all: 1,
            //     search_text: searchValue ? searchValue : "",
            // };
            const res = await getDebtorsForDropdown(); //payload
            if (res.data.status) {
                let _data = res.data.debtors;

                setDropdownDebtors(_data);
                setClientLoading(false);
            }
        } catch (e) {
            setClientLoading(false);
            Toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // Use Effects
    useEffect(() => {
        if (!fiscalYears || fiscalYears?.length > 0) {
            fiscalYearsDropDown();
        }
    }, []);

    useEffect(() => {
        if (clientDebtor && clientDebtor == "client") {
            fetchClients();
        } else {
            fetchDebtors();
        }
    }, [clientDebtor]);

    useEffect(() => {
        if (formik?.values?.fiscal_year) {
            setSelectedYear(formik.values.fiscal_year);
        }
        if (formik?.values?.transaction_type) {
            setTransactionType(formik.values.transaction_type);
        }
    }, [formik.values]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid">
                            <CustomInputField
                                className="col-12 md:col-4"
                                iden="fiscal_year"
                                label="Fiscal Year"
                                type="dropdown"
                                formik={formik}
                                mandatory="*"
                                options={fiscalYears}
                                optionLabel="name"
                                placeHolder="Select"
                                disabled={fiscalYears?.length == 0}
                            />

                            <div className="col-12 md:col-4">
                                <div style={{ marginBottom: "-2px" }}>
                                    <input type="radio" name="selectCheck" value="client" onChange={handleRadioChange} checked={clientDebtor === "client"} id="gl_client" for="gl_client" />
                                    <span className="ml-2 mr-2">Client</span>
                                    <input type="radio" name="selectCheck" value="debtor" onChange={handleRadioChange} checked={clientDebtor === "debtor"} id="gl_debtor" for="gl_debtor" />
                                    <span className="ml-2 mr-2">Debtor</span>
                                </div>

                                {clientDebtor && clientDebtor == "client" ? (
                                    <CustomInputField
                                        iden="id"
                                        type="dropdown"
                                        formik={formik}
                                        mandatory="*"
                                        options={dropdownClients}
                                        optionLabel="company_business_name"
                                        optionValue="id"
                                        placeHolder="Select"
                                        filterBy="company_business_name"
                                        className="hide_input_iden"
                                        filter={true}
                                        disabled={clientLoading}
                                    />
                                ) : (
                                    <CustomInputField
                                        iden="debtor_id"
                                        type="dropdown"
                                        formik={formik}
                                        mandatory="*"
                                        options={dropdownDebtors}
                                        optionLabel="legal_name"
                                        optionValue="id"
                                        placeHolder="Select"
                                        filterBy="legal_name"
                                        className="hide_input_iden"
                                        filter={true}
                                        disabled={clientLoading}
                                    />
                                )}
                            </div>
                            <CustomInputField
                                className="col-12 md:col-3"
                                iden="transaction_type"
                                label="Transaction Type"
                                type="dropdown"
                                formik={formik}
                                mandatory="*"
                                options={transactiontypeOptions}
                                optionLabel="name"
                                placeHolder="Select"
                            />
                            <div className="col-12 md:col-1">
                                <Button className="fetch_button" type="submit" disabled={disableButton || clientLoading}>
                                    Fetch
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default FilterBarLedger;
