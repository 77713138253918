import React, { useEffect, useState } from "react";

// Components
import Body from "./GridView/Body";
import FilterBar from "./GridView/FilterBar";

// Constants
import { CLIENT_HEADERS } from "../../../utils/Constants/global";
import { useSelector } from "react-redux";
import { mainViewState } from "../../../redux/main-view";
import { fetchProspect } from "../../../services/prospect";

const ClientIndex = () => {
    // States
    const [selectedUsers, setSelectedUsers] = useState([]);
    //Rdux
    const { screens } = useSelector(mainViewState);
    // State
    const [response, setResponse] = useState([]);
    const [keyword, setKeyword] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState(CLIENT_HEADERS);
    const [count, setCount] = useState({
        total_duly_filled_applications: 0,
        total_fallouts: 0,
        total_prospects: 0,
    });
    const [kebabMenuOption, setKebabMenuOption] = useState({
        promote_to_client: false,
        demote_to_fallout: false,
        delete: false,
    });
    const [status, setStatus] = useState(0);
    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });
    // View States
    const [loading, setLoading] = useState(true);
    const [showAll, setShowAll] = useState(false);
    // API'S Handler
    const getProspects = async (page) => {
        setLoading(true);
        let payload = {
            per_page: pageSize,
            show_all: showAll,
            search_text: keyword === null ? "" : keyword,
            application_completely_filled: true,
            page: page ? page : currentPage,
            status: 1,
        };
        if (sortField) {
            payload["sort_by"] = sortField;
        }
        if (sortOrder) {
            payload["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
        }
        const res = await fetchProspect(payload);

        if (res.data.status) {
            setResponse(res.data.prospects.data);
            setPagination({
                ...pagination,
                totalRecords: res.data.prospects.total,
                totalPages: res.data.prospects.last_page,
                isLastPage: res.data.prospects.last_page,
            });
        }
        setLoading(false);
    };

    // UseEffect
    // useEffect(() => {
    //     if (keyword) {
    //         const delayDebounceFn = setTimeout(() => {
    //             setCurrentPage(1);
    //             setPage(1);
    //             getProspects(1);
    //         }, 350);
    //         return () => clearTimeout(delayDebounceFn);
    //     } else {
    //         getProspects();
    //     }
    // }, [screens.prospect.isMutated, keyword, sortField, showAll, sortOrder, pageSize, currentPage, status]);

    useEffect(() => {
        getProspects();
    }, [screens.prospect.isMutated, sortField, showAll, sortOrder, pageSize, currentPage, status]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getProspects();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    return (
        <>
            <FilterBar
                showAll={showAll}
                setShowAll={setShowAll}
                setSelectedColumns={setSelectedColumns}
                setKeyword={setKeyword}
                keyword={keyword}
                selectedUsers={selectedUsers}
                setKebabMenuOption={setKebabMenuOption}
            />
            <Body
                showAll={showAll}
                setShowAll={setShowAll}
                setSelectedColumns={setSelectedColumns}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                response={response}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                page={page}
                setPage={setPage}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
            />
        </>
    );
};

export default ClientIndex;
