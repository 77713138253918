import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useRef } from "react";
import { currencyFormat } from "../../../../../utils/HelperFuctions/checkAmount";

const NoneFinancedSchedules = (props) => {
    const { response } = props;
    const dt = useRef(null);
    const handleTemplate = (rowData) => {
        if (rowData) {
            return "Not Funded";
        }
    };
    const handleScheduleTemplate = (rowData) => {
        if (rowData.net_schedule_funding === null || rowData.net_schedule_funding === 0) {
            return "$0.00";
        } else if (rowData.net_schedule_funding) {
            return currencyFormat(rowData.net_schedule_funding);
        }
    };
    return (
        <>
            <div className="grid">
                <div className="col-12 pt-3">
                    <DataTable ref={dt} value={response} emptyMessage="No record available.">
                        <Column field="schedule_no" header="Schedule No"></Column>
                        <Column body={handleScheduleTemplate} header="Net Schedule Funding"></Column>
                        <Column body={handleTemplate} header="Funding Date"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default NoneFinancedSchedules;
