import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export async function getClient(data) {
    return postData(appUrl["client"].fetchAll, data);
}

export async function getClientDropdown(data) {
    return postData(appUrl["client"].fetchDropdown, data);
}
export async function getClientBusinessName(data) {
    return postData(appUrl["client"].fetchBusinessName, data);
}
export async function getClientTermSheet(data) {
    return postData(appUrl["client"].fetchTermSheetDetail, data);
}
export async function getClientDebtor(data) {
    return postData(appUrl["client"].fetchingDebtor, data);
}
export async function getClientAllInformation(data) {
    return postData(appUrl["client"].clientAllInformation, data);
}
export async function postClientTerminationDate(data) {
    return postData(appUrl["client"].saveTerminationDate, data);
}
export async function getClientAllInvoicesInformation(data) {
    return postData(appUrl["client"].clientAllInvoicesInformation, data);
}
export async function saveClientUnderwritingAttachments(data) {
    return postData(appUrl["client"].saveUnderwritingAttachments, data);
}
export async function deleteClientUnderwritingAttachments(data) {
    return postData(appUrl["client"].deleteUnderwritingAttachments, data);
}
export async function getCountUnderwritingDocument(data) {
    return postData(appUrl["client"].totalClientUnderwritingDocuments, data);
}
export async function getUWAllDocs(data) {
    return postData(appUrl["client"].fetchAllClientUWDocs, data);
}
export async function getUniqueClientAttachmentType(data) {
    return postData(appUrl["client"].fetchUniqueAttachmentType, data);
}
export async function getClientUsers(data) {
    return postData(appUrl["client"].fetchClientUsers, data);
}
export async function inviteClient(data) {
    return postData(appUrl["client"].invite, data);
}
export async function resendInvite(data) {
    return postData(appUrl["client"].resendClientInvite, data);
}

export async function fetchW9Form(data) {
    return postData(appUrl["client"].fetchw9Form, data);
}
export async function sendw9Form(data) {
    return postData(appUrl["client"].sendw9Form, data);
}

export async function fetch8221Form(data) {
    return postData(appUrl["client"].fetch8821Form, data);
}
export async function send8221Form(data) {
    return postData(appUrl["client"].send8821Form, data);
}

export async function fetchContract(data) {
    return postData(appUrl["client"].fetchContract, data);
}

export async function saveContract(data) {
    return postData(appUrl["client"].saveContract, data);
}

export async function postW9Attachment(data) {
    return postData(appUrl["client"].saveAttachmentw9Form, data);
}

export async function countW9Attachment(data) {
    return postData(appUrl["client"].countAttachmentw9Form, data);
}

export async function getW9Attachment(data) {
    return postData(appUrl["client"].fetchAttachmentw9Form, data);
}

export async function post8821Attachment(data) {
    return postData(appUrl["client"].saveAttachment8821Form, data);
}

export async function count8821Attachment(data) {
    return postData(appUrl["client"].countAttachment8821Form, data);
}

export async function get8821Attachment(data) {
    return postData(appUrl["client"].fetchAttachment8821Form, data);
}

export async function deleteW9Attachment(data) {
    return postData(appUrl["client"].deleteAttachmentw9Form, data);
}

export async function delete8821Attachment(data) {
    return postData(appUrl["client"].deleteAttachment8821Form, data);
}