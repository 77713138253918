import React, { useContext, useEffect, useState } from "react";
//components
import * as Yup from "yup";
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { postClientTerminationDate } from "../../../../services/client";
import { ToastContext } from "../../../../context/toast";
import { getDateFormat } from "../../../../utils/HelperFuctions/dateFormat";
import { BsCheckLg } from "react-icons/bs";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";

const TerminateModal = (props) => {
    const { onHide, setIsDate, setDate, selectedUsers } = props;

    // states
    const [disableButton, setDisableButton] = useState(false);
    const [minimumDate, setMinimumDate] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    //formik
    const validationSchema = Yup.object().shape({
        termination_date: Yup.string().required("Termination Date is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            termination_date: "",
        },
        onSubmit: async (data) => {
            setDisableButton(true);

            data = {
                terminated_at: getDateFormat(data.termination_date),
                id: selectedUsers[0]?.id,
            };
            setDate(data);
            try {
                const res = await postClientTerminationDate(data);
                if (res.data.status) {
                    setIsDate(true);
                    onHide();
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            onHide();
        }
    };
    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date(today);
        const date = tomorrow.setDate(tomorrow.getDate() + 1);
        setMinimumDate(new Date(date));
    }, []);
    return (
        <>
            <div>
                <p className="text-center pb-4">Are you sure, you want to terminate client? This action cannot be reverted.</p>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <label htmlFor="phone" className="step-fields-title">
                            <b>Termination Date</b>
                            <span className="clr_red">*</span>
                        </label>
                        <div className="mt-2">
                            <Calendar
                                id="termination_date"
                                name="termination_date"
                                showIcon
                                placeholder="mm/dd/yyyy"
                                mask="99/99/9999"
                                value={formik.values.termination_date}
                                onChange={formik.handleChange}
                                minDate={minimumDate}
                                monthNavigator
                                yearNavigator
                                yearRange="1945:2050"
                            />
                        </div>
                        {getFormErrorMessage("termination_date")}
                    </div>
                    <div className="col-12 text-right mt-3">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} isDisabled={disableButton} />
                        <CustomAddNewButton title="Confirm" type="submit" isDisabled={disableButton} />
                    </div>
                </div>
            </form>
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={() => onHide()}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default TerminateModal;
