import React, { useState, useEffect, useContext } from "react";

//components

import TopSection from "../../../DebtorModule/DebtorDashboard/Components/TopSection";
import ProgressBarCard from "../../../../components/dashboardcomponents/DebtorDashboardBar/ProgressBarCard";
import { fetchInvoiceAnalytics } from "../../../../services/dashboard";
import { ToastContext } from "../../../../context/toast";
import Invoices from "../../../Operations/Screens/Transactions/Invoices/Screens";

const DebtorDashboard = (props) => {
    const [analyticsLoading, setAnalyticsLoading] = useState(false);
    const [invoiceAnalytics, setInvoiceAnalytics] = useState({
        collected: 0,
        due_30: 0,
        over_due: 0,
        charge_back: 0,
        collected_percentage: 0,
        due_30_percentage: 0,
        over_due_percentage: 0,
        charge_back_percentage: 0,
    });
    const [selectedStatus, setSelectedStatus] = useState(0);

    //toast message
    const toast = useContext(ToastContext);

    // Handlers
    const fetchInvoicesAnalytics = async () => {
        setAnalyticsLoading(true);
        try {
            const res = await fetchInvoiceAnalytics();
            if (res.data.status) {
                setInvoiceAnalytics(res.data.analytics);
                setAnalyticsLoading(false);
            }
        } catch (e) {
            setAnalyticsLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    // Use Effect
    useEffect(() => {
        fetchInvoicesAnalytics();
    }, []);

    let discount_days = invoiceAnalytics?.discount_days ? invoiceAnalytics.discount_days : 0;

    const progressData = [
        {
            title: "Paid Invoices",
            value: invoiceAnalytics.collected_percentage,
            total: invoiceAnalytics.collected,
            text: "Paid Invoices",
            progressColor: "#27B5FD",
        },
        // {
        //     title: "Unverified Invoices",
        //     value: invoiceAnalytics.unverified_invoices_percentage,
        //     total: invoiceAnalytics.unverified_invoices,
        //     text: "Unverified Invoices",
        //     progressColor: "#26BB91",
        // },
        {
            title: "Due Invoices",
            value: invoiceAnalytics.due_30_percentage,
            total: invoiceAnalytics.due_30,
            text: "Due Under " + discount_days + " days",
            progressColor: "#3165CB",
        },
        {
            title: "Over Due",
            value: invoiceAnalytics.over_due_percentage,
            total: invoiceAnalytics.over_due,
            text: "Over Due Invoices",
            progressColor: "#F3654A",
        },
        {
            title: "Chargeback(s)",
            value: invoiceAnalytics.charge_back_percentage,
            total: invoiceAnalytics.charge_back,
            text: "Chargeback(s) Invoices",
            progressColor: "#26BB91",
        },
    ];
    return (
        <>
            <TopSection />
            <div className="grid">
                <ProgressBarCard data={progressData} />
            </div>

            <Invoices selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
        </>
    );
};

export default DebtorDashboard;
