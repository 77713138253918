import React from "react";
import FactorFlow from "../src/Images/Logo.png";
export const LinkExpireScreen = () => {
    return (
        <div className="link_expire_background">
            <div>
                <img src={FactorFlow} alt="logo" width="187" height="33" />
            </div>

            <div className="link_expire_container">
                <div>
                    <p className="link_expired_heading">Link Expired</p>
                </div>
                <div>
                    <p className="link_expire_text">Link can only be used once.</p>
                </div>
            </div>
        </div>
    );
};

export default LinkExpireScreen;
