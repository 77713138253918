import React, { useContext, useEffect, useState } from "react";

// Components
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../../../../ui/KebabMenu/KebabMenuComponent";
import { UNAPPLIED_CASH_GRID_HEADERS, UNAPPLIED_CASH_RELEASED, UNAPPLIED_CASH_WITHOUT_GRID_HEADERS } from "../../../../../../../utils/Constants/global";
import AccountAdjustmentModal from "../../Components/AccountAdjustmentModal";
import { Dialog } from "primereact/dialog";
import { ToastContext } from "../../../../../../../context/toast";
import { loginState } from "../../../../../../../redux/login";
import { useSelector } from "react-redux";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { Checkbox } from "primereact/checkbox";
import CustomAddNewButton from "../../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import ReturnToDebtorComponent from "../../Components/ReturnToDebtorComponent";
import Legends from "../../../../../../../ui/Legends/Legends";

// Constants

const FilterBar = (props) => {
    const {
        setShowAll,
        showAll,
        setKeyword,
        keyword,
        setSelectedColumns,
        selectedColumns,
        selectedInvoices,
        setSelectedInvoices,
        kebabMenuOption,
        setKebabMenuOption,
        fetchInvoices,
        header,
        componentName,
        setModalOpen,
        fetchUCNonInvoice,
    } = props;
    // State
    const [onSelectionColumn, setOnSelectionColumn] = useState([]);
    const [kebab_menu_items, setKebabMenuItems] = useState([]);
    const [returnDebtorMenu, setReturnDebtorMenu] = useState(false);

    // Redux
    const { user } = useSelector(loginState);

    //Kebab Menu Items
    const UNAPPLIED_KEBAB_MENU = [
        { title: "Transfer to cash reserves", status: 1, isDisabled: selectedInvoices.length !== 1 },
        { title: "Transfer to collection", status: 2, isDisabled: selectedInvoices.length == 0 }
    ];
    const UNAPPLIED_WITHOUT_KEBAB_MENU = [{ title: "Return to Debtor", status: 3, isDisabled: selectedInvoices.length !== 1 }];
    // const kebabMenuItems = [{ title: "Transfer to cash reserves", status: 1, isDisabled: selectedInvoices.length === 0 }];

    //toast message
    const toast = useContext(ToastContext);

    //Handlers
    const handleOpenMenuItems = (status) => {
        if (status === 1) {
            let status = false;
            if (selectedInvoices.length > 0) {
                let prospectID = selectedInvoices[0].prospect_id;

                // Checking all the invoices against prospectID variable
                status = selectedInvoices.some((invoices) => {
                    if (invoices.prospect_id !== prospectID) {
                        return true;
                    }
                });
            }

            if (status) {
                toast.showMessage("Warning", "Please select invoices which belongs to same client", "warn");
            } else {
                setKebabMenuOption({
                    transfer_cash_reserves: true,
                });
            }
        } else if (status === 2) {
            setKebabMenuOption({
                transfer_collection: true,
            });
        }else if (status === 3) {
            setReturnDebtorMenu(true);
        }
    };

    useEffect(() => {
        // Kebab Items
        if (componentName === "unapplied_cash_without_invoices") {
            const _kebab_menu_items = UNAPPLIED_WITHOUT_KEBAB_MENU.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

                if (isAllowed) {
                    return col;
                }
            });
            setKebabMenuItems(_kebab_menu_items);
        } else {
            const _kebab_menu_items = UNAPPLIED_KEBAB_MENU.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

                if (isAllowed) {
                    return col;
                }
            });
            setKebabMenuItems(_kebab_menu_items);
        }
    }, [user, selectedInvoices.length, componentName]);

    //UseEffect
    useEffect(() => {
        const temp = [];
        selectedColumns.filter((col) => {
            temp.push(col.field);
        });
        setOnSelectionColumn(temp);
    }, [header]);

    return (
        <>
            <div className="grid">
                <div className={componentName === "unapplied_cash_without_invoices" ? "md:col-5 col-12" : "md:col-7 col-12"}>
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include terminated invoice
                        </label>
                        {showAll === 1 && (
                            <>
                                <div className="mt-1 ml-3">
                                    <Legends classes="square-legend-delete" title="Deleted" />
                                </div>
                                <div className="mt-1 ml-2">
                                    <Legends classes="square-legend-terminate" title="Terminated" />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={componentName === "unapplied_cash_without_invoices" ? "md:col-7 col-12 pt-3" : "md:col-5 col-12 pt-3"}>
                    <div className="flex flex-row">
                        <SearchInputComponent
                            type="text"
                            value={keyword}
                            setKeyword={setKeyword}
                            placeholder={componentName === "unapplied_cash_with_invoices" ? "Search by invoice no, client name" : "Search by client name, debtor name"}
                        />
                        <div className="ml-2">
                            <ColumnFilterComponent
                                items={
                                    componentName === "unapplied_cash_without_invoices"
                                        ? UNAPPLIED_CASH_WITHOUT_GRID_HEADERS
                                        : componentName === "unapplied_cash_released"
                                        ? UNAPPLIED_CASH_RELEASED
                                        : UNAPPLIED_CASH_GRID_HEADERS
                                }
                                handleColumnFilter={(e) => setSelectedColumns(e)}
                                selectedColumn={onSelectionColumn}
                            />
                        </div>
                        {componentName !== "unapplied_cash_released" && (
                            <div className={`ml-2  ${true ? "no-drop" : ""}`}>
                                {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} />}
                            </div>
                        )}
                        {componentName === "unapplied_cash_without_invoices" ? (
                            <div className="ml-2">
                                <CustomAddNewButton type="button" title="Add New UC" onClick={() => setModalOpen(true)} />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>

            <Dialog
                visible={kebabMenuOption.transfer_cash_reserves}
                onHide={() => {
                    setKebabMenuOption({
                        transfer_cash_reserves: false,
                    });
                    setSelectedInvoices([]);
                }}
                header="Adjust Unapplied Cash"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
                blockScroll={true}
            >
                <AccountAdjustmentModal
                    onHide={() => {
                        setKebabMenuOption({
                            transfer_cash_reserves: false,
                        });
                        setSelectedInvoices([]);
                    }}
                    fetchInvoices={fetchInvoices}
                    selectedInvoices={selectedInvoices}
                    type="cash_reserves"
                />
            </Dialog>

            <Dialog
                visible={kebabMenuOption.transfer_collection}
                onHide={() => {
                    setKebabMenuOption({
                        transfer_collection: false,
                    });
                    setSelectedInvoices([]);
                }}
                header="Adjust Unapplied Cash"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
                blockScroll={true}
            >
                <AccountAdjustmentModal
                    onHide={() => {
                        setKebabMenuOption({
                            transfer_collection: false,
                        });
                        setSelectedInvoices([]);
                    }}
                    fetchInvoices={fetchInvoices}
                    selectedInvoices={selectedInvoices}
                    type="collection"
                />
            </Dialog>

            <Dialog
                visible={returnDebtorMenu}
                onHide={() => {
                    setReturnDebtorMenu(false);
                    setSelectedInvoices([]);
                }}
                header="Return to Debtor"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "60vw" }}
                blockScroll={true}
            >
                <ReturnToDebtorComponent
                    onHide={() => {
                        setReturnDebtorMenu(false);
                        fetchUCNonInvoice();
                        setSelectedInvoices([]);
                    }}
                    onCancel={() => {
                        setReturnDebtorMenu(false);
                        setSelectedInvoices([]);
                    }}
                    selectedInvoices={selectedInvoices}
                />
            </Dialog>
        </>
    );
};

export default FilterBar;
