import React, { useState, useRef, useEffect, useContext } from "react";
//Component

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import ReserveBreakdown from "./ReserveBreakdown";
import { CHARGEBACK_GRID_HEADERS, RESERVE_CHARGEBACK_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import { getChargebackColumnBody } from "../../../../../../utils/HelperFuctions/invoiceHelper";
import classNames from "classnames";
import { ToastContext } from "../../../../../../context/toast";
import { getAdjustedTaxPerDay, getCbAfterReserveDeduction } from "../../../../../../services/reserve";
import { getColumnBody } from "../../../../../../utils/HelperFuctions/supportHelper";

const ChargeBackTable = (props) => {
    const { chargebacks, selectedChargebacks, setSelectedChargebacks, setCb, setCbLoading } = props;
    const toast = useContext(ToastContext);

    const dt = useRef(null);
    //states
    const [globalFilter, setGlobalFilter] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedChargebacks(event.value);
        }
    };

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = CHARGEBACK_GRID_HEADERS.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable
            headerClassName={`${col.field === "invoice_amount" || col.field === "actual_cb" || col.field === "cb" || col.field === "adjusted_cb"  ? "amount_column_alignment" : ""}`}>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    const fetchCbAfterReserveDeduction = async () => {
        let payload = selectedChargebacks.map((item) => item.id);

        setCbLoading(true);
        try {
            let res = await getCbAfterReserveDeduction(payload);
            if (res?.data?.status) {
                setCb(res.data.cb);
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setCbLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            setCbLoading(false);
        }
    };

    useEffect(() => {
        if (selectedChargebacks.length > 0) {
            // fetchCbAfterReserveDeduction();
            const sum = selectedChargebacks.reduce((accumulator, object) => {
                return accumulator + object.adjusted_cb;
            }, 0);
            setCb(sum);
        } else {
            setCb(0);
        }
    }, [selectedChargebacks.length]);

    return (
        <>
            <div className="grid">
                <div className="md:col-5">
                    <h5 className="pt-3">
                        <b>Chargeback(s)</b>
                    </h5>
                </div>

                {/* rrbatch_chargeback_scroll */}
                <div className="col-12 pt-3">
                    <DataTable
                        ref={dt}
                        value={chargebacks}
                        selection={selectedChargebacks.length === 0 ? null : selectedChargebacks}
                        onSelectionChange={handleSelectionChange}
                        onSort={onSort}
                        sortable
                        sortField={sortField}
                        sortOrder={sortOrder}
                        responsiveLayout="scroll"
                        globalFilter={globalFilter}
                        emptyMessage="No record available."
                    >
                        <Column selectionMode="multiple"></Column>
                        {columnComponents}
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ChargeBackTable;
