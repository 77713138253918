import { currencyFormatUSD } from "./checkAmount";
import React from "react";

export const ProspectColumnBody = (colField) => (rowData) => {
    if (colField === "createdBy") {
        return (rowData.cu_last_name ? rowData.cu_last_name : "") + " " + (rowData.cu_first_name ? rowData.cu_first_name : "");
    } else if (colField === "company_phone") {
        return (rowData.company_country_phone_code ? "(" + rowData.company_country_phone_code + ")" : "") + " " + (rowData.company_phone ? rowData.company_phone : "");
    } else if (colField === "funding_amount" || colField === "factoring_limit" || colField === "active_credit_limit") {
        return <div className="amount_column_alignment">{rowData[colField] ? currencyFormatUSD(rowData[colField]) : ""}</div>;
    }
    return rowData[colField] ? rowData[colField] : "";
};

export function knobPercentage(value) {
    let result = 0;
    switch (value) {
        case 1:
            result = 17;
            break;
        case 2:
            result = 34;
            break;
        case 3:
            result = 50;
            break;
        case 4:
            result = 67;
            break;
        case 5:
            result = 83;
            break;
        case 6:
            result = 100;
            break;
        default:
            result = 0;
            break;
    }
    return result;
}

export function percentageCheckerHundred(value, index) {}
