import React, { useContext, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Context & APIs
import { updateUnderwritingVertical } from "../../../../services/under-writing";
import { ToastContext } from "../../../../context/toast";
// UI Componnets
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../utils/HelperFuctions/cleanData";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onUnderWritingFormDirty } from "../../../../redux/main-view";
const EditVertical = ({ onHide, verticalId, verticalData, clientId, underWritings }) => {
    const toastContext = useContext(ToastContext);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);
    const validationSchema = Yup.object().shape({
        tasktitle: Yup.string().required("Vertical Title is required").trim(),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tasktitle: verticalData.name,
        },
        onSubmit: async (data) => {
            let _underwritings = underWritings.filter((item) => item.name === data.tasktitle && item.id !== verticalId);
            if (_underwritings.length !== 0) {
                toastContext.showMessage("Validation Failed", "Vertical Title should be unique.", "error");
            } else {
                try {
                    setIsSaving(true);
                    let dto = {
                        id: verticalId,
                        prospect_id: clientId,
                        name: data.tasktitle.trim(),
                    };
                    let resp = await updateUnderwritingVertical(dto);
                    setIsSaving(false);
                    if (resp.data.status) {
                        toastContext.updateToast("Vertical");
                        onHide(true, "edit vertical");
                    }
                    return <></>;
                } catch (error) {
                    setIsSaving(false);
                }
            }
        },
    });

    const inputHanlder = (e) => {
        const { name, value } = e.target;
        const cleanValue = textExtraSpaceRemoval(value);
        const finalValue = toTitleCaseWhileTyping(cleanValue);
        formik.setFieldValue(name, finalValue);
        dispatch(onUnderWritingFormDirty(true));
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <div className="col-12">
                        <CustomInputField iden="tasktitle" label="Vertical Title" mandatory="*" placeHolder="Enter Vertical Title" maxLength={35} formik={formik} onChange={(e) => inputHanlder(e)} />
                    </div>
                    <div className="col-12 text-right pt-5">
                        <CustomCancelbtn title="Cancel" type="button" onClick={() => onHide(false, "edit vertical")} />
                        <CustomAddNewButton title="Update" type="submit" isDisabled={isSaving || !screens.underwriting.isFormDirty} />
                    </div>
                </div>
            </form>
        </>
    );
};
export default EditVertical;
