import React, { useEffect, useState } from "react";
//styles
import "./reports.scss";
// RRD
import { useHistory } from "react-router-dom";
//components
import CustomLoading from "../../ui/CustomSpinner/custom_spinner";
import SearchInputComponent from "../../ui/SearchInput/SearchInputComponent";
import { REPORTS } from "../../utils/Constants/global";
import { useSelector } from "react-redux";

// Redux
import { loginState } from "../../redux/login";

// Utils
import { validateUserPermissions } from "../../utils/HelperFuctions/validatePermissions";

const ReportsMain = () => {
    //state
    const [timeoutId, setTimeoutId] = useState(null);
    const [search_filter_keyword, setSearchKeyword] = useState("");
    const [loading, setLoading] = useState(true);
    const [reportMap, setReportMap] = useState({
        original: { category: [], data: {} },
        filtered: { category: [], data: {} },
    });

    // Redux
    const { user } = useSelector(loginState);

    // Hook
    const history = useHistory();

    // Handlers
    const handleReport = (report) => {
        localStorage.setItem("report_name", report.handle);
        localStorage.setItem("report", JSON.stringify(report));

        history.push("/reportindex");
    };

    const onReportMapingHandler = () => {
        try {
            setLoading(true);
            const categories = [...REPORTS.category];

            let _reports = { category: [], data: {} };
            categories.forEach((category, catIndex) => {
                const category_reports = REPORTS.data[category].filter((report, reportIndex) => {
                    const isAllowed = validateUserPermissions([report.required_rights[0]], user?.rights);
                    if (isAllowed) {
                        return report;
                    }
                });

                if (category_reports.length > 0) {
                    _reports["category"].push(category);
                    _reports["data"][category] = category_reports;
                }
            });
            setReportMap({
                original: _reports,
                filtered: _reports,
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const onReportSearchHandler = (search_filter_keyword) => {
        try {
            setLoading(true);

            const categories = [...reportMap["original"].category];

            let _reports = { category: [], data: {} };
            categories.forEach((category, catIndex) => {
                const category_reports = reportMap["original"].data[category].filter((report, reportIndex) => {
                    const isFiltered = report.title.toLowerCase().includes(search_filter_keyword.toLowerCase());

                    if (isFiltered) {
                        return report;
                    }
                });

                if (category_reports.length > 0) {
                    _reports["category"].push(category);
                    _reports["data"][category] = category_reports;
                }
            });

            setReportMap({
                original: reportMap.original,
                filtered: _reports,
            });

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    // Use Effect
    useEffect(() => {
        if (!user) return;

        onReportMapingHandler();

        setTimeout(() => setLoading(false), 1000); // simulate data loading for 1 second
    }, [user]);

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setTimeoutId(
            setTimeout(() => {
                if (user && search_filter_keyword.length > 2) {
                    onReportSearchHandler(search_filter_keyword);
                }
            }, 700)
        );
        return () => clearTimeout(timeoutId);
    }, [search_filter_keyword]);

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 pt-3 pb-3">
                            <h4>
                                <b>Reports</b>
                            </h4>
                        </div>
                    </div>
                    <div className="card">
                        <div className="grid">
                            <div className="md:col-9 pt-3 pb-3"></div>
                            <div className="col-12 md:col-3 pt-3 pb-3">
                                <SearchInputComponent placeholder="Search by report name" value={search_filter_keyword} setKeyword={(value) => setSearchKeyword(value)} />
                            </div>
                        </div>

                        {reportMap[search_filter_keyword !== "" ? "filtered" : "original"]?.category?.map((reportCategory, catIndex) => {
                            return (
                                <div className={`grid ${catIndex !== 0 ? "mt-3" : "mt-0"}`} key={`${reportCategory}-${catIndex}`}>
                                    <div className="col-12">
                                        <div className="report_header">
                                            <h5>
                                                <b>{reportCategory}</b>
                                            </h5>
                                        </div>
                                    </div>

                                    {reportMap[search_filter_keyword !== "" ? "filtered" : "original"]?.data[reportCategory]?.map((report, reportIndex) => {
                                        return (
                                            <div key={`${report.title}-${reportIndex}`} className="col-12 md:col-4 text-center" onClick={() => handleReport(report)}>
                                                {/* <Link to="/reportindex"> */}
                                                <div className="reportcard report_card_alignment">
                                                    <div className="pb-2 pl-3 pt-2">
                                                        <span className="report_icon_style">{report.icon}</span>
                                                    </div>
                                                    <div className="pb-3 pt-2 report_icon_style">{report.title}</div>
                                                </div>
                                                {/* </Link> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default ReportsMain;
