import React, { useContext, useEffect, useRef, useState } from "react";

// Prime React
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Constants
import { CHANGE_STATUS_GRID, FILE_MAX_SIZE } from "../../../../../utils/Constants/global";

// Icons
import Document from "../../../../../svgs/Document/Document";

// UI Components
import CustomDropdownField from "../../../../../ui/CustomDropdownField/CustomDropdownField";
import CustomInputTextArea from "../../../../../ui/CustomInputTextArea/CustomInputTextArea";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import { ToastContext } from "../../../../../context/toast";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { getCustomDropDown } from "../../../../../services/dropdown";
import { fetchProspectStatus, saveProspectStatus } from "../../../../../services/prospect";
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import { BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import { Button } from "primereact/button";
import appUrl from "../../../../../utils/Constants/appUrl";

const validationSchema = Yup.object().shape({
    future_status: Yup.string().required("Future Status is required"),
    comments: Yup.string().required("Comments is required"),
    response_given_by_prospect: Yup.string().required("Prospect Response is required"),
});

const ChangeStatus = (props) => {
    const { selectedUsers } = props;
    // Context
    const toast = useContext(ToastContext);
    // State
    const [selectedColumns, setSelectedColumns] = useState(CHANGE_STATUS_GRID);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [futureStatus, setFutureStatus] = useState([]);
    const [futureStatusLoading, setFutureStatusLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [prospectStatuses, setProspectStatuses] = useState([]);
    const hiddenFileInput = useRef(null);
    // Formik
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            future_status: "",
            comments: "",
            response_given_by_prospect: "",
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            const form = new FormData();
            form.append("future_status", data.future_status);
            form.append("comments", data.comments);
            form.append("response_given_by_prospect", data.response_given_by_prospect);
            form.append("attachment_path", uploadedFile);
            form.append("prospect_id", selectedUsers[0].id);

            const res = await saveProspectStatus(form);
            if (res.data.status) {
                toast.createdToast("Status");
                formik.resetForm();
                setUploadedFile(null);
                fetchStatuses();
            } else {
                toast.showMessage("Status", res.data.message, "error");
            }
            setIsSaving(false);
        },
    });
    // Handlers
    const inputHandler = (e) => {
        formik.handleChange(e);
        const { name, value } = e.target;
    };
    // File Selection Handler
    const onFileSelect = (event) => {
        const file = event.target.files[0];
        const fileType = file.name.split(".");
        const index = fileType.length - 1;
        const accept = ".pdf,.png,.jpg,.jpeg,.xlsx,.xls";
        const check_file = accept.includes(fileType[index]);
        if (check_file) {
            const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
            if (fileSize) {
                toast.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size (3mb allowed).", "error");
                event.target.value = null;
                setUploadedFile(null);
            } else if (file.name.includes("#") || file.name.includes("&")) {
                toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
            } else {
                setUploadedFile(file);
                event.target.value = null;
            }
        } else {
            toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
            setUploadedFile(null);
        }
    };

    const openDocument = (attachment) => {
        if (attachment) {
            window.open(appUrl.imageBaseUrl + attachment, "_blank");
        }
    };

    const handleAttachmentDelete = () => {
        setUploadedFile(null);
    };

    // Prospect Change Status Grid View
    const getColumnBody = (colField) => (rowData) => {
        const { id, response } = rowData;
        if (colField === "attachment_path") {
            return (
                <>
                    <div className={` mr-2 mt-1 ${rowData.attachment_path ? "document_icon_background" : "no_doc"}`} onClick={() => openDocument(rowData.attachment_path)}>
                        {rowData.attachment_path ? <Document /> : "No Attachment"}
                    </div>
                </>
            );
        } else {
            return rowData[colField] ? rowData[colField] : "";
        }
    };
    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)}></Column>;
    });

    const getFutureStatus = async () => {
        setFutureStatusLoading(true);
        let form = {
            select: "prospect_future_response",
        };
        let res = await getCustomDropDown(form);
        if (res?.data.status) {
            const _type = JSON.parse(res.data.dropdowns.prospect_future_response);
            setFutureStatus(_type);
        }
        setFutureStatusLoading(false);
        return;
    };

    const fetchStatuses = async () => {
        setStatusLoading(true);
        let form = {
            prospect_id: selectedUsers[0].id,
        };

        fetchProspectStatus(form).then((res) => {
            if (res.data.status) {
                setProspectStatuses(res.data.responses);
            }
            setStatusLoading(false);
        });

        return;
    };

    useEffect(() => {
        getFutureStatus();
        fetchStatuses();
    }, []);

    const handleOpenUpload = () => {
        hiddenFileInput.current.click();
    };

    return (
        <div className="grid">
            <div className="col-12">
                <form onSubmit={isSaving ? null : formik.handleSubmit}>
                    <div className="card">
                        <div className="grid">
                            <CustomDropdownField
                                className="col-12 md:col-6"
                                iden="future_status"
                                label="Future Status "
                                mandatory="*"
                                formik={formik}
                                options={futureStatus}
                                onChange={(e) => inputHandler(e)}
                                optionLabel="name"
                                optionValue="value"
                                placeHolder="Select"
                            />

                            <div className="col-12 md:col-6">
                                <label>
                                    <b>Attachment</b>
                                    <Button
                                        type="button"
                                        tooltip=".pdf, .png, .jpg, .jpeg, .xlsx, .xls, .doc, .docx files only (max size 3MB) "
                                        tooltipOptions={{ position: "bottom" }}
                                        icon={<BsInfoCircle />}
                                        aria-label="Submit"
                                        className="customTooltipicon"
                                    />
                                </label>

                                <div className="file_upload_class">
                                    <label className="flex flex-row justify-content-between align-items-center" onClick={handleOpenUpload}>
                                        {uploadedFile ? (
                                            <div>
                                                <div className="flex flex row justify-content-around">
                                                    <span className="text_break  cursor-pointer">{uploadedFile.name}</span>

                                                    <span className="text_break  cursor-pointer" onClick={() => handleAttachmentDelete(uploadedFile)}>
                                                        <BsXLg className="bs_Cros_img" />
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>Choose File</>
                                        )}

                                        <BsPaperclip />
                                    </label>
                                </div>
                                <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    disabled={uploadedFile}
                                    id="file-upload"
                                    onChange={onFileSelect}
                                    accept=".pdf, .png, .jpg, .jpeg, .xlsx, .xls"
                                    style={{ display: "none" }}
                                />
                            </div>

                            <CustomInputTextArea
                                className="col-12 md:col-12"
                                mandatory="*"
                                label="Comments"
                                iden="comments"
                                formik={formik}
                                maxLength={90}
                                rows="1"
                                placeHolder="Enter Comments"
                                onChange={(e) => inputHandler(e)}
                            />

                            <CustomInputTextArea
                                className="col-12 md:col-12"
                                mandatory="*"
                                label="Prospect Response"
                                iden="response_given_by_prospect"
                                formik={formik}
                                maxLength={90}
                                rows="1"
                                placeHolder="Enter Prospect Response"
                                onChange={(e) => inputHandler(e)}
                            />

                            <div className="text-right pt-3 col-12">
                                <CustomAddNewButton title="Save" isDisabled={isSaving} type="submit" />
                            </div>
                        </div>
                    </div>
                </form>
                <div className="card responser_scrollbar">
                    <p>
                        <b>Responses</b>
                    </p>

                    <div className="pt-3">
                        {statusLoading ? (
                            <CustomLoading top={"50px"} />
                        ) : (
                            <DataTable value={prospectStatuses} responsiveLayout="scroll" emptyMessage="No record available.">
                                {columnComponents}
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeStatus;
