import React, { useContext, useEffect, useRef, useState } from "react";
//styles
import "./fundingcomponent.scss";
//--ui-component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { scheduleSOFDetail } from "../../../../../../services/schedule-assignment";
import { ToastContext } from "../../../../../../context/toast";
import { getDateFormat } from "../../../../../../utils/HelperFuctions/dateFormat";
import { getScheduleColumnBody } from "../../../../../../utils/HelperFuctions/scheduleHelper";
import CustomModalSpinner from "../../../../../../ui/CustomModalSpinner/CustomModalSpinner";

const DetailsOfFunding = (props) => {
    const { selectedRowId } = props;
    const dt = useRef(null);

    const [loading, setLoading] = useState(false);
    const [schedule, setSchedule] = useState({});

    //toast message
    const toast = useContext(ToastContext);

    const fetchSchedules = async (id) => {
        setLoading(true);
        let payload = {
            id: selectedRowId,
        };
        try {
            // Fetching schedules invoices
            const res = await scheduleSOFDetail(payload);
            if (res.data.status) {
                setSchedule(res.data.schedule);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSchedules();
    }, []);

    return (
        <>
            <div className="grid">
                <div className="md:col-3 col-6">
                    <label className="custom_label_funding">
                        <b>Client : </b>
                        <span>{schedule?.prospect?.company_business_name}</span>
                    </label>
                </div>
                <div className="md:col-3 col-6">
                    <label className="custom_label_funding">
                        <b>Schedule Number : </b>
                        <span>{schedule.schedule_no}</span>
                    </label>
                </div>
                <div className="md:col-3 col-7">
                    <label className="custom_label_funding">
                        <b>Creation Date : </b>
                        <span> {getDateFormat(schedule.created_at)}</span>
                    </label>
                </div>
                {loading ? (
                    <CustomModalSpinner />
                ) : (
                    <div className="col-12">
                        <div className="funding_scroll">
                            <DataTable ref={dt} value={schedule.invoices} responsiveLayout="scroll" emptyMessage="No record available.">
                                <Column field="invoice_no" header="Invoice No"></Column>
                                <Column field="debtor.legal_name" header="Debtor Name"></Column>
                                <Column field="invoice_amount" header="Invoice Amount"></Column>
                                <Column field="advance_amount" header="Advance Amount"></Column>
                                <Column field="net_invoice_term" header="Net Invoice Term"></Column>
                                <Column field="invoice_date" header="Invoice Date"></Column>
                                <Column body={getScheduleColumnBody("funding_date")} header="Funding Date"></Column>
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
            {schedule.chargebacks && schedule.chargebacks.length > 0 && (
                <div className="grid">
                    <h5 class="mt-3">
                        <b>Chargeback (s)</b>
                    </h5>
                    <div className="col-12 pt-3">
                        <DataTable ref={dt} value={schedule.chargebacks} rows={5} responsiveLayout="scroll" emptyMessage="No record available.">
                            <Column field="invoice_no" header="Invoice No"></Column>
                            <Column field="debtor.legal_name" header="Debtor Name"></Column>
                            <Column field="invoice_amount" header="Invoice Amount"></Column>
                            <Column field="advance_amount" header="Advance Amount"></Column>
                            <Column field="actual_cb" header="Adjusted CB"></Column>
                        </DataTable>
                    </div>
                </div>
            )}
            <div className="col-12 mt-3 text-right">
                <h6>
                    <b> Net Total : </b>&nbsp; <span>$ {schedule.total_amount}</span>
                </h6>
            </div>
        </>
    );
};

export default DetailsOfFunding;
