import React from "react";
import loaderGif from "../../Images/loader_gif.gif";
import "./loader.scss";

export default function CustomLoading(props) {
    return (
        <div className={props.center ? "loader-center" : "loader_main_div"}>
            <img src={loaderGif} alt="Loading..." className="loader_image_width" />
            {/* <div className="loader">
                <div className="dot dot-1" style={{ top }}></div>
                <div className="dot dot-2" style={{ top }}></div>
                <div className="dot dot-3" style={{ top }}></div>
            </div> */}
        </div>
    );
}
