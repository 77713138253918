import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FactorFlow from "../../Images/LoginPageLogo.png";
import { useFormik } from "formik";
import * as Yup from "yup";

import CustomInputField from "../../ui/CustomInputField/CustomInputField";
import { setPassword, validatePasswordToken } from "../../services/user-management/user";
import { Link } from "react-router-dom";
import { ToastContext } from "../../context/toast";
import "./Login.css";
import { useQuery } from "../../utils/HelperFuctions/UseQuery";

const SetPass = () => {
    const toastContext = useContext(ToastContext);
    let history = useHistory();

    let query = useQuery();
    let token = query.get("token");

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .max(20, "Maximum length should be 20")
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
        confirmpassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            password: "",
            confirmpassword: "",
        },
        onSubmit: async (data) => {
            try {
                console.log(data);
                let dto = {
                    token: token,
                    password: data.password,
                };
                let resp = await setPassword(dto);
                toastContext.updateToast("Password");
                history.push("/login");
            } catch (error) {
                console.log(error);
            }
        },
    });

    useEffect(() => {
        const validate = async () => {
            try {
                let resp = await validatePasswordToken(token);
                if (!resp.data.status) {
                    toastContext.showMessage("Link Expired", "Your link has expired", "error");
                }
            } catch (error) {
                console.log(error);
            }
        };

        validate();
    }, []);

    return (
        <div className="background_image">
            <div className="grid">
                <div className="lg:col-7 md:col-6"></div>
                <div className="lg:col-5 md:col-6 col-12">
                    {/* Logo Text Section */}

                    <div className="custom_margin_top">
                        <div className="res_img">
                            <img src={FactorFlow} alt="Factor Flow Logo" />
                        </div>
                        <div className="pt-2">
                            <h4>One tool for your whole team.</h4>
                        </div>
                    </div>

                    {/* Container Section */}

                    <div className="container">
                        <p className="sign_in_text">Reset Password</p>
                        <p className="sign_text">Reset your password to access the Portal!</p>

                        <div className="pt-3">
                            {/* input section  */}
                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-3">
                                    <CustomInputField iden="password" maxLength="20" formik={formik} placeHolder="Enter Password" type="password" label="Password" />
                                </div>
                                <div className="mb-3">
                                    <CustomInputField iden="confirmpassword" maxLength="20" formik={formik} placeHolder="Enter Confirm Password" type="password" label="Confirm Password " />
                                </div>
                                {/* button section */}
                                <div className="flex flex-row justify-content-between mt-5">
                                    <button className="btn sign_in_btn" type="submit" name="signinbtn" id="signinbtn">
                                        Reset
                                    </button>
                                </div>
                            </form>
                            {/* Register section */}

                            <div className="pt-3 text-center">
                                <p className="client_text">{/* Not yet a client? */}</p>
                                <button
                                    className="register_btn"
                                    onClick={(e) => {
                                        history.push("/login");
                                    }}
                                    name="backtologin"
                                    id="backtologin"
                                >
                                    Back to login
                                </button>
                            </div>
                        </div>

                        {/* Privacy Policy Section */}

                        <div className="pt-6 text-center">
                            <p className="footer_text">
                                By registering, you accept our
                                <br />
                                <a>Terms of use</a> and{" "}
                                <a>
                                    <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPass;
