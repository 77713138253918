import React from "react";
//css
import "./Login.css";
//components
import ProspectForm from "../../ui/ProspectForm/ProspectForm";
import { Link } from "react-router-dom";

const RegisterNow = () => {
    return (
        <div className="become_partner RegisterNow">
            <div class="pt-4" style={{ width: "fit-content" }}>
                {/* <div className="md:col-3 col-6"> */}
                <div className="ml-3">
                    <Link to="/login">
                        <div className="effect_hover">
                            <span className="back_login flex flex-row align-items-center">
                                <i className="text-2xl pi pi-arrow-circle-left mr-2"></i>
                                <h3> Back to Login</h3>
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
            {/* 
            </div>
        */}
            <div className="grid">
                {/* </div> */}
                <div className="md:col-1"></div>
                <div className="col-12 md:col-10">
                    <div className="mt-2">
                        <ProspectForm />
                    </div>
                </div>
                <div className="md:col-1"></div>
            </div>
        </div>
    );
};

export default RegisterNow;
