import React, { useEffect } from "react";
//styles
import "./searchinputcomponent.scss";
//components
import { InputText } from "primereact/inputtext";
import { textExtraSpaceRemoval } from "../../utils/HelperFuctions/cleanData";

const SearchInputComponent = (props) => {
    const { placeholder, value, setKeyword, isClassWidth = false } = props;

    const handleChange = (e) => {
        setKeyword(textExtraSpaceRemoval(e.target.value));
        
    };

  

    return (
        <div className={isClassWidth ? "custom_search_width" : "search_input_width"}>
            <span className="p-input-icon-right text ">
                {/* <InputText type="text" value={value} onInput={handleInput} placeholder={placeholder} className={`p-3 ${"border_class"}`} /> */}
                <InputText type="text" maxLength={30} value={value} onChange={handleChange} placeholder={placeholder} className={`p-3 ${"border_class"}`} />
                <i className="pi pi-search" />
            </span>
        </div>
        // keyfilter={/^[a-zA-Z0-9\s]+$/}
    );
};
// onChange={handleInput}

export default SearchInputComponent;
