import React, { useState, useEffect, useRef, useContext } from "react";

// Prime React
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Panel } from "primereact/panel";
import { Image } from "primereact/image";

// Context
import { ToastContext } from "../../context/toast";

// SVG Icons
import Document from "../../svgs/Document/Document";

// Styles
import "./customattachmentupload.scss";

const CustomAttachmentUpload = (props) => {
    const { setIsFormDirty, isFormDirty, handleSelectedFile, fileType, remove, multiple, preview, disabled } = props;
    // Context
    const toastContext = useContext(ToastContext);

    const toastBC = useRef(null);
    const uploadMultiple = useRef(null);
    const uploadSingle = useRef(null);

    const [files, setFiles] = useState("");
    const [imageFile, setImageFile] = useState("");

    function handleFileUpload(file) {
        setIsFormDirty(true);
        handleRemove();
        const fileType = file.type;

        // Perform specific actions based on the file type
        if (fileType.startsWith("image/")) {
            getBase64(file);
        }
        setFiles(file);
        handleSelectedFile(file);
    }

    const onFileChange = async (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        const fileSize = file.size > 5242880;
        if (fileSize) {
            toastContext.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size.", "error");
            e.target.value = null;
        } else {
            handleFileUpload(file);
        }
    };

    async function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const filetype = file.type.split("/");
            let singleFile = {
                fileBase64: reader.result,
                fileName: file.name,
                fileSize: file.size,
                fileExtension: `.${filetype[1]}`,
            };
            setImageFile(singleFile);
        };
    }

    const handleRemove = () => {
        setFiles("");
        setImageFile("");
        handleSelectedFile("");
        if (uploadMultiple.current) {
            uploadMultiple.current.value = null;
        }
        if (uploadSingle.current) {
            uploadSingle.current.value = null;
        }
    };

    // UseEffect
    useEffect(() => {
        handleRemove();
    }, [remove, disabled]);

    const Header = () => {
        return (
            <>
                {/* <input type="file" accept={fileType} style={{ display: "none" }} multiple={multiple} ref={upload} onChange={(e) => onFileChange(e)} /> */}
                {multiple === "uploadMultiple" ? (
                    <input type="file" accept={fileType} style={{ display: "none" }} multiple ref={uploadMultiple} onChange={(e) => onFileChange(e)} preview />
                ) : (
                    <input type="file" accept={fileType} style={{ display: "none" }} ref={uploadSingle} onChange={(e) => onFileChange(e)} preview disabled={disabled} />
                )}
                {/* {!multiple && <input type="file" accept={fileType} style={{ display: "none" }} ref={uploadSingle} onChange={(e) => onFileChange(e)} />} */}
                <Button
                    className={disabled ? "disabled-button" : ""}
                    onClick={(e) => {
                        e.preventDefault();
                        multiple === "uploadMultiple" ? uploadMultiple.current.click() : uploadSingle.current.click();
                        // upload.current.click();
                    }}
                    label="Choose File"
                ></Button>
            </>
        );
    };

    return (
        <Panel className="Custom__Panel" header={Header}>
            <Toast ref={toastBC} position="bottom-center" />
            <div className="grid ">
                {imageFile ? (
                    <>
                        <div className="field col-12 md:col-5" style={{ position: "relative" }}>
                            {/* <Image className="mb_img" src={imageFile?.fileBase64} alt="img" preview /> */}
                            <Image className="mb_img" src={imageFile?.fileBase64} alt="img" preview />
                            <span
                                className="trash__btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleRemove();
                                }}
                            >
                                <i className="bi bi-x text-2xl"></i>
                            </span>
                        </div>
                        <div className="field col-12 md:col-1 text-center padding__top5">
                            {/* <span
                                className="trash__btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleRemove();
                                }}
                            >
                                <i className="bi bi-x "></i>
                            </span> */}
                        </div>
                        <div className="md:col-6"></div>
                    </>
                ) : files ? (
                    <div className="flex flex-row">
                        <div className={` mr-2 mt-1 document_icon_background }`}>
                            <Document />
                        </div>
                        <div className="pt-3 pl-2">{files.name}</div>
                        <span
                            className="trash__btn mt-3 ml-3"
                            onClick={(e) => {
                                e.preventDefault();
                                handleRemove();
                                setIsFormDirty(false);
                            }}
                        >
                            <i className="bi bi-x p-0"></i>
                        </span>
                    </div>
                ) : (
                    <div className="text-center col-12">
                        <div className="file_type_text"> {fileType} files only (max size 3MB)</div>
                    </div>
                )}
            </div>
        </Panel>
    );
};

export default CustomAttachmentUpload;
