import React from "react";
import { useHistory } from "react-router-dom";
//styles
import "../../../userManagement.scss";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../redux/login";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { USERMANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

const Header = (props) => {
    const history = useHistory();

    // Redux
    const { user } = useSelector(loginState);

    //navigate to add user
    const handleNavigate = () => {
        history.push("/addnewrole");
    };
    return validateUserPermissions([USERMANAGMENT_RIGHTS.role_rights_addrole], user?.rights) && <CustomAddNewButton title=" Add New Role" onClick={handleNavigate}></CustomAddNewButton>;
};

export default Header;
