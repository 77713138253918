import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Icons
import moment from "moment";
import { BiMapPin } from "react-icons/bi";
import { FaRegHandshake } from "react-icons/fa";
import { BsCheckCircleFill, BsEye, BsHouses, BsPeople, BsPinAngle, BsPinMap, BsQuestionCircleFill, BsTelephone } from "react-icons/bs";

// Prime React
import { Tooltip } from "primereact/tooltip";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onClientDataChange, onProspectCountryCodes } from "../../../../../../redux/main-view";

// Context
import { ToastContext } from "../../../../../../context/toast";

// Components
import ProprietorInformation from "./Components/ProprietorInformation";
import BusinessInformation from "./Components/BusinessInformation";
import FinancialInformation from "./Components/FinancialInformation";
import SupplierInformation from "./Components/SupplierInformation";
import LandlordInformation from "./Components/LandlordInformation";

// UI Components
import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";

// Constant
import { DOCUMENT_CHECKLIST } from "../../../../../../utils/Constants/global";

// API'S
import { fetchProspectById } from "../../../../../../services/prospect";
import { getCountryCode } from "../../../../../../services/country-code";
import { getClientDebtor } from "../../../../../../services/client";
import { Button } from "primereact/button";
import { getStorageParam, saveStorageParam } from "../../../../../../utils/Constants/param";
import ViewDocumentChecklist from "../../ViewDocumentChecklist";

// Prime React
import { Dialog } from "primereact/dialog";

const ClientProfile = (props) => {
    //Dispatch
    const dispatch = useDispatch();
    const history = useHistory();

    const { setDebtorIds, setOwnerIds, setEditedClientId } = props;
    // Redux
    const { screens } = useSelector(mainViewState);

    const result = screens?.client?.data;

    //get the slected id from localstorage

    let id = getStorageParam() ?? result?.id;

    if (id === null || id === undefined) {
        history.replace("/");
    }

    setEditedClientId(id);

    // Context
    const toast = useContext(ToastContext);

    // State
    const [debtorResponse, setDebtorResponse] = useState([]);
    const [showDocumentsDialog, setShowDocumentDialog] = useState(false);
    const [showDocuments, setShowDocuments] = useState([]);
    const [clientAdvocates, setClientAdvocates] = useState([]);
    const [formArray, setFormArray] = useState({
        invoices_to_factor: [],
        customer_list_with_addresses: [],
        accounts_receivable_aging: [],
        current_financial_statements: [],
        accounts_payable_aging: [],
        bank_authorization_form: [],
        tax_authorization_form: [],
        tax_returns: [],
        liability_insurance_certificate: [],
        articles_of_incorporation_or_assumed_name_certificate: [],
        copy_of_941s_with_proof_of_payment: [],
        sample_copy_of_invoice: [],
        workers_comp_insurance: [],
        copy_of_current_PACA_license: [],
        copy_of_applicant_driver_license: [],
    });

    // View State
    const [isLoading, setIsLoading] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const handleRedirection = (id) => {
        history.push({ pathname: "/editdebtor", state: { id: `${id}`, prospect_id: `${screens.client.data.id}` } });
    };

    // API Handler
    const getProspectById = (state) => {
        if (!state) setIsLoading(true);
        let payload = {
            id: id,
            status: 1
        };
        try {
            fetchProspectById(payload).then((res) => {
                if (res) {
                    dispatch(onClientDataChange(res.data.prospect));
                    setClientAdvocates(res.data.ca);
                    if (res.data.prospect.support_attachments) {
                        setFormArray(res.data.prospect.support_attachments);
                    }
                    setIsLoading(false);
                } else {
                    toast.showMessage("Error", "Server Error", "error");
                    setIsLoading(false);
                }
            });
        } catch (error) {
            setIsLoading(false);
            toast.showMessage("Error", "Server Error", "error");
        }
    };

    const fetchCountryCodes = () => {
        try {
            getCountryCode().then((res) => {
                dispatch(onProspectCountryCodes(res.data.countries));
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchingDebtor = () => {
        let payload = {
            prospect_id: id,
        };

        getClientDebtor(payload).then((res) => {
            let debtorIds = [];
            setDebtorResponse(res.data.debtors);
            let debtors = res.data.debtors ? res.data.debtors : [];
            debtors.map((item) => {
                debtorIds.push(item.id);
            });

            setDebtorIds(debtorIds);
        });
    };
    const handleOpenDocumentDialog = (docs) => {
        setShowDocuments(docs);
        setShowDocumentDialog(true);
    };

    useEffect(() => {
        if (id) {
            saveStorageParam("clientdetails", id);
            fetchCountryCodes();
            getProspectById(false);
        }
    }, []);

    useEffect(() => {
        if (id !== undefined) fetchingDebtor();
    }, [id]);

    return (
        <>
            {isLoading ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    {/* //Established data */}
                    <div className="grid main_brdr_class">
                        <div className="col-12 md:col-3 brdr_class pl-3">
                            <h5>
                                <b>{result?.company_business_name}</b>
                            </h5>
                            <div className="client_data_text">
                                <p>
                                    <b>Doing Business As:</b> <span>{result?.doing_business_as ? result?.doing_business_as : result?.company_business_name}</span>
                                </p>
                                <p>
                                    <b>Business Established On:</b> <span>{moment(result?.company_date_established).format("MM/DD/YYYY")}</span>
                                </p>
                                <p>
                                    <b>Business Established State:</b> <span>{result?.business_registration_state ? result?.business_registration_state : "Unspecified"}</span>
                                </p>
                                <p>
                                    <b> Business Type:</b> <span>{result?.company_legal_status_text}</span>
                                </p>
                                <p>
                                    <b> Contact Name: </b>
                                    <span>{result?.poc_name}</span>
                                </p>
                                <p>
                                    <b> Contact Email: </b>
                                    <span>{result?.poc_email}</span>
                                </p>
                                <p>
                                    <b>FEIN: </b>
                                    <span>{result?.company_fein}</span>
                                    {/* <Button
                                        type="button"
                                        tooltip="View"
                                        onClick={() => getProspectById(true)}
                                        tooltipOptions={{ position: "top" }}
                                        icon={<BsEye />}
                                        className="icon_size justify-content-end border-0 py-0"
                                    /> */}
                                </p>
                            </div>
                        </div>

                        <div className="col-12 md:col-3 brdr_class">
                            <div className="text_icons_phone pl-3">
                                <p>
                                    <Button type="button" tooltip="Employee Count" tooltipOptions={{ position: "top" }} icon={<BsPeople />} className="icon_size" />

                                    <span className="pl-5">{result?.company_number_of_employees ? result?.company_number_of_employees : "Unspecified"}</span>
                                </p>
                                <p>
                                    <Button type="button" tooltip="Phone No" tooltipOptions={{ position: "top" }} icon={<BsTelephone />} className="icon_size" />

                                    <span className="pl-5">{`(${result?.company_country_phone_code}) ${result?.company_phone}`}</span>
                                </p>
                                <p>
                                    <Button type="button" tooltip="Company Address" tooltipOptions={{ position: "top" }} icon={<BsHouses />} className="icon_size" />

                                    <Tooltip target=".company_address" position="top" content={result?.company_address.length > 44 ? result?.company_address : ""} />
                                    <span className="pl-5 company_address">{result?.company_address.length > 44 ? result?.company_address.substring(0, 44) + "..." : result?.company_address}</span>
                                </p>
                                <p>
                                    <Button type="button" tooltip="Zip Code" tooltipOptions={{ position: "top" }} icon={<BsPinAngle />} className="icon_size" />

                                    <span className="pl-5">{result?.company_zip}</span>
                                </p>
                                <p>
                                    <Button type="button" tooltip="State" tooltipOptions={{ position: "top" }} icon={<BsPinMap />} className="icon_size" />

                                    <span className="pl-5">{result?.company_state}</span>
                                </p>
                                <p>
                                    <Button type="button" tooltip="City" tooltipOptions={{ position: "top" }} icon={<BiMapPin />} className="icon_size" />

                                    <span className="pl-5">{result?.company_city}</span>
                                </p>
                                <p>
                                    <Button type="button" tooltip="Client Advocates" tooltipOptions={{ position: "top" }} icon={<FaRegHandshake />} className="icon_size" />

                                    <span id={`responder-ca`} className="pl-5 client_text_break">
                                        {clientAdvocates}
                                    </span>
                                    <Tooltip target={`#responder-ca`} position="bottom">
                                        {clientAdvocates}
                                    </Tooltip>
                                </p>
                            </div>
                        </div>

                        <div className="col-12 md:col-2 brdr_class ">
                            <div className="pl-3">
                                <h5>
                                    <b>Debtors</b>
                                </h5>
                                <div className="custom_profile_overflow">
                                    {debtorResponse && (
                                        <>
                                            {debtorResponse.map((item, index) => {
                                                return (
                                                    <p key={index} onClick={() => handleRedirection(item.id)} className="debtor_name">
                                                        <span>{item.debtor_name}</span>
                                                    </p>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 md:col-4 pl-5">
                            <h5>
                                <b>Documents Checklist</b>
                            </h5>

                            <div className="client_doc_scrollbar">
                                <div className="">
                                    {DOCUMENT_CHECKLIST.map((document, index) => (
                                        <div className="document_type flex flex-row justify-content-between mt-1" key={index}>
                                            <p className="attachment_text m-0">{document.title}</p>

                                            <p>
                                                {formArray[document.value]?.length === 0 || formArray[document.value] === null ? (
                                                    <BsQuestionCircleFill className="question_mark_color" />
                                                ) : (
                                                    <BsCheckCircleFill className="check_color cursor-pointer" onClick={() => handleOpenDocumentDialog(formArray[document.value])} />
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid  mt-3">
                        <div className="col-12 md:col-6">
                            <BusinessInformation />
                        </div>
                        <div className="col-12 md:col-6">
                            <ProprietorInformation setOwnerIds={setOwnerIds} />
                        </div>
                    </div>

                    <div className="grid  mt-3">
                        <div className="col-12 md:col-6">
                            <FinancialInformation />
                        </div>
                        <div className="col-12 md:col-6">
                            <SupplierInformation />
                        </div>
                    </div>
                    <div className="grid  mt-3">
                        <div className="col-12 md:col-6">
                            <LandlordInformation />
                        </div>
                    </div>
                </div>
            )}
            <Dialog
                header="Documents"
                visible={showDocumentsDialog}
                onHide={() => setShowDocumentDialog(false)}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw", height: "50vh" }}
            >
                <ViewDocumentChecklist documents={showDocuments} />
            </Dialog>
        </>
    );
};

export default ClientProfile;
