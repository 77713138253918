import React, { useContext, useEffect, useState } from "react";
//components
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { createAnnoucement, updateAnnoucement } from "../../../../services/annoucements";
import { getRoleGrid } from "../../../../services/user-management/user";
import { ToastContext } from "../../../../context/toast";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import { getAssignUser } from "../../../../services/support";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";

const options = [
    {
        name: "Users",
        value: 1,
    },
    {
        name: "Roles",
        value: 2,
    },
];

const AddNewAnnouncement = ({ onHide, editAnnoucementData }) => {
    const toastContext = useContext(ToastContext);
    //state
    const [roleDropDown, setRoleDropDown] = useState([]);
    const [usersDropDown, setUsersDropDown] = useState([]);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //formik validation
    // const re = /^((ftp|http|https):\/\/)?(?!.*(ftp|http|https))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    const re = /^https?:\/\/\S+$/;
    // const validationSchema = editData == null ? Yup.object().shape({
    const validationSchema = Yup.object().shape({
        announcementtitle: Yup.mixed().required("Announcement Title is required"),
        effectivefrom: Yup.date().required("Effective From is required"),
        effectiveto: Yup.date().required("Effective To is required").min(Yup.ref("effectivefrom"), "Effective to should be greater than effective from"),
        announcementtext: Yup.string().required("Announcement Text is required"),
        hyperlink: Yup.string().matches(re, "Please add valid url"),
        broadcast_to: Yup.string().required("Broadcast To is required"),
        broadcasttousers: Yup.mixed().when("broadcast_to", {
            is: (val) => val === "1",
            then: Yup.mixed().required("User is required"),
        }),
        broadcastto: Yup.mixed().when("broadcast_to", {
            is: (val) => val === "2",
            then: Yup.mixed().required("Role is required"),
        }),
        hyperlink: Yup.string().test("required", "URL is required", function (value) {
            if (formik.values.hyperlinktext !== "") {
                if (!value) {
                    return false;
                } else {
                    if (!re.test(value)) {
                        return this.createError({
                            message: "Please add a valid URL",
                            path: "hyperlink",
                        });
                    }
                    return true;
                }
            } else if (formik.values.hyperlink !== "") {
                if (!re.test(value)) {
                    return this.createError({
                        message: "Please add a valid URL",
                        path: "hyperlink",
                    });
                }
                return true;
            } else {
                return true;
            }
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            announcementtitle: editAnnoucementData ? editAnnoucementData.title : "",
            effectivefrom: editAnnoucementData ? new Date(editAnnoucementData.effective_from) : "",
            effectiveto: editAnnoucementData ? new Date(editAnnoucementData.effective_to) : "",
            announcementtext: editAnnoucementData ? editAnnoucementData.announcement_text : "",
            broadcastto: editAnnoucementData && editAnnoucementData.broadcast_to === 2 ? editAnnoucementData.roles : "",
            hyperlinktext: editAnnoucementData ? editAnnoucementData.hyperlink_text : "",
            hyperlink: editAnnoucementData ? editAnnoucementData.hyperlinks : "",
            broadcasttousers: editAnnoucementData && editAnnoucementData.broadcast_to === 1 ? editAnnoucementData.users : "",
            broadcast_to: editAnnoucementData && editAnnoucementData.broadcast_to !== null ? editAnnoucementData.broadcast_to : "",
        },
        onSubmit: async (data) => {
            setSaveDisabled(true);
            setIsLoading(true);

            try {
                let dto = {
                    title: data.announcementtitle,
                    effective_from: data.effectivefrom,
                    effective_to: data.effectiveto,
                    announcement_text: data.announcementtext,
                    hyperlinks: data.hyperlink,
                    broadcast_to: data.broadcast_to,
                    hyperlink_text: data.hyperlinktext,
                    roles: [],
                    users: [],
                };
                if (data.broadcastto.length > 0 && data.broadcast_to === 2) {
                    dto.roles = data.broadcastto;
                } else {
                    dto.roles = [];
                }
                if (data.broadcasttousers.length > 0 && data.broadcast_to === 1) {
                    dto.users = data.broadcasttousers;
                } else {
                    dto.users = [];
                }
                if (editAnnoucementData) {
                    dto.id = editAnnoucementData.id;
                    let resp = await updateAnnoucement(dto);
                    onHide(true);
                } else {
                    let resp = await createAnnoucement(dto);
                    if (resp.data.status) {
                        toastContext.createdToast("Announcement");
                        onHide(true);
                    }
                }

                return;
            } catch (error) {
                toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            setSaveDisabled(false);
            setIsLoading(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getRoles = async () => {
        let dto = {
            pageNumber: 1,
            size: 1000,
            search: "",
            status: null,
        };
        let resp = await getRoleGrid(dto);
        setRoleDropDown(resp.data.records);
    };
    const getUsers = async (searchValue = null) => {
        const dto = {
            pageNumber: 1,
            size: 1000,
            search: searchValue,
            status: false,
            isLogin: false,
        };
        try {
            const res = await getAssignUser(dto);
            setUsersDropDown(res.data.records);
        } catch {
            toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        setLoading(true);
        getRoles();
        getUsers();
        setLoading(false);
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === " " && e.target.value.trim() === "") {
            e.preventDefault();
        }
    };

    const handleCancel = () => {
        if (formik.dirty) {
            setIsFormDirty(true);
        } else {
            onHide(false);
        }
    };
    const confirmHandler = () => {
        onHide(false);
    };
    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid">
                            <div className="col-12 md:col-12">
                                <CustomInputField
                                    iden="announcementtitle"
                                    label="Announcement Title"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="Enter Announcement Title"
                                    keyfilter={/^[a-zA-Z0-9\s]+$/}
                                    maxLength={50}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="effectivefrom"
                                    type="calendar"
                                    label="Effective From"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="mm/dd/yyyy"
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                            </div>
                            <div className="col-12 md:col-6">
                                <CustomInputField
                                    iden="effectiveto"
                                    type="calendar"
                                    label="Effective To"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="mm/dd/yyyy"
                                    monthNavigator
                                    yearNavigator
                                    yearRange="1945:2050"
                                />
                            </div>
                            <div className="col-12">
                                <CustomInputField
                                    iden="announcementtext"
                                    type="textarea"
                                    label="Announcement Text"
                                    mandatory="*"
                                    rows="3"
                                    formik={formik}
                                    placeHolder="Enter Announcement Text"
                                    keyfilter={/^[a-zA-Z0-9\s]+$/}
                                    maxLength={150}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            <div className="col-12">
                                <CustomInputField iden="hyperlinktext" label="Hyperlink Text" formik={formik} placeHolder="Enter Hyperlink Text" maxLength={15} onKeyPress={handleKeyPress} />
                            </div>
                            <div className="col-12">
                                <CustomInputField iden="hyperlink" type="text" label="Hyperlink" formik={formik} placeHolder="https://" />
                            </div>
                            <div className="col-12">
                                <CustomInputField
                                    type="dropdown"
                                    iden="broadcast_to"
                                    label="Broadcast To"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="Select"
                                    optionLabel="name"
                                    optionValue="value"
                                    options={options}
                                />
                            </div>
                            {formik.values.broadcast_to === 2 && (
                                <div className="col-12">
                                    <CustomInputField
                                        type="multiSelect"
                                        iden="broadcastto"
                                        mandatory="*"
                                        label="Roles"
                                        formik={formik}
                                        placeHolder="Select"
                                        optionLabel="roleName"
                                        optionValue="roleId"
                                        filter={true}
                                        filterBy="roleName"
                                        options={roleDropDown}
                                    />
                                </div>
                            )}
                            {formik.values.broadcast_to === 1 && (
                                <div className="col-12">
                                    <CustomInputField
                                        type="multiSelect"
                                        iden="broadcasttousers"
                                        mandatory="*"
                                        label="Users"
                                        formik={formik}
                                        filter={true}
                                        filterBy="first_Name"
                                        placeHolder="Select"
                                        optionLabel="first_Name"
                                        optionValue="id"
                                        options={usersDropDown}
                                    />
                                </div>
                            )}

                            <div className="col-12 text-right pt-3">
                                <CustomCancelbtn title="Cancel" type="button" onClick={() => handleCancel()} />
                                <CustomAddNewButton title={editAnnoucementData ? "Update" : "Add"} type="submit" isDisabled={saveDisabled} isLoading={isLoading} />
                            </div>
                        </div>
                    </form>
                </>
            )}
            {isFormDirty && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsFormDirty(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

export default AddNewAnnouncement;
