import React from "react";
//css
import "./Login.css";
import "./frontlinefunding.scss";
//components
import ProspectForm from "../../ui/ProspectForm/ProspectForm";
// import { Link } from "react-router-dom";

const FronllineFundingRegisterNow = () => {
    return (
        <div className="custom-css-for-frontline-funding">
            <div className="grid">
                <div className="col-12 md:col-12">
                    <div className="mt-2">
                        <ProspectForm frontline_color={true} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FronllineFundingRegisterNow;
