import React from "react";
//styles
import "./zipcodescomponent.scss";

const ZipCodesMenuComponent = (props) => {
    const { zipCodes, handleSelectedZipCodes, indexing = -1 } = props;
    const handleZipCodes = (zip) => {
        handleSelectedZipCodes(zip, indexing);
    };
    return (
        <div>
            {zipCodes.length > 0 && (
                <>
                    <div className="card custom_scrollbar menu_position">
                        {zipCodes.map((zip, index) => (
                            <p onClick={() => handleZipCodes(zip)} className="cursor-pointer" key={index}>
                                {zip.zip}
                            </p>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ZipCodesMenuComponent;
