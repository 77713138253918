import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../../../../../context/toast";
import { DebtorContext } from "../../../../../context/DebtorContext";
import { mainViewState, onDebtorFormDirty } from "../../../../../redux/main-view";

// Prime React
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";

// UI Components
import DebtorPocModal from "./DebtorPocModal";
import CustomLoading from "../../../../../ui/CustomSpinner/custom_spinner";
import CustomCancelbtn from "../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import ZipCodesMenuComponent from "../../../../../ui/ZipCodesMenu/ZipCodesMenuComponent";

import "../../debtors.scss";

// Helper Function
import { AmountCheck } from "../../../../../utils/HelperFuctions/checkAmount";
import { countryCodeSearchHandler } from "../../../../../utils/HelperFuctions/phoneCodeSearch";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../../../utils/HelperFuctions/cleanData";

// API'S
import { getZipCode } from "../../../../../services/zip-code";
import { getCountryCode } from "../../../../../services/country-code";
import { getProspectDropdown } from "../../../../../services/prospect";
import { loginState } from "../../../../../redux/login";
import { ROLE_NAME } from "../../../../../utils/Constants/global";
import { getDebtorById, getDebtorPocs, getDebtors, inviteDebtor, resendDebtorInvite } from "../../../../../services/debtor";
import CustomConfirmDialog from "../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { BsCheckLg } from "react-icons/bs";
import { getIndustriesForDropdown } from "../../../../../services/industries";
import CreatableSelect from "react-select/creatable";

const DebtorForm = (props) => {
    const {
        setIndexCall,
        setIsOpening,
        formik,
        showDebtorPocModal,
        setShowDebtorPocModal,
        pocsOnDebtorChange,
        setPocsOnDebtorChange,
        isZipSearch,
        setIsZipSearch,
        setDebtorPocs,
        debtorPocs,
        setPocs,
        initialPocState,
        debtorExistsChecking,
        prospect_id,
        creditLimitLoading,
        showInviteButton,
        debtorContactsList,
        showResendInvite,
        setShowInviteButton,
        setUpdateDebtorRecord,
        setIsClient,
        isClient,
        setNewIndustry,
        industryTitle,
        setIndustryTitle,
        setIndustryValue,
        industries,
        setIndustries,
    } = props;

    const toast = useContext(ToastContext);
    const history = useHistory();
    const debtor = useContext(DebtorContext);

    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);

    const [debtorById, setDebtorById] = useState(props.debtorById);
    const [zipCode, setZipCode] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [recordNotFoundMsg, setRecordNotFoundMsg] = useState("");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
        fax_code: "+1",
    });
    const [isSaving, setIsSaving] = useState(false);
    const [sendInvite, setSendInvite] = useState(false);
    const [resendInvitePayload, setResendInvitePayload] = useState("");

    const [prospects, setProspects] = useState([]);
    const [debtors, setDebtors] = useState([]);
    const [debtorId, setDebtorId] = useState(null);
    const [debtorLoading, setDebtorLoading] = useState(false);
    const [isRemovingDebtorPoc, setIsRemovingDebtorPoc] = useState(false);

    const [industryInitialValue, setIndustryInitialValue] = useState(null);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Redux
    const { user } = useSelector(loginState);

    // Dont remove this function || its used to closed the toggle dropdown
    function useOutsideClickHandler(ref, callback) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, callback]);
    }

    // Handler
    const replicatePocs = (selectedPocs) => {
        if (selectedPocs && selectedPocs.length > 0) {
            setShowDebtorPocModal(false);
            let data = formik.values;

            if (debtor && debtor.profile && debtor.profile.id) {
                data.id = debtor.profile.id;
            }

            let _selectedPocs = selectedPocs.map((item) => {
                const { id, ...rest } = item;
                return rest;
            });

            setIndexCall(1, data, _selectedPocs);
        }
    };

    const inputHandler = (e) => {
        dispatch(onDebtorFormDirty(true));
        const { name, value } = e.target;

        if (name === "country_phone_code" || name === "country_fax_code") {
            formik.setFieldValue(name, value);
        } else if (name === "address") {
            formik.setFieldValue(name, toTitleCaseWhileTyping(value));
        } else if (name === "prospect_id") {
            formik.setFieldValue(name, value);
            formik.setFieldValue("legal_name", "");
            // clearDebtor()
        } else {
            const cleanValue = textExtraSpaceRemoval(value);
            formik.setFieldValue(name, cleanValue);
        }
    };

    const debtorInputChangeHandler = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);

        // clearDebtor();
    };

    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRecordNotFoundMsg("");
        formik.values.zip = zip.zip;
        formik.values.state = zip.full_state;
        formik.values.city = zip.city;
        setZipCode([]);
        formik.setFieldTouched("zip", false);
    };
    const handleClientFilter = (event) => {
        const filterValue = event.filter;
        if (filterValue.length > 2 || filterValue.length == 0) {
            const delayDebounceFn = setTimeout(() => {
                fetchProspectDropdown(filterValue);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    };
    const handleDebtorFilter = (event) => {
        const filterValue = event.filter;
        if (filterValue.length > 2 || filterValue.length == 0) {
            const delayDebounceFn = setTimeout(() => {
                fetchDebtorDropdown(filterValue);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    };

    const handleSelectIndustry = (newValue, actionMeta) => {
        if (newValue && actionMeta.action === "create-option") {
            setIndustryTitle(newValue.label);
            setIndustryValue(newValue.value);
            setNewIndustry(true);
            setIndustryInitialValue({ value: newValue.value, label: newValue.label });
        } else if (newValue && actionMeta.action === "select-option") {
            setIndustryTitle(newValue.label);
            setIndustryValue(newValue.value);
            setNewIndustry(false);
            setIndustryInitialValue({ value: newValue.value, label: newValue.label });
        } else {
            setIndustryInitialValue(null);
            setIndustryTitle(null);
            setIndustryValue(0);
            setNewIndustry(true);
        }

        return;
    };

    const handleInputChange = (inputValue, actionMeta) => {
        if (inputValue.length <= 30 && inputValue.length > 0) {
            setIndustryTitle(inputValue);
        }
    };

    const handleKeyDown = (event) => {
        // Prevent input beyond the maximum character limit
        if (industryTitle?.length >= 30 && event.key !== "Backspace") {
            event.preventDefault();
        }
    };

    const handleCreditLimitChange = (e, name) => {
        dispatch(onDebtorFormDirty(true));
        if (e.value !== null) {
            let amount = AmountCheck(e.value.toString());
            if (e.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            }
        }
        formik.setFieldValue(name, e.value);
    };
    const cancelHandlder = () => {
        if (screens.debtor.isFormDirty) {
            setIsOpening(true);
        } else {
            dispatch(onDebtorFormDirty(false));
            history.push("/debtormain");
        }
    };

    const debtorPocModalCloseHandler = () => {
        setShowDebtorPocModal(false);
        setIndexCall(1, debtor?.profile, []);
    };

    // API'S Handler
    const fetchCountryCodes = () => {
        try {
            getCountryCode().then((resp) => {
                setCountryCode(resp.data.countries);
            });
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchZipCodeData = (value) => {
        try {
            const payload = {
                zip: value ? value : "",
            };
            getZipCode(payload).then((res) => {
                if (res && res.data && res.data.codes) {
                    setZipCode(res.data.codes);
                    if (res.data.codes.length == 0) {
                        setZipCode([]);
                        setRecordNotFoundMsg("No Record Available");
                    }
                } else {
                    toast.showMessage("Fetch Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchProspectDropdown = (filterValue) => {
        try {
            const payload = {
                per_page: 50,
                search_text: filterValue,
            };

            getProspectDropdown(payload).then((res) => {
                if (res.status) {
                    if (res.data && res.data.prospects && res.data.prospects.data.length > 0) {
                        setProspects(res.data.prospects.data);
                    }
                }
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchDebtorDropdown = (filterValue = null) => {
        try {
            let payload = {
                page: 1,
                per_page: 50,
                search_text: filterValue,
                status: 0,
            };

            setDebtorLoading(true);
            getDebtors(payload).then((res) => {
                let _debtors = [];

                if (res && res.status) {
                    if (res.data.debtors.data.length > 0) {
                        //filter debtors with unique names...
                        let prospect_debtors = res.data.debtors.data.filter((item) => item.prospect_id == formik.values.prospect_id);
                        res.data.debtors.data.forEach((debtor) => {
                            // if (formik.values.prospect_id && debtor.debtor_name !== formik.values.prospect_id) {
                            let d = prospect_debtors.filter((item) => item.debtor_name == debtor.debtor_name);
                            let l = _debtors.filter((item) => item.debtor_name == debtor.debtor_name);
                            if (d.length == 0 && l.length == 0) {
                                _debtors.push(debtor);
                            }
                            // }
                        });

                        setDebtors(_debtors);
                    }
                    setDebtorLoading(false);
                } else {
                    toast.showMessage("Fetch Failed", "Sorry, we are unable to process your request at this time.", "error");
                    setDebtorLoading(false);
                }
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchIndustryDropdown = (filterValue = null) => {
        try {
            let payload = {
                page: 1,
                per_page: 50,
                search_text: filterValue,
            };

            setDebtorLoading(true);
            getIndustriesForDropdown(payload).then((res) => {
                let _industries = [];

                if (res && res.status) {
                    if (res.data.industries.data.length > 0) {
                        const _industries = res.data.industries.data.map((industry) => ({
                            label: industry.title,
                            value: industry.id,
                        }));

                        setIndustries(_industries);
                    }
                    setDebtorLoading(false);
                } else {
                    toast.showMessage("Fetch Failed", "Sorry, we are unable to process your request at this time.", "error");
                    setDebtorLoading(false);
                }
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchDebtorById = (prospect_id, debtor_id) => {
        try {
            const data = {
                prospect_id: prospect_id,
                id: debtor_id,
            };

            setDebtorLoading(true);
            getDebtorById(data).then((res) => {
                if (res && res.status) {
                    if (res.data.status) {
                        setDebtorById(res.data.debtor);
                    } else {
                        toast.showMessage("Data Fetch Failed", res.data.message, "error");
                    }
                } else {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
                setDebtorLoading(false);
            });
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const fetchDebtorPocs = async (prospect_id, debtor_id) => {
        try {
            const data = {
                // prospect_id: prospect_id,
                debtor_id: debtor_id,
            };

            try {
                let res = await getDebtorPocs(data);
                if (res?.data?.status) {
                    let _pocs = [];
                    res.data.pocs.forEach((item) => {
                        let duplicate = _pocs.filter((p) => {
                            return p.name === item.name && p.email === item.email;
                        });

                        if (duplicate?.length == 0) {
                            _pocs.push(item);
                        }
                    });

                    setPocsOnDebtorChange(_pocs);
                } else {
                    toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (e) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (error) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const inviteDebtorHandler = async () => {
        const temp = [];
        setIsSaving(true);
        debtorPocs.map((item) => {
            temp.push(item.id);
        });

        const payload = {
            poc_ids: temp,
        };

        const res = await inviteDebtor(payload);
        if (res) {
            if (res.data.status) {
                setShowInviteButton(false);
                setSendInvite(false);
                setIsSaving(false);
                toast.showMessage("Invite Debtor", res.data.message, "success");
            } else {
                setSendInvite(false);
                setIsSaving(false);
                toast.showMessage("Error", res.data.message, "error");
            }

            setUpdateDebtorRecord(true);
        } else {
            setSendInvite(false);
            setIsSaving(false);
            toast.showMessage("Error", "Server error.", "error");
        }
    };

    const resendInviteHandler = async (payload) => {
        setIsSaving(true);
        const res = await resendDebtorInvite(payload);
        if (res) {
            if (res.data.status) {
                setIsSaving(false);
                setIsDropdownOpen(false);
                setResendInvitePayload("");
                toast.showMessage("Invite Debtor", res.data.message, "success");
            } else {
                setIsSaving(false);
                setIsDropdownOpen(false);
                setResendInvitePayload("");
                toast.showMessage("Error", res.data.message, "error");
            }

            setUpdateDebtorRecord(true);
        } else {
            setIsSaving(false);
            setIsDropdownOpen(false);
            setResendInvitePayload("");
            toast.showMessage("Error", "Server error.", "error");
        }
    };

    // UseEffect
    useEffect(() => {
        if (formik.values.zip && formik.values.zip.length >= 4 && isZipSearch) {
            fetchZipCodeData(formik.values.zip);
        } else if (formik.values.zip?.length < 3) {
            setZipCode([]);
            setIsZipSearch(true);
            formik.values.state = "";
            formik.values.city = "";
        }
    }, [formik.values.zip]);

    useEffect(() => {
        fetchCountryCodes();
        fetchProspectDropdown();
    }, []);

    useEffect(() => {
        const apiCall = async () => {
            formik.values.id = debtor.profile.id;
            formik.setFieldValue("prospect_id", debtor.profile.prospect_id);
            formik.setFieldValue("legal_name", debtor.profile.legal_name);
            formik.setFieldValue("country_phone_code", debtor.profile.country_phone_code);
            formik.setFieldValue("phone", debtor.profile.phone);
            formik.setFieldValue("country_fax_code", debtor.profile.country_fax_code);
            formik.setFieldValue("fax", debtor.profile.fax);
            formik.setFieldValue("email", debtor.profile.email);
            formik.setFieldValue("address", debtor.profile.address);
            formik.setFieldValue("zip", debtor.profile.zip);
            formik.setFieldValue("state", debtor.profile.state);
            formik.setFieldValue("city", debtor.profile.city);
            formik.setFieldValue("fein", debtor.profile.fein);
            formik.setFieldValue("credit_limit", debtor.profile.credit_limit);
            formik.setFieldValue("optional_comments", debtor.profile.comments);
            setPhoneCodesPlaceholders({
                phone_code: debtor.profile.country_phone_code,
                fax_code: debtor.profile.country_fax_code,
            });

            if (debtor.profile.id) {
                setDebtorId(debtor.profile.id);
            }
        };
        if (debtor && debtor.profile && Object.keys(debtor.profile).length > 0) {
            apiCall();
        }
        setIsZipSearch(false);
    }, [debtor]);

    useEffect(() => {
        if (formik.values.legal_name === undefined) {
            formik.values.legal_name = "";
        }

        let foundInList = false;
        debtors.map((item) => {
            if (item.debtor_name === formik.values.legal_name) {
                foundInList = true;
                if (!debtorPocs || debtorPocs?.length == 0) {
                    if (!prospect_id) {
                        fetchDebtorById(item.prospect_id, item.id);
                        fetchDebtorPocs(item.prospect_id, item.id); //fetch debtor pocs.
                    }
                }
            }
        });

        if (!foundInList) {
            if (formik.values.legal_name && formik.values.legal_name.length > 2) {
                const delayDebounceFn = setTimeout(() => {
                    fetchDebtorDropdown(formik.values.legal_name);
                }, 500);

                return () => clearTimeout(delayDebounceFn);
            } else {
                fetchDebtorDropdown();
            }
        }
    }, [formik.values.legal_name, formik.values.prospect_id]);

    useEffect(() => {
        fetchIndustryDropdown();
    }, [formik.values.prospect_id]);

    useEffect(() => {
        if (debtorById && debtorById.hasOwnProperty("id")) {
            formik.setFieldValue("id", debtorById.id);
            formik.setFieldValue("phone", debtorById.phone);
            formik.setFieldTouched("phone", false);
            formik.setFieldValue("country_phone_code", debtorById.country_phone_code);
            formik.setFieldValue("fein", debtorById.fein);
            formik.setFieldTouched("fein", false);
            formik.setFieldValue("zip", debtorById.zip);
            formik.setFieldTouched("zip", false);
            formik.setFieldValue("state", debtorById.state);
            formik.setFieldValue("city", debtorById.city);
            formik.setFieldValue("email", debtorById.email);
            formik.setFieldTouched("email", false);
            formik.setFieldValue("address", debtorById.address);
            formik.setFieldTouched("address", false);
            formik.setFieldValue("fax", debtorById.fax);
            formik.setFieldValue("country_fax_code", debtorById.country_fax_code);
            formik.setFieldValue("credit_limit", debtorById.credit_limit);
            formik.setFieldTouched("credit_limit", false);
            formik.setFieldValue("optional_comments", debtorById.optional_comments);

            // formik.validateForm();

            setIsZipSearch(false);
        }
    }, [debtorById]);

    useEffect(() => {
        if (isRemovingDebtorPoc) {
            setDebtorPocs([]);
            setPocs(initialPocState);
            setPocsOnDebtorChange([]);
        }
    }, [isRemovingDebtorPoc]);

    useEffect(() => {
        if (debtor && debtor.profile && debtor.profile.industry) {
            const industry_value = industries.find((data) => data.label === debtor.profile.industry)?.value;
            const industry_label = industries.find((data) => data.label === debtor.profile.industry)?.label;

            if (industry_value) {
                setIndustryTitle(industry_label);
                setIndustryValue(industry_value);
                setNewIndustry(false);
                setIndustryInitialValue({ value: industry_value, label: industry_label });
            } else {
                setIndustryInitialValue(null);
                setIndustryTitle(null);
                setIndustryValue(0);
                setNewIndustry(true);
            }
        } else if (industryTitle) {
            setIndustryInitialValue({ value: 0, label: industryTitle });
        } else {
            setIndustryInitialValue(null);
            setIndustryTitle(null);
            setIndustryValue(0);
            setNewIndustry(true);
        }
    }, [debtor, industries]);

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            if (prospects.length > 0) {
                setTimeout(() => {
                    formik.setFieldValue("prospect_id", prospects[0].id);
                }, 100);
            }
        }
    }, [prospects]);

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            if (debtor && debtor.profile && debtor.profile.id) {
                setIsClient(true);
            }
        }
    }, [debtor.profile]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = (e) => {
        e.preventDefault();
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleOptionClick = () => {
        resendInviteHandler(resendInvitePayload);
    };

    const dropdownRef = useRef(null);
    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    useOutsideClickHandler(dropdownRef, closeDropdown);

    const customStyles = {
        control: (base, state) => ({
            ...base,

            color: "#495057 !important", // Change this color to your desired text color
            fontSize: "13px !important",
            minHeight: "39.2px",
            borderRadius: "6px", // Adjust the value as needed
            boxShadow: "none",
            border: "1px solid #ced4da !important",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#495057 !important", // Change this color to your desired text color
            fontSize: "13px !important",
        }),
        menu: (provided, state) => ({
            ...provided,
            // backgroundColor: "in", // Change this color to your desired background color
            // minHeight: "45px !important",
            paddingTop: "4px",
            paddingBottom: "4px",
            marginTop: "4px",
        }),
        option: (base, state) => ({
            ...base,
            // Modify the option's background color when it's selected
            backgroundColor: state.isSelected ? " #eef2ff" : "white",
            color: state.isSelected ? "#4338ca" : "#495057",
            cursor: "pointer",
            ":hover": {
                backgroundColor: state.isSelected ? " #eef2ff" : "#e9ecef", // Change this color to your desired hover color
                color: state.isSelected ? "#4338ca" : "#495057",
                border: "none",
            },
            ":focus": {
                border: "none",
                boxShadow: "none",
            },
            fontSize: "13px !important",
            paddingTop: "10px", // Adjust the padding value as needed
            paddingBottom: "10px", // Adjust the padding value as needed
            paddingRight: "20px", // Adjust the padding value as needed
            paddingLeft: "20px", // Adjust the padding value as needed
        }),
        input: (provided) => ({
            ...provided,
            borderRadius: "6px", // Adjust the value as needed
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#9d9fa3",
            fontSize: "13px !important",
        }),

        noOptionsMessage: (provided) => ({
            ...provided,
            padding: "0.75rem 1.25rem",
            textAlign: "left",
            color: "#495057",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            cursor: "pointer", // Change the cursor style to 'pointer'
        }),
    };

    return (
        <>
            {screens.debtor.loader ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="grid pt-3">
                                <div className="col-12 text-right flex flex-row justify-content-end align-items-center">
                                    {debtor && debtor.profile && debtor.profile.status == 0 && debtorPocs?.length > 0 && user?.userRoles[0]["roleName"] !== ROLE_NAME.CLIENT ? (
                                        <>
                                            {showResendInvite ? (
                                                <>
                                                    <button type="button" className={`dropdown_button ${isDropdownOpen ? "hide-chevron" : ""}`} onClick={toggleDropdown}>
                                                        +{debtorContactsList.length}
                                                    </button>
                                                    {isDropdownOpen && (
                                                        <div className="dropdown_menu" ref={dropdownRef}>
                                                            <ul className="dropdown-list">
                                                                {debtorContactsList.map((item) => (
                                                                    <li key={item.id} className={!item.status || !item.invite_send_date ? "dropdown-item divider no_pointer" : "dropdown-item divider"}>
                                                                        <p className={!item.status || !item.invite_send_date ? "disabled_row_color" : "enabled_row_color"}>{item.title}</p>
                                                                        {item.status ? (
                                                                            <span className="link_class">
                                                                                <p
                                                                                    onClick={() => {
                                                                                        setResendInvitePayload({ poc_id: item.id, email: item.email });
                                                                                    }}
                                                                                >
                                                                                    Resend Invite
                                                                                </p>
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            <CustomAddNewButton
                                                title="Invite Debtor"
                                                type="button"
                                                name="invite_debtor"
                                                onClick={() => {
                                                    setSendInvite(true);
                                                }}
                                                isDisabled={showInviteButton == false}
                                            />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <CustomInputField
                                    className={`col-12 md:col-3 ${screens.debtor.loader && "no-drop"}`}
                                    type="dropdown"
                                    iden="prospect_id"
                                    label="Client Name"
                                    mandatory="*"
                                    formik={formik}
                                    options={prospects}
                                    optionLabel="company_business_name"
                                    optionValue="id"
                                    placeHolder="Select"
                                    filter
                                    filterBy="company_business_name"
                                    handleFilter={handleClientFilter}
                                    disabled={screens.debtor.loader || (debtor && debtor.profile && debtor.profile.id) || user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT}
                                    onChange={(e) => inputHandler(e)}
                                />

                                <CustomInputField
                                    className={`col-12 md:col-3 ${screens.debtor.loader && "no-drop"}`}
                                    type="dropdown"
                                    iden="legal_name"
                                    label="Debtor Name"
                                    mandatory="*"
                                    editable
                                    formik={formik}
                                    options={debtors}
                                    optionLabel="debtor_name"
                                    optionValue="debtor_name"
                                    placeHolder="Select or Type here"
                                    filter
                                    filterBy="debtor_name"
                                    handleFilter={handleDebtorFilter}
                                    disabled={screens.debtor.loader || isClient}
                                    maxLength={40}
                                    keyfilter={/^[A-Za-z0-9\s]+$/}
                                    onChange={(e) => debtorInputChangeHandler(e)}
                                />

                                <div className="col-12 md:col-3">
                                    <label htmlFor="phone" className="lbl">
                                        <b>
                                            Phone No<span className="clr_red">*</span>
                                        </b>
                                    </label>
                                    <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                        <Dropdown
                                            id="country_phone_code"
                                            name="country_phone_code"
                                            value={formik.values.country_phone_code}
                                            className={`proscustom_width_country ${screens.debtor.loader && "no-drop"}`}
                                            options={countryCode}
                                            optionLabel="phone_code"
                                            optionValue="phone_code"
                                            placeholder={phoneCodesPlaceholders.phone_code}
                                            filter
                                            filterBy="phone_code"
                                            emptyMessage="No results found"
                                            disabled={screens.debtor.loader || isClient}
                                            onChange={(e) => inputHandler(e)}
                                        />

                                        <InputMask
                                            name="phone"
                                            id="phone"
                                            mask="999-999-9999"
                                            value={formik.values.phone}
                                            placeholder="999-999-9999"
                                            className={`proscustom_width_phone ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                            disabled={debtorLoading || screens.debtor.loader || isClient}
                                            onChange={(e) => inputHandler(e)}
                                        ></InputMask>
                                    </div>
                                    {getFormErrorMessage("phone")}
                                </div>
                                {/* Zip CODE */}
                                <div className="col-12 md:col-3">
                                    <div className="zip_menu_position">
                                        <CustomInputField
                                            className={`${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                            iden="zip"
                                            label="Zip Code"
                                            mandatory="*"
                                            formik={formik}
                                            placeHolder="Search..."
                                            maxLength={5}
                                            keyfilter={/^[0-9\b]+$/}
                                            disabled={debtorLoading || screens.debtor.loader || isClient}
                                            onChange={(e) => inputHandler(e)}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                        <small className="p-error">{recordNotFoundMsg}</small>
                                    </div>
                                </div>
                                <CustomInputField className="col-12 md:col-3" disabled iden="state" label="State" formik={formik} />
                                <CustomInputField className="col-12 md:col-3" disabled iden="city" label="City" formik={formik} />

                                <CustomInputField
                                    className={`col-12 md:col-6 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                    type="textarea"
                                    mandatory="*"
                                    label="Address"
                                    iden="address"
                                    formik={formik}
                                    rows="1"
                                    placeHolder="Enter Address"
                                    maxLength={60}
                                    disabled={debtorLoading || screens.debtor.loader || isClient}
                                    onChange={(e) => inputHandler(e)}
                                />

                                <div className="col-12 md:col-3">
                                    <label htmlFor="fax" className="lbl">
                                        <b>Fax No</b>
                                    </label>
                                    <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                        <Dropdown
                                            id="country_fax_code"
                                            name="country_fax_code"
                                            value={formik.values.country_fax_code}
                                            className={`proscustom_width_country ${screens.debtor.loader && "no-drop"}`}
                                            options={countryCode}
                                            optionLabel="phone_code"
                                            optionValue="phone_code"
                                            placeholder={phoneCodesPlaceholders.fax_code}
                                            filter
                                            filterBy="phone_code"
                                            emptyMessage="No results found"
                                            disabled={screens.debtor.loader || isClient}
                                            onChange={(e) => inputHandler(e)}
                                        />

                                        <InputMask
                                            name="fax"
                                            id="fax"
                                            mask="999-999-9999"
                                            value={formik.values.fax}
                                            placeholder="999-999-9999"
                                            className={`proscustom_width_phone ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                            disabled={debtorLoading || screens.debtor.loader || isClient}
                                            onChange={(e) => inputHandler(e)}
                                        ></InputMask>
                                    </div>
                                    {getFormErrorMessage("fax")}
                                </div>
                                <div className="col-12 md:col-3 industry">
                                    <label htmlFor="industry" className="lbl ">
                                        <b>Industry</b>
                                    </label>
                                    <CreatableSelect
                                        styles={customStyles}
                                        isClearable
                                        options={industries}
                                        onChange={handleSelectIndustry}
                                        onInputChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        isDisabled={screens.debtor.loader || isClient}
                                        placeholder="Select or Type here"
                                        value={industryInitialValue}
                                        noOptionsMessage={() => "No available options"}
                                    />
                                </div>

                                {
                                    user?.sensitive_information_disclosure ? 
                                    (
                                        <CustomInputField
                                            className={`col-12 md:col-3 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                            iden="fein"
                                            label="FEIN"
                                            mandatory="*"
                                            formik={formik}
                                            disabled={debtorLoading || screens.debtor.loader || isClient}
                                            mask={"99-9999999"}
                                            placeHolder="Enter FEIN"
                                            onChange={(e) => inputHandler(e)}
                                        />
                                    ) : !user?.sensitive_information_disclosure && !debtor?.profile?.id ? (
                                        <CustomInputField
                                            className={`col-12 md:col-3 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                            iden="fein"
                                            label="FEIN"
                                            mandatory="*"
                                            formik={formik}
                                            disabled={debtorLoading || screens.debtor.loader || isClient}
                                            mask={"99-9999999"}
                                            placeHolder="Enter FEIN"
                                            onChange={(e) => inputHandler(e)}
                                        />
                                    )  :  !user?.sensitive_information_disclosure && debtor?.profile?.id  ? (
                                        <CustomInputField
                                            className={`col-12 md:col-3 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                            iden="fein"
                                            label="FEIN"
                                            mandatory="*"
                                            formik={formik}
                                            onChange={(e) => inputHandler(e)}
                                            placeHolder="Enter FEIN"
                                            disabled={true}
                                            autoComplete="none"
                                        />
                                    ) : ""

                                }

                                <CustomInputField
                                    className={`col-12 md:col-3 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                    mandatory="*"
                                    iden="email"
                                    label="Company Email"
                                    formik={formik}
                                    placeHolder="Enter Company Email"
                                    maxLength={50}
                                    disabled={debtorLoading || screens.debtor.loader || isClient}
                                    onChange={(e) => inputHandler(e)}
                                />

                                <CustomInputField
                                    className={`col-12 md:col-3 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                    type="currency"
                                    iden="credit_limit"
                                    label="Weekly Credit Limit"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="$10000000.00"
                                    max={99999999.99}
                                    keyfilter={/^\d*\.?\d*$/}
                                    onValueChange={(e) => handleCreditLimitChange(e, "credit_limit")}
                                    onPaste={(e) => e.preventDefault()}
                                    disabled={debtorLoading || screens.debtor.loader || (debtor && debtor.profile && debtor.profile.id) || isClient}
                                />

                                <CustomInputField
                                    className={`col-12 md:col-9 ${(debtorLoading || screens.debtor.loader) && "no-drop"}`}
                                    type="textarea"
                                    iden="optional_comments"
                                    label="Comments"
                                    formik={formik}
                                    placeHolder="Optional Comments"
                                    rows="1"
                                    maxLength={500}
                                    disabled={debtorLoading || screens.debtor.loader || isClient}
                                    onChange={(e) => inputHandler(e)}
                                />
                            </div>
                        </div>
                        <div className="action-btns-prospect-form pt-6">
                            <CustomCancelbtn id="cancel" title="Cancel" type="button" onClick={cancelHandlder} />
                            <CustomAddNewButton id="submit" title="Next" type="submit" isDisabled={debtorLoading || screens.debtor.loader || debtorExistsChecking || creditLimitLoading || isClient} />
                        </div>
                    </form>
                    <Dialog
                        visible={showDebtorPocModal}
                        onHide={() => setShowDebtorPocModal(false)}
                        header="Debtor Point of Contacts"
                        breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                        style={{ width: "40vw" }}
                    >
                        <DebtorPocModal pocs={pocsOnDebtorChange} onHide={debtorPocModalCloseHandler} replicatePocs={replicatePocs} />
                    </Dialog>
                </div>
            )}

            {sendInvite && (
                <CustomConfirmDialog
                    header="Confirm Invite"
                    onHide={() => setSendInvite(false)}
                    type="success"
                    handler={inviteDebtorHandler}
                    icon={<BsCheckLg />}
                    isDisabled={isSaving}
                    title="Confirm"
                    firsttext="Are you sure, you want to send invite to the debtor contact(s)?"
                    className="custom_confirm_dialog"
                />
            )}

            {resendInvitePayload && (
                <CustomConfirmDialog
                    header="Confirm Resend Invite"
                    onHide={() => setResendInvitePayload("")}
                    type="success"
                    handler={handleOptionClick}
                    icon={<BsCheckLg />}
                    isDisabled={isSaving}
                    title="Confirm"
                    firsttext="Are you sure, you want to send invite to the debtor contact(s)?"
                    className="custom_confirm_dialog"
                />
            )}
        </>
    );
};

export default DebtorForm;
