import React, { useState } from "react";
//styles
import "../../BankAccount/bankaccount.scss"
//components
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputMask } from "primereact/inputmask";

const AddNewBankAccount = ({onHide}) => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
        country: Yup.string().required("This field is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            code: "",
            email: "",
            city: "",
            state: "",
            phone: "",
            address: "",
            country:"",
        },
        onSubmit: async (data) => {
            console.log(data);
            return;
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };;
    const countryoption = [
        { name: "US", value: "a" },
        { name: "Pakistan", value: "b" },
        { name: "Austrailia", value: "c" },
    ];

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="step-form-top-padding">
                <div className="grid ">
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "step-fields-title")}>
                            Bank Name*
                        </label>
                        <InputText
                            name="name"
                            id="name"
                            type="text"
                            placeholder="Enter bank name"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": isFormFieldValid("name") }, "mt-2")}
                            onBlur={formik.handleBlur}
                        />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="code" className="step-fields-title">
                            Bank Code*
                        </label>
                        <InputText
                            name="code"
                            id="code"
                            type="text"
                            placeholder="Enter bank code"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            className= "mt-2"
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="md:col-6  col-12 row-margin-bottom">
                        <label htmlFor="email" className="step-fields-title">
                            Email Address*
                        </label>
                        <InputText
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Enter email address"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className="mt-2"
                            onBlur={formik.handleBlur}
                        />

                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="city" className="step-fields-title">
                            City
                        </label>
                        <InputText
                            name="code"
                            id="code"
                            type="text"
                            placeholder="Enter bank code"
                            keyfilter="alpha"
                            maxLength="24"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            className= "mt-2"
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="state" className="step-fields-title">
                            State
                        </label>
                        <InputText name="state" id="state" type="text" placeholder="Enter state name" keyfilter="alpha" maxLength="24" value={formik.values.state} onChange={formik.handleChange} className="mt-2" onBlur={formik.handleBlur} />
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="phone" className={classNames({ "p-error": isFormFieldValid("phone") }, "step-fields-title")}>
                            Phone *
                        </label>
                        <div className={classNames({ "p-invalid": isFormFieldValid("phone") }, "border_div")}>
                            <Dropdown id="country" name="country" value={formik.values.country} onChange={formik.handleChange} className="custom_width_country" options={countryoption} optionLabel="name" placeholder="Select" filter filterBy="country" onBlur={formik.handleBlur} />

                            <InputMask name="phone" id="phone" mask="(999) 999-9999" value={formik.values.phone} placeholder="(999) 999-9999" onChange={formik.handleChange} className="custom_width_phone" onBlur={formik.handleBlur}></InputMask>
                        </div>
                        {getFormErrorMessage("phone")}
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="country" className="step-fields-title">
                            Coutnry
                        </label>
                        <Dropdown id="country" name="country" value={formik.values.country} onChange={formik.handleChange} className="mt-2" options={countryoption} optionLabel="name" placeholder="Select" filter filterBy="name" onBlur={formik.handleBlur}></Dropdown>
                    </div>
                    <div className="md:col-6 col-12 row-margin-bottom">
                        <label htmlFor="address" className="step-fields-title">
                            Address
                        </label>
                        <InputText name="address" id="address" type="text" placeholder="Enter bank address" keyfilter="alpha" maxLength="24" value={formik.values.address} onChange={formik.handleChange} className="mt-2" onBlur={formik.handleBlur} />
                    </div>
                </div>
                <div class="cancel_save_btn mt-4">
                    <Button
                        label="Cancel"
                        className="btn btn-default"
                        onClick={(e) => {
                            e.preventDefault();
                            onHide();
                        }}
                    />

                    <Button
                        label="Save"
                        type="submit"
                        className="btn btn-default savebtn"
                        //  onClick={handleSaveAndNext}
                    />
                </div>
            </form>
        </>
    );
}

export default AddNewBankAccount