import React from "react";

//style sheet
import "./legends.scss";

const Legends = (props) => {
    const { classes, title } = props;
    return (
        <div className="flex">
            <div className={`${classes}`}></div>
            <p className="square-legend-text">{title}</p>
        </div>
    );
};

export default Legends;
