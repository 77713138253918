import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Prime React
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";

// Moment
import moment from "moment";

// Context
import { ToastContext } from "../../../context/toast";

// Redux
import { loginState } from "../../../redux/login";
import { useDispatch, useSelector } from "react-redux";
import {
    mainViewState,
    onFormDirty,
    onProspectAttachment,
    onProspectBusinessProprietorFormDirty,
    onProspectCountryCodes,
    onProspectDataChange,
    onProspectFormDirty,
    onProspectIndexChange,
    onProspectIsEditing,
    onProspectKnobValue,
    onProspectSupplierFormDirty,
} from "../../../redux/main-view";

// Constants
import { FEDRAL_TAXES_PAST_DUE, TAXI_LIEN_FIELD } from "../../../utils/Constants/global";

// Components
import { Header } from "../Header/Header";
import CustomLoading from "../../CustomSpinner/custom_spinner";
import CustomCancelbtn from "../../CustomCancelbtn/CustomCancelbtn";
import CustomTextField from "../../CustomTextField/CustomTextField";
import CustomInputMask from "../../CustomInputMask/CustomInputMask";
import CustomInputField from "../../CustomInputField/CustomInputField";
import CustomAddNewButton from "../../CustomAddNewButton/CustomAddNewButton";

import CustomInputTextArea from "../../CustomInputTextArea/CustomInputTextArea";
import CustomDropdownField from "../../CustomDropdownField/CustomDropdownField";
import ZipCodesMenuComponent from "../../ZipCodesMenu/ZipCodesMenuComponent";

// Helper Function
import { useQuery } from "../../../utils/HelperFuctions/UseQuery";
import { AmountCheck } from "../../../utils/HelperFuctions/checkAmount";
import { knobPercentage } from "../../../utils/HelperFuctions/prospectHelper";
import { checkPathname } from "../../../utils/HelperFuctions/getPathNameForProspect";
import { convertingDateToUTC } from "../../../utils/HelperFuctions/convertingDateToUTC";
import { countryCodeSearchHandler } from "../../../utils/HelperFuctions/phoneCodeSearch";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";

// API'S
import { getStates } from "../../../services/states";
import { getZipCode } from "../../../services/zip-code";
import { getAllDropdown } from "../../../services/support";
import { getCountryCode } from "../../../services/country-code";
import { fetchDraftProspect, fetchProspectById, saveProspect, unSavedProspectSendEmail } from "../../../services/prospect";
import { getReferralCode } from "../../../services/broker";
import { Button } from "primereact/button";

const validationSchema = Yup.object().shape({
    company_business_name: Yup.string().required("Registered Business Name is required"),
    poc_name: Yup.string().required("Contact Name is required"),
    poc_email: Yup.string().required("Contact Email is required").email("Invalid Email"),
    company_phone: Yup.string().required("Phone No is required"),
    company_date_established: Yup.string().required("Business Established is required"),
    company_fein: Yup.string().required("FEIN is required"),
    company_zip: Yup.string().required("Zip Code is required").min(5, "Zip Code must be at least 5 characters"),
    company_address: Yup.string().required("Company Address is required"),
    company_legal_status: Yup.string().required("Legal Status is required"),
    company_company_taxes_past_due_status: Yup.string().required("Federal/State Tax Due is required"),
    company_tax_lien_field: Yup.string().required("Tax Lien Filed is required"),
    broker_referral_code: Yup.string().nullable().min(7, "Referral Code must be at least 7 characters"),
    company_state: Yup.string().required("State is required"),
    company_city: Yup.string().required("City is required"),
});

const BusinessInformation = (props) => {
    const { sendNextStep, setIsOpeneing, frontline_color } = props;

    // use  location to get the pathname
    const location = useLocation();

    const history = useHistory();

    // Editing Scenario Prospect Id
    let id = location.state?.id;

    //Dispatch
    const dispatch = useDispatch();

    const businessRegistrationStateRef = useRef(null);

    // Redux
    const { screens } = useSelector(mainViewState);
    const { user } = useSelector(loginState);

    // Context
    const toast = useContext(ToastContext);

    // States
    const [states, setStates] = useState([]);
    const [zipCode, setZipCode] = useState([]);
    const [countryCode, setCountryCode] = useState([]);
    const [legalStatus, setLegalStatus] = useState([]);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [phoneCodesPlaceholders, setPhoneCodesPlaceholders] = useState({
        phone_code: "+1",
        fax_code: "+1",
    });

    // View State
    const [loading, setLoading] = useState(false);
    const [stateLoad, setStateLoad] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isZipCodeSearch, setIsZipSearch] = useState(true);
    const [isCalenderVisible, setIsCalenderVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let query = useQuery();
    let code = query.get("code");
    const [isFetchingReferralCode, setIsFetchingReferralCode] = useState(false);
    const [referralCode, setReferralCode] = useState("");

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            company_business_name: "",
            poc_name: "",
            poc_email: "",
            company_phone: "",
            company_date_established: "",
            company_fein: "",
            company_zip: "",
            company_address: "",
            company_legal_status: "",
            company_company_taxes_past_due_status: "",
            company_tax_lien_field: "",
            company_state: "",
            company_city: "",
        },
        onSubmit: async (data) => {
            try {
                setIsSaving(true);
                setIsLoading(false);
                data = {
                    ...data,
                    step: "1",
                    company_country_fax_code: data.company_country_fax_code ? data.company_country_fax_code.phone_code : "+1",
                    company_country_phone_code: data.company_country_phone_code ? data.company_country_phone_code.phone_code : "+1",
                };
                const pathname = location.pathname;
                const isFound = checkPathname(pathname);
                if (!isFound) {
                    data["company_id"] = user?.company_id;
                }
                if (screens.prospect.data) {
                    data["id"] = screens.prospect.data.id;
                }
                if (screens.prospect.isFormDirty) {
                    sendNextStep(2);
                    dispatch(onProspectFormDirty(false));
                } else {
                    let res = await saveProspect(data);
                    if (res) {
                        if (res.data.status) {
                            dispatch(onProspectDataChange(res.data.prospect));
                            const isEditing = localStorage.getItem("isEditing");
                            if (isEditing) {
                                dispatch(onProspectKnobValue(100));
                            } else {
                                dispatch(onProspectKnobValue(17));
                            }
                            dispatch(onFormDirty(false));
                            sendNextStep(2);
                        } else {
                            toast.showMessage("Creation Failed", res.data.message, "error");
                        }
                    } else {
                        toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                        setIsSaving(false);
                        setIsLoading(false);
                    }
                }

                setIsSaving(false);
                setIsLoading(false);
            } catch (error) {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
                setIsSaving(false);
                setIsLoading(false);
            }
        },
    });

    // Validation
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSaveAndNext = () => {
        dispatch(onProspectKnobValue(88));
        sendNextStep(5);
    };

    // Handlers
    const handleCalendarVisibleChange = (visible) => {
        setIsCalenderVisible(visible.visible);
    };
    const inputFieldDataCleaningHandler = (name, value) => {
        if (name === "company_business_name" || name === "doing_business_as" || name === "poc_name") {
            return toTitleCaseWhileTyping(value);
        } else if (name === "company_date_established") {
            if (value) {
                // return new Date(value);
                return convertingDateToUTC(value);
            } else {
                return "";
            }
        } else if (name === "company_taxes_past_due_amount") {
            return AmountCheck(value.toString());
        } else if (name === "company_tax_lien_field" || name === "company_country_phone_code" || name === "company_country_fax_code" || name === "company_legal_status") {
            return value;
        } else {
            return textExtraSpaceRemoval(value);
        }
    };

    const inputHandler = (e) => {
        dispatch(onProspectFormDirty(false));
        dispatch(onFormDirty(true));

        const { name, value } = e.target;
        setIsZipSearch(true);

        if (name === "company_company_taxes_past_due_status") {
            if (value === 0) {
                setIsDisabled(true);
                formik.setFieldValue("company_taxes_past_due_type", "");
                formik.setFieldValue("company_taxes_past_due_amount", "");
            } else {
                setIsDisabled(false);
            }
            formik.setFieldValue(name, value);
        } else if (name === "company_date_established") {
            if (e.originalEvent.key === "Enter" || e.originalEvent.type === "blur") {
                if (name === "company_date_established") {
                    setIsCalenderVisible(true);
                }

                // setIsCalenderVisible(true);
                const nextInput = document.getElementById("company_fein");
                if (nextInput) {
                    // nextInput.focus();
                }
            }
            if (value) {
                formik.setFieldValue(name, new Date(value));
            } else {
                formik.setFieldValue(name, "");
            }
        } else {
            formik.setFieldValue([name], inputFieldDataCleaningHandler(name, value));
        }
    };

    const handleCurrencyChange = (event, name) => {
        dispatch(onProspectFormDirty(false));
        dispatch(onFormDirty(true));
        if (event.value !== null) {
            let amount = AmountCheck(event.value.toString());
            if (event.value > 100000000) {
                formik.setFieldValue(name, amount);
                return;
            } else {
                formik.setFieldValue(name, event.value);
            }
        } else {
            formik.setFieldValue(name, "");
        }
    };

    const cancelHanlder = () => {
        if (screens.main.isFormDirty) {
            setIsOpeneing(true);
        } else {
            const isEditing = localStorage.getItem("isEditing");
            if (screens.prospect.data && !screens.prospect.isEditing && !isEditing) {
                let payload = {
                    id: screens.prospect.data.id,
                };
                unSavedProspectSendEmail(payload);
            }
            dispatch(onProspectIndexChange(0));
            dispatch(onProspectKnobValue(0));
            dispatch(onProspectDataChange(null));
            dispatch(onProspectAttachment(null));
            dispatch(onProspectIsEditing(false));
            dispatch(onProspectFormDirty(false));
            dispatch(onProspectBusinessProprietorFormDirty(false));
            dispatch(onProspectSupplierFormDirty(false));
            dispatch(onProspectCountryCodes(null));
            dispatch(onFormDirty(false));
            localStorage.removeItem("isEditing");
            history.push("/prospectindex");
        }
    };
    const settingValuesHanlder = (result) => {
        formik.setFieldValue("company_business_name", result?.company_business_name);
        formik.setFieldValue("doing_business_as", result?.doing_business_as);
        formik.setFieldValue("poc_name", result?.poc_name);
        formik.setFieldValue("poc_email", result?.poc_email);
        formik.setFieldValue("company_country_phone_code", result?.company_country_phone_code);
        formik.setFieldValue("company_phone", result?.company_phone);
        formik.setFieldValue("company_fein", result?.company_fein);
        formik.setFieldValue("company_date_established", moment(result?.company_date_established).format("YYYY-MM-DD"));
        formik.setFieldValue("business_registration_state", result?.business_registration_state);
        formik.setFieldValue("company_number_of_employees", result?.company_number_of_employees);
        formik.setFieldValue("company_zip", result?.company_zip);
        formik.setFieldValue("company_state", result?.company_state);
        formik.setFieldValue("company_city", result?.company_city);
        formik.setFieldValue("company_country_fax_code", result?.company_country_fax_code);
        formik.setFieldValue("company_fax", result?.company_fax);
        formik.setFieldValue("company_legal_status", result?.company_legal_status);
        formik.setFieldValue("company_address", result?.company_address);
        formik.setFieldValue("company_company_taxes_past_due_status", result?.company_company_taxes_past_due_status === null ? "" : result?.company_company_taxes_past_due_status ? 1 : 0);
        setIsDisabled(result?.company_company_taxes_past_due_status ? false : true);
        formik.setFieldValue("company_taxes_past_due_type", result?.company_taxes_past_due_type);
        formik.setFieldValue("company_taxes_past_due_amount", result?.company_taxes_past_due_amount);
        formik.setFieldValue("company_tax_lien_field", result?.company_tax_lien_field === null ? "" : result?.company_tax_lien_field ? 1 : 0);
        formik.setFieldValue("reference", result?.reference);
        formik.setFieldValue("broker_referral_code", result?.broker_referral_code);
        formik.setFieldValue("company_business_description", result?.company_business_description);
        setPhoneCodesPlaceholders({
            phone_code: result?.company_country_phone_code,
            fax_code: result?.company_country_fax_code,
        });
    };
    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRcordNotFoundMsg("");
        formik.values.company_zip = zip.zip;
        formik.values.company_state = zip.full_state;
        formik.values.company_city = zip.city;
        formik.setFieldError("company_state", "");
        formik.setFieldError("company_city", "");
        formik.setFieldError("zip", "");
        setZipCode([]);
    };
    // Api Handler
    const fetchZipCodeData = (value) => {
        const paylod = {
            zip: value,
        };
        getZipCode(paylod).then((res) => {
            setZipCode(res.data.codes);
            if (res.data.codes.length === 0) {
                setZipCode([]);
                setRcordNotFoundMsg("No record available");
            }
        });
    };
    const fetchCountryCodes = async () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                setCountryCode(res.data.countries);
                dispatch(onProspectCountryCodes(res.data.countries));
            });
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const getProspectById = (state) => {
        const data = JSON.parse(localStorage.getItem("MenuData"));
        // const
        setIsLoad(true);
        let payload = {
            id: id,
            status: data ? data.status : 0
        };
        try {
            if (id && id.length > 20) {
                fetchDraftProspect(payload).then((res) => {
                    if (res?.data.status) {
                        setIsZipSearch(false);
                        dispatch(onProspectFormDirty(true));
                        dispatch(onProspectDataChange(res.data.prospect));
                        dispatch(onProspectAttachment(res.data.prospect.support_attachments));
                        dispatch(onProspectKnobValue(knobPercentage(res.data.prospect.step)));
                        // Setting Form Value
                        settingValuesHanlder(res.data.prospect);

                        const prospectStep = res.data.prospect.step <= 5 ? res.data.prospect.step + 1 : res.data.prospect.step;
                        sendNextStep(prospectStep);
                        setIsLoad(false);
                    } else {
                        const message = res?.data.message ? res.data.message : "Server Error";
                        toast.showMessage("Error", message, "error");
                        setIsLoad(false);
                    }
                });
            } else {
                fetchProspectById(payload).then((res) => {
                    if (res?.data.status) {
                        setIsZipSearch(false);
                        dispatch(onProspectFormDirty(true));
                        dispatch(onProspectDataChange(res.data.prospect));
                        dispatch(onProspectAttachment(res.data.prospect.support_attachments));
                        dispatch(onProspectKnobValue(knobPercentage(res.data.prospect.step)));
                        // Setting Form Value
                        settingValuesHanlder(res.data.prospect);
                        setIsLoad(false);
                    } else {
                        const message = res?.data.message ? res.data.message : "Server Error";
                        toast.showMessage("Error", message, "error");
                        setIsLoad(false);
                    }
                });
            }
        } catch (error) {
            setIsLoad(false);
            toast.showMessage("Error", "Server Error", "error");
        }
    };
    const fetchingDropdownValues = async () => {
        try {
            setStateLoad(true);
            const data = {
                select: ["prospect_legal_status "],
            };
            getAllDropdown(data).then((res) => {
                const _type = JSON.parse(res.data.dropdowns.prospect_legal_status);
                setLegalStatus(_type);
            });
            getStates().then((res) => {
                if (res.data.status) {
                    setStates(res.data.states);
                    setStateLoad(false);
                }
            });
        } catch (error) {
            toast.showMessage("Error", "Server Error", "error");
            setStateLoad(false);
        }
    };

    const fetchReferralCode = async () => {
        try {
            setIsFetchingReferralCode(true);
            let payload = {
                code: code,
            };
            let resp = await getReferralCode(payload);
            if (resp.data.status) {
                setIsFetchingReferralCode(false);
                setReferralCode(resp.data.referralCode);
                formik.setFieldValue("broker_referral_code", resp.data.referralCode);
            }
        } catch (error) {
            setIsFetchingReferralCode(false);
        }
    };

    // UseEffect
    useEffect(() => {
        if (formik.values.company_zip && formik.values.company_zip?.length >= 4 && isZipCodeSearch && formik.touched) {
            fetchZipCodeData(formik.values.company_zip);
        } else if (formik.values.company_zip?.length < 4) {
            setZipCode([]);
            setIsZipSearch(true);
            formik.values.company_state = "";
            formik.values.company_city = "";
        }
    }, [formik?.values?.company_zip]);

    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCode(screens.prospect.countryCodes);
        } else {
            fetchCountryCodes();
        }
        if (id) {
            if (screens.prospect.data === null) {
                getProspectById(true);
            } else {
                dispatch(onProspectFormDirty(true));
                setIsZipSearch(false);
                const result = screens.prospect.data;
                settingValuesHanlder(result);
            }
        } else {
            if (screens.prospect.data) {
                dispatch(onProspectFormDirty(true));
                setIsZipSearch(false);
                const result = screens.prospect.data;
                settingValuesHanlder(result);
            }
        }
        fetchingDropdownValues();
    }, []);
    useEffect(() => {
        if (code) {
            fetchReferralCode();
        }
    }, []);

    // const monthNavigatorTemplate = (e) => {
    //     return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    // };

    // const yearNavigatorTemplate = (e) => {
    //     return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
    // };
    return (
        <>
            <Header />
            {isLoad ? (
                <CustomLoading />
            ) : (
                <form autoComplete="off">
                    <div className={frontline_color ? "frontLine_scroll" : "pros_scrollbar"}>
                        <div className="grid pt-3">
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="company_business_name"
                                label="Registered Business Name "
                                mandatory="*"
                                maxLength={50}
                                formik={formik}
                                placeHolder="Enter Registered Business Name"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="doing_business_as"
                                label="Doing Business As (DBA) "
                                maxLength={35}
                                formik={formik}
                                placeHolder="Enter DBA"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="poc_name"
                                label="Contact Name"
                                mandatory="*"
                                maxLength={30}
                                formik={formik}
                                placeHolder="Enter Contact Name"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="poc_email"
                                type="email"
                                label="Contact Email"
                                mandatory="*"
                                maxLength={50}
                                formik={formik}
                                placeHolder="Enter Contact Email"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />

                            <div className="md:col-2 col-12 ">
                                <label htmlFor="phone" className="step-fields-title">
                                    <b>Phone No</b>
                                    <span className="clr_red">*</span>
                                </label>
                                <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                    <Dropdown
                                        id="company_country_phone_code"
                                        name="company_country_phone_code"
                                        options={countryCode}
                                        optionLabel="phone_code"
                                        onChange={(e) => inputHandler(e)}
                                        value={formik.values.company_country_phone_code}
                                        placeholder={phoneCodesPlaceholders.phone_code}
                                        disabled={loading}
                                        filter
                                        filterBy="phone_code"
                                        emptyMessage="No results found"
                                        className="proscustom_width_country"
                                    />

                                    <InputMask
                                        name="company_phone"
                                        id="company_phone"
                                        mask="999-999-9999"
                                        value={formik.values.company_phone}
                                        placeholder="999-999-9999"
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_phone"
                                        autoComplete="none"
                                    ></InputMask>
                                </div>
                                {getFormErrorMessage("company_phone")}
                            </div>
                            <div className="col-12 md:col-2">
                                <label htmlFor="phone" className="step-fields-title">
                                    <b>Business Established</b>
                                    <span className="clr_red">*</span>
                                </label>
                                <div className="mt-2">
                                    <Calendar
                                        id="company_date_established"
                                        name="company_date_established"
                                        value={new Date(formik.values.company_date_established)}
                                        placeholder="mm/dd/yyyy"
                                        mask="99/99/9999"
                                        showIcon
                                        maxDate={new Date()}
                                        onVisibleChange={handleCalendarVisibleChange}
                                        visible={isCalenderVisible}
                                        onChange={(e) => inputHandler(e)}
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1945:2050"
                                    />
                                </div>
                                {getFormErrorMessage("company_date_established")}
                            </div>

                            {
                                user?.sensitive_information_disclosure ? 
                                (
                                    <CustomInputMask
                                        className="col-12 md:col-2"
                                        iden="company_fein"
                                        label="FEIN"
                                        mandatory="*"
                                        formik={formik}
                                        onChange={(e) => inputHandler(e)}
                                        mask="99-9999999"
                                        placeHolder="Enter FEIN"
                                        maskChar={null}
                                        autoComplete="none"
                                    />
                                ) : !user?.sensitive_information_disclosure && !screens?.prospect?.data?.company_fein ? (
                                    <CustomInputMask
                                        className="col-12 md:col-2"
                                        iden="company_fein"
                                        label="FEIN"
                                        mandatory="*"
                                        formik={formik}
                                        onChange={(e) => inputHandler(e)}
                                        mask="99-9999999"
                                        placeHolder="Enter FEIN"
                                        maskChar={null}
                                        autoComplete="none"
                                    />
                                )  :  !user?.sensitive_information_disclosure && screens?.prospect?.data?.company_fein  ? (
                                    <CustomInputField
                                    className="col-12 md:col-2"
                                    iden="company_fein"
                                    label="FEIN"
                                    mandatory="*"
                                    formik={formik}
                                    onChange={(e) => inputHandler(e)}
                                    // mask="99-9999999"
                                    placeHolder="Enter FEIN"
                                    // maskChar={null}
                                    disabled={true}
                                    autoComplete="none"
                                />
                                ) : ""

                            }

                            <CustomInputField
                                ref={businessRegistrationStateRef}
                                className="col-12 md:col-2"
                                options={states}
                                optionLabel="value"
                                iden="business_registration_state"
                                label="Business Registration State"
                                formik={formik}
                                type="dropdown"
                                filter={true}
                                filterBy="value"
                                placeHolder="Select"
                                disabled={stateLoad}
                                autoComplete="none"
                            />

                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="company_number_of_employees"
                                label="Employee Count"
                                type="text"
                                keyfilter="int"
                                maxLength={4}
                                formik={formik}
                                placeHolder="Enter Employee Count"
                                onChange={(e) => inputHandler(e)}
                                onPaste={(e) => e.preventDefault()}
                                autoComplete="none"
                            />

                            {/* Zip CODE */}
                            <div className="col-12 md:col-2">
                                <div className="zip_menu_position">
                                    {/* <CustomZipCodeDropdown
                                        mandatory="*"
                                        iden="company_zip"
                                        label="Zip Code"
                                        optionLabel="zip"
                                        formik={formik}
                                        placeHolder="Search..."
                                        maxLength={5}
                                        filter
                                        filterBy="zip"
                                        selectedZip={selectedZip}
                                        setSelectedZip={setSelectedZip}
                                    /> */}
                                    <CustomTextField
                                        iden="company_zip"
                                        label="Zip Code"
                                        mandatory="*"
                                        type="text"
                                        keyfilter="int"
                                        formik={formik}
                                        placeHolder="Search..."
                                        maxLength={5}
                                        onChange={(e) => inputHandler(e)}
                                        onPaste={(e) => e.preventDefault()}
                                        autoComplete="none"
                                    />
                                    <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                    <small className="p-error">{recordNotFoundMsg}</small>
                                </div>
                            </div>
                            <CustomTextField className="col-12 md:col-2" mandatory="*" disabled iden="company_state" label="State" formik={formik} placeHolder="" onChange={(e) => inputHandler(e)} />
                            <CustomTextField className="col-12 md:col-2" mandatory="*" disabled iden="company_city" label="City" formik={formik} placeHolder="" onChange={(e) => inputHandler(e)} />

                            <div className="md:col-2 col-12 ">
                                <label htmlFor="faxno" className="step-fields-title">
                                    <b>
                                        Fax No
                                        {/* <span className="clr_red">*</span> */}
                                    </b>
                                </label>
                                <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                    <Dropdown
                                        id="company_country_fax_code"
                                        name="company_country_fax_code"
                                        value={formik.values.company_country_fax_code}
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_country"
                                        options={countryCode}
                                        optionLabel="phone_code"
                                        placeholder={phoneCodesPlaceholders.fax_code}
                                        filter
                                        filterBy="phone_code"
                                        emptyMessage="No results available"
                                        disabled={loading}
                                    />

                                    <InputMask
                                        name="company_fax"
                                        id="company_fax"
                                        mask="999-999-9999"
                                        value={formik.values.company_fax}
                                        placeholder="999-999-9999"
                                        onChange={(e) => inputHandler(e)}
                                        className="proscustom_width_phone"
                                        autoComplete="none"
                                    ></InputMask>
                                </div>
                                {getFormErrorMessage("company_fax")}
                            </div>
                            <CustomDropdownField
                                className="col-12 md:col-2"
                                iden="company_legal_status"
                                label="Legal Status"
                                mandatory="*"
                                formik={formik}
                                options={legalStatus}
                                onChange={(e) => inputHandler(e)}
                                optionLabel="name"
                                optionValue="value"
                                placeHolder="Select"
                            />

                            <CustomInputTextArea
                                className="col-12 md:col-6"
                                mandatory="*"
                                label="Company Address"
                                iden="company_address"
                                formik={formik}
                                maxLength={100}
                                rows="1"
                                placeHolder="Enter Company Address"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />

                            <CustomDropdownField
                                className="col-12 md:col-2"
                                iden="company_company_taxes_past_due_status"
                                label="Federal/State Tax Due?"
                                mandatory="*"
                                formik={formik}
                                options={FEDRAL_TAXES_PAST_DUE}
                                onChange={(e) => inputHandler(e)}
                                optionLabel="name"
                                optionValue="value"
                                placeHolder="Select"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="company_taxes_past_due_type"
                                label="Due Type"
                                disabled={isDisabled}
                                formik={formik}
                                maxLength={30}
                                placeHolder="Enter Due Type"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />
                            {/* <CustomTextField
                                className="col-12 md:col-2"
                                iden="company_taxes_past_due_amount"
                                label="Due Amount"
                                keyfilter={/^\d*\.?\d*$/}
                                maxLength="11"
                                disabled={isDisabled}
                                // disabled={formik.values.company_company_taxes_past_due_status === 0 ? true : false}
                                formik={formik}
                                placeHolder="$10000000.00"
                                onChange={(e) => inputHandler(e)}
                                onPaste={(e) => e.preventDefault()}
                            /> */}
                            <CustomInputField
                                className="col-12 md:col-2"
                                iden="company_taxes_past_due_amount"
                                label="Due Amount"
                                formik={formik}
                                type="currency"
                                placeHolder="$0.00"
                                onPaste={(e) => e.preventDefault()}
                                onValueChange={(e) => handleCurrencyChange(e, "company_taxes_past_due_amount")}
                                min={0}
                                disabled={isDisabled}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                max={99999999.99}
                                autoComplete="none"
                            />
                            <CustomDropdownField
                                className="col-12 md:col-2"
                                type="dropdown"
                                iden="company_tax_lien_field"
                                label="Tax Lien Filed?"
                                mandatory="*"
                                formik={formik}
                                options={TAXI_LIEN_FIELD}
                                onChange={(e) => inputHandler(e)}
                                optionLabel="name"
                                optionValue="value"
                                placeHolder="Select"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="reference"
                                label="Referral Platform"
                                formik={formik}
                                maxLength={35}
                                placeHolder="Enter Referral Platform"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />
                            <CustomTextField
                                className="col-12 md:col-2"
                                iden="broker_referral_code"
                                label="Referral Code"
                                formik={formik}
                                maxLength={7}
                                keyfilter="alphanum"
                                placeHolder="Enter Referral Code"
                                onChange={(e) => inputHandler(e)}
                                disabled={referralCode?.length === 7 || isFetchingReferralCode}
                            />
                            <CustomInputTextArea
                                className="col-12 md:col-12"
                                iden="company_business_description"
                                label="Description of Business"
                                formik={formik}
                                maxLength={500}
                                rows="6"
                                placeHolder="Description of Business"
                                onChange={(e) => inputHandler(e)}
                                autoComplete="none"
                            />
                        </div>
                    </div>
                    {/* Buttons */}

                    <div className="action-btns-prospect-form pt-3 pr-3">
                        <CustomCancelbtn title="Cancel" type="button" onClick={cancelHanlder} />
                        {frontline_color ? (
                            <Button label="Save and Next" className="fronLineSavebtn" type="button" disabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} />
                        ) : (
                            <CustomAddNewButton title="Save and Next" type="button" isDisabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} isLoading={isLoading} />
                        )}
                        {/* <CustomAddNewButton title="Save and Next" type="button" isDisabled={isSaving} onClick={() => (isSaving ? null : formik.handleSubmit())} /> */}
                        {/* <CustomAddNewButton title="Save and Next" type="submit" isDisabled={isSaving} onClick={handleSaveAndNext} /> */}
                    </div>
                </form>
            )}
        </>
    );
};

export default BusinessInformation;
