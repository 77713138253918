import React, { useContext, useEffect, useRef, useState } from "react";

//components
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import { getColumnBody } from "../../../../../../utils/HelperFuctions/supportHelper";
import { getCustomDropDown } from "../../../../../../services/dropdown";
import { ToastContext } from "../../../../../../context/toast";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { mainViewState, onChargebackFormDirty } from "../../../../../../redux/main-view";
import { useDispatch, useSelector } from "react-redux";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { useHistory } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import { adjustChargebacks } from "../../../../../../services/chargeback";

const initialValidationState = {
    adjusted_cb: false,
    is_greater: false,
};

const ChargebackAdjustmentModal = (props) => {
    const { onHide, selectedChargebacks, setSelectedChargebacks, selectedColumns, isOpening, setIsOpening } = props;

    const ref = useRef(null);

    const toast = useContext(ToastContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);
    const [isLoading, setIsLoading] = useState(false);
    const [cbAdjustmentTypes, setCbAdjustmentTypes] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [cb, setCb] = useState([]);
    const [chargebackSettleStatus, setChargebackSettleStatus] = useState("");
    const [validations, setValidations] = useState({
        charge_back_settle_status: false,
    });

    const adjustAmountTemplate = (rowData) => {
        return (
            <>
                <form>
                    <div>
                        <InputNumber
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            inputId="cb_to_adjust"
                            name="adjusted_cb"
                            value={rowData.adjusted_cb}
                            onChange={(e) => {
                                handleInputChange(e, rowData);
                            }}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            max="99999999.99"
                            maxLength={2}
                            disabled="true"
                        />
                        {rowData.validations?.adjusted_cb ? (
                            <small className="p-error">CB Amount to be adjusted is required</small>
                        ) : rowData.validations?.is_greater ? (
                            <small className="p-error">CB Amount to be adjusted is greater than CB Amount</small>
                        ) : (
                            ""
                        )}
                    </div>
                </form>
            </>
        );
    };

    const columnComponents = selectedColumns.map((col) => {
        return (
            // col.header == 'CB Amount to be Adjusted' ?
            //     <Column key={col.field} field={col.field} header={col.header} body={adjustAmountTemplate}></Column> :
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getColumnBody(col.field)}
                headerClassName={`${col.field === "invoice_amount" || col.field === "cb" || col.field === "adjusted_cb" ? "amount_column_alignment" : ""}`}
            ></Column>
        );
    });

    const handleInputChange = (e, rowData) => {
        dispatch(onChargebackFormDirty(true));

        const { value } = e;
        const _selectedChargebacks = selectedChargebacks.map((item) => {
            const validations = item.validations;
            if (item.id === rowData.id) {
                return {
                    ...item,
                    adjusted_cb: value,
                    validations: {
                        ...validations,
                        adjusted_cb: !value,
                        is_greater: value > item.cb,
                    },
                };
            }
            return item;
        });

        setSelectedChargebacks(_selectedChargebacks);
        setCb(_selectedChargebacks);
    };

    const fetchCbAdjustmentTypes = async () => {
        let payload = {
            select: ["chargeback_adjust"],
        };
        try {
            const res = await getCustomDropDown(payload);
            if (res?.data?.status) {
                const status = JSON.parse(res.data.dropdowns.chargeback_adjust);
                setCbAdjustmentTypes(status);
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const onChargebackSettleStatusChange = (e) => {
        const { value } = e.target;

        setChargebackSettleStatus(value);
        dispatch(onChargebackFormDirty(true));
    };

    const cancelHandler = () => {
        if (screens.cb.isFormDirty) {
            setIsOpening(true);
        } else {
            dispatch(onChargebackFormDirty(false));
            onHide();
        }
    };

    const saveHandler = async () => {
        let hasError = false;
        let _selectedChargebacks = selectedChargebacks.map((item) => {
            const { validations, ...rest } = item;

            if (!item.adjusted_cb || item.adjusted_cb > item.cb) {
                hasError = true;

                return {
                    ...rest,
                    validations: {
                        adjusted_cb: !item.adjusted_cb,
                        is_greater: item.adjusted_cb > item.cb,
                    },
                };
            } else {
                return {
                    ...rest,
                };
            }
        });

        if (!chargebackSettleStatus) {
            hasError = true;
            setValidations({ charge_back_settle_status: true });
        } else {
            setValidations({ charge_back_settle_status: false });
        }

        setCb(_selectedChargebacks);
        if (hasError) {
            return;
        }

        let payload = {
            cb_to_adjust: _selectedChargebacks.map((item) => ({ id: item.id, cb_to_adjust: item.adjusted_cb })),
            charge_back_settle_status: chargebackSettleStatus,
        };

        setIsSaving(true);
        setIsLoading(true);

        try {
            let res = await adjustChargebacks(payload);
            if (res?.data) {
                if (res.data.status) {
                    toast.showMessage("Chargeback Adjusted", "Chargeback has been adjusted successfully", "success");
                    onHide(true);
                } else {
                    toast.showMessage("Creation Failed", res.data.message, "error");
                }
            } else {
                toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
            }

            setIsSaving(false);
            setIsLoading(false);
        } catch (e) {
            toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
            setIsSaving(false);
            setIsLoading(false);
        }
    };

    const confirmHandler = () => {
        dispatch(onChargebackFormDirty(false));
        setIsOpening(false);
        onHide();
        history.push("/chargebacks");
    };

    useEffect(() => {
        if (cbAdjustmentTypes?.length == 0) {
            fetchCbAdjustmentTypes();
        }
    }, []);

    useEffect(() => {
        if (cb?.length == 0) {
            let _selectedChargebacks = selectedChargebacks.map((item) => {
                item.adjusted_cb = item.cb ? item.cb : "";
                item.validations = initialValidationState;

                return item;
            });

            setCb(_selectedChargebacks);
        }
    }, []);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="custom_scrollbar">
                        <DataTable ref={ref} value={cb} responsiveLayout="scroll" emptyMessage="No record available.">
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            </div>

            <form>
                <div className="col-12 md:col-4">
                    <div style={{ width: "100%", marginBottom: ".5rem" }}>
                        <label htmlFor="Name">
                            <b>
                                Adjust Chargeback <span className="clr_red">*</span>
                            </b>
                        </label>
                    </div>
                    <Dropdown
                        id="charge_back_settle_status"
                        name="charge_back_settle_status"
                        value={chargebackSettleStatus}
                        options={cbAdjustmentTypes}
                        optionLabel="name"
                        placeholder="Select Adjust Chargeback"
                        onChange={onChargebackSettleStatusChange}
                    />
                    {validations?.charge_back_settle_status && <small className="p-error">Adjust Chargeback is required</small>}
                    {/* <Dropdown
                            type="dropdown"
                            iden="cb_type"
                            label="Adjust Chargeback"
                            optionLabel="name"
                            optionValue="value"
                            options={cbAdjustmentTypes}
                            placeHolder="Select"
                        /> */}
                </div>
            </form>

            <div className="col-12 text-center mt-3">
                <CustomCancelbtn type="button" title="Cancel" onClick={cancelHandler} />
                <CustomAddNewButton id="adjust" title="Adjust" type="submit" isDisabled={isSaving} onClick={saveHandler} isLoading={isLoading} />
            </div>
            {isOpening && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpening(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};
export default ChargebackAdjustmentModal;
