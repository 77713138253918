import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export async function fetchDebtConcentration(data = null) {
    return postData(appUrl["dashboard"].fetch_debt_concentration, data);
}

export async function fetchAnalytics(data = null) {
    return postData(appUrl["dashboard"].fetch_analytics, data);
}

export async function fetchInvoiceAnalytics(data = null) {
    return postData(appUrl["dashboard"].fetch_invoice_analytics, data);
}
