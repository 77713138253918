import appUrl from "../../utils/Constants/appUrl";
import { postData } from "../genaric/genaric-api";

export async function getScheduleAssignment(data) {
    return postData(appUrl["schedule-assignment"].fetch, data);
}

export async function createSchedule(data) {
    return postData(appUrl["schedule-assignment"].save, data);
}

export async function getScheduleNo(data) {
    return postData(appUrl["schedule-assignment"].scheduleNo, data);
}

export async function getChargeBackInvoiceStatus(data) {
    return postData(appUrl["schedule-assignment"].checkChargeBacks, data);
}

export async function postVerifySchedule(data) {
    return postData(appUrl["schedule-assignment"].verifySchedule, data);
}

export async function getSchedulesDetail(data) {
    return postData(appUrl["schedule-assignment"].schedulesDetail, data);
}

export async function downloadSchedule(data) {
    return postData(appUrl["schedule-assignment"].scheduleDownload, data);
}

export async function getScheduleInvoices(data) {
    return postData(appUrl["schedule-assignment"].scheduleInvoices, data);
}

export async function getProspectScheduleNo(data) {
    return postData(appUrl["schedule-assignment"].prospectScheduleNo, data);
}

export async function getSchedulePayments(data) {
    return postData(appUrl["schedule-assignment"].schedulePayment, data);
}
export async function deleteSchedule(data) {
    return postData(appUrl["schedule-assignment"].scheduleDelete, data);
}

export async function deleteScheduleInvoice(data) {
    return postData(appUrl["schedule-assignment"].scheduleInvoiceDelete, data);
}

export async function checkInvoiceNo(data) {
    return postData(appUrl["schedule-assignment"].checkUniqueInvoiceNo, data);
}
export async function postRevertSchedule(data) {
    return postData(appUrl["schedule-assignment"].revertSchedule, data);
}

export async function scheduleSOFDetail(data) {
    return postData(appUrl["schedule-assignment"].schedulesSofDetail, data);
}

export async function scheduleFundedReversal(data) {
    return postData(appUrl["schedule-assignment"].fundedScheduleReversal, data);
}




