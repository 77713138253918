import React, { useRef, useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ToastContext } from "../../../../context/toast";
import { getClientDropdown } from "../../../../services/client";
import { getDebtorsForDropdown } from "../../../../services/debtor";
import { getBrokersForDropdown } from "../../../../services/broker";
import { getReports } from "../../../../services/reports";
import { PercentageCheck } from "../../../../utils/HelperFuctions/checkPercentage";
import { InputText } from "primereact/inputtext";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";
import { dateFormatForInputFields } from "../../../../utils/HelperFuctions/dateFormat";
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";
import { loginState } from "../../../../redux/login";
import axios from "axios";
import { saveAs } from "file-saver";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { CHARGEBACKS_REPORT_GRID_HEADERS, COLLECTION_EFFICIENY_ANALYSIS_DATE_FILTER, ESCROW_RESERVE_REPORT_GRID_HEADERS, INVOICE_SUMMARY_AGING_REPORT_GRID_HEADERS, PAYMENT_HISTORY_DETAIL_REPORT_GRID_HEADERS, RESERVE_DETAIL_REPORT_GRID_HEADERS, ROLE_NAME, TRANSACTIONS, currencyFields } from "../../../../utils/Constants/global";
import { getReportsColumnBody } from "../../../../utils/HelperFuctions/reportsHelper";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";

const Body = (props) => {
    const { selectedColumns, globalFilter, reportValue, setSelectedColumns } = props;

    // States
    const [prospectId, setProspectId] = useState("");
    const [debtorId, setDebtorId] = useState("");
    const [brokerId, setBrokerId] = useState("");
    const [limit, setLimit] = useState("");
    const [noOfDays, setNoOfDays] = useState("");
    const [duration, setDuration] = useState("");
    const [dropdownClients, setDropdownClients] = useState([]);
    const [dropdownDebtors, setDropdownDebtors] = useState([]);
    const [dropdownBrokers, setDropdownBrokers] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [rowsCount, setRowsCount] = useState(0);
    const [transactionType, setTransactionType] = useState("");
    const [fiscalYear, setFiscalYear] = useState(null);
    const [fiscalYears, setFiscalYears] = useState(null);
    // View States
    const [clientLoading, setClientLoading] = useState(false);
    const [debtorLoading, setDebtorLoading] = useState(false);
    const [brokerLoading, setBrokerLoading] = useState(false);
    const [gridDataLoading, setGridDataLoading] = useState(false);
    const [exportMenuItems, setExportMenuItems] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    //toast message
    const toast = useContext(ToastContext);
    // Base Url
    let BASE_URL = process.env.REACT_APP_BASE_URL;

    const [isRangeShow, setIsRangeShow] = useState(false);
    const [selectedDateFilter, setSelectedDateFilter] = useState("");
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [dates2, setDates2] = useState(null);
    const [scheduleReport, setScheduleReport] = useState(false);
    //use ref
    const dt = useRef(null);

    let columns = TRANSACTIONS;

    const columnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={getReportsColumnBody(col.field)}
                headerClassName={currencyFields.includes(col.field) || col.field === "debit_amount" || col.field === "credit_amount" || col.field === "cb_uc_amount" ? "amount_column_alignment" : ""}
            />
        );
    });

    const datefilteroption = [
        { name: "Last 24 Hours", value: "24hours" },
        { name: "Last 7 Days", value: "7days" },
        { name: "Last 30 Days", value: "30days" },
        { name: "Last 90 Days", value: "90days" },
        { name: "Date Range", value: "dateRange" },
    ];

    const dateFilterForDebtorAging = [
        { name: "Last 7 Days", value: "7days" },
        { name: "Last 30 Days", value: "30days" },
        { name: "Last 90 Days", value: "90days" },
        { name: "Date Range", value: "dateRange" },
    ];

    const dropdownDuration = [
        { value: 3, title: "3 Months" },
        { value: 6, title: "6 Months" },
        { value: 1, title: "1 Year" },
        { value: "0", title: "All Time" },
    ];

    const transactiontypeOptions = [
        {
            name: "All",
            value: "all",
        },
        {
            name: "Debit",
            value: "debit",
        },
        {
            name: "Credit",
            value: "credit",
        },
    ];

    // Event handler for date filter dropdown value change
    const handleDateFilterChange = (event) => {
        if (event.value === "dateRange") {
            setIsRangeShow(true);
            setSelectedDateFilter(event.value);
        }
        setSelectedDateFilter(event.value);
    };

    // getClientDropdown
    const fetchClients = async (searchValue = null) => {
        setClientLoading(true);
        try {
            let payload = {
                per_page: 100,
                show_all: 1,
                search_text: searchValue ? searchValue : "",
            };
            const res = await getClientDropdown(payload);
            if (res.data.status) {
                let _data = res.data.prospects.data;

                // if (reportValue == "collection_efficiency_analysis" || reportValue == "cash_posting_report") {
                _data.unshift({ id: "0", company_business_name: "All" });
                // }

                setDropdownClients(_data);
                setClientLoading(false);
            }
        } catch (e) {
            setClientLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchDebtors = async () => {
        setDebtorLoading(true);
        try {
            let payload = {
                prospect_id: prospectId,
            };
            let res = await getDebtorsForDropdown(payload);
            if (res.data.status) {
                setDropdownDebtors(res.data.debtors);
                setDebtorLoading(false);
                if (res.data.debtors.length > 0) {
                    setDebtorLoading(false);
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchBrokers = async () => {
        setBrokerLoading(true);
        try {
            const res = await getBrokersForDropdown();
            if (res.data.status) {
                setDropdownBrokers(res.data.brokers);
                setBrokerLoading(false);
            }
        } catch (e) {
            setBrokerLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const exportHandler = (type) => {
        if (selectedDateFilter !== "dateRange" || (selectedDateFilter != null && selectedDateFilter[0] != null && selectedDateFilter[1] != null)) {
            handleDownloadSchedule(type);
        }
    };

    const handleDownloadSchedule = async (type) => {
        setGridDataLoading(true);
        try {
            const accessToken = localStorage.getItem("accessToken");

            let data = {
                prospect_id: prospectId,
                debtor_id: debtorId,
                date_keyword: selectedDateFilter === "dateRange" ? dateFormatForInputFields(dates2[0]) + "," + dateFormatForInputFields(dates2[1]) : selectedDateFilter,
                report: reportValue,
                test_debug: false,
                export: type === "Excel" ? 1 : 0,
            };

            if (reportValue == "broker_commission_report") {
                data["broker_id"] = brokerId;
            } else if (reportValue == "credit_limit_warning") {
                data["limit"] = limit;
            } else if (reportValue == "client_analysis" || reportValue == "collection_efficiency_analysis") {
                data["duration"] = duration;
            } else if (reportValue == "last_payment_date") {
                data["no_of_days"] = noOfDays;
            } else if (reportValue === "transactions") {
                data["fiscal_year"] = fiscalYear;
                data["transaction_type"] = transactionType;
                data["data_type"] = "client";
            }

            const response = await axios.post(`${BASE_URL}reports/generate`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                responseType: "arraybuffer",
            });

            if (response) {
                console.log('rowsCount', rowsCount)
                console.log('REACT_APP_REPORTS_EMAIL_THRESHOLD', parseInt(process.env.REACT_APP_REPORTS_EMAIL_THRESHOLD, 10))
                if (type !== "Excel") {
                    if (rowsCount > parseInt(process.env.REACT_APP_REPORTS_EMAIL_THRESHOLD, 10)) { //process.env.REACT_APP_REPORTS_EMAIL_THRESHOLD
                        toast.showMessage("Report Generated", "PDF report has been sent to you over an email.", "success");
                    } else {
                        const pdfData = new Uint8Array(response.data);
                        const blob = new Blob([pdfData], { type: "application/pdf" });
                        const url = URL.createObjectURL(blob);
                        // window.open(url, "_blank");
                        const a = document.createElement("a");
                        a.href = url;

                        // Set the custom filename
                        a.download = reportValue.includes('_report') ? reportValue + ".pdf" : reportValue + "_report.pdf"; // Replace with your desired filename

                        // Trigger a click event to initiate the download
                        a.click();
                    }
                } else {
                    const filename = reportValue.includes('_report') ? reportValue : reportValue + "_report";

                    saveAs(new Blob([response.data]), filename + ".xlsx");
                }
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }

        setGridDataLoading(false);
    };

    const handleOptionClick = (optionLabel) => {
        exportHandler(optionLabel);
        // Implement your desired functionality for each option here.

        // Close the dropdown after an option is selected
        setDropdownOpen(false);
    };

    const onExportDropdownModifier = (rights) => {
        const export_dropdown_items = [];

        rights.forEach((right, index, arr) => {
            // PDF
            if (right.includes("excel")) {
                export_dropdown_items.push({ id: 1, label: "Excel", required_rights: [right] });
            }

            // Excel
            if (right.includes("pdf")) {
                export_dropdown_items.push({ id: 2, label: "PDF", required_rights: [right] });
            }
        });

        return export_dropdown_items;
    };

    const onExportDropdownModifiedOnPermissions = () => {
        const report = JSON.parse(localStorage.getItem("report") ?? "{}");

        const items = onExportDropdownModifier(report?.required_rights);

        const allowed_items = items.filter((col, index, arr) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setExportMenuItems(allowed_items);
    };

    const fiscalYearsDropDown = () => {
        const startingYear = 2020;
        const currentYear = moment().year();

        let years = [];
        for (let year = startingYear; year <= currentYear; year++) {
            years.push({
                name: year - 1 + " - " + year,
                value: year,
            });
        }
        setFiscalYears(years);
    };

    useEffect(() => {
        if (
            (reportValue === "client_analysis" && prospectId && duration) ||
            (reportValue === "collection_efficiency_analysis" && prospectId && duration) ||
            (reportValue === "cash_posting_report" && prospectId && selectedDateFilter) ||
            (reportValue === "last_payment_date" && debtorId && debtorId != "") ||
            (debtorId && debtorId != "" && selectedDateFilter) ||
            (debtorId && debtorId != "" && selectedDateFilter && reportValue !== "credit_limit_warning") ||
            (brokerId && selectedDateFilter) ||
            (reportValue === "transactions" && prospectId && fiscalYear && transactionType)
        ) {
            let data = {
                prospect_id: prospectId,
                debtor_id: debtorId,
                date_keyword:  selectedDateFilter === "dateRange" 
                ? (dates2?.[0] && dates2?.[1] 
                    ? `${dateFormatForInputFields(dates2[0])},${dateFormatForInputFields(dates2[1])}` 
                    : null)
                : selectedDateFilter,
                report: reportValue,
                test_debug: false,
            };

            if (reportValue == "broker_commission_report") {
                data["broker_id"] = brokerId;
            } else if (reportValue == "credit_limit_warning") {
                data["limit"] = limit;
            } else if (reportValue == "client_analysis" || reportValue == "collection_efficiency_analysis") {
                data["duration"] = duration;
            } else if (reportValue == "last_payment_date") {
                data["no_of_days"] = noOfDays;
            } else if (reportValue === "transactions") {
                data["fiscal_year"] = fiscalYear;
                data["transaction_type"] = transactionType;
                data["data_type"] = "client";
            }

            if (selectedDateFilter !== "dateRange" || (data["date_keyword"] != null && data["date_keyword"][0] != null && data["date_keyword"][1] != null)) {
                setGridDataLoading(true);

                getReports(data).then(function (resp) {
                    if (resp.data.status) {
                        setGridDataLoading(false);

                        // This piece of code is only for updating the columns of transactions report based on transaction type
                        if (reportValue == "transactions") {
                            let updatedColumns = columns;
                            if (transactionType == "debit") {
                                const fieldToRemove = "credit_amount";
                                updatedColumns = updatedColumns.filter((column) => column.field !== fieldToRemove);
                            } else if (transactionType == "credit") {
                                const fieldToRemove = "debit_amount";
                                updatedColumns = updatedColumns.filter((column) => column.field !== fieldToRemove);
                            }

                            setSelectedColumns(updatedColumns);
                        }

                        setGridData(resp.data.gridData);
                        if (resp?.data?.rowsCount) {
                            console.log('rowsCount', resp.data.rowsCount)
                            setRowsCount(resp.data.rowsCount);
                        }
                    }
                    setGridDataLoading(false);
                });
            }

            const reportHeadersMap = {
                'invoice_summary_aging': INVOICE_SUMMARY_AGING_REPORT_GRID_HEADERS,
                'payment_history_detail': PAYMENT_HISTORY_DETAIL_REPORT_GRID_HEADERS,
                'reserve_detail': RESERVE_DETAIL_REPORT_GRID_HEADERS,
                'escrow_reserve': ESCROW_RESERVE_REPORT_GRID_HEADERS,
                'chargebacks': CHARGEBACKS_REPORT_GRID_HEADERS
            };

            if (reportHeadersMap.hasOwnProperty(reportValue)) {
                let updatedColumns = reportHeadersMap[reportValue];

                if (debtorId.length == 1) {
                    const fieldToRemove = "debtor";
                    updatedColumns = updatedColumns.filter(column => column.field !== fieldToRemove);
                }

                setSelectedColumns(updatedColumns);
            }

        }
    }, [debtorId, brokerId, selectedDateFilter, duration, prospectId, reportValue, dates2, limit, noOfDays, fiscalYear, transactionType]);

    useEffect(() => {
        if (reportValue && reportValue != "broker_commission_report") {
            fetchClients();
        }
    }, [reportValue]);

    useEffect(() => {
        if (prospectId && reportValue !== "client_analysis" && reportValue != "collection_efficiency_analysis" && reportValue != "cash_posting_report") {
            setDebtorId("");
            fetchDebtors();
        }
    }, [prospectId, reportValue]);

    useEffect(() => {
        if (reportValue && reportValue == "broker_commission_report") {
            fetchBrokers();
        }
    }, [reportValue]);

    useEffect(() => {
        onExportDropdownModifiedOnPermissions();
    }, [user]);

    useEffect(() => {
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            if (dropdownClients.length > 0) {
                setTimeout(() => {
                    setProspectId(dropdownClients[0].id);
                }, 250);
            }
        }
    }, [dropdownClients]);

    useEffect(() => {
        if (!fiscalYears || fiscalYears?.length > 0) {
            fiscalYearsDropDown();
        }
    }, []);

    const onShow = () => {
        let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    
        // Create a span element for the "Select All" text
        let selectAllText = document.createElement("span");
        selectAllText.textContent = "Select All";
    
        // Add some margin to the left of the text
        selectAllText.style.marginLeft = "6px"; // Adjust the value as needed
        selectAllText.style.marginLeft = "6px"; // Adjust the value as needed
    
        // Insert the text after the checkbox
        selectAllCheckbox.after(selectAllText);
    }

    return (
        <>
            <div className="card">
                <div className="grid">
                    {reportValue === "broker_commission_report" ? (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Broker Name</b>
                            </label>
                            <Dropdown
                                id="brokerId"
                                name="brokerId"
                                placeholder="Select"
                                value={brokerId}
                                options={dropdownBrokers}
                                onChange={(e) => setBrokerId(e.target.value)}
                                optionLabel="name"
                                optionValue="id"
                                disabled={brokerLoading}
                                className="mt-2"
                            />
                        </div>
                    ) : (
                        ""
                    )}

                    {/* {clientReports.includes(reportValue) && ( */}

                    {reportValue !== "broker_commission_report" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Client Name</b>
                            </label>
                            <Dropdown
                                id="prospectId"
                                name="prospectId"
                                placeholder="Select"
                                value={prospectId}
                                options={dropdownClients}
                                onChange={(e) => {
                                    setProspectId(e.target.value);
                                    setDebtorId("");
                                }}
                                optionLabel="company_business_name"
                                optionValue="id"
                                disabled={clientLoading || user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT}
                                className="mt-2"
                                filter
                                filterBy="company_business_name"
                            />
                        </div>
                    )}

                    {reportValue == "transactions" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Transaction Type</b>
                            </label>
                            <Dropdown
                                id="transactionType"
                                name="transactionType"
                                placeholder="Select"
                                value={transactionType}
                                options={transactiontypeOptions}
                                onChange={(e) => setTransactionType(e.target.value)}
                                optionLabel="name"
                                optionValue="value"
                                className="mt-2"
                            />
                        </div>
                    )}

                    {reportValue == "transactions" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Fiscal Year</b>
                            </label>
                            <Dropdown
                                id="fiscalYear"
                                name="fiscalYear"
                                placeholder="Select"
                                value={fiscalYear}
                                options={fiscalYears}
                                onChange={(e) => setFiscalYear(e.target.value)}
                                optionLabel="name"
                                optionValue="value"
                                className="mt-2"
                                disabled={fiscalYears?.length == 0}
                            />
                        </div>
                    )}

                    {reportValue !== "broker_commission_report" &&
                        reportValue !== "client_analysis" &&
                        reportValue !== "collection_efficiency_analysis" &&
                        reportValue !== "cash_posting_report" &&
                        reportValue !== "transactions" ? (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Debtor Name</b>
                            </label>
                            <MultiSelect
                                id="debtorId"
                                name="debtorId"
                                placeholder="Select"
                                value={debtorId}
                                options={dropdownDebtors}
                                onChange={(e) => setDebtorId(e.target.value)}
                                optionLabel="legal_name"
                                optionValue="id"
                                className="mt-2"
                                disabled={debtorLoading}

                                display="chip" // or "comma" to change the display format
                                showSelectAll={true} // Enable the select all checkbox
                                selectAll={debtorId.length !== dropdownDebtors.length} // Show an empty checkbox if not all items are selected
                                selectAllLabel="Select All" // Customize the label for the Select All option
                                onShow={onShow}
                            />
                        </div>
                    ) : (
                        ""
                    )}

                    {reportValue !== "last_payment_date" && reportValue !== "client_analysis" && reportValue !== "collection_efficiency_analysis" && reportValue !== "transactions" && (
                        <div className={selectedDateFilter === "4" ? "display_none" : "md:col-4 col-12"} style={{ position: "relative" }}>
                            <label>
                                <b>Date Filter</b>
                                {isRangeShow ? (
                                    <span
                                        onClick={() => {
                                            setIsRangeShow(false);
                                            setSelectedDateFilter("24hours");
                                            setDates2(null);
                                        }}
                                        className={reportValue === "broker_commission_report" ? "cross_icon_broker" : "cross_icon_date"}
                                    >
                                        x
                                    </span>
                                ) : (
                                    ""
                                )}
                            </label>
                            {isRangeShow ? (
                                <div className="custom_date_pos">
                                    <Calendar
                                        className="mt-2 "
                                        id="range"
                                        value={dates2}
                                        onChange={(e) => {
                                            setDates2(e.value);
                                            e.preventDefault();
                                        }}
                                        selectionMode="range"
                                        readOnlyInput
                                        showIcon
                                        placeholder="mm/dd/yyyy"
                                        mask="99/99/9999"
                                        monthNavigator
                                        yearNavigator
                                        yearRange="1945:2050"
                                    />
                                </div>
                            ) : (
                                <Dropdown
                                    id="datefilter"
                                    name="datefilter"
                                    options={reportValue === "debtor_aging" ? dateFilterForDebtorAging : datefilteroption}
                                    optionLabel="name"
                                    optionValue="value"
                                    value={selectedDateFilter}
                                    className="mt-2"
                                    placeholder="Select"
                                    onChange={handleDateFilterChange}
                                />
                            )}

                            {/* <Dropdown
                            id="datefilter"
                            name="datefilter"
                            options={datefilteroption}
                            optionLabel="name"
                            optionValue="value"
                            value={selectedDateFilter}
                            className="mt-2"
                            placeholder="Select"
                            onChange={handleDateFilterChange}
                        /> */}
                        </div>
                    )}

                    {reportValue == "client_analysis" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Duration</b>
                            </label>
                            <Dropdown
                                id="duration"
                                name="duration"
                                placeholder="Select"
                                value={duration}
                                options={dropdownDuration}
                                onChange={(e) => setDuration(e.target.value)}
                                optionLabel="title"
                                optionValue="value"
                                disabled={clientLoading}
                                className="mt-2"
                            />
                        </div>
                    )}

                    {reportValue === "credit_limit_warning" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Limit</b>
                            </label>
                            {/* <CustomInputField
                                id="limit"
                                name="limit"
                                placeholder="%"
                                value={limit}
                                onChange={(e) => setLimit(e.target.value)}
                                className="mt-2"
                                label="Limit"
                                disabled={!selectedDateFilter}
                                maxLength="3"
                                keyfilter={/^\d*\.?\d*$/}
                                onPaste={(e) => e.preventDefault()}
                            /> */}
                            <InputText
                                id="limit"
                                name="limit"
                                type="text"
                                placeholder="%"
                                keyfilter={/^\d*\.?\d*$/}
                                maxLength="5"
                                value={limit}
                                disabled={!selectedDateFilter}
                                onChange={(e) => {
                                    let cleanValue = PercentageCheck(e.target.value);
                                    setLimit(cleanValue);
                                }}
                                onPaste={(e) => e.preventDefault()}
                                className="mt-2"
                            />
                        </div>
                    )}

                    {reportValue === "last_payment_date" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>No of Days</b>
                            </label>
                            <InputText
                                id="noOfDays"
                                name="noOfDays"
                                placeholder=""
                                value={noOfDays}
                                onChange={(e) => setNoOfDays(e.target.value)}
                                className="mt-2"
                                // label=""
                                disabled={!debtorId}
                                maxLength="3"
                                keyfilter={/^\d*\.?\d*$/}
                                onPaste={(e) => e.preventDefault()}
                            />
                        </div>
                    )}

                    {reportValue === "collection_efficiency_analysis" && (
                        <div className="md:col-4 col-12">
                            <label>
                                <b>Date Filter</b>
                            </label>
                            <Dropdown
                                id="dateFilter"
                                name="duration"
                                placeholder="Select"
                                value={duration}
                                options={COLLECTION_EFFICIENY_ANALYSIS_DATE_FILTER}
                                onChange={(e) => setDuration(e.target.value)}
                                optionLabel="name"
                                optionValue="value"
                                className="mt-2"
                            />
                        </div>
                    )}

                    <div className="md:col-10 mt-3">
                        <h5>
                            <b>Summary View</b>
                        </h5>
                    </div>
                    {/* <div className="col-12 md:col-1 mt-1">
                        <CustomAddNewButton title="Schedule Report" type="button" onClick={() => setScheduleReport(true)} />
                    </div> */}
                    <div className="col-12 md:col-2 mt-1">
                        {exportMenuItems.length > 0 && (
                            <div className="dropdown">
                                {/* <button className={`dropdown-button ${isDropdownOpen ? "hide-chevron" : ""}`} onClick={toggleDropdown}>
                                    Export To
                                </button> */}

                                <CustomAddNewButton
                                    title="Export To"
                                    className={`dropdown-button ${isDropdownOpen ? "hide-chevron" : ""}`}
                                    onClick={toggleDropdown}
                                    isDisabled={gridData.length <= 0}
                                />

                                {isDropdownOpen && exportMenuItems.length > 0 && (
                                    <div className="dropdown-menu">
                                        <ul className="dropdown-list">
                                            {exportMenuItems.map((item) => (
                                                <li key={item.id} className="dropdown-item" onClick={() => handleOptionClick(item.label)}>
                                                    {item.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-12 pr-0">
                        {gridDataLoading ? (
                            <CustomLoading />
                        ) : (
                            <DataTable ref={dt} rows={6} value={gridData} responsiveLayout="scroll" globalFilter={globalFilter} emptyMessage="No record available.">
                                {columnComponents}
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
            {/* scheduleReport */}
            <Dialog
                blockScroll={true}
                header="Schedule Report"
                visible={scheduleReport}
                onHide={() => setScheduleReport(false)}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                {/* <AddVertical clientId={formik.values.client} onHide={closeModal} underWritings={underWritings} /> */}
            </Dialog>
        </>
    );
};

export default Body;
