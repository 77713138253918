import React, { useState, useEffect, useContext } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { changeDebtorStatus } from "../../../../../services/debtor";
import { ToastContext } from "../../../../../context/toast";
import CustomInputField from "../../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { getCustomDropDown } from "../../../../../services/dropdown";
import { setDebtorStatusChanged } from "../../../../../redux/main-view";
import { useDispatch } from "react-redux";

const ChangeDebtorStatus = (props) => {
    const { onHide, selectedDebtors } = props;
    // View States
    const [isLoading, setIsLoading] = useState(false);
    const [debtorStatuses, setDebtorStatuses] = useState([]);
    //toast message
    const toast = useContext(ToastContext);
    const dispatch = useDispatch();

    // FormMik
    let validationSchema = Yup.object().shape({
        status: Yup.string().required("Status is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: "",
        },
        onSubmit: async (data) => {
            onHide();
            setIsLoading(true);

            try {
                let payload = {
                    "debtor_prospect_id" : selectedDebtors.map((item) => item.debtor_prospect_id),
                    "status": data.status,
                }

                const res = await changeDebtorStatus(payload);
                if (res?.data) {
                    if (res.data.status) {
                        toast.updateToast("Debtor status");
                        dispatch(setDebtorStatusChanged(true));
                    } else {
                        toast.showMessage("Validation failed", res.data.message, "error");
                    }

                } else {
                    setIsLoading(false);
                    toast.showMessage("Error", "Admin has been notified", "error");
                }
            } catch (e) {
                toast.showMessage("Error", "Admin has been notified", "error");
                setIsLoading(false);
            }
            setIsLoading(false);
        },
    });

    const fetchDropdowns = async () => {
        const data = {
            select: ["debtor_status"],
        };

        setIsLoading(true);
        await getCustomDropDown(data).then((res) => {
            let _statuses = JSON.parse(res.data.dropdowns.debtor_status);

            let status = 'pending';
            selectedDebtors.map((item) => {
                if (status == 'pending' && item.status == 'Active') { //pending
                    status = 'active'
                } else if (status !== 'deleted' && item.status == 'Terminated') { // active
                    status = 'terminated';
                } else if (item.deleted) {
                    status = 'deleted';
                }
            });

            if (status == 'pending') {
                _statuses = _statuses.filter((item) => item.name === 'Active' || item.name === 'Terminated')
            } else if (status == 'active') {
                _statuses = _statuses.filter((item) => item.name === 'Terminated')
            } else if (status == 'terminated') {
                _statuses = _statuses.filter((item) => item.name === 'Active')
            }

            setDebtorStatuses(_statuses);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (!debtorStatuses || debtorStatuses.length === 0) {
            fetchDropdowns();
        }
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="grid">
                    <CustomInputField
                        className={`col-12 md:col-12 ${isLoading ? "no-drop" : " "}`}
                        disabled={isLoading}
                        type="dropdown"
                        iden="status"
                        label="Status"
                        mandatory="*"
                        formik={formik}
                        optionLabel="name"
                        optionValue="value"
                        options={debtorStatuses}
                        placeHolder="Select"
                    />
                    <div className="col-12 text-right pt-5">
                        <CustomAddNewButton id="save" title="Save" type="submit" isDisabled={isLoading} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default ChangeDebtorStatus;
