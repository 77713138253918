export function checkDaysUnder120(value) {
    if ((value.match(/\./g) || []).length > 1) {
        value = value.replace(/\./g, "");
    }
    if (value === "" || parseFloat(value) <= 120) {
        return value;
    } else {
        const update = value.slice(0, -1);
        value = update;

        return value;
    }
}

export function checkDaysUnder1(value) {
    if ((value.match(/\./g) || []).length > 1) {
        value = value.replace(/\./g, "");
    }
    if (value === "" || parseFloat(value) <= 1) {
        return value;
    } else {
        const update = value.slice(0, -1);
        value = update;

        return value;
    }
}