import React, { useState } from "react";
//styles
import "./progressbarcard.scss";
//components
import { Knob } from "primereact/knob";
//svgs
import ArrowIcon from "../../../svgs/DebtorDashboard/ArrowIcon";
import ArrowDown from "../../../svgs/DebtorDashboard/ArrowDown";

const ProgressBarCard = (props) => {
    const { data } = props;

    return (
        <>
            {data.map((li) => (
                <div className="md:col-3 col-12 card_width m-0">
                    <div className="debtor_dasboard">
                        <div className="card">
                            <h5 className="title m-0">
                                <b>{li.title}</b>
                            </h5>
                            <div className="text-center">
                                <div className="mt-2">
                                    <Knob value={li.value} size={120} valueColor={li.progressColor} valueTemplate={li.value + "%"} />
                                </div>
                                <div className="flex flex-row justify-content-center">
                                    <div className="ml-2 footer_number text-center">
                                        <b>{li.total}</b> <br />
                                        <span className="text-sm">{li.text}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ProgressBarCard;
