import React, { useContext, useEffect, useState } from "react";

// Prime React
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

// Icons
import { BsX } from "react-icons/bs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onFormDirty, onProspectBusinessProprietorFormDirty, onProspectCountryCodes, onProspectDataChange, onProspectFormDirty, onProspectKnobValue } from "../../../redux/main-view";

// Moment
import moment from "moment";

// Context
import { ToastContext } from "../../../context/toast";

// UI Components
import CustomCancelbtn from "../../CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../CustomAddNewButton/CustomAddNewButton";
import ZipCodesMenuComponent from "../../ZipCodesMenu/ZipCodesMenuComponent";

// Constants
import { HOME_OWNERSHIP_OPTION } from "../../../utils/Constants/global";

// Helper Function
import { PercentageCheck } from "../../../utils/HelperFuctions/checkPercentage";
import { convertingDateToUTC } from "../../../utils/HelperFuctions/convertingDateToUTC";
import { countryCodeSearchHandler } from "../../../utils/HelperFuctions/phoneCodeSearch";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";

// API'S
import { getZipCode } from "../../../services/zip-code";
import { ownerDelete, saveProspect } from "../../../services/prospect";
import { getCountryCode } from "../../../services/country-code";
import { validateEmail } from "../../../utils/HelperFuctions/checkEmail";
import CustomLoading from "../../CustomSpinner/custom_spinner";
import { loginState } from "../../../redux/login";

const initialValidationState = {
    name: false,
    percent_owned: false,
    percent_check: false,
    email: false,
    email_check: false,
    house_owned: false,
    ssn: false,
    phone: false,
    dob: false,
    zip: false,
};

const BusinessProprietors = (props) => {
    const { user } = useSelector(loginState);

    const { sendNextStep, frontline_color } = props;

    // Redux
    const { screens } = useSelector(mainViewState);

    //Dispatch
    const dispatch = useDispatch();

    // Context
    const toast = useContext(ToastContext);

    // States
    const [countryCodeOptions, setCountryCodeOptions] = useState([]);
    const [formArray, setFormArray] = useState([
        {
            name: "",
            percent_owned: "",
            email: "",
            house_owned: "",
            ssn: "",
            dob: "",
            phone: "",
            country_phone_code: "+1",
            zip: "",
            city: "",
            state: "",
            driver_license: "",
            address: "",
            driving_license_expiry_date: "",
            zipCode: [],
            recordNotFoundMsg: "",
            license_mask: "999-99-9999",
            validations: {
                ...initialValidationState,
            },
        },
    ]);
    const [hundredChecker, setHundredChecker] = useState([0, 0, 0, 0, 0]);
    const [isCalenderVisibleDOB, setIsCalenderVisibleDOB] = useState([false, false, false, false, false]);
    const [isCalenderVisible, setIsCalenderVisible] = useState([false, false, false, false, false]);

    // View State
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isZipCodeSearch, setIsZipSearch] = useState(true);
    const [isButtonClick, setIsButtonClick] = useState(true);

    // Handlers
    const handleCalendarVisibleChange = (e, index, name) => {
        if (name === "dob") {
            let visiblity = [...isCalenderVisibleDOB];
            visiblity[index] = e.visible;
            setIsCalenderVisibleDOB(visiblity);
        } else {
            let visiblity = [...isCalenderVisible];
            visiblity[index] = e.visible;
            setIsCalenderVisible(visiblity);
        }
    };

    const inputFieldDataCleaningHandler = (e, item, name, value, index) => {
        const validations = item.validations;
        if (name === "house_owned" || name === "country_phone_code") {
            return {
                ...item,
                [name]: value,
                validations: {
                    ...validations,
                    [name]: false,
                },
            };
        } else if (name === "email") {
            return {
                ...item,
                [name]: value,
                validations: {
                    ...validations,
                    [name]: false,
                    email_check: !validateEmail(value),
                },
            };
        } else if (name === "name") {
            return {
                ...item,
                [name]: toTitleCaseWhileTyping(value),
                validations: {
                    ...validations,
                    [name]: false,
                },
            };
        } else if (name === "dob" || name === "driving_license_expiry_date") {
            if (e.originalEvent.key === "Enter" || e.originalEvent.type === "blur") {
                // Close the calendar modal
                if (name === "dob") {
                    // setIsCalenderVisibleDOB([true, true, true, true, true]);
                } else if (name === "driving_license_expiry_date") {
                    // setIsCalenderVisible([true, true, true, true, true]);
                }

                // if (e.originalEvent.type === "blur") {
                const nextInput = document.getElementById(name === "dob" ? `zip-${index}` : "addMore");
                if (nextInput) {
                    // nextInput.focus();
                    setIsButtonClick(false);
                }
                // }
            }
            return {
                ...item,
                [name]: value ? convertingDateToUTC(value) : "",
                // [name]: value ? new Date(value) : "",
                validations:
                    name === "driving_license_expiry_date"
                        ? {
                              ...validations,
                          }
                        : {
                              ...validations,
                              [name]: false,
                          },
            };
        } else {
            return {
                ...item,
                [name]: textExtraSpaceRemoval(value),
                validations: {
                    ...validations,
                    [name]: false,
                },
            };
        }
    };
    const handleInputChange = (e, index) => {
        dispatch(onFormDirty(true));
        dispatch(onProspectFormDirty(false));
        dispatch(onProspectBusinessProprietorFormDirty(false));
        let { name, value } = e.target;
        let cleanValue = name === "house_owned" || name === "dob" || name === "country_phone_code" || name === "driving_license_expiry_date" ? value : textExtraSpaceRemoval(value);
        let hundredChecker_temp = hundredChecker;
        const updatedFormArray = formArray.map((item, i) => {
            const validations = item.validations;
            let temp = 0;
            if (i === index) {
                if (name === "percent_owned") {
                    cleanValue = PercentageCheck(cleanValue);
                    hundredChecker_temp[index] = cleanValue;
                    setHundredChecker(hundredChecker_temp);
                    hundredChecker_temp.map((item) => {
                        temp = temp + Number(item);
                    });
                    return {
                        ...item,
                        [name]: cleanValue,
                        validations: {
                            ...validations,
                            [name]: false,
                            percent_check: temp > 100 ? true : false,
                        },
                    };
                } else {
                    return inputFieldDataCleaningHandler(e, item, name, value, index);
                }
            }
            return {
                ...item,
                validations: {
                    ...validations,
                    percent_check: temp > 100 ? true : false,
                },
            };
        });
        if (name === "zip") {
            if (cleanValue.length >= 4 && isZipCodeSearch) {
                fetchZipCodeData(cleanValue, index);
            } else {
                updatedFormArray[index]["zipCode"] = [];
                updatedFormArray[index]["state"] = "";
                updatedFormArray[index]["city"] = "";
                updatedFormArray[index]["license_mask"] = "999-99-9999";
                setIsZipSearch(true);
            }
        }
        setFormArray(updatedFormArray);
    };
    const reamianingPercentage = () => {
        let temp = 100;
        for (let i = 0; i < formArray.length; i++) {
            temp = temp - Number(hundredChecker[i]);
        }
        const formattedValue = Number(temp.toFixed(2));
        if (formattedValue > 0) {
            return `Avaiable Percentage is ${formattedValue}`;
        } else if (formattedValue < 0) {
            const newValue = formattedValue.toString().slice(1);
            return `Exceeding Percentage is ${newValue}`;
        }
    };

    const handlePrevious = () => {
        sendNextStep(1);
    };

    const selectedZipCode = (zip, indexing) => {
        setIsZipSearch(false);
        formArray[indexing]["recordNotFoundMsg"] = "";
        formArray[indexing]["zip"] = zip.zip;
        formArray[indexing]["validations"]["zip"] = false;
        formArray[indexing]["state"] = zip.full_state;
        formArray[indexing]["city"] = zip.city;
        formArray[indexing]["license_mask"] = zip.license_mask;
        formArray[indexing]["zipCode"] = [];
        setFormArray(formArray);
    };

    const handleAddMore = () => {
        if (formArray.length === 5) {
            toast.showMessage("Limit", "Maximum 5 alloweds.", "error");
        } else {
            dispatch(onFormDirty(true));
            dispatch(onProspectFormDirty(false));
            dispatch(onProspectBusinessProprietorFormDirty(false));
            setFormArray([
                ...formArray,
                {
                    name: "",
                    percent_owned: "",
                    email: "",
                    house_owned: "",
                    ssn: "",
                    dob: "",
                    phone: "",
                    country_phone_code: "+1",
                    zip: "",
                    city: "",
                    state: "",
                    driver_license: "",
                    address: "",
                    driving_license_expiry_date: "",
                    zipCode: [],
                    recordNotFoundMsg: "",
                    license_mask: "999-99-9999",
                    validations: {
                        ...initialValidationState,
                    },
                },
            ]);
        }
    };

    const handleRemoveForm = async (index) => {
        // Deleting Data from Backend
        const res = formArray.filter((_, i) => i === index);

        if (res[0]?.id) {
            const id = [res[0]?.id];

            try {
                setIsLoading(true);
                let payload = {
                    id: id,
                };

                const res = await ownerDelete(payload);

                if (res.data.status) {
                    let prospect_data = JSON.parse(JSON.stringify(screens.prospect.data));
                    let _owners = prospect_data.owners;
                    _owners = _owners.filter((item, i) => item.id !== id[0]);
                    prospect_data["owners"] = _owners;
                    dispatch(onProspectDataChange(prospect_data));
                    setIsLoading(false);
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        }
        if (index !== 0) {
            const updatedFormArray = formArray.filter((_, i) => i !== index);
            setFormArray(updatedFormArray);
        }
    };

    const saveHandler = async () => {
        try {
            let hasError = false;
            if (screens.prospect.isFormDirty || screens.prospect.isBusinessProprietorFormDirty) {
                sendNextStep(3);
                dispatch(onProspectFormDirty(false));
                dispatch(onProspectBusinessProprietorFormDirty(false));
            } else {
                const updatedFormArray = formArray.map((item) => {
                    const { validations, country_phone_code, ...rest } = item;

                    if (
                        item.name === "" ||
                        item.percent_owned === "" ||
                        item.email === "" ||
                        item.house_owned === "" ||
                        item.ssn === "" ||
                        item.phone === "" ||
                        item.dob === "" ||
                        item.zip === "" ||
                        validations.percent_check ||
                        validations.email_check ||
                        item.state === ""
                    ) {
                        hasError = true;
                        return {
                            ...rest,
                            country_phone_code,
                            validations: {
                                ...validations,
                                name: item.name === "",
                                percent_owned: item.percent_owned === "",
                                email: item.email === "",
                                house_owned: item.house_owned === "",
                                ssn: item.ssn === "",
                                phone: item.phone === "",
                                dob: item.dob === "",
                                zip: item.zip === "" || item.state === "",
                            },
                        };
                    } else {
                        return {
                            ...rest,
                            validations,
                            country_phone_code: country_phone_code.phone_code ? country_phone_code.phone_code : country_phone_code,
                        };
                    }
                });

                if (hasError) {
                    setFormArray(updatedFormArray);
                    return;
                }
                setIsSaving(true);

                // Cleaning States
                const data = updatedFormArray.map((item) => {
                    const { validations, zipCode, recordNotFoundMsg, license_mask, ...rest } = item;
                    return {
                        ...rest,
                    };
                });
                let payload = {
                    owners: data,
                    step: "2",
                    id: screens.prospect.data.id,
                };

                let res = await saveProspect(payload);
                if (res) {
                    if (res.status) {
                        dispatch(onProspectDataChange(res.data.prospect));
                        const isEditing = JSON.parse(localStorage.getItem("isEditing"));

                        if (isEditing) {
                            dispatch(onProspectKnobValue(100));
                        } else {
                            dispatch(onProspectKnobValue(34));
                        }
                        dispatch(onFormDirty(false));
                        sendNextStep(3);
                    } else {
                        toast.showMessage("Creation Failed", "Sorry, we are unable to process your request at this time.", "error");
                    }
                } else {
                    toast.showMessage("Error", "Server Error", "error");
                    setIsSaving(false);
                }
                setIsSaving(false);
            }
        } catch (error) {
            toast.showMessage("Error", "Server Error", "error");
            setIsSaving(false);
        }
    };

    // API Handler's
    const fetchZipCodeData = (value, index) => {
        const paylod = {
            zip: value,
        };
        getZipCode(paylod).then((res) => {
            formArray[index]["zip"] = value;
            formArray[index]["state"] = "";
            formArray[index]["city"] = "";
            formArray[index]["zipCode"] = res.data.codes;
            setFormArray(formArray);
            if (res.data.codes.length === 0) {
                formArray[index]["zip"] = value;
                formArray[index]["zipCode"] = [];
                formArray[index]["recordNotFoundMsg"] = "No Record Available";
                setFormArray(formArray);
            }
        });
    };
    const fetchingCountryCodes = () => {
        setLoading(true);
        try {
            getCountryCode().then((res) => {
                setCountryCodeOptions(res.data.countries);
                dispatch(onProspectCountryCodes(res.data.countries));
            });
            setLoading(false);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    
    //UseEffect
    useEffect(() => {
        if (screens.prospect.countryCodes) {
            setCountryCodeOptions(screens.prospect.countryCodes);
        } else {
            fetchingCountryCodes();
        }
        if (screens.prospect.data?.owners) {
            if (screens.prospect.data.owners.length !== 0) {
                dispatch(onProspectFormDirty(true));
                let temp = [];
                screens.prospect.data.owners.map((item, index) => {
                    const data = {
                        id: item.id,
                        name: item.name,
                        percent_owned: item.percent_owned,
                        email: item.email,
                        house_owned: item.house_owned ? 1 : 0,
                        ssn: item.ssn,
                        dob: moment(item.dob).format("YYYY-MM-DD"),
                        phone: item.phone,
                        country_phone_code: item.country_phone_code,
                        zip: item.zip,
                        city: item.city,
                        state: item.state,
                        driver_license: item.driver_license,
                        license_mask: item.driver_license,
                        address: item.address,
                        driving_license_expiry_date: item.driving_license_expiry_date ? moment(item.driving_license_expiry_date).format("YYYY-MM-DD") : "",
                        zipCode: [],
                        recordNotFoundMsg: "",
                        validations: {
                            ...initialValidationState,
                        },
                    };
                    hundredChecker[index] = item.percent_owned;
                    temp.push(data);
                });
                setFormArray(temp);

                dispatch(onProspectBusinessProprietorFormDirty(true));
            }
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsButtonClick(true);
        }, 1);
    }, [isButtonClick]);

    return (
        <>
            {isLoading ? (
                <CustomLoading />
            ) : (
                <div className={frontline_color ? "frontLine_scroll" : "pros_scrollbar"}>
                    {formArray.map((item, index) => (
                        <form className="step-form-top-padding" key={index} autoComplete="off">
                            <Fieldset legend="Business Owners" className="positon-relative">
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="NameTitle">
                                            <b>
                                                Full Name <span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter Full Name "
                                                maxLength="40"
                                                value={item.name}
                                                onChange={(e) => handleInputChange(e, index)}
                                                autoComplete="none"
                                            />
                                            {item.validations.name && <small className="p-error">Full Name is required</small>}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="ownershippercentage">
                                            <b>
                                                Ownership Percentage<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                id="percent_owned"
                                                name="percent_owned"
                                                type="text"
                                                placeholder="Enter Ownership Percentage"
                                                keyfilter={/^\d*\.?\d*$/}
                                                maxLength="5"
                                                value={item.percent_owned}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onPaste={(e) => e.preventDefault()}
                                            />

                                            {item.validations.percent_owned && <small className="p-error">Ownership Percentage is required</small>}

                                            {item.validations.percent_check && <small className="p-error"> {reamianingPercentage()}</small>}
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="email">
                                            <b>
                                                Email<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="mt-2">
                                            <InputText name="email" id="email" type="email" placeholder="Enter Email" maxLength="50" value={item.email} onChange={(e) => handleInputChange(e, index)} />
                                            {item.validations.email
                                                ? item.validations.email && <small className="p-error">Email is required</small>
                                                : item.validations.email_check && <small className="p-error">Invalid Email</small>}
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="homeOwnership">
                                            <b>
                                                Home Ownership<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="mt-2">
                                            <Dropdown
                                                id="house_owned"
                                                name="house_owned"
                                                placeholder="Select"
                                                value={item.house_owned}
                                                options={HOME_OWNERSHIP_OPTION}
                                                onChange={(e) => handleInputChange(e, index)}
                                                optionLabel="name"
                                            />
                                        </div>
                                        {item.validations.house_owned && <small className="p-error">Home Ownership is required</small>}
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="ssn">
                                            <b>
                                                SSN<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="mt-2">

                                            {
                                                user?.sensitive_information_disclosure ? 
                                                (
                                                    <InputMask
                                                        name="ssn"
                                                        id="ssn"
                                                        type="text"
                                                        mask="999-99-9999"
                                                        placeholder="999-99-9999"
                                                        value={item.ssn}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        autoComplete="none"
                                                    />  
                                                ) : !user?.sensitive_information_disclosure && !formArray[index].id ? (
                                                    <InputMask
                                                        name="ssn"
                                                        id="ssn"
                                                        type="text"
                                                        mask="999-99-9999"
                                                        placeholder="999-99-9999"
                                                        value={item.ssn}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        autoComplete="none"
                                                    />  
                                                )  :  !user?.sensitive_information_disclosure && formArray[index].id  ? (
                                                    <InputText
                                                        name="ssn"
                                                        id="ssn"
                                                        type="text"
                                                        placeholder="999-99-9999"
                                                        value={item.ssn}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        autoComplete="none"
                                                        disabled={true}
                                                    />
                                                ) : ""

                                            }
                                            {item.validations.ssn && <small className="p-error">SSN is required</small>}
                                        </div>
                                    </div>
                                    <div className="md:col-3 col-12">
                                        <label htmlFor="phone" className="step-fields-title">
                                            <b>
                                                Phone No<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="border_div" onChangeCapture={countryCodeSearchHandler}>
                                            <Dropdown
                                                id="country_phone_code"
                                                name="country_phone_code"
                                                placeholder={item.country_phone_code.phone_code ?? item.country_phone_code}
                                                optionLabel="phone_code"
                                                value={item.country_phone_code}
                                                options={countryCodeOptions}
                                                onChange={(e) => handleInputChange(e, index)}
                                                className="proscustom_width_country"
                                                filter
                                                filterBy="phone_code"
                                                emptyMessage="No results found"
                                            />
                                            <InputMask
                                                iden="phone"
                                                name="phone"
                                                mask="999-999-9999"
                                                placeholder="999-999-9999"
                                                value={item.phone}
                                                onChange={(e) => handleInputChange(e, index)}
                                                className="proscustom_width_phone"
                                                autoComplete="none"
                                            />
                                        </div>
                                        {item.validations.phone && <small className="p-error">Phone No is required</small>}
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="phone" className="step-fields-title">
                                            <b>Date of Birth</b>
                                            <span className="clr_red">*</span>
                                        </label>

                                        <div className="mt-2">
                                            <Calendar
                                                name="dob"
                                                id="dob"
                                                value={new Date(item.dob)}
                                                placeholder="mm/dd/yyyy"
                                                mask="99/99/9999"
                                                showIcon
                                                maxDate={new Date()}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onVisibleChange={(e) => handleCalendarVisibleChange(e, index, "dob")}
                                                visible={isCalenderVisibleDOB[index]}
                                                monthNavigator
                                                yearNavigator
                                                yearRange="1945:2050"
                                            />
                                        </div>
                                        {item.validations.dob && <small className="p-error">Date of Birth is required</small>}
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="zipcode">
                                            <b>
                                                Zip Code<span className="clr_red">*</span>
                                            </b>
                                        </label>
                                        <div className="mt-2">
                                            <InputText
                                                name="zip"
                                                id={`zip-${index}`}
                                                type="text"
                                                placeholder="Search..."
                                                keyfilter="int"
                                                maxLength="5"
                                                value={item.zip}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onPaste={(e) => e.preventDefault()}
                                            />
                                            {item.validations.zip && <small className="p-error">Zip Code is required</small>}
                                            {item.zip && (
                                                <div>
                                                    <ZipCodesMenuComponent zipCodes={item.zipCode} handleSelectedZipCodes={selectedZipCode} indexing={index} />
                                                    <small className="p-error">{item.recordNotFoundMsg}</small>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="state">
                                            <b> State</b>
                                            <span className="clr_red">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <InputText name="state" id="state" type="text" disabled value={item.state} />
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="city">
                                            <b> City</b>
                                            <span className="clr_red">*</span>
                                        </label>
                                        <div className="mt-2">
                                            <InputText name="city" id="city" type="text" disabled value={item.city} />
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="driverlicense">
                                            <b> Driver License No </b>
                                        </label>
                                        <div className="mt-2">
                                            {/* <InputMask
                                            id="driver_license"
                                            name="driver_license"
                                            type="text"
                                            mask={item?.license_mask}
                                            placeholder={item?.license_mask}
                                            value={item.driver_license}
                                            onChange={(e) => (e.target.value === undefined ? null : handleInputChange(e, index))}
                                        /> */}
                                            <InputText
                                                name="driver_license"
                                                id="driver_license"
                                                type="text"
                                                placeholder="Enter Driver License No"
                                                maxLength="25"
                                                value={item.driver_license}
                                                onChange={(e) => handleInputChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="phone" className="step-fields-title">
                                            <b>Driver License Expiry Date</b>
                                        </label>
                                        <div className="mt-2">
                                            <Calendar
                                                name="driving_license_expiry_date"
                                                id="driving_license_expiry_date"
                                                value={new Date(item.driving_license_expiry_date)}
                                                placeholder="mm/dd/yyyy"
                                                mask="99/99/9999"
                                                showIcon
                                                minDate={new Date()}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onVisibleChange={(e) => handleCalendarVisibleChange(e, index, "driving_license_expiry_date")}
                                                // onVisibleChange={(e) => setIsCalenderVisible(e.visible)}
                                                visible={isCalenderVisible[index]}
                                                monthNavigator
                                                yearNavigator
                                                yearRange="1945:2050"
                                            />
                                        </div>
                                    </div>
                                    {index !== 0 && <Button type="button" onClick={() => handleRemoveForm(index)} className="cros_btn" icon={<BsX />}></Button>}
                                </div>
                            </Fieldset>
                        </form>
                    ))}
                    <div className="pt-3">
                        {formArray.length === 5 ? null : frontline_color ? (
                            <Button id="addMore" label="Add More" className="fronLineSavebtn" type="button" onClick={() => (isButtonClick ? handleAddMore() : null)} />
                        ) : (
                            <CustomAddNewButton id="addMore" title="Add More" type="button" onClick={() => (isButtonClick ? handleAddMore() : null)} />
                        )}
                    </div>

                    <div className="action-btns-prospect-form pt-6 pr-1">
                        <CustomCancelbtn title="Previous" type="button" onClick={handlePrevious} />
                        {frontline_color ? (
                            <Button label="Save and Next" className="fronLineSavebtn" type="submit" disabled={isSaving} onClick={() => (isSaving ? null : saveHandler())} />
                        ) : (
                            <CustomAddNewButton title="Save and Next" type="submit" onClick={() => (isSaving ? null : saveHandler())} isDisabled={isSaving} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default BusinessProprietors;
