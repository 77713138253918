import { createSlice } from "@reduxjs/toolkit";
import { AVAILABLE_PROSPECT } from "../utils/Constants/global";

// import { ReduxinitialState } from "../utils/constants/redux-intial";

const initialState = {
    screens: {
        support: {
            isMutated: false,
            isResponseMutated: false,
        },
        broker: {
            brokerLoader: false,
        },
        prospect: {
            index: 0,
            data: null,
            isMutated: false,
            isEditing: false,
            percentValue: 0,
            isFormDirty: false,
            isBusinessProprietorFormDirty: false,
            isSupplierFormDirty: false,
            attachments: null,
            countryCodes: null,

            selectedColums: AVAILABLE_PROSPECT,
            header: AVAILABLE_PROSPECT,
            filledForm: true,
            activeCard: 0,
            status: 0,
            componentName: "available_prospect",
        },
        client: {
            index: 0,
            data: null,
            isBusinessInformationFormDirty: false,
            isBusinessProprietorFormDirty: false,
            isFinancialFormDirty: false,
            isLandlordFormDirty: false,
            isSupplierFormDirty: false,
        },
        chat: {
            isNewMessage: false,
        },
        notification: {
            isNewNotification: false,
        },
        debtor: {
            statusChanged: false,
            loader: false,
            isFormDirty: false,
        },
        main: {
            isFormDirty: false,
            sideBarName: "",
            zipCode: [],
        },
        cr: {
            isFormDirty: false,
        },
        invoice: {
            isFormDirty: false,
        },
        cb: {
            isFormDirty: false,
        },
        reserve: {
            isFormDirty: false,
            batch: [], //list of invoice ids whose reserve release batch is to be created
        },
        user_management: {
            user: {
                isFormDirty: false,
            },
            role: {
                isFormDirty: false,
            },
        },
        underwriting: {
            isFormDirty: false,
        },
    },
};
const mainViewSlice = createSlice({
    name: "mainView",
    initialState,
    reducers: {
        // Main
        onFormDirty: (state, action) => {
            state.screens.main.isFormDirty = action.payload;
        },
        onSideBarMain: (state, action) => {
            state.screens.main.sideBarName = action.payload;
        },
        onZipCodeChange: (state, action) => {
            state.screens.main.zipCode = action.payload;
        },
        // Support
        onSupportDataMutated: (state, action) => {
            state.screens.support.isMutated = !state.screens.support.isMutated;
        },
        onSupportResponseMutated: (state, action) => {
            state.screens.support.isResponseMutated = !state.screens.support.isResponseMutated;
        },

        // Broker
        setBrokerLoader: (state, action) => {
            state.screens.broker.brokerLoader = action.payload;
        },
        // Prospect
        onProspectIndexChange: (state, action) => {
            state.screens.prospect.index = action.payload;
        },
        onProspectKnobValue: (state, action) => {
            state.screens.prospect.percentValue = action.payload;
        },
        onProspectDataChange: (state, action) => {
            state.screens.prospect.data = action.payload;
        },
        onProspectFormDirty: (state, action) => {
            state.screens.prospect.isFormDirty = action.payload;
        },
        onProspectBusinessProprietorFormDirty: (state, action) => {
            state.screens.prospect.isBusinessProprietorFormDirty = action.payload;
        },
        onProspectSupplierFormDirty: (state, action) => {
            state.screens.prospect.isSupplierFormDirty = action.payload;
        },
        onProspectAttachment: (state, action) => {
            state.screens.prospect.attachments = action.payload;
        },
        onProspectCountryCodes: (state, action) => {
            state.screens.prospect.countryCodes = action.payload;
        },
        onProspectIsEditing: (state, action) => {
            state.screens.prospect.isEditing = action.payload;
        },
        onProspectDataMutated: (state, action) => {
            state.screens.prospect.isMutated = !state.screens.prospect.isMutated;
        },
        onProspectMenuChange: (state, action) => {
            state.screens.prospect.selectedColums = action.payload.selectedColums;
            state.screens.prospect.filledForm = action.payload.filledForm;
            state.screens.prospect.header = action.payload.header;
            state.screens.prospect.status = action.payload.status;
            state.screens.prospect.activeCard = action.payload.activeCard;
            state.screens.prospect.componentName = action.payload.componentName;
        },

        // Client
        onClientIndexChange: (state, action) => {
            state.screens.prospect.index = action.payload;
        },
        onClientDataChange: (state, action) => {
            state.screens.client.data = action.payload;
        },
        onClientBusinessInformationFormDirty: (state, action) => {
            state.screens.client.isBusinessInformationFormDirty = action.payload;
        },
        onClientBusinessProprietorFormDirty: (state, action) => {
            state.screens.client.isBusinessProprietorFormDirty = action.payload;
        },
        onClientFinancialFormDirty: (state, action) => {
            state.screens.client.isFinancialFormDirty = action.payload;
        },
        onClientLandlordFormDirty: (state, action) => {
            state.screens.client.isLandlordFormDirty = action.payload;
        },
        onClientSupplierFormDirty: (state, action) => {
            state.screens.client.isSupplierFormDirty = action.payload;
        },

        //Chat
        onChatNewMessage: (state, action) => {
            state.screens.chat.isNewMessage = action.payload;
        },

        //Notification
        setFetchNewNotification: (state, action) => {
            state.screens.notification.isNewNotification = action.payload;
        },

        // Debtor
        setDebtorStatusChanged: (state, action) => {
            state.screens.debtor.statusChanged = action.payload;
        },
        onDebtorFormDirty: (state, action) => {
            state.screens.debtor.isFormDirty = action.payload;
        },
        setDebtorLoader: (state, action) => {
            state.screens.debtor.loader = action.payload;
        },

        //CR
        onCreditRequestFormDirty: (state, action) => {
            state.screens.cr.isFormDirty = action.payload;
        },

        //Invoice
        onInvoiceFormDirty: (state, action) => {
            state.screens.invoice.isFormDirty = action.payload;
        },

        //Chargeback
        onChargebackFormDirty: (state, action) => {
            state.screens.cb.isFormDirty = action.payload;
        },

        //reserves
        onReserveFormDirty: (state, action) => {
            state.screens.reserve.isFormDirty = action.payload;
        },

        // User Management
        onUserManagementUserFormDirty: (state, action) => {
            state.screens.user_management.user.isFormDirty = action.payload;
        },
        onUserManagementRoleFormDirty: (state, action) => {
            state.screens.user_management.role.isFormDirty = action.payload;
        },

        onUnderWritingFormDirty: (state, action) => {
            state.screens.underwriting.isFormDirty = action.payload;
        },
    },
});

export const {
    // Main
    onFormDirty,
    onSideBarMain,
    onZipCodeChange,

    // Support
    onSupportDataMutated,
    onSupportResponseMutated,
    setBrokerLoader,

    // Prospect
    onProspectIndexChange,
    onProspectKnobValue,
    onProspectDataChange,
    onProspectFormDirty,
    onProspectBusinessProprietorFormDirty,
    onProspectSupplierFormDirty,
    onProspectAttachment,
    onProspectCountryCodes,
    onProspectIsEditing,
    onProspectDataMutated,
    onProspectMenuChange,

    // Client
    onClientIndexChange,
    onClientDataChange,
    onClientBusinessInformationFormDirty,
    onClientBusinessProprietorFormDirty,
    onClientFinancialFormDirty,
    onClientLandlordFormDirty,
    onClientSupplierFormDirty,

    // Chat
    onChatNewMessage,

    //notification
    setFetchNewNotification,

    // Debtor Status Change
    setDebtorStatusChanged,
    setDebtorLoader,
    onDebtorFormDirty,
    onCreditRequestFormDirty,

    //Invoice
    onInvoiceFormDirty,

    //Chargeback
    onChargebackFormDirty,

    //reserve
    onReserveFormDirty,

    // User Management
    onUserManagementUserFormDirty,
    onUserManagementRoleFormDirty,

    //Underwriting
    onUnderWritingFormDirty,
} = mainViewSlice.actions;
export const mainViewState = (state) => state.mainView;

export default mainViewSlice.reducer;
