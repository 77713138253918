import React, { useContext, useEffect, useState } from "react";

import FilterBar from "./GridView/FilterBar";
import Body from "./GridView/Body";
import { DEBTOR_GRID_HEADERS, DEBTOR_GRID_HEADERS_FOR_CLIENT, ROLE_NAME } from "../../../../utils/Constants/global";
import { deleteDebtor, getDebtors } from "../../../../services/debtor";
import { ToastContext } from "../../../../context/toast";
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, setDebtorStatusChanged } from "../../../../redux/main-view";
import { Dialog } from "primereact/dialog";
import ChangeDebtorStatus from "../Components/DebtorComponent/ChangeDebtorStatus";
import { loginState } from "../../../../redux/login";
const Debtor = () => {
    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);
    const toast = useContext(ToastContext);
    const [keyword, setKeyword] = useState("");
    const [selectedDebtors, setSelectedDebtors] = useState([]);

    

    const [selectedColumns, setSelectedColumns] = useState(DEBTOR_GRID_HEADERS);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);

    // PAGINATION
    // States
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });
    const [loading, setLoading] = useState(false);
    const [kebabMenuItems, setKebabMenuItems] = useState([
        { title: "Import", status: 1 },
        { title: "Change Status", status: 2 },
    ]);
    const [showStatusDialog, setShowStatusDialog] = useState(false);

    // API response Data
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [kebabMenuItemLoader, setKebabMenuItemLoader] = useState(false);

    //Redux
    const { user } = useSelector(loginState);

    const fetchDebtors = async () => {
        let payload = {
            per_page: pageSize,
            page: currentPage,
            sort_by: sortField,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
            search_text: keyword,
            show_all: showAll ? true : false,
            inc_client_role: true,
        };

        setSelectedDebtors([]);
        setLoading(true);
        try {
            let res = await getDebtors(payload);

            if (res.status) {
                setSelectedHeaders(res.data.debtors.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.debtors.total,
                    totalPages: res.data.debtors.last_page,
                    isFirstPage: res.data.debtors.current_page === 1 ? 1 : 0,
                    isLastPage: res.data.debtors.current_page === res.data.debtors.last_page ? 1 : 0,
                });
                setLoading(false);
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const removeDebtor = () => {
        let payload = {
            debtors_prospects: selectedDebtors.map((item) => {
                return { prospect_id: item.prospect_id, debtor_id: item.id };
            }),
        };

        deleteDebtor(payload).then((res) => {
            if (res && res.status) {
                if (res.data && res.data.status) {
                    toast.deleteToast("Debtor");
                }
            } else {
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        });
    };

    const kebabMenuItemClick = (status) => {
        if (status === 2 && selectedDebtors.length !== 0) {
            //show change debtor status modal
            setShowStatusDialog(true);

            // let debtorStatus = '';
            // kebabMenuItems.forEach((item) => {
            //     if (item.status === 3) {
            //         debtorStatus =  item.title;
            //     }
            // });

            // if (debtorStatus) {
            //     let found = debtorStatuses.find(item => item.name.indexOf(debtorStatus) > -1);
            //     if (found) {
            //         changeStatus(found.value);
            //     }
            // }
        } else if (status == 3) {
            //remove debtor
            removeDebtor();
        }
    };

    const handleCloseStatusDialog = () => {
        setShowStatusDialog(false);
        setSelectedDebtors([]);
        fetchDebtors();
    };

    useEffect(() => {
        fetchDebtors();
    }, [currentPage, pageSize, showAll, sortField, sortOrder]);

    // useEffect(() => {
    //     if (keyword) {
    //         const delayDebounceFn = setTimeout(() => {
    //             fetchDebtors();
    //         }, 250);

    //         return () => clearTimeout(delayDebounceFn);
    //     } else {
    //         fetchDebtors();
    //     }
    // }, [keyword]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    fetchDebtors();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    useEffect(() => {
        if (screens.debtor.statusChanged) {
            fetchDebtors();
            dispatch(setDebtorStatusChanged(false));
        }
    }, [screens.debtor.statusChanged]);

    useEffect(()=>{

        if(user?.userRoles[0]['roleName']===ROLE_NAME.CLIENT){
            setSelectedColumns(DEBTOR_GRID_HEADERS_FOR_CLIENT);
        }

        let updatedHeaderColumns = user.sensitive_information_disclosure
        ? selectedColumns
        : selectedColumns.filter(header => header.field !== "fein");

        setSelectedColumns(updatedHeaderColumns);

    },[]);

    return (
        <div className="grid">
            <FilterBar
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                selectedDebtors={selectedDebtors}
                kebabMenuItems={kebabMenuItems}
                kebabMenuItemClick={kebabMenuItemClick}
                kebabMenuItemLoader={kebabMenuItemLoader}
            />
            <Body
                responseData={selectedHeaders}
                selectedDebtors={selectedDebtors}
                setSelectedDebtors={setSelectedDebtors}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                setPage={setPage}
                page={page}
                showAll={showAll}
            />

            <Dialog blockScroll={true} visible={showStatusDialog} onHide={handleCloseStatusDialog} header="Change Status" breakpoints={{ "960px": "60vw", "640px": "100vw" }} style={{ width: "30vw" }}>
                <ChangeDebtorStatus selectedDebtors={selectedDebtors} onHide={handleCloseStatusDialog} />
            </Dialog>
        </div>
    );
};

export default Debtor;
