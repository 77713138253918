import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Prime React
import { Checkbox } from "primereact/checkbox";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { onProspectIndexChange } from "../../../../../redux/main-view";
// Ui Components
import KebabMenuComponent from "../../../../../ui/KebabMenu/KebabMenuComponent";
import SearchInputComponent from "../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../ui/ColumnFilter/ColumnFilterComponent";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import { validateUserPermissions } from "../../../../../utils/HelperFuctions/validatePermissions";
import { loginState } from "../../../../../redux/login";
import { MANAGMENT_RIGHTS } from "../../../../../utils/Constants/rights";

const FilterBar = (props) => {
    const { showAll, setShowAll, selectedColumns, setSelectedColumns, header, keyword, setKeyword, selectedUsers, setKebabMenuOption, componentName, setDullyKebabMenuOption } = props;

    // States
    const [prospect_menu_items, setProspectMenuItems] = useState([]);
    const [prospect_dully_menu_items, setProspectDullyMenuItems] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    // State
    const [onSelectionColumn, setOnSelectionColumn] = useState([]);

    // Hooks
    const history = useHistory();

    //Dispatch
    const dispatch = useDispatch();

    // Constants
    const PROSPECT_MENU_ITEMS = [
        { status: 1, title: "Promote to Client", isDisabled: selectedUsers.length === 0, required_rights: [MANAGMENT_RIGHTS.prospects.promote_to_client] },
        { status: 2, title: "Demote to Fallout", isDisabled: selectedUsers.length === 0, required_rights: [MANAGMENT_RIGHTS.prospects.demote_to_fallout] },
        { status: 3, title: "Delete", isDisabled: selectedUsers.length === 0, required_rights: [MANAGMENT_RIGHTS.prospects.delete] },
    ];
    const PROSPECT_DULLY_MENU_ITEMS = [
        { status: 1, title: "Change Status", isDisabled: selectedUsers.length === 0, required_rights: [MANAGMENT_RIGHTS.prospects.status_change] },
        { status: 2, title: "Demote to Fallout", isDisabled: selectedUsers.length === 0, required_rights: [MANAGMENT_RIGHTS.prospects.demote_to_fallout] },
        { status: 3, title: "Email to Prospect", isDisabled: selectedUsers.length === 0, required_rights: [MANAGMENT_RIGHTS.prospects.email_reminder] },
    ];

    // Handlers
    const handleOpenMenuItems = (status) => {
        if (status == 1 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                promote_to_client: true,
                demote_to_fallout: false,
                delete: false,
            });
        } else if (status == 2 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                promote_to_client: false,
                demote_to_fallout: true,
                delete: false,
            });
        } else if (status == 3 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                promote_to_client: false,
                demote_to_fallout: false,
                delete: true,
            });
        }
    };

    const dullyFilledHandler = (status) => {
        if (status === 1 && selectedUsers.length === 1) {
            setDullyKebabMenuOption({
                change_status: true,
                email_to_prospect: false,
            });
        } else if (status == 2 && selectedUsers.length !== 0) {
            setKebabMenuOption({
                promote_to_client: false,
                demote_to_fallout: true,
                delete: false,
            });
        } else if (status === 3 && selectedUsers.length !== 0) {
            setDullyKebabMenuOption({
                change_status: false,
                email_to_prospect: true,
            });
        }
    };

    //UseEffect
    useEffect(() => {
        const temp = [];
        selectedColumns.filter((col) => {
            temp.push(col.field);
        });
        setOnSelectionColumn(temp);

        // Menu Items
        if (user) {
            // Prospect Menu Items
            const _prospect_menu_items = PROSPECT_MENU_ITEMS.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user.rights);
                if (isAllowed) return col;
            });
            setProspectMenuItems(_prospect_menu_items);

            // Prospect Dully Menu Items
            const _prospect_dully_menu_items = PROSPECT_DULLY_MENU_ITEMS.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user.rights);
                if (isAllowed) return col;
            });
            setProspectDullyMenuItems(_prospect_dully_menu_items);
        }
    }, [user, header, selectedUsers.length]);

    return (
        <div className="grid">
            <div className="md:col-5 col-12">
                {componentName === "available_prospect" && (
                    <div className="flex mt-4">
                        <Checkbox inputId="showall" checked={showAll} onChange={(e) => setShowAll(e.checked)} />
                        <label htmlFor="showall" className="mt-1 ml-2">
                            Include deleted prospects
                        </label>
                    </div>
                )}
            </div>

            <div className="md:col-7 col-12 pt-3">
                <div className="flex flex-row">
                    <SearchInputComponent value={keyword} setKeyword={setKeyword} placeholder="Search by registered business name, contact name & email " />
                    <div className="ml-2">
                        <ColumnFilterComponent items={header} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                    </div>
                    {!(componentName === "fall_out") && (
                        <div className="ml-2">
                            {(componentName === "available_prospect" ? prospect_menu_items.length > 0 : prospect_dully_menu_items.length > 0) && (
                                <KebabMenuComponent
                                    items={componentName === "available_prospect" ? prospect_menu_items : prospect_dully_menu_items}
                                    handleMenuOpen={(e) => (componentName === "available_prospect" ? handleOpenMenuItems(e) : dullyFilledHandler(e))}
                                />
                            )}
                        </div>
                    )}
                    {componentName === "available_prospect" && validateUserPermissions([MANAGMENT_RIGHTS.prospects.add_new], user?.rights) && (
                        <div className="ml-2">
                            <CustomAddNewButton
                                title="Add New Prospect"
                                type="button"
                                onClick={(e) => {
                                    history.push(`/addprospect`);
                                    dispatch(onProspectIndexChange(1));
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FilterBar;
