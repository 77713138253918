import React, { useState } from "react";

import "./addnewcollection.scss";

import Header from "./Header";
import CollectionCard from "./CollectionCard";

const AddNewCollection = () => {
    // States
    const [subHeader, setSubHeader] = useState("");
    const [activeCard, setActiveCard] = useState(0);
    const [header, setHeader] = useState("Collection Against Invoice(s)");
    const [collectionType, setCollectionType] = useState(1);

    return (
        <>
            <Header setHeader={setHeader} setSubHeader={setSubHeader} activeCard={activeCard} setActiveCard={setActiveCard}  setCollectionType = {setCollectionType}/>
            <CollectionCard header={header} subHeader={subHeader} collectionType={collectionType}/>
        </>
    );
};

export default AddNewCollection;
