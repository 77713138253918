import React, { useContext, useEffect, useState } from "react";
import Body from "./GridView/Body";
import FilterBar from "./GridView/FilterBar";
//redux
import { CHARGEBACK_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import { ToastContext } from "../../../../../../context/toast";
import { getChargebacks } from "../../../../../../services/chargeback";

const Chargebacks = () => {
    const toast = useContext(ToastContext);

    const [keyword, setKeyword] = useState("");
    const [showAll, setShowAll] = useState(false);
    const [selectedChargebacks, setSelectedChargebacks] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(CHARGEBACK_GRID_HEADERS);
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const [kebabMenuOption, setKebabMenuOption] = useState({
        move_to_schedule: false,
    });

    const fetchChargebacks = async (page) => {
        let payload = {
            per_page: pageSize,
            page: currentPage,
            sort_by: sortField,
            sort_desc: sortOrder === 1 ? "asc" : "desc",
            search_text: keyword,
            show_all: showAll
        };

        setSelectedChargebacks([]);
        setLoading(true);
        try {
            const res = await getChargebacks(payload);
            if (res.data.status) {
                setResponseData(res.data.invoices.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.invoices.total,
                    totalPages: res.data.invoices.last_page,
                    isLastPage: res.data.invoices.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };
    
    useEffect(() => {
        if (keyword) {
            const delayDebounceFn = setTimeout(() => {
                fetchChargebacks();
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        } else {
            fetchChargebacks();
        }
    }, [showAll, keyword, sortField, sortOrder, pageSize, currentPage]);

    return (
        <>
            <FilterBar
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                setKebabMenuOption={setKebabMenuOption}
                selectedChargebacks={selectedChargebacks}
                setSelectedChargebacks={setSelectedChargebacks}
                fetchChargebacks={fetchChargebacks}
            />
            <Body
                selectedHeaders={responseData}
                selectedChargebacks={selectedChargebacks}
                setSelectedChargebacks={setSelectedChargebacks}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                setPage={setPage}
                page={page}
            />
        </>
    );
};

export default Chargebacks;
