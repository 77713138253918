import React from "react";
import loaderGif from "../../Images/loader_gif.gif";
import "./customcardspinner.scss";

export default function CustomCardSpinner() {
    return (
        <div className="loader_modal_div">
            <img src={loaderGif} alt="Loading..." className="loader_image_width" />
        </div>
    );
}
