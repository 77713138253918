import React, { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { deleteSchedule, getProspectScheduleNo, getScheduleInvoices } from "../../../../../../services/schedule-assignment";
import { ToastContext } from "../../../../../../context/toast";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomModalSpinner from "../../../../../../ui/CustomModalSpinner/CustomModalSpinner";

const DeleteScheduleModal = (props) => {
    const { selectedSchedules, onHide, getGridData } = props;

    //states
    const [actionchange, setActionChange] = useState([]);
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [prospectSchedules, setProspectSchedules] = useState([]);
    const [data, setData] = useState([]);

    //ref
    const dt = useRef(null);

    //toast message
    const toast = useContext(ToastContext);

    const onActionChange = (e, index, invoice_id) => {
        if (e.value.invoice_count >= 20) {
            toast.showMessage("Warning", `Schedule No: ${e.value.schedule_no} invoices exceed more than 20 invoices`, "warn");
        } else {
            setActionChange((prevState) => ({
                ...prevState,
                [index.rowIndex]: e.value,
            }));

            data[index.rowIndex] = {
                schedules: e.value,
                invoice_id,
            };
            setData(data);
        }
    };

    const fetchInvoices = async () => {
        setLoading(true);
        let payload = {
            schedule_id: selectedSchedules[0].id,
        };
        try {
            const res = await getScheduleInvoices(payload);
            if (res.data.status) {
                setInvoices(res.data.invoices);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    const handleScheduleNo = async () => {
        setLoading(true);
        let payload = {
            "schedules.prospect_id": selectedSchedules[0].prospect_id,
            id: selectedSchedules[0].id,
        };
        try {
            const res = await getProspectScheduleNo(payload);
            if (res.data.status) {
                const data = res.data.invoices;
                data.unshift({
                    schedule_no: "New Schedule",
                    invoice_count: 0,
                });
                setProspectSchedules(data);
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        let payload = {
            id: [selectedSchedules[0].id],
            prospect_id: selectedSchedules[0].prospect_id,
            schedule_invoices: {},
        };

        data.forEach((element, index) => {
            if (element.schedules.schedule_no === "New Schedule") {
                data[index].schedules.schedule_no = -1;
            }
            payload.schedule_invoices[parseInt(element.invoice_id)] = data[index].schedules.schedule_no;
        });
        onHide();
        try {
            const res = await deleteSchedule(payload);
            if (res.data.status) {
                toast.deleteToast("Schedule");
                getGridData();
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    useEffect(() => {
        fetchInvoices();
        handleScheduleNo();
    }, []);

    const dropdownTemplate = (rowData, rowIndex) => {
        return (
            <>
                <Dropdown
                    name="action"
                    id="action"
                    options={prospectSchedules}
                    optionLabel="schedule_no"
                    onChange={(e) => onActionChange(e, rowIndex, rowData.id)}
                    value={actionchange[rowIndex.rowIndex]}
                    placeholder="Select"
                />
            </>
        );
    };
    return (
        <>
            {loading ? (
                <CustomModalSpinner top={10} />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 pt-3">
                            <DataTable ref={dt} value={invoices} rows={5} responsiveLayout="scroll" emptyMessage="No record available.">
                                <Column field="legal_name" header="Debtor Name"></Column>
                                <Column field="invoice_no" header="Invoice No"></Column>
                                <Column field="invoice_amount" header="Invoice Amount"></Column>
                                <Column field="advance_amount" header="Advance Amount"></Column>
                                <Column field="invoice_date" header="Invoice Date"></Column>
                                <Column field="invoice_due_date" header="Invoice Due Date"></Column>
                                <Column body={dropdownTemplate} header="Action"></Column>
                            </DataTable>
                        </div>
                    </div>
                    <div className="flex justify-content-end pt-4">
                        <CustomCancelbtn onClick={onHide} title="Cancel" /> <CustomAddNewButton title="Proceed" className="ml-2" onClick={() => handleDelete()} />
                    </div>
                </>
            )}
        </>
    );
};

export default DeleteScheduleModal;
