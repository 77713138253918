import React, { useContext, useEffect, useState } from "react";
//styles
import "../chats.scss";
//svgs
import { BsSend } from "react-icons/bs";
//PrimeReact components
import { Dialog } from "primereact/dialog";
//ui
import SearchInputComponent from "../../../ui/SearchInput/SearchInputComponent";
//components
import ComposeModal from "../Components/ComposeModal";
import ChatSidebar from "./ChatSidebar";
import ChatMessageIndex from "./ChatMessageIndex";
import { getChatList } from "../../../services/chat";
import { SocketContext } from "../../../context/socket";
import { loginState } from "../../../redux/login";
import { useDispatch, useSelector } from "react-redux";
import { onChatNewMessage } from "../../../redux/main-view";

const ChatIndex = () => {
    const socketContext = useContext(SocketContext);
    const { user } = useSelector(loginState);
    //states
    const [globalFilter, setGlobalFilter] = useState(null);
    const [composemodal, setComposeModal] = useState(false);

    const [chatlist, setChatList] = useState([]);
    const [displayChats, setDisplayChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedChat, setSelectedChat] = useState([]);

    const dispatch = useDispatch();

    // Api Handler
    const getChats = async () => {
        setLoading(true);
        let form = {
            page: 1,
            per_page: 10,
        };
        let res = await getChatList(form);
        if (res?.data) {
            let sortedChats = res.data.chats.data.sort((a, b) => {
                return b.id - a.id;
            });
            setChatList(sortedChats);
            setDisplayChats(sortedChats);
            setLoading(false);
        }
        setLoading(false);

        return;
    };

    useEffect(() => {
        getChats();
    }, []);

    useEffect(() => {
        if (socketContext.socket !== undefined && socketContext.socket !== null && user) {

            socketContext.socket.onAny((eventName, data) => {
                if(process.env.REACT_APP_NOTIFICATION_URL + `new_chat:${user.id}`===eventName){
                    getChats();
                }
            }); 
        }
    }, [socketContext.socket, user]);

    useEffect(() => {
        dispatch(onChatNewMessage(false));
    }, []);

    useEffect(() => {
        if (globalFilter != null) {
            const delayDebounceFn = setTimeout(() => {
                let result = [];
                if (globalFilter) {
                    result = chatlist.filter((item) => {
                        return item.name.toLowerCase().includes(globalFilter.toLowerCase());
                    });
                    setDisplayChats(result);
                } else {
                    setDisplayChats(chatlist);
                }
            }, 400);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [globalFilter]);

    useEffect(() => {}, [globalFilter]);

    return (
        <>
            <Dialog
                blockScroll={true}
                onHide={(e) => setComposeModal(false)}
                visible={composemodal}
                header="Compose Message"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
            >
                <ComposeModal
                    onHide={() => {
                        setComposeModal(false);
                        getChats();
                    }}
                />
            </Dialog>
            <div className="grid">
                <div className="col-12">
                    <h4 className="pl-2">
                        <b>
                            Chats <small className="beta_text">Beta</small>
                        </b>
                    </h4>
                </div>
            </div>
            <div className="card chat_view ">
                <div className="grid">
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="">
                            <SearchInputComponent placeholder="Search conversation" value={globalFilter} setKeyword={setGlobalFilter} />
                        </div>
                        <div className="">
                            <div className="grid">
                                <div className="col-12 text-center">
                                    <button className="compose_btn" onClick={setComposeModal}>
                                        Compose Message <BsSend />
                                    </button>
                                </div>
                                {/* //ChatSidebar Component */}
                                <div className="col-12">
                                    <ChatSidebar loading={loading} chatlist={displayChats} setSelectedChat={setSelectedChat} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-8 sm:col-6 col-12 chat_main_div">
                        <ChatMessageIndex selectedChat={selectedChat} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChatIndex;
