import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// Prime React
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";

// UI Components

// Components
import Header from "./Header";
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../../../../ui/KebabMenu/KebabMenuComponent";
import { ROLE_NAME, SCHEDULES_GRID_HEADERS, SCHEDULE_OF_ASSIGNMENT_MENU } from "../../../../../../../utils/Constants/global";
import VerifyScheduleModal from "../../Components/VerifyScheduleModal";
import { ToastContext } from "../../../../../../../context/toast";
import DeleteScheduleModal from "../../Components/DeleteScheduleModal";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import { useSelector } from "react-redux";

// Redux
import { loginState } from "../../../../../../../redux/login";
import Legends from "../../../../../../../ui/Legends/Legends";

// Constants

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedSchedules, setKebabMenuOption, kebabMenuOption, getGridData, setSelectedSchedules, setLoading } = props;

    // use State
    const [onSelectionColumn] = useState([]);
    const [kebab_menu_items, setKebabMenuItems] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    // Base Url
    let BASE_URL = process.env.REACT_APP_BASE_URL;

    //toast message
    const toast = useContext(ToastContext);

    //Handlers
    const handleOpenMenuItems = (status, menu) => {
        if (status === 1 && selectedSchedules.length === 1) {
            setKebabMenuOption({
                verify_schedule: true,
            });
        } else if (status === 2 && selectedSchedules.length === 1) {
            menu.current.hide();
            handleDownloadSchedule();
        } else if (status === 3 && selectedSchedules.length === 1) {
            setKebabMenuOption({
                delete_schedule: true,
            });
        }
    };

    //Download Schedules

    const handleDownloadSchedule = async () => {
        setLoading(true);
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await axios.post(
                `${BASE_URL}schedule/pending/download`,
                { id: selectedSchedules[0].id },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: "arraybuffer",
                }
            );
            if (response) {
                const pdfData = new Uint8Array(response.data);
                const blob = new Blob([pdfData], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setSelectedSchedules([]);
        setLoading(false);
    };

    useEffect(() => {
        SCHEDULES_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });

        // Kebab Items
        // const _kebab_items = SCHEDULE_OF_ASSIGNMENT_MENU.filter((col) => {
        //     const isAllowed = validateUserPermissions(col.required_rights, user?.rights);
        //     if (isAllowed) {
        //         return col;
        //     }
        // });

        const _kebab_items = SCHEDULE_OF_ASSIGNMENT_MENU
        .filter((col) => {
            // Check if user has permission
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);
            return isAllowed;
        })
        .map((col) => {
            // Add isDisabled if title is "Verify Schedule"
            if (col.title === "Verify Schedule") {
                return { ...col, isDisabled: selectedSchedules.length > 0 && selectedSchedules.length != 1 };
            }

            // Add isDisabled if title is "Delete Schedule"
            if (col.title === "Delete Schedule") {
                return { ...col, isDisabled: selectedSchedules.length > 0 && selectedSchedules.length != 1 };
            }

            return col;
        });

        setKebabMenuItems(_kebab_items);
    }, [user, selectedSchedules]);

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-10">
                    <h4 className="m-0">
                        <b>Schedule of Assignment</b>
                    </h4>
                </div>
                {/* <div className="col-12 md:col-12 text-right">
                   
                </div> */}
                <div className="md:col-6 col-12">
                    {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT ? (
                        <div className="flex mt-4">
                            <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                            <label htmlFor="showall" className="mt-1 ml-2">
                                Include deleted/terminated schedule
                            </label>
                            {showAll === 1 && (
                                <>
                                    <div className="mt-1 ml-3">
                                        <Legends classes="square-legend-delete" title="Deleted" />
                                    </div>
                                    <div className="mt-1 ml-2">
                                        <Legends classes="square-legend-terminate" title="Terminated" />
                                    </div>
                                </>
                            )}
                        </div>
                    ) : null}
                </div>
                <div className="md:col-6 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent
                            type="text"
                            value={keyword}
                            setKeyword={setKeyword}
                            // handleInput={(e) => setKeyword(e.target.value)}
                            placeholder="Search by client, schedule no & net funding"
                        />
                        <div className="ml-2">
                            <ColumnFilterComponent items={SCHEDULES_GRID_HEADERS} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className={`ml-2 ${true ? "no-drop" : ""}`}>
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedSchedules.length === 0} />}
                        </div>
                        <div className="ml-2">
                            <Header selectedUsersLength={10} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Dialouge Section*/}
            <Dialog
                blockScroll={true}
                className="verify_schedule"
                header="Verify Schedule"
                visible={kebabMenuOption.verify_schedule}
                fullScreen
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
                onHide={() => {
                    setKebabMenuOption({
                        verify_schedule: false,
                    });
                    setSelectedSchedules([]);
                }}
            >
                <VerifyScheduleModal
                    onHide={() => {
                        setKebabMenuOption({
                            verify_schedule: false,
                        });
                        setSelectedSchedules([]);
                    }}
                    getGridData={getGridData}
                    selectedSchedules={selectedSchedules}
                />
            </Dialog>
            {/* //Delete Schedule */}
            <Dialog
                blockScroll={true}
                header="Delete Schedule"
                visible={kebabMenuOption.delete_schedule}
                fullScreen
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw", height: "30vh" }}
                onHide={() => {
                    setKebabMenuOption({
                        delete_schedule: false,
                    });
                    setSelectedSchedules([]);
                }}
                className="verify_schedule"
            >
                <DeleteScheduleModal
                    onHide={() => {
                        setKebabMenuOption({
                            delete_schedule: false,
                        });
                        setSelectedSchedules([]);
                    }}
                    getGridData={getGridData}
                    selectedSchedules={selectedSchedules}
                />
            </Dialog>
        </>
    );
};

export default FilterBar;
