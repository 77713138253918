import React, { useContext, useEffect, useState } from "react";
//styles
import "../../../../../Operations/operation.scss";
// Constants
import FilterBar from "./GridView/FilterBy";
import Body from "./GridView/Body";
import { SCHEDULES_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import { ToastContext } from "../../../../../../context/toast";
import { getScheduleAssignment } from "../../../../../../services/schedule-assignment";

// import { getInvoices } from "../../../../../../services/invoices";

const ScheduleOffunding = () => {
    // State
    const [keyword, setKeyword] = useState("");
    const [onSelectionColumn] = useState([]);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(SCHEDULES_GRID_HEADERS);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [selectedSchedules, setSelectedSchedules] = useState([]);

    const [sortCount, setSortCount] = useState(0);
    const [showAll, setShowAll] = useState(0);

    const [kebabMenuOption, setKebabMenuOption] = useState({
        post_payment: false,
        revert_schedule: false,
        show_payment: false,
    });

    // PAGINATION
    // States
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(currentPage);
    const [pageSize, setPageSize] = useState(10);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    // View State
    const [loading, setLoading] = useState(false);

    //toast message
    const toast = useContext(ToastContext);

    // Api Handler
    const getGridData = async (page) => {
        setLoading(true);
        const form = new FormData();

        form.append("per_page", pageSize);
        form.append("page", page ? page : currentPage);
        form.append("search_text", keyword);
        form.append("sortDesc", "desc");
        form.append("deleted", showAll ? 1 : 0);
        form.append("is_funding", 1);
        form.append("sortBy", "updated_at");

        if (sortOrder) {
            form.append("sortDesc", sortOrder === 1 ? "asc" : "desc");
        }

        try {
            const res = await getScheduleAssignment(form);
            if (res.data.status) {
                setSelectedHeaders(res.data.schedules.data);
                setPagination({
                    ...pagination,
                    totalRecords: res.data.schedules.total,
                    totalPages: res.data.schedules.last_page,
                    isLastPage: res.data.schedules.last_page,
                });
            } else {
                setLoading(false);
                toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
        } catch (e) {
            setLoading(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setLoading(false);
        SCHEDULES_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    };

    //UseEffect
    // useEffect(() => {
    //     if (keyword) {
    //         const delayDebounceFn = setTimeout(() => {
    //             setCurrentPage(1);
    //             setPage(1);
    //             getGridData(1);
    //         }, 350);
    //         return () => clearTimeout(delayDebounceFn);
    //     } else {
    //         getGridData(1);
    //     }
    // }, [showAll, keyword, sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        getGridData();
    }, [showAll, sortField, sortOrder, pageSize, currentPage]);

    useEffect(() => {
        if (keyword != null) {
            const delayDebounceFn = setTimeout(() => {
                if (currentPage != 1) {
                    setCurrentPage(1);
                    setPage(1);
                } else {
                    getGridData();
                }
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [keyword]);

    return (
        <>
            <FilterBar
                setKeyword={setKeyword}
                keyword={keyword}
                setShowAll={setShowAll}
                showAll={showAll}
                setSelectedColumns={setSelectedColumns}
                setKebabMenuOption={setKebabMenuOption}
                kebabMenuOption={kebabMenuOption}
                selectedSchedules={selectedSchedules}
                setSelectedSchedules={setSelectedSchedules}
                getGridData={() => getGridData(1)}
                setLoading={setLoading}
            />
            <Body
                selectedHeaders={selectedHeaders}
                selectedColumns={selectedColumns}
                sortField={sortField}
                setSortField={setSortField}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                sortCount={sortCount}
                setSortCount={setSortCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pagination={pagination}
                loading={loading}
                kebabMenuOption={kebabMenuOption}
                setKebabMenuOption={setKebabMenuOption}
                setPage={setPage}
                page={page}
                selectedSchedules={selectedSchedules}
                setSelectedSchedules={setSelectedSchedules}
            />
        </>
    );
};

export default ScheduleOffunding;
