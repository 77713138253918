import React from "react";
import CreditRequestForm from "./CreditRequestForm";

const AddNewCreditRequest = (props) => {
    let { isClientRequest, onHide, setIsOpening } = props;

    return (
        <>
            <div className="">
                <CreditRequestForm isClientRequest={isClientRequest === true ? 1 : 0} onHide={onHide} setIsOpening={setIsOpening} />
            </div>
        </>
    );
};

export default AddNewCreditRequest;
