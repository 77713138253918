import React from "react";
// component
import { Chart } from "primereact/chart";
import { getDateFormat } from "../../../utils/HelperFuctions/dateFormat";
const PaymentTrendBarGraph = (props) => {
    const { collectionData } = props;
    const paymentTrendBarGraphLabels = [];
    const paymentTrendBarGraphData = [];
    Object.values(collectionData).forEach(function (collection) {
        paymentTrendBarGraphLabels.push(getDateFormat(collection.collection));
        paymentTrendBarGraphData.push(collection.age);
    });
    const paymentAgingData = {
        labels: paymentTrendBarGraphLabels,
        datasets: [
            {
                label: "Age Trend",
                backgroundColor: "#42A5F5",
                data: paymentTrendBarGraphData,
            },
        ],
    };
    const getLightTheme = () => {
        let paymentAgingOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                        display: false,
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
            animation: {
                duration: 0,
            },
        };
        return {
            paymentAgingOptions,
        };
    };
    const { paymentAgingOptions } = getLightTheme();
    return (
        <>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <h5 className="p-mx-0 p-my-1 pt-2">
                        <b>Payment Trend by Aging</b>
                    </h5>
                    <Chart type="bar" data={paymentAgingData} options={paymentAgingOptions} />
                </div>
            </div>
        </>
    );
};

export default PaymentTrendBarGraph;
