import React, { useContext, useEffect, useRef, useState } from "react";

import DefaultUserImage from "../../../../Images/user.png";

//styles
import "../../userprofile.scss";

// Icons
import { BsCheckLg } from "react-icons/bs";

//formik
import * as Yup from "yup";
import { useFormik } from "formik";

//ui
import CustomInputField from "../../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../ui/CustomCancelbtn/CustomCancelbtn";
import ZipCodesMenuComponent from "../../../../ui/ZipCodesMenu/ZipCodesMenuComponent";
import CustomConfirmDialog from "../../../../ui/CustomConfirmDialog/CustomConfirmDialog";

// Context, Redux & APIs
import { useDispatch, useSelector } from "react-redux";
import { loginState, setUser } from "../../../../redux/login";
import { getZipCode } from "../../../../services/zip-code";
import { getCountryCode } from "../../../../services/country-code";
import { ToastContext } from "../../../../context/toast";
import { deleteAttachment, getLoggedInUser, updateUserProfile, updateUserProfileImage } from "../../../../services/user-management/user";

// Utils
import { getTimeZones } from "../../../../services/helpers";

// primereact component
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
import CustomAttachmentUpload from "../../../../ui/CustomAttachmentUpload/CustomAttachmentUpload";
import { FILE_MAX_SIZE } from "../../../../utils/Constants/global";
import { deleteAttachments } from "../../../../services/invoice";
import appUrl from "../../../../utils/Constants/appUrl";

const AccountDetails = () => {
    const toastContext = useContext(ToastContext);
    const dispatch = useDispatch();
    const profileRef = useRef();
    const [editEnabled, setEditEnabled] = useState(false);
    const [countryCodes, setCountryCodes] = useState();
    const [timeZones, setTimeZones] = useState();
    const [zipCode, setZipCode] = useState([]);
    const [isZipCodeSearch, setIsZipSearch] = useState(false);
    const [recordNotFoundMsg, setRcordNotFoundMsg] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isOpeneing, setIsOpeneing] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [remove, setRemove] = useState(false);
    const [fileUploadData, setFileUploadData] = useState(null);
    const [showOfficialSignature, setShowOfficialSignature] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [deleteOfficialSignature, setDeleteOfficialSignature] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const toast = useContext(ToastContext);

    const { user } = useSelector(loginState);

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required("First Name is required").nullable(),
        zipcode: Yup.string().required("Zip Code is required").nullable(),
        phone: Yup.string().required("Phone No is required").nullable(),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstname: user?.first_Name ?? "",
            lastname: user?.last_Name ?? "",
            gender: user?.gender ?? "",
            country: user?.country_phone_code ?? "+1",
            address: user?.address ?? "",
            zipcode: user?.zip ?? "",
            timezone: user?.timezone ?? "",
            phone: user?.contact ?? "",
            official_signature: "",
        },
        onSubmit: async (data) => {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("sso_id", user?.sso_id);
            formData.append("first_name", data.firstname);
            formData.append("last_name", data.lastname);
            formData.append("phone_number", data.phone);
            formData.append("gender", data.gender);
            formData.append("address", data.address);
            formData.append("city", data.city);
            formData.append("state", data.state);
            formData.append("zip", data.zipcode);
            formData.append("timezone", data.timezone);

            if (fileUploadData != null || !showOfficialSignature) formData.append("official_signature", fileUploadData);
            try {
                let resp = await updateUserProfile(formData);
                if (resp.data.status) {
                    setEditEnabled(false);
                    toastContext.updateToast("User profile");
                    getUpdatedUserData();
                    setShowOfficialSignature(true);
                    setFileUploadData(null);
                    setIsLoading(false);
                }
            } catch (e) {
                setShowOfficialSignature(true);
                setIsLoading(false);
                toastContext.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
            }
            // window.location.reload();
            return;
        },
    });

    const handleInputChange = (e) => {
        setIsFormDirty(true);
        formik.handleChange(e);
    };

    const genderOption = [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
    ];

    const getUpdatedUserData = async () => {
        let resp = await getLoggedInUser();
        dispatch(setUser(resp.data.user));
    };

    const zipcodeChange = async () => {
        const dto = {
            zip: formik.values.zipcode,
        };
        let resp = await getZipCode(dto);
        if (resp.data.codes.length !== 0) {
            formik.setFieldValue("city", resp.data.codes[0].city);
            formik.setFieldValue("state", resp.data.codes[0].full_state);
        }
    };

    const countryCode = async () => {
        let resp = await getCountryCode();
        setCountryCodes(resp.data.countries);
    };

    const timeZone = async () => {
        let resp = await getTimeZones();
        setTimeZones(resp.data.codes);
    };

    const openFileBrowser = (ref) => {
        ref.current.click();
    };

    const onProfileChange = async (e) => {
        const file = e.target.files[0];
        const body = new FormData();
        body.append("profile_pic", file);

        if (file.size > 5000000) {
            toastContext.showMessage("Error", "File size exceeds 5MB limit", "error");
            return;
        }
        if (!file.name.endsWith(".png") && !file.name.endsWith(".jpg") && !file.name.endsWith(".jpeg")) {
            toastContext.showMessage("Error", "Invalid file type", "error");
            return;
        }

        let resp = await updateUserProfileImage(body);
        if (resp.data.status) {
            toastContext.updateToast("Profile picture");
        } else {
            toastContext.showMessage("Error", resp.data.message, "error");
        }

        getUpdatedUserData();
    };

    const selectedZipCode = (zip) => {
        setIsZipSearch(false);
        setRcordNotFoundMsg("");
        formik.setFieldValue("zipcode", zip.zip);
        formik.setFieldValue("city", zip.city);
        formik.setFieldValue("state", zip.full_state);
        setZipCode([]);
    };

    const onCancelHandler = () => {
        if (isFormDirty) {
            setIsOpeneing(true);
        } else {
            setEditEnabled(false);
        }
        setZipCode([]);
    };

    // Api Handler
    const fetchZipCodeData = (value) => {
        const paylod = {
            zip: value,
        };
        getZipCode(paylod).then((res) => {
            setZipCode(res.data.codes);
            if (res.data.codes.length === 0) {
                setZipCode([]);
                setRcordNotFoundMsg("No record available");
            }
        });
    };

    const onFileSelect = (event) => {
        if (event.size > 0) {
            const file = event;
            const fileType = file.name.split(".");
            const index = fileType.length - 1;
            const accept = ".png,.jpg,.jpeg";
            const check_file = accept.includes(fileType[index]);

            if (check_file) {
                if (file.size > FILE_MAX_SIZE.FILE_SIZE) {
                    toast.showMessage("Exceeding Limit", `${file?.name} size is greater than allowed size.`, "error");
                } else if (file.name.includes("#") || file.name.includes("&")) {
                    toast.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
                } else {
                    setFileUploadData(file);
                }
            } else {
                toast.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
                setFileUploadData(null);
            }

            event = null;
        }
    };

    const handleDelete = (file) => {
        setShowConfirmDeleteDialog(true);
        setDeleteOfficialSignature({
            path: file,
        });
    };

    const confirmDeleteHandler = async () => {
        setIsDeleting(true);
        try {
            let resp = await deleteAttachment(deleteOfficialSignature);
            if (resp.data.status) {
                setShowConfirmDeleteDialog(false);
                toast.showMessage("File Deleted", "File has been deleted successfully", "success");

                setFileUploadData(null);
                setShowOfficialSignature(false);
            } else {
                toast.showMessage("Deletion Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsDeleting(false);
        } catch (error) {
            setIsDeleting(false);
            toast.showMessage("Server Error", "Server error.", "error");
        }
    };

    useEffect(() => {
        if (formik.values.zipcode && formik.values.zipcode?.length >= 4 && isZipCodeSearch && formik.touched) {
            fetchZipCodeData(formik.values.zipcode);
        } else if (formik.values.zipcode?.length <= 4) {
            formik.setFieldValue("city", "");
            formik.setFieldValue("state", "");

            setZipCode([]);
            setIsZipSearch(true);
        }
    }, [formik.values.zipcode]);

    useEffect(() => {
        setIsZipSearch(false);
        formik.setFieldValue("firstname", user?.first_Name ?? "");
        formik.setFieldValue("lastname", user?.last_Name ?? "");
        formik.setFieldValue("gender", user?.gender ?? "");
        formik.setFieldValue("country", user?.country_phone_code ?? "+1");
        formik.setFieldValue("city", user?.city ?? "");
        formik.setFieldValue("state", user?.state ?? "");
        formik.setFieldValue("address", user?.address ?? "");
        formik.setFieldValue("zipcode", user?.zip ?? "");
        formik.setFieldValue("timezone", user?.timezone ?? "");
        formik.setFieldValue("phone", user?.contact ?? "");

        if (user?.official_signature == null) {
            setShowOfficialSignature(true);
        }
    }, [user, isCheck]);

    useEffect(() => {
        if (formik?.values?.zipcode?.length > 4) {
            zipcodeChange();
        }
    }, [formik.values.zipcode]);

    useEffect(() => {
        countryCode();
        timeZone();
    }, []);

    const handleRemoveSignature = () => {
        setFileUploadData(null);
        setShowOfficialSignature(false);
    };
    return (
        <>
            <div className="mt-6 account_details">
                {/* <div className="flex justify-content-between align-items-center w-100"> */}
                <div className="profile_image_btn">
                    <div className="flex">
                        <span className="border_profile_img" onClick={() => openFileBrowser(profileRef)}>
                            <img src={user?.profile_pic ? appUrl.imageBaseUrl + user?.profile_pic : DefaultUserImage} alt="User Profile" />
                            <input type="file" id="file" accept=".jpg,.jpeg,.png" ref={profileRef} onChange={onProfileChange} style={{ display: "none" }} />
                        </span>
                        <div className="flex flex-column justify-content-center ml-4">
                            <h4 className="account_name">
                                {user?.last_Name} {user?.first_Name}
                            </h4>
                            <p className="account_email">{user?.email}</p>
                        </div>
                    </div>
                    <div>
                        <CustomAddNewButton title={"Edit Profile"} onClick={() => setEditEnabled(true)} isDisabled={editEnabled} />
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid mt-4">
                        <CustomInputField
                            className="col-12 md:col-4"
                            iden="firstname"
                            label="First Name"
                            mandatory="*"
                            formik={formik}
                            placeHolder="Enter First Name"
                            disabled={!editEnabled}
                            onChange={handleInputChange}
                            maxLength={30}
                        />
                        <CustomInputField
                            className="col-12 md:col-4"
                            iden="lastname"
                            label="Last Name"
                            formik={formik}
                            placeHolder="Enter Last Name"
                            disabled={!editEnabled}
                            onChange={handleInputChange}
                            maxLength={30}
                        />
                        <CustomInputField
                            type="dropdown"
                            options={genderOption}
                            optionLabel="name"
                            className="col-12 md:col-4"
                            iden="gender"
                            label="Gender"
                            formik={formik}
                            placeHolder="Select"
                            disabled={!editEnabled}
                            onChange={handleInputChange}
                        />
                        <div className="col-12 md:col-4">
                            <div className="zip_menu_position">
                                <CustomInputField
                                    className=""
                                    iden="zipcode"
                                    label="Zip Code"
                                    mandatory="*"
                                    formik={formik}
                                    placeHolder="Search..."
                                    maxLength={5}
                                    disabled={!editEnabled}
                                    onChange={handleInputChange}
                                    onPaste={(e) => e.preventDefault()}
                                />
                                <ZipCodesMenuComponent zipCodes={zipCode} handleSelectedZipCodes={selectedZipCode} />
                                <small className="p-error">{recordNotFoundMsg}</small>
                            </div>
                        </div>
                        <CustomInputField className="col-12 md:col-4" iden="state" label="State" formik={formik} disabled={true} />
                        <CustomInputField className="col-12 md:col-4" iden="city" label="City" formik={formik} disabled={true} />
                        <CustomInputField
                            className="col-12 md:col-4"
                            iden="address"
                            label="Address"
                            type="text"
                            formik={formik}
                            placeHolder="Enter Address"
                            maxLength={60}
                            disabled={!editEnabled}
                            onChange={handleInputChange}
                        />
                        <div className="col-12 md:col-4">
                            <label htmlFor="phone" className={classNames("step-fields-title")}>
                                <b style={{ fontSize: "14px" }}>Phone No</b> <span className="clr_red">*</span>
                            </label>
                            <div className={classNames({ "p-invalid": isFormFieldValid("phone") }, "border_div")}>
                                <Dropdown
                                    id="country"
                                    name="country"
                                    value={formik.values.country}
                                    className="custom_width_country"
                                    options={countryCodes}
                                    // onChange={formik.handleChange}
                                    onChange={handleInputChange}
                                    optionLabel="phone_code"
                                    optionValue="phone_code"
                                    placeholder="+1"
                                    filter
                                    filterBy="phone_code"
                                    disabled={!editEnabled}
                                />
                                <InputMask
                                    name="phone"
                                    id="phone"
                                    mask="999-999-9999"
                                    value={formik.values.phone}
                                    placeholder="999-999-9999"
                                    // onChange={formik.handleChange}
                                    onChange={handleInputChange}
                                    className="custom_width_phone"
                                    onBlur={formik.handleBlur}
                                    disabled={!editEnabled}
                                ></InputMask>
                            </div>
                            {getFormErrorMessage("phone")}
                        </div>

                        <CustomInputField
                            type="dropdown"
                            options={timeZones}
                            optionLabel="name"
                            optionValue="name"
                            className="col-12 md:col-4"
                            iden="timezone"
                            label="Time Zone"
                            formik={formik}
                            placeHolder="Select"
                            disabled={!editEnabled}
                            onChange={handleInputChange}
                        />

                        {/* {editEnabled && (
                            <> */}
                        <div className="col-12 md:col-4">
                            <label htmlFor="official_signature" className={classNames("step-fields-title mb-2")}>
                                <b style={{ fontSize: "14px" }}>Official Signature</b>
                            </label>
                            <CustomAttachmentUpload
                                setIsFormDirty={setIsFormDirty}
                                isFormDirty={isFormDirty}
                                handleSelectedFile={(e) => onFileSelect(e)}
                                fileType={".png, .jpg, .jpeg"}
                                remove={remove}
                                multiple="uploadSingle"
                                preview
                                disabled={!editEnabled}
                                style={!editEnabled ? { cursor: "not-allowed" } : {}}
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            {user?.official_signature && user?.official_signature.length > 4 && showOfficialSignature && (
                                <>
                                    <div className="official_signature_class">
                                        <img src={user?.official_signature} alt="User Profile" />
                                        {editEnabled && (
                                            <div
                                                className="signature_cros"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // handleRemoveSignature();
                                                    handleDelete(user?.official_signature);
                                                }}
                                            >
                                                <i class="bi bi-x text-2xl"></i>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {/* </>
                        )} */}

                        {editEnabled && (
                            <div className="col-12 text-right">
                                <CustomCancelbtn title={"Cancel"} className="mr-3" type="button" onClick={() => onCancelHandler()} />
                                {/* <CustomCancelbtn title={"Cancel"} className="mr-3" type="button" onClick={() => setEditEnabled(false)} /> */}
                                <CustomAddNewButton title={"Save"} type="submit" isLoading={isLoading} />
                            </div>
                        )}
                    </div>
                </form>
            </div>
            {isOpeneing && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpeneing(false)}
                    handler={() => {
                        setEditEnabled(false);
                        setIsOpeneing(false);
                        setIsCheck(!isCheck);
                        setIsFormDirty(false);
                        setFileUploadData(null);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                    // secondtext="This action cannot be reverted."
                />
            )}

            {showConfirmDeleteDialog && (
                <CustomConfirmDialog
                    header="Confirm Delete"
                    type="error"
                    onHide={() => setShowConfirmDeleteDialog(false)}
                    handler={() => confirmDeleteHandler()}
                    icon={<BsCheckLg />}
                    isDisabled={isDeleting}
                    isCancelDisabled={isDeleting}
                    title="Confirm"
                    firsttext="Are you sure, you want to delete?"
                />
            )}
        </>
    );
};

export default AccountDetails;
