import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";

//Prime React
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { mainViewState, onSupportResponseMutated } from "../../../redux/main-view";

// Context
import { ToastContext } from "../../../context/toast";

// FormMik
import * as Yup from "yup";
import { useFormik } from "formik";

// Components
import CustomInputField from "../../../ui/CustomInputField/CustomInputField";
import CustomAddNewButton from "../../../ui/CustomAddNewButton/CustomAddNewButton";

// Constants
import { DATE_TIME_FORMAT, FILE_MAX_SIZE, SUPPORT_TICKET_DETAIL } from "../../../utils/Constants/global";

// Icons SVGS
import Document from "../../../svgs/Document/Document";

// API'S
import { getTicketById, saveResponse } from "../../../services/support";
import CustomModalSpinner from "../../../ui/CustomModalSpinner/CustomModalSpinner";
import { BsInfoCircle, BsPaperclip, BsXLg } from "react-icons/bs";
import { Button } from "primereact/button";
import appUrl from "../../../utils/Constants/appUrl";

const TicketDetailModal = (props) => {
    const { selectedRowId, setIsFormDirty } = props;

    const [showTooltip, setShowTooltip] = useState(false);
    const [notes, setNotes] = useState([]);

    // Redux
    const { screens } = useSelector(mainViewState);

    // Context
    const toastContext = useContext(ToastContext);

    const hiddenFileInput = useRef(null);

    // Dispatch
    const dispatch = useDispatch();

    const [isLoad, setIsLoad] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedColumns, setSelectedColumns] = useState(SUPPORT_TICKET_DETAIL);

    const validationSchema = Yup.object().shape({
        responsedetail: Yup.string().required("Response Details is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            responsedetail: "",
        },
        onSubmit: async (data) => {
            setIsSaving(true);
            setIsLoading(true);
            try {
                const form = new FormData();
                form.append("response", data.responsedetail);
                form.append("id", selectedRowId);
                if (uploadedFile) {
                    form.append("attachment_path", uploadedFile);
                }
                const res = await saveResponse(form);
                if (res.data.status) {
                    dispatch(onSupportResponseMutated());
                    setIsFormDirty(false);
                    formik.setFieldValue("responsedetail", "");
                    formik.resetForm();
                    setUploadedFile(null);
                } else {
                    toastContext.showMessage("Assignment Failed", "Sorry, we are unable to process your request at this time.", "error");
                }
            } catch (error) {
                toastContext.showMessage("Assignment Failed", "Sorry, we are unable to process your request at this time.", "error");
            }
            setIsSaving(false);
            setIsLoading(false);
            formik.resetForm();
            return;
        },
    });
    // Tikcet Grid View
    const openDocument = (attachment) => {
        // Perform the action to open the document
        if (attachment) {
            window.open(appUrl.imageBaseUrl + attachment, "_blank");
        }
    };
    const supportResponseGetColumnBody = (colField) => (rowData) => {
        const { id, response } = rowData;
        const handleMouseEnter = () => {
            setShowTooltip(true);
        };
        const handleMouseLeave = () => {
            setShowTooltip(false);
        };
        if (colField === "responder") {
            const fullName = `${rowData?.user?.last_name ? rowData?.user?.last_name : ""} ${rowData?.user?.first_name ? rowData?.user?.first_name : ""}`;
            const shouldShowTooltip = fullName.length > 12;
            return (
                <div>
                    {shouldShowTooltip ? (
                        <div className="response_text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <span id={`responder-${id}`}>{fullName}</span>
                            <Tooltip target={`#responder-${id}`} position="bottom" onHide={() => setShowTooltip(false)}>
                                {fullName}
                            </Tooltip>
                        </div>
                    ) : (
                        <span id={`responder-${id}`}>{fullName}</span>
                    )}
                </div>
            );
        } else if (colField === "responsetime") {
            return rowData.created_at ? moment(rowData.created_at).format(DATE_TIME_FORMAT) : "";
        } else if (colField === "attachments") {
            return (
                <>
                    <div className={` mr-2 mt-1 ${rowData.attachment_path ? "document_icon_background" : "no_doc"}`} onClick={() => openDocument(rowData.attachment_path)}>
                        {rowData.attachment_path ? <Document /> : "No Attachment"}
                    </div>
                </>
            );
        } else {
            if (response.length > 5) {
                return (
                    <div className="response_text" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <span id={`name-${id}`}>{response}</span>
                        {showTooltip && (
                            <Tooltip target={`#name-${id}`} position="left" onHide={() => setShowTooltip(false)}>
                                {response}
                            </Tooltip>
                        )}
                    </div>
                );
            }
            return <span>{response}</span>;
        }
    };

    const inputHandler = (e) => {
        setIsFormDirty(true);
        formik.handleChange(e);
    };
    // File Selection Handler
    const onFileSelect = (event) => {
        const file = event.target.files[0];
        const fileType = file.name.split(".");
        const index = fileType.length - 1;
        const accept = ".jpg,.jpeg,.png,.pdf";
        const check_file = accept.includes(fileType[index]);

        if (check_file) {
            const fileSize = file.size > FILE_MAX_SIZE.FILE_SIZE;
            if (fileSize) {
                toastContext.showMessage("Exceeding Limit", "Uploaded file size is greater than allowed size.", "error");
                event.target.value = null;
            } else if (file.name.includes("#") || file.name.includes("&")) {
                toastContext.showMessage("Invalid Filename", `Filename is invalid, filename must not include # and &`, "error");
            } else {
                setIsFormDirty(true);
                setUploadedFile(file);
                event.target.value = null;
            }
        } else {
            event.target.value = null;
            toastContext.showMessage("Not Allowed", `File type ${fileType[fileType.length - 1]} is not allowed.`, "error");
        }
    };

    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={supportResponseGetColumnBody(col.field)}></Column>;
    });

    const handleAttachmentDelete = () => {
        setUploadedFile(null);
    };
    // UseEffects
    useEffect(() => {
        const getDataByID = async () => {
            setIsLoad(true);
            const object = {
                id: selectedRowId,
            };
            const res = await getTicketById(object);
            if (res?.data) {
                formik.setFieldValue("title", res["data"]["tickets"]["title"]);
                formik.setFieldValue("ticket_type", res["data"]["tickets"]["ticket_type_text"]);
                formik.setFieldValue("module", res["data"]["tickets"]["module_text"]);
                formik.setFieldValue("priority", res["data"]["tickets"]["priority_text"]);
                formik.setFieldValue(
                    "assign_to",
                    res["data"]["tickets"]["au_first_name"] && res["data"]["tickets"]["au_last_name"]
                        ? (res["data"]["tickets"]["au_first_name"] ?? "") + " " + (res["data"]["tickets"]["au_last_name"] ?? "")
                        : "Unassigned"
                );
                formik.setFieldValue("details", res["data"]["tickets"]["details"]);
                formik.setFieldValue("responses", res["data"]["tickets"]["responses"]);
                setNotes(res?.data?.tickets?.responses);
            }
            setIsLoad(false);
        };
        if (selectedRowId) {
            getDataByID();
        }
    }, [screens.support.isResponseMutated]);

    const handleOpenUpload = () => {
        hiddenFileInput.current.click();
    };
    return (
        <>
            {isLoad ? (
                <CustomModalSpinner />
            ) : (
                <div className="grid">
                    <div className="col-12 md:col-6">
                        <div className="card">
                            <div className="grid">
                                <CustomInputField
                                    className="col-12 md:col-6 no-drop"
                                    iden="title"
                                    label="Ticket Title"
                                    formik={formik}
                                    placeHolder="Please Enter"
                                    disabled
                                    onChange={(e) => inputHandler(e)}
                                />
                                <CustomInputField
                                    className="col-12 md:col-6 no-drop"
                                    iden="ticket_type"
                                    label="Ticket Type"
                                    formik={formik}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    disabled
                                />

                                <CustomInputField
                                    className="col-12 md:col-6 no-drop"
                                    iden="priority"
                                    label="Priority"
                                    formik={formik}
                                    optionLabel="name"
                                    optionValue="value"
                                    placeHolder="Select"
                                    disabled
                                />
                                <CustomInputField className="col-12 md:col-6 no-drop" iden="assign_to" label="Assigned To" formik={formik} placeHolder="Enter Assigned To" disabled />
                                <CustomInputField className="col-12 md:col-12 no-drop" type="textarea" iden="details" label="Ticket Details" formik={formik} rows="12" disabled />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="card attachment_custom_height">
                            <form onSubmit={isSaving ? null : formik.handleSubmit}>
                                <div className="grid">
                                    <div className="col-12 pt-3">
                                        <label htmlFor="NameTitle">
                                            <b>Attachment</b>
                                            {/* <span className="clr_red">*</span> */}
                                            <Button
                                                type="button"
                                                tooltip=".jpg, .jpeg, .png, .pdf files only (max size 3MB) "
                                                tooltipOptions={{ position: "bottom" }}
                                                icon={<BsInfoCircle />}
                                                aria-label="Submit"
                                                className="customTooltipicon"
                                            />
                                        </label>
                                        <div className="file_upload_class">
                                            <label className={`flex flex-row justify-content-between align-items-center`} onClick={handleOpenUpload}>
                                                {uploadedFile ? (
                                                    <div className="flex row">
                                                        <span className="text_break">{uploadedFile.name}</span>
                                                        <BsXLg className="cursor-pointer cross_icon ml-1" onClick={() => handleAttachmentDelete()} />
                                                    </div>
                                                ) : (
                                                    <>Choose File</>
                                                )}
                                                <BsPaperclip />
                                            </label>
                                        </div>
                                        <input
                                            type="file"
                                            disabled={uploadedFile}
                                            ref={hiddenFileInput}
                                            id="file-upload"
                                            onChange={onFileSelect}
                                            accept=".jpg,.jpeg,.png,.pdf"
                                            style={{ display: "none" }}
                                        />
                                    </div>

                                    <CustomInputField
                                        className="col-12 md:col-12"
                                        type="textarea"
                                        label="Response Details"
                                        mandatory="*"
                                        iden="responsedetail"
                                        formik={formik}
                                        maxLength="150"
                                        onChange={inputHandler}
                                    />

                                    <div className="col-12 text-right pt-2">
                                        <CustomAddNewButton className={isSaving ? "no-drop" : ""} title="Save" type="submit" isDisabled={notes.length >= 10 ? true : false} isLoading={isLoading} />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card responser_scrollbar">
                            <p>
                                <b>Responses</b>
                            </p>

                            <div className="pt-3">
                                <DataTable value={formik?.values?.responses} responsiveLayout="scroll" emptyMessage="No record available.">
                                    {columnComponents}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TicketDetailModal;
