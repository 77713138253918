import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { loginState } from "./redux/login";

// Prime React
import { BsSend } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { SplitButton } from "primereact/splitbutton";

// UI Components
import NotificationMenuComponent from "./ui/NotificationMenu/NotificationMenuComponent";
import CustomBrokerInviteModal from "./ui/CustomBrokerInviteModal/CustomBrokerInviteModal";

// API'S
import { logout, logoutCall } from "./services/user-management/user";
import { getAllAnnoucements } from "./services/annoucements";
import { ToastContext } from "./context/toast";
import { trimTextWithDots } from "./utils/HelperFuctions/trimText";
import { Button } from "primereact/button";
import { ROLE_NAME } from "./utils/Constants/global";
import appUrl from "./utils/Constants/appUrl";

import DefaultUserImage from "../src/Images/user.png";

export const AppTopbar = (props) => {
    const { user } = useSelector(loginState);
    const history = useHistory();
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
    const [response, setReponseData] = useState([]);

    const [isVisible, setIsVisible] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const toast = useContext(ToastContext);
    //state
    const [inviteclientmodal, setInviteClientModal] = useState(false);
    //    Items section
    const items = [
        {
            label: "User Profile",
            icon: "pi pi-user",
            command: () => {
                history.push("./profile");
            },
        },

        {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => {
                handleLogout();
            },
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % response.length);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [response.length]);

    const handleAnnouncment = async () => {
        const dto = {
            page: 1,
            per_page: 10,
            search_text: null,
        };
        try {
            let resp = await getAllAnnoucements(dto);
            setReponseData(resp.data.announcement.data);
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    const handleOpenWindow = (url) => {
        window.open(url, "_blank");
    };

    const handleLogout = async () => {
        try {
            let res = await logoutCall();
            logout();
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const fetchImageUrl = (user) => {
        if (user?.profile_pic) {
            return appUrl.imageBaseUrl + user?.profile_pic;
        } else {
            return DefaultUserImage;
        }
    };
    useEffect(() => {
        handleAnnouncment();
    }, []);

    return (
        <>
            <Dialog
                blockScroll={true}
                onHide={(e) => (isFormDirty ? setIsVisible(true) : setInviteClientModal(false))}
                visible={inviteclientmodal}
                header="Invite Client"
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "40vw" }}
            >
                <CustomBrokerInviteModal
                    setInviteClientModal={setInviteClientModal}
                    onHide={() => setInviteClientModal(false)}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    isFormDirty={isFormDirty}
                    setIsFormDirty={setIsFormDirty}
                />
            </Dialog>
            <div className="layout-topbar">
                <Link className="layout-topbar-logo" to="">
                    <img src={props.layoutColorMode === "light" ? "assets/layout/images/panelimages/Logo.svg" : "assets/layout/images/logo-white.svg"} alt="logo" />
                </Link>

                <Button
                    type="button"
                    tooltip="Expand or Collapse"
                    tooltipOptions={{ position: "right" }}
                    className="p-link  layout-menu-button layout-topbar-button border-0"
                    onClick={props.onToggleMenuClick}
                >
                    <i className="pi pi-bars" />
                    {/* <img src="assets/layout/images/panelimages/menu.png" /> */}
                </Button>

                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>
                {response.length > 0 && (
                    <div className="flex flex-row justify-content-center width-100 pt-3 announcement-bar">
                        <p className="announcement_bar_title ">{response[currentTitleIndex]?.title} - </p>
                        <p className="ml-2 announcement_bar_text">{trimTextWithDots(response[currentTitleIndex]?.announcement_text, 70)}</p>
                        {response[currentTitleIndex]?.hyperlinks && (
                            <p className="announcement_bar_title cursor-pointer ml-1" onClick={() => handleOpenWindow(response[currentTitleIndex]?.hyperlinks)}>
                                {response[currentTitleIndex]?.hyperlink_text ? response[currentTitleIndex]?.hyperlink_text : "See More"}
                            </p>
                        )}
                    </div>
                )}
                <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                    <li>
                        {user?.userRoles[0]["roleName"] == ROLE_NAME.BROKER && (
                            <button className="invite_client_button" onClick={setInviteClientModal}>
                                Invite Client <BsSend className="ml-2" />
                            </button>
                        )}
                    </li>
                    <li style={{ textAlign: "-webkit-center !important" }}>
                        <NotificationMenuComponent />
                    </li>
                    <li className="flex ml-2" onClick={props.onMobileSubTopbarMenuClick}>
                        <button className="p-link layout-topbar-button user-image">
                            <Avatar image={fetchImageUrl()} className="mr-1" size="3rem" shape="circle" />
                            <span>Profile</span>
                        </button>
                        <SplitButton model={items} className="p-button-text custom-button-css"></SplitButton>
                    </li>
                </ul>
            </div>
        </>
    );
};
