import React, { useState, useRef, useEffect, useContext } from "react";

// Prime React
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

// Context
import { ToastContext } from "../../../context/toast";
// Constant
import { DATE_TIME_FORMAT, PROSPECT_DOCUMENT_GRID_HEADERS } from "../../../utils/Constants/global";

// Helper Function
import { previewDocumentHandler } from "../../../utils/HelperFuctions/documnetPreview";

// Component
import PreviewProspectDocumentModal from "../../Documents/Components/PreviewProspectDocumentModal";
// API'S
import { fetchProspectDocuments } from "../../../services/documents";

//styles
import { Dialog } from "primereact/dialog";
import moment from "moment";
import CustomModalSpinner from "../../../ui/CustomModalSpinner/CustomModalSpinner";
import Document from "../../../svgs/Document/Document";

const ViewDocumentModal = (props) => {
    const { selectedRowId } = props;
    // Context
    const toast = useContext(ToastContext);

    // UseRef
    const dt = useRef(null);

    // States
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [previewdocument, setPreviewDocument] = useState(false);
    const [senddocumenturl, setSendDocumentUrl] = useState(null);

    const headerDataBdoy = (colField) => (rowData) => {
        if (colField === "attachment_path") {
            return (
                <>
                    <div className={` mr-2 mt-1 ${rowData.attachment_path ? "document_icon_background" : "no_doc"}`} onClick={() => previewDocumentHandler(rowData.attachment_path)}>
                        {rowData.attachment_path ? <Document /> : "No Attachment"}
                    </div>
                </>
            );
        } else if (colField === "updated_at") {
            return rowData[colField] ? moment(rowData[colField]).format(DATE_TIME_FORMAT) : "";
        } else {
            return rowData[colField] ? rowData[colField] : "";
        }
    };

    const columnComponents = PROSPECT_DOCUMENT_GRID_HEADERS.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={headerDataBdoy(col.field)}></Column>;
    });

    const getProspectDocuments = async () => {
        try {
            const payload = {
                prospect_id: selectedRowId,
            };
            setLoading(true);
            const res = await fetchProspectDocuments(payload);
            if (res) {
                if (res?.data?.prospect_attachments) {
                    setSelectedHeaders(res.data.prospect_attachments);
                    setLoading(false);
                }
            } else {
                toast.showMessage("Error", "Server error.", "error");
                setLoading(false);
            }
        } catch (error) {
            toast.showMessage("Error", "Server error.", "error");
            setLoading(false);
        }
    };

    useEffect(() => {
        getProspectDocuments();
    }, []);

    return (
        <>
            {loading ? (
                <CustomModalSpinner top={"200px"} />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 custom_scrollbar_fieldsetting">
                            <DataTable ref={dt} value={selectedHeaders} responsiveLayout="scroll" emptyMessage="No record available.">
                                {columnComponents}
                            </DataTable>
                        </div>
                    </div>
                    <Dialog
                        blockScroll={true}
                        visible={previewdocument}
                        onHide={() => setPreviewDocument(false)}
                        breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                        style={{ width: "100vw", height: "100vh" }}
                    >
                        <PreviewProspectDocumentModal senddocumenturl={senddocumenturl} />
                    </Dialog>
                </>
            )}
        </>
    );
};

export default ViewDocumentModal;
