import React, { useEffect, useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { getAssignUser } from "../../../services/support";
import SearchInputComponent from "../../../ui/SearchInput/SearchInputComponent";
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
import { saveChat } from "../../../services/chat";
import CustomModalSpinner from "../../../ui/CustomModalSpinner/CustomModalSpinner";
import toTitleCaseWhileTyping, { textExtraSpaceRemoval } from "../../../utils/HelperFuctions/cleanData";
import { InputText } from "primereact/inputtext";
import { loginState } from "../../../redux/login";
import { useSelector } from "react-redux";
import { ROLE_NAME } from "../../../utils/Constants/global";

const ComposeModal = (props) => {
    const { onHide } = props;

    const [options, setOptions] = useState({
        pageNumber: 1,
        size: 10,
        search: "",
        status: null,
        isLogin: true,
    });
    const [users, setUsers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [saveLoading, setSaveLoading] = useState(false);

    const { user } = useSelector(loginState);

    // Handlers
    // const searchHandler = (e) => {
    //     setGlobalFilter(e.target.value);
    //     const temp = e.target.value;
    //     setOptions({ ...options, search: temp });
    // };
    const handleChange = (e) => {
        const temp = textExtraSpaceRemoval(e.target.value);
        const result = toTitleCaseWhileTyping(temp);
        setGlobalFilter(textExtraSpaceRemoval(result));
        setOptions({ ...options, search: result });
    };
    const fetchingValues = async () => {
        setIsLoading(true);
        // Fetching Users
        const res = await getAssignUser(options);

        let filteredRes=res?.data?.records ?? [];
        if (user?.userRoles[0]["roleName"] === ROLE_NAME.CLIENT) {
            filteredRes = filteredRes.filter((record) => record.role_Name === ROLE_NAME.DEBTOR);
        } else if (user?.userRoles[0]["roleName"] === ROLE_NAME.DEBTOR) {
            filteredRes = filteredRes.filter((record) => record.role_Name === ROLE_NAME.CLIENT);
        }
        setUsers(filteredRes);
        setIsLoading(false);
        return;
    };

    const saveMessage = async (message) => {
        if (keyword.trim()) {
            setSaveLoading(true);
            let form = {
                receiver_id: selectedUserId,
                message: keyword,
            };
            let res = await saveChat(form);
            if (res?.status) {
                if (res.data.status) {
                    onHide();
                }
            }
            setSaveLoading(false);
        }
        return;
    };

    // // UseEffects
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchingValues();
        }, 450);
        return () => clearTimeout(delayDebounceFn);
    }, [options]);

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <label>
                        <b>
                            To<span className="clr_red">*</span>
                        </b>
                    </label>
                    <div className="pt-3">
                        <div className="search_input_width">
                            <span className="p-input-icon-right text ">
                                <InputText type="text" value={globalFilter} onChange={handleChange} placeholder="Search by name" className={`p-3 ${"border_class"}`} />
                                <i className="pi pi-search" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 pl-3">
                    {isLoading ? (
                        <CustomModalSpinner top={"50px"} />
                    ) : users.length === 0 ? (
                        <div>No record available</div>
                    ) : (
                        <div className="grid">
                            {users.map((item, index) => {
                                return (
                                    <div key={index} className={`pb-2 assign_ticket_efffect col-6 ${selectedUserId === item.id ? "assign_ticket" : ""} `} onClick={() => setSelectedUserId(item.id)}>
                                        {item.first_Name + " " + (item.last_Name ?? "")}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className="col-12">
                    <div className="compose_message_div">
                        <div className="chat_input_class">
                            <textarea rows="2" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeHolder="Type your message" />
                        </div>
                        <div className="chat_button_position">
                            <button className="chat_send_button" onClick={saveMessage} disabled={saveLoading} id="chat_send" name="chat_send">
                                <BsFillSendFill className="text-2xl" />
                            </button>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ComposeModal;
