import React from "react";

export default function EditIcon(props) {
    const { color, width = "41", height = "40" } = props;
    return (
        <>
            <svg width={width} height={height} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1346_88379)">
                    <path d="M30.21 12.4242C30.4541 12.6683 30.4541 13.064 30.21 13.3081L28.9064 14.6117L26.4064 12.1117L27.71 10.8081C27.9541 10.564 28.3498 10.564 28.5939 10.8081L30.21 12.4242Z" fill="#555960" />
                    <path d="M28.0225 15.4955L25.5225 12.9955L17.0063 21.5118C16.9377 21.5804 16.886 21.664 16.8553 21.7561L15.8496 24.7732C15.7682 25.0175 16.0006 25.2499 16.2449 25.1685L19.262 24.1628C19.354 24.1321 19.4377 24.0804 19.5063 24.0118L28.0225 15.4955Z" fill="#555960" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.083 26.875C12.083 27.9105 12.9225 28.75 13.958 28.75H27.708C28.7435 28.75 29.583 27.9105 29.583 26.875V19.375C29.583 19.0298 29.3032 18.75 28.958 18.75C28.6128 18.75 28.333 19.0298 28.333 19.375V26.875C28.333 27.2202 28.0532 27.5 27.708 27.5H13.958C13.6128 27.5 13.333 27.2202 13.333 26.875V13.125C13.333 12.7798 13.6128 12.5 13.958 12.5H22.083C22.4282 12.5 22.708 12.2202 22.708 11.875C22.708 11.5298 22.4282 11.25 22.083 11.25H13.958C12.9225 11.25 12.083 12.0895 12.083 13.125V26.875Z"
                        fill="#555960"
                    />
                </g>
                <defs>
                    <filter id="filter0_d_1346_88379" x="-3.16699" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1346_88379" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1346_88379" result="shape" />
                    </filter>
                </defs>
            </svg>
        </>
    );
}
