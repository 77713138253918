import React from "react";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import "../CustomInputField/customInputfield.scss";

export default function CustomDropdownField({
    mandatory,
    iden,
    formik,
    className,
    label,
    placeHolder,
    options,
    optionLabel,
    optionValue,
    value,
    disabled = false,
    filter,
    filterBy,
    ...remainingProps
}) {
    function titleCase(str) {
        return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    }
    const isFormFieldValid = (name) => !!(formik?.touched[name] && formik?.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik?.errors[name]}</small>;
    };

    // Handle Change
    const handleChange = (e) => formik?.handleChange(e);
    return (
        <div className={`field ${className} flex flex-column`}>
            <div style={{ width: "100%", marginBottom: ".5rem" }}>
                <label htmlFor="name1">
                    <b> {label || titleCase(iden)}</b>
                    <span className="clr_red">{mandatory}</span>
                </label>
            </div>
            <div className="flex-grow-1 default">
                <Dropdown
                    disabled={disabled}
                    placeholder={placeHolder}
                    id={iden}
                    name={iden}
                    options={options}
                    optionLabel={optionLabel}
                    optionValue={optionValue}
                    value={formik.values[iden]}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    className={classNames("custom_cursor", { "p-invalid": isFormFieldValid({ iden }) })}
                    filter={filter}
                    filterBy={filterBy}
                    {...remainingProps}
                />
            </div>
            {getFormErrorMessage(iden)}
        </div>
    );
}
