import React, { useEffect, useState } from "react";
//styles
import "./settings.scss";
// RRD
import { useHistory } from "react-router-dom"; // import Link from react-router-dom
//components
import CustomLoading from "../../ui/CustomSpinner/custom_spinner";
import SearchInputComponent from "../../ui/SearchInput/SearchInputComponent";
import { useSelector } from "react-redux";

// Constants
import { SETTINGS } from "../../utils/Constants/global";

// Helper
import { validateUserPermissions } from "../../utils/HelperFuctions/validatePermissions";

// Redux
import { loginState } from "../../redux/login";

const SettingsMain = () => {
    const history = useHistory();
    //state
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [settings_card, setSettingCards] = useState([]);

    // Redux
    const { user } = useSelector(loginState);

    //useEffect

    useEffect(() => {
        const _cards = SETTINGS.data.filter((col) => {
            const isAllowed = validateUserPermissions([col.required_rights[0]], user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setSettingCards(_cards);

        setTimeout(() => setLoading(false), 1000); // simulate data loading for 1 second
    }, [user]);

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-12 pt-3 pb-3">
                            <h4>
                                <b>Settings</b>
                            </h4>
                        </div>
                    </div>
                    <div className="card">
                        <div className="grid">
                            <div className="md:col-9 pt-3 pb-3"></div>
                            <div className="col-12 md:col-3 pt-3 pb-3">
                                <SearchInputComponent placeholder="Search by settings name" handleInput={(e) => setGlobalFilter(e.target.value)} />
                            </div>

                            {settings_card.map((card, index, arr) => {
                                return (
                                    <div className="col-12 md:col-3 text-center" onClick={() => history.push(card.route)}>
                                        <div className="setting_card setting_card_alignment">
                                            <div className="">
                                                <span className="report_icon_style report_icon_bg">{card.icon}</span>
                                            </div>
                                            <div className="report_icon_style">{card.title}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default SettingsMain;
