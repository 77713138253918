import React, { useState } from "react";

//component
import { Dialog } from "primereact/dialog";
import AddNewBankAccount from "../Components/AddNewBankAccount";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";

const Header = () => {
    //state
    const [showAddEditDialog, setAddEditDialog] = useState(false);

    //Properties
    const dialogFuncMap = {
        showAddEditDialog: setAddEditDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    return (
        <>
            <Dialog
                blockScroll={true}
                header="Add New Bank Account"
                visible={showAddEditDialog}
                onHide={() => onHide("showAddEditDialog")}
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
            >
                <AddNewBankAccount onHide={() => onHide("showAddEditDialog")} />
            </Dialog>

            {/* Dialouge Section */}

            <div className="grid text-right">
                <div className="md:col-12 ">
                    <CustomAddNewButton
                        title="Add New"
                        onClick={() => {
                            onClick("showAddEditDialog");
                        }}
                    ></CustomAddNewButton>
                </div>
            </div>
        </>
    );
};

export default Header;
