import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

// Prime React
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";

// Icons
import ViewEye from "../../../../svgs/Document/ViewEye";

// UI Components
import { PaginatorTemplate } from "../../../../ui/PaginatorTemplate/PaginatorTemplate";
import ViewDocumentModal from "../../Components/ViewDocumentModal";
import CustomLoading from "../../../../ui/CustomSpinner/custom_spinner";

// Redux
import { loginState } from "../../../../redux/login";

// Constant
import { DOCUMENT_RIGHTS } from "../../../../utils/Constants/rights";

// Helper Function
import { validateUserPermissions } from "../../../../utils/HelperFuctions/validatePermissions";

// Components
const Body = (props) => {
    const {
        selectedHeaders,
        selectedColumns,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        sortCount,
        setSortCount,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        pagination,
        loading,
        setPage,
        page,
    } = props;

    //Use Ref
    const dt = useRef(null);

    // States
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [showAddEditDialog, setAddEditDialog] = useState(false);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    // Redux
    const { user } = useSelector(loginState);

    //Handlers
    // Grid Sorting
    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const viewHandler = (id) => {
        setSelectedRowId(id);
        setAddEditDialog(true);
    };

    const headerDataBdoy = (colField) => (rowData) => {
        if (colField === "prospect_id") {
            return (
                <>
                    <div className="Edit_Icon">
                        {validateUserPermissions([DOCUMENT_RIGHTS.document_selection], user?.rights) && (
                            <Button tooltip="View" tooltipOptions={{ position: "top" }} icon={<ViewEye />} className="icon_button" onClick={() => viewHandler(rowData.prospect_id)} />
                        )}
                    </div>
                </>
            );
        } else {
            return rowData[colField] ? rowData[colField] : "";
        }
    };

    const columnComponents = selectedColumns.map((col) => {
        if (col?.field === "client_name") {
            return (
                <Column key={col.field} field={col.field} header={col.header} body={headerDataBdoy(col.field)} sortable>
                    {renderSortIcon(col.field)}
                </Column>
            );
        } else {
            if (col?.header === "Action" && !validateUserPermissions([DOCUMENT_RIGHTS.document_selection], user?.rights)) return <></>;
            return <Column key={col.field} field={col.field} header={col.header} body={headerDataBdoy(col.field)}></Column>;
        }
    });

    // Paginations
    const onCustomPage = (event) => {
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <div className="col-12 pr-0">
                    <div className="card p-1 m-0">
                        <DataTable
                            ref={dt}
                            value={selectedHeaders}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginatorTemplate={PaginatorTemplate(
                                pagination.isFirstPage,
                                pagination.isLastPage,
                                currentPage,
                                pagination.totalPages,
                                pageSize,
                                page,
                                pagination.totalRecords,
                                pageInputTooltip,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                setPageInputTooltip
                            )}
                            rows={pageSize}
                            paginator
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            selectionMode="single" // Add selectionMode prop
                        >
                            {columnComponents}
                        </DataTable>
                    </div>
                </div>
            )}
            {validateUserPermissions([DOCUMENT_RIGHTS.document_selection], user?.rights) && (
                <Dialog
                    blockScroll={true}
                    header="View Document List"
                    visible={showAddEditDialog}
                    onHide={() => setAddEditDialog(false)}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw", height: "50vh" }}
                >
                    <ViewDocumentModal selectedRowId={selectedRowId} />
                </Dialog>
            )}
        </>
    );
};

export default Body;
