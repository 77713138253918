import React from "react";
// component
import { Chart } from "primereact/chart";
//style
import { currencyFormatUSD } from "../../../utils/HelperFuctions/checkAmount";
const AvgDebtBarGraph = (props) => {
    const { debtorsData } = props;

    const avgDebtBarGraphLabels = [];
    const avgDebtBarGraphPaidAmount = [];
    const avgDebtBarGraphDebtAmount = [];

    Object.values(debtorsData).forEach(function (debtor) {
        avgDebtBarGraphLabels.push(debtor.name);
        avgDebtBarGraphPaidAmount.push(debtor.total_paid_amount);
        avgDebtBarGraphDebtAmount.push(debtor.total_debt);
    });

    const basicData = {
        labels: avgDebtBarGraphLabels,
        datasets: [
            {
                label: "Paid Amount",
                backgroundColor: "#42A5F5",
                data: avgDebtBarGraphPaidAmount,
            },
            {
                label: "Debt Amount",
                backgroundColor: "#FFA726",
                data: avgDebtBarGraphDebtAmount,
            },
        ],
    };
    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context) =>
                            context.datasetIndex === 1 ? currencyFormatUSD(avgDebtBarGraphDebtAmount[context.dataIndex]) : currencyFormatUSD(avgDebtBarGraphPaidAmount[context.dataIndex]), // Display custom tooltip labels
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                        display: false,
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                        callback: (value) => `$${value.toFixed(2)}`, // Adding currency symbol
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
            animation: {
                duration: 0,
            },
        };
        return {
            basicOptions,
        };
    };
    const { basicOptions } = getLightTheme();
    return (
        <>
            <div className="col-12 lg:col-6">
                <div className="card">
                    <h5 className="p-mx-0 p-my-1 pt-2">
                        <b>Debt vs. Paid Amount by Debtor</b>
                    </h5>
                    <Chart type="bar" data={basicData} options={basicOptions} />
                </div>
            </div>
        </>
    );
};

export default AvgDebtBarGraph;
