import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomAddNewButton from "../../../../../ui/CustomAddNewButton/CustomAddNewButton";

const DebtorPocModal = (props) => {
    const { onHide, pocs, replicatePocs } = props;
    const [selectedPocs, setSelectedPocs] = useState([]);

    const onPocSelectionChange = (event) => {
        setSelectedPocs(event.value);
    };

    const replicate = () => {
        replicatePocs(selectedPocs);
    };

    const createNew = () => {
        replicatePocs([]);
        onHide();
    };

    return (
        <>
            <div className="card">
                <DataTable value={pocs} selection={selectedPocs.length === 0 ? null : selectedPocs} onSelectionChange={onPocSelectionChange} dataKey="id" responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle={{ width: "3em" }}></Column>
                    <Column field="first_name" header="Name"></Column>
                    <Column field="email" header="Email"></Column>
                </DataTable>
                <div class="action-btns-prospect-form pt-6">
                    <CustomAddNewButton title="Replicate" type="button" isDisabled={selectedPocs.length <= 0} onClick={replicate}/>
                    <CustomAddNewButton title="Create New" type="button" onClick={createNew}/>
                </div>
            </div>
        </>
    );
};

export default DebtorPocModal;
