export const countryCodeSearchHandler = (event) => {
    let phone_code = event.target.value;
    let regex = /^\+?[0-9]*$/;
    if (!regex.test(phone_code)) {
        event.stopPropagation();
    }
};

export const zipCodeSearchHandler = (event) => {
    let phone_code = event.target.value;
    let regex = /^\d{0,5}$/;
    if (!regex.test(phone_code)) {
        event.stopPropagation();
    }
};
