import React, { useContext, useEffect, useState } from "react";

// Prime React
import { Checkbox } from "primereact/checkbox";

// UI Components

// Components
import SearchInputComponent from "../../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../../../../ui/KebabMenu/KebabMenuComponent";
import PostPaymentModal from "../../Components/PostPaymentModal";
import RevertScheduleModal from "../../Components/RevertScheduleModal";
import ShowPaymentModal from "../../Components/ShowPaymentModal";
import { ROLE_NAME, SCHEDULES_GRID_HEADERS } from "../../../../../../../utils/Constants/global";
import { Dialog } from "primereact/dialog";
import { ToastContext } from "../../../../../../../context/toast";

import axios from "axios";
import { BsCheckLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import { loginState } from "../../../../../../../redux/login";
import { TRANSACTION_RIGHTS } from "../../../../../../../utils/Constants/rights";
import { validateUserPermissions } from "../../../../../../../utils/HelperFuctions/validatePermissions";
import CustomConfirmDialog from "../../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import Legends from "../../../../../../../ui/Legends/Legends";
import { scheduleFundedReversal } from "../../../../../../../services/schedule-assignment";

// Constants

const FilterBar = (props) => {
    const { setShowAll, showAll, setKeyword, keyword, setSelectedColumns, selectedSchedules, setSelectedSchedules, setKebabMenuOption, kebabMenuOption, getGridData, setLoading } = props;
    const [onSelectionColumn] = useState([]);
    const [kebab_menu_items, setKebabMenuItems] = useState([]);
    const [reversedScheduleDialog, setReversedScheduleDialog] = useState(false);

    // Constants
    const SCHEDULE_OF_FUNDING_MENU = [
        {
            title: "Post Payment",
            status: 1,
            isDisabled: (selectedSchedules.length > 0 && selectedSchedules[0].remaining_amount === 0) || selectedSchedules.length !== 1,
            required_rights: [TRANSACTION_RIGHTS.schedule_of_funding.post_payment],
        },

        { title: "Show Payment", status: 2, isDisabled: selectedSchedules.length !== 1, required_rights: [TRANSACTION_RIGHTS.schedule_of_funding.show_payment] },

        {
            title: "Revert Verification",
            status: 3,
            isDisabled: (selectedSchedules.length > 0 && selectedSchedules[0].schedule_fund_date !== null) || selectedSchedules.length !== 1 || selectedSchedules[0].cb_amount > 0,
            required_rights: [TRANSACTION_RIGHTS.schedule_of_funding.revert_verification],
        },
        {
            title: "Revert Funding",
            status: 4,
            isDisabled: (selectedSchedules.length > 0 && selectedSchedules[0].schedule_fund_date === null) || selectedSchedules.length !== 1 || selectedSchedules[0].cb_amount > 0,
            required_rights: [TRANSACTION_RIGHTS.schedule_of_funding.revert_verification],
        },
    ];

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [formikIsDirty, setFormikIsDirty] = useState(false);
    //Kebab Menu Items

    // Base Url
    let BASE_URL = process.env.REACT_APP_BASE_URL;

    //toast message
    const toast = useContext(ToastContext);

    // Redux
    const { user } = useSelector(loginState);

    //Handlers
    const handleOpenMenuItems = (status) => {
        if (status === 1) {
            setKebabMenuOption({
                post_payment: true,
            });
        }

        if (status === 2) {
            setKebabMenuOption({
                show_payment: true,
            });
        }

        if (status == 4) {
            setReversedScheduleDialog(true);
        }

        if (status === 3) {
            setKebabMenuOption({
                revert_schedule: true,
            });
        }
    };

    //Download Schedules

    const handleDownloadSchedule = async () => {
        setLoading(true);
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await axios.post(
                `${BASE_URL}schedule/pending/download`,
                { id: selectedSchedules[0].id },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: "arraybuffer",
                }
            );
            if (response) {
                const pdfData = new Uint8Array(response.data);
                const blob = new Blob([pdfData], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        setSelectedSchedules([]);
        setLoading(false);
    };

    const handleScheduleFundedReversal = async () => {
        setReversedScheduleDialog(false);
        try {
            let payload = {
                schedule_id: selectedSchedules[0]?.id,
            };
            const res = await scheduleFundedReversal(payload);
            if (res.data.status) {
                toast.showMessage("Success", "Funded Schedule has been reversed successfully.", "success");
                setSelectedSchedules([]);
                getGridData();
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };
    useEffect(() => {
        SCHEDULES_GRID_HEADERS.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    }, [user]);

    useEffect(() => {
        // Kebab Items
        const _kebab_menu_items = SCHEDULE_OF_FUNDING_MENU.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setKebabMenuItems(_kebab_menu_items);
    }, [user, selectedSchedules.length]);

    return (
        <>
            <div className="grid">
                <div className="col-12 md:col-10">
                    <h4 className="m-0">
                        <b>Schedule of Funding</b>
                    </h4>
                </div>

                <div className="md:col-7 col-12">
                    {user?.userRoles[0]?.roleName !== ROLE_NAME.CLIENT ? (
                        <div className="flex mt-4">
                            <Checkbox inputId="showall" checked={showAll === 1 ? true : false} onChange={(e) => setShowAll(e.checked ? 1 : 0)} />
                            <label htmlFor="showall" className="mt-1 ml-2">
                                Include terminated schedule
                            </label>
                            {showAll === 1 && (
                                <>
                                    <div className="mt-1 ml-3">
                                        <Legends classes="square-legend-delete" title="Deleted" />
                                    </div>
                                    <div className="mt-1 ml-2">
                                        <Legends classes="square-legend-terminate" title="Terminated" />
                                    </div>
                                </>
                            )}
                        </div>
                    ) : null}
                </div>

                <div className="md:col-5 col-12 pt-3">
                    <div className="flex flex-row">
                        <SearchInputComponent
                            type="text"
                            value={keyword}
                            setKeyword={setKeyword}
                            // handleInput={(e) => setKeyword(e.target.value)}
                            placeholder="Search by client, schedule no & net funding"
                        />
                        <div className="ml-2">
                            <ColumnFilterComponent items={SCHEDULES_GRID_HEADERS} handleColumnFilter={(e) => setSelectedColumns(e)} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className={`ml-2 ${true ? "no-drop" : ""}`}>
                            {/* handleMenuOpen={handleOpenMenuItems} */}
                            {kebab_menu_items.length > 0 && <KebabMenuComponent items={kebab_menu_items} handleMenuOpen={handleOpenMenuItems} />}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                className="detail_funding"
                header="Revert Schedule"
                visible={kebabMenuOption.revert_schedule}
                fullScreen
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "30vw" }}
                onHide={() => {
                    setKebabMenuOption({
                        revert_schedule: false,
                    });
                    setSelectedSchedules([]);
                }}
            >
                <RevertScheduleModal
                    onHide={() => {
                        setKebabMenuOption({
                            revert_schedule: false,
                        });
                        setSelectedSchedules([]);
                    }}
                    getGridData={getGridData}
                    selectedSchedules={selectedSchedules}
                />
            </Dialog>

            <Dialog
                className="detail_funding"
                header="Post Payment"
                visible={kebabMenuOption.post_payment}
                fullScreen
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw" }}
                onHide={() => {
                    if (formikIsDirty) {
                        setIsVisible(true);
                    } else {
                        setKebabMenuOption({
                            post_payment: false,
                        });

                        setIsFormDirty(false);
                        setFormikIsDirty(false);
                    }

                    setSelectedSchedules([]);
                }}
            >
                <PostPaymentModal
                    onHide={() => {
                        setKebabMenuOption({
                            post_payment: false,
                        });
                        setSelectedSchedules([]);
                        setIsFormDirty(false);
                    }}
                    setFormikIsDirty={setFormikIsDirty}
                    isVisible={isVisible}
                    isFormDirty={isFormDirty}
                    setIsFormDirty={setIsFormDirty}
                    getGridData={getGridData}
                    selectedSchedules={selectedSchedules}
                />
            </Dialog>

            <Dialog
                className="detail_funding"
                header="Payments"
                visible={kebabMenuOption.show_payment}
                fullScreen
                breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                style={{ width: "50vw", height: "30vh" }}
                onHide={() => {
                    setKebabMenuOption({
                        show_payment: false,
                    });
                    setSelectedSchedules([]);
                }}
            >
                <ShowPaymentModal
                    onHide={() => {
                        setKebabMenuOption({
                            show_payment: false,
                        });
                        setSelectedSchedules([]);
                    }}
                    selectedSchedules={selectedSchedules}
                />
            </Dialog>

            {isVisible && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsVisible(false)}
                    handler={() => {
                        setKebabMenuOption({
                            post_payment: false,
                        });
                        setSelectedSchedules([]);
                        setIsVisible(false);
                    }}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}

            {reversedScheduleDialog && (
                <CustomConfirmDialog
                    header="Funded Schedule Reversal"
                    type="error"
                    onHide={() => setReversedScheduleDialog(false)}
                    handler={() => handleScheduleFundedReversal()}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to reverse the funded schedule?"
                />
            )}
        </>
    );
};

export default FilterBar;
