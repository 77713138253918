import React, { useState, useRef, useEffect, useContext } from "react";
//Component

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ToastContext } from "../../../../../../context/toast";
import { getReserveDetail } from "../../../../../../services/reserve";
import { currencyFormatUSD } from "../../../../../../utils/HelperFuctions/checkAmount";
import { RESERVE_RELEASE_BREAKDOWN_GRID_HEADERS } from "../../../../../../utils/Constants/global";
import { getCashReserveColumnBody } from "../../../../../../utils/HelperFuctions/invoiceHelper";
import classNames from "classnames";

const ReserveReleaseDetail = ({ onHide, reserveId }) => {
    const dt = useRef(null);
    const toast = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [rr, setRR] = useState({});

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const renderSortIcon = (field) => {
        if (sortField === field) {
            return (
                <i
                    className={classNames("pi pi-fw", {
                        "pi-sort-alt": sortOrder === null,
                        "pi-sort-asc": sortOrder === 1,
                        "pi-sort-desc": sortOrder === -1,
                    })}
                />
            );
        }
        return null;
    };

    const columnComponents = RESERVE_RELEASE_BREAKDOWN_GRID_HEADERS.map((col) => {
        return (
            <Column key={col.field} field={col.field} header={col.header} body={getCashReserveColumnBody(col.field)} sortable
                headerClassName={`${col.field === "invoice_amount" || col.field === "reserve_released_amount" ? "amount_column_alignment" : ""}`}>
                {renderSortIcon(col.field)}
            </Column>
        );
    });

    const fetchReserveDetail = async () => {
        setLoading(true);
        try {
            let res = await getReserveDetail(reserveId);
            if (res?.data?.status) {
                setInvoices(res.data.invoices);
                setRR(res.data.rr);
            } else {
                toast.showMessage("Info", "Sorry, we are unable to process your request at this time.", "error");
            }
            setLoading(false);
        } catch (e) {
            toast.showMessage("Info", "Sorry, we are unable to process your request at this time.", "error");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (reserveId > 0) {
            fetchReserveDetail();
        }
    }, [reserveId]);

    return (
        <>
            {/* {loading ? 
            (
                <CustomModalSpinner top={"50px"} />
            ) : ( */}
            <div className="grid">
                <div className="col-12 pt-3">
                    <DataTable ref={dt} value={invoices} emptyMessage="No record available." onSort={onSort} sortable sortField={sortField} sortOrder={sortOrder}>
                        {columnComponents}
                    </DataTable>
                </div>

                <div className="col-12 md:col-3 mt-3">
                    <label>
                        <b>Invoice Reserves</b>
                    </label>

                    <p className="pt-2">
                        <span>{rr?.amount ? currencyFormatUSD(rr.amount) : currencyFormatUSD(0)}</span>
                    </p>
                </div>
                <div className="col-12 md:col-3 mt-3">
                    <label>
                        <b>Wire Fee</b>
                    </label>

                    <p className="pt-2">
                        <span>{rr?.wire_fee ? currencyFormatUSD(rr.wire_fee) : currencyFormatUSD(0)}</span>
                    </p>
                </div>
                {/* <div className="col-12 md:col-3 mt-3">
                    <label>
                        <b>Escrows Released</b>
                    </label>

                    <p className="pt-2">
                        <span>{rr?.escrow_amount ? currencyFormatUSD(rr.escrow_amount) : currencyFormatUSD(0)}</span>
                    </p>
                </div> */}
                <div className="col-12 md:col-3 mt-3">
                    <label>
                        <b>Custom Amount Released from Reserves</b>
                    </label>

                    <p className="pt-2">
                        <span>{rr?.custom_amount ? currencyFormatUSD(rr.custom_amount) : currencyFormatUSD(0)}</span>
                    </p>
                </div>
                <div className="col-12 md:col-3 mt-3">
                    <label>
                        <b>Chargeback amount adjusted</b>
                    </label>

                    <p className="pt-2">
                        <span>{rr?.cb_amount ? currencyFormatUSD(rr.cb_amount) : currencyFormatUSD(0)}</span>
                    </p>
                </div>
                <div className="col-12 md:col-3 mt-3">
                    <label>
                        <b>Reserves Released</b>
                    </label>

                    <p className="pt-2">
                        <span>{rr?.released_amount ? currencyFormatUSD(rr.released_amount) : currencyFormatUSD(0)}</span>
                    </p>
                </div>
            </div>
            {/* )} */}
        </>
    );
};

export default ReserveReleaseDetail;
