import React, { useState, useRef, useEffect, useContext } from "react";
//context
import { ToastContext } from "../../../../../../context/toast";
//services
import { deleteAnnoucement, getAllAnnoucements } from "../../../../../../services/annoucements";
//ui-components
import SearchInputComponent from "../../../../../../ui/SearchInput/SearchInputComponent";
import ColumnFilterComponent from "../../../../../../ui/ColumnFilter/ColumnFilterComponent";
import KebabMenuComponent from "../../../../../../ui/KebabMenu/KebabMenuComponent";
import CustomLoading from "../../../../../../ui/CustomSpinner/custom_spinner";
import CustomConfirmDialog from "../../../../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import { PaginatorTemplate } from "../../../../../../ui/PaginatorTemplate/PaginatorTemplate";
import UserHeader from "./Header/header";
//components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//react icons
import { BsCheckLg } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { getClientUsers, resendInvite } from "../../../../../../services/client";
import { CLIENT_USERS_KEBAB_MENU_ITEMS, getContactMask } from "../../../../../../utils/Constants/global";
import { fetchOwners } from "../../../../../../services/term-sheet";
import moment from "moment";
import { loginState } from "../../../../../../redux/login";
import { useSelector } from "react-redux";
import { validateUserPermissions } from "../../../../../../utils/HelperFuctions/validatePermissions";
import { MANAGMENT_RIGHTS } from "../../../../../../utils/Constants/rights";
import { TRUE } from "sass";
import { setUserStatus } from "../../../../../../services/user-management/user";

const UsersIndex = (props) => {
    const { debtorIds, ownerIds, id } = props;
    const toast = useContext(ToastContext);

    const columns = [
        { field: "full_name", header: "Full Name" },
        { field: "email", header: "Email" },
        { field: "username", header: "Username" },
        { field: "phone_number", header: "Phone No" },
        { field: "is_active", header: "Status" },
        { field: "role_type", header: "Role" },
    ];
    //kebab Menu Items
    const [kebabMenuItems, setKebabMenuItems] = useState([]);

    const dt = useRef(null);
    //states

    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [onSelectionColumn] = useState([]);
    const [statusChangeDialog, setStatusChangeDialog] = useState(false);
    const [resendInvitePayload, setResendInvitePayload] = useState("");
    const [updateClientUser, setUpdateClientUser] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [owners, setOwners] = useState([]);
    const [ownersForDropdown, setOwnersForDropdown] = useState([]);
    const [ownerLoading, setOwnerLoading] = useState(false);
    const [showInviteButton, setShowInviteButton] = useState(false);
    const [isDisabledCheck, setIsDisabledCheck] = useState(false);

    /////////////////////////////////////// PAGINATION REQUIRED DATA START ////////////////////////////////////////////////////
    const [showAll, setShowAll] = useState(false);
    const [first, setFirst] = useState(0);
    const [responseData, setReponseData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortCount, setSortCount] = useState(null);
    const [pagination, setPagination] = useState({
        totalRecords: 0,
        isFirstPage: 1,
        isLastPage: 0,
        totalPages: 0,
    });

    const [page, setPage] = useState(currentPage);
    const [pageInputTooltip, setPageInputTooltip] = useState("Press 'Enter' key to go to this page.");

    const { user } = useSelector(loginState);

    const prevPage = () => {
        if (!responseData.isFirstPage) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (!responseData.isLastPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onSort = (e) => {
        if (e.sortField === sortField) {
            if (sortCount === 1) {
                setSortCount(0);
                setSortField(null);
                setSortOrder(null);
            } else {
                setSortCount(sortCount + 1);
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
            }
        } else {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
        }
    };

    const onPageInputKeyDown = (event, options) => {
        if (event.key === "Enter") {
            const _page = parseInt(page);
            if (_page <= 0 || _page > responseData.total) {
                setPage(currentPage);
                setPageInputTooltip(`Value must be between 1 and ${responseData.total}.`);
            } else {
                setCurrentPage(_page);
                setPageInputTooltip("Press 'Enter' key to go to this page.");
            }
        }
    };

    const onCustomPage = (event) => {
        setFirst(event.first);
        setPageSize(event.rows);
        setCurrentPage(event.page + 1);
    };

    const onPageInputChange = (event) => {
        setPage(event.target.value);
    };
    /////////////////////////////////////// PAGINATION REQUIRED DATA END ////////////////////////////////////////////////////
    const handleColumn = (data) => {
        setSelectedColumns(data);
    };

    const getColumnBody = (colField) => (rowData) => {
        if (colField === "full_name") {
            return (rowData.last_name != null ? rowData.last_name : "") + " " + (rowData.first_name ? rowData.first_name : "");
        } else if (colField === "is_active") {
            if (!rowData.password) {
                return "Pending";
            } else {
                return rowData.is_active == true ? "Active" : "Inactive";
            }
        } else if (colField === "phone_number") {
            let phone_mask = getContactMask(rowData.country_phone_code, rowData.phone_number);
            return phone_mask;
        }
        return rowData[colField] ? rowData[colField] : "";
    };

    const columnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={getColumnBody(col.field)} sortable={col.sortable} />;
    });

    const handleOpenMenuItems = (status) => {
        if (status === 1) {
            setStatusChangeDialog(true);
        }
        if (status === 2 && selectedUser && selectedUser.length === 1) {
            setResendInvitePayload({ id: selectedUser[0].role_type_id });
        }
    };

    const getProspectUsers = async (page) => {
        setLoading(true);
        const dto = {
            pageNumber: page ? page : currentPage,
            size: pageSize,
            search: search,
            status: true,
            isLogin: false,
            prospect_id: id,
            role_type_id: ownerIds.concat(debtorIds),
            sort_by: "id",
            sort_desc: "desc",
            showAll: showAll,
        };

        if (sortField) {
            dto["sort_by"] = sortField;
        }
        if (sortOrder) {
            dto["sort_desc"] = sortOrder === 1 ? "asc" : "desc";
        }

        let res = await getClientUsers(dto);

        if (res.data.status) {
            setReponseData(res.data.users);
            setPagination({
                ...pagination,
                totalRecords: res.data.users.total,
                totalPages: res.data.users.last_page,
                isLastPage: res.data.users.last_page,
            });
        }

        setLoading(false);
        setIsLoaded(true);
    };

    const getOwners = async () => {
        setOwnerLoading(true);
        let form = {
            prospect_id: id,
        };
        let res = await fetchOwners(form);
        let _owners = [];
        if (res?.data.status) {
            let resp = res.data.owners;

            setShowInviteButton(false);
            resp.forEach((owner) => {
                if (!owner.invite_send_date || owner.invite_send_date == null) {
                    setShowInviteButton(true);
                    _owners.push(owner);
                }
            });

            setOwnersForDropdown(_owners);
            setOwners(resp);
        }
        setOwnerLoading(false);

        return;
    };

    const handleSelectionChange = (event) => {
        // Handle checkbox selection functionality
        if (event.type === "checkbox" || event.type === "all") {
            setSelectedUser(event.value);

            if (event.value) {
                setKebabMenuItems([
                    { title: "Change Status", status: 1, required_rights: [MANAGMENT_RIGHTS.clients.change_status], isDisabled: false },
                    { title: "Resend Invite", status: 2, required_rights: [MANAGMENT_RIGHTS.clients.resend_invite], isDisabled: false },
                ]);
            }
        }
    };

    let handleResendInvite = () => {
        let isDisabled = false;
        if (selectedUser && selectedUser.length === 1) {
            const selectedRoleType = selectedUser[0]?.role_type;
            isDisabled = selectedRoleType && selectedRoleType == "Debtor";
        }

        let owner = [];
        if (owners && owners.length > 0 && selectedUser[0]?.role_type_id) {
            owner = owners.find((row) => row["value"] === selectedUser[0]?.role_type_id);

            if (owner) {
                let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
                let InviteSendDate = moment(owner.invite_send_date, "YYYY-MM-DD HH:mm:ss").add(1, "day");
                let is_before = !owner.invite_send_date ? true : InviteSendDate.isBefore(currentDateTime);
                isDisabled = selectedUser[0]?.password ? true : !is_before;
            }
        }

        setIsDisabledCheck(isDisabled);
    };

    const handleOptionClick = () => {
        resendInviteHandler(resendInvitePayload);
    };

    const resendInviteHandler = async (payload) => {
        try {
            setIsSaving(true);
            const res = await resendInvite(payload);
            if (res) {
                if (res.data.status) {
                    setIsSaving(false);
                    setResendInvitePayload("");
                    toast.showMessage("Invite Debtor", res.data.message, "success");
                } else {
                    setIsSaving(false);
                    setResendInvitePayload("");
                    toast.showMessage("Error", res.data.message, "error");
                }

                setUpdateClientUser(true);
            } else {
                setIsSaving(false);
                setResendInvitePayload("");
                toast.showMessage("Error", "Server error.", "error");
            }
        } catch (e) {
            setIsSaving(false);
            setResendInvitePayload("");
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    const changeUserStatus = async () => {
        try {
            setIsSaving(true);
            let data = selectedUser[0];
            let resp = await setUserStatus({
                sso_id: data.sso_id,
                status: !data.is_active,
            });
            setSelectedUser([]);
            if (resp.data.status) {
                toast.updateToast("User Status");
                setUpdateClientUser(true);
            } else {
                toast.showMessage("Error", resp.data.message, "error");
            }
            setStatusChangeDialog(false);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
            setStatusChangeDialog(false);
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
    };

    //useEffect
    useEffect(() => {
        columns.filter((col) => {
            onSelectionColumn.push(col.field);
        });
    }, []);

    useEffect(() => {
        setKebabMenuItems([
            { title: "Change Status", status: 1, required_rights: [MANAGMENT_RIGHTS.clients.change_status], isDisabled: false },
            { title: "Resend Invite", status: 2, required_rights: [MANAGMENT_RIGHTS.clients.resend_invite], isDisabled: isDisabledCheck ? isDisabledCheck : false },
        ]);
    }, [isDisabledCheck]);

    useEffect(() => {
        getProspectUsers();
    }, [updateClientUser == true]);

    useEffect(() => {
        if (search != null) {
            const delayDebounceFn = setTimeout(() => {
                getProspectUsers();
            }, 250);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [search]);

    useEffect(() => {
        setSelectedUser([]);
        setUpdateClientUser(false);
        getOwners();
    }, [updateClientUser == true]);

    useEffect(() => {
        setIsDisabledCheck(false);
        if (selectedUser.length == 1) handleResendInvite(selectedUser, owners);
    }, [selectedUser.length]);

    useEffect(() => {
        // Kebab
        const _kebab_menu_items = kebabMenuItems.filter((col) => {
            const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

            if (isAllowed) {
                return col;
            }
        });

        setKebabMenuItems(_kebab_menu_items);
    }, [user, selectedUser]);

    return (
        <>
            <div className="grid client_user pl-1 pr-1">
                <div className="md:col-6 col-12"></div>
                <div className="md:col-6 col-12 pt-4">
                    <div className="flex flex-row">
                        <SearchInputComponent value={search} setKeyword={setSearch} placeholder="Search by full name, email & phone" />
                        <div className="ml-2">
                            <ColumnFilterComponent items={columns} handleColumnFilter={handleColumn} selectedColumn={onSelectionColumn} />
                        </div>
                        <div className="ml-2">
                            <KebabMenuComponent items={kebabMenuItems} handleMenuOpen={handleOpenMenuItems} isDisabled={selectedUser.length == 1 ? false : true} />
                        </div>
                        <div className="ml-2">
                            <UserHeader
                                getProspectUsers={getProspectUsers}
                                ownersForDropdown={ownersForDropdown}
                                ownerLoading={ownerLoading}
                                showInviteButton={showInviteButton}
                                setShowInviteButton={setShowInviteButton}
                                setUpdateClientUser={setUpdateClientUser}
                                id={id}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-3">
                    {loading ? (
                        <CustomLoading />
                    ) : (
                        <DataTable
                            ref={dt}
                            value={responseData?.data}
                            selection={selectedUser.length === 0 ? null : selectedUser}
                            onSelectionChange={handleSelectionChange} // for checkbox
                            // onRowSelect={handleRowSelect} // Add onRowSelect event handler
                            rows={pageSize}
                            onSort={onSort}
                            sortable
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onPage={onCustomPage}
                            paginator
                            paginatorTemplate={PaginatorTemplate(
                                currentPage == 1 ? true : false,
                                currentPage == responseData.isLastPage ? true : false,
                                currentPage,
                                responseData.isLastPage,
                                pageSize,
                                page,
                                responseData.total,
                                pageInputTooltip,
                                prevPage,
                                nextPage,
                                setCurrentPage,
                                setPage,
                                setPageSize,
                                onPageInputKeyDown,
                                onPageInputChange
                            )}
                            first={first}
                            responsiveLayout="scroll"
                            emptyMessage="No record available."
                            page={page}
                        >
                            <Column selectionMode="multiple"></Column>
                            {columnComponents}
                        </DataTable>
                    )}
                </div>
            </div>

            {statusChangeDialog && (
                <CustomConfirmDialog
                    isDisabled={isSaving}
                    header="Change Status"
                    type="error"
                    onHide={() => setStatusChangeDialog(false)}
                    handler={changeUserStatus}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to change status?"
                />
            )}

            {resendInvitePayload && (
                <CustomConfirmDialog
                    isDisabled={isSaving}
                    header="Resend Invite"
                    type="error"
                    handler={handleOptionClick}
                    onHide={() => setResendInvitePayload("")}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to resend invite?"
                />
            )}
        </>
    );
};

export default UsersIndex;
