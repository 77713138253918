import React, { useContext } from "react";

//components
import CustomAddNewButton from "../../../../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomCancelbtn from "../../../../../../ui/CustomCancelbtn/CustomCancelbtn";
import { adjustHoldAccount, adjustHoldAccountInCollection } from "../../../../../../services/invoice";
import { ToastContext } from "../../../../../../context/toast";

const AccountAdjustmentModal = (props) => {
    const { onHide, selectedInvoices, fetchInvoices, type } = props;

    //toast message
    const toast = useContext(ToastContext);

    const revertSchedule = async () => {
        onHide();
        let payload = {
            id: selectedInvoices.map((invoice) => invoice.id),
            hold_account_status: true
        };
        try {
            const res = await adjustHoldAccount(payload);
            if (res.data.status) {
                toast.showMessage("Success", "Unapplied Cash has been adjusted", "success");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        fetchInvoices();
    };

    const adjustInCollection = async () => {
        onHide();
        let payload = {
            id: selectedInvoices.map((invoice) => invoice.id),
            hold_account_collection_status: true,
        };
        try {
            const res = await adjustHoldAccountInCollection(payload);
            if (res.data.status) {
                toast.showMessage("Success", "Unapplied Cash has been adjusted", "success");
            }
        } catch (e) {
            toast.showMessage("Error", "Sorry, we are unable to process your request at this time.", "error");
        }
        fetchInvoices();
    }

    
    return (
        <>
            <div className="verify_schedule">
                <div className="text-center ">
                    <p>Are you sure you want to adjust Unapplied Cash</p>
                </div>
                <div className="flex justify-content-center pt-4">
                    <CustomCancelbtn onClick={onHide} title="Cancel" />
                    <CustomAddNewButton title="Yes" className="ml-2" onClick={() => type === "cash_reserves" ? revertSchedule() : adjustInCollection()} />
                </div>
            </div>
        </>
    );
};
export default AccountAdjustmentModal;
