import React, { useState, useRef, useContext, useEffect } from "react";

// Prime React
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

//context
import { ToastContext } from "../../../../../context/toast";

//ui-components

import { DATE_TIME_FORMAT, PROSPECT_TERM_SHEET_HEADERS } from "../../../../../utils/Constants/global";
import moment from "moment";
import Document from "../../../../../svgs/Document/Document";
import CustomModalSpinner from "../../../../../ui/CustomModalSpinner/CustomModalSpinner";
import { getDateFormat } from "../../../../../utils/HelperFuctions/dateFormat";
import { useHistory } from "react-router-dom";

const ViewTermSheetModal = (props) => {
    const { signedOwners, term } = props;

    const history = useHistory();

    // Context
    const toast = useContext(ToastContext);

    // UseRef
    const dt = useRef(null);

    const openDocument = (ownerId) => {
        if (ownerId) {
            history.push({
                pathname: "/SignedTermSheet",
                state: {
                    id: ownerId,
                    id2: term.id,
                    id3: term.prospect_id,
                },
            });
        }
    };

    const headerDataBdoy = (colField) => (rowData) => {
        if (colField === "pivot.signature") {
            return (
                <>
                    <div className={` mr-2 mt-1 ${rowData.pivot.signature ? "document_icon_background" : "no_doc"}`} onClick={() => openDocument(rowData.value)}>
                        {rowData.pivot.signature ? <Document /> : "Pending for Signature"}
                    </div>
                </>
            );
        } else if (colField === "pivot.agreement_id") {
            return rowData.pivot.agreement_id ? "Yes" : "No";
        } else {
            return rowData[colField] ? rowData[colField] : "";
        }
    };

    const columnComponents = PROSPECT_TERM_SHEET_HEADERS.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} body={headerDataBdoy(col.field)}></Column>;
    });

    return (
        <>
            <div className="grid">
                <div className="col-12 custom_scrollbar_fieldsetting">
                    <DataTable ref={dt} value={signedOwners} responsiveLayout="scroll" emptyMessage="No record available.">
                        {columnComponents}
                    </DataTable>
                </div>
            </div>
        </>
    );
};

export default ViewTermSheetModal;
