import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
//styles
import "./ClientChecklist.scss";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
// Prime React
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { BsCheckLg } from "react-icons/bs";

// Redux, Context & APIs
import { getAllWUnderWriting, getClientsDropdown, updateUnderwritingTask, deleteUnderwritingVertical, deleteUnderwritingTask } from "../../../services/under-writing";
import { ToastContext } from "../../../context/toast";
import { loginState } from "../../../redux/login";
// Utils
import { validateUserPermissions } from "../../../utils/HelperFuctions/validatePermissions";
import { UNDERWRITING_RIGHTS } from "../../../utils/Constants/rights";
// UI Components
import CustomAddNewButton from "../../../ui/CustomAddNewButton/CustomAddNewButton";
import CustomInputField from "../../../ui/CustomInputField/CustomInputField";
import CustomLoading from "../../../ui/CustomSpinner/custom_spinner";
// Components
import EditTask from "./Components/EditTask";
import AddVertical from "./Components/AddVertical";
import EditVertical from "./Components/EditVertical";
import AddTaskComponent from "./Components/AddTask";
import CustomConfirmDialog from "../../../ui/CustomConfirmDialog/CustomConfirmDialog";
import CustomUnderWritingVerticalDots from "../../../ui/CustomUnderWritingVerticalDots/CustomUnderWritingVerticalDots";
import { UNDERWRITING_CHILD_MENU, UNDERWRITING_PARENT_MENU } from "../../../utils/Constants/global";
import { mainViewState, onUnderWritingFormDirty } from "../../../redux/main-view";

const ClientChecklist = () => {
    const { user } = useSelector(loginState);
    const toastContext = useContext(ToastContext);
    const [loading, setLoading] = useState(false);
    const [clientLoading, setClientLoading] = useState(false);
    // Dialog state
    const [showAddVertical, setShowAddVertical] = useState(false);
    const [showEditVertical, setShowEditVertical] = useState(false);
    const [showDeleteVertical, setShowDeleteVertical] = useState(false);
    const [showAddTask, setShowAddTask] = useState(false);
    const [showEditTask, setShowEditTask] = useState(false);
    const [showDeleteTask, setShowDeleteTask] = useState(false);
    const [isTaskDeleteing, setIsTaskDeleting] = useState(false);
    const [isVerticalDeleteing, setIsVerticalDeleting] = useState(false);
    // display data
    const [clients, setClients] = useState([]);
    const [underWritings, setUnderWritings] = useState([]);
    const [menuStates, setMenuStates] = useState([]);
    const [checkedStates, setCheckedStates] = useState([]);
    // Progress
    const [progress, setProgress] = useState(0);
    // modal data
    const [selectedVerticalId, setSelectedVerticalId] = useState(null);
    const [verticalData, setVerticalData] = useState(null);
    const [taskId, setTaskId] = useState(null);
    const [taskData, setTaskData] = useState(null);
    const [underwriting_child_menu, setUnderwritingChildMenu] = useState([]);
    const [underwriting_parent_menu, setUnderwritingParentMenu] = useState([]);
    const [isOpening, setIsOpening] = useState(false);
    const dispatch = useDispatch();
    const { screens } = useSelector(mainViewState);
    //formik
    const validationSchema = Yup.object().shape({});
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            client: "",
        },
        onSubmit: async (data) => {
            console.log(data);
            return;
        },
    });

    const closeModal = (reload, name) => {
        if (!reload && screens.underwriting.isFormDirty) {
            setIsOpening(true);
        } else {
            dispatch(onUnderWritingFormDirty(false));
            if (name === "add vertical") {
                setShowAddVertical(false);
            } else if (name === "edit vertical") {
                setShowEditVertical(false);
            } else if (name === "delete vertical") {
                setShowDeleteVertical(false);
            } else if (name === "add task") {
                setShowAddTask(false);
            } else if (name === "edit task") {
                setShowEditTask(false);
            } else if (name === "delete task") {
                setShowDeleteTask(false);
            }
            if (reload) {
                getUnderwritings();
            }
        }
    };

    const getDropdown = async () => {
        let dto = {
            page: 1,
            per_page: 100,
            deleted: 0,
        };
        setClientLoading(true);
        const dropdown = await getClientsDropdown(dto);

        setClients(dropdown.data.prospects.data);
        // setLoading(false);
        setClientLoading(false);
    };

    const getUnderwritings = async () => {
        try {
            setLoading(true);
            let dto = {
                prospect_id: formik.values.client,
                // prospect_id: 1,
            };
            let resp = await getAllWUnderWriting(dto);
            const menuStateData = resp.data.under_writings?.map((under) => {
                return {
                    isOpened: false,
                    taskIsOpened: under.tasks.map((task) => {
                        return { isOpened: false };
                    }),
                };
            });
            const checkedStateData = resp.data.under_writings?.map((under) => {
                return {
                    isChecked: false,
                    taskIsChecked: under.tasks.map((task) => {
                        return { isChecked: false };
                    }),
                };
            });

            let total = 0;
            let count = 0;
            resp.data.under_writings.forEach((data) => {
                data.tasks.forEach((task) => {
                    total++;
                    if (task.status === 1) {
                        count++;
                    }
                });
            });
            let progress = (total / count) * 100;
            setProgress((count / total) * 100);

            setMenuStates([...menuStateData]);
            setCheckedStates([...checkedStateData]);
            setUnderWritings(resp.data.under_writings);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // const toggleParentMenu = (index) => {
    //     let menuStateData = [...menuStates];
    //     menuStateData[index].isOpened = !menuStateData[index].isOpened;
    //     setMenuStates([...menuStateData]);
    // };
    const toggleParentMenu = (index) => {
        let menuStateData = [...menuStates];
        menuStateData.forEach((menuState, i) => {
            if (i === index) {
                // Toggle the menu state for the clicked menu
                menuState.isOpened = !menuState.isOpened;
            } else {
                // Close other menus
                menuState.isOpened = false;
            }
        });
        setMenuStates(menuStateData);
    };

    // const toggleTaskMenu = (parentIndex, childIndex) => {
    //     let menuStateData = [...menuStates];
    //     menuStateData[parentIndex].taskIsOpened[childIndex].isOpened = !menuStateData[parentIndex].taskIsOpened[childIndex].isOpened;
    //     setMenuStates([...menuStateData]);
    // };

    // const toggleTaskMenu = (parentIndex, childIndex) => {
    //     let menuStateData = [...menuStates];
    //     menuStateData.forEach((menuState, parentIdx) => {
    //         if (parentIdx === parentIndex) {
    //             menuState.taskIsOpened[childIndex].isOpened = !menuState.taskIsOpened[childIndex].isOpened;
    //         } else {
    //             menuState.taskIsOpened.forEach((task, childIdx) => {
    //                 task.isOpened = false;
    //             });
    //         }
    //     });
    //     setMenuStates(menuStateData);
    // };

    //Handlers
    const handleOpenMenuItems = (status, id, data) => {
        if (status === 0 && validateUserPermissions([UNDERWRITING_RIGHTS.add_task], user?.rights)) {
            setShowAddTask(true);
            setSelectedVerticalId(id);
        } else if (status === 1 && validateUserPermissions([UNDERWRITING_RIGHTS.edit_vertical], user?.rights)) {
            setShowEditVertical(true);
            setVerticalData(data);
            setSelectedVerticalId(id);
        } else if (status === 2 && validateUserPermissions([UNDERWRITING_RIGHTS.delete_vertical], user?.rights)) {
            setShowDeleteVertical(true);
            setSelectedVerticalId(id);
        }

        // if (menuRef !== null) {
        //     menuRef.current.hide();
        // }
    };
    const handleChildrenOpenMenuItems = (status, id, data) => {
        if (status === 1 && validateUserPermissions([UNDERWRITING_RIGHTS.edit_task], user?.rights)) {
            setShowEditTask(true);
            setTaskId(id);
            setTaskData(data);
        } else if (status === 2 && validateUserPermissions([UNDERWRITING_RIGHTS.delete_task], user?.rights)) {
            setShowDeleteTask(true);
            setTaskId(id);
        }

        // if (menuRef !== null) {
        //     menuRef.current.hide();
        // }
    };

    const toggleTaskStatus = async (task) => {
        try {
            setLoading(true);
            let status = task.status === 1 ? true : false;
            let dto = {
                id: task.id,
                status: !status,
            };
            let resp = await updateUnderwritingTask(dto);
            if (resp.data.status) {
                toastContext.updateToast("Task");
                getUnderwritings();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const deleteVertical = async () => {
        try {
            setIsVerticalDeleting(true);
            let dto = {
                id: selectedVerticalId,
            };
            let resp = await deleteUnderwritingVertical(dto);
            if (resp.data.status) {
                toastContext.deleteToast("Vertical");
                setShowDeleteVertical(false);
                getUnderwritings();
            }
            setIsVerticalDeleting(false);
        } catch (error) {
            setIsVerticalDeleting(false);
        }
    };

    const deleteTask = async () => {
        try {
            setIsTaskDeleting(true);
            let dto = {
                id: taskId,
            };
            let resp = await deleteUnderwritingTask(dto);
            if (resp.data.status) {
                toastContext.deleteToast("Task");
                setShowDeleteTask(false);
                getUnderwritings();
            }
            setIsTaskDeleting(false);
        } catch (error) {
            setIsTaskDeleting(false);
        }
    };

    // const cancelHandlder = (reload, name) => {
    //     if (screens.underwriting.isFormDirty) {
    //         setIsOpening(true);
    //     } else {
    //         dispatch(onUnderWritingFormDirty(false));
    //         closeModal(reload, name)
    //     }
    // };

    const confirmHandler = () => {
        dispatch(onUnderWritingFormDirty(false));
        setIsOpening(false);

        setShowAddVertical(false);
        setShowEditVertical(false);
        setShowAddTask(false);
        setShowEditTask(false);
    };

    useEffect(() => {
        getDropdown();
    }, []);

    useEffect(() => {
        if (formik.values.client) {
            getUnderwritings();
        }
    }, [formik.values.client]);

    useEffect(() => {
        if (user?.rights) {
            const child_menu = UNDERWRITING_CHILD_MENU.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user?.rights);

                if (isAllowed) {
                    return col;
                }
            });

            setUnderwritingChildMenu(child_menu);

            
            const parent_menu = UNDERWRITING_PARENT_MENU.filter((col) => {
                const isAllowed = validateUserPermissions(col.required_rights, user?.rights);
                
                if (isAllowed) {
                    return col;
                }
            });
            
            setUnderwritingParentMenu(parent_menu);
        }
    }, [user]);

    // Ref
    // const menuRef = useRef();

    return (
        <>
            <>
                <Dialog
                    blockScroll={true}
                    header="Add Task"
                    visible={showAddTask}
                    onHide={() => closeModal(false, "add task")}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw" }}
                >
                    <AddTaskComponent verticalId={selectedVerticalId} onHide={closeModal} underWritings={underWritings} />
                </Dialog>

                <Dialog
                    blockScroll={true}
                    header="Edit Task"
                    visible={showEditTask}
                    onHide={() => closeModal(false, "edit task")}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw" }}
                >
                    <EditTask taskId={taskId} taskData={taskData} onHide={closeModal} underWritings={underWritings} />
                </Dialog>

                <Dialog
                    blockScroll={true}
                    header="Add New Vertical"
                    visible={showAddVertical}
                    onHide={() => closeModal(false, "add vertical")}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw" }}
                >
                    <AddVertical clientId={formik.values.client} onHide={closeModal} underWritings={underWritings} />
                </Dialog>

                <Dialog
                    blockScroll={true}
                    header="Edit Vertical"
                    visible={showEditVertical}
                    onHide={() => closeModal(false, "edit vertical")}
                    breakpoints={{ "960px": "60vw", "640px": "100vw" }}
                    style={{ width: "40vw" }}
                >
                    <EditVertical taskId={formik.values.client} verticalId={selectedVerticalId} verticalData={verticalData} onHide={closeModal} underWritings={underWritings} />
                </Dialog>

                <h4 className="pt-2">
                    <b>Underwriting Checklist</b>
                </h4>
                <div className="card client-checklist">
                    <div className="grid">
                        <div className="md:col-3 col-12 pt-3">
                            <CustomInputField
                                type="dropdown"
                                iden="client"
                                label="Client"
                                formik={formik}
                                optionLabel="company_business_name"
                                optionValue="id"
                                options={clients}
                                placeHolder="Select Client"
                                disabled={clientLoading}
                            />
                        </div>
                        <div className="md:col-7 col-12 progessbar-margin">
                            <div className="flex">
                                <ProgressBar value={progress}></ProgressBar>
                                <p className="ml-3 progressbar-label">{!isNaN(progress) ? progress.toFixed(0) : 0}%</p>
                            </div>
                        </div>
                        <div className="md:col-2 col-12 text-right pt-4 pr-0">
                            {formik.values.client ? (
                                validateUserPermissions([UNDERWRITING_RIGHTS.add_new_vertical], user?.rights) ? (
                                    <CustomAddNewButton title="Add New Vertical" type="button" onClick={() => setShowAddVertical(true)} />
                                ) : null
                            ) : null}
                        </div>
                    </div>
                </div>
                {loading === true ? (
                    <CustomLoading />
                ) : (
                    <div className="underwriting-card">
                        <div className="grid">
                            {underWritings?.map((underwriting, writingIndex) => (
                                <div key={`header-${writingIndex}`} className="md:col-3 col-12">
                                    <div className="card">
                                        {/* Header */}
                                        <div className="card_heading">
                                            <div className="flex flex-row justify-content-between align-items-center p-3">
                                                <p className="ml-2">{underwriting.name}</p>
                                                {underwriting_parent_menu.length > 0 && (
                                                    <CustomUnderWritingVerticalDots
                                                        items={underwriting_parent_menu}
                                                        handleMenuOpen={(status) => handleOpenMenuItems(status, underwriting.id, underwriting)}
                                                        showDots={underwriting.name === "Underwriting Documents"}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {/* Body */}
                                        <div className="card-cont custom_scrollbar">
                                            {underwriting?.tasks?.map((task, bodyIndex) => (
                                                <div className="mt-4" key={`body-${bodyIndex}`}>
                                                    <div className="flex flex-row justify-content-between align-items-center">
                                                        <Checkbox
                                                            checked={task.status ? true : false}
                                                            onChange={(e) => {
                                                                underwriting.name !== "Underwriting Documents" ? toggleTaskStatus(task) : e.preventDefault();
                                                            }}
                                                            disabled={underwriting.name === "Underwriting Documents" ? true : false}
                                                        />
                                                        <p className="ml-2 mb-0">{task?.title}</p>
                                                        <div className="ml-auto">
                                                            {underwriting_child_menu.length > 0 && underwriting.name !== "Underwriting Documents" && (
                                                                <CustomUnderWritingVerticalDots
                                                                    items={underwriting_child_menu}
                                                                    btnclr={true}
                                                                    handleMenuOpen={(status) => handleChildrenOpenMenuItems(status, task.id, task)}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <Divider />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </>
            {showDeleteTask && (
                <CustomConfirmDialog
                    isDisabled={isTaskDeleteing}
                    header="Delete Task"
                    type="error"
                    onHide={() => setShowDeleteTask(false)}
                    handler={deleteTask}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to delete task?"
                />
            )}
            {showDeleteVertical && (
                <CustomConfirmDialog
                    isDisabled={isVerticalDeleteing}
                    header="Delete Vertical"
                    type="error"
                    onHide={() => setShowDeleteVertical(false)}
                    handler={deleteVertical}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to delete vertical? Associated tasks will also be deleted."
                />
            )}
            {isOpening && (
                <CustomConfirmDialog
                    header="Unsaved Data"
                    type="error"
                    onHide={() => setIsOpening(false)}
                    handler={confirmHandler}
                    icon={<BsCheckLg />}
                    title={"Confirm"}
                    firsttext="Are you sure, you want to close the form without saving the changes?"
                />
            )}
        </>
    );
};

/* const VerticalMenu = ({ setShowAddTask, setSelectedVerticalId, setShowEditVertical, setVerticalData, setShowDeleteVertical, toggleParentMenu, user }) => {
    const wrapperRef = useRef("menu");

    return (
        <ul className="menu-content" ref={wrapperRef}>
            {validateUserPermissions(UNDERWRITING_RIGHTS.add_task ,user?.rights) ? (
                <li>
                    <a
                        onClick={() => {
                            setShowAddTask();
                            setSelectedVerticalId();
                        }}
                    >
                        Add Task
                    </a>
                </li>
            ) : null}
            {validateUserPermissions(UNDERWRITING_RIGHTS.edit_vertical ,user?.rights) ? (
                <li>
                    <a
                        onClick={() => {
                            setShowEditVertical();
                            setSelectedVerticalId();
                            setVerticalData();
                        }}
                    >
                        Edit Vertical
                    </a>
                </li>
            ) : null}
            {validateUserPermissions(UNDERWRITING_RIGHTS.delete_vertical ,user?.rights) ? (
                <li>
                    <a
                        onClick={() => {
                            setShowDeleteVertical();
                            setSelectedVerticalId();
                        }}
                    >
                        Delete Vertical
                    </a>
                </li>
            ) : null}
        </ul>
    );
};
 */
export default ClientChecklist;
