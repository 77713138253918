import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FactorFlow from "../../Images/LoginPageLogo.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./Login.css";
import { getLoggedInUser, login } from "../../services/user-management/user";
import CustomInputField from "../../ui/CustomInputField/CustomInputField";
import { loginState, setUser, setSignInLoading } from "../../redux/login";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../../context/toast";
import { Exception } from "sass";

const Login = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("");

    const toastContext = useContext(ToastContext);

    const dispatch = useDispatch();

    let history = useHistory();

    // Handlers
    const getUserData = async () => {
        try {
            setloading(true);

            if (localStorage.getItem("accessToken")) {
                let resp = await getLoggedInUser();
                if (resp) {
                    dispatch(setUser(resp?.data?.user));
                    setloading(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // const validationSchema = editData == null ? Yup.object().shape({
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is required").email("Invalid Email"),
        password: Yup.string().required("Password is required").min(8, "Minimum length should be 8 characters"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (data) => {
            // data.preventDefault();
            setloading(true);
            setloadingIcon("pi pi-spin pi-spinner");
            try {
                let dto = {
                    username: data.email,
                    password: data.password,
                };
                // setloading(true);
                // setloadingIcon("pi pi-spin pi-spinner");
                let resp = await login(dto);

                if (resp) {
                    localStorage.setItem("accessToken", resp.data.access_token);
                    localStorage.setItem("refreshToken", resp.data.refresh_token);

                    // let dateObject = new Date(resp.data.user.tokenExpTime);
                    // document.cookie = `access_token=${resp.data.access_token}; expires=${dateObject.toUTCString()}; path=/`;

                    getUserData();
                    getUserData();
                    setloadingIcon("");
                    setloading(false);
                    history.replace("/home");
                } else {
                    throw Exception();
                }
            } catch (error) {
                toastContext.showMessage("Error", "Email or Password you entered doesn't match your account.", "error");
                setloading(false);
            }
        },
    });

    return (
        <div className="background_image">
            <div className="grid">
                <div className="lg:col-7 md:col-6"></div>
                <div className="lg:col-5 md:col-6 col-12">
                    <div className="custom_margin_top">
                        <div className="res_img">
                            <img src={FactorFlow} alt="Factor Flow Logo" />
                        </div>
                        <div className="pt-2">
                            <h4>One tool for your whole team.</h4>
                        </div>
                    </div>

                    {/* Container Section */}

                    <div className="container">
                        <p className="sign_in_text">Sign In</p>
                        <p className="sign_text">Enter your Email & Password to Login</p>

                        <div className="pt-3">
                            {/* input section  */}

                            <form onSubmit={formik.handleSubmit}>
                                <div className="mb-3">
                                    <CustomInputField maxLength={35} iden="email" label="Email" formik={formik} placeHolder="Enter Email" />
                                </div>
                                <div className="mb-3">
                                    <CustomInputField maxLength={20} iden="password" formik={formik} placeHolder="Enter Password" type="password" toggleMask id="password" name="password" />
                                </div>
                                {/* button section */}
                                <div className="flex flex-row justify-content-between mt-5">
                                    <p
                                        className="forget_text"
                                        onClick={(e) => {
                                            history.push("/forgetpass");
                                        }}
                                    >
                                        Forgot password?
                                    </p>

                                    <button
                                        className={loading ? "disabled_sign_in_btn btn sign_in_btn" : "btn sign_in_btn"}
                                        type="submit"
                                        name="signin"
                                        id="signin"
                                        disabled={loading}
                                        icon={loadingIcon || ""}
                                        iconPos="right"
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </form>
                            {/* Register section */}

                            <div className="pt-3 text-center">
                                <p className="client_text">Not yet a client?</p>
                                <button
                                    className="register_btn"
                                    onClick={(e) => {
                                        history.push("/registernow");
                                    }}
                                    name="registernow"
                                    id="registernow"
                                >
                                    Register Now
                                </button>
                                <p
                                    className="client_text mt-3 cursor-pointer hyper_link_class"
                                    onClick={(e) => {
                                        history.push("/becomepartner");
                                    }}
                                >
                                    Become a Partner
                                </p>
                            </div>
                        </div>

                        {/* Privacy Policy Section */}

                        <div className="pt-6 text-center">
                            <p className="footer_text">
                                By registering, you accept our
                                <br />
                                <a>Terms of use</a> and{" "}
                                <a>
                                    <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
