import React, { useRef, useState } from "react";
//style
import "./columnfilter.scss";
//components
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { BsFilter } from "react-icons/bs";
import { Badge } from "primereact/badge";

const ColumnFilterComponent = (props) => {
    const { items, handleColumnFilter, selectedColumn } = props;
    const menu = useRef(null);
    // const [onSelectionColumn] = useState(selectedColumn);
    let onSelectionColumn = selectedColumn;
    const [minimumColumnMessage, setMinimumColumnMessage] = useState("");

    const onColumnToggle = (item) => {
        if (onSelectionColumn.includes(item.field) && onSelectionColumn.length > 3) {
            const index = onSelectionColumn.indexOf(item.field);
            onSelectionColumn.splice(index, 1);
            handleColumnFilter(onSelectionColumn);
        } else if (!onSelectionColumn.includes(item.field)) {
            onSelectionColumn.push(item.field);
            setMinimumColumnMessage("");
            handleColumnFilter(onSelectionColumn);
        } else if (onSelectionColumn.length === 3) {
            setMinimumColumnMessage("Minimum 3 columns should be selected.");
        }
        let orderedSelectedColumns = items.filter((col) => {
            return onSelectionColumn.some((sCol) => col.field == sCol);
        });
        handleColumnFilter(orderedSelectedColumns);
    };

    const renderMenuItem = (item, index) => {
        const isLastItem = index === items.length - 1;
        const menuClass = isLastItem ? "last_menu_item" : "menu_item";
        return (
            <>
                <div className={`pt-3 mb-2 ${menuClass} flex flex-row cursor-pointer`} onClick={() => onColumnToggle(item)}>
                    <p className="ml-4 menu_item_font">{item.header}</p>
                    <p className="text-right flex-grow-1 mr-4">{selectedColumn.includes(item.field) && <i className="pi pi-check selected_menu_color" />}</p>
                </div>
                <div>{isLastItem && <p className="mb-2 column_minimum_validation_text">{minimumColumnMessage}</p>}</div>
            </>
        );
    };

    const menuItems = items.map((item, index) => {
        return {
            label: item.label,
            template: () => renderMenuItem(item, index),
        };
    });

    return (
        <div className="column_filter_btn">
            <Menu model={menuItems} popup ref={menu} id="popup_menu" className="menu_border" />
            <Button
                icon={<BsFilter />}
                onClick={(event) => {
                    menu.current.toggle(event);
                    setMinimumColumnMessage("");
                }}
                aria-controls="popup_menu"
                aria-haspopup
                className="p-overlay-badge"
            ></Button>
            <Badge className="custom_bag_class" value={selectedColumn.length}></Badge>
        </div>
    );
};

export default ColumnFilterComponent;
